export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 130.3,
    "out:Primary Energy": 111.343944,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 45.697642,
    "out:Total CO2/m2": 45.697642,
    "out:Total CO2 (tons)": 120.139074,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 26850.394737,
    "out:Running Cost over RSP/MSEK": 7.97809,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 2,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": -3.736e-11,
    "out:EL kWh savings": -3.0355e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": -3.73603285623858e-11,
    "out:El kr savings": -6.071053391387693e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 107.724068,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 44.257706,
    "out:Total CO2/m2": 44.257706,
    "out:Total CO2 (tons)": 116.353483,
    "out:Klimatpaverkan": -3.785591,
    "out:Initial Cost/MSEK": 0.0657249875,
    "out:Running cost/(Apt SEK y)": 26014.631579,
    "out:Running Cost over RSP/MSEK": 7.72968,
    "out:LCP/MSEK": 0.182685,
    "out:ROI% old": 53.993381,
    "out:Payback discounted": 3,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 3.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 48,
    "out:Return/kSEK/y": 32,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 12235.696053,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 12235.7,
    "out:DH kr savings": 12235.696052886413,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 65724.9875,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.558064,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 128.3,
    "out:Primary Energy": 108.718944,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.715101,
    "out:CO2 Operational/m2": 44.977674,
    "out:Total CO2/m2": 45.692775,
    "out:Total CO2 (tons)": 120.126278,
    "out:Klimatpaverkan": -0.012795,
    "out:Initial Cost/MSEK": 0.22768,
    "out:Running cost/(Apt SEK y)": 26432.526316,
    "out:Running Cost over RSP/MSEK": 7.85389,
    "out:LCP/MSEK": -0.10348,
    "out:ROI% old": 7.792892,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 1.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 7,
    "out:Return/kSEK/y": 16,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 6739.43997,
    "out:EL kWh savings": -3.0355e-11,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 6739.44,
    "out:DH kr savings": 6739.439969590475,
    "out:El kr savings": -6.071053391387693e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 227680,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 123.3,
    "out:Primary Energy": 105.099068,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.715101,
    "out:CO2 Operational/m2": 43.177754,
    "out:Total CO2/m2": 43.892855,
    "out:Total CO2 (tons)": 115.39429,
    "out:Klimatpaverkan": -4.744784,
    "out:Initial Cost/MSEK": 0.2934049875,
    "out:Running cost/(Apt SEK y)": 25387.842105,
    "out:Running Cost over RSP/MSEK": 7.54338,
    "out:LCP/MSEK": 0.141305,
    "out:ROI% old": 21.165771,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 5.7,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 19,
    "out:Return/kSEK/y": 56,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 18975.136022,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 18975.14,
    "out:DH kr savings": 18975.136022476927,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 293404.9875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 128.138626,
    "out:Primary Energy": 107.453471,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.5698,
    "out:CO2 Operational/m2": -10.632405,
    "out:Total CO2/m2": 3.937395,
    "out:Total CO2 (tons)": 10.351409,
    "out:Klimatpaverkan": -109.787664,
    "out:Initial Cost/MSEK": 0.64498323651125,
    "out:Running cost/(Apt SEK y)": 25407.578947,
    "out:Running Cost over RSP/MSEK": 7.56116,
    "out:LCP/MSEK": -0.228053,
    "out:ROI% old": 9.23457,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 1.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 9,
    "out:Return/kSEK/y": 55,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": -3.736e-11,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54826.91,
    "out:DH kr savings": -3.73603285623858e-11,
    "out:El kr savings": 11364.501911319505,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 644983.23651125,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 124.138626,
    "out:Primary Energy": 103.833595,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.5698,
    "out:CO2 Operational/m2": -12.072341,
    "out:Total CO2/m2": 2.497459,
    "out:Total CO2 (tons)": 6.565818,
    "out:Klimatpaverkan": -113.573255,
    "out:Initial Cost/MSEK": 0.71070822401125,
    "out:Running cost/(Apt SEK y)": 24571.815789,
    "out:Running Cost over RSP/MSEK": 7.31275,
    "out:LCP/MSEK": -0.045368,
    "out:ROI% old": 13.373782,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 4.8,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 12,
    "out:Return/kSEK/y": 87,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 12235.696053,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 67062.6,
    "out:DH kr savings": 12235.696052886413,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 710708.22401125,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.558064,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 126.138626,
    "out:Primary Energy": 104.828471,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.284901,
    "out:CO2 Operational/m2": -11.352373,
    "out:Total CO2/m2": 3.932528,
    "out:Total CO2 (tons)": 10.338614,
    "out:Klimatpaverkan": -109.80046,
    "out:Initial Cost/MSEK": 0.87266323651125,
    "out:Running cost/(Apt SEK y)": 24989.710526,
    "out:Running Cost over RSP/MSEK": 7.43695,
    "out:LCP/MSEK": -0.331523,
    "out:ROI% old": 8.858596,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 3.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 71,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 6739.43997,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61566.35,
    "out:DH kr savings": 6739.439969590475,
    "out:El kr savings": 11364.501911319505,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 872663.23651125,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 121.138626,
    "out:Primary Energy": 101.208595,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.284901,
    "out:CO2 Operational/m2": -13.152293,
    "out:Total CO2/m2": 2.132608,
    "out:Total CO2 (tons)": 5.606625,
    "out:Klimatpaverkan": -114.532448,
    "out:Initial Cost/MSEK": 0.93838822401125,
    "out:Running cost/(Apt SEK y)": 23945.026316,
    "out:Running Cost over RSP/MSEK": 7.12645,
    "out:LCP/MSEK": -0.086748,
    "out:ROI% old": 12.965088,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 7.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 12,
    "out:Return/kSEK/y": 110,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 18975.136022,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73802.04,
    "out:DH kr savings": 18975.136022476927,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 938388.22401125,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 128.026217,
    "out:Primary Energy": 107.251135,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.1396,
    "out:CO2 Operational/m2": -77.287609,
    "out:Total CO2/m2": -48.148009,
    "out:Total CO2 (tons)": -126.581087,
    "out:Klimatpaverkan": -246.72016,
    "out:Initial Cost/MSEK": 1.2899664555600001,
    "out:Running cost/(Apt SEK y)": 24035.631579,
    "out:Running Cost over RSP/MSEK": 7.16499,
    "out:LCP/MSEK": -0.476866,
    "out:ROI% old": 9.004664,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 1.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 107,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": -3.736e-11,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106960.45,
    "out:DH kr savings": -3.73603285623858e-11,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1289966.4555600001,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 124.026217,
    "out:Primary Energy": 103.631259,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.1396,
    "out:CO2 Operational/m2": -78.727545,
    "out:Total CO2/m2": -49.587945,
    "out:Total CO2 (tons)": -130.366678,
    "out:Klimatpaverkan": -250.505751,
    "out:Initial Cost/MSEK": 1.35569144306,
    "out:Running cost/(Apt SEK y)": 23199.894737,
    "out:Running Cost over RSP/MSEK": 6.91658,
    "out:LCP/MSEK": -0.294181,
    "out:ROI% old": 11.185752,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 4.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 139,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 12235.696053,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119196.14,
    "out:DH kr savings": 12235.696052886413,
    "out:El kr savings": 11955.548299103008,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1355691.44306,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.558064,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 126.026217,
    "out:Primary Energy": 104.626135,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.854701,
    "out:CO2 Operational/m2": -78.007577,
    "out:Total CO2/m2": -48.152876,
    "out:Total CO2 (tons)": -126.593882,
    "out:Klimatpaverkan": -246.732956,
    "out:Initial Cost/MSEK": 1.5176464555600002,
    "out:Running cost/(Apt SEK y)": 23617.763158,
    "out:Running Cost over RSP/MSEK": 7.04079,
    "out:LCP/MSEK": -0.580346,
    "out:ROI% old": 8.822872,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 3.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 123,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 6739.43997,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113699.89,
    "out:DH kr savings": 6739.439969590475,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1517646.4555600001,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 121.026217,
    "out:Primary Energy": 101.006259,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.854701,
    "out:CO2 Operational/m2": -79.807497,
    "out:Total CO2/m2": -49.952796,
    "out:Total CO2 (tons)": -131.325871,
    "out:Klimatpaverkan": -251.464944,
    "out:Initial Cost/MSEK": 1.5833714430599999,
    "out:Running cost/(Apt SEK y)": 22573.078947,
    "out:Running Cost over RSP/MSEK": 6.73028,
    "out:LCP/MSEK": -0.335561,
    "out:ROI% old": 11.258165,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 7.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 163,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 18975.136022,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125935.58,
    "out:DH kr savings": 18975.136022476927,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1583371.44306,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 127.3,
    "out:Primary Energy": 107.843944,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 44.61769,
    "out:Total CO2/m2": 44.61769,
    "out:Total CO2 (tons)": 117.29988,
    "out:Klimatpaverkan": -2.839193,
    "out:Initial Cost/MSEK": 0.100293875,
    "out:Running cost/(Apt SEK y)": 26223.578947,
    "out:Running Cost over RSP/MSEK": 7.79179,
    "out:LCP/MSEK": 0.086006,
    "out:ROI% old": 26.536302,
    "out:Payback discounted": 5,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 2.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 24,
    "out:Return/kSEK/y": 24,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 8985.919959,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 8985.92,
    "out:DH kr savings": 8985.919959453979,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 100293.875,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 122.3,
    "out:Primary Energy": 104.224068,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 42.81777,
    "out:Total CO2/m2": 42.81777,
    "out:Total CO2 (tons)": 112.567892,
    "out:Klimatpaverkan": -7.571182,
    "out:Initial Cost/MSEK": 0.16601886249999998,
    "out:Running cost/(Apt SEK y)": 25178.894737,
    "out:Running Cost over RSP/MSEK": 7.48128,
    "out:LCP/MSEK": 0.330791,
    "out:ROI% old": 42.749876,
    "out:Payback discounted": 3,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 6.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 38,
    "out:Return/kSEK/y": 64,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 21221.616012,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 21221.62,
    "out:DH kr savings": 21221.61601234043,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 166018.8625,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 105.743944,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.715101,
    "out:CO2 Operational/m2": 43.897722,
    "out:Total CO2/m2": 44.612823,
    "out:Total CO2 (tons)": 117.287085,
    "out:Klimatpaverkan": -2.851989,
    "out:Initial Cost/MSEK": 0.327973875,
    "out:Running cost/(Apt SEK y)": 25805.710526,
    "out:Running Cost over RSP/MSEK": 7.66758,
    "out:LCP/MSEK": -0.017464,
    "out:ROI% old": 13.525032,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 12,
    "out:Return/kSEK/y": 40,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 14377.471935,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 14377.47,
    "out:DH kr savings": 14377.471935126367,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 327973.875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 120.3,
    "out:Primary Energy": 102.124068,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.715101,
    "out:CO2 Operational/m2": 42.097802,
    "out:Total CO2/m2": 42.812903,
    "out:Total CO2 (tons)": 112.555096,
    "out:Klimatpaverkan": -7.583977,
    "out:Initial Cost/MSEK": 0.3936988624999999,
    "out:Running cost/(Apt SEK y)": 24761.026316,
    "out:Running Cost over RSP/MSEK": 7.35708,
    "out:LCP/MSEK": 0.227311,
    "out:ROI% old": 22.533902,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 20,
    "out:Return/kSEK/y": 79,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 26613.167988,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 26613.17,
    "out:DH kr savings": 26613.167988012818,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 393698.86249999993,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 125.138626,
    "out:Primary Energy": 103.953471,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.5698,
    "out:CO2 Operational/m2": -11.712357,
    "out:Total CO2/m2": 2.857443,
    "out:Total CO2 (tons)": 7.512216,
    "out:Klimatpaverkan": -112.626858,
    "out:Initial Cost/MSEK": 0.74527711151125,
    "out:Running cost/(Apt SEK y)": 24780.763158,
    "out:Running Cost over RSP/MSEK": 7.37485,
    "out:LCP/MSEK": -0.142037,
    "out:ROI% old": 11.563101,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 11,
    "out:Return/kSEK/y": 79,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 8985.919959,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63812.83,
    "out:DH kr savings": 8985.919959453979,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 745277.11151125,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 120.138626,
    "out:Primary Energy": 100.333595,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.5698,
    "out:CO2 Operational/m2": -13.512277,
    "out:Total CO2/m2": 1.057523,
    "out:Total CO2 (tons)": 2.780227,
    "out:Klimatpaverkan": -117.358846,
    "out:Initial Cost/MSEK": 0.81100209901125,
    "out:Running cost/(Apt SEK y)": 23736.078947,
    "out:Running Cost over RSP/MSEK": 7.06435,
    "out:LCP/MSEK": 0.102738,
    "out:ROI% old": 16.095431,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 15,
    "out:Return/kSEK/y": 118,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 21221.616012,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 76048.52,
    "out:DH kr savings": 21221.61601234043,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 811002.09901125,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 123.138626,
    "out:Primary Energy": 101.853471,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.284901,
    "out:CO2 Operational/m2": -12.432325,
    "out:Total CO2/m2": 2.852576,
    "out:Total CO2 (tons)": 7.499421,
    "out:Klimatpaverkan": -112.639653,
    "out:Initial Cost/MSEK": 0.97295711151125,
    "out:Running cost/(Apt SEK y)": 24362.894737,
    "out:Running Cost over RSP/MSEK": 7.25065,
    "out:LCP/MSEK": -0.245517,
    "out:ROI% old": 10.680841,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 5.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 10,
    "out:Return/kSEK/y": 95,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 14377.471935,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69204.38,
    "out:DH kr savings": 14377.471935126367,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 972957.11151125,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 118.138626,
    "out:Primary Energy": 98.233595,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.284901,
    "out:CO2 Operational/m2": -14.232245,
    "out:Total CO2/m2": 1.052656,
    "out:Total CO2 (tons)": 2.767432,
    "out:Klimatpaverkan": -117.371642,
    "out:Initial Cost/MSEK": 1.03868209901125,
    "out:Running cost/(Apt SEK y)": 23318.210526,
    "out:Running Cost over RSP/MSEK": 6.94015,
    "out:LCP/MSEK": -0.000742,
    "out:ROI% old": 14.275508,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 13,
    "out:Return/kSEK/y": 134,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 26613.167988,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 81440.07,
    "out:DH kr savings": 26613.167988012818,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1038682.09901125,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 125.026217,
    "out:Primary Energy": 103.751135,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.1396,
    "out:CO2 Operational/m2": -78.367561,
    "out:Total CO2/m2": -49.227961,
    "out:Total CO2 (tons)": -129.42028,
    "out:Klimatpaverkan": -249.559354,
    "out:Initial Cost/MSEK": 1.39026033056,
    "out:Running cost/(Apt SEK y)": 23408.815789,
    "out:Running Cost over RSP/MSEK": 6.97868,
    "out:LCP/MSEK": -0.39085,
    "out:ROI% old": 10.269505,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 4,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 9,
    "out:Return/kSEK/y": 131,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 8985.919959,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115946.37,
    "out:DH kr savings": 8985.919959453979,
    "out:El kr savings": 11955.548299103008,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1390260.3305600001,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 120.026217,
    "out:Primary Energy": 100.131259,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.1396,
    "out:CO2 Operational/m2": -80.167481,
    "out:Total CO2/m2": -51.027881,
    "out:Total CO2 (tons)": -134.152269,
    "out:Klimatpaverkan": -254.291342,
    "out:Initial Cost/MSEK": 1.4559853180600002,
    "out:Running cost/(Apt SEK y)": 22364.157895,
    "out:Running Cost over RSP/MSEK": 6.66818,
    "out:LCP/MSEK": -0.146075,
    "out:ROI% old": 12.852465,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 12,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 21221.616012,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128182.06,
    "out:DH kr savings": 21221.61601234043,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1455985.3180600002,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.390785,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 123.026217,
    "out:Primary Energy": 101.651135,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.854701,
    "out:CO2 Operational/m2": -79.087529,
    "out:Total CO2/m2": -49.232828,
    "out:Total CO2 (tons)": -129.433075,
    "out:Klimatpaverkan": -249.572149,
    "out:Initial Cost/MSEK": 1.6179403305600002,
    "out:Running cost/(Apt SEK y)": 22990.947368,
    "out:Running Cost over RSP/MSEK": 6.85448,
    "out:LCP/MSEK": -0.49433,
    "out:ROI% old": 9.920991,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 5.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 9,
    "out:Return/kSEK/y": 147,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 14377.471935,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121337.92,
    "out:DH kr savings": 14377.471935126367,
    "out:El kr savings": 11955.548299103008,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 284959.309582,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.39,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1617940.3305600001,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.736659,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 118.026217,
    "out:Primary Energy": 98.031259,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.854701,
    "out:CO2 Operational/m2": -80.887449,
    "out:Total CO2/m2": -51.032748,
    "out:Total CO2 (tons)": -134.165064,
    "out:Klimatpaverkan": -254.304138,
    "out:Initial Cost/MSEK": 1.6836653180600003,
    "out:Running cost/(Apt SEK y)": 21946.263158,
    "out:Running Cost over RSP/MSEK": 6.54398,
    "out:LCP/MSEK": -0.249555,
    "out:ROI% old": 12.168265,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 11,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 26613.167988,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133573.62,
    "out:DH kr savings": 26613.167988012818,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 272723.613529,
    "out:% savings (space heating)": 4.29384,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.74,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1683665.3180600002,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.761281,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.908441,
    "out:Electricity (inc PV)": 27.806888,
    "out:Total Energy Use Pre PV": 88.908441,
    "out:Total Energy Use Post PV": 89.806888,
    "out:Primary Energy": 101.935157,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.733089,
    "out:CO2 Operational/m2": 25.871137,
    "out:Total CO2/m2": 29.604226,
    "out:Total CO2 (tons)": 77.829492,
    "out:Klimatpaverkan": -42.309581,
    "out:Initial Cost/MSEK": 2.511359684370602,
    "out:Running cost/(Apt SEK y)": 16677.315789,
    "out:Running Cost over RSP/MSEK": 4.94407,
    "out:LCP/MSEK": 0.52266,
    "out:ROI% old": 17.258835,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1883.59087,
    "out:Return %": 15,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 19.308941,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 167518.538501,
    "out:EL kWh savings": -59170.595115,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49177.35,
    "out:DH kr savings": 167518.538501256,
    "out:El kr savings": -118341.19023077573,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 162370.370878,
    "out:% savings (space heating)": 43.019805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.71,
    "out:Etvv": 0,
    "out:Ef": 27.81,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2511359.684370602,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": 3.552129,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.552129,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.368477,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.829252,
    "out:Electricity (inc PV)": 27.741881,
    "out:Total Energy Use Pre PV": 83.829252,
    "out:Total Energy Use Post PV": 84.741881,
    "out:Primary Energy": 98.365121,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.733089,
    "out:CO2 Operational/m2": 24.060764,
    "out:Total CO2/m2": 27.793853,
    "out:Total CO2 (tons)": 73.070023,
    "out:Klimatpaverkan": -47.069051,
    "out:Initial Cost/MSEK": 2.577084671870602,
    "out:Running cost/(Apt SEK y)": 15621.684211,
    "out:Running Cost over RSP/MSEK": 4.63034,
    "out:LCP/MSEK": 0.770665,
    "out:ROI% old": 18.557791,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 1878.04764,
    "out:Return %": 17,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 19.229752,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 179067.217595,
    "out:EL kWh savings": -58999.691751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 61067.83,
    "out:DH kr savings": 179067.217594752,
    "out:El kr savings": -117999.3835023941,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 150821.691784,
    "out:% savings (space heating)": 47.072552,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.27,
    "out:Etvv": 0,
    "out:Ef": 27.74,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2577084.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": 3.541676,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.541676,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.188892,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.608104,
    "out:Electricity (inc PV)": 27.387034,
    "out:Total Energy Use Pre PV": 86.608104,
    "out:Total Energy Use Post PV": 87.387034,
    "out:Primary Energy": 99.34038,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.44819,
    "out:CO2 Operational/m2": 25.111523,
    "out:Total CO2/m2": 29.559713,
    "out:Total CO2 (tons)": 77.712468,
    "out:Klimatpaverkan": -42.426606,
    "out:Initial Cost/MSEK": 2.739039684370602,
    "out:Running cost/(Apt SEK y)": 16217.894737,
    "out:Running Cost over RSP/MSEK": 4.80765,
    "out:LCP/MSEK": 0.4314,
    "out:ROI% old": 16.535722,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1862.56728,
    "out:Return %": 15,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 19.008604,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 171652.348244,
    "out:EL kWh savings": -58066.7992,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55518.75,
    "out:DH kr savings": 171652.34824353975,
    "out:El kr savings": -116133.59840008139,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 158236.561135,
    "out:% savings (space heating)": 44.470471,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 64.34,
    "out:Etvv": 0,
    "out:Ef": 27.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2739039.684370602,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 3.512483,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.512483,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.812764,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.523862,
    "out:Electricity (inc PV)": 27.314556,
    "out:Total Energy Use Pre PV": 82.523862,
    "out:Total Energy Use Post PV": 83.314556,
    "out:Primary Energy": 95.776071,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.44819,
    "out:CO2 Operational/m2": 23.660466,
    "out:Total CO2/m2": 28.108656,
    "out:Total CO2 (tons)": 73.89764,
    "out:Klimatpaverkan": -46.241434,
    "out:Initial Cost/MSEK": 2.8047646718706014,
    "out:Running cost/(Apt SEK y)": 15370.5,
    "out:Running Cost over RSP/MSEK": 4.55583,
    "out:LCP/MSEK": 0.617495,
    "out:ROI% old": 17.430849,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 56.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 1856.67034,
    "out:Return %": 16,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.924362,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 183157.186143,
    "out:EL kWh savings": -57876.254581,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 67404.68,
    "out:DH kr savings": 183157.18614299133,
    "out:El kr savings": -115752.50916262003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 146731.723236,
    "out:% savings (space heating)": 48.507833,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 59.93,
    "out:Etvv": 0,
    "out:Ef": 27.31,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2804764.6718706014,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": 3.501362,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.501362,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.761281,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.908441,
    "out:Electricity (inc PV)": 19.834548,
    "out:Total Energy Use Pre PV": 88.908441,
    "out:Total Energy Use Post PV": 81.834548,
    "out:Primary Energy": 87.584945,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.302889,
    "out:CO2 Operational/m2": -2.156924,
    "out:Total CO2/m2": 16.145965,
    "out:Total CO2 (tons)": 42.447732,
    "out:Klimatpaverkan": -77.691341,
    "out:Initial Cost/MSEK": 3.156342920881852,
    "out:Running cost/(Apt SEK y)": 15039.315789,
    "out:Running Cost over RSP/MSEK": 4.47023,
    "out:LCP/MSEK": 0.351517,
    "out:ROI% old": 15.876692,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1350.500939,
    "out:Return %": 14,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 19.308941,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14139.289063,
    "out:PV (% sold (El))": 40.797567,
    "out:PV (kWh self-consumed)": 20517.898297,
    "out:PV (ratio sold/self-consumed)": 0.68912,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 167518.538501,
    "out:EL kWh savings": -38211.318015,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112304.84,
    "out:DH kr savings": 167518.538501256,
    "out:El kr savings": -76422.63602974967,
    "out:El kr sold": 21208.933595184626,
    "out:El kr saved": 41035.796594,
    "out:El kr return": 62244.73018918463,
    "out:% solar/total": 147,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 162370.370878,
    "out:% savings (space heating)": 43.019805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.71,
    "out:Etvv": 0,
    "out:Ef": 19.83,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3156342.920881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -24.475932,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.003714,
    "out:Bought CO2": 2.527782,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.368477,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.829252,
    "out:Electricity (inc PV)": 19.78155,
    "out:Total Energy Use Pre PV": 83.829252,
    "out:Total Energy Use Post PV": 76.78155,
    "out:Primary Energy": 84.036525,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.302889,
    "out:CO2 Operational/m2": -4.042118,
    "out:Total CO2/m2": 14.260771,
    "out:Total CO2 (tons)": 37.491558,
    "out:Klimatpaverkan": -82.647515,
    "out:Initial Cost/MSEK": 3.2220679083818524,
    "out:Running cost/(Apt SEK y)": 13984.184211,
    "out:Running Cost over RSP/MSEK": 4.15666,
    "out:LCP/MSEK": 0.599362,
    "out:ROI% old": 16.943112,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 1345.955612,
    "out:Return %": 15,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 19.229752,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14178.651595,
    "out:PV (% sold (El))": 40.911143,
    "out:PV (kWh self-consumed)": 20478.535766,
    "out:PV (ratio sold/self-consumed)": 0.692366,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 179067.217595,
    "out:EL kWh savings": -38071.986305,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124191.22,
    "out:DH kr savings": 179067.217594752,
    "out:El kr savings": -76143.97260902927,
    "out:El kr sold": 21267.977391999153,
    "out:El kr saved": 40957.071532,
    "out:El kr return": 62225.04892399916,
    "out:% solar/total": 147,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 150821.691784,
    "out:% savings (space heating)": 47.072552,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.27,
    "out:Etvv": 0,
    "out:Ef": 19.78,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3222067.9083818523,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -24.561206,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.080489,
    "out:Bought CO2": 2.519283,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.188892,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.608104,
    "out:Electricity (inc PV)": 19.492726,
    "out:Total Energy Use Pre PV": 86.608104,
    "out:Total Energy Use Post PV": 79.492726,
    "out:Primary Energy": 85.130626,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.01799,
    "out:CO2 Operational/m2": -3.201051,
    "out:Total CO2/m2": 15.816939,
    "out:Total CO2 (tons)": 41.582723,
    "out:Klimatpaverkan": -78.55635,
    "out:Initial Cost/MSEK": 3.384022920881852,
    "out:Running cost/(Apt SEK y)": 14581.842105,
    "out:Running Cost over RSP/MSEK": 4.33439,
    "out:LCP/MSEK": 0.259677,
    "out:ROI% old": 15.381946,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1333.273288,
    "out:Return %": 14,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 19.008604,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14288.720651,
    "out:PV (% sold (El))": 41.228737,
    "out:PV (kWh self-consumed)": 20368.46671,
    "out:PV (ratio sold/self-consumed)": 0.701512,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171652.348244,
    "out:EL kWh savings": -37312.668181,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118460.09,
    "out:DH kr savings": 171652.34824353975,
    "out:El kr savings": -74625.33636188517,
    "out:El kr sold": 21433.080976510726,
    "out:El kr saved": 40736.93342,
    "out:El kr return": 62170.01439651073,
    "out:% solar/total": 146,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 158236.561135,
    "out:% savings (space heating)": 44.470471,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 64.34,
    "out:Etvv": 0,
    "out:Ef": 19.49,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3384022.920881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -24.800091,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.29566,
    "out:Bought CO2": 2.495569,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.812764,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.523862,
    "out:Electricity (inc PV)": 19.433817,
    "out:Total Energy Use Pre PV": 82.523862,
    "out:Total Energy Use Post PV": 75.433817,
    "out:Primary Energy": 81.590741,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.01799,
    "out:CO2 Operational/m2": -4.73224,
    "out:Total CO2/m2": 14.28575,
    "out:Total CO2 (tons)": 37.557228,
    "out:Klimatpaverkan": -82.581845,
    "out:Initial Cost/MSEK": 3.4497479083818523,
    "out:Running cost/(Apt SEK y)": 13735,
    "out:Running Cost over RSP/MSEK": 4.08272,
    "out:LCP/MSEK": 0.445622,
    "out:ROI% old": 16.131075,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 73.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 1328.446471,
    "out:Return %": 14,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.924362,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14330.735934,
    "out:PV (% sold (El))": 41.349968,
    "out:PV (kWh self-consumed)": 20326.451427,
    "out:PV (ratio sold/self-consumed)": 0.705029,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 183157.186143,
    "out:EL kWh savings": -37157.796455,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130337.7,
    "out:DH kr savings": 183157.18614299133,
    "out:El kr savings": -74315.59291022252,
    "out:El kr sold": 21496.103900252427,
    "out:El kr saved": 40652.902854,
    "out:El kr return": 62149.00675425243,
    "out:% solar/total": 146,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 146731.723236,
    "out:% savings (space heating)": 48.507833,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 59.93,
    "out:Etvv": 0,
    "out:Ef": 19.43,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3449747.9083818523,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -24.891344,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.377887,
    "out:Bought CO2": 2.486543,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.761281,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.908441,
    "out:Electricity (inc PV)": 18.051099,
    "out:Total Energy Use Pre PV": 88.908441,
    "out:Total Energy Use Post PV": 80.051099,
    "out:Primary Energy": 84.374737,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.872689,
    "out:CO2 Operational/m2": -60.717999,
    "out:Total CO2/m2": -27.84531,
    "out:Total CO2 (tons)": -73.205303,
    "out:Klimatpaverkan": -193.344377,
    "out:Initial Cost/MSEK": 3.801326139930602,
    "out:Running cost/(Apt SEK y)": 13612.236842,
    "out:Running Cost over RSP/MSEK": 4.05778,
    "out:LCP/MSEK": 0.118984,
    "out:ROI% old": 14.732866,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1228.403442,
    "out:Return %": 13,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 19.308941,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44310.505955,
    "out:PV (% sold (El))": 63.926864,
    "out:PV (kWh self-consumed)": 25003.868767,
    "out:PV (ratio sold/self-consumed)": 1.772146,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 167518.538501,
    "out:EL kWh savings": -33522.631659,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166939.03,
    "out:DH kr savings": 167518.538501256,
    "out:El kr savings": -67045.26331718775,
    "out:El kr sold": 66465.75893245947,
    "out:El kr saved": 50007.737534,
    "out:El kr return": 116473.49646645947,
    "out:% solar/total": 179,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 162370.370878,
    "out:% savings (space heating)": 43.019805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.71,
    "out:Etvv": 0,
    "out:Ef": 18.05,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3801326.139930602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -83.037007,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -85.33831,
    "out:Bought CO2": 2.301303,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.368477,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.829252,
    "out:Electricity (inc PV)": 18.003681,
    "out:Total Energy Use Pre PV": 83.829252,
    "out:Total Energy Use Post PV": 75.003681,
    "out:Primary Energy": 80.836361,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.872689,
    "out:CO2 Operational/m2": -62.637548,
    "out:Total CO2/m2": -29.764859,
    "out:Total CO2 (tons)": -78.251797,
    "out:Klimatpaverkan": -198.39087,
    "out:Initial Cost/MSEK": 3.867051127430602,
    "out:Running cost/(Apt SEK y)": 12557.342105,
    "out:Running Cost over RSP/MSEK": 3.74427,
    "out:LCP/MSEK": 0.366769,
    "out:ROI% old": 15.640637,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 73.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 1224.336305,
    "out:Return %": 14,
    "out:Return/kSEK/y": 543,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 19.229752,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44367.815973,
    "out:PV (% sold (El))": 64.009545,
    "out:PV (kWh self-consumed)": 24946.558748,
    "out:PV (ratio sold/self-consumed)": 1.778514,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 179067.217595,
    "out:EL kWh savings": -33397.969765,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178823,
    "out:DH kr savings": 179067.217594752,
    "out:El kr savings": -66795.93952980488,
    "out:El kr sold": 66551.72395963308,
    "out:El kr saved": 49893.117496,
    "out:El kr return": 116444.84145563308,
    "out:% solar/total": 179,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 150821.691784,
    "out:% savings (space heating)": 47.072552,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.27,
    "out:Etvv": 0,
    "out:Ef": 18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3867051.127430602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -83.156636,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -85.450339,
    "out:Bought CO2": 2.293703,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.188892,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.608104,
    "out:Electricity (inc PV)": 17.745239,
    "out:Total Energy Use Pre PV": 86.608104,
    "out:Total Energy Use Post PV": 77.745239,
    "out:Primary Energy": 81.985149,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.58779,
    "out:CO2 Operational/m2": -61.892544,
    "out:Total CO2/m2": -28.304754,
    "out:Total CO2 (tons)": -74.413181,
    "out:Klimatpaverkan": -194.552255,
    "out:Initial Cost/MSEK": 4.029006139930602,
    "out:Running cost/(Apt SEK y)": 13155.657895,
    "out:Running Cost over RSP/MSEK": 3.9222,
    "out:LCP/MSEK": 0.026884,
    "out:ROI% old": 14.381037,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1212.988843,
    "out:Return %": 13,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 19.008604,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44528.137746,
    "out:PV (% sold (El))": 64.240842,
    "out:PV (kWh self-consumed)": 24786.236976,
    "out:PV (ratio sold/self-consumed)": 1.796486,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171652.348244,
    "out:EL kWh savings": -32718.525901,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173007.5,
    "out:DH kr savings": 171652.34824353975,
    "out:El kr savings": -65437.051802384616,
    "out:El kr sold": 66792.20661885127,
    "out:El kr saved": 49572.473952,
    "out:El kr return": 116364.68057085127,
    "out:% solar/total": 178,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 158236.561135,
    "out:% savings (space heating)": 44.470471,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 64.34,
    "out:Etvv": 0,
    "out:Ef": 17.75,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4029006.139930602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -83.491584,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -85.764083,
    "out:Bought CO2": 2.272499,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.812764,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.523862,
    "out:Electricity (inc PV)": 17.692523,
    "out:Total Energy Use Pre PV": 82.523862,
    "out:Total Energy Use Post PV": 73.692523,
    "out:Primary Energy": 78.456411,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.58779,
    "out:CO2 Operational/m2": -63.460406,
    "out:Total CO2/m2": -29.872616,
    "out:Total CO2 (tons)": -78.53509,
    "out:Klimatpaverkan": -198.674163,
    "out:Initial Cost/MSEK": 4.094731127430602,
    "out:Running cost/(Apt SEK y)": 12309.078947,
    "out:Running Cost over RSP/MSEK": 3.67061,
    "out:LCP/MSEK": 0.212749,
    "out:ROI% old": 15.027953,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 1208.670486,
    "out:Return %": 13,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.924362,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44589.384433,
    "out:PV (% sold (El))": 64.329202,
    "out:PV (kWh self-consumed)": 24724.990289,
    "out:PV (ratio sold/self-consumed)": 1.803414,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 183157.186143,
    "out:EL kWh savings": -32579.935569,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184881.39,
    "out:DH kr savings": 183157.18614299133,
    "out:El kr savings": -65159.8711373275,
    "out:El kr sold": 66884.07664938289,
    "out:El kr saved": 49449.980578,
    "out:El kr return": 116334.05722738289,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 146731.723236,
    "out:% savings (space heating)": 48.507833,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 59.93,
    "out:Etvv": 0,
    "out:Ef": 17.69,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4094731.1274306024,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -83.61951,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -85.883941,
    "out:Bought CO2": 2.264431,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.667938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.50703,
    "out:Electricity (inc PV)": 27.244317,
    "out:Total Energy Use Pre PV": 86.50703,
    "out:Total Energy Use Post PV": 87.244317,
    "out:Primary Energy": 98.477574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.733089,
    "out:CO2 Operational/m2": 25.09818,
    "out:Total CO2/m2": 28.831269,
    "out:Total CO2 (tons)": 75.797389,
    "out:Klimatpaverkan": -44.341685,
    "out:Initial Cost/MSEK": 2.611653559370602,
    "out:Running cost/(Apt SEK y)": 16203.921053,
    "out:Running Cost over RSP/MSEK": 4.80355,
    "out:LCP/MSEK": 0.562886,
    "out:ROI% old": 17.364697,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1855.4921,
    "out:Return %": 15,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.90753,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 173021.935999,
    "out:EL kWh savings": -57691.596292,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57638.74,
    "out:DH kr savings": 173021.93599853019,
    "out:El kr savings": -115383.19258448547,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 156866.97338,
    "out:% savings (space heating)": 44.951097,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.56,
    "out:Etvv": 0,
    "out:Ef": 27.24,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2611653.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 3.49914,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.49914,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.298236,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.42084,
    "out:Electricity (inc PV)": 27.169988,
    "out:Total Energy Use Pre PV": 81.42084,
    "out:Total Energy Use Post PV": 82.169988,
    "out:Primary Energy": 94.914682,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.733089,
    "out:CO2 Operational/m2": 23.286882,
    "out:Total CO2/m2": 27.019971,
    "out:Total CO2 (tons)": 71.035488,
    "out:Klimatpaverkan": -49.103586,
    "out:Initial Cost/MSEK": 2.677378546870602,
    "out:Running cost/(Apt SEK y)": 15147.315789,
    "out:Running Cost over RSP/MSEK": 4.48954,
    "out:LCP/MSEK": 0.811171,
    "out:ROI% old": 18.61389,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 1849.4588,
    "out:Return %": 17,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.82134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184509.879948,
    "out:EL kWh savings": -57496.185396,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69517.51,
    "out:DH kr savings": 184509.87994781812,
    "out:El kr savings": -114992.37079123434,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 145379.029431,
    "out:% savings (space heating)": 48.98253,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 59.15,
    "out:Etvv": 0,
    "out:Ef": 27.17,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2677378.5468706023,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": 3.487762,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.487762,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.42548,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.262077,
    "out:Electricity (inc PV)": 26.897967,
    "out:Total Energy Use Pre PV": 84.262077,
    "out:Total Energy Use Post PV": 84.897967,
    "out:Primary Energy": 96.409777,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.44819,
    "out:CO2 Operational/m2": 24.345876,
    "out:Total CO2/m2": 28.794066,
    "out:Total CO2 (tons)": 75.699582,
    "out:Klimatpaverkan": -44.439491,
    "out:Initial Cost/MSEK": 2.839333559370602,
    "out:Running cost/(Apt SEK y)": 15752.157895,
    "out:Running Cost over RSP/MSEK": 4.66939,
    "out:LCP/MSEK": 0.469366,
    "out:ROI% old": 16.647267,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 1838.34539,
    "out:Return %": 15,
    "out:Return/kSEK/y": 422,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.662577,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 176288.357339,
    "out:EL kWh savings": -56781.042349,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62726.27,
    "out:DH kr savings": 176288.35733878275,
    "out:El kr savings": -113562.08469802736,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 153600.55204,
    "out:% savings (space heating)": 46.097374,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 61.71,
    "out:Etvv": 0,
    "out:Ef": 26.9,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2839333.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": 3.466804,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.466804,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.071002,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.171275,
    "out:Electricity (inc PV)": 26.818624,
    "out:Total Energy Use Pre PV": 80.171275,
    "out:Total Energy Use Post PV": 80.818624,
    "out:Primary Energy": 92.850727,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.44819,
    "out:CO2 Operational/m2": 22.893954,
    "out:Total CO2/m2": 27.342144,
    "out:Total CO2 (tons)": 71.88248,
    "out:Klimatpaverkan": -48.256593,
    "out:Initial Cost/MSEK": 2.9050585468706025,
    "out:Running cost/(Apt SEK y)": 14903.842105,
    "out:Running Cost over RSP/MSEK": 4.41729,
    "out:LCP/MSEK": 0.655741,
    "out:ROI% old": 17.510343,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 1831.98925,
    "out:Return %": 16,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.571775,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 187736.277401,
    "out:EL kWh savings": -56572.449649,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74591.38,
    "out:DH kr savings": 187736.2774011594,
    "out:El kr savings": -113144.89929876293,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 142152.631978,
    "out:% savings (space heating)": 50.114761,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 57.31,
    "out:Etvv": 0,
    "out:Ef": 26.82,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2905058.5468706023,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": 3.454818,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.454818,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.667938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.50703,
    "out:Electricity (inc PV)": 19.37673,
    "out:Total Energy Use Pre PV": 86.50703,
    "out:Total Energy Use Post PV": 79.37673,
    "out:Primary Energy": 84.315917,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.302889,
    "out:CO2 Operational/m2": -3.310554,
    "out:Total CO2/m2": 14.992335,
    "out:Total CO2 (tons)": 39.41484,
    "out:Klimatpaverkan": -80.724234,
    "out:Initial Cost/MSEK": 3.256636795881852,
    "out:Running cost/(Apt SEK y)": 14568.526316,
    "out:Running Cost over RSP/MSEK": 4.33048,
    "out:LCP/MSEK": 0.390973,
    "out:ROI% old": 16.000776,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1327.48235,
    "out:Return %": 14,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.90753,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14339.142773,
    "out:PV (% sold (El))": 41.374225,
    "out:PV (kWh self-consumed)": 20318.044588,
    "out:PV (ratio sold/self-consumed)": 0.705734,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 173021.935999,
    "out:EL kWh savings": -37007.714766,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120515.22,
    "out:DH kr savings": 173021.93599853019,
    "out:El kr savings": -74015.42953238434,
    "out:El kr sold": 21508.714159518793,
    "out:El kr saved": 40636.089176,
    "out:El kr return": 62144.803335518794,
    "out:% solar/total": 146,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 156866.97338,
    "out:% savings (space heating)": 44.951097,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.56,
    "out:Etvv": 0,
    "out:Ef": 19.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3256636.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -24.909594,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.394334,
    "out:Bought CO2": 2.48474,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.298236,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.42084,
    "out:Electricity (inc PV)": 19.316344,
    "out:Total Energy Use Pre PV": 81.42084,
    "out:Total Energy Use Post PV": 74.316344,
    "out:Primary Energy": 80.778123,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.302889,
    "out:CO2 Operational/m2": -5.203998,
    "out:Total CO2/m2": 13.098891,
    "out:Total CO2 (tons)": 34.436977,
    "out:Klimatpaverkan": -85.702097,
    "out:Initial Cost/MSEK": 3.3223617833818517,
    "out:Running cost/(Apt SEK y)": 13512.473684,
    "out:Running Cost over RSP/MSEK": 4.01663,
    "out:LCP/MSEK": 0.639098,
    "out:ROI% old": 17.033752,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 1322.546767,
    "out:Return %": 15,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.82134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14382.218616,
    "out:PV (% sold (El))": 41.498517,
    "out:PV (kWh self-consumed)": 20274.968745,
    "out:PV (ratio sold/self-consumed)": 0.709358,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 184509.879948,
    "out:EL kWh savings": -36848.960008,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132385.29,
    "out:DH kr savings": 184509.87994781812,
    "out:El kr savings": -73697.92001648527,
    "out:El kr sold": 21573.32792395773,
    "out:El kr saved": 40549.93749,
    "out:El kr return": 62123.26541395773,
    "out:% solar/total": 146,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 145379.029431,
    "out:% savings (space heating)": 48.98253,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 59.15,
    "out:Etvv": 0,
    "out:Ef": 19.32,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3322361.783381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -25.003118,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.478626,
    "out:Bought CO2": 2.475508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.42548,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.262077,
    "out:Electricity (inc PV)": 19.095494,
    "out:Total Energy Use Pre PV": 84.262077,
    "out:Total Energy Use Post PV": 77.095494,
    "out:Primary Energy": 82.365325,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.01799,
    "out:CO2 Operational/m2": -4.296628,
    "out:Total CO2/m2": 14.721362,
    "out:Total CO2 (tons)": 38.702452,
    "out:Klimatpaverkan": -81.436622,
    "out:Initial Cost/MSEK": 3.484316795881852,
    "out:Running cost/(Apt SEK y)": 14118.368421,
    "out:Running Cost over RSP/MSEK": 4.19679,
    "out:LCP/MSEK": 0.296983,
    "out:ROI% old": 15.503347,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 1313.461817,
    "out:Return %": 14,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.662577,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14461.675271,
    "out:PV (% sold (El))": 41.727781,
    "out:PV (kWh self-consumed)": 20195.51209,
    "out:PV (ratio sold/self-consumed)": 0.716084,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 176288.357339,
    "out:EL kWh savings": -36268.34549,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125444.18,
    "out:DH kr savings": 176288.35733878275,
    "out:El kr savings": -72536.69098018017,
    "out:El kr sold": 21692.512906686447,
    "out:El kr saved": 40391.02418,
    "out:El kr return": 62083.53708668645,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 153600.55204,
    "out:% savings (space heating)": 46.097374,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 61.71,
    "out:Etvv": 0,
    "out:Ef": 19.1,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3484316.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": -25.1757,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.634212,
    "out:Bought CO2": 2.458512,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.071002,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.171275,
    "out:Electricity (inc PV)": 19.031155,
    "out:Total Energy Use Pre PV": 80.171275,
    "out:Total Energy Use Post PV": 73.031155,
    "out:Primary Energy": 78.833283,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.01799,
    "out:CO2 Operational/m2": -5.835462,
    "out:Total CO2/m2": 13.182528,
    "out:Total CO2 (tons)": 34.656858,
    "out:Klimatpaverkan": -85.482215,
    "out:Initial Cost/MSEK": 3.5500417833818525,
    "out:Running cost/(Apt SEK y)": 13270.657895,
    "out:Running Cost over RSP/MSEK": 3.94487,
    "out:LCP/MSEK": 0.483178,
    "out:ROI% old": 16.230071,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 1308.269411,
    "out:Return %": 15,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.571775,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14507.232034,
    "out:PV (% sold (El))": 41.859231,
    "out:PV (kWh self-consumed)": 20149.955327,
    "out:PV (ratio sold/self-consumed)": 0.719963,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 187736.277401,
    "out:EL kWh savings": -36099.198298,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137298.73,
    "out:DH kr savings": 187736.2774011594,
    "out:El kr savings": -72198.39659500084,
    "out:El kr sold": 21760.84805093268,
    "out:El kr saved": 40299.910654,
    "out:El kr return": 62060.75870493268,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 142152.631978,
    "out:% savings (space heating)": 50.114761,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 57.31,
    "out:Etvv": 0,
    "out:Ef": 19.03,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3550041.7833818523,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -25.274598,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.723395,
    "out:Bought CO2": 2.448797,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.667938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.50703,
    "out:Electricity (inc PV)": 17.64145,
    "out:Total Energy Use Pre PV": 86.50703,
    "out:Total Energy Use Post PV": 77.64145,
    "out:Primary Energy": 81.192413,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.872689,
    "out:CO2 Operational/m2": -62.046052,
    "out:Total CO2/m2": -29.173363,
    "out:Total CO2 (tons)": -76.696754,
    "out:Klimatpaverkan": -196.835827,
    "out:Initial Cost/MSEK": 3.901620014930602,
    "out:Running cost/(Apt SEK y)": 13142.657895,
    "out:Running Cost over RSP/MSEK": 3.91837,
    "out:LCP/MSEK": 0.1581,
    "out:ROI% old": 14.864595,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1207.807933,
    "out:Return %": 13,
    "out:Return/kSEK/y": 521,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.90753,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44601.642971,
    "out:PV (% sold (El))": 64.346888,
    "out:PV (kWh self-consumed)": 24712.73175,
    "out:PV (ratio sold/self-consumed)": 1.804804,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 173021.935999,
    "out:EL kWh savings": -32445.664682,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175033.07,
    "out:DH kr savings": 173021.93599853019,
    "out:El kr savings": -64891.32936430865,
    "out:El kr sold": 66902.46445721152,
    "out:El kr saved": 49425.4635,
    "out:El kr return": 116327.92795721152,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 156866.97338,
    "out:% savings (space heating)": 44.951097,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.56,
    "out:Etvv": 0,
    "out:Ef": 17.64,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3901620.014930602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -83.645092,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -85.907911,
    "out:Bought CO2": 2.262819,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.298236,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.42084,
    "out:Electricity (inc PV)": 17.587442,
    "out:Total Energy Use Pre PV": 81.42084,
    "out:Total Energy Use Post PV": 72.587442,
    "out:Primary Energy": 77.6661,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.872689,
    "out:CO2 Operational/m2": -63.977069,
    "out:Total CO2/m2": -31.10438,
    "out:Total CO2 (tons)": -81.773396,
    "out:Klimatpaverkan": -201.91247,
    "out:Initial Cost/MSEK": 3.9673450024306023,
    "out:Running cost/(Apt SEK y)": 12086.868421,
    "out:Running Cost over RSP/MSEK": 3.6046,
    "out:LCP/MSEK": 0.406145,
    "out:ROI% old": 15.748171,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 1203.392718,
    "out:Return %": 14,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.82134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44664.462962,
    "out:PV (% sold (El))": 64.437518,
    "out:PV (kWh self-consumed)": 24649.911759,
    "out:PV (ratio sold/self-consumed)": 1.811952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 184509.879948,
    "out:EL kWh savings": -32303.677682,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186899.22,
    "out:DH kr savings": 184509.87994781812,
    "out:El kr savings": -64607.355364794224,
    "out:El kr sold": 66996.69444359619,
    "out:El kr saved": 49299.823518,
    "out:El kr return": 116296.5179615962,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 145379.029431,
    "out:% savings (space heating)": 48.98253,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 59.15,
    "out:Etvv": 0,
    "out:Ef": 17.59,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3967345.0024306024,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -83.776189,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.030759,
    "out:Bought CO2": 2.25457,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.42548,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.262077,
    "out:Electricity (inc PV)": 17.390054,
    "out:Total Energy Use Pre PV": 84.262077,
    "out:Total Energy Use Post PV": 75.390054,
    "out:Primary Energy": 79.295533,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.58779,
    "out:CO2 Operational/m2": -63.139097,
    "out:Total CO2/m2": -29.551307,
    "out:Total CO2 (tons)": -77.690368,
    "out:Klimatpaverkan": -197.829442,
    "out:Initial Cost/MSEK": 4.129300014930602,
    "out:Running cost/(Apt SEK y)": 12693.236842,
    "out:Running Cost over RSP/MSEK": 3.7849,
    "out:LCP/MSEK": 0.06389,
    "out:ROI% old": 14.506748,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 73.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 1195.266414,
    "out:Return %": 13,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.662577,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44780.362355,
    "out:PV (% sold (El))": 64.604727,
    "out:PV (kWh self-consumed)": 24534.012366,
    "out:PV (ratio sold/self-consumed)": 1.825236,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 176288.357339,
    "out:EL kWh savings": -31784.744748,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179889.41,
    "out:DH kr savings": 176288.35733878275,
    "out:El kr savings": -63569.48949647547,
    "out:El kr sold": 67170.54353313537,
    "out:El kr saved": 49068.024732,
    "out:El kr return": 116238.56826513537,
    "out:% solar/total": 176,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 153600.55204,
    "out:% savings (space heating)": 46.097374,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 61.71,
    "out:Etvv": 0,
    "out:Ef": 17.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4129300.014930602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": -84.018169,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.257556,
    "out:Bought CO2": 2.239387,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.071002,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.171275,
    "out:Electricity (inc PV)": 17.332558,
    "out:Total Energy Use Pre PV": 80.171275,
    "out:Total Energy Use Post PV": 71.332558,
    "out:Primary Energy": 75.775808,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.58779,
    "out:CO2 Operational/m2": -64.717723,
    "out:Total CO2/m2": -31.129933,
    "out:Total CO2 (tons)": -81.840575,
    "out:Klimatpaverkan": -201.979649,
    "out:Initial Cost/MSEK": 4.195025002430602,
    "out:Running cost/(Apt SEK y)": 11845.789474,
    "out:Running Cost over RSP/MSEK": 3.53306,
    "out:LCP/MSEK": 0.250005,
    "out:ROI% old": 15.13708,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 1190.622624,
    "out:Return %": 14,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.571775,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44846.809934,
    "out:PV (% sold (El))": 64.700591,
    "out:PV (kWh self-consumed)": 24467.564787,
    "out:PV (ratio sold/self-consumed)": 1.832909,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 187736.277401,
    "out:EL kWh savings": -31633.587799,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191739.32,
    "out:DH kr savings": 187736.2774011594,
    "out:El kr savings": -63267.17559712577,
    "out:El kr sold": 67270.21490164252,
    "out:El kr saved": 48935.129574,
    "out:El kr return": 116205.34447564252,
    "out:% solar/total": 176,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 142152.631978,
    "out:% savings (space heating)": 50.114761,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 57.31,
    "out:Etvv": 0,
    "out:Ef": 17.33,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4195025.002430602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -84.156859,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.387571,
    "out:Bought CO2": 2.230712,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.071452,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.238692,
    "out:Electricity (inc PV)": 40.642,
    "out:Total Energy Use Pre PV": 60.238692,
    "out:Total Energy Use Post PV": 61.642,
    "out:Primary Energy": 92.094905,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.1721,
    "out:CO2 Operational/m2": 12.739485,
    "out:Total CO2/m2": 16.911585,
    "out:Total CO2 (tons)": 44.460547,
    "out:Klimatpaverkan": -75.678527,
    "out:Initial Cost/MSEK": 3.572109684370602,
    "out:Running cost/(Apt SEK y)": 9817.052632,
    "out:Running Cost over RSP/MSEK": 2.89912,
    "out:LCP/MSEK": 1.50686,
    "out:ROI% old": 20.312006,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 2746.70844,
    "out:Return %": 18,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 31.639192,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 274492.07465,
    "out:EL kWh savings": -92914.096901,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88663.88,
    "out:DH kr savings": 274492.074650428,
    "out:El kr savings": -185828.19380165206,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 55396.834728,
    "out:% savings (space heating)": 80.559739,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 24.35,
    "out:Etvv": 0,
    "out:Ef": 40.64,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3572109.684370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.559664,
    "out:EL CO2": 5.179821,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.179821,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.690591,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.852854,
    "out:Electricity (inc PV)": 40.308482,
    "out:Total Energy Use Pre PV": 56.852854,
    "out:Total Energy Use Post PV": 58.308482,
    "out:Primary Energy": 88.730728,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.1721,
    "out:CO2 Operational/m2": 11.6086,
    "out:Total CO2/m2": 15.7807,
    "out:Total CO2 (tons)": 41.487451,
    "out:Klimatpaverkan": -78.651623,
    "out:Initial Cost/MSEK": 3.6378346718706025,
    "out:Running cost/(Apt SEK y)": 9136.868421,
    "out:Running Cost over RSP/MSEK": 2.69714,
    "out:LCP/MSEK": 1.643115,
    "out:ROI% old": 20.7382,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 2719.69978,
    "out:Return %": 19,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 31.253354,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283380.356201,
    "out:EL kWh savings": -92037.278278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99305.8,
    "out:DH kr savings": 283380.35620105406,
    "out:El kr savings": -184074.55655587252,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 46508.553178,
    "out:% savings (space heating)": 83.678879,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 20.8,
    "out:Etvv": 0,
    "out:Ef": 40.31,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3637834.6718706023,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.479712,
    "out:EL CO2": 5.128888,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.128888,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.053379,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.770381,
    "out:Electricity (inc PV)": 39.984463,
    "out:Total Energy Use Pre PV": 58.770381,
    "out:Total Energy Use Post PV": 59.984463,
    "out:Primary Energy": 89.682351,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.887201,
    "out:CO2 Operational/m2": 12.31768,
    "out:Total CO2/m2": 17.204881,
    "out:Total CO2 (tons)": 45.231622,
    "out:Klimatpaverkan": -74.907452,
    "out:Initial Cost/MSEK": 3.799789684370602,
    "out:Running cost/(Apt SEK y)": 9543.315789,
    "out:Running Cost over RSP/MSEK": 2.81799,
    "out:LCP/MSEK": 1.36031,
    "out:ROI% old": 19.399946,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2713.92667,
    "out:Return %": 17,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 31.170881,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 277168.58796,
    "out:EL kWh savings": -91185.43252,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94797.72,
    "out:DH kr savings": 277168.5879596385,
    "out:El kr savings": -182370.86504075114,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 52720.321419,
    "out:% savings (space heating)": 81.499,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 22.77,
    "out:Etvv": 0,
    "out:Ef": 39.98,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3799789.684370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": 5.118,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.118,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.736214,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.365241,
    "out:Electricity (inc PV)": 39.627639,
    "out:Total Energy Use Pre PV": 55.365241,
    "out:Total Energy Use Post PV": 56.627639,
    "out:Primary Energy": 86.33604,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.887201,
    "out:CO2 Operational/m2": 11.184247,
    "out:Total CO2/m2": 16.071448,
    "out:Total CO2 (tons)": 42.251827,
    "out:Klimatpaverkan": -77.887246,
    "out:Initial Cost/MSEK": 3.8655146718706024,
    "out:Running cost/(Apt SEK y)": 8860.447368,
    "out:Running Cost over RSP/MSEK": 2.61521,
    "out:LCP/MSEK": 1.497365,
    "out:ROI% old": 19.819501,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 128.1,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2685.56687,
    "out:Return %": 18,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 30.765741,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 285889.412764,
    "out:EL kWh savings": -90247.342437,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105394.73,
    "out:DH kr savings": 285889.412764291,
    "out:El kr savings": -180494.68487479904,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 43999.496614,
    "out:% savings (space heating)": 84.559376,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 19.29,
    "out:Etvv": 0,
    "out:Ef": 39.63,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3865514.6718706023,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": 5.064519,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.064519,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.071452,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.238692,
    "out:Electricity (inc PV)": 30.623478,
    "out:Total Energy Use Pre PV": 60.238692,
    "out:Total Energy Use Post PV": 51.623478,
    "out:Primary Energy": 74.061565,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.7419,
    "out:CO2 Operational/m2": -5.210539,
    "out:Total CO2/m2": 13.531361,
    "out:Total CO2 (tons)": 35.57394,
    "out:Klimatpaverkan": -84.565134,
    "out:Initial Cost/MSEK": 4.217092920881853,
    "out:Running cost/(Apt SEK y)": 8109.105263,
    "out:Running Cost over RSP/MSEK": 2.40503,
    "out:LCP/MSEK": 1.355967,
    "out:ROI% old": 18.879153,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 150,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 2079.898922,
    "out:Return %": 17,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 31.639192,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 8824.823627,
    "out:PV (% sold (El))": 25.463185,
    "out:PV (kWh self-consumed)": 25832.363734,
    "out:PV (ratio sold/self-consumed)": 0.341619,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 274492.07465,
    "out:EL kWh savings": -66575.408544,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154578.49,
    "out:DH kr savings": 274492.074650428,
    "out:El kr savings": -133150.8170877672,
    "out:El kr sold": 13237.2354404926,
    "out:El kr saved": 51664.727468,
    "out:El kr return": 64901.9629084926,
    "out:% solar/total": 185,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 55396.834728,
    "out:% savings (space heating)": 80.559739,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 24.35,
    "out:Etvv": 0,
    "out:Ef": 30.62,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4217092.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.559664,
    "out:EL CO2": -12.770203,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.662303,
    "out:Bought CO2": 3.8921,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.690591,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.852854,
    "out:Electricity (inc PV)": 30.335064,
    "out:Total Energy Use Pre PV": 56.852854,
    "out:Total Energy Use Post PV": 48.335064,
    "out:Primary Energy": 70.778575,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.7419,
    "out:CO2 Operational/m2": -6.610821,
    "out:Total CO2/m2": 12.131079,
    "out:Total CO2 (tons)": 31.892599,
    "out:Klimatpaverkan": -88.246474,
    "out:Initial Cost/MSEK": 4.282817908381852,
    "out:Running cost/(Apt SEK y)": 7430.789474,
    "out:Running Cost over RSP/MSEK": 2.20358,
    "out:LCP/MSEK": 1.491692,
    "out:ROI% old": 19.261384,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 170.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 2056.422267,
    "out:Return %": 17,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 31.253354,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 8967.610548,
    "out:PV (% sold (El))": 25.875183,
    "out:PV (kWh self-consumed)": 25689.576813,
    "out:PV (ratio sold/self-consumed)": 0.349076,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283380.356201,
    "out:EL kWh savings": -65817.16831,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165197.44,
    "out:DH kr savings": 283380.35620105406,
    "out:El kr savings": -131634.33662013354,
    "out:El kr sold": 13451.415821327068,
    "out:El kr saved": 51379.153626,
    "out:El kr return": 64830.56944732707,
    "out:% solar/total": 184,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 46508.553178,
    "out:% savings (space heating)": 83.678879,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 20.8,
    "out:Etvv": 0,
    "out:Ef": 30.34,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4282817.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.479712,
    "out:EL CO2": -13.090533,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.93874,
    "out:Bought CO2": 3.848207,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.053379,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.770381,
    "out:Electricity (inc PV)": 30.055227,
    "out:Total Energy Use Pre PV": 58.770381,
    "out:Total Energy Use Post PV": 50.055227,
    "out:Primary Energy": 71.809727,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.457001,
    "out:CO2 Operational/m2": -5.95971,
    "out:Total CO2/m2": 13.497291,
    "out:Total CO2 (tons)": 35.48437,
    "out:Klimatpaverkan": -84.654704,
    "out:Initial Cost/MSEK": 4.444772920881853,
    "out:Running cost/(Apt SEK y)": 7837.657895,
    "out:Running Cost over RSP/MSEK": 2.32455,
    "out:LCP/MSEK": 1.208767,
    "out:ROI% old": 18.17075,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 160,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2051.408915,
    "out:Return %": 16,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 31.170881,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 8998.331773,
    "out:PV (% sold (El))": 25.963826,
    "out:PV (kWh self-consumed)": 25658.855588,
    "out:PV (ratio sold/self-consumed)": 0.350691,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 277168.58796,
    "out:EL kWh savings": -65081.477004,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160503.13,
    "out:DH kr savings": 277168.5879596385,
    "out:El kr savings": -130162.95400825897,
    "out:El kr sold": 13497.497659164323,
    "out:El kr saved": 51317.711176,
    "out:El kr return": 64815.20883516432,
    "out:% solar/total": 184,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 52720.321419,
    "out:% savings (space heating)": 81.499,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 22.77,
    "out:Etvv": 0,
    "out:Ef": 30.06,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4444772.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -13.15939,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.998225,
    "out:Bought CO2": 3.838835,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.736214,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.365241,
    "out:Electricity (inc PV)": 29.747577,
    "out:Total Energy Use Pre PV": 55.365241,
    "out:Total Energy Use Post PV": 46.747577,
    "out:Primary Energy": 68.551929,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.457001,
    "out:CO2 Operational/m2": -7.380003,
    "out:Total CO2/m2": 12.076998,
    "out:Total CO2 (tons)": 31.750421,
    "out:Klimatpaverkan": -88.388653,
    "out:Initial Cost/MSEK": 4.510497908381852,
    "out:Running cost/(Apt SEK y)": 7156.789474,
    "out:Running Cost over RSP/MSEK": 2.12235,
    "out:LCP/MSEK": 1.345242,
    "out:ROI% old": 18.546385,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 176.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2026.807227,
    "out:Return %": 17,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 30.765741,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9150.544765,
    "out:PV (% sold (El))": 26.403022,
    "out:PV (kWh self-consumed)": 25506.642596,
    "out:PV (ratio sold/self-consumed)": 0.358751,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 285889.412764,
    "out:EL kWh savings": -64272.665338,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171069.9,
    "out:DH kr savings": 285889.412764291,
    "out:El kr savings": -128545.33067559308,
    "out:El kr sold": 13725.817147665563,
    "out:El kr saved": 51013.285192,
    "out:El kr return": 64739.102339665566,
    "out:% solar/total": 183,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 43999.496614,
    "out:% savings (space heating)": 84.559376,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 19.29,
    "out:Etvv": 0,
    "out:Ef": 29.75,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4510497.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": -13.499731,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.292582,
    "out:Bought CO2": 3.792851,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.071452,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.238692,
    "out:Electricity (inc PV)": 27.723954,
    "out:Total Energy Use Pre PV": 60.238692,
    "out:Total Energy Use Post PV": 48.723954,
    "out:Primary Energy": 68.842422,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.3117,
    "out:CO2 Operational/m2": -58.364286,
    "out:Total CO2/m2": -25.052586,
    "out:Total CO2 (tons)": -65.863234,
    "out:Klimatpaverkan": -186.002307,
    "out:Initial Cost/MSEK": 4.862076139930602,
    "out:Running cost/(Apt SEK y)": 6645.078947,
    "out:Running Cost over RSP/MSEK": 1.9817,
    "out:LCP/MSEK": 1.134314,
    "out:ROI% old": 17.618546,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 165.3,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 1882.508225,
    "out:Return %": 16,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 31.639192,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 36186.821091,
    "out:PV (% sold (El))": 52.206806,
    "out:PV (kWh self-consumed)": 33127.553631,
    "out:PV (ratio sold/self-consumed)": 1.092348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 274492.07465,
    "out:EL kWh savings": -58952.561679,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210867.18,
    "out:DH kr savings": 274492.074650428,
    "out:El kr savings": -117905.12335779895,
    "out:El kr sold": 54280.23163620877,
    "out:El kr saved": 66255.107262,
    "out:El kr return": 120535.33889820878,
    "out:% solar/total": 238,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 55396.834728,
    "out:% savings (space heating)": 80.559739,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 24.35,
    "out:Etvv": 0,
    "out:Ef": 27.72,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4862076.139930602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.559664,
    "out:EL CO2": -65.92395,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.448297,
    "out:Bought CO2": 3.524347,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.690591,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.852854,
    "out:Electricity (inc PV)": 27.465081,
    "out:Total Energy Use Pre PV": 56.852854,
    "out:Total Energy Use Post PV": 45.465081,
    "out:Primary Energy": 65.612606,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.3117,
    "out:CO2 Operational/m2": -59.937657,
    "out:Total CO2/m2": -26.625957,
    "out:Total CO2 (tons)": -69.999625,
    "out:Klimatpaverkan": -190.138699,
    "out:Initial Cost/MSEK": 4.927801127430602,
    "out:Running cost/(Apt SEK y)": 5967.947368,
    "out:Running Cost over RSP/MSEK": 1.7806,
    "out:LCP/MSEK": 1.269689,
    "out:ROI% old": 17.966547,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 188.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 1861.440224,
    "out:Return %": 16,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 31.253354,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 36419.529664,
    "out:PV (% sold (El))": 52.542535,
    "out:PV (kWh self-consumed)": 32894.845058,
    "out:PV (ratio sold/self-consumed)": 1.10715,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283380.356201,
    "out:EL kWh savings": -58271.984716,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221465.68,
    "out:DH kr savings": 283380.35620105406,
    "out:El kr savings": -116543.9694328933,
    "out:El kr sold": 54629.29449550272,
    "out:El kr saved": 65789.690116,
    "out:El kr return": 120418.98461150272,
    "out:% solar/total": 236,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 46508.553178,
    "out:% savings (space heating)": 83.678879,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 20.8,
    "out:Etvv": 0,
    "out:Ef": 27.47,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4927801.127430602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.479712,
    "out:EL CO2": -66.417369,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.902346,
    "out:Bought CO2": 3.484977,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.053379,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.770381,
    "out:Electricity (inc PV)": 27.214076,
    "out:Total Energy Use Pre PV": 58.770381,
    "out:Total Energy Use Post PV": 47.214076,
    "out:Primary Energy": 66.695655,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.026801,
    "out:CO2 Operational/m2": -59.323543,
    "out:Total CO2/m2": -25.296742,
    "out:Total CO2 (tons)": -66.50512,
    "out:Klimatpaverkan": -186.644193,
    "out:Initial Cost/MSEK": 5.089756139930602,
    "out:Running cost/(Apt SEK y)": 6375.052632,
    "out:Running Cost over RSP/MSEK": 1.90164,
    "out:LCP/MSEK": 0.986694,
    "out:ROI% old": 17.055125,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 176.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 1856.941549,
    "out:Return %": 15,
    "out:Return/kSEK/y": 778,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 31.170881,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 36469.495172,
    "out:PV (% sold (El))": 52.61462,
    "out:PV (kWh self-consumed)": 32844.879549,
    "out:PV (ratio sold/self-consumed)": 1.110356,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 277168.58796,
    "out:EL kWh savings": -57612.092721,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216648.65,
    "out:DH kr savings": 277168.5879596385,
    "out:El kr savings": -115224.1854425932,
    "out:El kr sold": 54704.24275861206,
    "out:El kr saved": 65689.759098,
    "out:El kr return": 120394.00185661206,
    "out:% solar/total": 236,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 52720.321419,
    "out:% savings (space heating)": 81.499,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 22.77,
    "out:Etvv": 0,
    "out:Ef": 27.21,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5089756.139930602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -66.523223,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.999794,
    "out:Bought CO2": 3.476571,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.736214,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.365241,
    "out:Electricity (inc PV)": 26.938172,
    "out:Total Energy Use Pre PV": 55.365241,
    "out:Total Energy Use Post PV": 43.938172,
    "out:Primary Energy": 63.495,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.026801,
    "out:CO2 Operational/m2": -60.925387,
    "out:Total CO2/m2": -26.898586,
    "out:Total CO2 (tons)": -70.716367,
    "out:Klimatpaverkan": -190.85544,
    "out:Initial Cost/MSEK": 5.155481127430602,
    "out:Running cost/(Apt SEK y)": 5695.421053,
    "out:Running Cost over RSP/MSEK": 1.69981,
    "out:LCP/MSEK": 1.122799,
    "out:ROI% old": 17.396963,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 195.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 1834.867004,
    "out:Return %": 16,
    "out:Return/kSEK/y": 804,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 30.765741,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 36715.725878,
    "out:PV (% sold (El))": 52.969858,
    "out:PV (kWh self-consumed)": 32598.648844,
    "out:PV (ratio sold/self-consumed)": 1.126296,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 285889.412764,
    "out:EL kWh savings": -56886.74127,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227189.52,
    "out:DH kr savings": 285889.412764291,
    "out:El kr savings": -113773.48254002267,
    "out:El kr sold": 55073.588816495016,
    "out:El kr saved": 65197.297688,
    "out:El kr return": 120270.88650449502,
    "out:% solar/total": 234,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 43999.496614,
    "out:% savings (space heating)": 84.559376,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 19.29,
    "out:Etvv": 0,
    "out:Ef": 26.94,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5155481.127430602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": -67.045115,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.480445,
    "out:Bought CO2": 3.43533,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.720129,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.612426,
    "out:Electricity (inc PV)": 39.76224,
    "out:Total Energy Use Pre PV": 58.612426,
    "out:Total Energy Use Post PV": 59.76224,
    "out:Primary Energy": 88.880499,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.1721,
    "out:CO2 Operational/m2": 12.296829,
    "out:Total CO2/m2": 16.468929,
    "out:Total CO2 (tons)": 43.296805,
    "out:Klimatpaverkan": -76.842269,
    "out:Initial Cost/MSEK": 3.672403559370602,
    "out:Running cost/(Apt SEK y)": 9521.473684,
    "out:Running Cost over RSP/MSEK": 2.81157,
    "out:LCP/MSEK": 1.494116,
    "out:ROI% old": 20.097853,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2702.86982,
    "out:Return %": 18,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 31.012926,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278044.702011,
    "out:EL kWh savings": -90601.208386,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96842.29,
    "out:DH kr savings": 278044.70201068826,
    "out:El kr savings": -181202.4167720854,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 51844.207368,
    "out:% savings (space heating)": 81.806452,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 22.25,
    "out:Etvv": 0,
    "out:Ef": 39.76,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3672403.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": 5.097149,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.097149,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.423919,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.200936,
    "out:Electricity (inc PV)": 39.396907,
    "out:Total Energy Use Pre PV": 54.200936,
    "out:Total Energy Use Post PV": 55.396907,
    "out:Primary Energy": 85.540713,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.1721,
    "out:CO2 Operational/m2": 10.802573,
    "out:Total CO2/m2": 14.974673,
    "out:Total CO2 (tons)": 39.368406,
    "out:Klimatpaverkan": -80.770667,
    "out:Initial Cost/MSEK": 3.7381285468706023,
    "out:Running cost/(Apt SEK y)": 8628.789474,
    "out:Running Cost over RSP/MSEK": 2.54643,
    "out:LCP/MSEK": 1.693531,
    "out:ROI% old": 20.757751,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 2674.06552,
    "out:Return %": 19,
    "out:Return/kSEK/y": 692,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 30.601436,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 286710.436133,
    "out:EL kWh savings": -89640.748147,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107428.94,
    "out:DH kr savings": 286710.4361328509,
    "out:El kr savings": -179281.49629429303,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 43178.473246,
    "out:% savings (space heating)": 84.847495,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 18.81,
    "out:Etvv": 0,
    "out:Ef": 39.4,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3738128.5468706023,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.759744,
    "out:EL CO2": 5.042829,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.042829,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.930548,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.230239,
    "out:Electricity (inc PV)": 39.222382,
    "out:Total Energy Use Pre PV": 57.230239,
    "out:Total Energy Use Post PV": 58.222382,
    "out:Primary Energy": 86.961057,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.887201,
    "out:CO2 Operational/m2": 11.886393,
    "out:Total CO2/m2": 16.773594,
    "out:Total CO2 (tons)": 44.097769,
    "out:Klimatpaverkan": -76.041305,
    "out:Initial Cost/MSEK": 3.900083559370602,
    "out:Running cost/(Apt SEK y)": 9259.657895,
    "out:Running Cost over RSP/MSEK": 2.73393,
    "out:LCP/MSEK": 1.344076,
    "out:ROI% old": 19.208966,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 2676.11673,
    "out:Return %": 17,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 30.630739,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 280120.509988,
    "out:EL kWh savings": -89181.922026,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101756.67,
    "out:DH kr savings": 280120.50998830836,
    "out:El kr savings": -178363.84405267588,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 49768.39939,
    "out:% savings (space heating)": 82.53491,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 21.04,
    "out:Etvv": 0,
    "out:Ef": 39.22,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3900083.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.839696,
    "out:EL CO2": 5.046697,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.046697,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.687211,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.802727,
    "out:Electricity (inc PV)": 38.835874,
    "out:Total Energy Use Pre PV": 53.802727,
    "out:Total Energy Use Post PV": 54.835874,
    "out:Primary Energy": 83.637503,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.887201,
    "out:CO2 Operational/m2": 10.750006,
    "out:Total CO2/m2": 15.637207,
    "out:Total CO2 (tons)": 41.110208,
    "out:Klimatpaverkan": -79.028866,
    "out:Initial Cost/MSEK": 3.965808546870602,
    "out:Running cost/(Apt SEK y)": 8573.684211,
    "out:Running Cost over RSP/MSEK": 2.53025,
    "out:LCP/MSEK": 1.482031,
    "out:ROI% old": 19.624317,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 2646.19089,
    "out:Return %": 18,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 30.203227,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288647.241025,
    "out:EL kWh savings": -88165.792725,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112315.66,
    "out:DH kr savings": 288647.24102503614,
    "out:El kr savings": -176331.58545016643,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 41241.668354,
    "out:% savings (space heating)": 85.527173,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 17.66,
    "out:Etvv": 0,
    "out:Ef": 38.84,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3965808.5468706023,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.759744,
    "out:EL CO2": 4.990262,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.990262,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.720129,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.612426,
    "out:Electricity (inc PV)": 29.863579,
    "out:Total Energy Use Pre PV": 58.612426,
    "out:Total Energy Use Post PV": 49.863579,
    "out:Primary Energy": 71.062909,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.7419,
    "out:CO2 Operational/m2": -6.091965,
    "out:Total CO2/m2": 12.649935,
    "out:Total CO2 (tons)": 33.256672,
    "out:Klimatpaverkan": -86.882402,
    "out:Initial Cost/MSEK": 4.317386795881853,
    "out:Running cost/(Apt SEK y)": 7816.578947,
    "out:Running Cost over RSP/MSEK": 2.31835,
    "out:LCP/MSEK": 1.342353,
    "out:ROI% old": 18.7274,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 160,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2041.812156,
    "out:Return %": 17,
    "out:Return/kSEK/y": 723,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 31.012926,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9057.363355,
    "out:PV (% sold (El))": 26.134156,
    "out:PV (kWh self-consumed)": 25599.824006,
    "out:PV (ratio sold/self-consumed)": 0.353806,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278044.702011,
    "out:EL kWh savings": -64577.634527,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162475.48,
    "out:DH kr savings": 278044.70201068826,
    "out:El kr savings": -129155.26905308667,
    "out:El kr sold": 13586.045032019292,
    "out:El kr saved": 51199.648012,
    "out:El kr return": 64785.69304401929,
    "out:% solar/total": 184,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 51844.207368,
    "out:% savings (space heating)": 81.806452,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 22.25,
    "out:Etvv": 0,
    "out:Ef": 29.86,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4317386.7958818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -13.291645,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.112541,
    "out:Bought CO2": 3.820896,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.423919,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.200936,
    "out:Electricity (inc PV)": 29.548877,
    "out:Total Energy Use Pre PV": 54.200936,
    "out:Total Energy Use Post PV": 45.548877,
    "out:Primary Energy": 67.814259,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.7419,
    "out:CO2 Operational/m2": -7.879052,
    "out:Total CO2/m2": 10.862848,
    "out:Total CO2 (tons)": 28.558421,
    "out:Klimatpaverkan": -91.580653,
    "out:Initial Cost/MSEK": 4.383111783381852,
    "out:Running cost/(Apt SEK y)": 6925.947368,
    "out:Running Cost over RSP/MSEK": 2.05381,
    "out:LCP/MSEK": 1.541168,
    "out:ROI% old": 19.308787,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 182.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 2016.842579,
    "out:Return %": 17,
    "out:Return/kSEK/y": 757,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 30.601436,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9212.896124,
    "out:PV (% sold (El))": 26.582931,
    "out:PV (kWh self-consumed)": 25444.291237,
    "out:PV (ratio sold/self-consumed)": 0.362081,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 286710.436133,
    "out:EL kWh savings": -63750.283156,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173029.21,
    "out:DH kr savings": 286710.4361328509,
    "out:El kr savings": -127500.56631284088,
    "out:El kr sold": 13819.344185354728,
    "out:El kr saved": 50888.582474,
    "out:El kr return": 64707.92665935473,
    "out:% solar/total": 183,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 43178.473246,
    "out:% savings (space heating)": 84.847495,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 18.81,
    "out:Etvv": 0,
    "out:Ef": 29.55,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4383111.783381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.759744,
    "out:EL CO2": -13.638796,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.413025,
    "out:Bought CO2": 3.774229,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.930548,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.230239,
    "out:Electricity (inc PV)": 29.398737,
    "out:Total Energy Use Pre PV": 57.230239,
    "out:Total Energy Use Post PV": 48.398737,
    "out:Primary Energy": 69.278496,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.457001,
    "out:CO2 Operational/m2": -6.77425,
    "out:Total CO2/m2": 12.682751,
    "out:Total CO2 (tons)": 33.342945,
    "out:Klimatpaverkan": -86.796129,
    "out:Initial Cost/MSEK": 4.545066795881852,
    "out:Running cost/(Apt SEK y)": 7556.657895,
    "out:Running Cost over RSP/MSEK": 2.24126,
    "out:LCP/MSEK": 1.191763,
    "out:ROI% old": 18.031575,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 170.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 2018.619209,
    "out:Return %": 16,
    "out:Return/kSEK/y": 733,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 30.630739,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9201.745023,
    "out:PV (% sold (El))": 26.550755,
    "out:PV (kWh self-consumed)": 25455.442337,
    "out:PV (ratio sold/self-consumed)": 0.361484,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280120.509988,
    "out:EL kWh savings": -63355.565186,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167212,
    "out:DH kr savings": 280120.50998830836,
    "out:El kr savings": -126711.13037210799,
    "out:El kr sold": 13802.61753498938,
    "out:El kr saved": 50910.884674,
    "out:El kr return": 64713.50220898938,
    "out:% solar/total": 183,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 49768.39939,
    "out:% savings (space heating)": 82.53491,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 21.04,
    "out:Etvv": 0,
    "out:Ef": 29.4,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4545066.7958818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.839696,
    "out:EL CO2": -13.613946,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.391495,
    "out:Bought CO2": 3.777549,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.687211,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.802727,
    "out:Electricity (inc PV)": 29.06666,
    "out:Total Energy Use Pre PV": 53.802727,
    "out:Total Energy Use Post PV": 45.06666,
    "out:Primary Energy": 66.052918,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.457001,
    "out:CO2 Operational/m2": -8.21871,
    "out:Total CO2/m2": 11.238291,
    "out:Total CO2 (tons)": 29.54546,
    "out:Klimatpaverkan": -90.593613,
    "out:Initial Cost/MSEK": 4.610791783381853,
    "out:Running cost/(Apt SEK y)": 6872.842105,
    "out:Running Cost over RSP/MSEK": 2.0382,
    "out:LCP/MSEK": 1.329098,
    "out:ROI% old": 18.403688,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 188.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 1992.723234,
    "out:Return %": 16,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 30.203227,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9365.466216,
    "out:PV (% sold (El))": 27.023157,
    "out:PV (kWh self-consumed)": 25291.721145,
    "out:PV (ratio sold/self-consumed)": 0.370298,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288647.241025,
    "out:EL kWh savings": -62482.534951,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177730.37,
    "out:DH kr savings": 288647.24102503614,
    "out:El kr savings": -124965.06990260791,
    "out:El kr sold": 14048.199324116507,
    "out:El kr saved": 50583.44229,
    "out:El kr return": 64631.64161411651,
    "out:% solar/total": 182,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 41241.668354,
    "out:% savings (space heating)": 85.527173,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 17.66,
    "out:Etvv": 0,
    "out:Ef": 29.07,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4610791.783381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.759744,
    "out:EL CO2": -13.978454,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.70762,
    "out:Bought CO2": 3.729166,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.720129,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.612426,
    "out:Electricity (inc PV)": 27.042201,
    "out:Total Energy Use Pre PV": 58.612426,
    "out:Total Energy Use Post PV": 47.042201,
    "out:Primary Energy": 65.984429,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.3117,
    "out:CO2 Operational/m2": -59.526659,
    "out:Total CO2/m2": -26.214959,
    "out:Total CO2 (tons)": -68.919112,
    "out:Klimatpaverkan": -189.058185,
    "out:Initial Cost/MSEK": 4.9623700149306025,
    "out:Running cost/(Apt SEK y)": 6354.473684,
    "out:Running Cost over RSP/MSEK": 1.89559,
    "out:LCP/MSEK": 1.12013,
    "out:ROI% old": 17.510354,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 176.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 1848.330356,
    "out:Return %": 16,
    "out:Return/kSEK/y": 779,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 31.012926,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 36565.371571,
    "out:PV (% sold (El))": 52.752942,
    "out:PV (kWh self-consumed)": 32749.003151,
    "out:PV (ratio sold/self-consumed)": 1.116534,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278044.702011,
    "out:EL kWh savings": -57160.233449,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218572.29,
    "out:DH kr savings": 278044.70201068826,
    "out:El kr savings": -114320.46689781202,
    "out:El kr sold": 54848.057356160425,
    "out:El kr saved": 65498.006302,
    "out:El kr return": 120346.06365816042,
    "out:% solar/total": 235,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 51844.207368,
    "out:% savings (space heating)": 81.806452,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 22.25,
    "out:Etvv": 0,
    "out:Ef": 27.04,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4962370.014930602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -66.726339,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.186822,
    "out:Bought CO2": 3.460483,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.423919,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.200936,
    "out:Electricity (inc PV)": 26.759961,
    "out:Total Energy Use Pre PV": 54.200936,
    "out:Total Energy Use Post PV": 42.759961,
    "out:Primary Energy": 62.79421,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.3117,
    "out:CO2 Operational/m2": -61.497884,
    "out:Total CO2/m2": -28.186184,
    "out:Total CO2 (tons)": -74.101461,
    "out:Klimatpaverkan": -194.240534,
    "out:Initial Cost/MSEK": 5.028095002430602,
    "out:Running cost/(Apt SEK y)": 5465.078947,
    "out:Running Cost over RSP/MSEK": 1.63142,
    "out:LCP/MSEK": 1.318575,
    "out:ROI% old": 18.032021,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 1825.926413,
    "out:Return %": 16,
    "out:Return/kSEK/y": 813,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 30.601436,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 36816.029898,
    "out:PV (% sold (El))": 53.114567,
    "out:PV (kWh self-consumed)": 32498.344823,
    "out:PV (ratio sold/self-consumed)": 1.132859,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 286710.436133,
    "out:EL kWh savings": -56418.224657,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229098.03,
    "out:DH kr savings": 286710.4361328509,
    "out:El kr savings": -112836.44931480658,
    "out:El kr sold": 55224.04484723111,
    "out:El kr saved": 64996.689646,
    "out:El kr return": 120220.73449323111,
    "out:% solar/total": 233,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 43178.473246,
    "out:% savings (space heating)": 84.847495,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 18.81,
    "out:Etvv": 0,
    "out:Ef": 26.76,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5028095.002430602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.759744,
    "out:EL CO2": -67.257628,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.676255,
    "out:Bought CO2": 3.418627,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.930548,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.230239,
    "out:Electricity (inc PV)": 26.625287,
    "out:Total Energy Use Pre PV": 57.230239,
    "out:Total Energy Use Post PV": 45.625287,
    "out:Primary Energy": 64.286286,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.026801,
    "out:CO2 Operational/m2": -60.379999,
    "out:Total CO2/m2": -26.353198,
    "out:Total CO2 (tons)": -69.282542,
    "out:Klimatpaverkan": -189.421615,
    "out:Initial Cost/MSEK": 5.190050014930602,
    "out:Running cost/(Apt SEK y)": 6095.710526,
    "out:Running Cost over RSP/MSEK": 1.81884,
    "out:LCP/MSEK": 0.9692,
    "out:ROI% old": 16.953456,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 182.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 1827.520443,
    "out:Return %": 15,
    "out:Return/kSEK/y": 789,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 30.630739,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 36798.120239,
    "out:PV (% sold (El))": 53.088729,
    "out:PV (kWh self-consumed)": 32516.254483,
    "out:PV (ratio sold/self-consumed)": 1.131684,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280120.509988,
    "out:EL kWh savings": -56064.166792,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223189.36,
    "out:DH kr savings": 280120.50998830836,
    "out:El kr savings": -112128.33358360731,
    "out:El kr sold": 55197.18035841733,
    "out:El kr saved": 65032.508966,
    "out:El kr return": 120229.68932441733,
    "out:% solar/total": 233,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 49768.39939,
    "out:% savings (space heating)": 82.53491,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 21.04,
    "out:Etvv": 0,
    "out:Ef": 26.63,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5190050.014930602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.839696,
    "out:EL CO2": -67.219695,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.6413,
    "out:Bought CO2": 3.421605,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.687211,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.802727,
    "out:Electricity (inc PV)": 26.327478,
    "out:Total Energy Use Pre PV": 53.802727,
    "out:Total Energy Use Post PV": 42.327478,
    "out:Primary Energy": 61.12239,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.026801,
    "out:CO2 Operational/m2": -62.014951,
    "out:Total CO2/m2": -27.98815,
    "out:Total CO2 (tons)": -73.58083,
    "out:Klimatpaverkan": -193.719903,
    "out:Initial Cost/MSEK": 5.255775002430603,
    "out:Running cost/(Apt SEK y)": 5413.184211,
    "out:Running Cost over RSP/MSEK": 1.61616,
    "out:LCP/MSEK": 1.106155,
    "out:ROI% old": 17.292353,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 209.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 1804.286179,
    "out:Return %": 15,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 30.203227,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37059.929155,
    "out:PV (% sold (El))": 53.466441,
    "out:PV (kWh self-consumed)": 32254.445567,
    "out:PV (ratio sold/self-consumed)": 1.148987,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288647.241025,
    "out:EL kWh savings": -55281.227109,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233674.68,
    "out:DH kr savings": 288647.24102503614,
    "out:El kr savings": -110562.45421719122,
    "out:El kr sold": 55589.893732393044,
    "out:El kr saved": 64508.891134,
    "out:El kr return": 120098.78486639305,
    "out:% solar/total": 231,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 41241.668354,
    "out:% savings (space heating)": 85.527173,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 17.66,
    "out:Etvv": 0,
    "out:Ef": 26.33,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5255775.002430603,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.759744,
    "out:EL CO2": -67.774695,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.152893,
    "out:Bought CO2": 3.378198,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 115.3,
    "out:Total Energy Use Post PV": 115.3,
    "out:Primary Energy": 99.219322,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.13959,
    "out:CO2 Operational/m2": 40.297882,
    "out:Total CO2/m2": 42.437472,
    "out:Total CO2 (tons)": 111.568089,
    "out:Klimatpaverkan": -8.570985,
    "out:Initial Cost/MSEK": 0.209938053085,
    "out:Running cost/(Apt SEK y)": 23716.342105,
    "out:Running Cost over RSP/MSEK": 7.04657,
    "out:LCP/MSEK": 0.721582,
    "out:ROI% old": 63.387406,
    "out:Payback discounted": 2,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 57,
    "out:Return/kSEK/y": 119,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 40982.944842,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 40982.94,
    "out:DH kr savings": 40982.94484188664,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 209938.053085,
    "out:Seasonal Variation ROI (%)": 18,
    "out:Seasonal Variation Payback": 5.555556,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.59824,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 111.3,
    "out:Primary Energy": 96.150565,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.13959,
    "out:CO2 Operational/m2": 38.857946,
    "out:Total CO2/m2": 40.997536,
    "out:Total CO2 (tons)": 107.782498,
    "out:Klimatpaverkan": -12.356576,
    "out:Initial Cost/MSEK": 0.27566304058499996,
    "out:Running cost/(Apt SEK y)": 22880.605263,
    "out:Running Cost over RSP/MSEK": 6.79817,
    "out:LCP/MSEK": 0.904257,
    "out:ROI% old": 61.147116,
    "out:Payback discounted": 2,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 17.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 55,
    "out:Return/kSEK/y": 151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 51355.779766,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 51355.78,
    "out:DH kr savings": 51355.77976559637,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 275663.04058499995,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 112.3,
    "out:Primary Energy": 96.594322,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.854691,
    "out:CO2 Operational/m2": 39.21793,
    "out:Total CO2/m2": 42.072621,
    "out:Total CO2 (tons)": 110.608895,
    "out:Klimatpaverkan": -9.530178,
    "out:Initial Cost/MSEK": 0.437618053085,
    "out:Running cost/(Apt SEK y)": 23089.526316,
    "out:Running Cost over RSP/MSEK": 6.86027,
    "out:LCP/MSEK": 0.680202,
    "out:ROI% old": 36.490398,
    "out:Payback discounted": 4,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 16.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 33,
    "out:Return/kSEK/y": 143,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 47722.384811,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47722.38,
    "out:DH kr savings": 47722.38481147715,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 437618.053085,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 93.525565,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.854691,
    "out:CO2 Operational/m2": 37.777994,
    "out:Total CO2/m2": 40.632685,
    "out:Total CO2 (tons)": 106.823305,
    "out:Klimatpaverkan": -13.315769,
    "out:Initial Cost/MSEK": 0.5033430405849999,
    "out:Running cost/(Apt SEK y)": 22253.789474,
    "out:Running Cost over RSP/MSEK": 6.61186,
    "out:LCP/MSEK": 0.862887,
    "out:ROI% old": 38.775884,
    "out:Payback discounted": 3,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 35,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 58095.219735,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 58095.22,
    "out:DH kr savings": 58095.21973518689,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 503343.0405849999,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 115.3,
    "out:Total Energy Use Post PV": 113.138626,
    "out:Primary Energy": 95.328849,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.70939,
    "out:CO2 Operational/m2": -16.032165,
    "out:Total CO2/m2": 0.677225,
    "out:Total CO2 (tons)": 1.780424,
    "out:Klimatpaverkan": -118.358649,
    "out:Initial Cost/MSEK": 0.85492128959625,
    "out:Running cost/(Apt SEK y)": 22273.526316,
    "out:Running Cost over RSP/MSEK": 6.62964,
    "out:LCP/MSEK": 0.493529,
    "out:ROI% old": 22.532567,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 15,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 20,
    "out:Return/kSEK/y": 174,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 40982.944842,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95809.85,
    "out:DH kr savings": 40982.94484188664,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 854921.28959625,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.59824,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 109.138626,
    "out:Primary Energy": 92.260092,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.70939,
    "out:CO2 Operational/m2": -17.472101,
    "out:Total CO2/m2": -0.762711,
    "out:Total CO2 (tons)": -2.005167,
    "out:Klimatpaverkan": -122.14424,
    "out:Initial Cost/MSEK": 0.92064627709625,
    "out:Running cost/(Apt SEK y)": 21437.789474,
    "out:Running Cost over RSP/MSEK": 6.38123,
    "out:LCP/MSEK": 0.676214,
    "out:ROI% old": 24.778556,
    "out:Payback discounted": 5,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 22,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 51355.779766,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106182.69,
    "out:DH kr savings": 51355.77976559637,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 920646.27709625,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 110.138626,
    "out:Primary Energy": 92.703849,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.424491,
    "out:CO2 Operational/m2": -17.112117,
    "out:Total CO2/m2": 0.312374,
    "out:Total CO2 (tons)": 0.821231,
    "out:Klimatpaverkan": -119.317842,
    "out:Initial Cost/MSEK": 1.08260128959625,
    "out:Running cost/(Apt SEK y)": 21646.710526,
    "out:Running Cost over RSP/MSEK": 6.44333,
    "out:LCP/MSEK": 0.452159,
    "out:ROI% old": 20.252279,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 18,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 47722.384811,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102549.29,
    "out:DH kr savings": 47722.38481147715,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1082601.28959625,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 106.138626,
    "out:Primary Energy": 89.635092,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.424491,
    "out:CO2 Operational/m2": -18.552053,
    "out:Total CO2/m2": -1.127562,
    "out:Total CO2 (tons)": -2.96436,
    "out:Klimatpaverkan": -123.103433,
    "out:Initial Cost/MSEK": 1.1483262770962501,
    "out:Running cost/(Apt SEK y)": 20810.973684,
    "out:Running Cost over RSP/MSEK": 6.19493,
    "out:LCP/MSEK": 0.634834,
    "out:ROI% old": 22.183342,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 20,
    "out:Return/kSEK/y": 229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 58095.219735,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112922.13,
    "out:DH kr savings": 58095.21973518689,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1148326.27709625,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 115.3,
    "out:Total Energy Use Post PV": 113.026217,
    "out:Primary Energy": 95.126513,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.27919,
    "out:CO2 Operational/m2": -82.687369,
    "out:Total CO2/m2": -51.408179,
    "out:Total CO2 (tons)": -135.152072,
    "out:Klimatpaverkan": -255.291145,
    "out:Initial Cost/MSEK": 1.4999045086450002,
    "out:Running cost/(Apt SEK y)": 20901.605263,
    "out:Running Cost over RSP/MSEK": 6.23347,
    "out:LCP/MSEK": 0.244715,
    "out:ROI% old": 16.616486,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 15,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 15,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 40982.944842,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147943.39,
    "out:DH kr savings": 40982.94484188664,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1499904.5086450002,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.59824,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 109.026217,
    "out:Primary Energy": 92.057756,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.27919,
    "out:CO2 Operational/m2": -84.127305,
    "out:Total CO2/m2": -52.848115,
    "out:Total CO2 (tons)": -138.937663,
    "out:Klimatpaverkan": -259.076736,
    "out:Initial Cost/MSEK": 1.5656294961450001,
    "out:Running cost/(Apt SEK y)": 20065.842105,
    "out:Running Cost over RSP/MSEK": 5.98507,
    "out:LCP/MSEK": 0.427391,
    "out:ROI% old": 18.185474,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 16,
    "out:Return/kSEK/y": 258,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 51355.779766,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158316.23,
    "out:DH kr savings": 51355.77976559637,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1565629.4961450002,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 110.026217,
    "out:Primary Energy": 92.501513,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.994291,
    "out:CO2 Operational/m2": -83.767321,
    "out:Total CO2/m2": -51.77303,
    "out:Total CO2 (tons)": -136.111265,
    "out:Klimatpaverkan": -256.250338,
    "out:Initial Cost/MSEK": 1.7275845086450001,
    "out:Running cost/(Apt SEK y)": 20274.789474,
    "out:Running Cost over RSP/MSEK": 6.04717,
    "out:LCP/MSEK": 0.203335,
    "out:ROI% old": 15.967133,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 14,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 47722.384811,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154682.83,
    "out:DH kr savings": 47722.38481147715,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1727584.5086450002,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 106.026217,
    "out:Primary Energy": 89.432756,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.994291,
    "out:CO2 Operational/m2": -85.207257,
    "out:Total CO2/m2": -53.212966,
    "out:Total CO2 (tons)": -139.896856,
    "out:Klimatpaverkan": -260.035929,
    "out:Initial Cost/MSEK": 1.7933094961450002,
    "out:Running cost/(Apt SEK y)": 19439.052632,
    "out:Running Cost over RSP/MSEK": 5.79876,
    "out:LCP/MSEK": 0.386021,
    "out:ROI% old": 17.360799,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 16,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 58095.219735,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165055.67,
    "out:DH kr savings": 58095.21973518689,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1793309.4961450002,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 111.3,
    "out:Primary Energy": 95.719322,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.13959,
    "out:CO2 Operational/m2": 38.857946,
    "out:Total CO2/m2": 40.997536,
    "out:Total CO2 (tons)": 107.782498,
    "out:Klimatpaverkan": -12.356576,
    "out:Initial Cost/MSEK": 0.310231928085,
    "out:Running cost/(Apt SEK y)": 22880.605263,
    "out:Running Cost over RSP/MSEK": 6.79817,
    "out:LCP/MSEK": 0.869688,
    "out:ROI% old": 54.333544,
    "out:Payback discounted": 3,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 17.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 49,
    "out:Return/kSEK/y": 151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 49968.864801,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49968.86,
    "out:DH kr savings": 49968.86480134066,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 310231.928085,
    "out:Seasonal Variation ROI (%)": 15,
    "out:Seasonal Variation Payback": 6.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 92.650565,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.13959,
    "out:CO2 Operational/m2": 37.777994,
    "out:Total CO2/m2": 39.917584,
    "out:Total CO2 (tons)": 104.943305,
    "out:Klimatpaverkan": -15.195769,
    "out:Initial Cost/MSEK": 0.375956915585,
    "out:Running cost/(Apt SEK y)": 22253.789474,
    "out:Running Cost over RSP/MSEK": 6.61186,
    "out:LCP/MSEK": 0.990273,
    "out:ROI% old": 51.914383,
    "out:Payback discounted": 3,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 46,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 60341.699725,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60341.7,
    "out:DH kr savings": 60341.69972505039,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 375956.915585,
    "out:Seasonal Variation ROI (%)": 15,
    "out:Seasonal Variation Payback": 6.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 109.3,
    "out:Primary Energy": 93.619322,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.854691,
    "out:CO2 Operational/m2": 38.137978,
    "out:Total CO2/m2": 40.992669,
    "out:Total CO2 (tons)": 107.769702,
    "out:Klimatpaverkan": -12.369371,
    "out:Initial Cost/MSEK": 0.537911928085,
    "out:Running cost/(Apt SEK y)": 22462.736842,
    "out:Running Cost over RSP/MSEK": 6.67396,
    "out:LCP/MSEK": 0.766218,
    "out:ROI% old": 34.634719,
    "out:Payback discounted": 4,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 31,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 55360.416777,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55360.42,
    "out:DH kr savings": 55360.416777013044,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 537911.928085,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 90.550565,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.854691,
    "out:CO2 Operational/m2": 36.698042,
    "out:Total CO2/m2": 39.552733,
    "out:Total CO2 (tons)": 103.984111,
    "out:Klimatpaverkan": -16.154962,
    "out:Initial Cost/MSEK": 0.603636915585,
    "out:Running cost/(Apt SEK y)": 21626.973684,
    "out:Running Cost over RSP/MSEK": 6.42556,
    "out:LCP/MSEK": 0.948893,
    "out:ROI% old": 36.742286,
    "out:Payback discounted": 4,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 33,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 65733.251701,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 65733.25,
    "out:DH kr savings": 65733.25170072277,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 603636.915585,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 109.138626,
    "out:Primary Energy": 91.828849,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.70939,
    "out:CO2 Operational/m2": -17.472101,
    "out:Total CO2/m2": -0.762711,
    "out:Total CO2 (tons)": -2.005167,
    "out:Klimatpaverkan": -122.14424,
    "out:Initial Cost/MSEK": 0.9552151645962499,
    "out:Running cost/(Apt SEK y)": 21437.789474,
    "out:Running Cost over RSP/MSEK": 6.38123,
    "out:LCP/MSEK": 0.641645,
    "out:ROI% old": 23.88183,
    "out:Payback discounted": 5,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 22,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 49968.864801,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104795.77,
    "out:DH kr savings": 49968.86480134066,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 955215.1645962499,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 106.138626,
    "out:Primary Energy": 88.760092,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.70939,
    "out:CO2 Operational/m2": -18.552053,
    "out:Total CO2/m2": -1.842663,
    "out:Total CO2 (tons)": -4.84436,
    "out:Klimatpaverkan": -124.983433,
    "out:Initial Cost/MSEK": 1.02094015209625,
    "out:Running cost/(Apt SEK y)": 20810.973684,
    "out:Running Cost over RSP/MSEK": 6.19493,
    "out:LCP/MSEK": 0.76222,
    "out:ROI% old": 24.951232,
    "out:Payback discounted": 5,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 22,
    "out:Return/kSEK/y": 229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 60341.699725,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115168.61,
    "out:DH kr savings": 60341.69972505039,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1020940.15209625,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 107.138626,
    "out:Primary Energy": 89.728849,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.424491,
    "out:CO2 Operational/m2": -18.192069,
    "out:Total CO2/m2": -0.767578,
    "out:Total CO2 (tons)": -2.017962,
    "out:Klimatpaverkan": -122.157036,
    "out:Initial Cost/MSEK": 1.18289516459625,
    "out:Running cost/(Apt SEK y)": 21019.921053,
    "out:Running Cost over RSP/MSEK": 6.25703,
    "out:LCP/MSEK": 0.538165,
    "out:ROI% old": 20.785081,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 21.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 19,
    "out:Return/kSEK/y": 222,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 55360.416777,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110187.32,
    "out:DH kr savings": 55360.416777013044,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1182895.16459625,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 103.138626,
    "out:Primary Energy": 86.660092,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.424491,
    "out:CO2 Operational/m2": -19.632005,
    "out:Total CO2/m2": -2.207514,
    "out:Total CO2 (tons)": -5.803553,
    "out:Klimatpaverkan": -125.942627,
    "out:Initial Cost/MSEK": 1.2486201520962499,
    "out:Running cost/(Apt SEK y)": 20184.157895,
    "out:Running Cost over RSP/MSEK": 6.00863,
    "out:LCP/MSEK": 0.72084,
    "out:ROI% old": 22.532988,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 20,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 65733.251701,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120560.16,
    "out:DH kr savings": 65733.25170072277,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1248620.1520962499,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 109.026217,
    "out:Primary Energy": 91.626513,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.27919,
    "out:CO2 Operational/m2": -84.127305,
    "out:Total CO2/m2": -52.848115,
    "out:Total CO2 (tons)": -138.937663,
    "out:Klimatpaverkan": -259.076736,
    "out:Initial Cost/MSEK": 1.6001983836450004,
    "out:Running cost/(Apt SEK y)": 20065.842105,
    "out:Running Cost over RSP/MSEK": 5.98507,
    "out:LCP/MSEK": 0.392822,
    "out:ROI% old": 17.792615,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 16,
    "out:Return/kSEK/y": 258,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 49968.864801,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156929.31,
    "out:DH kr savings": 49968.86480134066,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1600198.3836450004,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 106.026217,
    "out:Primary Energy": 88.557756,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.27919,
    "out:CO2 Operational/m2": -85.207257,
    "out:Total CO2/m2": -53.928067,
    "out:Total CO2 (tons)": -141.776856,
    "out:Klimatpaverkan": -261.915929,
    "out:Initial Cost/MSEK": 1.665923371145,
    "out:Running cost/(Apt SEK y)": 19439.052632,
    "out:Running Cost over RSP/MSEK": 5.79876,
    "out:LCP/MSEK": 0.513407,
    "out:ROI% old": 18.688306,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 17,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60341.699725,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167302.15,
    "out:DH kr savings": 60341.69972505039,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1665923.3711450002,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.801986,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 107.026217,
    "out:Primary Energy": 89.526513,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.994291,
    "out:CO2 Operational/m2": -84.847273,
    "out:Total CO2/m2": -52.852982,
    "out:Total CO2 (tons)": -138.950458,
    "out:Klimatpaverkan": -259.089532,
    "out:Initial Cost/MSEK": 1.8278783836450003,
    "out:Running cost/(Apt SEK y)": 19647.973684,
    "out:Running Cost over RSP/MSEK": 5.86086,
    "out:LCP/MSEK": 0.289352,
    "out:ROI% old": 16.547131,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 21.5,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 15,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 55360.416777,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162320.87,
    "out:DH kr savings": 55360.416777013044,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 243976.36474,
    "out:% savings (space heating)": 14.382034,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.8,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1827878.3836450004,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.856441,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 103.026217,
    "out:Primary Energy": 86.457756,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.994291,
    "out:CO2 Operational/m2": -86.287209,
    "out:Total CO2/m2": -54.292918,
    "out:Total CO2 (tons)": -142.736049,
    "out:Klimatpaverkan": -262.875123,
    "out:Initial Cost/MSEK": 1.8936033711450002,
    "out:Running cost/(Apt SEK y)": 18812.236842,
    "out:Running Cost over RSP/MSEK": 5.61246,
    "out:LCP/MSEK": 0.472027,
    "out:ROI% old": 17.846776,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 16,
    "out:Return/kSEK/y": 305,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 65733.251701,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172693.7,
    "out:DH kr savings": 65733.25170072277,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 233603.529816,
    "out:% savings (space heating)": 18.022145,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.86,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1893603.3711450002,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.560071,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.487959,
    "out:Electricity (inc PV)": 27.453153,
    "out:Total Energy Use Pre PV": 74.487959,
    "out:Total Energy Use Post PV": 75.453153,
    "out:Primary Energy": 90.081733,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.872679,
    "out:CO2 Operational/m2": 20.775854,
    "out:Total CO2/m2": 26.648533,
    "out:Total CO2 (tons)": 70.058977,
    "out:Klimatpaverkan": -50.080096,
    "out:Initial Cost/MSEK": 2.7212977374556018,
    "out:Running cost/(Apt SEK y)": 13694.026316,
    "out:Running Cost over RSP/MSEK": 4.05756,
    "out:LCP/MSEK": 1.199232,
    "out:ROI% old": 20.581199,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 73.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1854.15713,
    "out:Return %": 18,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.888459,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 204853.511113,
    "out:EL kWh savings": -58240.626012,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88372.26,
    "out:DH kr savings": 204853.51111313357,
    "out:El kr savings": -116481.25202313531,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 125035.398266,
    "out:% savings (space heating)": 56.121666,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.28,
    "out:Etvv": 0,
    "out:Ef": 27.45,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2721297.737455602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": 3.496622,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.496622,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.897327,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.402262,
    "out:Electricity (inc PV)": 27.386292,
    "out:Total Energy Use Pre PV": 70.402262,
    "out:Total Energy Use Post PV": 71.386292,
    "out:Primary Energy": 87.064235,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.872679,
    "out:CO2 Operational/m2": 19.324606,
    "out:Total CO2/m2": 25.197285,
    "out:Total CO2 (tons)": 66.243647,
    "out:Klimatpaverkan": -53.895426,
    "out:Initial Cost/MSEK": 2.7870227249556025,
    "out:Running cost/(Apt SEK y)": 12846.447368,
    "out:Running Cost over RSP/MSEK": 3.80567,
    "out:LCP/MSEK": 1.385397,
    "out:ROI% old": 21.38698,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1848.15834,
    "out:Return %": 19,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.802762,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 214482.862902,
    "out:EL kWh savings": -58064.848482,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98353.17,
    "out:DH kr savings": 214482.86290247543,
    "out:El kr savings": -116129.69696496733,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 115406.046476,
    "out:% savings (space heating)": 59.500868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 48.56,
    "out:Etvv": 0,
    "out:Ef": 27.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2787022.7249556025,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": 3.48531,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.48531,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.071327,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.162275,
    "out:Electricity (inc PV)": 27.002522,
    "out:Total Energy Use Pre PV": 72.162275,
    "out:Total Energy Use Post PV": 73.002522,
    "out:Primary Energy": 87.51055,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.58778,
    "out:CO2 Operational/m2": 20.012894,
    "out:Total CO2/m2": 26.600674,
    "out:Total CO2 (tons)": 69.933156,
    "out:Klimatpaverkan": -50.205917,
    "out:Initial Cost/MSEK": 2.9489777374556017,
    "out:Running cost/(Apt SEK y)": 13231.105263,
    "out:Running Cost over RSP/MSEK": 3.92012,
    "out:LCP/MSEK": 1.108992,
    "out:ROI% old": 19.657999,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1831.35925,
    "out:Return %": 18,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.562775,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 208767.4182,
    "out:EL kWh savings": -57055.917382,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94655.58,
    "out:DH kr savings": 208767.41820035345,
    "out:El kr savings": -114111.83476318872,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 121121.491178,
    "out:% savings (space heating)": 57.495163,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 50.02,
    "out:Etvv": 0,
    "out:Ef": 27,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2948977.737455602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": 3.45363,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.45363,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.432627,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.069292,
    "out:Electricity (inc PV)": 26.927527,
    "out:Total Energy Use Pre PV": 68.069292,
    "out:Total Energy Use Post PV": 68.927527,
    "out:Primary Energy": 84.499288,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.58778,
    "out:CO2 Operational/m2": 18.560683,
    "out:Total CO2/m2": 25.148463,
    "out:Total CO2 (tons)": 66.115294,
    "out:Klimatpaverkan": -54.023779,
    "out:Initial Cost/MSEK": 3.0147027249556024,
    "out:Running cost/(Apt SEK y)": 12382.5,
    "out:Running Cost over RSP/MSEK": 3.66793,
    "out:LCP/MSEK": 1.295457,
    "out:ROI% old": 20.424473,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 88.4,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1824.85044,
    "out:Return %": 18,
    "out:Return/kSEK/y": 550,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.469792,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 218333.558328,
    "out:EL kWh savings": -56858.755571,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104616.05,
    "out:DH kr savings": 218333.55832804955,
    "out:El kr savings": -113717.51114273274,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111555.351051,
    "out:% savings (space heating)": 60.852182,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 46.32,
    "out:Etvv": 0,
    "out:Ef": 26.93,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3014702.7249556025,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": 3.441355,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.441355,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.560071,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.487959,
    "out:Electricity (inc PV)": 19.546481,
    "out:Total Energy Use Pre PV": 74.487959,
    "out:Total Energy Use Post PV": 67.546481,
    "out:Primary Energy": 75.849724,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.442479,
    "out:CO2 Operational/m2": -7.651044,
    "out:Total CO2/m2": 12.791435,
    "out:Total CO2 (tons)": 33.628675,
    "out:Klimatpaverkan": -86.510399,
    "out:Initial Cost/MSEK": 3.3662809739668518,
    "out:Running cost/(Apt SEK y)": 12058.763158,
    "out:Running Cost over RSP/MSEK": 3.58452,
    "out:LCP/MSEK": 1.027289,
    "out:ROI% old": 18.64529,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 91.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1326.390058,
    "out:Return %": 17,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.888459,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14348.67236,
    "out:PV (% sold (El))": 41.401722,
    "out:PV (kWh self-consumed)": 20308.515001,
    "out:PV (ratio sold/self-consumed)": 0.706535,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204853.511113,
    "out:EL kWh savings": -37453.990044,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151468.54,
    "out:DH kr savings": 204853.51111313357,
    "out:El kr savings": -74907.98008770168,
    "out:El kr sold": 21523.00854021713,
    "out:El kr saved": 40617.030002,
    "out:El kr return": 62140.03854221713,
    "out:% solar/total": 146,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 125035.398266,
    "out:% savings (space heating)": 56.121666,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.28,
    "out:Etvv": 0,
    "out:Ef": 19.55,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3366280.973966852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -24.930276,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.412973,
    "out:Bought CO2": 2.482697,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.897327,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.402262,
    "out:Electricity (inc PV)": 19.492123,
    "out:Total Energy Use Pre PV": 70.402262,
    "out:Total Energy Use Post PV": 63.492123,
    "out:Primary Energy": 72.85473,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.442479,
    "out:CO2 Operational/m2": -9.183996,
    "out:Total CO2/m2": 11.258483,
    "out:Total CO2 (tons)": 29.598545,
    "out:Klimatpaverkan": -90.540528,
    "out:Initial Cost/MSEK": 3.4320059614668517,
    "out:Running cost/(Apt SEK y)": 11211.736842,
    "out:Running Cost over RSP/MSEK": 3.3328,
    "out:LCP/MSEK": 1.213284,
    "out:ROI% old": 19.336005,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1321.483271,
    "out:Return %": 17,
    "out:Return/kSEK/y": 594,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.802762,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14391.507431,
    "out:PV (% sold (El))": 41.525318,
    "out:PV (kWh self-consumed)": 20265.679929,
    "out:PV (ratio sold/self-consumed)": 0.710142,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 214482.862902,
    "out:EL kWh savings": -37311.082894,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161447.96,
    "out:DH kr savings": 214482.86290247543,
    "out:El kr savings": -74622.16578860508,
    "out:El kr sold": 21587.26114721358,
    "out:El kr saved": 40531.359858,
    "out:El kr return": 62118.62100521359,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 115406.046476,
    "out:% savings (space heating)": 59.500868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 48.56,
    "out:Etvv": 0,
    "out:Ef": 19.49,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3432005.9614668516,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -25.023292,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.496811,
    "out:Bought CO2": 2.473519,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.071327,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.162275,
    "out:Electricity (inc PV)": 19.180341,
    "out:Total Energy Use Pre PV": 72.162275,
    "out:Total Energy Use Post PV": 65.180341,
    "out:Primary Energy": 73.430624,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.15758,
    "out:CO2 Operational/m2": -8.725141,
    "out:Total CO2/m2": 12.432439,
    "out:Total CO2 (tons)": 32.684875,
    "out:Klimatpaverkan": -87.454199,
    "out:Initial Cost/MSEK": 3.593960973966852,
    "out:Running cost/(Apt SEK y)": 11597.973684,
    "out:Running Cost over RSP/MSEK": 3.44771,
    "out:LCP/MSEK": 0.936419,
    "out:ROI% old": 18.007907,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1307.754906,
    "out:Return %": 16,
    "out:Return/kSEK/y": 580,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.562775,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14511.748151,
    "out:PV (% sold (El))": 41.872262,
    "out:PV (kWh self-consumed)": 20145.43921,
    "out:PV (ratio sold/self-consumed)": 0.720349,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 208767.4182,
    "out:EL kWh savings": -36491.408202,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157552.22,
    "out:DH kr savings": 208767.41820035345,
    "out:El kr savings": -72982.81640487284,
    "out:El kr sold": 21767.6222261067,
    "out:El kr saved": 40290.87842,
    "out:El kr return": 62058.5006461067,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 121121.491178,
    "out:% savings (space heating)": 57.495163,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 50.02,
    "out:Etvv": 0,
    "out:Ef": 19.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3593960.9739668523,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -25.284405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.732239,
    "out:Bought CO2": 2.447834,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.432627,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.069292,
    "out:Electricity (inc PV)": 19.119473,
    "out:Total Energy Use Pre PV": 68.069292,
    "out:Total Energy Use Post PV": 61.119473,
    "out:Primary Energy": 70.44479,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.15758,
    "out:CO2 Operational/m2": -10.266507,
    "out:Total CO2/m2": 10.891073,
    "out:Total CO2 (tons)": 28.632624,
    "out:Klimatpaverkan": -91.506449,
    "out:Initial Cost/MSEK": 3.6596859614668524,
    "out:Running cost/(Apt SEK y)": 10749.973684,
    "out:Running Cost over RSP/MSEK": 3.1957,
    "out:LCP/MSEK": 1.122704,
    "out:ROI% old": 18.668229,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1302.440745,
    "out:Return %": 17,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.469792,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14558.46099,
    "out:PV (% sold (El))": 42.007047,
    "out:PV (kWh self-consumed)": 20098.72637,
    "out:PV (ratio sold/self-consumed)": 0.724347,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 218333.558328,
    "out:EL kWh savings": -36331.386267,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167508.48,
    "out:DH kr savings": 218333.55832804955,
    "out:El kr savings": -72662.77253354923,
    "out:El kr sold": 21837.691485722233,
    "out:El kr saved": 40197.45274,
    "out:El kr return": 62035.14422572224,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111555.351051,
    "out:% savings (space heating)": 60.852182,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 46.32,
    "out:Etvv": 0,
    "out:Ef": 19.12,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3659685.9614668526,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": -25.385835,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.823724,
    "out:Bought CO2": 2.437889,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.560071,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.487959,
    "out:Electricity (inc PV)": 17.793345,
    "out:Total Energy Use Pre PV": 74.487959,
    "out:Total Energy Use Post PV": 65.793345,
    "out:Primary Energy": 72.694079,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.012279,
    "out:CO2 Operational/m2": -66.394849,
    "out:Total CO2/m2": -31.38257,
    "out:Total CO2 (tons)": -82.504758,
    "out:Klimatpaverkan": -202.643831,
    "out:Initial Cost/MSEK": 4.011264193015602,
    "out:Running cost/(Apt SEK y)": 10632.947368,
    "out:Running Cost over RSP/MSEK": 3.17244,
    "out:LCP/MSEK": 0.794386,
    "out:ROI% old": 17.11484,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 97,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1206.830788,
    "out:Return %": 15,
    "out:Return/kSEK/y": 616,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.888459,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44615.533847,
    "out:PV (% sold (El))": 64.366928,
    "out:PV (kWh self-consumed)": 24698.840874,
    "out:PV (ratio sold/self-consumed)": 1.806382,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 204853.511113,
    "out:EL kWh savings": -32844.996546,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206086.82,
    "out:DH kr savings": 204853.51111313357,
    "out:El kr savings": -65689.99309294607,
    "out:El kr sold": 66923.30077069794,
    "out:El kr saved": 49397.681748,
    "out:El kr return": 116320.98251869794,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 125035.398266,
    "out:% savings (space heating)": 56.121666,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.28,
    "out:Etvv": 0,
    "out:Ef": 17.79,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4011264.1930156024,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -83.674081,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -85.935074,
    "out:Bought CO2": 2.260993,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.897327,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.402262,
    "out:Electricity (inc PV)": 17.744699,
    "out:Total Energy Use Pre PV": 70.402262,
    "out:Total Energy Use Post PV": 61.744699,
    "out:Primary Energy": 69.709367,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.012279,
    "out:CO2 Operational/m2": -67.965179,
    "out:Total CO2/m2": -32.9529,
    "out:Total CO2 (tons)": -86.633154,
    "out:Klimatpaverkan": -206.772228,
    "out:Initial Cost/MSEK": 4.076989180515603,
    "out:Running cost/(Apt SEK y)": 9786.184211,
    "out:Running Cost over RSP/MSEK": 2.92079,
    "out:LCP/MSEK": 0.980311,
    "out:ROI% old": 17.72071,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1202.441356,
    "out:Return %": 16,
    "out:Return/kSEK/y": 648,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.802762,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44678.014353,
    "out:PV (% sold (El))": 64.457069,
    "out:PV (kWh self-consumed)": 24636.360369,
    "out:PV (ratio sold/self-consumed)": 1.813499,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 214482.862902,
    "out:EL kWh savings": -32717.106242,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216065.67,
    "out:DH kr savings": 214482.86290247543,
    "out:El kr savings": -65434.21248302937,
    "out:El kr sold": 67017.02152926162,
    "out:El kr saved": 49272.720738,
    "out:El kr return": 116289.74226726162,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 115406.046476,
    "out:% savings (space heating)": 59.500868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 48.56,
    "out:Etvv": 0,
    "out:Ef": 17.74,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4076989.1805156027,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -83.804475,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.057267,
    "out:Bought CO2": 2.252792,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.071327,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.162275,
    "out:Electricity (inc PV)": 17.465867,
    "out:Total Energy Use Pre PV": 72.162275,
    "out:Total Energy Use Post PV": 63.465867,
    "out:Primary Energy": 70.344571,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.72738,
    "out:CO2 Operational/m2": -67.611355,
    "out:Total CO2/m2": -31.883975,
    "out:Total CO2 (tons)": -83.822951,
    "out:Klimatpaverkan": -203.962025,
    "out:Initial Cost/MSEK": 4.238944193015603,
    "out:Running cost/(Apt SEK y)": 10173.157895,
    "out:Running Cost over RSP/MSEK": 3.03591,
    "out:LCP/MSEK": 0.703236,
    "out:ROI% old": 16.655697,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1190.162515,
    "out:Return %": 15,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.562775,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44853.405842,
    "out:PV (% sold (El))": 64.710107,
    "out:PV (kWh self-consumed)": 24460.96888,
    "out:PV (ratio sold/self-consumed)": 1.833672,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 208767.4182,
    "out:EL kWh savings": -31984.05708,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212079.41,
    "out:DH kr savings": 208767.41820035345,
    "out:El kr savings": -63968.11415995481,
    "out:El kr sold": 67280.1087627518,
    "out:El kr saved": 48921.93776,
    "out:El kr return": 116202.0465227518,
    "out:% solar/total": 176,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 121121.491178,
    "out:% savings (space heating)": 57.495163,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 50.02,
    "out:Etvv": 0,
    "out:Ef": 17.47,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4238944.193015602,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -84.170619,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.400471,
    "out:Bought CO2": 2.229852,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.432627,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.069292,
    "out:Electricity (inc PV)": 17.411485,
    "out:Total Energy Use Pre PV": 68.069292,
    "out:Total Energy Use Post PV": 59.411485,
    "out:Primary Energy": 67.370412,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.72738,
    "out:CO2 Operational/m2": -69.193558,
    "out:Total CO2/m2": -33.466178,
    "out:Total CO2 (tons)": -87.982562,
    "out:Klimatpaverkan": -208.121636,
    "out:Initial Cost/MSEK": 4.304669180515602,
    "out:Running cost/(Apt SEK y)": 9325.447368,
    "out:Running Cost over RSP/MSEK": 2.78399,
    "out:LCP/MSEK": 0.889431,
    "out:ROI% old": 17.237429,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1185.410493,
    "out:Return %": 15,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.469792,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44921.614255,
    "out:PV (% sold (El))": 64.808511,
    "out:PV (kWh self-consumed)": 24392.760467,
    "out:PV (ratio sold/self-consumed)": 1.841596,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 218333.558328,
    "out:EL kWh savings": -31841.086834,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222033.81,
    "out:DH kr savings": 218333.55832804955,
    "out:El kr savings": -63682.1736688869,
    "out:El kr sold": 67382.42138227931,
    "out:El kr saved": 48785.520934,
    "out:El kr return": 116167.94231627931,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111555.351051,
    "out:% savings (space heating)": 60.852182,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 46.32,
    "out:Etvv": 0,
    "out:Ef": 17.41,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4304669.180515602,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": -84.312886,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.53386,
    "out:Bought CO2": 2.220974,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.579484,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.052379,
    "out:Electricity (inc PV)": 26.84962,
    "out:Total Energy Use Pre PV": 72.052379,
    "out:Total Energy Use Post PV": 72.84962,
    "out:Primary Energy": 86.655553,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.872679,
    "out:CO2 Operational/m2": 19.998386,
    "out:Total CO2/m2": 25.871065,
    "out:Total CO2 (tons)": 68.015014,
    "out:Klimatpaverkan": -52.124059,
    "out:Initial Cost/MSEK": 2.8215916124556024,
    "out:Running cost/(Apt SEK y)": 13215.894737,
    "out:Running Cost over RSP/MSEK": 3.91565,
    "out:LCP/MSEK": 1.240848,
    "out:ROI% old": 20.568128,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1823.66653,
    "out:Return %": 18,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.452879,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210060.473154,
    "out:EL kWh savings": -56653.938115,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96752.6,
    "out:DH kr savings": 210060.47315372317,
    "out:El kr savings": -113307.87622975372,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 119828.436225,
    "out:% savings (space heating)": 57.948931,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 49.28,
    "out:Etvv": 0,
    "out:Ef": 26.85,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2821591.6124556023,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": 3.439122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.439122,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.950832,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.956351,
    "out:Electricity (inc PV)": 26.77169,
    "out:Total Energy Use Pre PV": 67.956351,
    "out:Total Energy Use Post PV": 68.77169,
    "out:Primary Energy": 83.646543,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.872679,
    "out:CO2 Operational/m2": 18.545774,
    "out:Total CO2/m2": 24.418453,
    "out:Total CO2 (tons)": 64.196098,
    "out:Klimatpaverkan": -55.942975,
    "out:Initial Cost/MSEK": 2.8873165999556014,
    "out:Running cost/(Apt SEK y)": 12366.868421,
    "out:Running Cost over RSP/MSEK": 3.66334,
    "out:LCP/MSEK": 1.427433,
    "out:ROI% old": 21.348295,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 88.4,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1816.94457,
    "out:Return %": 19,
    "out:Return/kSEK/y": 550,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.356851,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219600.197095,
    "out:EL kWh savings": -56449.060191,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106702.08,
    "out:DH kr savings": 219600.1970954179,
    "out:El kr savings": -112898.12038280217,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 110288.712283,
    "out:% savings (space heating)": 61.29668,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.59,
    "out:Etvv": 0,
    "out:Ef": 26.77,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2887316.5999556016,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": 3.426446,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.426446,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.410989,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.785014,
    "out:Electricity (inc PV)": 26.476636,
    "out:Total Energy Use Pre PV": 69.785014,
    "out:Total Energy Use Post PV": 70.476636,
    "out:Primary Energy": 84.608172,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.58778,
    "out:CO2 Operational/m2": 19.243124,
    "out:Total CO2/m2": 25.830904,
    "out:Total CO2 (tons)": 67.909431,
    "out:Klimatpaverkan": -52.229642,
    "out:Initial Cost/MSEK": 3.0492716124556023,
    "out:Running cost/(Apt SEK y)": 12761.026316,
    "out:Running Cost over RSP/MSEK": 3.78058,
    "out:LCP/MSEK": 1.148238,
    "out:ROI% old": 19.665165,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 85.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1804.95098,
    "out:Return %": 18,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.185514,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213132.445811,
    "out:EL kWh savings": -55673.363402,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101785.72,
    "out:DH kr savings": 213132.44581113165,
    "out:El kr savings": -111346.7268030966,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 116756.463568,
    "out:% savings (space heating)": 59.02697,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.51,
    "out:Etvv": 0,
    "out:Ef": 26.48,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3049271.6124556023,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": 3.403828,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.403828,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.806911,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.681539,
    "out:Electricity (inc PV)": 26.390714,
    "out:Total Energy Use Pre PV": 66.681539,
    "out:Total Energy Use Post PV": 67.390714,
    "out:Primary Energy": 81.605289,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.58778,
    "out:CO2 Operational/m2": 18.149513,
    "out:Total CO2/m2": 24.737293,
    "out:Total CO2 (tons)": 65.034329,
    "out:Klimatpaverkan": -55.104745,
    "out:Initial Cost/MSEK": 3.1149965999556017,
    "out:Running cost/(Apt SEK y)": 12119.894737,
    "out:Running Cost over RSP/MSEK": 3.59007,
    "out:LCP/MSEK": 1.273023,
    "out:ROI% old": 20.123938,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1797.70773,
    "out:Return %": 18,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.082039,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222607.564721,
    "out:EL kWh savings": -55447.474515,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111712.62,
    "out:DH kr savings": 222607.5647214971,
    "out:El kr savings": -110894.94902968749,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107281.344657,
    "out:% savings (space heating)": 62.352048,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 43.85,
    "out:Etvv": 0,
    "out:Ef": 26.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3114996.5999556016,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": 3.390169,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.390169,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.579484,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.052379,
    "out:Electricity (inc PV)": 19.056288,
    "out:Total Energy Use Pre PV": 72.052379,
    "out:Total Energy Use Post PV": 65.056288,
    "out:Primary Energy": 72.627555,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.442479,
    "out:CO2 Operational/m2": -8.845038,
    "out:Total CO2/m2": 11.597441,
    "out:Total CO2 (tons)": 30.489665,
    "out:Klimatpaverkan": -89.649408,
    "out:Initial Cost/MSEK": 3.466574848966852,
    "out:Running cost/(Apt SEK y)": 11583.5,
    "out:Running Cost over RSP/MSEK": 3.44345,
    "out:LCP/MSEK": 1.068065,
    "out:ROI% old": 18.687198,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1301.474417,
    "out:Return %": 17,
    "out:Return/kSEK/y": 580,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.452879,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14566.973157,
    "out:PV (% sold (El))": 42.031608,
    "out:PV (kWh self-consumed)": 20090.214204,
    "out:PV (ratio sold/self-consumed)": 0.725078,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 210060.473154,
    "out:EL kWh savings": -36165.272939,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159580.39,
    "out:DH kr savings": 210060.47315372317,
    "out:El kr savings": -72330.5458789921,
    "out:El kr sold": 21850.459734968194,
    "out:El kr saved": 40180.428408,
    "out:El kr return": 62030.88814296819,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 119828.436225,
    "out:% savings (space heating)": 57.948931,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 49.28,
    "out:Etvv": 0,
    "out:Ef": 19.06,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3466574.848966852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -25.404302,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.840383,
    "out:Bought CO2": 2.436081,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.950832,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.956351,
    "out:Electricity (inc PV)": 18.993101,
    "out:Total Energy Use Pre PV": 67.956351,
    "out:Total Energy Use Post PV": 60.993101,
    "out:Primary Energy": 69.645083,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.442479,
    "out:CO2 Operational/m2": -10.389934,
    "out:Total CO2/m2": 10.052545,
    "out:Total CO2 (tons)": 26.428135,
    "out:Klimatpaverkan": -93.710939,
    "out:Initial Cost/MSEK": 3.5322998364668523,
    "out:Running cost/(Apt SEK y)": 10735.105263,
    "out:Running Cost over RSP/MSEK": 3.19133,
    "out:LCP/MSEK": 1.25446,
    "out:ROI% old": 19.35914,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1295.989762,
    "out:Return %": 17,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.356851,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14615.437371,
    "out:PV (% sold (El))": 42.171447,
    "out:PV (kWh self-consumed)": 20041.74999,
    "out:PV (ratio sold/self-consumed)": 0.72925,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 219600.197095,
    "out:EL kWh savings": -35999.154354,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169525.04,
    "out:DH kr savings": 219600.1970954179,
    "out:El kr savings": -71998.30870843737,
    "out:El kr sold": 21923.156055993415,
    "out:El kr saved": 40083.49998,
    "out:El kr return": 62006.656035993416,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 110288.712283,
    "out:% savings (space heating)": 61.29668,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.59,
    "out:Etvv": 0,
    "out:Ef": 18.99,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3532299.836466852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": -25.509262,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.935078,
    "out:Bought CO2": 2.425816,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.410989,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.785014,
    "out:Electricity (inc PV)": 18.75398,
    "out:Total Energy Use Pre PV": 69.785014,
    "out:Total Energy Use Post PV": 62.75398,
    "out:Primary Energy": 70.707391,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.15758,
    "out:CO2 Operational/m2": -9.857704,
    "out:Total CO2/m2": 11.299876,
    "out:Total CO2 (tons)": 29.707367,
    "out:Klimatpaverkan": -90.431706,
    "out:Initial Cost/MSEK": 3.694254848966852,
    "out:Running cost/(Apt SEK y)": 11130.421053,
    "out:Running Cost over RSP/MSEK": 3.3089,
    "out:LCP/MSEK": 0.974935,
    "out:ROI% old": 18.055797,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1286.211185,
    "out:Return %": 16,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.185514,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14702.230226,
    "out:PV (% sold (El))": 42.421879,
    "out:PV (kWh self-consumed)": 19954.957135,
    "out:PV (ratio sold/self-consumed)": 0.736771,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213132.445811,
    "out:EL kWh savings": -35370.505388,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164444.78,
    "out:DH kr savings": 213132.44581113165,
    "out:El kr savings": -70741.01077594786,
    "out:El kr sold": 22053.345338414623,
    "out:El kr saved": 39909.91427,
    "out:El kr return": 61963.25960841462,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 116756.463568,
    "out:% savings (space heating)": 59.02697,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.51,
    "out:Etvv": 0,
    "out:Ef": 18.75,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3694254.848966852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -25.697,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.104513,
    "out:Bought CO2": 2.407513,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.806911,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.681539,
    "out:Electricity (inc PV)": 18.684396,
    "out:Total Energy Use Pre PV": 66.681539,
    "out:Total Energy Use Post PV": 59.684396,
    "out:Primary Energy": 67.733917,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.15758,
    "out:CO2 Operational/m2": -11.051325,
    "out:Total CO2/m2": 10.106255,
    "out:Total CO2 (tons)": 26.569338,
    "out:Klimatpaverkan": -93.569735,
    "out:Initial Cost/MSEK": 3.759979836466852,
    "out:Running cost/(Apt SEK y)": 10489.973684,
    "out:Running Cost over RSP/MSEK": 3.11859,
    "out:LCP/MSEK": 1.09952,
    "out:ROI% old": 18.463245,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1280.310401,
    "out:Return %": 17,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.082039,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14754.806626,
    "out:PV (% sold (El))": 42.573584,
    "out:PV (kWh self-consumed)": 19902.380734,
    "out:PV (ratio sold/self-consumed)": 0.741359,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222607.564721,
    "out:EL kWh savings": -35187.569094,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174364.64,
    "out:DH kr savings": 222607.5647214971,
    "out:El kr savings": -70375.1381870312,
    "out:El kr sold": 22132.209939654553,
    "out:El kr saved": 39804.761468,
    "out:El kr return": 61936.97140765455,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107281.344657,
    "out:% savings (space heating)": 62.352048,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 43.85,
    "out:Etvv": 0,
    "out:Ef": 18.68,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3759979.836466852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -25.810669,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.207136,
    "out:Bought CO2": 2.396467,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.579484,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.052379,
    "out:Electricity (inc PV)": 17.355016,
    "out:Total Energy Use Pre PV": 72.052379,
    "out:Total Energy Use Post PV": 63.355016,
    "out:Primary Energy": 69.565266,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.012279,
    "out:CO2 Operational/m2": -67.779527,
    "out:Total CO2/m2": -32.767248,
    "out:Total CO2 (tons)": -86.145075,
    "out:Klimatpaverkan": -206.284149,
    "out:Initial Cost/MSEK": 4.1115580680156025,
    "out:Running cost/(Apt SEK y)": 10159,
    "out:Running Cost over RSP/MSEK": 3.03175,
    "out:LCP/MSEK": 0.834782,
    "out:ROI% old": 17.186186,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1184.546489,
    "out:Return %": 15,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.452879,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44934.039886,
    "out:PV (% sold (El))": 64.826438,
    "out:PV (kWh self-consumed)": 24380.334836,
    "out:PV (ratio sold/self-consumed)": 1.843044,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 210060.473154,
    "out:EL kWh savings": -31692.629867,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214076.27,
    "out:DH kr savings": 210060.47315372317,
    "out:El kr savings": -63385.25973431084,
    "out:El kr sold": 67401.05982840572,
    "out:El kr saved": 48760.669672,
    "out:El kr return": 116161.72950040572,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 119828.436225,
    "out:% savings (space heating)": 57.948931,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 49.28,
    "out:Etvv": 0,
    "out:Ef": 17.36,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4111558.0680156024,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -84.338791,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.558151,
    "out:Bought CO2": 2.21936,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.950832,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.956351,
    "out:Electricity (inc PV)": 17.298552,
    "out:Total Energy Use Pre PV": 67.956351,
    "out:Total Energy Use Post PV": 59.298552,
    "out:Primary Energy": 66.594895,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.012279,
    "out:CO2 Operational/m2": -69.366684,
    "out:Total CO2/m2": -34.354405,
    "out:Total CO2 (tons)": -90.31771,
    "out:Klimatpaverkan": -210.456784,
    "out:Initial Cost/MSEK": 4.177283055515602,
    "out:Running cost/(Apt SEK y)": 9310.894737,
    "out:Running Cost over RSP/MSEK": 2.77971,
    "out:LCP/MSEK": 1.021097,
    "out:ROI% old": 17.777721,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1179.642807,
    "out:Return %": 16,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.356851,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45004.654677,
    "out:PV (% sold (El))": 64.928314,
    "out:PV (kWh self-consumed)": 24309.720045,
    "out:PV (ratio sold/self-consumed)": 1.851303,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 219600.197095,
    "out:EL kWh savings": -31544.186045,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 224018.81,
    "out:DH kr savings": 219600.1970954179,
    "out:El kr savings": -63088.37208972891,
    "out:El kr sold": 67506.98201485537,
    "out:El kr saved": 48619.44009,
    "out:El kr return": 116126.42210485536,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 110288.712283,
    "out:% savings (space heating)": 61.29668,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.59,
    "out:Etvv": 0,
    "out:Ef": 17.3,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4177283.0555156018,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": -84.486012,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.696212,
    "out:Bought CO2": 2.2102,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.410989,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.785014,
    "out:Electricity (inc PV)": 17.085005,
    "out:Total Energy Use Pre PV": 69.785014,
    "out:Total Energy Use Post PV": 61.085005,
    "out:Primary Energy": 67.703236,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.72738,
    "out:CO2 Operational/m2": -68.910052,
    "out:Total CO2/m2": -33.182672,
    "out:Total CO2 (tons)": -87.237225,
    "out:Klimatpaverkan": -207.376298,
    "out:Initial Cost/MSEK": 4.339238068015603,
    "out:Running cost/(Apt SEK y)": 9706.736842,
    "out:Running Cost over RSP/MSEK": 2.89743,
    "out:LCP/MSEK": 0.741422,
    "out:ROI% old": 16.726636,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1170.901981,
    "out:Return %": 15,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.185514,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45131.117984,
    "out:PV (% sold (El))": 65.110763,
    "out:PV (kWh self-consumed)": 24183.256737,
    "out:PV (ratio sold/self-consumed)": 1.866213,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 213132.445811,
    "out:EL kWh savings": -30982.771109,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218863.58,
    "out:DH kr savings": 213132.44581113165,
    "out:El kr savings": -61965.542218703995,
    "out:El kr sold": 67696.6769765264,
    "out:El kr saved": 48366.513474,
    "out:El kr return": 116063.1904505264,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 116756.463568,
    "out:% savings (space heating)": 59.02697,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.51,
    "out:Etvv": 0,
    "out:Ef": 17.09,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4339238.068015602,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -84.749348,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.94322,
    "out:Bought CO2": 2.193872,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.806911,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.681539,
    "out:Electricity (inc PV)": 17.022916,
    "out:Total Energy Use Pre PV": 66.681539,
    "out:Total Energy Use Post PV": 58.022916,
    "out:Primary Energy": 64.743253,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.72738,
    "out:CO2 Operational/m2": -70.149462,
    "out:Total CO2/m2": -34.422082,
    "out:Total CO2 (tons)": -90.495633,
    "out:Klimatpaverkan": -210.634707,
    "out:Initial Cost/MSEK": 4.404963055515602,
    "out:Running cost/(Apt SEK y)": 9066.605263,
    "out:Running Cost over RSP/MSEK": 2.70722,
    "out:LCP/MSEK": 0.865907,
    "out:ROI% old": 17.093933,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1165.628498,
    "out:Return %": 15,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.082039,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45207.712565,
    "out:PV (% sold (El))": 65.221266,
    "out:PV (kWh self-consumed)": 24106.662157,
    "out:PV (ratio sold/self-consumed)": 1.87532,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222607.564721,
    "out:EL kWh savings": -30819.539165,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228780.06,
    "out:DH kr savings": 222607.5647214971,
    "out:El kr savings": -61639.07833083826,
    "out:El kr sold": 67811.56884737467,
    "out:El kr saved": 48213.324314,
    "out:El kr return": 116024.89316137467,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107281.344657,
    "out:% savings (space heating)": 62.352048,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 43.85,
    "out:Etvv": 0,
    "out:Ef": 17.02,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4404963.055515602,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -84.908806,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.092829,
    "out:Bought CO2": 2.184023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.079203,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.542767,
    "out:Electricity (inc PV)": 39.163162,
    "out:Total Energy Use Pre PV": 48.542767,
    "out:Total Energy Use Post PV": 50.163162,
    "out:Primary Energy": 81.104059,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.31169,
    "out:CO2 Operational/m2": 8.91577,
    "out:Total CO2/m2": 15.22746,
    "out:Total CO2 (tons)": 40.032983,
    "out:Klimatpaverkan": -80.10609,
    "out:Initial Cost/MSEK": 3.782047737455602,
    "out:Running cost/(Apt SEK y)": 7493.026316,
    "out:Running Cost over RSP/MSEK": 2.20917,
    "out:LCP/MSEK": 1.986872,
    "out:ROI% old": 21.790614,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 160,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2627.99369,
    "out:Return %": 19,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.943267,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 300761.691306,
    "out:EL kWh savings": -89026.232682,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122709.23,
    "out:DH kr savings": 300761.6913060554,
    "out:El kr savings": -178052.46536338457,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 29127.218073,
    "out:% savings (space heating)": 89.778464,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 13.64,
    "out:Etvv": 0,
    "out:Ef": 39.16,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3782047.737455602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": 4.955946,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.955946,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.750139,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.052925,
    "out:Electricity (inc PV)": 38.737571,
    "out:Total Energy Use Pre PV": 46.052925,
    "out:Total Energy Use Post PV": 47.737571,
    "out:Primary Energy": 78.361588,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.31169,
    "out:CO2 Operational/m2": 8.131139,
    "out:Total CO2/m2": 14.442829,
    "out:Total CO2 (tons)": 37.970189,
    "out:Klimatpaverkan": -82.168885,
    "out:Initial Cost/MSEK": 3.8477727249556026,
    "out:Running cost/(Apt SEK y)": 7007.368421,
    "out:Running Cost over RSP/MSEK": 2.06506,
    "out:LCP/MSEK": 2.065257,
    "out:ROI% old": 21.953442,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 170.8,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2593.70475,
    "out:Return %": 20,
    "out:Return/kSEK/y": 754,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.453425,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 306884.799172,
    "out:EL kWh savings": -87907.354197,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131070.09,
    "out:DH kr savings": 306884.79917160416,
    "out:El kr savings": -175814.70839354023,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 23004.110207,
    "out:% savings (space heating)": 91.92723,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.1,
    "out:Etvv": 0,
    "out:Ef": 38.74,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3847772.7249556025,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 4.891283,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.891283,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.305945,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.00027,
    "out:Electricity (inc PV)": 38.405831,
    "out:Total Energy Use Pre PV": 47.00027,
    "out:Total Energy Use Post PV": 48.405831,
    "out:Primary Energy": 78.768012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.026791,
    "out:CO2 Operational/m2": 8.484172,
    "out:Total CO2/m2": 15.510963,
    "out:Total CO2 (tons)": 40.778312,
    "out:Klimatpaverkan": -79.360761,
    "out:Initial Cost/MSEK": 4.009727737455602,
    "out:Running cost/(Apt SEK y)": 7209.026316,
    "out:Running Cost over RSP/MSEK": 2.12502,
    "out:LCP/MSEK": 1.843342,
    "out:ROI% old": 20.853108,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 170.8,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2590.0189,
    "out:Return %": 19,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 29.40077,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 302794.586126,
    "out:EL kWh savings": -87035.209935,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128724.17,
    "out:DH kr savings": 302794.5861264204,
    "out:El kr savings": -174070.41986963782,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 27094.323252,
    "out:% savings (space heating)": 90.491862,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 12.39,
    "out:Etvv": 0,
    "out:Ef": 38.41,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4009727.7374556023,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": 4.884332,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.884332,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.049902,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.4883,
    "out:Electricity (inc PV)": 37.949229,
    "out:Total Energy Use Pre PV": 44.4883,
    "out:Total Energy Use Post PV": 45.949229,
    "out:Primary Energy": 76.049316,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.026791,
    "out:CO2 Operational/m2": 7.69662,
    "out:Total CO2/m2": 14.723411,
    "out:Total CO2 (tons)": 38.707839,
    "out:Klimatpaverkan": -81.431235,
    "out:Initial Cost/MSEK": 4.0754527249556025,
    "out:Running cost/(Apt SEK y)": 6720.315789,
    "out:Running Cost over RSP/MSEK": 1.98001,
    "out:LCP/MSEK": 1.922627,
    "out:ROI% old": 21.025114,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 182.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2554.181,
    "out:Return %": 19,
    "out:Return/kSEK/y": 765,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.8888,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308725.721827,
    "out:EL kWh savings": -85834.803549,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137056.11,
    "out:DH kr savings": 308725.7218265627,
    "out:El kr savings": -171669.60709882062,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 21163.187552,
    "out:% savings (space heating)": 92.57326,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.95,
    "out:Etvv": 0,
    "out:Ef": 37.95,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4075452.7249556025,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": 4.816748,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.816748,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.079203,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.542767,
    "out:Electricity (inc PV)": 29.347819,
    "out:Total Energy Use Pre PV": 48.542767,
    "out:Total Energy Use Post PV": 40.347819,
    "out:Primary Energy": 63.436441,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.88149,
    "out:CO2 Operational/m2": -10.242403,
    "out:Total CO2/m2": 10.639087,
    "out:Total CO2 (tons)": 27.970153,
    "out:Klimatpaverkan": -92.16892,
    "out:Initial Cost/MSEK": 4.427030973966851,
    "out:Running cost/(Apt SEK y)": 5793.526316,
    "out:Running Cost over RSP/MSEK": 1.71751,
    "out:LCP/MSEK": 1.833549,
    "out:ROI% old": 20.202447,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 225,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 1977.001907,
    "out:Return %": 18,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.943267,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9466.11372,
    "out:PV (% sold (El))": 27.313566,
    "out:PV (kWh self-consumed)": 25191.073641,
    "out:PV (ratio sold/self-consumed)": 0.375773,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 300761.691306,
    "out:EL kWh savings": -63221.701794,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188517.46,
    "out:DH kr savings": 300761.6913060554,
    "out:El kr savings": -126443.40358890411,
    "out:El kr sold": 14199.170579391437,
    "out:El kr saved": 50382.147282,
    "out:El kr return": 64581.317861391435,
    "out:% solar/total": 181,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 29127.218073,
    "out:% savings (space heating)": 89.778464,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 13.64,
    "out:Etvv": 0,
    "out:Ef": 29.35,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4427030.973966852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -14.202227,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.902028,
    "out:Bought CO2": 3.699801,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.750139,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.052925,
    "out:Electricity (inc PV)": 28.982291,
    "out:Total Energy Use Pre PV": 46.052925,
    "out:Total Energy Use Post PV": 37.982291,
    "out:Primary Energy": 60.802084,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.88149,
    "out:CO2 Operational/m2": -11.38851,
    "out:Total CO2/m2": 9.49298,
    "out:Total CO2 (tons)": 24.957039,
    "out:Klimatpaverkan": -95.182035,
    "out:Initial Cost/MSEK": 4.492755961466853,
    "out:Running cost/(Apt SEK y)": 5310.394737,
    "out:Running Cost over RSP/MSEK": 1.57412,
    "out:LCP/MSEK": 1.911214,
    "out:ROI% old": 20.362843,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 242.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1947.432344,
    "out:Return %": 18,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.453425,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9658.001053,
    "out:PV (% sold (El))": 27.867238,
    "out:PV (kWh self-consumed)": 24999.186307,
    "out:PV (ratio sold/self-consumed)": 0.386333,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 306884.799172,
    "out:EL kWh savings": -62260.728901,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196850.34,
    "out:DH kr savings": 306884.79917160416,
    "out:El kr savings": -124521.45780134456,
    "out:El kr sold": 14487.001580247015,
    "out:El kr saved": 49998.372614,
    "out:El kr return": 64485.374194247015,
    "out:% solar/total": 179,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 23004.110207,
    "out:% savings (space heating)": 91.92723,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.1,
    "out:Etvv": 0,
    "out:Ef": 28.98,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4492755.961466853,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -14.628366,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.272952,
    "out:Bought CO2": 3.644586,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.305945,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.00027,
    "out:Electricity (inc PV)": 28.697958,
    "out:Total Energy Use Pre PV": 47.00027,
    "out:Total Energy Use Post PV": 38.697958,
    "out:Primary Energy": 61.29384,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.596591,
    "out:CO2 Operational/m2": -11.07469,
    "out:Total CO2/m2": 10.521901,
    "out:Total CO2 (tons)": 27.662071,
    "out:Klimatpaverkan": -92.477002,
    "out:Initial Cost/MSEK": 4.654710973966852,
    "out:Running cost/(Apt SEK y)": 5512.315789,
    "out:Running Cost over RSP/MSEK": 1.63416,
    "out:LCP/MSEK": 1.689219,
    "out:ROI% old": 19.470075,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 233.3,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 1944.258037,
    "out:Return %": 17,
    "out:Return/kSEK/y": 811,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 29.40077,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9678.812956,
    "out:PV (% sold (El))": 27.927289,
    "out:PV (kWh self-consumed)": 24978.374405,
    "out:PV (ratio sold/self-consumed)": 0.387488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 302794.586126,
    "out:EL kWh savings": -61513.217613,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194286.37,
    "out:DH kr savings": 302794.5861264204,
    "out:El kr savings": -123026.43522683813,
    "out:El kr sold": 14518.219433331791,
    "out:El kr saved": 49956.74881,
    "out:El kr return": 64474.96824333179,
    "out:% solar/total": 179,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 27094.323252,
    "out:% savings (space heating)": 90.491862,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 12.39,
    "out:Etvv": 0,
    "out:Ef": 28.7,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4654710.973966852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -14.67453,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.31319,
    "out:Bought CO2": 3.63866,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.049902,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.4883,
    "out:Electricity (inc PV)": 28.307515,
    "out:Total Energy Use Pre PV": 44.4883,
    "out:Total Energy Use Post PV": 36.307515,
    "out:Primary Energy": 58.694231,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.596591,
    "out:CO2 Operational/m2": -12.246751,
    "out:Total CO2/m2": 9.34984,
    "out:Total CO2 (tons)": 24.580724,
    "out:Klimatpaverkan": -95.55835,
    "out:Initial Cost/MSEK": 4.720435961466853,
    "out:Running cost/(Apt SEK y)": 5026.289474,
    "out:Running Cost over RSP/MSEK": 1.48992,
    "out:LCP/MSEK": 1.767734,
    "out:ROI% old": 19.635505,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 261.1,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 1913.438783,
    "out:Return %": 18,
    "out:Return/kSEK/y": 829,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.8888,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9882.305112,
    "out:PV (% sold (El))": 28.514446,
    "out:PV (kWh self-consumed)": 24774.882248,
    "out:PV (ratio sold/self-consumed)": 0.398884,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308725.721827,
    "out:EL kWh savings": -60486.7432,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202575.69,
    "out:DH kr savings": 308725.7218265627,
    "out:El kr savings": -120973.48639902708,
    "out:El kr sold": 14823.457668682142,
    "out:El kr saved": 49549.764496,
    "out:El kr return": 64373.22216468214,
    "out:% solar/total": 178,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 21163.187552,
    "out:% savings (space heating)": 92.57326,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.95,
    "out:Etvv": 0,
    "out:Ef": 28.31,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4720435.961466853,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -15.126623,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.707748,
    "out:Bought CO2": 3.581125,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.079203,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.542767,
    "out:Electricity (inc PV)": 26.579605,
    "out:Total Energy Use Pre PV": 48.542767,
    "out:Total Energy Use Post PV": 37.579605,
    "out:Primary Energy": 58.453656,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.45129,
    "out:CO2 Operational/m2": -64.153865,
    "out:Total CO2/m2": -28.702575,
    "out:Total CO2 (tons)": -75.459053,
    "out:Klimatpaverkan": -195.598126,
    "out:Initial Cost/MSEK": 5.072014193015603,
    "out:Running cost/(Apt SEK y)": 4334.631579,
    "out:Running Cost over RSP/MSEK": 1.2957,
    "out:LCP/MSEK": 1.610376,
    "out:ROI% old": 18.82146,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 242.1,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 1790.180436,
    "out:Return %": 17,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.943267,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37219.694398,
    "out:PV (% sold (El))": 53.696935,
    "out:PV (kWh self-consumed)": 32094.680324,
    "out:PV (ratio sold/self-consumed)": 1.159684,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 300761.691306,
    "out:EL kWh savings": -55944.068841,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 244703.1,
    "out:DH kr savings": 300761.6913060554,
    "out:El kr savings": -111888.13768215162,
    "out:El kr sold": 55829.54159679482,
    "out:El kr saved": 64189.360648,
    "out:El kr return": 120018.90224479482,
    "out:% solar/total": 230,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 29127.218073,
    "out:% savings (space heating)": 89.778464,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 13.64,
    "out:Etvv": 0,
    "out:Ef": 26.58,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5072014.193015602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -68.113689,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.465532,
    "out:Bought CO2": 3.351843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.750139,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.052925,
    "out:Electricity (inc PV)": 26.25184,
    "out:Total Energy Use Pre PV": 46.052925,
    "out:Total Energy Use Post PV": 35.25184,
    "out:Primary Energy": 55.887272,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 35.45129,
    "out:CO2 Operational/m2": -65.515641,
    "out:Total CO2/m2": -30.064351,
    "out:Total CO2 (tons)": -79.039161,
    "out:Klimatpaverkan": -199.178234,
    "out:Initial Cost/MSEK": 5.137739180515602,
    "out:Running cost/(Apt SEK y)": 3852.973684,
    "out:Running Cost over RSP/MSEK": 1.15275,
    "out:LCP/MSEK": 1.687601,
    "out:ROI% old": 18.978164,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1763.648101,
    "out:Return %": 17,
    "out:Return/kSEK/y": 874,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.453425,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37522.604043,
    "out:PV (% sold (El))": 54.133943,
    "out:PV (kWh self-consumed)": 31791.770678,
    "out:PV (ratio sold/self-consumed)": 1.180262,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 306884.799172,
    "out:EL kWh savings": -55082.374852,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253003.96,
    "out:DH kr savings": 306884.79917160416,
    "out:El kr savings": -110164.74970350304,
    "out:El kr sold": 56283.90606471769,
    "out:El kr saved": 63583.541356,
    "out:El kr return": 119867.44742071768,
    "out:% solar/total": 228,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 23004.110207,
    "out:% savings (space heating)": 91.92723,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.1,
    "out:Etvv": 0,
    "out:Ef": 26.25,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5137739.180515602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -68.755497,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.057758,
    "out:Bought CO2": 3.302261,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.305945,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.00027,
    "out:Electricity (inc PV)": 25.996787,
    "out:Total Energy Use Pre PV": 47.00027,
    "out:Total Energy Use Post PV": 35.996787,
    "out:Primary Energy": 56.431733,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.166391,
    "out:CO2 Operational/m2": -65.224883,
    "out:Total CO2/m2": -29.058492,
    "out:Total CO2 (tons)": -76.394758,
    "out:Klimatpaverkan": -196.533832,
    "out:Initial Cost/MSEK": 5.299694193015601,
    "out:Running cost/(Apt SEK y)": 4055.052632,
    "out:Running Cost over RSP/MSEK": 1.21284,
    "out:LCP/MSEK": 1.465556,
    "out:ROI% old": 18.236227,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 261.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 1760.799696,
    "out:Return %": 16,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 29.40077,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37555.290308,
    "out:PV (% sold (El))": 54.181099,
    "out:PV (kWh self-consumed)": 31759.084413,
    "out:PV (ratio sold/self-consumed)": 1.182505,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 302794.586126,
    "out:EL kWh savings": -54411.840667,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250303.84,
    "out:DH kr savings": 302794.5861264204,
    "out:El kr savings": -108823.6813340363,
    "out:El kr sold": 56332.93546234559,
    "out:El kr saved": 63518.168826,
    "out:El kr return": 119851.10428834558,
    "out:% solar/total": 228,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 27094.323252,
    "out:% savings (space heating)": 90.491862,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 12.39,
    "out:Etvv": 0,
    "out:Ef": 26,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5299694.1930156015,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -68.824723,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.121661,
    "out:Bought CO2": 3.296938,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.049902,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.4883,
    "out:Electricity (inc PV)": 25.646352,
    "out:Total Energy Use Pre PV": 44.4883,
    "out:Total Energy Use Post PV": 33.646352,
    "out:Primary Energy": 53.904138,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 36.166391,
    "out:CO2 Operational/m2": -66.620457,
    "out:Total CO2/m2": -30.454066,
    "out:Total CO2 (tons)": -80.063721,
    "out:Klimatpaverkan": -200.202795,
    "out:Initial Cost/MSEK": 5.365419180515603,
    "out:Running cost/(Apt SEK y)": 3570.552632,
    "out:Running Cost over RSP/MSEK": 1.06905,
    "out:LCP/MSEK": 1.543621,
    "out:ROI% old": 18.395687,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 282.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 1733.14103,
    "out:Return %": 16,
    "out:Return/kSEK/y": 885,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.8888,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37874.744853,
    "out:PV (% sold (El))": 54.641977,
    "out:PV (kWh self-consumed)": 31439.629868,
    "out:PV (ratio sold/self-consumed)": 1.204682,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 308725.721827,
    "out:EL kWh savings": -53490.547261,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258556.74,
    "out:DH kr savings": 308725.7218265627,
    "out:El kr savings": -106981.0945224557,
    "out:El kr sold": 56812.11727974859,
    "out:El kr saved": 62879.259736,
    "out:El kr return": 119691.37701574859,
    "out:% solar/total": 226,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 21163.187552,
    "out:% savings (space heating)": 92.57326,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.95,
    "out:Etvv": 0,
    "out:Ef": 25.65,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5365419.180515602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -69.500329,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.745571,
    "out:Bought CO2": 3.245242,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.054186,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.817621,
    "out:Electricity (inc PV)": 38.1487,
    "out:Total Energy Use Pre PV": 46.817621,
    "out:Total Energy Use Post PV": 48.1487,
    "out:Primary Energy": 77.992925,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.31169,
    "out:CO2 Operational/m2": 8.460061,
    "out:Total CO2/m2": 14.771751,
    "out:Total CO2 (tons)": 38.834925,
    "out:Klimatpaverkan": -81.304149,
    "out:Initial Cost/MSEK": 3.882341612455602,
    "out:Running cost/(Apt SEK y)": 7183.763158,
    "out:Running Cost over RSP/MSEK": 2.1176,
    "out:LCP/MSEK": 1.978148,
    "out:ROI% old": 21.564637,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 170.8,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2577.23347,
    "out:Return %": 19,
    "out:Return/kSEK/y": 747,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 29.218121,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303456.460387,
    "out:EL kWh savings": -86359.212689,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130738.04,
    "out:DH kr savings": 303456.46038712026,
    "out:El kr savings": -172718.42537865214,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 26432.448992,
    "out:% savings (space heating)": 90.724132,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.99,
    "out:Etvv": 0,
    "out:Ef": 38.15,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3882341.6124556023,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": 4.860221,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.860221,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.823543,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.297954,
    "out:Electricity (inc PV)": 37.682311,
    "out:Total Energy Use Pre PV": 44.297954,
    "out:Total Energy Use Post PV": 45.682311,
    "out:Primary Energy": 75.281733,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.31169,
    "out:CO2 Operational/m2": 7.671492,
    "out:Total CO2/m2": 13.983182,
    "out:Total CO2 (tons)": 36.761777,
    "out:Klimatpaverkan": -83.377296,
    "out:Initial Cost/MSEK": 3.9480665999556024,
    "out:Running cost/(Apt SEK y)": 6693.973684,
    "out:Running Cost over RSP/MSEK": 1.97227,
    "out:LCP/MSEK": 2.057753,
    "out:ROI% old": 21.731505,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 182.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2540.85678,
    "out:Return %": 19,
    "out:Return/kSEK/y": 766,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.698454,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 309320.819502,
    "out:EL kWh savings": -85133.076287,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139054.67,
    "out:DH kr savings": 309320.81950242637,
    "out:El kr savings": -170266.1525735207,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 20568.089876,
    "out:% savings (space heating)": 92.782096,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.58,
    "out:Etvv": 0,
    "out:Ef": 37.68,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3948066.5999556025,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": 4.79162,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.79162,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.462293,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.375528,
    "out:Electricity (inc PV)": 37.523877,
    "out:Total Energy Use Pre PV": 45.375528,
    "out:Total Energy Use Post PV": 46.523877,
    "out:Primary Energy": 76.138622,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.026791,
    "out:CO2 Operational/m2": 8.041717,
    "out:Total CO2/m2": 15.068508,
    "out:Total CO2 (tons)": 39.615099,
    "out:Klimatpaverkan": -80.523975,
    "out:Initial Cost/MSEK": 4.110021612455602,
    "out:Running cost/(Apt SEK y)": 6913.657895,
    "out:Running Cost over RSP/MSEK": 2.03753,
    "out:LCP/MSEK": 1.830538,
    "out:ROI% old": 20.648345,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 176.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2546.28696,
    "out:Return %": 18,
    "out:Return/kSEK/y": 758,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.776028,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 305012.546731,
    "out:EL kWh savings": -84716.553395,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135579.44,
    "out:DH kr savings": 305012.54673076014,
    "out:El kr savings": -169433.10679069086,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24876.362648,
    "out:% savings (space heating)": 91.270205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.05,
    "out:Etvv": 0,
    "out:Ef": 37.52,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4110021.6124556023,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 4.801861,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.801861,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.295339,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.836561,
    "out:Electricity (inc PV)": 37.033276,
    "out:Total Energy Use Pre PV": 42.836561,
    "out:Total Energy Use Post PV": 44.033276,
    "out:Primary Energy": 73.445993,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 7.026791,
    "out:CO2 Operational/m2": 7.250601,
    "out:Total CO2/m2": 14.277392,
    "out:Total CO2 (tons)": 37.535255,
    "out:Klimatpaverkan": -82.603818,
    "out:Initial Cost/MSEK": 4.175746599955603,
    "out:Running cost/(Apt SEK y)": 6421.210526,
    "out:Running Cost over RSP/MSEK": 1.89141,
    "out:LCP/MSEK": 1.910933,
    "out:ROI% old": 20.823239,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 195.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2508.55927,
    "out:Return %": 19,
    "out:Return/kSEK/y": 776,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.237061,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 310709.467503,
    "out:EL kWh savings": -83426.763659,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143855.94,
    "out:DH kr savings": 310709.4675030886,
    "out:El kr savings": -166853.52731846843,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 19179.441876,
    "out:% savings (space heating)": 93.26941,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.72,
    "out:Etvv": 0,
    "out:Ef": 37.03,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4175746.5999556025,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 4.730713,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.730713,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.054186,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.817621,
    "out:Electricity (inc PV)": 28.477952,
    "out:Total Energy Use Pre PV": 46.817621,
    "out:Total Energy Use Post PV": 38.477952,
    "out:Primary Energy": 60.585579,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.88149,
    "out:CO2 Operational/m2": -11.235312,
    "out:Total CO2/m2": 9.646178,
    "out:Total CO2 (tons)": 25.359796,
    "out:Klimatpaverkan": -94.779277,
    "out:Initial Cost/MSEK": 4.527324848966852,
    "out:Running cost/(Apt SEK y)": 5488,
    "out:Running Cost over RSP/MSEK": 1.62701,
    "out:LCP/MSEK": 1.823755,
    "out:ROI% old": 20.040469,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 242.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 1933.253758,
    "out:Return %": 18,
    "out:Return/kSEK/y": 812,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 29.218121,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9751.141805,
    "out:PV (% sold (El))": 28.135987,
    "out:PV (kWh self-consumed)": 24906.045556,
    "out:PV (ratio sold/self-consumed)": 0.391517,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303456.460387,
    "out:EL kWh savings": -60934.821971,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196213.53,
    "out:DH kr savings": 303456.46038712026,
    "out:El kr savings": -121869.64394152531,
    "out:El kr sold": 14626.712707211516,
    "out:El kr saved": 49812.091112,
    "out:El kr return": 64438.80381921152,
    "out:% solar/total": 179,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 26432.448992,
    "out:% savings (space heating)": 90.724132,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.99,
    "out:Etvv": 0,
    "out:Ef": 28.48,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4527324.848966852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -14.835152,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.453268,
    "out:Bought CO2": 3.618116,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.823543,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.297954,
    "out:Electricity (inc PV)": 28.079736,
    "out:Total Energy Use Pre PV": 44.297954,
    "out:Total Energy Use Post PV": 36.079736,
    "out:Primary Energy": 57.997098,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.88149,
    "out:CO2 Operational/m2": -12.416204,
    "out:Total CO2/m2": 8.465286,
    "out:Total CO2 (tons)": 22.255232,
    "out:Klimatpaverkan": -97.883842,
    "out:Initial Cost/MSEK": 4.593049836466853,
    "out:Running cost/(Apt SEK y)": 5000.947368,
    "out:Running Cost over RSP/MSEK": 1.48247,
    "out:LCP/MSEK": 1.90257,
    "out:ROI% old": 20.203258,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 261.1,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 1902.000792,
    "out:Return %": 18,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.698454,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9958.386198,
    "out:PV (% sold (El))": 28.733971,
    "out:PV (kWh self-consumed)": 24698.801163,
    "out:PV (ratio sold/self-consumed)": 0.403193,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 309320.819502,
    "out:EL kWh savings": -59887.912344,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204482.57,
    "out:DH kr savings": 309320.81950242637,
    "out:El kr savings": -119775.82468899523,
    "out:El kr sold": 14937.579297132339,
    "out:El kr saved": 49397.602326,
    "out:El kr return": 64335.18162313234,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 20568.089876,
    "out:% savings (space heating)": 92.782096,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.58,
    "out:Etvv": 0,
    "out:Ef": 28.08,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4593049.836466853,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -15.296076,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.855845,
    "out:Bought CO2": 3.559769,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.462293,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.375528,
    "out:Electricity (inc PV)": 27.944665,
    "out:Total Energy Use Pre PV": 45.375528,
    "out:Total Energy Use Post PV": 36.944665,
    "out:Primary Energy": 58.89604,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.596591,
    "out:CO2 Operational/m2": -11.987076,
    "out:Total CO2/m2": 9.609515,
    "out:Total CO2 (tons)": 25.263409,
    "out:Klimatpaverkan": -94.875664,
    "out:Initial Cost/MSEK": 4.755004848966852,
    "out:Running cost/(Apt SEK y)": 5220.210526,
    "out:Running Cost over RSP/MSEK": 1.54761,
    "out:LCP/MSEK": 1.675475,
    "out:ROI% old": 19.319434,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 251.4,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1906.660959,
    "out:Return %": 17,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.776028,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9927.337015,
    "out:PV (% sold (El))": 28.644382,
    "out:PV (kWh self-consumed)": 24729.850346,
    "out:PV (ratio sold/self-consumed)": 0.401431,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 305012.546731,
    "out:EL kWh savings": -59532.810766,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200837.93,
    "out:DH kr savings": 305012.54673076014,
    "out:El kr savings": -119065.62153226999,
    "out:El kr sold": 14891.00552244439,
    "out:El kr saved": 49459.700692,
    "out:El kr return": 64350.70621444439,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24876.362648,
    "out:% savings (space heating)": 91.270205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.05,
    "out:Etvv": 0,
    "out:Ef": 27.94,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4755004.848966852,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -15.226932,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.795402,
    "out:Bought CO2": 3.56847,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.295339,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.836561,
    "out:Electricity (inc PV)": 27.527218,
    "out:Total Energy Use Pre PV": 42.836561,
    "out:Total Energy Use Post PV": 34.527218,
    "out:Primary Energy": 56.335088,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.596591,
    "out:CO2 Operational/m2": -13.189866,
    "out:Total CO2/m2": 8.406725,
    "out:Total CO2 (tons)": 22.101275,
    "out:Klimatpaverkan": -98.037799,
    "out:Initial Cost/MSEK": 4.820729836466852,
    "out:Running cost/(Apt SEK y)": 4730.631579,
    "out:Running Cost over RSP/MSEK": 1.40233,
    "out:LCP/MSEK": 1.75503,
    "out:ROI% old": 19.486557,
    "out:Payback discounted": 6,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1874.320553,
    "out:Return %": 17,
    "out:Return/kSEK/y": 841,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.237061,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10144.594417,
    "out:PV (% sold (El))": 29.271257,
    "out:PV (kWh self-consumed)": 24512.592944,
    "out:PV (ratio sold/self-consumed)": 0.413852,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 310709.467503,
    "out:EL kWh savings": -58435.342852,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209055.67,
    "out:DH kr savings": 310709.4675030886,
    "out:El kr savings": -116870.68570470173,
    "out:El kr sold": 15216.891625355844,
    "out:El kr saved": 49025.185888,
    "out:El kr return": 64242.07751335584,
    "out:% solar/total": 176,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 19179.441876,
    "out:% savings (space heating)": 93.26941,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.72,
    "out:Etvv": 0,
    "out:Ef": 27.53,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4820729.836466853,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -15.709754,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.217835,
    "out:Bought CO2": 3.508081,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.054186,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.817621,
    "out:Electricity (inc PV)": 25.799366,
    "out:Total Energy Use Pre PV": 46.817621,
    "out:Total Energy Use Post PV": 35.799366,
    "out:Primary Energy": 55.764124,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 35.45129,
    "out:CO2 Operational/m2": -65.465388,
    "out:Total CO2/m2": -30.014098,
    "out:Total CO2 (tons)": -78.907046,
    "out:Klimatpaverkan": -199.046119,
    "out:Initial Cost/MSEK": 5.172308068015602,
    "out:Running cost/(Apt SEK y)": 4031.289474,
    "out:Running Cost over RSP/MSEK": 1.20585,
    "out:LCP/MSEK": 1.599932,
    "out:ROI% old": 18.704664,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 261.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 1750.924679,
    "out:Return %": 17,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 29.218121,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37668.929751,
    "out:PV (% sold (El))": 54.345047,
    "out:PV (kWh self-consumed)": 31645.44497,
    "out:PV (ratio sold/self-consumed)": 1.190343,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303456.460387,
    "out:EL kWh savings": -53892.820976,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252174.21,
    "out:DH kr savings": 303456.46038712026,
    "out:El kr savings": -107785.64195175694,
    "out:El kr sold": 56503.394626703055,
    "out:El kr saved": 63290.88994,
    "out:El kr return": 119794.28456670305,
    "out:% solar/total": 227,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 26432.448992,
    "out:% savings (space heating)": 90.724132,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.99,
    "out:Etvv": 0,
    "out:Ef": 25.8,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5172308.068015602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -69.065228,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.343709,
    "out:Bought CO2": 3.278481,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.823543,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.297954,
    "out:Electricity (inc PV)": 25.44171,
    "out:Total Energy Use Pre PV": 44.297954,
    "out:Total Energy Use Post PV": 33.44171,
    "out:Primary Energy": 53.248651,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 35.45129,
    "out:CO2 Operational/m2": -66.872938,
    "out:Total CO2/m2": -31.421648,
    "out:Total CO2 (tons)": -82.607494,
    "out:Klimatpaverkan": -202.746567,
    "out:Initial Cost/MSEK": 5.2380330555156025,
    "out:Running cost/(Apt SEK y)": 3545.789474,
    "out:Running Cost over RSP/MSEK": 1.06177,
    "out:LCP/MSEK": 1.678287,
    "out:ROI% old": 18.862915,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 293.9,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 1722.874849,
    "out:Return %": 17,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.698454,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37994.380066,
    "out:PV (% sold (El))": 54.814575,
    "out:PV (kWh self-consumed)": 31319.994656,
    "out:PV (ratio sold/self-consumed)": 1.213103,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 309320.819502,
    "out:EL kWh savings": -52952.543565,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260407.3,
    "out:DH kr savings": 309320.81950242637,
    "out:El kr savings": -105905.08713079829,
    "out:El kr sold": 56991.57009872768,
    "out:El kr saved": 62639.989312,
    "out:El kr return": 119631.55941072767,
    "out:% solar/total": 225,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 20568.089876,
    "out:% savings (space heating)": 92.782096,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.58,
    "out:Etvv": 0,
    "out:Ef": 25.44,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5238033.055515602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -69.75281,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.978866,
    "out:Bought CO2": 3.226056,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.462293,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.375528,
    "out:Electricity (inc PV)": 25.320361,
    "out:Total Energy Use Pre PV": 45.375528,
    "out:Total Energy Use Post PV": 34.320361,
    "out:Primary Energy": 54.172293,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 36.166391,
    "out:CO2 Operational/m2": -66.409953,
    "out:Total CO2/m2": -30.243562,
    "out:Total CO2 (tons)": -79.510306,
    "out:Klimatpaverkan": -199.64938,
    "out:Initial Cost/MSEK": 5.3999880680156025,
    "out:Running cost/(Apt SEK y)": 3764.815789,
    "out:Running Cost over RSP/MSEK": 1.12683,
    "out:LCP/MSEK": 1.451272,
    "out:ROI% old": 18.125067,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 282.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1727.057603,
    "out:Return %": 16,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.776028,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37945.589349,
    "out:PV (% sold (El))": 54.744185,
    "out:PV (kWh self-consumed)": 31368.785373,
    "out:PV (ratio sold/self-consumed)": 1.209661,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 305012.546731,
    "out:EL kWh savings": -52633.517117,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256663.9,
    "out:DH kr savings": 305012.54673076014,
    "out:El kr savings": -105267.03423368892,
    "out:El kr sold": 56918.38402286066,
    "out:El kr saved": 62737.570746,
    "out:El kr return": 119655.95476886065,
    "out:% solar/total": 225,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24876.362648,
    "out:% savings (space heating)": 91.270205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.05,
    "out:Etvv": 0,
    "out:Ef": 25.32,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5399988.068015602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -69.649809,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.883682,
    "out:Bought CO2": 3.233873,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.295339,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.836561,
    "out:Electricity (inc PV)": 24.945188,
    "out:Total Energy Use Pre PV": 42.836561,
    "out:Total Energy Use Post PV": 31.945188,
    "out:Primary Energy": 51.687434,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 36.166391,
    "out:CO2 Operational/m2": -67.848255,
    "out:Total CO2/m2": -31.681864,
    "out:Total CO2 (tons)": -83.291602,
    "out:Klimatpaverkan": -203.430675,
    "out:Initial Cost/MSEK": 5.465713055515602,
    "out:Running cost/(Apt SEK y)": 3276.842105,
    "out:Running Cost over RSP/MSEK": 0.98202,
    "out:LCP/MSEK": 1.530357,
    "out:ROI% old": 18.285603,
    "out:Payback discounted": 7,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 306.2,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1698.030566,
    "out:Return %": 16,
    "out:Return/kSEK/y": 896,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.237061,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38286.309241,
    "out:PV (% sold (El))": 55.235742,
    "out:PV (kWh self-consumed)": 31028.06548,
    "out:PV (ratio sold/self-consumed)": 1.233925,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 310709.467503,
    "out:EL kWh savings": -51647.187524,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264844.56,
    "out:DH kr savings": 310709.4675030886,
    "out:El kr savings": -103294.37504764553,
    "out:El kr sold": 57429.46386208332,
    "out:El kr saved": 62056.13096,
    "out:El kr return": 119485.59482208332,
    "out:% solar/total": 223,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 19179.441876,
    "out:% savings (space heating)": 93.26941,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.72,
    "out:Etvv": 0,
    "out:Ef": 24.95,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5465713.055515602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -70.368143,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.54778,
    "out:Bought CO2": 3.179637,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 114.3,
    "out:Primary Energy": 98.187116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.068264,
    "out:CO2 Operational/m2": 39.937898,
    "out:Total CO2/m2": 51.006162,
    "out:Total CO2 (tons)": 134.095169,
    "out:Klimatpaverkan": 13.956096,
    "out:Initial Cost/MSEK": 2.098125,
    "out:Running cost/(Apt SEK y)": 23507.394737,
    "out:Running Cost over RSP/MSEK": 6.98447,
    "out:LCP/MSEK": -1.104505,
    "out:ROI% old": 6.76536,
    "out:Payback discounted": 18,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 6,
    "out:Return/kSEK/y": 127,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 44471.94621,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 44471.95,
    "out:DH kr savings": 44471.94621019515,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2098125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 95.165278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.068264,
    "out:CO2 Operational/m2": 38.497962,
    "out:Total CO2/m2": 49.566226,
    "out:Total CO2 (tons)": 130.309579,
    "out:Klimatpaverkan": 10.170505,
    "out:Initial Cost/MSEK": 2.1638499875,
    "out:Running cost/(Apt SEK y)": 22671.657895,
    "out:Running Cost over RSP/MSEK": 6.73606,
    "out:LCP/MSEK": -0.92182,
    "out:ROI% old": 8.199869,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 7,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 54686.190163,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 54686.19,
    "out:DH kr savings": 54686.19016261814,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2163849.9875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 111.3,
    "out:Primary Energy": 95.562116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.783365,
    "out:CO2 Operational/m2": 38.857946,
    "out:Total CO2/m2": 50.641311,
    "out:Total CO2 (tons)": 133.135976,
    "out:Klimatpaverkan": 12.996903,
    "out:Initial Cost/MSEK": 2.325805,
    "out:Running cost/(Apt SEK y)": 22880.605263,
    "out:Running Cost over RSP/MSEK": 6.79817,
    "out:LCP/MSEK": -1.145885,
    "out:ROI% old": 7.247383,
    "out:Payback discounted": 17,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 17.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 6,
    "out:Return/kSEK/y": 151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 51211.38618,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51211.39,
    "out:DH kr savings": 51211.386179785666,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2325805,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 107.3,
    "out:Primary Energy": 92.540278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.783365,
    "out:CO2 Operational/m2": 37.41801,
    "out:Total CO2/m2": 49.201375,
    "out:Total CO2 (tons)": 129.350386,
    "out:Klimatpaverkan": 9.211312,
    "out:Initial Cost/MSEK": 2.3915299875,
    "out:Running cost/(Apt SEK y)": 22044.842105,
    "out:Running Cost over RSP/MSEK": 6.54976,
    "out:LCP/MSEK": -0.9632,
    "out:ROI% old": 8.532075,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 21.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 183,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 61425.630132,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 61425.63,
    "out:DH kr savings": 61425.63013220866,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2391529.9875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 112.138626,
    "out:Primary Energy": 94.296643,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.638064,
    "out:CO2 Operational/m2": -16.392149,
    "out:Total CO2/m2": 9.245915,
    "out:Total CO2 (tons)": 24.307505,
    "out:Klimatpaverkan": -95.831569,
    "out:Initial Cost/MSEK": 2.74310823651125,
    "out:Running cost/(Apt SEK y)": 22064.605263,
    "out:Running Cost over RSP/MSEK": 6.56754,
    "out:LCP/MSEK": -1.332558,
    "out:ROI% old": 7.345942,
    "out:Payback discounted": 17,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 16.1,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 7,
    "out:Return/kSEK/y": 182,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 44471.94621,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99298.85,
    "out:DH kr savings": 44471.94621019515,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2743108.23651125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 108.138626,
    "out:Primary Energy": 91.274805,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.638064,
    "out:CO2 Operational/m2": -17.832085,
    "out:Total CO2/m2": 7.805979,
    "out:Total CO2 (tons)": 20.521914,
    "out:Klimatpaverkan": -99.617159,
    "out:Initial Cost/MSEK": 2.8088332240112504,
    "out:Running cost/(Apt SEK y)": 21228.842105,
    "out:Running Cost over RSP/MSEK": 6.31913,
    "out:LCP/MSEK": -1.149873,
    "out:ROI% old": 8.437464,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 54686.190163,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109513.1,
    "out:DH kr savings": 54686.19016261814,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2808833.2240112503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 109.138626,
    "out:Primary Energy": 91.671643,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.353165,
    "out:CO2 Operational/m2": -17.472101,
    "out:Total CO2/m2": 8.881064,
    "out:Total CO2 (tons)": 23.348312,
    "out:Klimatpaverkan": -96.790762,
    "out:Initial Cost/MSEK": 2.97078823651125,
    "out:Running cost/(Apt SEK y)": 21437.789474,
    "out:Running Cost over RSP/MSEK": 6.38123,
    "out:LCP/MSEK": -1.373928,
    "out:ROI% old": 7.678866,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 7,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 51211.38618,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106038.29,
    "out:DH kr savings": 51211.386179785666,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2970788.23651125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 105.138626,
    "out:Primary Energy": 88.649805,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.353165,
    "out:CO2 Operational/m2": -18.912037,
    "out:Total CO2/m2": 7.441128,
    "out:Total CO2 (tons)": 19.562721,
    "out:Klimatpaverkan": -100.576352,
    "out:Initial Cost/MSEK": 3.0365132240112502,
    "out:Running cost/(Apt SEK y)": 20602.026316,
    "out:Running Cost over RSP/MSEK": 6.13283,
    "out:LCP/MSEK": -1.191253,
    "out:ROI% old": 8.681292,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61425.630132,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116252.54,
    "out:DH kr savings": 61425.63013220866,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3036513.2240112503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 112.026217,
    "out:Primary Energy": 94.094307,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.207864,
    "out:CO2 Operational/m2": -83.047353,
    "out:Total CO2/m2": -42.839489,
    "out:Total CO2 (tons)": -112.624991,
    "out:Klimatpaverkan": -232.764065,
    "out:Initial Cost/MSEK": 3.38809145556,
    "out:Running cost/(Apt SEK y)": 20692.657895,
    "out:Running Cost over RSP/MSEK": 6.17137,
    "out:LCP/MSEK": -1.581371,
    "out:ROI% old": 7.617942,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 16.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 7,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 44471.94621,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151432.4,
    "out:DH kr savings": 44471.94621019515,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3388091.45556,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 108.026217,
    "out:Primary Energy": 91.072469,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.207864,
    "out:CO2 Operational/m2": -84.487289,
    "out:Total CO2/m2": -44.279425,
    "out:Total CO2 (tons)": -116.410582,
    "out:Klimatpaverkan": -236.549655,
    "out:Initial Cost/MSEK": 3.4538164430600005,
    "out:Running cost/(Apt SEK y)": 19856.921053,
    "out:Running Cost over RSP/MSEK": 5.92297,
    "out:LCP/MSEK": -1.398696,
    "out:ROI% old": 8.50041,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 54686.190163,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161646.64,
    "out:DH kr savings": 54686.19016261814,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3453816.4430600004,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 109.026217,
    "out:Primary Energy": 91.469307,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.922965,
    "out:CO2 Operational/m2": -84.127305,
    "out:Total CO2/m2": -43.20434,
    "out:Total CO2 (tons)": -113.584184,
    "out:Klimatpaverkan": -233.723258,
    "out:Initial Cost/MSEK": 3.61577145556,
    "out:Running cost/(Apt SEK y)": 20065.842105,
    "out:Running Cost over RSP/MSEK": 5.98507,
    "out:LCP/MSEK": -1.622751,
    "out:ROI% old": 7.874312,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 7,
    "out:Return/kSEK/y": 258,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 51211.38618,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158171.84,
    "out:DH kr savings": 51211.386179785666,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3615771.45556,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 105.026217,
    "out:Primary Energy": 88.447469,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.922965,
    "out:CO2 Operational/m2": -85.567241,
    "out:Total CO2/m2": -44.644276,
    "out:Total CO2 (tons)": -117.369775,
    "out:Klimatpaverkan": -237.508848,
    "out:Initial Cost/MSEK": 3.6814964430600003,
    "out:Running cost/(Apt SEK y)": 19230.105263,
    "out:Running Cost over RSP/MSEK": 5.73666,
    "out:LCP/MSEK": -1.440066,
    "out:ROI% old": 8.697667,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 61425.630132,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168386.08,
    "out:DH kr savings": 61425.63013220866,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3681496.4430600004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 94.687116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.068264,
    "out:CO2 Operational/m2": 38.497962,
    "out:Total CO2/m2": 49.566226,
    "out:Total CO2 (tons)": 130.309579,
    "out:Klimatpaverkan": 10.170505,
    "out:Initial Cost/MSEK": 2.198418875,
    "out:Running cost/(Apt SEK y)": 22671.657895,
    "out:Running Cost over RSP/MSEK": 6.73606,
    "out:LCP/MSEK": -0.956389,
    "out:ROI% old": 8.07093,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 7,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 53457.86617,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53457.87,
    "out:DH kr savings": 53457.86616964917,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2198418.875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 106.3,
    "out:Primary Energy": 91.665278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.068264,
    "out:CO2 Operational/m2": 37.058026,
    "out:Total CO2/m2": 48.12629,
    "out:Total CO2 (tons)": 126.523988,
    "out:Klimatpaverkan": 6.384914,
    "out:Initial Cost/MSEK": 2.2641438625,
    "out:Running cost/(Apt SEK y)": 21835.921053,
    "out:Running Cost over RSP/MSEK": 6.48766,
    "out:LCP/MSEK": -0.773714,
    "out:ROI% old": 9.403933,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 191,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63672.110122,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63672.11,
    "out:DH kr savings": 63672.11012207216,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2264143.8625000003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 92.587116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.783365,
    "out:CO2 Operational/m2": 37.777994,
    "out:Total CO2/m2": 49.561359,
    "out:Total CO2 (tons)": 130.296783,
    "out:Klimatpaverkan": 10.15771,
    "out:Initial Cost/MSEK": 2.426098875,
    "out:Running cost/(Apt SEK y)": 22253.789474,
    "out:Running Cost over RSP/MSEK": 6.61186,
    "out:LCP/MSEK": -1.059869,
    "out:ROI% old": 8.044838,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 7,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 58849.418145,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 58849.42,
    "out:DH kr savings": 58849.41814532156,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2426098.875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 104.3,
    "out:Primary Energy": 89.565278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.783365,
    "out:CO2 Operational/m2": 36.338058,
    "out:Total CO2/m2": 48.121423,
    "out:Total CO2 (tons)": 126.511192,
    "out:Klimatpaverkan": 6.372119,
    "out:Initial Cost/MSEK": 2.4918238625000004,
    "out:Running cost/(Apt SEK y)": 21418.052632,
    "out:Running Cost over RSP/MSEK": 6.36346,
    "out:LCP/MSEK": -0.877194,
    "out:ROI% old": 9.256731,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 69063.662098,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69063.66,
    "out:DH kr savings": 69063.66209774454,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2491823.8625000003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 108.138626,
    "out:Primary Energy": 90.796643,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.638064,
    "out:CO2 Operational/m2": -17.832085,
    "out:Total CO2/m2": 7.805979,
    "out:Total CO2 (tons)": 20.521914,
    "out:Klimatpaverkan": -99.617159,
    "out:Initial Cost/MSEK": 2.84340211151125,
    "out:Running cost/(Apt SEK y)": 21228.842105,
    "out:Running Cost over RSP/MSEK": 6.31913,
    "out:LCP/MSEK": -1.184442,
    "out:ROI% old": 8.334885,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53457.86617,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108284.77,
    "out:DH kr savings": 53457.86616964917,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2843402.11151125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 104.138626,
    "out:Primary Energy": 87.774805,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.638064,
    "out:CO2 Operational/m2": -19.272021,
    "out:Total CO2/m2": 6.366043,
    "out:Total CO2 (tons)": 16.736323,
    "out:Klimatpaverkan": -103.40275,
    "out:Initial Cost/MSEK": 2.9091270990112506,
    "out:Running cost/(Apt SEK y)": 20393.105263,
    "out:Running Cost over RSP/MSEK": 6.07073,
    "out:LCP/MSEK": -1.001767,
    "out:ROI% old": 9.366383,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 245,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 63672.110122,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118499.02,
    "out:DH kr savings": 63672.11012207216,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2909127.099011251,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 106.138626,
    "out:Primary Energy": 88.696643,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.353165,
    "out:CO2 Operational/m2": -18.552053,
    "out:Total CO2/m2": 7.801112,
    "out:Total CO2 (tons)": 20.509119,
    "out:Klimatpaverkan": -99.629955,
    "out:Initial Cost/MSEK": 3.07108211151125,
    "out:Running cost/(Apt SEK y)": 20810.973684,
    "out:Running Cost over RSP/MSEK": 6.19493,
    "out:LCP/MSEK": -1.287922,
    "out:ROI% old": 8.294703,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 7,
    "out:Return/kSEK/y": 229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 58849.418145,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113676.32,
    "out:DH kr savings": 58849.41814532156,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3071082.11151125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 102.138626,
    "out:Primary Energy": 85.674805,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.353165,
    "out:CO2 Operational/m2": -19.991989,
    "out:Total CO2/m2": 6.361176,
    "out:Total CO2 (tons)": 16.723528,
    "out:Klimatpaverkan": -103.415546,
    "out:Initial Cost/MSEK": 3.136807099011251,
    "out:Running cost/(Apt SEK y)": 19975.236842,
    "out:Running Cost over RSP/MSEK": 5.94653,
    "out:LCP/MSEK": -1.105247,
    "out:ROI% old": 9.252174,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69063.662098,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123890.57,
    "out:DH kr savings": 69063.66209774454,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3136807.099011251,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 108.026217,
    "out:Primary Energy": 90.594307,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.207864,
    "out:CO2 Operational/m2": -84.487289,
    "out:Total CO2/m2": -44.279425,
    "out:Total CO2 (tons)": -116.410582,
    "out:Klimatpaverkan": -236.549655,
    "out:Initial Cost/MSEK": 3.4883853305600003,
    "out:Running cost/(Apt SEK y)": 19856.921053,
    "out:Running Cost over RSP/MSEK": 5.92297,
    "out:LCP/MSEK": -1.433265,
    "out:ROI% old": 8.416174,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 53457.86617,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160418.32,
    "out:DH kr savings": 53457.86616964917,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3488385.33056,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 104.026217,
    "out:Primary Energy": 87.572469,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.207864,
    "out:CO2 Operational/m2": -85.927225,
    "out:Total CO2/m2": -45.719361,
    "out:Total CO2 (tons)": -120.196173,
    "out:Klimatpaverkan": -240.335246,
    "out:Initial Cost/MSEK": 3.55411031806,
    "out:Running cost/(Apt SEK y)": 19021.157895,
    "out:Running Cost over RSP/MSEK": 5.67456,
    "out:LCP/MSEK": -1.25058,
    "out:ROI% old": 9.259018,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 63672.110122,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170632.56,
    "out:DH kr savings": 63672.11012207216,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3554110.3180600004,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.474864,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 106.026217,
    "out:Primary Energy": 88.494307,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.922965,
    "out:CO2 Operational/m2": -85.207257,
    "out:Total CO2/m2": -44.284292,
    "out:Total CO2 (tons)": -116.423377,
    "out:Klimatpaverkan": -236.562451,
    "out:Initial Cost/MSEK": 3.71606533056,
    "out:Running cost/(Apt SEK y)": 19439.052632,
    "out:Running Cost over RSP/MSEK": 5.79876,
    "out:LCP/MSEK": -1.536735,
    "out:ROI% old": 8.378024,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 58849.418145,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165809.87,
    "out:DH kr savings": 58849.41814532156,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 240487.363371,
    "out:% savings (space heating)": 15.60642,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.47,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3716065.33056,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.589643,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 102.026217,
    "out:Primary Energy": 85.472469,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.922965,
    "out:CO2 Operational/m2": -86.647193,
    "out:Total CO2/m2": -45.724228,
    "out:Total CO2 (tons)": -120.208968,
    "out:Klimatpaverkan": -240.348042,
    "out:Initial Cost/MSEK": 3.7817903180600005,
    "out:Running cost/(Apt SEK y)": 18603.289474,
    "out:Running Cost over RSP/MSEK": 5.55036,
    "out:LCP/MSEK": -1.35406,
    "out:ROI% old": 9.170751,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 69063.662098,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176024.11,
    "out:DH kr savings": 69063.66209774454,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 230273.119419,
    "out:% savings (space heating)": 19.190877,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.59,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3781790.3180600004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.26042,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.479634,
    "out:Electricity (inc PV)": 27.451256,
    "out:Total Energy Use Pre PV": 72.479634,
    "out:Total Energy Use Post PV": 73.451256,
    "out:Primary Energy": 89.050981,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.801353,
    "out:CO2 Operational/m2": 20.054788,
    "out:Total CO2/m2": 34.856141,
    "out:Total CO2 (tons)": 91.636774,
    "out:Klimatpaverkan": -28.5023,
    "out:Initial Cost/MSEK": 4.609484684370601,
    "out:Running cost/(Apt SEK y)": 13275,
    "out:Running Cost over RSP/MSEK": 3.93301,
    "out:LCP/MSEK": -0.564405,
    "out:ROI% old": 12.536511,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1853.57438,
    "out:Return %": 11,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.880134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 208270.292816,
    "out:EL kWh savings": -58235.6388,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91799.02,
    "out:DH kr savings": 208270.29281624197,
    "out:El kr savings": -116471.27759940033,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 121618.616563,
    "out:% savings (space heating)": 57.320708,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 50.96,
    "out:Etvv": 0,
    "out:Ef": 27.45,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4609484.684370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": 3.495524,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.495524,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.662165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.392675,
    "out:Electricity (inc PV)": 27.38266,
    "out:Total Energy Use Pre PV": 69.392675,
    "out:Total Energy Use Post PV": 70.38266,
    "out:Primary Energy": 86.081733,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.801353,
    "out:CO2 Operational/m2": 18.963356,
    "out:Total CO2/m2": 33.764709,
    "out:Total CO2 (tons)": 88.7674,
    "out:Klimatpaverkan": -31.371674,
    "out:Initial Cost/MSEK": 4.675209671870602,
    "out:Running cost/(Apt SEK y)": 12636.184211,
    "out:Running Cost over RSP/MSEK": 3.74318,
    "out:LCP/MSEK": -0.4403,
    "out:ROI% old": 12.940321,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 85.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1847.48725,
    "out:Return %": 12,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.793175,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 217730.103063,
    "out:EL kWh savings": -58055.299957,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101619.5,
    "out:DH kr savings": 217730.10306308372,
    "out:El kr savings": -116110.59991330396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 112158.806316,
    "out:% savings (space heating)": 60.640413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.31,
    "out:Etvv": 0,
    "out:Ef": 27.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4675209.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": 3.484044,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.484044,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.776839,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.152386,
    "out:Electricity (inc PV)": 26.997824,
    "out:Total Energy Use Pre PV": 71.152386,
    "out:Total Energy Use Post PV": 71.997824,
    "out:Primary Energy": 86.481945,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.516454,
    "out:CO2 Operational/m2": 19.651604,
    "out:Total CO2/m2": 35.168058,
    "out:Total CO2 (tons)": 92.456803,
    "out:Klimatpaverkan": -27.68227,
    "out:Initial Cost/MSEK": 4.8371646843706015,
    "out:Running cost/(Apt SEK y)": 13020.789474,
    "out:Running Cost over RSP/MSEK": 3.85761,
    "out:LCP/MSEK": -0.716685,
    "out:ROI% old": 12.169112,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 80.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 1830.66702,
    "out:Return %": 11,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.552886,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 212170.62638,
    "out:EL kWh savings": -57043.566342,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98083.49,
    "out:DH kr savings": 212170.6263795441,
    "out:El kr savings": -114087.13268479817,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 117718.282999,
    "out:% savings (space heating)": 58.689441,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 48.71,
    "out:Etvv": 0,
    "out:Ef": 27,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4837164.684370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.19928,
    "out:EL CO2": 3.452324,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.452324,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.205437,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.05729,
    "out:Electricity (inc PV)": 26.920788,
    "out:Total Energy Use Pre PV": 67.05729,
    "out:Total Energy Use Post PV": 67.920788,
    "out:Primary Energy": 83.519168,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.516454,
    "out:CO2 Operational/m2": 18.199115,
    "out:Total CO2/m2": 33.715569,
    "out:Total CO2 (tons)": 88.638211,
    "out:Klimatpaverkan": -31.500863,
    "out:Initial Cost/MSEK": 4.902889671870602,
    "out:Running cost/(Apt SEK y)": 12171.894737,
    "out:Running Cost over RSP/MSEK": 3.60534,
    "out:LCP/MSEK": -0.53014,
    "out:ROI% old": 12.741029,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 91.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1824.0103,
    "out:Return %": 11,
    "out:Return/kSEK/y": 558,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.45779,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 221559.840105,
    "out:EL kWh savings": -56841.038744,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107877.76,
    "out:DH kr savings": 221559.8401054171,
    "out:El kr savings": -113682.07748877912,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 108329.069273,
    "out:% savings (space heating)": 61.984373,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.08,
    "out:Etvv": 0,
    "out:Ef": 26.92,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4902889.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": 3.439771,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.439771,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.26042,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.479634,
    "out:Electricity (inc PV)": 19.544938,
    "out:Total Energy Use Pre PV": 72.479634,
    "out:Total Energy Use Post PV": 65.544938,
    "out:Primary Energy": 74.819608,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.371153,
    "out:CO2 Operational/m2": -8.380044,
    "out:Total CO2/m2": 20.991109,
    "out:Total CO2 (tons)": 55.185613,
    "out:Klimatpaverkan": -64.953461,
    "out:Initial Cost/MSEK": 5.2544679208818525,
    "out:Running cost/(Apt SEK y)": 11639.815789,
    "out:Running Cost over RSP/MSEK": 3.46,
    "out:LCP/MSEK": -0.736378,
    "out:ROI% old": 12.283669,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 97,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1325.913275,
    "out:Return %": 11,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.880134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14352.832279,
    "out:PV (% sold (El))": 41.413725,
    "out:PV (kWh self-consumed)": 20304.355082,
    "out:PV (ratio sold/self-consumed)": 0.706884,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 208270.292816,
    "out:EL kWh savings": -37449.933498,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154899.67,
    "out:DH kr savings": 208270.29281624197,
    "out:El kr savings": -74899.86699554403,
    "out:El kr sold": 21529.248418530467,
    "out:El kr saved": 40608.710164,
    "out:El kr return": 62137.95858253047,
    "out:% solar/total": 146,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 121618.616563,
    "out:% savings (space heating)": 57.320708,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 50.96,
    "out:Etvv": 0,
    "out:Ef": 19.54,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5254467.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -24.939308,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.421113,
    "out:Bought CO2": 2.481805,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.662165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.392675,
    "out:Electricity (inc PV)": 19.489171,
    "out:Total Energy Use Pre PV": 69.392675,
    "out:Total Energy Use Post PV": 62.489171,
    "out:Primary Energy": 71.873453,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.371153,
    "out:CO2 Operational/m2": -9.554392,
    "out:Total CO2/m2": 19.816761,
    "out:Total CO2 (tons)": 52.098253,
    "out:Klimatpaverkan": -68.040821,
    "out:Initial Cost/MSEK": 5.320192908381852,
    "out:Running cost/(Apt SEK y)": 11001.526316,
    "out:Running Cost over RSP/MSEK": 3.27032,
    "out:LCP/MSEK": -0.612423,
    "out:ROI% old": 12.641244,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1320.934511,
    "out:Return %": 11,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.793175,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14396.300836,
    "out:PV (% sold (El))": 41.539149,
    "out:PV (kWh self-consumed)": 20260.886524,
    "out:PV (ratio sold/self-consumed)": 0.710546,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 217730.103063,
    "out:EL kWh savings": -37303.322088,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164717.91,
    "out:DH kr savings": 217730.10306308372,
    "out:El kr savings": -74606.6441761298,
    "out:El kr sold": 21594.451254402462,
    "out:El kr saved": 40521.773048,
    "out:El kr return": 62116.224302402465,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 112158.806316,
    "out:% savings (space heating)": 60.640413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.31,
    "out:Etvv": 0,
    "out:Ef": 19.49,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5320192.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -25.033704,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.506197,
    "out:Bought CO2": 2.472493,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.776839,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.152386,
    "out:Electricity (inc PV)": 19.176527,
    "out:Total Energy Use Pre PV": 71.152386,
    "out:Total Energy Use Post PV": 64.176527,
    "out:Primary Energy": 72.403611,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.086254,
    "out:CO2 Operational/m2": -9.095906,
    "out:Total CO2/m2": 20.990348,
    "out:Total CO2 (tons)": 55.183612,
    "out:Klimatpaverkan": -64.955461,
    "out:Initial Cost/MSEK": 5.482147920881853,
    "out:Running cost/(Apt SEK y)": 11387.736842,
    "out:Running Cost over RSP/MSEK": 3.38522,
    "out:LCP/MSEK": -0.889278,
    "out:ROI% old": 11.96838,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 103.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 1307.189608,
    "out:Return %": 11,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.552886,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14516.71036,
    "out:PV (% sold (El))": 41.88658,
    "out:PV (kWh self-consumed)": 20140.477001,
    "out:PV (ratio sold/self-consumed)": 0.720773,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 212170.62638,
    "out:EL kWh savings": -36481.381199,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160982.93,
    "out:DH kr savings": 212170.6263795441,
    "out:El kr savings": -72962.76239742672,
    "out:El kr sold": 21775.065539250696,
    "out:El kr saved": 40280.954002,
    "out:El kr return": 62056.019541250695,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 117718.282999,
    "out:% savings (space heating)": 58.689441,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 48.71,
    "out:Etvv": 0,
    "out:Ef": 19.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5482147.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.19928,
    "out:EL CO2": -25.295186,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.741962,
    "out:Bought CO2": 2.446776,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.205437,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.05729,
    "out:Electricity (inc PV)": 19.114005,
    "out:Total Energy Use Pre PV": 67.05729,
    "out:Total Energy Use Post PV": 60.114005,
    "out:Primary Energy": 69.466959,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.086254,
    "out:CO2 Operational/m2": -10.639594,
    "out:Total CO2/m2": 19.44666,
    "out:Total CO2 (tons)": 51.125258,
    "out:Klimatpaverkan": -69.013816,
    "out:Initial Cost/MSEK": 5.547872908381852,
    "out:Running cost/(Apt SEK y)": 10539.473684,
    "out:Running Cost over RSP/MSEK": 3.13314,
    "out:LCP/MSEK": -0.702923,
    "out:ROI% old": 12.475695,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1301.754999,
    "out:Return %": 11,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.45779,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14564.501493,
    "out:PV (% sold (El))": 42.024476,
    "out:PV (kWh self-consumed)": 20092.685868,
    "out:PV (ratio sold/self-consumed)": 0.724866,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221559.840105,
    "out:EL kWh savings": -36317.010898,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170772.57,
    "out:DH kr savings": 221559.8401054171,
    "out:El kr savings": -72634.02179607806,
    "out:El kr sold": 21846.752239429414,
    "out:El kr saved": 40185.371736,
    "out:El kr return": 62032.123975429415,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 108329.069273,
    "out:% savings (space heating)": 61.984373,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.08,
    "out:Etvv": 0,
    "out:Ef": 19.11,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5547872.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -25.398938,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.835544,
    "out:Bought CO2": 2.436606,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.26042,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.479634,
    "out:Electricity (inc PV)": 17.791964,
    "out:Total Energy Use Pre PV": 72.479634,
    "out:Total Energy Use Post PV": 63.791964,
    "out:Primary Energy": 71.664255,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.940953,
    "out:CO2 Operational/m2": -67.127473,
    "out:Total CO2/m2": -23.18652,
    "out:Total CO2 (tons)": -60.957347,
    "out:Klimatpaverkan": -181.096421,
    "out:Initial Cost/MSEK": 5.899451139930602,
    "out:Running cost/(Apt SEK y)": 10214.026316,
    "out:Running Cost over RSP/MSEK": 3.04792,
    "out:LCP/MSEK": -0.969281,
    "out:ROI% old": 11.938568,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 103.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1206.404269,
    "out:Return %": 11,
    "out:Return/kSEK/y": 632,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.880134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44621.598085,
    "out:PV (% sold (El))": 64.375677,
    "out:PV (kWh self-consumed)": 24692.776636,
    "out:PV (ratio sold/self-consumed)": 1.807071,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 208270.292816,
    "out:EL kWh savings": -32841.365898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209519.96,
    "out:DH kr savings": 208270.29281624197,
    "out:El kr savings": -65682.73179659493,
    "out:El kr sold": 66932.39712777888,
    "out:El kr saved": 49385.553272,
    "out:El kr return": 116317.95039977887,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 121618.616563,
    "out:% savings (space heating)": 57.320708,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 50.96,
    "out:Etvv": 0,
    "out:Ef": 17.79,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5899451.139930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -83.686737,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -85.946934,
    "out:Bought CO2": 2.260197,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.662165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.392675,
    "out:Electricity (inc PV)": 17.742057,
    "out:Total Energy Use Pre PV": 69.392675,
    "out:Total Energy Use Post PV": 60.742057,
    "out:Primary Energy": 68.728648,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.940953,
    "out:CO2 Operational/m2": -68.339763,
    "out:Total CO2/m2": -24.39881,
    "out:Total CO2 (tons)": -64.144457,
    "out:Klimatpaverkan": -184.28353,
    "out:Initial Cost/MSEK": 5.965176127430602,
    "out:Running cost/(Apt SEK y)": 9576,
    "out:Running Cost over RSP/MSEK": 2.85832,
    "out:LCP/MSEK": -0.845406,
    "out:ROI% old": 12.261092,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1201.950459,
    "out:Return %": 11,
    "out:Return/kSEK/y": 656,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.793175,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44685.008811,
    "out:PV (% sold (El))": 64.46716,
    "out:PV (kWh self-consumed)": 24629.365911,
    "out:PV (ratio sold/self-consumed)": 1.814298,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 217730.103063,
    "out:EL kWh savings": -32710.160425,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219337.3,
    "out:DH kr savings": 217730.10306308372,
    "out:El kr savings": -65420.32085018395,
    "out:El kr sold": 67027.51321628205,
    "out:El kr saved": 49258.731822,
    "out:El kr return": 116286.24503828205,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 112158.806316,
    "out:% savings (space heating)": 60.640413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.31,
    "out:Etvv": 0,
    "out:Ef": 17.74,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5965176.127430602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -83.819075,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.07095,
    "out:Bought CO2": 2.251875,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.776839,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.152386,
    "out:Electricity (inc PV)": 17.462459,
    "out:Total Energy Use Pre PV": 71.152386,
    "out:Total Energy Use Post PV": 62.462459,
    "out:Primary Energy": 69.318288,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.656054,
    "out:CO2 Operational/m2": -67.986459,
    "out:Total CO2/m2": -23.330405,
    "out:Total CO2 (tons)": -61.335621,
    "out:Klimatpaverkan": -181.474694,
    "out:Initial Cost/MSEK": 6.127131139930603,
    "out:Running cost/(Apt SEK y)": 9962.947368,
    "out:Running Cost over RSP/MSEK": 2.97343,
    "out:LCP/MSEK": -1.122471,
    "out:ROI% old": 11.668616,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 1189.656977,
    "out:Return %": 10,
    "out:Return/kSEK/y": 642,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.552886,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44860.65624,
    "out:PV (% sold (El))": 64.720567,
    "out:PV (kWh self-consumed)": 24453.718482,
    "out:PV (ratio sold/self-consumed)": 1.834513,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 212170.62638,
    "out:EL kWh savings": -31975.09745,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215511.42,
    "out:DH kr savings": 212170.6263795441,
    "out:El kr savings": -63950.19490002392,
    "out:El kr sold": 67290.98435997528,
    "out:El kr saved": 48907.436964,
    "out:El kr return": 116198.42132397529,
    "out:% solar/total": 176,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 117718.282999,
    "out:% savings (space heating)": 58.689441,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 48.71,
    "out:Etvv": 0,
    "out:Ef": 17.46,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6127131.139930603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.19928,
    "out:EL CO2": -84.185739,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.414647,
    "out:Bought CO2": 2.228908,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.205437,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.05729,
    "out:Electricity (inc PV)": 17.4066,
    "out:Total Energy Use Pre PV": 67.05729,
    "out:Total Energy Use Post PV": 58.4066,
    "out:Primary Energy": 66.39363,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.656054,
    "out:CO2 Operational/m2": -69.571924,
    "out:Total CO2/m2": -24.91587,
    "out:Total CO2 (tons)": -65.503807,
    "out:Klimatpaverkan": -185.642881,
    "out:Initial Cost/MSEK": 6.192856127430603,
    "out:Running cost/(Apt SEK y)": 9114.947368,
    "out:Running Cost over RSP/MSEK": 2.72143,
    "out:LCP/MSEK": -0.936196,
    "out:ROI% old": 12.126092,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1184.797357,
    "out:Return %": 11,
    "out:Return/kSEK/y": 674,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.45779,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44930.431876,
    "out:PV (% sold (El))": 64.821232,
    "out:PV (kWh self-consumed)": 24383.942846,
    "out:PV (ratio sold/self-consumed)": 1.842624,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221559.840105,
    "out:EL kWh savings": -31828.244172,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225299,
    "out:DH kr savings": 221559.8401054171,
    "out:El kr savings": -63656.488344719575,
    "out:El kr sold": 67395.64781345098,
    "out:El kr saved": 48767.885692,
    "out:El kr return": 116163.53350545099,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 108329.069273,
    "out:% savings (space heating)": 61.984373,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.08,
    "out:Etvv": 0,
    "out:Ef": 17.41,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6192856.127430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -84.331268,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.551097,
    "out:Bought CO2": 2.219829,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.288075,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.041557,
    "out:Electricity (inc PV)": 26.843678,
    "out:Total Energy Use Pre PV": 70.041557,
    "out:Total Energy Use Post PV": 70.843678,
    "out:Primary Energy": 85.627903,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.801353,
    "out:CO2 Operational/m2": 19.27699,
    "out:Total CO2/m2": 34.078343,
    "out:Total CO2 (tons)": 89.591943,
    "out:Klimatpaverkan": -30.54713,
    "out:Initial Cost/MSEK": 4.709778559370601,
    "out:Running cost/(Apt SEK y)": 12796.526316,
    "out:Running Cost over RSP/MSEK": 3.79101,
    "out:LCP/MSEK": -0.522699,
    "out:ROI% old": 12.700263,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1822.90899,
    "out:Return %": 11,
    "out:Return/kSEK/y": 534,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.442057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 213455.586644,
    "out:EL kWh savings": -56638.3166,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100178.95,
    "out:DH kr savings": 213455.58664375197,
    "out:El kr savings": -113276.63320084845,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 116433.322735,
    "out:% savings (space heating)": 59.140369,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.97,
    "out:Etvv": 0,
    "out:Ef": 26.84,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4709778.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": 3.437694,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.437694,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.725589,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.943759,
    "out:Electricity (inc PV)": 26.76392,
    "out:Total Energy Use Pre PV": 66.943759,
    "out:Total Energy Use Post PV": 67.76392,
    "out:Primary Energy": 82.667211,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.801353,
    "out:CO2 Operational/m2": 18.184128,
    "out:Total CO2/m2": 32.985481,
    "out:Total CO2 (tons)": 86.71881,
    "out:Klimatpaverkan": -33.420264,
    "out:Initial Cost/MSEK": 4.775503546870603,
    "out:Running cost/(Apt SEK y)": 12156.184211,
    "out:Running Cost over RSP/MSEK": 3.60073,
    "out:LCP/MSEK": -0.398144,
    "out:ROI% old": 13.094685,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 91.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1816.06313,
    "out:Return %": 12,
    "out:Return/kSEK/y": 558,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.344259,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 222821.360211,
    "out:EL kWh savings": -56428.632866,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109964.09,
    "out:DH kr savings": 222821.36021094787,
    "out:El kr savings": -112857.2657320795,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107067.549168,
    "out:% savings (space heating)": 62.427074,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 44.35,
    "out:Etvv": 0,
    "out:Ef": 26.76,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4775503.546870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": 3.424784,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.424784,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.125523,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.772391,
    "out:Electricity (inc PV)": 26.468257,
    "out:Total Energy Use Pre PV": 68.772391,
    "out:Total Energy Use Post PV": 69.468257,
    "out:Primary Energy": 83.582393,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.516454,
    "out:CO2 Operational/m2": 18.881474,
    "out:Total CO2/m2": 34.397928,
    "out:Total CO2 (tons)": 90.432132,
    "out:Klimatpaverkan": -29.706941,
    "out:Initial Cost/MSEK": 4.937458559370602,
    "out:Running cost/(Apt SEK y)": 12550.342105,
    "out:Running Cost over RSP/MSEK": 3.71797,
    "out:LCP/MSEK": -0.677339,
    "out:ROI% old": 12.325948,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 88.4,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1804.06737,
    "out:Return %": 11,
    "out:Return/kSEK/y": 543,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.172891,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 216511.935158,
    "out:EL kWh savings": -55651.335016,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105209.27,
    "out:DH kr savings": 216511.93515770845,
    "out:El kr savings": -111302.6700311011,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 113376.974221,
    "out:% savings (space heating)": 60.212925,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 46.21,
    "out:Etvv": 0,
    "out:Ef": 26.47,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4937458.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": 3.402162,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.402162,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.585609,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.667753,
    "out:Electricity (inc PV)": 26.381021,
    "out:Total Energy Use Pre PV": 65.667753,
    "out:Total Energy Use Post PV": 66.381021,
    "out:Primary Energy": 80.627434,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.516454,
    "out:CO2 Operational/m2": 17.787709,
    "out:Total CO2/m2": 33.304163,
    "out:Total CO2 (tons)": 87.556625,
    "out:Klimatpaverkan": -32.582449,
    "out:Initial Cost/MSEK": 5.003183546870602,
    "out:Running cost/(Apt SEK y)": 11909.052632,
    "out:Running Cost over RSP/MSEK": 3.52741,
    "out:LCP/MSEK": -0.552504,
    "out:ROI% old": 12.708137,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 97,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1796.74271,
    "out:Return %": 11,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.068253,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 225818.36695,
    "out:EL kWh savings": -55421.991624,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114974.38,
    "out:DH kr savings": 225818.3669503798,
    "out:El kr savings": -110843.98324726094,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 104070.542428,
    "out:% savings (space heating)": 63.478806,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.61,
    "out:Etvv": 0,
    "out:Ef": 26.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5003183.546870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": 3.388349,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.388349,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.288075,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.041557,
    "out:Electricity (inc PV)": 19.05147,
    "out:Total Energy Use Pre PV": 70.041557,
    "out:Total Energy Use Post PV": 63.05147,
    "out:Primary Energy": 71.601929,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.371153,
    "out:CO2 Operational/m2": -9.576824,
    "out:Total CO2/m2": 19.794329,
    "out:Total CO2 (tons)": 52.039279,
    "out:Klimatpaverkan": -68.099794,
    "out:Initial Cost/MSEK": 5.354761795881852,
    "out:Running cost/(Apt SEK y)": 11164.210526,
    "out:Running Cost over RSP/MSEK": 3.31883,
    "out:LCP/MSEK": -0.695502,
    "out:ROI% old": 12.430218,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1300.856164,
    "out:Return %": 11,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.442057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14572.426781,
    "out:PV (% sold (El))": 42.047344,
    "out:PV (kWh self-consumed)": 20084.76058,
    "out:PV (ratio sold/self-consumed)": 0.725546,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213455.586644,
    "out:EL kWh savings": -36152.60642,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163009.01,
    "out:DH kr savings": 213455.58664375197,
    "out:El kr savings": -72305.21284074479,
    "out:El kr sold": 21858.640171718904,
    "out:El kr saved": 40169.52116,
    "out:El kr return": 62028.161331718904,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 116433.322735,
    "out:% savings (space heating)": 59.140369,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.97,
    "out:Etvv": 0,
    "out:Ef": 19.05,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5354761.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -25.41612,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.851044,
    "out:Bought CO2": 2.434924,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.725589,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.943759,
    "out:Electricity (inc PV)": 18.986802,
    "out:Total Energy Use Pre PV": 66.943759,
    "out:Total Energy Use Post PV": 59.986802,
    "out:Primary Energy": 68.668399,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.371153,
    "out:CO2 Operational/m2": -10.763699,
    "out:Total CO2/m2": 18.607454,
    "out:Total CO2 (tons)": 48.918985,
    "out:Klimatpaverkan": -71.220088,
    "out:Initial Cost/MSEK": 5.420486783381851,
    "out:Running cost/(Apt SEK y)": 10524.5,
    "out:Running Cost over RSP/MSEK": 3.12874,
    "out:LCP/MSEK": -0.571137,
    "out:ROI% old": 12.780481,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1295.270758,
    "out:Return %": 11,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.344259,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14621.805047,
    "out:PV (% sold (El))": 42.18982,
    "out:PV (kWh self-consumed)": 20035.382313,
    "out:PV (ratio sold/self-consumed)": 0.729799,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222821.360211,
    "out:EL kWh savings": -35982.594287,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172788.88,
    "out:DH kr savings": 222821.36021094787,
    "out:El kr savings": -71965.1885739584,
    "out:El kr sold": 21932.707570961415,
    "out:El kr saved": 40070.764626,
    "out:El kr return": 62003.47219696141,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107067.549168,
    "out:% savings (space heating)": 62.427074,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 44.35,
    "out:Etvv": 0,
    "out:Ef": 18.99,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5420486.783381851,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -25.523043,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.947513,
    "out:Bought CO2": 2.42447,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.125523,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.772391,
    "out:Electricity (inc PV)": 18.747194,
    "out:Total Energy Use Pre PV": 68.772391,
    "out:Total Energy Use Post PV": 61.747194,
    "out:Primary Energy": 69.684479,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.086254,
    "out:CO2 Operational/m2": -10.231541,
    "out:Total CO2/m2": 19.854713,
    "out:Total CO2 (tons)": 52.198029,
    "out:Klimatpaverkan": -67.941045,
    "out:Initial Cost/MSEK": 5.582441795881851,
    "out:Running cost/(Apt SEK y)": 10919.815789,
    "out:Running Cost over RSP/MSEK": 3.24631,
    "out:LCP/MSEK": -0.850662,
    "out:ROI% old": 12.108833,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1285.491157,
    "out:Return %": 11,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.172891,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14708.638657,
    "out:PV (% sold (El))": 42.44037,
    "out:PV (kWh self-consumed)": 19948.548704,
    "out:PV (ratio sold/self-consumed)": 0.737329,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 216511.935158,
    "out:EL kWh savings": -35352.664998,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167869.56,
    "out:DH kr savings": 216511.93515770845,
    "out:El kr savings": -70705.3299960504,
    "out:El kr sold": 22062.957985294146,
    "out:El kr saved": 39897.097408,
    "out:El kr return": 61960.05539329415,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 113376.974221,
    "out:% savings (space heating)": 60.212925,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 46.21,
    "out:Etvv": 0,
    "out:Ef": 18.75,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5582441.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -25.710853,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.117018,
    "out:Bought CO2": 2.406165,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.585609,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.667753,
    "out:Electricity (inc PV)": 18.676546,
    "out:Total Energy Use Pre PV": 65.667753,
    "out:Total Energy Use Post PV": 58.676546,
    "out:Primary Energy": 66.759379,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.086254,
    "out:CO2 Operational/m2": -11.42647,
    "out:Total CO2/m2": 18.659784,
    "out:Total CO2 (tons)": 49.056561,
    "out:Klimatpaverkan": -71.082513,
    "out:Initial Cost/MSEK": 5.648166783381852,
    "out:Running cost/(Apt SEK y)": 10279.236842,
    "out:Running Cost over RSP/MSEK": 3.05595,
    "out:LCP/MSEK": -0.726027,
    "out:ROI% old": 12.4494,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1279.524486,
    "out:Return %": 11,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.068253,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14761.819473,
    "out:PV (% sold (El))": 42.593818,
    "out:PV (kWh self-consumed)": 19895.367887,
    "out:PV (ratio sold/self-consumed)": 0.741973,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 225818.36695,
    "out:EL kWh savings": -35166.931448,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177627.23,
    "out:DH kr savings": 225818.3669503798,
    "out:El kr savings": -70333.86289640408,
    "out:El kr sold": 22142.72921001066,
    "out:El kr saved": 39790.735774,
    "out:El kr return": 61933.46498401066,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 104070.542428,
    "out:% savings (space heating)": 63.478806,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.61,
    "out:Etvv": 0,
    "out:Ef": 18.68,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5648166.783381851,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -25.82583,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.220825,
    "out:Bought CO2": 2.394995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.288075,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.041557,
    "out:Electricity (inc PV)": 17.350711,
    "out:Total Energy Use Pre PV": 70.041557,
    "out:Total Energy Use Post PV": 61.350711,
    "out:Primary Energy": 68.540563,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.940953,
    "out:CO2 Operational/m2": -68.516075,
    "out:Total CO2/m2": -24.575122,
    "out:Total CO2 (tons)": -64.607981,
    "out:Klimatpaverkan": -184.747055,
    "out:Initial Cost/MSEK": 5.999745014930602,
    "out:Running cost/(Apt SEK y)": 9739.736842,
    "out:Running Cost over RSP/MSEK": 2.90714,
    "out:LCP/MSEK": -0.928795,
    "out:ROI% old": 12.074204,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1183.993703,
    "out:Return %": 11,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.442057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44941.993586,
    "out:PV (% sold (El))": 64.837912,
    "out:PV (kWh self-consumed)": 24372.381136,
    "out:PV (ratio sold/self-consumed)": 1.843972,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213455.586644,
    "out:EL kWh savings": -31681.312025,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217505.95,
    "out:DH kr savings": 213455.58664375197,
    "out:El kr savings": -63362.62404945108,
    "out:El kr sold": 67412.99037877562,
    "out:El kr saved": 48744.762272,
    "out:El kr return": 116157.75265077563,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 116433.322735,
    "out:% savings (space heating)": 59.140369,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 47.97,
    "out:Etvv": 0,
    "out:Ef": 17.35,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5999745.014930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -84.355371,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.573699,
    "out:Bought CO2": 2.218328,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.725589,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.943759,
    "out:Electricity (inc PV)": 17.292922,
    "out:Total Energy Use Pre PV": 66.943759,
    "out:Total Energy Use Post PV": 58.292922,
    "out:Primary Energy": 65.619415,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.940953,
    "out:CO2 Operational/m2": -69.745991,
    "out:Total CO2/m2": -25.805038,
    "out:Total CO2 (tons)": -67.841429,
    "out:Klimatpaverkan": -187.980503,
    "out:Initial Cost/MSEK": 6.065470002430603,
    "out:Running cost/(Apt SEK y)": 9100.342105,
    "out:Running Cost over RSP/MSEK": 2.71713,
    "out:LCP/MSEK": -0.80451,
    "out:ROI% old": 12.39089,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1179.000037,
    "out:Return %": 11,
    "out:Return/kSEK/y": 675,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.344259,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45013.925009,
    "out:PV (% sold (El))": 64.941688,
    "out:PV (kWh self-consumed)": 24300.449713,
    "out:PV (ratio sold/self-consumed)": 1.852391,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222821.360211,
    "out:EL kWh savings": -31529.384778,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227283.48,
    "out:DH kr savings": 222821.36021094787,
    "out:El kr savings": -63058.769556451145,
    "out:El kr sold": 67520.88751342933,
    "out:El kr saved": 48600.899426,
    "out:El kr return": 116121.78693942934,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107067.549168,
    "out:% savings (space heating)": 62.427074,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 44.35,
    "out:Etvv": 0,
    "out:Ef": 17.29,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6065470.002430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -84.505335,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.714334,
    "out:Bought CO2": 2.208999,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.125523,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.772391,
    "out:Electricity (inc PV)": 17.07895,
    "out:Total Energy Use Pre PV": 68.772391,
    "out:Total Energy Use Post PV": 60.07895,
    "out:Primary Energy": 66.68164,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.656054,
    "out:CO2 Operational/m2": -69.289472,
    "out:Total CO2/m2": -24.633418,
    "out:Total CO2 (tons)": -64.761241,
    "out:Klimatpaverkan": -184.900315,
    "out:Initial Cost/MSEK": 6.227425014930602,
    "out:Running cost/(Apt SEK y)": 9496.184211,
    "out:Running Cost over RSP/MSEK": 2.83485,
    "out:LCP/MSEK": -1.084185,
    "out:ROI% old": 11.798594,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1170.258466,
    "out:Return %": 11,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.172891,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45140.453043,
    "out:PV (% sold (El))": 65.12423,
    "out:PV (kWh self-consumed)": 24173.921679,
    "out:PV (ratio sold/self-consumed)": 1.86732,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216511.935158,
    "out:EL kWh savings": -30966.852518,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222288.91,
    "out:DH kr savings": 216511.93515770845,
    "out:El kr savings": -61933.70503593365,
    "out:El kr sold": 67710.67956381488,
    "out:El kr saved": 48347.843358,
    "out:El kr return": 116058.52292181487,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 113376.974221,
    "out:% savings (space heating)": 60.212925,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 46.21,
    "out:Etvv": 0,
    "out:Ef": 17.08,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6227425.014930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -84.768784,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.961454,
    "out:Bought CO2": 2.19267,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.585609,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.667753,
    "out:Electricity (inc PV)": 17.015913,
    "out:Total Energy Use Pre PV": 65.667753,
    "out:Total Energy Use Post PV": 57.015913,
    "out:Primary Energy": 63.770239,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.656054,
    "out:CO2 Operational/m2": -70.530716,
    "out:Total CO2/m2": -25.874662,
    "out:Total CO2 (tons)": -68.024471,
    "out:Klimatpaverkan": -188.163544,
    "out:Initial Cost/MSEK": 6.293150002430603,
    "out:Running cost/(Apt SEK y)": 8855.894737,
    "out:Running Cost over RSP/MSEK": 2.6446,
    "out:LCP/MSEK": -0.95966,
    "out:ROI% old": 12.107246,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 128.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1164.926215,
    "out:Return %": 11,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.068253,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45217.934106,
    "out:PV (% sold (El))": 65.236012,
    "out:PV (kWh self-consumed)": 24096.440616,
    "out:PV (ratio sold/self-consumed)": 1.87654,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225818.36695,
    "out:EL kWh savings": -30801.128283,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232043.01,
    "out:DH kr savings": 225818.3669503798,
    "out:El kr savings": -61602.25656519986,
    "out:El kr sold": 67826.90115877532,
    "out:El kr saved": 48192.881232,
    "out:El kr return": 116019.78239077532,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 104070.542428,
    "out:% savings (space heating)": 63.478806,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.61,
    "out:Etvv": 0,
    "out:Ef": 17.02,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6293150.002430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -84.930076,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.112787,
    "out:Bought CO2": 2.182711,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.156492,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.420219,
    "out:Electricity (inc PV)": 39.061762,
    "out:Total Energy Use Pre PV": 47.420219,
    "out:Total Energy Use Post PV": 49.061762,
    "out:Primary Energy": 80.149593,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.240364,
    "out:CO2 Operational/m2": 8.539608,
    "out:Total CO2/m2": 23.779972,
    "out:Total CO2 (tons)": 62.517532,
    "out:Klimatpaverkan": -57.621541,
    "out:Initial Cost/MSEK": 5.670234684370603,
    "out:Running cost/(Apt SEK y)": 7267.131579,
    "out:Running Cost over RSP/MSEK": 2.14209,
    "out:LCP/MSEK": 0.165765,
    "out:ROI% old": 14.703347,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 165.3,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2619.41533,
    "out:Return %": 13,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.820719,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 303187.497974,
    "out:EL kWh savings": -88759.652142,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125668.19,
    "out:DH kr savings": 303187.4979741969,
    "out:El kr savings": -177519.3042844562,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 26701.411405,
    "out:% savings (space heating)": 90.629746,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 12.65,
    "out:Etvv": 0,
    "out:Ef": 39.06,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5670234.6843706025,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": 4.939768,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.939768,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.932534,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.916806,
    "out:Electricity (inc PV)": 38.624986,
    "out:Total Energy Use Pre PV": 44.916806,
    "out:Total Energy Use Post PV": 46.624986,
    "out:Primary Energy": 77.462616,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.240364,
    "out:CO2 Operational/m2": 7.753186,
    "out:Total CO2/m2": 22.99355,
    "out:Total CO2 (tons)": 60.450029,
    "out:Klimatpaverkan": -59.689044,
    "out:Initial Cost/MSEK": 5.735959671870602,
    "out:Running cost/(Apt SEK y)": 6779.605263,
    "out:Running Cost over RSP/MSEK": 1.99743,
    "out:LCP/MSEK": 0.2447,
    "out:ROI% old": 14.895154,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 176.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2584.17642,
    "out:Return %": 13,
    "out:Return/kSEK/y": 763,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.317306,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 309034.282228,
    "out:EL kWh savings": -87611.368299,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133811.55,
    "out:DH kr savings": 309034.282228494,
    "out:El kr savings": -175222.73659796672,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 20854.62715,
    "out:% savings (space heating)": 92.681542,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 10.21,
    "out:Etvv": 0,
    "out:Ef": 38.62,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5735959.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": 4.873314,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.873314,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.408898,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.869944,
    "out:Electricity (inc PV)": 38.29354,
    "out:Total Energy Use Pre PV": 45.869944,
    "out:Total Energy Use Post PV": 47.29354,
    "out:Primary Energy": 77.821898,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.955465,
    "out:CO2 Operational/m2": 8.106984,
    "out:Total CO2/m2": 24.062449,
    "out:Total CO2 (tons)": 63.260164,
    "out:Klimatpaverkan": -56.878909,
    "out:Initial Cost/MSEK": 5.897914684370602,
    "out:Running cost/(Apt SEK y)": 6982.052632,
    "out:Running Cost over RSP/MSEK": 2.05762,
    "out:LCP/MSEK": 0.022555,
    "out:ROI% old": 14.340347,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 176.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2580.89608,
    "out:Return %": 13,
    "out:Return/kSEK/y": 755,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 29.270444,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 305152.922154,
    "out:EL kWh savings": -86739.996963,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131672.93,
    "out:DH kr savings": 305152.9221538833,
    "out:El kr savings": -173479.99392571324,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24735.987225,
    "out:% savings (space heating)": 91.319467,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.43,
    "out:Etvv": 0,
    "out:Ef": 38.29,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5897914.6843706025,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 4.867128,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.867128,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.260994,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.343484,
    "out:Electricity (inc PV)": 37.825941,
    "out:Total Energy Use Pre PV": 43.343484,
    "out:Total Energy Use Post PV": 44.825941,
    "out:Primary Energy": 75.15855,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.955465,
    "out:CO2 Operational/m2": 7.317519,
    "out:Total CO2/m2": 23.272984,
    "out:Total CO2 (tons)": 61.184661,
    "out:Klimatpaverkan": -58.954412,
    "out:Initial Cost/MSEK": 5.963639671870602,
    "out:Running cost/(Apt SEK y)": 6491.342105,
    "out:Running Cost over RSP/MSEK": 1.91202,
    "out:LCP/MSEK": 0.10243,
    "out:ROI% old": 14.531083,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 188.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2544.04388,
    "out:Return %": 13,
    "out:Return/kSEK/y": 774,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.743984,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 310799.760488,
    "out:EL kWh savings": -85510.679471,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139778.4,
    "out:DH kr savings": 310799.76048758463,
    "out:El kr savings": -171021.35894202642,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 19089.148891,
    "out:% savings (space heating)": 93.301097,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.09,
    "out:Etvv": 0,
    "out:Ef": 37.83,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5963639.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 4.797631,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.797631,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.156492,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.420219,
    "out:Electricity (inc PV)": 29.260673,
    "out:Total Energy Use Pre PV": 47.420219,
    "out:Total Energy Use Post PV": 39.260673,
    "out:Primary Energy": 62.507632,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.810164,
    "out:CO2 Operational/m2": -10.708434,
    "out:Total CO2/m2": 19.10173,
    "out:Total CO2 (tons)": 50.218437,
    "out:Klimatpaverkan": -69.920637,
    "out:Initial Cost/MSEK": 6.315217920881852,
    "out:Running cost/(Apt SEK y)": 5568.263158,
    "out:Running Cost over RSP/MSEK": 1.65061,
    "out:LCP/MSEK": 0.012262,
    "out:ROI% old": 14.313452,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 233.3,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 1969.597509,
    "out:Return %": 13,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.820719,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9513.766822,
    "out:PV (% sold (El))": 27.451064,
    "out:PV (kWh self-consumed)": 25143.420539,
    "out:PV (ratio sold/self-consumed)": 0.37838,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303187.497974,
    "out:EL kWh savings": -62992.595012,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191472.96,
    "out:DH kr savings": 303187.4979741969,
    "out:El kr savings": -125985.1900249564,
    "out:El kr sold": 14270.65023257388,
    "out:El kr saved": 50286.841078,
    "out:El kr return": 64557.49131057388,
    "out:% solar/total": 180,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 26701.411405,
    "out:% savings (space heating)": 90.629746,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 12.65,
    "out:Etvv": 0,
    "out:Ef": 29.26,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6315217.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -14.308274,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.994246,
    "out:Bought CO2": 3.685972,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.932534,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.916806,
    "out:Electricity (inc PV)": 28.885714,
    "out:Total Energy Use Pre PV": 44.916806,
    "out:Total Energy Use Post PV": 36.885714,
    "out:Primary Energy": 59.931926,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.810164,
    "out:CO2 Operational/m2": -11.867961,
    "out:Total CO2/m2": 17.942203,
    "out:Total CO2 (tons)": 47.170041,
    "out:Klimatpaverkan": -72.969033,
    "out:Initial Cost/MSEK": 6.3809429083818525,
    "out:Running cost/(Apt SEK y)": 5083.342105,
    "out:Running Cost over RSP/MSEK": 1.50669,
    "out:LCP/MSEK": 0.090457,
    "out:ROI% old": 14.48823,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 251.4,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 1939.228292,
    "out:Return %": 13,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.317306,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9711.844391,
    "out:PV (% sold (El))": 28.022598,
    "out:PV (kWh self-consumed)": 24945.34297,
    "out:PV (ratio sold/self-consumed)": 0.389325,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 309034.282228,
    "out:EL kWh savings": -62006.828025,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199588.39,
    "out:DH kr savings": 309034.282228494,
    "out:El kr savings": -124013.65605065748,
    "out:El kr sold": 14567.76658577317,
    "out:El kr saved": 49890.68594,
    "out:El kr return": 64458.45252577317,
    "out:% solar/total": 179,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 20854.62715,
    "out:% savings (space heating)": 92.681542,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 10.21,
    "out:Etvv": 0,
    "out:Ef": 28.89,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6380942.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -14.747833,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.377102,
    "out:Bought CO2": 3.629269,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.408898,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.869944,
    "out:Electricity (inc PV)": 28.601833,
    "out:Total Energy Use Pre PV": 45.869944,
    "out:Total Energy Use Post PV": 37.601833,
    "out:Primary Energy": 60.376825,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.525265,
    "out:CO2 Operational/m2": -11.549204,
    "out:Total CO2/m2": 18.976061,
    "out:Total CO2 (tons)": 49.888053,
    "out:Klimatpaverkan": -70.25102,
    "out:Initial Cost/MSEK": 6.5428979208818525,
    "out:Running cost/(Apt SEK y)": 5286.026316,
    "out:Running Cost over RSP/MSEK": 1.56696,
    "out:LCP/MSEK": -0.131768,
    "out:ROI% old": 13.998013,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 242.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1936.405159,
    "out:Return %": 13,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 29.270444,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9730.4088,
    "out:PV (% sold (El))": 28.076164,
    "out:PV (kWh self-consumed)": 24926.778561,
    "out:PV (ratio sold/self-consumed)": 0.39036,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 305152.922154,
    "out:EL kWh savings": -61260.505046,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 197227.53,
    "out:DH kr savings": 305152.9221538833,
    "out:El kr savings": -122521.01009161142,
    "out:El kr sold": 14595.61319954559,
    "out:El kr saved": 49853.557122,
    "out:El kr return": 64449.17032154559,
    "out:% solar/total": 179,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24735.987225,
    "out:% savings (space heating)": 91.319467,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.43,
    "out:Etvv": 0,
    "out:Ef": 28.6,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6542897.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -14.78906,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.413059,
    "out:Bought CO2": 3.623999,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.260994,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.343484,
    "out:Electricity (inc PV)": 28.202249,
    "out:Total Energy Use Pre PV": 43.343484,
    "out:Total Energy Use Post PV": 35.202249,
    "out:Primary Energy": 57.835904,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.525265,
    "out:CO2 Operational/m2": -12.73559,
    "out:Total CO2/m2": 17.789675,
    "out:Total CO2 (tons)": 46.769045,
    "out:Klimatpaverkan": -73.370029,
    "out:Initial Cost/MSEK": 6.608622908381852,
    "out:Running cost/(Apt SEK y)": 4798.078947,
    "out:Running Cost over RSP/MSEK": 1.42215,
    "out:LCP/MSEK": -0.052683,
    "out:ROI% old": 14.171831,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1904.735753,
    "out:Return %": 13,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.743984,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9940.145559,
    "out:PV (% sold (El))": 28.681339,
    "out:PV (kWh self-consumed)": 24717.041802,
    "out:PV (ratio sold/self-consumed)": 0.402158,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 310799.760488,
    "out:EL kWh savings": -60209.998948,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205289.98,
    "out:DH kr savings": 310799.76048758463,
    "out:El kr savings": -120419.99789671462,
    "out:El kr sold": 14910.218338015748,
    "out:El kr saved": 49434.083604,
    "out:El kr return": 64344.30194201575,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 19089.148891,
    "out:% savings (space heating)": 93.301097,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.09,
    "out:Etvv": 0,
    "out:Ef": 28.2,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6608622.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -15.255478,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.820354,
    "out:Bought CO2": 3.564876,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.156492,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.420219,
    "out:Electricity (inc PV)": 26.501441,
    "out:Total Energy Use Pre PV": 47.420219,
    "out:Total Energy Use Post PV": 36.501441,
    "out:Primary Energy": 57.541015,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.379964,
    "out:CO2 Operational/m2": -64.674051,
    "out:Total CO2/m2": -20.294087,
    "out:Total CO2 (tons)": -53.353143,
    "out:Klimatpaverkan": -173.492216,
    "out:Initial Cost/MSEK": 6.960201139930603,
    "out:Running cost/(Apt SEK y)": 4109.736842,
    "out:Running Cost over RSP/MSEK": 1.22891,
    "out:LCP/MSEK": -0.211021,
    "out:ROI% old": 13.852596,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 251.4,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 1783.536826,
    "out:Return %": 12,
    "out:Return/kSEK/y": 864,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.820719,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37295.307308,
    "out:PV (% sold (El))": 53.806021,
    "out:PV (kWh self-consumed)": 32019.067413,
    "out:PV (ratio sold/self-consumed)": 1.164784,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303187.497974,
    "out:EL kWh savings": -55738.575732,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247653.31,
    "out:DH kr savings": 303187.4979741969,
    "out:El kr savings": -111477.15146347943,
    "out:El kr sold": 55942.960962406825,
    "out:El kr saved": 64038.134826,
    "out:El kr return": 119981.09578840682,
    "out:% solar/total": 230,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 26701.411405,
    "out:% savings (space heating)": 90.629746,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 12.65,
    "out:Etvv": 0,
    "out:Ef": 26.5,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6960201.139930603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -68.273891,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.61332,
    "out:Bought CO2": 3.339429,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.932534,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.916806,
    "out:Electricity (inc PV)": 26.165252,
    "out:Total Energy Use Pre PV": 44.916806,
    "out:Total Energy Use Post PV": 34.165252,
    "out:Primary Energy": 55.035095,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.379964,
    "out:CO2 Operational/m2": -66.054669,
    "out:Total CO2/m2": -21.674705,
    "out:Total CO2 (tons)": -56.982787,
    "out:Klimatpaverkan": -177.12186,
    "out:Initial Cost/MSEK": 7.025926127430603,
    "out:Running cost/(Apt SEK y)": 3626.315789,
    "out:Running Cost over RSP/MSEK": 1.08544,
    "out:LCP/MSEK": -0.133276,
    "out:ROI% old": 14.014726,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 282.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 1756.286102,
    "out:Return %": 13,
    "out:Return/kSEK/y": 883,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.317306,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37607.156252,
    "out:PV (% sold (El))": 54.255927,
    "out:PV (kWh self-consumed)": 31707.218469,
    "out:PV (ratio sold/self-consumed)": 1.186076,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 309034.282228,
    "out:EL kWh savings": -54854.735051,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255735.55,
    "out:DH kr savings": 309034.282228494,
    "out:El kr savings": -109709.47010288904,
    "out:El kr sold": 56410.734378548776,
    "out:El kr saved": 63414.436938,
    "out:El kr return": 119825.17131654877,
    "out:% solar/total": 228,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 20854.62715,
    "out:% savings (space heating)": 92.681542,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 10.21,
    "out:Etvv": 0,
    "out:Ef": 26.17,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7025926.127430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -68.934541,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.223043,
    "out:Bought CO2": 3.288502,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.408898,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.869944,
    "out:Electricity (inc PV)": 25.910534,
    "out:Total Energy Use Pre PV": 45.869944,
    "out:Total Energy Use Post PV": 34.910534,
    "out:Primary Energy": 55.532487,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 45.095065,
    "out:CO2 Operational/m2": -65.75641,
    "out:Total CO2/m2": -20.661345,
    "out:Total CO2 (tons)": -54.318664,
    "out:Klimatpaverkan": -174.457737,
    "out:Initial Cost/MSEK": 7.187881139930603,
    "out:Running cost/(Apt SEK y)": 3829.157895,
    "out:Running Cost over RSP/MSEK": 1.14575,
    "out:LCP/MSEK": -0.355541,
    "out:ROI% old": 13.579086,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1753.752671,
    "out:Return %": 12,
    "out:Return/kSEK/y": 875,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 29.270444,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37636.326822,
    "out:PV (% sold (El))": 54.298011,
    "out:PV (kWh self-consumed)": 31678.0479,
    "out:PV (ratio sold/self-consumed)": 1.188089,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 305152.922154,
    "out:EL kWh savings": -54185.081582,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253237.25,
    "out:DH kr savings": 305152.9221538833,
    "out:El kr savings": -108370.16316302234,
    "out:El kr sold": 56454.49023280965,
    "out:El kr saved": 63356.0958,
    "out:El kr return": 119810.58603280966,
    "out:% solar/total": 227,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24735.987225,
    "out:% savings (space heating)": 91.319467,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.43,
    "out:Etvv": 0,
    "out:Ef": 25.91,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7187881.139930603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -68.996266,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.280033,
    "out:Bought CO2": 3.283767,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.260994,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.343484,
    "out:Electricity (inc PV)": 25.551807,
    "out:Total Energy Use Pre PV": 43.343484,
    "out:Total Energy Use Post PV": 32.551807,
    "out:Primary Energy": 53.065109,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 45.095065,
    "out:CO2 Operational/m2": -67.172454,
    "out:Total CO2/m2": -22.077389,
    "out:Total CO2 (tons)": -58.041443,
    "out:Klimatpaverkan": -178.180516,
    "out:Initial Cost/MSEK": 7.2536061274306025,
    "out:Running cost/(Apt SEK y)": 3342.763158,
    "out:Running Cost over RSP/MSEK": 1.00141,
    "out:LCP/MSEK": -0.276926,
    "out:ROI% old": 13.740318,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 293.9,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1725.329605,
    "out:Return %": 12,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.743984,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37965.736661,
    "out:PV (% sold (El))": 54.773251,
    "out:PV (kWh self-consumed)": 31348.63806,
    "out:PV (ratio sold/self-consumed)": 1.211081,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 310799.760488,
    "out:EL kWh savings": -53241.988513,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261264.39,
    "out:DH kr savings": 310799.76048758463,
    "out:El kr savings": -106483.97702534238,
    "out:El kr sold": 56948.604992234104,
    "out:El kr saved": 62697.27612,
    "out:El kr return": 119645.88111223411,
    "out:% solar/total": 225,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 19089.148891,
    "out:% savings (space heating)": 93.301097,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 9.09,
    "out:Etvv": 0,
    "out:Ef": 25.55,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7253606.127430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -69.692342,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.922986,
    "out:Bought CO2": 3.230644,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.165516,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.684757,
    "out:Electricity (inc PV)": 38.033374,
    "out:Total Energy Use Pre PV": 45.684757,
    "out:Total Energy Use Post PV": 47.033374,
    "out:Primary Energy": 77.049136,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.240364,
    "out:CO2 Operational/m2": 8.082537,
    "out:Total CO2/m2": 23.322901,
    "out:Total CO2 (tons)": 61.315893,
    "out:Klimatpaverkan": -58.823181,
    "out:Initial Cost/MSEK": 5.770528559370602,
    "out:Running cost/(Apt SEK y)": 6956.447368,
    "out:Running Cost over RSP/MSEK": 2.05009,
    "out:LCP/MSEK": 0.157471,
    "out:ROI% old": 14.675556,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 176.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2567.93299,
    "out:Return %": 13,
    "out:Return/kSEK/y": 756,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 29.085257,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 305792.773287,
    "out:EL kWh savings": -86056.020704,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133680.73,
    "out:DH kr savings": 305792.7732865843,
    "out:El kr savings": -172112.04140835145,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24096.136092,
    "out:% savings (space heating)": 91.544008,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.04,
    "out:Etvv": 0,
    "out:Ef": 38.03,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5770528.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 4.842681,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.842681,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.045214,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.149933,
    "out:Electricity (inc PV)": 37.555337,
    "out:Total Energy Use Pre PV": 43.149933,
    "out:Total Energy Use Post PV": 44.555337,
    "out:Primary Energy": 74.393792,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 15.240364,
    "out:CO2 Operational/m2": 7.291968,
    "out:Total CO2/m2": 22.532332,
    "out:Total CO2 (tons)": 59.237487,
    "out:Klimatpaverkan": -60.901586,
    "out:Initial Cost/MSEK": 5.836253546870602,
    "out:Running cost/(Apt SEK y)": 6464.552632,
    "out:Running Cost over RSP/MSEK": 1.90415,
    "out:LCP/MSEK": 0.237686,
    "out:ROI% old": 14.867512,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 188.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2530.49531,
    "out:Return %": 13,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.550433,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 311367.045979,
    "out:EL kWh savings": -84799.261717,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141768.52,
    "out:DH kr savings": 311367.04597876396,
    "out:El kr savings": -169598.52343312764,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 18521.8634,
    "out:% savings (space heating)": 93.500173,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.74,
    "out:Etvv": 0,
    "out:Ef": 37.56,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5836253.546870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 4.77208,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.77208,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.594772,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.236255,
    "out:Electricity (inc PV)": 37.401045,
    "out:Total Energy Use Pre PV": 45.236255,
    "out:Total Energy Use Post PV": 46.401045,
    "out:Primary Energy": 75.200588,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.955465,
    "out:CO2 Operational/m2": 8.023332,
    "out:Total CO2/m2": 23.978797,
    "out:Total CO2 (tons)": 63.040243,
    "out:Klimatpaverkan": -57.098831,
    "out:Initial Cost/MSEK": 5.998208559370601,
    "out:Running cost/(Apt SEK y)": 6894.368421,
    "out:Running Cost over RSP/MSEK": 2.03186,
    "out:LCP/MSEK": -0.051979,
    "out:ROI% old": 14.161919,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 182.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2536.53785,
    "out:Return %": 13,
    "out:Return/kSEK/y": 758,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.636755,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 307293.258922,
    "out:EL kWh savings": -84393.628141,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138506,
    "out:DH kr savings": 307293.25892185007,
    "out:El kr savings": -168787.25628135234,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 22595.650457,
    "out:% savings (space heating)": 92.070569,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 10.13,
    "out:Etvv": 0,
    "out:Ef": 37.4,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5998208.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 4.783476,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.783476,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.541858,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.681011,
    "out:Electricity (inc PV)": 36.89685,
    "out:Total Energy Use Pre PV": 42.681011,
    "out:Total Energy Use Post PV": 43.89685,
    "out:Primary Energy": 72.565298,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 15.955465,
    "out:CO2 Operational/m2": 7.230067,
    "out:Total CO2/m2": 23.185532,
    "out:Total CO2 (tons)": 60.95475,
    "out:Klimatpaverkan": -59.184324,
    "out:Initial Cost/MSEK": 6.063933546870603,
    "out:Running cost/(Apt SEK y)": 6399.684211,
    "out:Running Cost over RSP/MSEK": 1.88509,
    "out:LCP/MSEK": 0.029066,
    "out:ROI% old": 14.35419,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 195.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2497.67077,
    "out:Return %": 13,
    "out:Return/kSEK/y": 777,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.081511,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 312690.368602,
    "out:EL kWh savings": -83068.099787,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146554.17,
    "out:DH kr savings": 312690.3686022604,
    "out:El kr savings": -166136.19957336108,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 17198.540777,
    "out:% savings (space heating)": 93.964563,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.91,
    "out:Etvv": 0,
    "out:Ef": 36.9,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6063933.546870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 4.710179,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.710179,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.165516,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.684757,
    "out:Electricity (inc PV)": 28.379393,
    "out:Total Energy Use Pre PV": 45.684757,
    "out:Total Energy Use Post PV": 37.379393,
    "out:Primary Energy": 59.67197,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.810164,
    "out:CO2 Operational/m2": -11.712613,
    "out:Total CO2/m2": 18.097551,
    "out:Total CO2 (tons)": 47.578451,
    "out:Klimatpaverkan": -72.560623,
    "out:Initial Cost/MSEK": 6.4155117958818515,
    "out:Running cost/(Apt SEK y)": 5261.368421,
    "out:Running Cost over RSP/MSEK": 1.55971,
    "out:LCP/MSEK": 0.002868,
    "out:ROI% old": 14.292101,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 251.4,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1925.25547,
    "out:Return %": 13,
    "out:Return/kSEK/y": 820,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 29.085257,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9803.969692,
    "out:PV (% sold (El))": 28.288417,
    "out:PV (kWh self-consumed)": 24853.217669,
    "out:PV (ratio sold/self-consumed)": 0.394475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 305792.773287,
    "out:EL kWh savings": -60675.710419,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199147.31,
    "out:DH kr savings": 305792.7732865843,
    "out:El kr savings": -121351.42083720474,
    "out:El kr sold": 14705.9545376397,
    "out:El kr saved": 49706.435338,
    "out:El kr return": 64412.3898756397,
    "out:% solar/total": 178,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24096.136092,
    "out:% savings (space heating)": 91.544008,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.04,
    "out:Etvv": 0,
    "out:Ef": 28.38,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6415511.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -14.952469,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.555654,
    "out:Bought CO2": 3.603185,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.045214,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.149933,
    "out:Electricity (inc PV)": 27.971481,
    "out:Total Energy Use Pre PV": 43.149933,
    "out:Total Energy Use Post PV": 34.971481,
    "out:Primary Energy": 57.142851,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.810164,
    "out:CO2 Operational/m2": -12.90843,
    "out:Total CO2/m2": 16.901734,
    "out:Total CO2 (tons)": 44.434649,
    "out:Klimatpaverkan": -75.704425,
    "out:Initial Cost/MSEK": 6.481236783381852,
    "out:Running cost/(Apt SEK y)": 4772.315789,
    "out:Running Cost over RSP/MSEK": 1.41458,
    "out:LCP/MSEK": 0.082273,
    "out:ROI% old": 14.467058,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1893.11361,
    "out:Return %": 13,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.550433,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10017.799355,
    "out:PV (% sold (El))": 28.905402,
    "out:PV (kWh self-consumed)": 24639.388006,
    "out:PV (ratio sold/self-consumed)": 0.406577,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 311367.045979,
    "out:EL kWh savings": -59603.310014,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207187.12,
    "out:DH kr savings": 311367.04597876396,
    "out:El kr savings": -119206.62002825162,
    "out:El kr sold": 15026.699032180943,
    "out:El kr saved": 49278.776012,
    "out:El kr return": 64305.475044180945,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 18521.8634,
    "out:% savings (space heating)": 93.500173,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.74,
    "out:Etvv": 0,
    "out:Ef": 27.97,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6481236.783381851,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -15.428318,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.971493,
    "out:Bought CO2": 3.543175,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.594772,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.236255,
    "out:Electricity (inc PV)": 27.840016,
    "out:Total Energy Use Pre PV": 45.236255,
    "out:Total Energy Use Post PV": 36.840016,
    "out:Primary Energy": 57.990736,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.525265,
    "out:CO2 Operational/m2": -12.111287,
    "out:Total CO2/m2": 18.413978,
    "out:Total CO2 (tons)": 48.410337,
    "out:Klimatpaverkan": -71.728736,
    "out:Initial Cost/MSEK": 6.643191795881852,
    "out:Running cost/(Apt SEK y)": 5201.684211,
    "out:Running Cost over RSP/MSEK": 1.54216,
    "out:LCP/MSEK": -0.207262,
    "out:ROI% old": 13.840012,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 251.4,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1898.295551,
    "out:Return %": 12,
    "out:Return/kSEK/y": 823,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.636755,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9983.128665,
    "out:PV (% sold (El))": 28.805363,
    "out:PV (kWh self-consumed)": 24674.058696,
    "out:PV (ratio sold/self-consumed)": 0.4046,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 307293.258922,
    "out:EL kWh savings": -59257.688608,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203752.57,
    "out:DH kr savings": 307293.25892185007,
    "out:El kr savings": -118515.37721522084,
    "out:El kr sold": 14974.692996913807,
    "out:El kr saved": 49348.117392,
    "out:El kr return": 64322.81038891381,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 22595.650457,
    "out:% savings (space heating)": 92.070569,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 10.13,
    "out:Etvv": 0,
    "out:Ef": 27.84,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6643191.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -15.351143,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.903994,
    "out:Bought CO2": 3.552851,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.541858,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.681011,
    "out:Electricity (inc PV)": 27.411326,
    "out:Total Energy Use Pre PV": 42.681011,
    "out:Total Energy Use Post PV": 34.411326,
    "out:Primary Energy": 55.491355,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 30.525265,
    "out:CO2 Operational/m2": -13.330258,
    "out:Total CO2/m2": 17.195007,
    "out:Total CO2 (tons)": 45.205663,
    "out:Klimatpaverkan": -74.93341,
    "out:Initial Cost/MSEK": 6.708916783381851,
    "out:Running cost/(Apt SEK y)": 4709.921053,
    "out:Running Cost over RSP/MSEK": 1.39624,
    "out:LCP/MSEK": -0.127067,
    "out:ROI% old": 14.015143,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 282.4,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1865.003321,
    "out:Return %": 13,
    "out:Return/kSEK/y": 841,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.081511,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10207.862888,
    "out:PV (% sold (El))": 29.453812,
    "out:PV (kWh self-consumed)": 24449.324473,
    "out:PV (ratio sold/self-consumed)": 0.417511,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 312690.368602,
    "out:EL kWh savings": -58130.662854,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211740.84,
    "out:DH kr savings": 312690.3686022604,
    "out:El kr savings": -116261.32570707676,
    "out:El kr sold": 15311.794332004985,
    "out:El kr saved": 48898.648946,
    "out:El kr return": 64210.44327800498,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 17198.540777,
    "out:% savings (space heating)": 93.964563,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.91,
    "out:Etvv": 0,
    "out:Ef": 27.41,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6708916.783381851,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -15.850146,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.340825,
    "out:Bought CO2": 3.490679,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.165516,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.684757,
    "out:Electricity (inc PV)": 25.710894,
    "out:Total Energy Use Pre PV": 45.684757,
    "out:Total Energy Use Post PV": 34.710894,
    "out:Primary Energy": 54.868672,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.379964,
    "out:CO2 Operational/m2": -66.000666,
    "out:Total CO2/m2": -21.620702,
    "out:Total CO2 (tons)": -56.840813,
    "out:Klimatpaverkan": -176.979886,
    "out:Initial Cost/MSEK": 7.060495014930602,
    "out:Running cost/(Apt SEK y)": 3805.052632,
    "out:Running Cost over RSP/MSEK": 1.13866,
    "out:LCP/MSEK": -0.221065,
    "out:ROI% old": 13.838427,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1743.746568,
    "out:Return %": 12,
    "out:Return/kSEK/y": 876,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 29.085257,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37751.782301,
    "out:PV (% sold (El))": 54.464579,
    "out:PV (kWh self-consumed)": 31562.59242,
    "out:PV (ratio sold/self-consumed)": 1.196093,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 305792.773287,
    "out:EL kWh savings": -53660.228141,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 255099.99,
    "out:DH kr savings": 305792.7732865843,
    "out:El kr savings": -107320.4562813925,
    "out:El kr sold": 56627.67345166004,
    "out:El kr saved": 63125.18484,
    "out:El kr return": 119752.85829166004,
    "out:% solar/total": 227,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 24096.136092,
    "out:% savings (space heating)": 91.544008,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 11.04,
    "out:Etvv": 0,
    "out:Ef": 25.71,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7060495.014930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -69.240522,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.505587,
    "out:Bought CO2": 3.265065,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.045214,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.149933,
    "out:Electricity (inc PV)": 25.344447,
    "out:Total Energy Use Pre PV": 43.149933,
    "out:Total Energy Use Post PV": 32.344447,
    "out:Primary Energy": 52.41419,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.379964,
    "out:CO2 Operational/m2": -67.429817,
    "out:Total CO2/m2": -23.049853,
    "out:Total CO2 (tons)": -60.59805,
    "out:Klimatpaverkan": -180.737123,
    "out:Initial Cost/MSEK": 7.126220002430603,
    "out:Running cost/(Apt SEK y)": 3317.578947,
    "out:Running Cost over RSP/MSEK": 0.994,
    "out:LCP/MSEK": -0.14213,
    "out:ROI% old": 14.000791,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 306.2,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1714.898075,
    "out:Return %": 13,
    "out:Return/kSEK/y": 894,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.550433,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38087.760838,
    "out:PV (% sold (El))": 54.949296,
    "out:PV (kWh self-consumed)": 31226.613884,
    "out:PV (ratio sold/self-consumed)": 1.219721,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 311367.045979,
    "out:EL kWh savings": -52696.839196,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263105.01,
    "out:DH kr savings": 311367.04597876396,
    "out:El kr savings": -105393.67839293032,
    "out:El kr sold": 57131.64125698198,
    "out:El kr saved": 62453.227768,
    "out:El kr return": 119584.86902498198,
    "out:% solar/total": 224,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 18521.8634,
    "out:% savings (space heating)": 93.500173,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.74,
    "out:Etvv": 0,
    "out:Ef": 25.34,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7126220.002430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -69.949705,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.160856,
    "out:Bought CO2": 3.211151,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.594772,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.236255,
    "out:Electricity (inc PV)": 25.226365,
    "out:Total Energy Use Pre PV": 45.236255,
    "out:Total Energy Use Post PV": 34.226365,
    "out:Primary Energy": 53.286164,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 45.095065,
    "out:CO2 Operational/m2": -66.59496,
    "out:Total CO2/m2": -21.499895,
    "out:Total CO2 (tons)": -56.523211,
    "out:Klimatpaverkan": -176.662285,
    "out:Initial Cost/MSEK": 7.288175014930602,
    "out:Running cost/(Apt SEK y)": 3746.684211,
    "out:Running Cost over RSP/MSEK": 1.12151,
    "out:LCP/MSEK": -0.431595,
    "out:ROI% old": 13.439735,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 282.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1719.54922,
    "out:Return %": 12,
    "out:Return/kSEK/y": 878,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.636755,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38033.27314,
    "out:PV (% sold (El))": 54.870686,
    "out:PV (kWh self-consumed)": 31281.101582,
    "out:PV (ratio sold/self-consumed)": 1.215855,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 307293.258922,
    "out:EL kWh savings": -52386.401689,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259570.37,
    "out:DH kr savings": 307293.25892185007,
    "out:El kr savings": -104772.80337792018,
    "out:El kr sold": 57049.909709884516,
    "out:El kr saved": 62562.203164,
    "out:El kr return": 119612.11287388451,
    "out:% solar/total": 224,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 22595.650457,
    "out:% savings (space heating)": 92.070569,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 10.13,
    "out:Etvv": 0,
    "out:Ef": 25.23,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7288175.014930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -69.834816,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.054658,
    "out:Bought CO2": 3.219842,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.541858,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.681011,
    "out:Electricity (inc PV)": 24.84101,
    "out:Total Energy Use Pre PV": 42.681011,
    "out:Total Energy Use Post PV": 31.84101,
    "out:Primary Energy": 50.864786,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 45.095065,
    "out:CO2 Operational/m2": -68.056796,
    "out:Total CO2/m2": -22.961731,
    "out:Total CO2 (tons)": -60.366377,
    "out:Klimatpaverkan": -180.505451,
    "out:Initial Cost/MSEK": 7.353900002430603,
    "out:Running cost/(Apt SEK y)": 3256.631579,
    "out:Running Cost over RSP/MSEK": 0.97608,
    "out:LCP/MSEK": -0.35189,
    "out:ROI% old": 13.602131,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 306.2,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1689.668228,
    "out:Return %": 12,
    "out:Return/kSEK/y": 897,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 28.081511,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38385.234808,
    "out:PV (% sold (El))": 55.378462,
    "out:PV (kWh self-consumed)": 30929.139914,
    "out:PV (ratio sold/self-consumed)": 1.24107,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 312690.368602,
    "out:EL kWh savings": -51373.303624,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 267521.61,
    "out:DH kr savings": 312690.3686022604,
    "out:El kr savings": -102746.60724803404,
    "out:El kr sold": 57577.85221139977,
    "out:El kr saved": 61858.279828,
    "out:El kr return": 119436.13203939976,
    "out:% solar/total": 222,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 17198.540777,
    "out:% savings (space heating)": 93.964563,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.91,
    "out:Etvv": 0,
    "out:Ef": 24.84,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7353900.002430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -70.576684,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.7407,
    "out:Bought CO2": 3.164016,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 104.3,
    "out:Primary Energy": 90.390555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.470481,
    "out:CO2 Operational/m2": 36.338058,
    "out:Total CO2/m2": 48.808539,
    "out:Total CO2 (tons)": 128.31762,
    "out:Klimatpaverkan": 8.178546,
    "out:Initial Cost/MSEK": 2.308063053085,
    "out:Running cost/(Apt SEK y)": 21418.052632,
    "out:Running Cost over RSP/MSEK": 6.36346,
    "out:LCP/MSEK": -0.693433,
    "out:ROI% old": 9.993723,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 9,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 70825.434519,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 70825.43,
    "out:DH kr savings": 70825.4345192767,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2308063.0530850003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 100.3,
    "out:Total Energy Use Post PV": 100.3,
    "out:Primary Energy": 87.723105,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.470481,
    "out:CO2 Operational/m2": 34.898122,
    "out:Total CO2/m2": 47.368603,
    "out:Total CO2 (tons)": 124.532029,
    "out:Klimatpaverkan": 4.392955,
    "out:Initial Cost/MSEK": 2.373788040585,
    "out:Running cost/(Apt SEK y)": 20582.289474,
    "out:Running Cost over RSP/MSEK": 6.11505,
    "out:LCP/MSEK": -0.510748,
    "out:ROI% old": 11.211977,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 30,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 10,
    "out:Return/kSEK/y": 238,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 79841.792639,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79841.79,
    "out:DH kr savings": 79841.79263946874,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2373788.040585,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 101.3,
    "out:Total Energy Use Post PV": 101.3,
    "out:Primary Energy": 87.765555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.185582,
    "out:CO2 Operational/m2": 35.258106,
    "out:Total CO2/m2": 48.443688,
    "out:Total CO2 (tons)": 127.358427,
    "out:Klimatpaverkan": 7.219353,
    "out:Initial Cost/MSEK": 2.5357430530850005,
    "out:Running cost/(Apt SEK y)": 20791.236842,
    "out:Running Cost over RSP/MSEK": 6.17715,
    "out:LCP/MSEK": -0.734803,
    "out:ROI% old": 10.146026,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 28.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 9,
    "out:Return/kSEK/y": 230,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77564.874489,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77564.87,
    "out:DH kr savings": 77564.87448886722,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2535743.0530850003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 98.3,
    "out:Total Energy Use Post PV": 98.3,
    "out:Primary Energy": 85.098105,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.185582,
    "out:CO2 Operational/m2": 34.178154,
    "out:Total CO2/m2": 47.363736,
    "out:Total CO2 (tons)": 124.519234,
    "out:Klimatpaverkan": 4.38016,
    "out:Initial Cost/MSEK": 2.601468040585,
    "out:Running cost/(Apt SEK y)": 20164.421053,
    "out:Running Cost over RSP/MSEK": 5.99085,
    "out:LCP/MSEK": -0.614228,
    "out:ROI% old": 10.912739,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 10,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 86581.232609,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 86581.23,
    "out:DH kr savings": 86581.23260905925,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2601468.040585,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 102.138626,
    "out:Primary Energy": 86.500082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.040281,
    "out:CO2 Operational/m2": -19.991989,
    "out:Total CO2/m2": 7.048292,
    "out:Total CO2 (tons)": 18.529955,
    "out:Klimatpaverkan": -101.609118,
    "out:Initial Cost/MSEK": 2.95304628959625,
    "out:Running cost/(Apt SEK y)": 19975.236842,
    "out:Running Cost over RSP/MSEK": 5.94653,
    "out:LCP/MSEK": -0.921486,
    "out:ROI% old": 9.827914,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 9,
    "out:Return/kSEK/y": 261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 70825.434519,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125652.34,
    "out:DH kr savings": 70825.4345192767,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2953046.2895962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 100.3,
    "out:Total Energy Use Post PV": 98.138626,
    "out:Primary Energy": 83.832632,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.040281,
    "out:CO2 Operational/m2": -21.431925,
    "out:Total CO2/m2": 5.608356,
    "out:Total CO2 (tons)": 14.744365,
    "out:Klimatpaverkan": -105.394709,
    "out:Initial Cost/MSEK": 3.0187712770962505,
    "out:Running cost/(Apt SEK y)": 19139.473684,
    "out:Running Cost over RSP/MSEK": 5.69812,
    "out:LCP/MSEK": -0.738801,
    "out:ROI% old": 10.789489,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 10,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 79841.792639,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134668.7,
    "out:DH kr savings": 79841.79263946874,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3018771.2770962506,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 101.3,
    "out:Total Energy Use Post PV": 99.138626,
    "out:Primary Energy": 83.875082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.755382,
    "out:CO2 Operational/m2": -21.071941,
    "out:Total CO2/m2": 6.683441,
    "out:Total CO2 (tons)": 17.570762,
    "out:Klimatpaverkan": -102.568311,
    "out:Initial Cost/MSEK": 3.1807262895962505,
    "out:Running cost/(Apt SEK y)": 19348.421053,
    "out:Running Cost over RSP/MSEK": 5.76022,
    "out:LCP/MSEK": -0.962856,
    "out:ROI% old": 9.961202,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 31.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 9,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77564.874489,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132391.78,
    "out:DH kr savings": 77564.87448886722,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3180726.2895962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 98.3,
    "out:Total Energy Use Post PV": 96.138626,
    "out:Primary Energy": 81.207632,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.755382,
    "out:CO2 Operational/m2": -22.151893,
    "out:Total CO2/m2": 5.603489,
    "out:Total CO2 (tons)": 14.731569,
    "out:Klimatpaverkan": -105.407504,
    "out:Initial Cost/MSEK": 3.2464512770962504,
    "out:Running cost/(Apt SEK y)": 18721.605263,
    "out:Running Cost over RSP/MSEK": 5.57392,
    "out:LCP/MSEK": -0.842281,
    "out:ROI% old": 10.579332,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 35.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 10,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86581.232609,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141408.14,
    "out:DH kr savings": 86581.23260905925,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3246451.2770962506,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 102.026217,
    "out:Primary Energy": 86.297746,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.610081,
    "out:CO2 Operational/m2": -86.647193,
    "out:Total CO2/m2": -45.037112,
    "out:Total CO2 (tons)": -118.402541,
    "out:Klimatpaverkan": -238.541614,
    "out:Initial Cost/MSEK": 3.598029508645,
    "out:Running cost/(Apt SEK y)": 18603.289474,
    "out:Running Cost over RSP/MSEK": 5.55036,
    "out:LCP/MSEK": -1.1703,
    "out:ROI% old": 9.639125,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 9,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 70825.434519,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177785.88,
    "out:DH kr savings": 70825.4345192767,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3598029.508645,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 100.3,
    "out:Total Energy Use Post PV": 98.026217,
    "out:Primary Energy": 83.630296,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.610081,
    "out:CO2 Operational/m2": -88.087129,
    "out:Total CO2/m2": -46.477048,
    "out:Total CO2 (tons)": -122.188131,
    "out:Klimatpaverkan": -242.327205,
    "out:Initial Cost/MSEK": 3.6637544961450006,
    "out:Running cost/(Apt SEK y)": 17767.552632,
    "out:Running Cost over RSP/MSEK": 5.30195,
    "out:LCP/MSEK": -0.987614,
    "out:ROI% old": 10.434807,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 9,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 79841.792639,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186802.24,
    "out:DH kr savings": 79841.79263946874,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3663754.4961450007,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 101.3,
    "out:Total Energy Use Post PV": 99.026217,
    "out:Primary Energy": 83.672746,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.325182,
    "out:CO2 Operational/m2": -87.727145,
    "out:Total CO2/m2": -45.401963,
    "out:Total CO2 (tons)": -119.361734,
    "out:Klimatpaverkan": -239.500807,
    "out:Initial Cost/MSEK": 3.825709508645,
    "out:Running cost/(Apt SEK y)": 17976.473684,
    "out:Running Cost over RSP/MSEK": 5.36405,
    "out:LCP/MSEK": -1.21167,
    "out:ROI% old": 9.761177,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 31.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 9,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77564.874489,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184525.32,
    "out:DH kr savings": 77564.87448886722,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3825709.508645,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 98.3,
    "out:Total Energy Use Post PV": 96.026217,
    "out:Primary Energy": 81.005296,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.325182,
    "out:CO2 Operational/m2": -88.807097,
    "out:Total CO2/m2": -46.481915,
    "out:Total CO2 (tons)": -122.200927,
    "out:Klimatpaverkan": -242.34,
    "out:Initial Cost/MSEK": 3.8914344961450005,
    "out:Running cost/(Apt SEK y)": 17349.684211,
    "out:Running Cost over RSP/MSEK": 5.17775,
    "out:LCP/MSEK": -1.091094,
    "out:ROI% old": 10.280234,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 35.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 9,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 86581.232609,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193541.68,
    "out:DH kr savings": 86581.23260905925,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3891434.4961450007,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 100.3,
    "out:Total Energy Use Post PV": 100.3,
    "out:Primary Energy": 86.890555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.470481,
    "out:CO2 Operational/m2": 34.898122,
    "out:Total CO2/m2": 47.368603,
    "out:Total CO2 (tons)": 124.532029,
    "out:Klimatpaverkan": 4.392955,
    "out:Initial Cost/MSEK": 2.4083569280850003,
    "out:Running cost/(Apt SEK y)": 20582.289474,
    "out:Running Cost over RSP/MSEK": 6.11505,
    "out:LCP/MSEK": -0.545317,
    "out:ROI% old": 11.051043,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 30,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 10,
    "out:Return/kSEK/y": 238,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 79811.354479,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79811.35,
    "out:DH kr savings": 79811.35447873073,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2408356.9280850003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 97.3,
    "out:Total Energy Use Post PV": 97.3,
    "out:Primary Energy": 84.223105,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.470481,
    "out:CO2 Operational/m2": 33.81817,
    "out:Total CO2/m2": 46.288651,
    "out:Total CO2 (tons)": 121.692836,
    "out:Klimatpaverkan": 1.553762,
    "out:Initial Cost/MSEK": 2.4740819155850002,
    "out:Running cost/(Apt SEK y)": 19955.5,
    "out:Running Cost over RSP/MSEK": 5.92875,
    "out:LCP/MSEK": -0.424742,
    "out:ROI% old": 11.833192,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 34,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 11,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88827.712599,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88827.71,
    "out:DH kr savings": 88827.71259892275,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2474081.915585,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 98.3,
    "out:Total Energy Use Post PV": 98.3,
    "out:Primary Energy": 84.790555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.185582,
    "out:CO2 Operational/m2": 34.178154,
    "out:Total CO2/m2": 47.363736,
    "out:Total CO2 (tons)": 124.519234,
    "out:Klimatpaverkan": 4.38016,
    "out:Initial Cost/MSEK": 2.6360369280850002,
    "out:Running cost/(Apt SEK y)": 20164.421053,
    "out:Running Cost over RSP/MSEK": 5.99085,
    "out:LCP/MSEK": -0.648797,
    "out:ROI% old": 10.76963,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 10,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85202.906454,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85202.91,
    "out:DH kr savings": 85202.90645440311,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2636036.9280850003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 95.3,
    "out:Total Energy Use Post PV": 95.3,
    "out:Primary Energy": 82.123105,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.185582,
    "out:CO2 Operational/m2": 33.098202,
    "out:Total CO2/m2": 46.283784,
    "out:Total CO2 (tons)": 121.680041,
    "out:Klimatpaverkan": 1.540967,
    "out:Initial Cost/MSEK": 2.7017619155850006,
    "out:Running cost/(Apt SEK y)": 19537.605263,
    "out:Running Cost over RSP/MSEK": 5.80455,
    "out:LCP/MSEK": -0.528222,
    "out:ROI% old": 11.492712,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 36.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 10,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 94219.264575,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94219.26,
    "out:DH kr savings": 94219.26457459514,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2701761.9155850005,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 100.3,
    "out:Total Energy Use Post PV": 98.138626,
    "out:Primary Energy": 83.000082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.040281,
    "out:CO2 Operational/m2": -21.431925,
    "out:Total CO2/m2": 5.608356,
    "out:Total CO2 (tons)": 14.744365,
    "out:Klimatpaverkan": -105.394709,
    "out:Initial Cost/MSEK": 3.0533401645962504,
    "out:Running cost/(Apt SEK y)": 19139.473684,
    "out:Running Cost over RSP/MSEK": 5.69812,
    "out:LCP/MSEK": -0.77337,
    "out:ROI% old": 10.667334,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 10,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 79811.354479,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134638.26,
    "out:DH kr savings": 79811.35447873073,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3053340.1645962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 97.3,
    "out:Total Energy Use Post PV": 95.138626,
    "out:Primary Energy": 80.332632,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.040281,
    "out:CO2 Operational/m2": -22.511877,
    "out:Total CO2/m2": 4.528404,
    "out:Total CO2 (tons)": 11.905171,
    "out:Klimatpaverkan": -108.233902,
    "out:Initial Cost/MSEK": 3.1190651520962507,
    "out:Running cost/(Apt SEK y)": 18512.684211,
    "out:Running Cost over RSP/MSEK": 5.51182,
    "out:LCP/MSEK": -0.652795,
    "out:ROI% old": 11.29583,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 36.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 10,
    "out:Return/kSEK/y": 317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 88827.712599,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143654.62,
    "out:DH kr savings": 88827.71259892275,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3119065.1520962506,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 98.3,
    "out:Total Energy Use Post PV": 96.138626,
    "out:Primary Energy": 80.900082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.755382,
    "out:CO2 Operational/m2": -22.151893,
    "out:Total CO2/m2": 5.603489,
    "out:Total CO2 (tons)": 14.731569,
    "out:Klimatpaverkan": -105.407504,
    "out:Initial Cost/MSEK": 3.2810201645962502,
    "out:Running cost/(Apt SEK y)": 18721.605263,
    "out:Running Cost over RSP/MSEK": 5.57392,
    "out:LCP/MSEK": -0.87685,
    "out:ROI% old": 10.467868,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 35.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 9,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85202.906454,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140029.81,
    "out:DH kr savings": 85202.90645440311,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3281020.1645962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 95.3,
    "out:Total Energy Use Post PV": 93.138626,
    "out:Primary Energy": 78.232632,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.755382,
    "out:CO2 Operational/m2": -23.231845,
    "out:Total CO2/m2": 4.523537,
    "out:Total CO2 (tons)": 11.892376,
    "out:Klimatpaverkan": -108.246697,
    "out:Initial Cost/MSEK": 3.3467451520962506,
    "out:Running cost/(Apt SEK y)": 18094.815789,
    "out:Running Cost over RSP/MSEK": 5.38761,
    "out:LCP/MSEK": -0.756265,
    "out:ROI% old": 11.057567,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 10,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 94219.264575,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149046.17,
    "out:DH kr savings": 94219.26457459514,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3346745.1520962506,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 100.3,
    "out:Total Energy Use Post PV": 98.026217,
    "out:Primary Energy": 82.797746,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.610081,
    "out:CO2 Operational/m2": -88.087129,
    "out:Total CO2/m2": -46.477048,
    "out:Total CO2 (tons)": -122.188131,
    "out:Klimatpaverkan": -242.327205,
    "out:Initial Cost/MSEK": 3.6983233836450005,
    "out:Running cost/(Apt SEK y)": 17767.552632,
    "out:Running Cost over RSP/MSEK": 5.30195,
    "out:LCP/MSEK": -1.022183,
    "out:ROI% old": 10.337271,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 9,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 79811.354479,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186771.8,
    "out:DH kr savings": 79811.35447873073,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3698323.3836450004,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 97.3,
    "out:Total Energy Use Post PV": 95.026217,
    "out:Primary Energy": 80.130296,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.610081,
    "out:CO2 Operational/m2": -89.167081,
    "out:Total CO2/m2": -47.557,
    "out:Total CO2 (tons)": -125.027325,
    "out:Klimatpaverkan": -245.166398,
    "out:Initial Cost/MSEK": 3.764048371145001,
    "out:Running cost/(Apt SEK y)": 17140.736842,
    "out:Running Cost over RSP/MSEK": 5.11565,
    "out:LCP/MSEK": -0.901608,
    "out:ROI% old": 10.863835,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 36.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 88827.712599,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195788.16,
    "out:DH kr savings": 88827.71259892275,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3764048.3711450007,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.450713,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 98.3,
    "out:Total Energy Use Post PV": 96.026217,
    "out:Primary Energy": 80.697746,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.325182,
    "out:CO2 Operational/m2": -88.807097,
    "out:Total CO2/m2": -46.481915,
    "out:Total CO2 (tons)": -122.200927,
    "out:Klimatpaverkan": -242.34,
    "out:Initial Cost/MSEK": 3.9260033836450003,
    "out:Running cost/(Apt SEK y)": 17349.684211,
    "out:Running Cost over RSP/MSEK": 5.17775,
    "out:LCP/MSEK": -1.125663,
    "out:ROI% old": 10.189715,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 35.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 9,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85202.906454,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192163.36,
    "out:DH kr savings": 85202.90645440311,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 214133.875062,
    "out:% savings (space heating)": 24.854578,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.45,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3926003.3836450004,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.021135,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 95.3,
    "out:Total Energy Use Post PV": 93.026217,
    "out:Primary Energy": 78.030296,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.325182,
    "out:CO2 Operational/m2": -89.887049,
    "out:Total CO2/m2": -47.561867,
    "out:Total CO2 (tons)": -125.04012,
    "out:Klimatpaverkan": -245.179193,
    "out:Initial Cost/MSEK": 3.9917283711450007,
    "out:Running cost/(Apt SEK y)": 16722.868421,
    "out:Running Cost over RSP/MSEK": 4.99144,
    "out:LCP/MSEK": -1.005078,
    "out:ROI% old": 10.68871,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 94219.264575,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201179.71,
    "out:DH kr savings": 94219.26457459514,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 205117.516942,
    "out:% savings (space heating)": 28.018664,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.02,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3991728.3711450007,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.371802,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.135533,
    "out:Electricity (inc PV)": 27.176748,
    "out:Total Energy Use Pre PV": 63.135533,
    "out:Total Energy Use Post PV": 64.176748,
    "out:Primary Energy": 81.464874,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.20357,
    "out:CO2 Operational/m2": 16.769507,
    "out:Total CO2/m2": 32.973077,
    "out:Total CO2 (tons)": 86.6862,
    "out:Klimatpaverkan": -33.452874,
    "out:Initial Cost/MSEK": 4.819422737455602,
    "out:Running cost/(Apt SEK y)": 11346.973684,
    "out:Running Cost over RSP/MSEK": 3.36012,
    "out:LCP/MSEK": -0.201453,
    "out:ROI% old": 13.688569,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 103.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1829.48731,
    "out:Return %": 12,
    "out:Return/kSEK/y": 589,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.536033,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 231638.464232,
    "out:EL kWh savings": -57513.957432,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116610.55,
    "out:DH kr savings": 231638.46423173702,
    "out:El kr savings": -115027.91486316289,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98250.445147,
    "out:% savings (space heating)": 65.521237,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 41.85,
    "out:Etvv": 0,
    "out:Ef": 27.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4819422.737455602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": 3.450099,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.450099,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.261522,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.038777,
    "out:Electricity (inc PV)": 27.103562,
    "out:Total Energy Use Pre PV": 60.038777,
    "out:Total Energy Use Post PV": 61.103562,
    "out:Primary Energy": 78.853535,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.20357,
    "out:CO2 Operational/m2": 15.676783,
    "out:Total CO2/m2": 31.880353,
    "out:Total CO2 (tons)": 83.813429,
    "out:Klimatpaverkan": -36.325645,
    "out:Initial Cost/MSEK": 4.885147724955602,
    "out:Running cost/(Apt SEK y)": 10706.789474,
    "out:Running Cost over RSP/MSEK": 3.16988,
    "out:LCP/MSEK": -0.076938,
    "out:ROI% old": 14.060724,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1822.71439,
    "out:Return %": 13,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.439277,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 239815.388495,
    "out:EL kWh savings": -57321.551481,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125172.29,
    "out:DH kr savings": 239815.38849489985,
    "out:El kr savings": -114643.10296254694,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90073.520884,
    "out:% savings (space heating)": 68.390743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 38.66,
    "out:Etvv": 0,
    "out:Ef": 27.1,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4885147.724955603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": 3.437327,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.437327,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.971562,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.78303,
    "out:Electricity (inc PV)": 26.693162,
    "out:Total Energy Use Pre PV": 61.78303,
    "out:Total Energy Use Post PV": 62.693162,
    "out:Primary Energy": 78.918957,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.918671,
    "out:CO2 Operational/m2": 16.36299,
    "out:Total CO2/m2": 33.281661,
    "out:Total CO2 (tons)": 87.497467,
    "out:Klimatpaverkan": -32.641607,
    "out:Initial Cost/MSEK": 5.047102737455602,
    "out:Running cost/(Apt SEK y)": 11089.263158,
    "out:Running Cost over RSP/MSEK": 3.28369,
    "out:LCP/MSEK": -0.352703,
    "out:ROI% old": 13.287397,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1804.8121,
    "out:Return %": 12,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.18353,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 235319.694356,
    "out:EL kWh savings": -56242.610126,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122834.47,
    "out:DH kr savings": 235319.69435579376,
    "out:El kr savings": -112485.22025256457,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 94569.215023,
    "out:% savings (space heating)": 66.813081,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.69,
    "out:Etvv": 0,
    "out:Ef": 26.69,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5047102.737455602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": 3.403566,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.403566,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.886966,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.678491,
    "out:Electricity (inc PV)": 26.607711,
    "out:Total Energy Use Pre PV": 58.678491,
    "out:Total Energy Use Post PV": 59.607711,
    "out:Primary Energy": 76.317021,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.918671,
    "out:CO2 Operational/m2": 15.269238,
    "out:Total CO2/m2": 32.187909,
    "out:Total CO2 (tons)": 84.621994,
    "out:Klimatpaverkan": -35.51708,
    "out:Initial Cost/MSEK": 5.1128277249556024,
    "out:Running cost/(Apt SEK y)": 10448,
    "out:Running Cost over RSP/MSEK": 3.09314,
    "out:LCP/MSEK": -0.227878,
    "out:ROI% old": 13.649003,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 33,
    "out:El bought/kWh/m2": 1797.49437,
    "out:Return %": 12,
    "out:Return/kSEK/y": 623,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.078991,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 243429.095398,
    "out:EL kWh savings": -56017.959498,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131393.18,
    "out:DH kr savings": 243429.0953982899,
    "out:El kr savings": -112035.91899659307,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 86459.81398,
    "out:% savings (space heating)": 69.658891,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 36.54,
    "out:Etvv": 0,
    "out:Ef": 26.61,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5112827.724955603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.879472,
    "out:EL CO2": 3.389766,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.389766,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.371802,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.135533,
    "out:Electricity (inc PV)": 19.321835,
    "out:Total Energy Use Pre PV": 63.135533,
    "out:Total Energy Use Post PV": 56.321835,
    "out:Primary Energy": 67.326031,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.77337,
    "out:CO2 Operational/m2": -11.994151,
    "out:Total CO2/m2": 18.779219,
    "out:Total CO2 (tons)": 49.370556,
    "out:Klimatpaverkan": -70.768518,
    "out:Initial Cost/MSEK": 5.464405973966853,
    "out:Running cost/(Apt SEK y)": 9714.026316,
    "out:Running Cost over RSP/MSEK": 2.88776,
    "out:LCP/MSEK": -0.374076,
    "out:ROI% old": 13.307759,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1306.226291,
    "out:Return %": 12,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.536033,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14525.169581,
    "out:PV (% sold (El))": 41.910988,
    "out:PV (kWh self-consumed)": 20132.01778,
    "out:PV (ratio sold/self-consumed)": 0.721496,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231638.464232,
    "out:EL kWh savings": -36863.395844,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179699.43,
    "out:DH kr savings": 231638.46423173702,
    "out:El kr savings": -73726.79168792904,
    "out:El kr sold": 21787.7543713314,
    "out:El kr saved": 40264.03556,
    "out:El kr return": 62051.7899313314,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98250.445147,
    "out:% savings (space heating)": 65.521237,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 41.85,
    "out:Etvv": 0,
    "out:Ef": 19.32,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5464405.973966853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": -25.313559,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.758532,
    "out:Bought CO2": 2.444973,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.261522,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.038777,
    "out:Electricity (inc PV)": 19.262391,
    "out:Total Energy Use Pre PV": 60.038777,
    "out:Total Energy Use Post PV": 53.262391,
    "out:Primary Energy": 64.739427,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.77337,
    "out:CO2 Operational/m2": -13.1797,
    "out:Total CO2/m2": 17.59367,
    "out:Total CO2 (tons)": 46.253748,
    "out:Klimatpaverkan": -73.885326,
    "out:Initial Cost/MSEK": 5.530130961466853,
    "out:Running cost/(Apt SEK y)": 9074.473684,
    "out:Running Cost over RSP/MSEK": 2.69771,
    "out:LCP/MSEK": -0.249751,
    "out:ROI% old": 13.640545,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 145.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1300.697353,
    "out:Return %": 12,
    "out:Return/kSEK/y": 675,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.439277,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14573.828434,
    "out:PV (% sold (El))": 42.051388,
    "out:PV (kWh self-consumed)": 20083.358927,
    "out:PV (ratio sold/self-consumed)": 0.725667,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 239815.388495,
    "out:EL kWh savings": -36707.117603,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188261.9,
    "out:DH kr savings": 239815.38849489985,
    "out:El kr savings": -73414.23520690518,
    "out:El kr sold": 21860.742650967484,
    "out:El kr saved": 40166.717854,
    "out:El kr return": 62027.46050496749,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90073.520884,
    "out:% savings (space heating)": 68.390743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 38.66,
    "out:Etvv": 0,
    "out:Ef": 19.26,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5530130.961466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": -25.419156,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.853783,
    "out:Bought CO2": 2.434627,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.971562,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.78303,
    "out:Electricity (inc PV)": 18.929436,
    "out:Total Energy Use Pre PV": 61.78303,
    "out:Total Energy Use Post PV": 54.929436,
    "out:Primary Energy": 64.94425,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.488471,
    "out:CO2 Operational/m2": -12.739754,
    "out:Total CO2/m2": 18.748717,
    "out:Total CO2 (tons)": 49.290366,
    "out:Klimatpaverkan": -70.848708,
    "out:Initial Cost/MSEK": 5.692085973966853,
    "out:Running cost/(Apt SEK y)": 9458.657895,
    "out:Running Cost over RSP/MSEK": 2.81201,
    "out:LCP/MSEK": -0.526006,
    "out:ROI% old": 12.965571,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1286.098012,
    "out:Return %": 12,
    "out:Return/kSEK/y": 661,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.18353,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14703.237092,
    "out:PV (% sold (El))": 42.424785,
    "out:PV (kWh self-consumed)": 19953.950269,
    "out:PV (ratio sold/self-consumed)": 0.736858,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235319.694356,
    "out:EL kWh savings": -35831.779107,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185710.99,
    "out:DH kr savings": 235319.69435579376,
    "out:El kr savings": -71663.55821445338,
    "out:El kr sold": 22054.855637348377,
    "out:El kr saved": 39907.900538,
    "out:El kr return": 61962.756175348375,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 94569.215023,
    "out:% savings (space heating)": 66.813081,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.69,
    "out:Etvv": 0,
    "out:Ef": 18.93,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5692085.973966853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -25.699178,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.106479,
    "out:Bought CO2": 2.407301,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.886966,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.678491,
    "out:Electricity (inc PV)": 18.860174,
    "out:Total Energy Use Pre PV": 58.678491,
    "out:Total Energy Use Post PV": 51.860174,
    "out:Primary Energy": 62.371454,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.488471,
    "out:CO2 Operational/m2": -13.934549,
    "out:Total CO2/m2": 17.553922,
    "out:Total CO2 (tons)": 46.14925,
    "out:Klimatpaverkan": -73.989823,
    "out:Initial Cost/MSEK": 5.757810961466853,
    "out:Running cost/(Apt SEK y)": 8818.078947,
    "out:Running Cost over RSP/MSEK": 2.62166,
    "out:LCP/MSEK": -0.401381,
    "out:ROI% old": 13.289847,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 150,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 33,
    "out:El bought/kWh/m2": 1280.136635,
    "out:Return %": 12,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.078991,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14756.357124,
    "out:PV (% sold (El))": 42.578057,
    "out:PV (kWh self-consumed)": 19900.830237,
    "out:PV (ratio sold/self-consumed)": 0.741495,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 243429.095398,
    "out:EL kWh savings": -35649.689351,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194264.25,
    "out:DH kr savings": 243429.0953982899,
    "out:El kr savings": -71299.37870115228,
    "out:El kr sold": 22134.535685777075,
    "out:El kr saved": 39801.660474,
    "out:El kr return": 61936.19615977707,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 86459.81398,
    "out:% savings (space heating)": 69.658891,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 36.54,
    "out:Etvv": 0,
    "out:Ef": 18.86,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5757810.961466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.879472,
    "out:EL CO2": -25.814021,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.210162,
    "out:Bought CO2": 2.396141,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.371802,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.135533,
    "out:Electricity (inc PV)": 17.592352,
    "out:Total Energy Use Pre PV": 63.135533,
    "out:Total Energy Use Post PV": 54.592352,
    "out:Primary Energy": 64.212962,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.34317,
    "out:CO2 Operational/m2": -70.892106,
    "out:Total CO2/m2": -25.548936,
    "out:Total CO2 (tons)": -67.168137,
    "out:Klimatpaverkan": -187.307211,
    "out:Initial Cost/MSEK": 6.109389193015602,
    "out:Running cost/(Apt SEK y)": 8289.289474,
    "out:Running Cost over RSP/MSEK": 2.47598,
    "out:LCP/MSEK": -0.607279,
    "out:ROI% old": 12.8657,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1188.795511,
    "out:Return %": 12,
    "out:Return/kSEK/y": 705,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.536033,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44873.012501,
    "out:PV (% sold (El))": 64.738393,
    "out:PV (kWh self-consumed)": 24441.362221,
    "out:PV (ratio sold/self-consumed)": 1.835946,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231638.464232,
    "out:EL kWh savings": -32316.586069,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234314.81,
    "out:DH kr savings": 231638.46423173702,
    "out:El kr savings": -64633.17213893173,
    "out:El kr sold": 67309.51875129568,
    "out:El kr saved": 48882.724442,
    "out:El kr return": 116192.24319329567,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98250.445147,
    "out:% savings (space heating)": 65.521237,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 41.85,
    "out:Etvv": 0,
    "out:Ef": 17.59,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6109389.1930156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": -84.211514,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.438812,
    "out:Bought CO2": 2.227298,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.261522,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.038777,
    "out:Electricity (inc PV)": 17.539203,
    "out:Total Energy Use Pre PV": 60.038777,
    "out:Total Energy Use Post PV": 51.539203,
    "out:Primary Energy": 61.637688,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.34317,
    "out:CO2 Operational/m2": -72.120176,
    "out:Total CO2/m2": -26.777006,
    "out:Total CO2 (tons)": -70.396733,
    "out:Klimatpaverkan": -190.535806,
    "out:Initial Cost/MSEK": 6.175114180515602,
    "out:Running cost/(Apt SEK y)": 7650.026316,
    "out:Running Cost over RSP/MSEK": 2.28602,
    "out:LCP/MSEK": -0.483044,
    "out:ROI% old": 13.168224,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 150,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1183.851708,
    "out:Return %": 12,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.439277,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44944.036829,
    "out:PV (% sold (El))": 64.84086,
    "out:PV (kWh self-consumed)": 24370.337893,
    "out:PV (ratio sold/self-consumed)": 1.844211,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 239815.388495,
    "out:EL kWh savings": -32176.85738,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242877.73,
    "out:DH kr savings": 239815.38849489985,
    "out:El kr savings": -64353.71476039161,
    "out:El kr sold": 67416.05524287064,
    "out:El kr saved": 48740.675786,
    "out:El kr return": 116156.73102887065,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90073.520884,
    "out:% savings (space heating)": 68.390743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 38.66,
    "out:Etvv": 0,
    "out:Ef": 17.54,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6175114.180515602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": -84.359632,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.577694,
    "out:Bought CO2": 2.218062,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.971562,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.78303,
    "out:Electricity (inc PV)": 17.241674,
    "out:Total Energy Use Pre PV": 61.78303,
    "out:Total Energy Use Post PV": 53.241674,
    "out:Primary Energy": 61.906278,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.058271,
    "out:CO2 Operational/m2": -71.792979,
    "out:Total CO2/m2": -25.734708,
    "out:Total CO2 (tons)": -67.656532,
    "out:Klimatpaverkan": -187.795606,
    "out:Initial Cost/MSEK": 6.337069193015601,
    "out:Running cost/(Apt SEK y)": 8035,
    "out:Running Cost over RSP/MSEK": 2.40055,
    "out:LCP/MSEK": -0.759529,
    "out:ROI% old": 12.573501,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 145.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1170.800831,
    "out:Return %": 11,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.18353,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45132.585115,
    "out:PV (% sold (El))": 65.112879,
    "out:PV (kWh self-consumed)": 24181.789607,
    "out:PV (ratio sold/self-consumed)": 1.866387,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 235319.694356,
    "out:EL kWh savings": -31394.653817,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240229.26,
    "out:DH kr savings": 235319.69435579376,
    "out:El kr savings": -62789.307633641256,
    "out:El kr sold": 67698.87767231141,
    "out:El kr saved": 48363.579214,
    "out:El kr return": 116062.45688631141,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 94569.215023,
    "out:% savings (space heating)": 66.813081,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.69,
    "out:Etvv": 0,
    "out:Ef": 17.24,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6337069.1930156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -84.752403,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.946086,
    "out:Bought CO2": 2.193683,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.886966,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.678491,
    "out:Electricity (inc PV)": 17.179808,
    "out:Total Energy Use Pre PV": 58.678491,
    "out:Total Energy Use Post PV": 50.179808,
    "out:Primary Energy": 59.346795,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.058271,
    "out:CO2 Operational/m2": -73.034036,
    "out:Total CO2/m2": -26.975765,
    "out:Total CO2 (tons)": -70.91927,
    "out:Klimatpaverkan": -191.058344,
    "out:Initial Cost/MSEK": 6.402794180515603,
    "out:Running cost/(Apt SEK y)": 7394.736842,
    "out:Running Cost over RSP/MSEK": 2.21029,
    "out:LCP/MSEK": -0.634994,
    "out:ROI% old": 12.868935,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 160,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 33,
    "out:El bought/kWh/m2": 1165.47322,
    "out:Return %": 12,
    "out:Return/kSEK/y": 739,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.078991,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45209.971992,
    "out:PV (% sold (El))": 65.224525,
    "out:PV (kWh self-consumed)": 24104.40273,
    "out:PV (ratio sold/self-consumed)": 1.87559,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 243429.095398,
    "out:EL kWh savings": -31232.00814,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248780.04,
    "out:DH kr savings": 243429.0953982899,
    "out:El kr savings": -62464.01627950925,
    "out:El kr sold": 67814.95798774221,
    "out:El kr saved": 48208.80546,
    "out:El kr return": 116023.76344774221,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 86459.81398,
    "out:% savings (space heating)": 69.658891,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 36.54,
    "out:Etvv": 0,
    "out:Ef": 17.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6402794.180515602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.879472,
    "out:EL CO2": -84.913508,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.097241,
    "out:Bought CO2": 2.183733,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.510118,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.663922,
    "out:Electricity (inc PV)": 26.527626,
    "out:Total Energy Use Pre PV": 61.663922,
    "out:Total Energy Use Post PV": 62.527626,
    "out:Primary Energy": 78.073647,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.20357,
    "out:CO2 Operational/m2": 16.347267,
    "out:Total CO2/m2": 32.550837,
    "out:Total CO2 (tons)": 85.576131,
    "out:Klimatpaverkan": -34.562942,
    "out:Initial Cost/MSEK": 4.919716612455602,
    "out:Running cost/(Apt SEK y)": 11072.789474,
    "out:Running Cost over RSP/MSEK": 3.27885,
    "out:LCP/MSEK": -0.220477,
    "out:ROI% old": 13.645501,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1796.47454,
    "out:Return %": 12,
    "out:Return/kSEK/y": 600,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.064422,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236532.830356,
    "out:EL kWh savings": -55807.416081,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124918,
    "out:DH kr savings": 236532.8303563117,
    "out:El kr savings": -111614.83216221453,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 93356.079022,
    "out:% savings (space heating)": 67.238804,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 38.99,
    "out:Etvv": 0,
    "out:Ef": 26.53,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4919716.612455602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": 3.387843,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.387843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.4336,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.556935,
    "out:Electricity (inc PV)": 26.43867,
    "out:Total Energy Use Pre PV": 57.556935,
    "out:Total Energy Use Post PV": 58.43867,
    "out:Primary Energy": 75.474397,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.20357,
    "out:CO2 Operational/m2": 14.893208,
    "out:Total CO2/m2": 31.096778,
    "out:Total CO2 (tons)": 81.753411,
    "out:Klimatpaverkan": -38.385663,
    "out:Initial Cost/MSEK": 4.985441599955602,
    "out:Running cost/(Apt SEK y)": 10222.236842,
    "out:Running Cost over RSP/MSEK": 3.02609,
    "out:LCP/MSEK": -0.033442,
    "out:ROI% old": 14.189888,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 1788.98545,
    "out:Return %": 13,
    "out:Return/kSEK/y": 632,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.957435,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244620.994342,
    "out:EL kWh savings": -55573.55081,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133473.89,
    "out:DH kr savings": 244620.99434163043,
    "out:El kr savings": -111147.10162042802,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 85267.915037,
    "out:% savings (space heating)": 70.077161,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 35.85,
    "out:Etvv": 0,
    "out:Ef": 26.44,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4985441.599955603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": 3.37372,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.37372,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.413303,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.374836,
    "out:Electricity (inc PV)": 26.123275,
    "out:Total Energy Use Pre PV": 59.374836,
    "out:Total Energy Use Post PV": 60.123275,
    "out:Primary Energy": 76.050316,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.918671,
    "out:CO2 Operational/m2": 15.589137,
    "out:Total CO2/m2": 32.507808,
    "out:Total CO2 (tons)": 85.463008,
    "out:Klimatpaverkan": -34.676066,
    "out:Initial Cost/MSEK": 5.147396612455601,
    "out:Running cost/(Apt SEK y)": 10614.921053,
    "out:Running Cost over RSP/MSEK": 3.14289,
    "out:LCP/MSEK": -0.312197,
    "out:ROI% old": 13.419266,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1776.23852,
    "out:Return %": 12,
    "out:Return/kSEK/y": 617,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.775336,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 239416.356337,
    "out:EL kWh savings": -54744.377544,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129927.6,
    "out:DH kr savings": 239416.3563365131,
    "out:El kr savings": -109488.7550870096,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90472.553042,
    "out:% savings (space heating)": 68.250712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.32,
    "out:Etvv": 0,
    "out:Ef": 26.12,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5147396.612455602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": 3.349681,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.349681,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.353597,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.262754,
    "out:Electricity (inc PV)": 26.026259,
    "out:Total Energy Use Pre PV": 56.262754,
    "out:Total Energy Use Post PV": 57.026259,
    "out:Primary Energy": 73.457244,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.918671,
    "out:CO2 Operational/m2": 14.49439,
    "out:Total CO2/m2": 31.413061,
    "out:Total CO2 (tons)": 82.584919,
    "out:Klimatpaverkan": -37.554155,
    "out:Initial Cost/MSEK": 5.213121599955603,
    "out:Running cost/(Apt SEK y)": 9972.578947,
    "out:Running Cost over RSP/MSEK": 2.95203,
    "out:LCP/MSEK": -0.187062,
    "out:ROI% old": 13.773102,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 128.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 1768.39278,
    "out:Return %": 12,
    "out:Return/kSEK/y": 641,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.663254,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247460.321584,
    "out:EL kWh savings": -54489.322537,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138481.68,
    "out:DH kr savings": 247460.32158386864,
    "out:El kr savings": -108978.64507484672,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 82428.587795,
    "out:% savings (space heating)": 71.073559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 34.21,
    "out:Etvv": 0,
    "out:Ef": 26.03,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5213121.599955603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": 3.334886,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.334886,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.510118,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.663922,
    "out:Electricity (inc PV)": 18.795281,
    "out:Total Energy Use Pre PV": 61.663922,
    "out:Total Energy Use Post PV": 54.795281,
    "out:Primary Energy": 64.155426,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.77337,
    "out:CO2 Operational/m2": -12.870619,
    "out:Total CO2/m2": 17.902751,
    "out:Total CO2 (tons)": 47.066322,
    "out:Klimatpaverkan": -73.072752,
    "out:Initial Cost/MSEK": 5.5646998489668515,
    "out:Running cost/(Apt SEK y)": 9442.973684,
    "out:Running Cost over RSP/MSEK": 2.8074,
    "out:LCP/MSEK": -0.39401,
    "out:ROI% old": 13.274211,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1279.306098,
    "out:Return %": 12,
    "out:Return/kSEK/y": 661,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.064422,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14763.768278,
    "out:PV (% sold (El))": 42.599442,
    "out:PV (kWh self-consumed)": 19893.419083,
    "out:PV (ratio sold/self-consumed)": 0.742143,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236532.830356,
    "out:EL kWh savings": -35479.085692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187720.31,
    "out:DH kr savings": 236532.8303563117,
    "out:El kr savings": -70958.1713846345,
    "out:El kr sold": 22145.65241650524,
    "out:El kr saved": 39786.838166,
    "out:El kr return": 61932.49058250524,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 93356.079022,
    "out:% savings (space heating)": 67.238804,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 38.99,
    "out:Etvv": 0,
    "out:Ef": 18.8,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5564699.848966852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -25.830043,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.22463,
    "out:Bought CO2": 2.394587,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.4336,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.556935,
    "out:Electricity (inc PV)": 18.723231,
    "out:Total Energy Use Pre PV": 57.556935,
    "out:Total Energy Use Post PV": 50.723231,
    "out:Primary Energy": 61.586607,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.77337,
    "out:CO2 Operational/m2": -14.428357,
    "out:Total CO2/m2": 16.345013,
    "out:Total CO2 (tons)": 42.971029,
    "out:Klimatpaverkan": -77.168044,
    "out:Initial Cost/MSEK": 5.630424836466853,
    "out:Running cost/(Apt SEK y)": 8593.157895,
    "out:Running Cost over RSP/MSEK": 2.55486,
    "out:LCP/MSEK": -0.207195,
    "out:ROI% old": 13.760012,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 154.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 1273.209451,
    "out:Return %": 12,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.957435,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14818.239037,
    "out:PV (% sold (El))": 42.756612,
    "out:PV (kWh self-consumed)": 19838.948324,
    "out:PV (ratio sold/self-consumed)": 0.746927,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244620.994342,
    "out:EL kWh savings": -35289.666285,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196269.02,
    "out:DH kr savings": 244620.99434163043,
    "out:El kr savings": -70579.33257066217,
    "out:El kr sold": 22227.3585555048,
    "out:El kr saved": 39677.896648,
    "out:El kr return": 61905.2552035048,
    "out:% solar/total": 142,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 85267.915037,
    "out:% savings (space heating)": 70.077161,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 35.85,
    "out:Etvv": 0,
    "out:Ef": 18.72,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5630424.836466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": -25.947845,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.331018,
    "out:Bought CO2": 2.383173,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.413303,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.374836,
    "out:Electricity (inc PV)": 18.467943,
    "out:Total Energy Use Pre PV": 59.374836,
    "out:Total Energy Use Post PV": 52.467943,
    "out:Primary Energy": 62.270718,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.488471,
    "out:CO2 Operational/m2": -13.909484,
    "out:Total CO2/m2": 17.578987,
    "out:Total CO2 (tons)": 46.215146,
    "out:Klimatpaverkan": -73.923927,
    "out:Initial Cost/MSEK": 5.792379848966852,
    "out:Running cost/(Apt SEK y)": 8987.052632,
    "out:Running Cost over RSP/MSEK": 2.67201,
    "out:LCP/MSEK": -0.4863,
    "out:ROI% old": 13.086356,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 150,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1262.841449,
    "out:Return %": 12,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.775336,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14911.332051,
    "out:PV (% sold (El))": 43.025223,
    "out:PV (kWh self-consumed)": 19745.85531,
    "out:PV (ratio sold/self-consumed)": 0.755163,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 239416.356337,
    "out:EL kWh savings": -34618.514286,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192546.33,
    "out:DH kr savings": 239416.3563365131,
    "out:El kr savings": -69237.02857147608,
    "out:El kr sold": 22366.998075934407,
    "out:El kr saved": 39491.71062,
    "out:El kr return": 61858.7086959344,
    "out:% solar/total": 142,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90472.553042,
    "out:% savings (space heating)": 68.250712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.32,
    "out:Etvv": 0,
    "out:Ef": 18.47,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5792379.848966852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": -26.14894,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.512701,
    "out:Bought CO2": 2.363761,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.353597,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.262754,
    "out:Electricity (inc PV)": 18.389495,
    "out:Total Energy Use Pre PV": 56.262754,
    "out:Total Energy Use Post PV": 49.389495,
    "out:Primary Energy": 59.711069,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.488471,
    "out:CO2 Operational/m2": -15.113607,
    "out:Total CO2/m2": 16.374864,
    "out:Total CO2 (tons)": 43.049508,
    "out:Klimatpaverkan": -77.089566,
    "out:Initial Cost/MSEK": 5.858104836466852,
    "out:Running cost/(Apt SEK y)": 8345.473684,
    "out:Running Cost over RSP/MSEK": 2.48137,
    "out:LCP/MSEK": -0.361385,
    "out:ROI% old": 13.404433,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 165.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 1256.465587,
    "out:Return %": 12,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.663254,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14968.834074,
    "out:PV (% sold (El))": 43.191139,
    "out:PV (kWh self-consumed)": 19688.353287,
    "out:PV (ratio sold/self-consumed)": 0.760289,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247460.321584,
    "out:EL kWh savings": -34412.274541,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201089.02,
    "out:DH kr savings": 247460.32158386864,
    "out:El kr savings": -68824.54908114295,
    "out:El kr sold": 22453.251110421294,
    "out:El kr saved": 39376.706574,
    "out:El kr return": 61829.9576844213,
    "out:% solar/total": 141,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 82428.587795,
    "out:% savings (space heating)": 71.073559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 34.21,
    "out:Etvv": 0,
    "out:Ef": 18.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5858104.836466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": -26.273111,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.624935,
    "out:Bought CO2": 2.351824,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.510118,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.663922,
    "out:Electricity (inc PV)": 17.121869,
    "out:Total Energy Use Pre PV": 61.663922,
    "out:Total Energy Use Post PV": 53.121869,
    "out:Primary Energy": 61.143284,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.34317,
    "out:CO2 Operational/m2": -71.976564,
    "out:Total CO2/m2": -26.633394,
    "out:Total CO2 (tons)": -70.019177,
    "out:Klimatpaverkan": -190.15825,
    "out:Initial Cost/MSEK": 6.209683068015601,
    "out:Running cost/(Apt SEK y)": 8019.657895,
    "out:Running Cost over RSP/MSEK": 2.39604,
    "out:LCP/MSEK": -0.627633,
    "out:ROI% old": 12.84181,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 145.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1164.731071,
    "out:Return %": 12,
    "out:Return/kSEK/y": 716,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.064422,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45220.775106,
    "out:PV (% sold (El))": 65.240111,
    "out:PV (kWh self-consumed)": 24093.599615,
    "out:PV (ratio sold/self-consumed)": 1.876879,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236532.830356,
    "out:EL kWh savings": -31079.686543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242204.62,
    "out:DH kr savings": 236532.8303563117,
    "out:El kr savings": -62159.37308668843,
    "out:El kr sold": 67831.16265921689,
    "out:El kr saved": 48187.19923,
    "out:El kr return": 116018.36188921689,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 93356.079022,
    "out:% savings (space heating)": 67.238804,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 38.99,
    "out:Etvv": 0,
    "out:Ef": 17.12,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6209683.0680156015,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -84.935988,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.118334,
    "out:Bought CO2": 2.182346,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.4336,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.556935,
    "out:Electricity (inc PV)": 17.057567,
    "out:Total Energy Use Pre PV": 57.556935,
    "out:Total Energy Use Post PV": 49.057567,
    "out:Primary Energy": 58.588412,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.34317,
    "out:CO2 Operational/m2": -73.581787,
    "out:Total CO2/m2": -28.238617,
    "out:Total CO2 (tons)": -74.239307,
    "out:Klimatpaverkan": -194.378381,
    "out:Initial Cost/MSEK": 6.2754080555156015,
    "out:Running cost/(Apt SEK y)": 7170.157895,
    "out:Running Cost over RSP/MSEK": 2.1436,
    "out:LCP/MSEK": -0.440918,
    "out:ROI% old": 13.281982,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 165.3,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 1159.283566,
    "out:Return %": 12,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.957435,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45300.205706,
    "out:PV (% sold (El))": 65.354706,
    "out:PV (kWh self-consumed)": 24014.169015,
    "out:PV (ratio sold/self-consumed)": 1.886395,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244620.994342,
    "out:EL kWh savings": -30910.636624,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 250750.03,
    "out:DH kr savings": 244620.99434163043,
    "out:El kr savings": -61821.273247465026,
    "out:El kr sold": 67950.3085595645,
    "out:El kr saved": 48028.33803,
    "out:El kr return": 115978.6465895645,
    "out:% solar/total": 172,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 85267.915037,
    "out:% savings (space heating)": 70.077161,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 35.85,
    "out:Etvv": 0,
    "out:Ef": 17.06,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6275408.055515601,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": -85.101275,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.273448,
    "out:Bought CO2": 2.172173,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.413303,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.374836,
    "out:Electricity (inc PV)": 16.829863,
    "out:Total Energy Use Pre PV": 59.374836,
    "out:Total Energy Use Post PV": 50.829863,
    "out:Primary Energy": 59.322174,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.058271,
    "out:CO2 Operational/m2": -73.144003,
    "out:Total CO2/m2": -27.085732,
    "out:Total CO2 (tons)": -71.208373,
    "out:Klimatpaverkan": -191.347447,
    "out:Initial Cost/MSEK": 6.4373630680156015,
    "out:Running cost/(Apt SEK y)": 7564.631579,
    "out:Running Cost over RSP/MSEK": 2.26091,
    "out:LCP/MSEK": -0.720183,
    "out:ROI% old": 12.687493,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 154.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1150.021676,
    "out:Return %": 11,
    "out:Return/kSEK/y": 733,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.775336,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45435.894812,
    "out:PV (% sold (El))": 65.550465,
    "out:PV (kWh self-consumed)": 23878.47991,
    "out:PV (ratio sold/self-consumed)": 1.902797,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 239416.356337,
    "out:EL kWh savings": -30312.002944,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 246946.19,
    "out:DH kr savings": 239416.3563365131,
    "out:El kr savings": -60624.005887343585,
    "out:El kr sold": 68153.84221784495,
    "out:El kr saved": 47756.95982,
    "out:El kr return": 115910.80203784496,
    "out:% solar/total": 171,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90472.553042,
    "out:% savings (space heating)": 68.250712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.32,
    "out:Etvv": 0,
    "out:Ef": 16.83,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6437363.0680156015,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": -85.383459,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.53834,
    "out:Bought CO2": 2.154881,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.353597,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.262754,
    "out:Electricity (inc PV)": 16.759944,
    "out:Total Energy Use Pre PV": 56.262754,
    "out:Total Energy Use Post PV": 47.759944,
    "out:Primary Energy": 56.777877,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.058271,
    "out:CO2 Operational/m2": -74.398173,
    "out:Total CO2/m2": -28.339902,
    "out:Total CO2 (tons)": -74.505585,
    "out:Klimatpaverkan": -194.644659,
    "out:Initial Cost/MSEK": 6.503088055515601,
    "out:Running cost/(Apt SEK y)": 6923.421053,
    "out:Running Cost over RSP/MSEK": 2.07037,
    "out:LCP/MSEK": -0.595368,
    "out:ROI% old": 12.977834,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 170.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 1144.327299,
    "out:Return %": 12,
    "out:Return/kSEK/y": 757,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.663254,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45519.63476,
    "out:PV (% sold (El))": 65.671277,
    "out:PV (kWh self-consumed)": 23794.739962,
    "out:PV (ratio sold/self-consumed)": 1.913012,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247460.321584,
    "out:EL kWh savings": -30128.185934,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255483.4,
    "out:DH kr savings": 247460.32158386864,
    "out:El kr savings": -60256.37186882688,
    "out:El kr sold": 68279.45213968195,
    "out:El kr saved": 47589.479924,
    "out:El kr return": 115868.93206368195,
    "out:% solar/total": 171,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 82428.587795,
    "out:% savings (space heating)": 71.073559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 34.21,
    "out:Etvv": 0,
    "out:Ef": 16.76,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6503088.055515601,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": -85.557677,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.701928,
    "out:Bought CO2": 2.144251,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.230974,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.875178,
    "out:Electricity (inc PV)": 37.706961,
    "out:Total Energy Use Pre PV": 40.875178,
    "out:Total Energy Use Post PV": 42.706961,
    "out:Primary Energy": 73.391711,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.642581,
    "out:CO2 Operational/m2": 6.53573,
    "out:Total CO2/m2": 23.178311,
    "out:Total CO2 (tons)": 60.935766,
    "out:Klimatpaverkan": -59.203308,
    "out:Initial Cost/MSEK": 5.880172737455601,
    "out:Running cost/(Apt SEK y)": 6008.684211,
    "out:Running Cost over RSP/MSEK": 1.76878,
    "out:LCP/MSEK": 0.329137,
    "out:ROI% old": 15.085344,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2511.26246,
    "out:Return %": 13,
    "out:Return/kSEK/y": 792,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 28.275678,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 316136.681856,
    "out:EL kWh savings": -85197.881122,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145740.92,
    "out:DH kr savings": 316136.68185566267,
    "out:El kr savings": -170395.76224408858,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 13752.227523,
    "out:% savings (space heating)": 95.173968,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.1,
    "out:Etvv": 0,
    "out:Ef": 37.71,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5880172.737455602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 4.73581,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.73581,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.765968,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.279854,
    "out:Electricity (inc PV)": 37.192807,
    "out:Total Energy Use Pre PV": 39.279854,
    "out:Total Energy Use Post PV": 41.192807,
    "out:Primary Energy": 71.118446,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.642581,
    "out:CO2 Operational/m2": 6.097159,
    "out:Total CO2/m2": 22.73974,
    "out:Total CO2 (tons)": 59.782763,
    "out:Klimatpaverkan": -60.356311,
    "out:Initial Cost/MSEK": 5.945897724955603,
    "out:Running cost/(Apt SEK y)": 5717.368421,
    "out:Running Cost over RSP/MSEK": 1.68248,
    "out:LCP/MSEK": 0.349712,
    "out:ROI% old": 15.125939,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 217.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2469.58978,
    "out:Return %": 14,
    "out:Return/kSEK/y": 803,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 27.680354,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319988.181755,
    "out:EL kWh savings": -83846.170563,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152295.84,
    "out:DH kr savings": 319988.1817550541,
    "out:El kr savings": -167692.34112598843,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 9900.727624,
    "out:% savings (space heating)": 96.525564,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.36,
    "out:Etvv": 0,
    "out:Ef": 37.19,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5945897.724955603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 4.657223,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.657223,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.69791,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.259895,
    "out:Electricity (inc PV)": 36.851708,
    "out:Total Energy Use Pre PV": 40.259895,
    "out:Total Energy Use Post PV": 41.851708,
    "out:Primary Energy": 71.130739,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.357682,
    "out:CO2 Operational/m2": 6.454508,
    "out:Total CO2/m2": 23.81219,
    "out:Total CO2 (tons)": 62.602233,
    "out:Klimatpaverkan": -57.53684,
    "out:Initial Cost/MSEK": 6.107852737455603,
    "out:Running cost/(Apt SEK y)": 5923.526316,
    "out:Running Cost over RSP/MSEK": 1.74377,
    "out:LCP/MSEK": 0.126467,
    "out:ROI% old": 14.58151,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 209.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2468.19265,
    "out:Return %": 13,
    "out:Return/kSEK/y": 795,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.660395,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 317538.106793,
    "out:EL kWh savings": -82949.421496,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151639.26,
    "out:DH kr savings": 317538.1067934235,
    "out:El kr savings": -165898.84299126064,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 12350.802585,
    "out:% savings (space heating)": 95.665766,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.17,
    "out:Etvv": 0,
    "out:Ef": 36.85,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6107852.737455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 4.654588,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.654588,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.320795,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.637936,
    "out:Electricity (inc PV)": 36.299083,
    "out:Total Energy Use Pre PV": 37.637936,
    "out:Total Energy Use Post PV": 39.299083,
    "out:Primary Energy": 68.886967,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.357682,
    "out:CO2 Operational/m2": 5.652437,
    "out:Total CO2/m2": 23.010119,
    "out:Total CO2 (tons)": 60.493589,
    "out:Klimatpaverkan": -59.645484,
    "out:Initial Cost/MSEK": 6.173577724955604,
    "out:Running cost/(Apt SEK y)": 5419.605263,
    "out:Running Cost over RSP/MSEK": 1.59429,
    "out:LCP/MSEK": 0.210222,
    "out:ROI% old": 14.772171,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 233.3,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2424.65552,
    "out:Return %": 13,
    "out:Return/kSEK/y": 814,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.038436,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 321158.541306,
    "out:EL kWh savings": -81496.570701,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158165.4,
    "out:DH kr savings": 321158.5413062858,
    "out:El kr savings": -162993.1414010949,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 8730.368072,
    "out:% savings (space heating)": 96.936276,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 4.56,
    "out:Etvv": 0,
    "out:Ef": 36.3,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6173577.7249556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": 4.572485,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.572485,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.230974,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.875178,
    "out:Electricity (inc PV)": 28.100758,
    "out:Total Energy Use Pre PV": 40.875178,
    "out:Total Energy Use Post PV": 33.100758,
    "out:Primary Energy": 56.100545,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 31.212381,
    "out:CO2 Operational/m2": -13.875051,
    "out:Total CO2/m2": 17.33733,
    "out:Total CO2 (tons)": 45.57983,
    "out:Klimatpaverkan": -74.559243,
    "out:Initial Cost/MSEK": 6.525155973966853,
    "out:Running cost/(Apt SEK y)": 4317.894737,
    "out:Running Cost over RSP/MSEK": 1.27963,
    "out:LCP/MSEK": 0.173304,
    "out:ROI% old": 14.665134,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 293.9,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1876.634765,
    "out:Return %": 13,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 28.275678,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10128.913816,
    "out:PV (% sold (El))": 29.226012,
    "out:PV (kWh self-consumed)": 24528.273545,
    "out:PV (ratio sold/self-consumed)": 0.412949,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 316136.681856,
    "out:EL kWh savings": -59943.17917,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211443.69,
    "out:DH kr savings": 316136.68185566267,
    "out:El kr savings": -119886.3583398949,
    "out:El kr sold": 15193.370723534568,
    "out:El kr saved": 49056.54709,
    "out:El kr return": 64249.91781353457,
    "out:% solar/total": 176,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 13752.227523,
    "out:% savings (space heating)": 95.173968,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.1,
    "out:Etvv": 0,
    "out:Ef": 28.1,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6525155.973966853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -15.674971,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.187374,
    "out:Bought CO2": 3.512403,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.765968,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.279854,
    "out:Electricity (inc PV)": 27.662815,
    "out:Total Energy Use Pre PV": 39.279854,
    "out:Total Energy Use Post PV": 31.662815,
    "out:Primary Energy": 53.96446,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 31.212381,
    "out:CO2 Operational/m2": -14.774121,
    "out:Total CO2/m2": 16.43826,
    "out:Total CO2 (tons)": 43.216176,
    "out:Klimatpaverkan": -76.922898,
    "out:Initial Cost/MSEK": 6.590880961466852,
    "out:Running cost/(Apt SEK y)": 4029.763158,
    "out:Running Cost over RSP/MSEK": 1.19426,
    "out:LCP/MSEK": 0.192949,
    "out:ROI% old": 14.703931,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 306.2,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1841.008382,
    "out:Return %": 13,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 27.680354,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10371.661123,
    "out:PV (% sold (El))": 29.926436,
    "out:PV (kWh self-consumed)": 24285.526238,
    "out:PV (ratio sold/self-consumed)": 0.427072,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319988.181755,
    "out:EL kWh savings": -58791.827284,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217962.02,
    "out:DH kr savings": 319988.1817550541,
    "out:El kr savings": -117583.65456879885,
    "out:El kr sold": 15557.491684141482,
    "out:El kr saved": 48571.052476,
    "out:El kr return": 64128.54416014148,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 9900.727624,
    "out:% savings (space heating)": 96.525564,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.36,
    "out:Etvv": 0,
    "out:Ef": 27.66,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6590880.961466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -16.214057,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.659907,
    "out:Bought CO2": 3.44585,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.69791,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.259895,
    "out:Electricity (inc PV)": 27.372986,
    "out:Total Energy Use Pre PV": 40.259895,
    "out:Total Energy Use Post PV": 32.372986,
    "out:Primary Energy": 54.06904,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 31.927482,
    "out:CO2 Operational/m2": -14.432313,
    "out:Total CO2/m2": 17.495169,
    "out:Total CO2 (tons)": 45.994789,
    "out:Klimatpaverkan": -74.144285,
    "out:Initial Cost/MSEK": 6.752835973966853,
    "out:Running cost/(Apt SEK y)": 4236.052632,
    "out:Running Cost over RSP/MSEK": 1.25558,
    "out:LCP/MSEK": -0.030326,
    "out:ROI% old": 14.221559,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 306.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1839.815826,
    "out:Return %": 13,
    "out:Return/kSEK/y": 859,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.660395,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10379.855558,
    "out:PV (% sold (El))": 29.950081,
    "out:PV (kWh self-consumed)": 24277.331803,
    "out:PV (ratio sold/self-consumed)": 0.427553,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 317538.106793,
    "out:EL kWh savings": -58029.867016,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217048.16,
    "out:DH kr savings": 317538.1067934235,
    "out:El kr savings": -116059.73403285469,
    "out:El kr sold": 15569.783337238689,
    "out:El kr saved": 48554.663606,
    "out:El kr return": 64124.44694323869,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 12350.802585,
    "out:% savings (space heating)": 95.665766,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.17,
    "out:Etvv": 0,
    "out:Ef": 27.37,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6752835.973966853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -16.232233,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.675854,
    "out:Bought CO2": 3.443621,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.320795,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.637936,
    "out:Electricity (inc PV)": 26.904157,
    "out:Total Energy Use Pre PV": 37.637936,
    "out:Total Energy Use Post PV": 29.904157,
    "out:Primary Energy": 51.976101,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 31.927482,
    "out:CO2 Operational/m2": -15.722891,
    "out:Total CO2/m2": 16.204591,
    "out:Total CO2 (tons)": 42.60186,
    "out:Klimatpaverkan": -77.537213,
    "out:Initial Cost/MSEK": 6.8185609614668525,
    "out:Running cost/(Apt SEK y)": 3735.526316,
    "out:Running Cost over RSP/MSEK": 1.10708,
    "out:LCP/MSEK": 0.052449,
    "out:ROI% old": 14.395601,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 333.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 1802.715036,
    "out:Return %": 13,
    "out:Return/kSEK/y": 878,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.038436,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10638.186797,
    "out:PV (% sold (El))": 30.695471,
    "out:PV (kWh self-consumed)": 24019.000564,
    "out:PV (ratio sold/self-consumed)": 0.442907,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 321158.541306,
    "out:EL kWh savings": -56797.315855,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223521.19,
    "out:DH kr savings": 321158.5413062858,
    "out:El kr savings": -113594.63171063656,
    "out:El kr sold": 15957.280195505959,
    "out:El kr saved": 48038.001128,
    "out:El kr return": 63995.28132350596,
    "out:% solar/total": 172,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 8730.368072,
    "out:% savings (space heating)": 96.936276,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 4.56,
    "out:Etvv": 0,
    "out:Ef": 26.9,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6818560.961466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -16.802843,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -20.177127,
    "out:Bought CO2": 3.374284,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.230974,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.875178,
    "out:Electricity (inc PV)": 25.460602,
    "out:Total Energy Use Pre PV": 40.875178,
    "out:Total Energy Use Post PV": 30.460602,
    "out:Primary Energy": 51.348265,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 45.782181,
    "out:CO2 Operational/m2": -68.516539,
    "out:Total CO2/m2": -22.734358,
    "out:Total CO2 (tons)": -59.768614,
    "out:Klimatpaverkan": -179.907687,
    "out:Initial Cost/MSEK": 7.1701391930156015,
    "out:Running cost/(Apt SEK y)": 2864,
    "out:Running Cost over RSP/MSEK": 0.8593,
    "out:LCP/MSEK": -0.051349,
    "out:ROI% old": 14.183407,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 333.3,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1700.107686,
    "out:Return %": 13,
    "out:Return/kSEK/y": 911,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 28.275678,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38261.774258,
    "out:PV (% sold (El))": 55.200345,
    "out:PV (kWh self-consumed)": 31052.600464,
    "out:PV (ratio sold/self-consumed)": 1.23216,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 316136.681856,
    "out:EL kWh savings": -53002.210622,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267524.92,
    "out:DH kr savings": 316136.68185566267,
    "out:El kr savings": -106004.42124424121,
    "out:El kr sold": 57392.66138672782,
    "out:El kr saved": 62105.200928,
    "out:El kr return": 119497.86231472783,
    "out:% solar/total": 223,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 13752.227523,
    "out:% savings (space heating)": 95.173968,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.1,
    "out:Etvv": 0,
    "out:Ef": 25.46,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7170139.1930156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -70.316459,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.499976,
    "out:Bought CO2": 3.183517,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.765968,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.279854,
    "out:Electricity (inc PV)": 25.067106,
    "out:Total Energy Use Pre PV": 39.279854,
    "out:Total Energy Use Post PV": 29.067106,
    "out:Primary Energy": 49.292184,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 45.782181,
    "out:CO2 Operational/m2": -69.676983,
    "out:Total CO2/m2": -23.894802,
    "out:Total CO2 (tons)": -62.81942,
    "out:Klimatpaverkan": -182.958494,
    "out:Initial Cost/MSEK": 7.235864180515602,
    "out:Running cost/(Apt SEK y)": 2577.684211,
    "out:Running Cost over RSP/MSEK": 0.77445,
    "out:LCP/MSEK": -0.032224,
    "out:ROI% old": 14.222094,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 29,
    "out:Energy use kWh/m2": 29,
    "out:Energy savings %": 348.3,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1668.133226,
    "out:Return %": 13,
    "out:Return/kSEK/y": 922,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 27.680354,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38641.105675,
    "out:PV (% sold (El))": 55.747608,
    "out:PV (kWh self-consumed)": 30673.269046,
    "out:PV (ratio sold/self-consumed)": 1.259765,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319988.181755,
    "out:EL kWh savings": -51967.709873,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274014.42,
    "out:DH kr savings": 319988.1817550541,
    "out:El kr savings": -103935.41974607541,
    "out:El kr sold": 57961.65851275352,
    "out:El kr saved": 61346.538092,
    "out:El kr return": 119308.19660475352,
    "out:% solar/total": 220,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 9900.727624,
    "out:% savings (space heating)": 96.525564,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.36,
    "out:Etvv": 0,
    "out:Ef": 25.07,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7235864.180515602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -71.116919,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -74.240713,
    "out:Bought CO2": 3.123794,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.69791,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.259895,
    "out:Electricity (inc PV)": 24.80656,
    "out:Total Energy Use Pre PV": 40.259895,
    "out:Total Energy Use Post PV": 29.80656,
    "out:Primary Energy": 49.449473,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 46.497282,
    "out:CO2 Operational/m2": -69.343966,
    "out:Total CO2/m2": -22.846684,
    "out:Total CO2 (tons)": -60.063919,
    "out:Klimatpaverkan": -180.202992,
    "out:Initial Cost/MSEK": 7.397819193015602,
    "out:Running cost/(Apt SEK y)": 2784.026316,
    "out:Running Cost over RSP/MSEK": 0.83579,
    "out:LCP/MSEK": -0.255519,
    "out:ROI% old": 13.792289,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 333.3,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1667.062979,
    "out:Return %": 12,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.660395,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38653.864326,
    "out:PV (% sold (El))": 55.766015,
    "out:PV (kWh self-consumed)": 30660.510395,
    "out:PV (ratio sold/self-consumed)": 1.260705,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 317538.106793,
    "out:EL kWh savings": -51282.734595,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272953.43,
    "out:DH kr savings": 317538.1067934235,
    "out:El kr savings": -102565.46918916737,
    "out:El kr sold": 57980.79648947457,
    "out:El kr saved": 61321.02079,
    "out:El kr return": 119301.81727947458,
    "out:% solar/total": 220,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 12350.802585,
    "out:% savings (space heating)": 95.665766,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.17,
    "out:Etvv": 0,
    "out:Ef": 24.81,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7397819.1930156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -71.143886,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -74.265681,
    "out:Bought CO2": 3.121795,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.320795,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.637936,
    "out:Electricity (inc PV)": 24.385537,
    "out:Total Energy Use Pre PV": 37.637936,
    "out:Total Energy Use Post PV": 27.385537,
    "out:Primary Energy": 47.442585,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 46.497282,
    "out:CO2 Operational/m2": -70.907365,
    "out:Total CO2/m2": -24.410083,
    "out:Total CO2 (tons)": -64.174094,
    "out:Klimatpaverkan": -184.313167,
    "out:Initial Cost/MSEK": 7.463544180515602,
    "out:Running cost/(Apt SEK y)": 2285.315789,
    "out:Running Cost over RSP/MSEK": 0.68783,
    "out:LCP/MSEK": -0.173284,
    "out:ROI% old": 13.954037,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 27,
    "out:Energy use kWh/m2": 27,
    "out:Energy savings %": 381.5,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 1633.764579,
    "out:Return %": 13,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.038436,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 39052.355918,
    "out:PV (% sold (El))": 56.340919,
    "out:PV (kWh self-consumed)": 30262.018804,
    "out:PV (ratio sold/self-consumed)": 1.290474,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 321158.541306,
    "out:EL kWh savings": -50175.865379,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279385.34,
    "out:DH kr savings": 321158.5413062858,
    "out:El kr savings": -100351.7307578688,
    "out:El kr sold": 58578.5338763911,
    "out:El kr saved": 60524.037608,
    "out:El kr return": 119102.5714843911,
    "out:% solar/total": 217,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 8730.368072,
    "out:% savings (space heating)": 96.936276,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 4.56,
    "out:Etvv": 0,
    "out:Ef": 24.39,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7463544.180515602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -71.987317,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -75.046902,
    "out:Bought CO2": 3.059585,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.528074,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.052421,
    "out:Electricity (inc PV)": 36.561206,
    "out:Total Energy Use Pre PV": 40.052421,
    "out:Total Energy Use Post PV": 41.561206,
    "out:Primary Energy": 70.381234,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.642581,
    "out:CO2 Operational/m2": 6.42712,
    "out:Total CO2/m2": 23.069701,
    "out:Total CO2 (tons)": 60.65023,
    "out:Klimatpaverkan": -59.488843,
    "out:Initial Cost/MSEK": 5.9804666124556025,
    "out:Running cost/(Apt SEK y)": 5894.815789,
    "out:Running Cost over RSP/MSEK": 1.73534,
    "out:LCP/MSEK": 0.262283,
    "out:ROI% old": 14.912238,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 209.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2453.66947,
    "out:Return %": 13,
    "out:Return/kSEK/y": 796,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 27.452921,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 317984.605536,
    "out:EL kWh savings": -82185.691911,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153613.22,
    "out:DH kr savings": 317984.60553603136,
    "out:El kr savings": -164371.38382212006,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11904.303843,
    "out:% savings (space heating)": 95.822455,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.88,
    "out:Etvv": 0,
    "out:Ef": 36.56,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5980466.612455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 4.6272,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.6272,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.180498,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.421511,
    "out:Electricity (inc PV)": 35.995939,
    "out:Total Energy Use Pre PV": 37.421511,
    "out:Total Energy Use Post PV": 38.995939,
    "out:Primary Energy": 68.147157,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.642581,
    "out:CO2 Operational/m2": 5.623867,
    "out:Total CO2/m2": 22.266448,
    "out:Total CO2 (tons)": 58.538478,
    "out:Klimatpaverkan": -61.600595,
    "out:Initial Cost/MSEK": 6.046191599955604,
    "out:Running cost/(Apt SEK y)": 5389.657895,
    "out:Running Cost over RSP/MSEK": 1.58549,
    "out:LCP/MSEK": 0.346408,
    "out:ROI% old": 15.104195,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 233.3,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2409.50577,
    "out:Return %": 13,
    "out:Return/kSEK/y": 816,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 26.822011,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 321527.382036,
    "out:EL kWh savings": -80699.605306,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160128.17,
    "out:DH kr savings": 321527.38203552854,
    "out:El kr savings": -161399.21061104882,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 8361.527343,
    "out:% savings (space heating)": 97.065712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 4.31,
    "out:Etvv": 0,
    "out:Ef": 36,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6046191.5999556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": 4.543915,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.543915,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.137037,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.549462,
    "out:Electricity (inc PV)": 35.853758,
    "out:Total Energy Use Pre PV": 38.549462,
    "out:Total Energy Use Post PV": 39.853758,
    "out:Primary Energy": 68.590277,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.357682,
    "out:CO2 Operational/m2": 6.000741,
    "out:Total CO2/m2": 23.358423,
    "out:Total CO2 (tons)": 61.40928,
    "out:Klimatpaverkan": -58.729793,
    "out:Initial Cost/MSEK": 6.208146612455603,
    "out:Running cost/(Apt SEK y)": 5616.289474,
    "out:Running Cost over RSP/MSEK": 1.65279,
    "out:LCP/MSEK": 0.117153,
    "out:ROI% old": 14.555299,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 225,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2418.46234,
    "out:Return %": 13,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 26.949962,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319012.641576,
    "out:EL kWh savings": -80325.811541,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158361.02,
    "out:DH kr savings": 319012.64157558227,
    "out:El kr savings": -160651.62308281296,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 10876.267803,
    "out:% savings (space heating)": 96.183221,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.21,
    "out:Etvv": 0,
    "out:Ef": 35.85,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6208146.612455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 4.560805,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.560805,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.860302,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 33.897086,
    "out:Electricity (inc PV)": 35.257508,
    "out:Total Energy Use Pre PV": 36.897086,
    "out:Total Energy Use Post PV": 38.257508,
    "out:Primary Energy": 66.379954,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.357682,
    "out:CO2 Operational/m2": 5.554639,
    "out:Total CO2/m2": 22.912321,
    "out:Total CO2 (tons)": 60.236478,
    "out:Klimatpaverkan": -59.902595,
    "out:Initial Cost/MSEK": 6.273871599955603,
    "out:Running cost/(Apt SEK y)": 5317.105263,
    "out:Running Cost over RSP/MSEK": 1.56417,
    "out:LCP/MSEK": 0.140048,
    "out:ROI% old": 14.604607,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 242.1,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2372.79602,
    "out:Return %": 13,
    "out:Return/kSEK/y": 818,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 26.297586,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 322369.177128,
    "out:EL kWh savings": -78758.270647,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164852.64,
    "out:DH kr savings": 322369.1771283715,
    "out:El kr savings": -157516.54129473545,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 7519.73225,
    "out:% savings (space heating)": 97.361121,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 3.75,
    "out:Etvv": 0,
    "out:Ef": 35.26,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6273871.5999556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": 4.474687,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.474687,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.528074,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.052421,
    "out:Electricity (inc PV)": 27.126449,
    "out:Total Energy Use Pre PV": 40.052421,
    "out:Total Energy Use Post PV": 32.126449,
    "out:Primary Energy": 53.398671,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 31.212381,
    "out:CO2 Operational/m2": -14.62172,
    "out:Total CO2/m2": 16.590661,
    "out:Total CO2 (tons)": 43.616838,
    "out:Klimatpaverkan": -76.522236,
    "out:Initial Cost/MSEK": 6.625449848966852,
    "out:Running cost/(Apt SEK y)": 4208.473684,
    "out:Running Cost over RSP/MSEK": 1.24747,
    "out:LCP/MSEK": 0.10517,
    "out:ROI% old": 14.512481,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 306.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1827.426252,
    "out:Return %": 13,
    "out:Return/kSEK/y": 860,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 27.452921,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10465.374361,
    "out:PV (% sold (El))": 30.196837,
    "out:PV (kWh self-consumed)": 24191.813,
    "out:PV (ratio sold/self-consumed)": 0.4326,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 317984.605536,
    "out:EL kWh savings": -57381.721391,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218919.22,
    "out:DH kr savings": 317984.60553603136,
    "out:El kr savings": -114763.44278121988,
    "out:El kr sold": 15698.061541377469,
    "out:El kr saved": 48383.626,
    "out:El kr return": 64081.68754137747,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11904.303843,
    "out:% savings (space heating)": 95.822455,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.88,
    "out:Etvv": 0,
    "out:Ef": 27.13,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6625449.848966852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -16.42164,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.842107,
    "out:Bought CO2": 3.420467,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.180498,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.421511,
    "out:Electricity (inc PV)": 26.647426,
    "out:Total Energy Use Pre PV": 37.421511,
    "out:Total Energy Use Post PV": 29.647426,
    "out:Primary Energy": 51.319834,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 31.212381,
    "out:CO2 Operational/m2": -15.923315,
    "out:Total CO2/m2": 15.289066,
    "out:Total CO2 (tons)": 40.194945,
    "out:Klimatpaverkan": -79.944128,
    "out:Initial Cost/MSEK": 6.691174836466852,
    "out:Running cost/(Apt SEK y)": 3706.763158,
    "out:Running Cost over RSP/MSEK": 1.09863,
    "out:LCP/MSEK": 0.188285,
    "out:ROI% old": 14.687705,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 333.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 1789.833163,
    "out:Return %": 13,
    "out:Return/kSEK/y": 879,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 26.822011,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10728.873483,
    "out:PV (% sold (El))": 30.957138,
    "out:PV (kWh self-consumed)": 23928.313878,
    "out:PV (ratio sold/self-consumed)": 0.448376,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 321527.382036,
    "out:EL kWh savings": -56122.37021,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225375.95,
    "out:DH kr savings": 321527.38203552854,
    "out:El kr savings": -112244.74041917337,
    "out:El kr sold": 16093.310224668803,
    "out:El kr saved": 47856.627756,
    "out:El kr return": 63949.93798066881,
    "out:% solar/total": 172,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 8361.527343,
    "out:% savings (space heating)": 97.065712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 4.31,
    "out:Etvv": 0,
    "out:Ef": 26.65,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6691174.836466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -17.003267,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -20.353473,
    "out:Bought CO2": 3.350206,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.137037,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.549462,
    "out:Electricity (inc PV)": 26.527177,
    "out:Total Energy Use Pre PV": 38.549462,
    "out:Total Energy Use Post PV": 30.527177,
    "out:Primary Energy": 51.802432,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 31.927482,
    "out:CO2 Operational/m2": -15.444732,
    "out:Total CO2/m2": 16.48275,
    "out:Total CO2 (tons)": 43.33314,
    "out:Klimatpaverkan": -76.805934,
    "out:Initial Cost/MSEK": 6.8531298489668515,
    "out:Running cost/(Apt SEK y)": 3932.710526,
    "out:Running Cost over RSP/MSEK": 1.16572,
    "out:LCP/MSEK": -0.04076,
    "out:ROI% old": 14.200748,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 319.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1797.447253,
    "out:Return %": 13,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 26.949962,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10675.205461,
    "out:PV (% sold (El))": 30.802285,
    "out:PV (kWh self-consumed)": 23981.9819,
    "out:PV (ratio sold/self-consumed)": 0.445134,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319012.641576,
    "out:EL kWh savings": -55806.23566,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223412.98,
    "out:DH kr savings": 319012.64157558227,
    "out:El kr savings": -111612.47132075066,
    "out:El kr sold": 16012.80819139021,
    "out:El kr saved": 47963.9638,
    "out:El kr return": 63976.77199139021,
    "out:% solar/total": 172,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 10876.267803,
    "out:% savings (space heating)": 96.183221,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.21,
    "out:Etvv": 0,
    "out:Ef": 26.53,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6853129.848966852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -16.884668,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -20.249106,
    "out:Bought CO2": 3.364438,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.860302,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 33.897086,
    "out:Electricity (inc PV)": 26.023443,
    "out:Total Energy Use Pre PV": 36.897086,
    "out:Total Energy Use Post PV": 29.023443,
    "out:Primary Energy": 49.758637,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 31.927482,
    "out:CO2 Operational/m2": -16.412848,
    "out:Total CO2/m2": 15.514634,
    "out:Total CO2 (tons)": 40.787964,
    "out:Klimatpaverkan": -79.35111,
    "out:Initial Cost/MSEK": 6.918854836466853,
    "out:Running cost/(Apt SEK y)": 3637.105263,
    "out:Running Cost over RSP/MSEK": 1.07815,
    "out:LCP/MSEK": -0.018915,
    "out:ROI% old": 14.24666,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 29,
    "out:Energy use kWh/m2": 29,
    "out:Energy savings %": 348.3,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 1758.678261,
    "out:Return %": 13,
    "out:Return/kSEK/y": 882,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 26.297586,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10950.391485,
    "out:PV (% sold (El))": 31.596307,
    "out:PV (kWh self-consumed)": 23706.795876,
    "out:PV (ratio sold/self-consumed)": 0.461909,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 322369.177128,
    "out:EL kWh savings": -54481.919275,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229830.93,
    "out:DH kr savings": 322369.1771283715,
    "out:El kr savings": -108963.83855020905,
    "out:El kr sold": 16425.587227164746,
    "out:El kr saved": 47413.591752,
    "out:El kr return": 63839.178979164746,
    "out:% solar/total": 170,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 7519.73225,
    "out:% savings (space heating)": 97.361121,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 3.75,
    "out:Etvv": 0,
    "out:Ef": 26.02,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6918854.836466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -17.4928,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -20.784762,
    "out:Bought CO2": 3.291962,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.528074,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.052421,
    "out:Electricity (inc PV)": 24.585023,
    "out:Total Energy Use Pre PV": 40.052421,
    "out:Total Energy Use Post PV": 29.585023,
    "out:Primary Energy": 48.824104,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 45.782181,
    "out:CO2 Operational/m2": -69.624668,
    "out:Total CO2/m2": -23.842487,
    "out:Total CO2 (tons)": -62.681884,
    "out:Klimatpaverkan": -182.820958,
    "out:Initial Cost/MSEK": 7.270433068015602,
    "out:Running cost/(Apt SEK y)": 2757.052632,
    "out:Running Cost over RSP/MSEK": 0.82786,
    "out:LCP/MSEK": -0.120203,
    "out:ROI% old": 14.049527,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 333.3,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1655.943952,
    "out:Return %": 13,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 27.452921,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38786.612929,
    "out:PV (% sold (El))": 55.957531,
    "out:PV (kWh self-consumed)": 30527.761793,
    "out:PV (ratio sold/self-consumed)": 1.270536,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 317984.605536,
    "out:EL kWh savings": -50700.313954,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274763.9,
    "out:DH kr savings": 317984.60553603136,
    "out:El kr savings": -101400.62790768253,
    "out:El kr sold": 58179.91939297296,
    "out:El kr saved": 61055.523586,
    "out:El kr return": 119235.44297897296,
    "out:% solar/total": 219,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11904.303843,
    "out:% savings (space heating)": 95.822455,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.88,
    "out:Etvv": 0,
    "out:Ef": 24.59,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7270433.0680156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -71.424588,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -74.525614,
    "out:Bought CO2": 3.101026,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.180498,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.421511,
    "out:Electricity (inc PV)": 24.155218,
    "out:Total Energy Use Pre PV": 37.421511,
    "out:Total Energy Use Post PV": 27.155218,
    "out:Primary Energy": 46.833859,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 45.782181,
    "out:CO2 Operational/m2": -71.202122,
    "out:Total CO2/m2": -25.419941,
    "out:Total CO2 (tons)": -66.82901,
    "out:Klimatpaverkan": -186.968083,
    "out:Initial Cost/MSEK": 7.336158055515601,
    "out:Running cost/(Apt SEK y)": 2257.210526,
    "out:Running Cost over RSP/MSEK": 0.67957,
    "out:LCP/MSEK": -0.037638,
    "out:ROI% old": 14.212422,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 27,
    "out:Energy use kWh/m2": 27,
    "out:Energy savings %": 381.5,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 1622.201152,
    "out:Return %": 13,
    "out:Return/kSEK/y": 935,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 26.822011,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 39191.745114,
    "out:PV (% sold (El))": 56.542016,
    "out:PV (kWh self-consumed)": 30122.629607,
    "out:PV (ratio sold/self-consumed)": 1.301073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 321527.382036,
    "out:EL kWh savings": -49570.356865,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281174.29,
    "out:DH kr savings": 321527.38203552854,
    "out:El kr savings": -99140.71373086968,
    "out:El kr sold": 58787.61767117362,
    "out:El kr saved": 60245.259214,
    "out:El kr return": 119032.87688517361,
    "out:% solar/total": 216,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 8361.527343,
    "out:% savings (space heating)": 97.065712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 4.31,
    "out:Etvv": 0,
    "out:Ef": 24.16,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7336158.055515601,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -72.282074,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -75.320045,
    "out:Bought CO2": 3.037971,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.137037,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.549462,
    "out:Electricity (inc PV)": 24.047368,
    "out:Total Energy Use Pre PV": 38.549462,
    "out:Total Energy Use Post PV": 28.047368,
    "out:Primary Energy": 47.338775,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 46.497282,
    "out:CO2 Operational/m2": -70.667803,
    "out:Total CO2/m2": -24.170521,
    "out:Total CO2 (tons)": -63.544285,
    "out:Klimatpaverkan": -183.683359,
    "out:Initial Cost/MSEK": 7.498113068015601,
    "out:Running cost/(Apt SEK y)": 2482.763158,
    "out:Running Cost over RSP/MSEK": 0.74656,
    "out:LCP/MSEK": -0.266583,
    "out:ROI% old": 13.777809,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 28,
    "out:Energy use kWh/m2": 28,
    "out:Energy savings %": 364.3,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1629.036036,
    "out:Return %": 12,
    "out:Return/kSEK/y": 926,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 26.949962,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 39109.278925,
    "out:PV (% sold (El))": 56.423042,
    "out:PV (kWh self-consumed)": 30205.095796,
    "out:PV (ratio sold/self-consumed)": 1.294791,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319012.641576,
    "out:EL kWh savings": -49286.81928,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279102.92,
    "out:DH kr savings": 319012.64157558227,
    "out:El kr savings": -98573.6385596426,
    "out:El kr sold": 58663.918388057515,
    "out:El kr saved": 60410.191592,
    "out:El kr return": 119074.10998005752,
    "out:% solar/total": 217,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 10876.267803,
    "out:% savings (space heating)": 96.183221,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.21,
    "out:Etvv": 0,
    "out:Ef": 24.05,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7498113.0680156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -72.107739,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -75.158487,
    "out:Bought CO2": 3.050748,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.860302,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 33.897086,
    "out:Electricity (inc PV)": 23.595663,
    "out:Total Energy Use Pre PV": 36.897086,
    "out:Total Energy Use Post PV": 26.595663,
    "out:Primary Energy": 45.388633,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 46.497282,
    "out:CO2 Operational/m2": -71.919239,
    "out:Total CO2/m2": -25.421957,
    "out:Total CO2 (tons)": -66.83431,
    "out:Klimatpaverkan": -186.973383,
    "out:Initial Cost/MSEK": 7.5638380555156015,
    "out:Running cost/(Apt SEK y)": 2189.131579,
    "out:Running Cost over RSP/MSEK": 0.65956,
    "out:LCP/MSEK": -0.245308,
    "out:ROI% old": 13.822404,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 27,
    "out:Energy use kWh/m2": 27,
    "out:Energy savings %": 381.5,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 1594.232719,
    "out:Return %": 12,
    "out:Return/kSEK/y": 937,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 26.297586,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 39531.553616,
    "out:PV (% sold (El))": 57.032259,
    "out:PV (kWh self-consumed)": 29782.821105,
    "out:PV (ratio sold/self-consumed)": 1.327327,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 322369.177128,
    "out:EL kWh savings": -48099.287104,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285467.93,
    "out:DH kr savings": 322369.1771283715,
    "out:El kr savings": -96198.57420897837,
    "out:El kr sold": 59297.33042427983,
    "out:El kr saved": 59565.64221,
    "out:El kr return": 118862.97263427984,
    "out:% solar/total": 214,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 7519.73225,
    "out:% savings (space heating)": 97.361121,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 3.75,
    "out:Etvv": 0,
    "out:Ef": 23.6,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7563838.055515601,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -72.999191,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -75.984863,
    "out:Bought CO2": 2.985672,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 93.741596,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.582572,
    "out:CO2 Operational/m2": 37.777994,
    "out:Total CO2/m2": 51.360566,
    "out:Total CO2 (tons)": 135.026897,
    "out:Klimatpaverkan": 14.887824,
    "out:Initial Cost/MSEK": 2.577,
    "out:Running cost/(Apt SEK y)": 22253.789474,
    "out:Running Cost over RSP/MSEK": 6.61186,
    "out:LCP/MSEK": -1.21077,
    "out:ROI% old": 7.573757,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 7,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 59498.437388,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 59498.44,
    "out:DH kr savings": 59498.437387640544,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2577000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 104.3,
    "out:Primary Energy": 90.921827,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.582572,
    "out:CO2 Operational/m2": 36.338058,
    "out:Total CO2/m2": 49.92063,
    "out:Total CO2 (tons)": 131.241306,
    "out:Klimatpaverkan": 11.102233,
    "out:Initial Cost/MSEK": 2.6427249875000003,
    "out:Running cost/(Apt SEK y)": 21418.052632,
    "out:Running Cost over RSP/MSEK": 6.36346,
    "out:LCP/MSEK": -1.028095,
    "out:ROI% old": 8.728166,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 69029.659014,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69029.66,
    "out:DH kr savings": 69029.65901381607,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2642724.9875000003,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 91.116596,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.297673,
    "out:CO2 Operational/m2": 36.698042,
    "out:Total CO2/m2": 50.995715,
    "out:Total CO2 (tons)": 134.067704,
    "out:Klimatpaverkan": 13.928631,
    "out:Initial Cost/MSEK": 2.80468,
    "out:Running cost/(Apt SEK y)": 21626.973684,
    "out:Running Cost over RSP/MSEK": 6.42556,
    "out:LCP/MSEK": -1.25215,
    "out:ROI% old": 7.907854,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 7,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 66237.877357,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66237.88,
    "out:DH kr savings": 66237.87735723106,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2804680,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 102.3,
    "out:Primary Energy": 88.296827,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.297673,
    "out:CO2 Operational/m2": 35.61809,
    "out:Total CO2/m2": 49.915763,
    "out:Total CO2 (tons)": 131.228511,
    "out:Klimatpaverkan": 11.089438,
    "out:Initial Cost/MSEK": 2.8704049875,
    "out:Running cost/(Apt SEK y)": 21000.157895,
    "out:Running Cost over RSP/MSEK": 6.23925,
    "out:LCP/MSEK": -1.131565,
    "out:ROI% old": 8.65403,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 222,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75769.098983,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75769.1,
    "out:DH kr savings": 75769.09898340658,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2870404.9875000003,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 106.138626,
    "out:Primary Energy": 89.851123,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.152372,
    "out:CO2 Operational/m2": -18.552053,
    "out:Total CO2/m2": 9.600319,
    "out:Total CO2 (tons)": 25.239233,
    "out:Klimatpaverkan": -94.899841,
    "out:Initial Cost/MSEK": 3.22198323651125,
    "out:Running cost/(Apt SEK y)": 20810.973684,
    "out:Running Cost over RSP/MSEK": 6.19493,
    "out:LCP/MSEK": -1.438823,
    "out:ROI% old": 7.906222,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 7,
    "out:Return/kSEK/y": 229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 59498.437388,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114325.34,
    "out:DH kr savings": 59498.437387640544,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3221983.23651125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 102.138626,
    "out:Primary Energy": 87.031354,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.152372,
    "out:CO2 Operational/m2": -19.991989,
    "out:Total CO2/m2": 8.160383,
    "out:Total CO2 (tons)": 21.453642,
    "out:Klimatpaverkan": -98.685432,
    "out:Initial Cost/MSEK": 3.2877082240112503,
    "out:Running cost/(Apt SEK y)": 19975.236842,
    "out:Running Cost over RSP/MSEK": 5.94653,
    "out:LCP/MSEK": -1.256148,
    "out:ROI% old": 8.827513,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69029.659014,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123856.57,
    "out:DH kr savings": 69029.65901381607,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3287708.2240112503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 103.138626,
    "out:Primary Energy": 87.226123,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.867473,
    "out:CO2 Operational/m2": -19.632005,
    "out:Total CO2/m2": 9.235468,
    "out:Total CO2 (tons)": 24.28004,
    "out:Klimatpaverkan": -95.859034,
    "out:Initial Cost/MSEK": 3.44966323651125,
    "out:Running cost/(Apt SEK y)": 20184.157895,
    "out:Running Cost over RSP/MSEK": 6.00863,
    "out:LCP/MSEK": -1.480203,
    "out:ROI% old": 8.15591,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 7,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66237.877357,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121064.78,
    "out:DH kr savings": 66237.87735723106,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3449663.23651125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 100.138626,
    "out:Primary Energy": 84.406354,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.867473,
    "out:CO2 Operational/m2": -20.711957,
    "out:Total CO2/m2": 8.155516,
    "out:Total CO2 (tons)": 21.440847,
    "out:Klimatpaverkan": -98.698227,
    "out:Initial Cost/MSEK": 3.51538822401125,
    "out:Running cost/(Apt SEK y)": 19557.368421,
    "out:Running Cost over RSP/MSEK": 5.82232,
    "out:LCP/MSEK": -1.359618,
    "out:ROI% old": 8.760544,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 30,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75769.098983,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130596.01,
    "out:DH kr savings": 75769.09898340658,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3515388.2240112503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 106.026217,
    "out:Primary Energy": 89.648787,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.722172,
    "out:CO2 Operational/m2": -85.207257,
    "out:Total CO2/m2": -42.485085,
    "out:Total CO2 (tons)": -111.693263,
    "out:Klimatpaverkan": -231.832337,
    "out:Initial Cost/MSEK": 3.86696645556,
    "out:Running cost/(Apt SEK y)": 19439.052632,
    "out:Running Cost over RSP/MSEK": 5.79876,
    "out:LCP/MSEK": -1.687636,
    "out:ROI% old": 8.051088,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 7,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 59498.437388,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166458.89,
    "out:DH kr savings": 59498.437387640544,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3866966.45556,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 102.026217,
    "out:Primary Energy": 86.829018,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.722172,
    "out:CO2 Operational/m2": -86.647193,
    "out:Total CO2/m2": -43.925021,
    "out:Total CO2 (tons)": -115.478854,
    "out:Klimatpaverkan": -235.617928,
    "out:Initial Cost/MSEK": 3.9326914430600004,
    "out:Running cost/(Apt SEK y)": 18603.289474,
    "out:Running Cost over RSP/MSEK": 5.55036,
    "out:LCP/MSEK": -1.504961,
    "out:ROI% old": 8.81886,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69029.659014,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175990.11,
    "out:DH kr savings": 69029.65901381607,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3932691.4430600004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 103.026217,
    "out:Primary Energy": 87.023787,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.437273,
    "out:CO2 Operational/m2": -86.287209,
    "out:Total CO2/m2": -42.849936,
    "out:Total CO2 (tons)": -112.652456,
    "out:Klimatpaverkan": -232.79153,
    "out:Initial Cost/MSEK": 4.09464645556,
    "out:Running cost/(Apt SEK y)": 18812.236842,
    "out:Running Cost over RSP/MSEK": 5.61246,
    "out:LCP/MSEK": -1.729016,
    "out:ROI% old": 8.25339,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 7,
    "out:Return/kSEK/y": 305,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66237.877357,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173198.33,
    "out:DH kr savings": 66237.87735723106,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4094646.4555600006,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 100.026217,
    "out:Primary Energy": 84.204018,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.437273,
    "out:CO2 Operational/m2": -87.367161,
    "out:Total CO2/m2": -43.929888,
    "out:Total CO2 (tons)": -115.491649,
    "out:Klimatpaverkan": -235.630723,
    "out:Initial Cost/MSEK": 4.160371443060001,
    "out:Running cost/(Apt SEK y)": 18185.421053,
    "out:Running Cost over RSP/MSEK": 5.42615,
    "out:LCP/MSEK": -1.608431,
    "out:ROI% old": 8.762748,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 30,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75769.098983,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182729.55,
    "out:DH kr savings": 75769.09898340658,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4160371.443060001,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 104.3,
    "out:Primary Energy": 90.241596,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.582572,
    "out:CO2 Operational/m2": 36.338058,
    "out:Total CO2/m2": 49.92063,
    "out:Total CO2 (tons)": 131.241306,
    "out:Klimatpaverkan": 11.102233,
    "out:Initial Cost/MSEK": 2.677293875,
    "out:Running cost/(Apt SEK y)": 21418.052632,
    "out:Running Cost over RSP/MSEK": 6.36346,
    "out:LCP/MSEK": -1.062664,
    "out:ROI% old": 8.615469,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 68484.357347,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68484.36,
    "out:DH kr savings": 68484.35734709456,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2677293.875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 101.3,
    "out:Total Energy Use Post PV": 101.3,
    "out:Primary Energy": 87.421827,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.582572,
    "out:CO2 Operational/m2": 35.258106,
    "out:Total CO2/m2": 48.840678,
    "out:Total CO2 (tons)": 128.402113,
    "out:Klimatpaverkan": 8.26304,
    "out:Initial Cost/MSEK": 2.7430188625000005,
    "out:Running cost/(Apt SEK y)": 20791.236842,
    "out:Running Cost over RSP/MSEK": 6.17715,
    "out:LCP/MSEK": -0.942079,
    "out:ROI% old": 9.379343,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 28.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 230,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 78015.578973,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78015.58,
    "out:DH kr savings": 78015.57897327008,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2743018.8625000003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 102.3,
    "out:Primary Energy": 88.141596,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.297673,
    "out:CO2 Operational/m2": 35.61809,
    "out:Total CO2/m2": 49.915763,
    "out:Total CO2 (tons)": 131.228511,
    "out:Klimatpaverkan": 11.089438,
    "out:Initial Cost/MSEK": 2.904973875,
    "out:Running cost/(Apt SEK y)": 21000.157895,
    "out:Running Cost over RSP/MSEK": 6.23925,
    "out:LCP/MSEK": -1.166134,
    "out:ROI% old": 8.551048,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 222,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 73875.909323,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73875.91,
    "out:DH kr savings": 73875.90932276695,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2904973.875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 99.3,
    "out:Total Energy Use Post PV": 99.3,
    "out:Primary Energy": 85.321827,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.297673,
    "out:CO2 Operational/m2": 34.538138,
    "out:Total CO2/m2": 48.835811,
    "out:Total CO2 (tons)": 128.389318,
    "out:Klimatpaverkan": 8.250244,
    "out:Initial Cost/MSEK": 2.9706988625000004,
    "out:Running cost/(Apt SEK y)": 20373.368421,
    "out:Running Cost over RSP/MSEK": 6.05295,
    "out:LCP/MSEK": -1.045559,
    "out:ROI% old": 9.257754,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 31.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 8,
    "out:Return/kSEK/y": 246,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 83407.130949,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83407.13,
    "out:DH kr savings": 83407.13094894247,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2970698.8625000003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 102.138626,
    "out:Primary Energy": 86.351123,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.152372,
    "out:CO2 Operational/m2": -19.991989,
    "out:Total CO2/m2": 8.160383,
    "out:Total CO2 (tons)": 21.453642,
    "out:Klimatpaverkan": -98.685432,
    "out:Initial Cost/MSEK": 3.32227711151125,
    "out:Running cost/(Apt SEK y)": 19975.236842,
    "out:Running Cost over RSP/MSEK": 5.94653,
    "out:LCP/MSEK": -1.290717,
    "out:ROI% old": 8.735661,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68484.357347,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123311.26,
    "out:DH kr savings": 68484.35734709456,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3322277.11151125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 101.3,
    "out:Total Energy Use Post PV": 99.138626,
    "out:Primary Energy": 83.531354,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.152372,
    "out:CO2 Operational/m2": -21.071941,
    "out:Total CO2/m2": 7.080431,
    "out:Total CO2 (tons)": 18.614449,
    "out:Klimatpaverkan": -101.524625,
    "out:Initial Cost/MSEK": 3.388002099011251,
    "out:Running cost/(Apt SEK y)": 19348.421053,
    "out:Running Cost over RSP/MSEK": 5.76022,
    "out:LCP/MSEK": -1.170132,
    "out:ROI% old": 9.351782,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 31.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 78015.578973,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132842.49,
    "out:DH kr savings": 78015.57897327008,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3388002.099011251,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 100.138626,
    "out:Primary Energy": 84.251123,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.867473,
    "out:CO2 Operational/m2": -20.711957,
    "out:Total CO2/m2": 8.155516,
    "out:Total CO2 (tons)": 21.440847,
    "out:Klimatpaverkan": -98.698227,
    "out:Initial Cost/MSEK": 3.54995711151125,
    "out:Running cost/(Apt SEK y)": 19557.368421,
    "out:Running Cost over RSP/MSEK": 5.82232,
    "out:LCP/MSEK": -1.394187,
    "out:ROI% old": 8.675236,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 30,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 73875.909323,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128702.82,
    "out:DH kr savings": 73875.90932276695,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3549957.11151125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 99.3,
    "out:Total Energy Use Post PV": 97.138626,
    "out:Primary Energy": 81.431354,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.867473,
    "out:CO2 Operational/m2": -21.791909,
    "out:Total CO2/m2": 7.075564,
    "out:Total CO2 (tons)": 18.601654,
    "out:Klimatpaverkan": -101.53742,
    "out:Initial Cost/MSEK": 3.615682099011251,
    "out:Running cost/(Apt SEK y)": 18930.552632,
    "out:Running Cost over RSP/MSEK": 5.63602,
    "out:LCP/MSEK": -1.273612,
    "out:ROI% old": 9.253619,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 34,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 8,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83407.130949,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138234.04,
    "out:DH kr savings": 83407.13094894247,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3615682.099011251,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 102.026217,
    "out:Primary Energy": 86.148787,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.722172,
    "out:CO2 Operational/m2": -86.647193,
    "out:Total CO2/m2": -43.925021,
    "out:Total CO2 (tons)": -115.478854,
    "out:Klimatpaverkan": -235.617928,
    "out:Initial Cost/MSEK": 3.9672603305600003,
    "out:Running cost/(Apt SEK y)": 18603.289474,
    "out:Running Cost over RSP/MSEK": 5.55036,
    "out:LCP/MSEK": -1.53953,
    "out:ROI% old": 8.742017,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68484.357347,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175444.81,
    "out:DH kr savings": 68484.35734709456,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3967260.33056,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 101.3,
    "out:Total Energy Use Post PV": 99.026217,
    "out:Primary Energy": 83.329018,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.722172,
    "out:CO2 Operational/m2": -87.727145,
    "out:Total CO2/m2": -45.004973,
    "out:Total CO2 (tons)": -118.318047,
    "out:Klimatpaverkan": -238.457121,
    "out:Initial Cost/MSEK": 4.032985318060001,
    "out:Running cost/(Apt SEK y)": 17976.473684,
    "out:Running Cost over RSP/MSEK": 5.36405,
    "out:LCP/MSEK": -1.418945,
    "out:ROI% old": 9.2595,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 31.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 78015.578973,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184976.03,
    "out:DH kr savings": 78015.57897327008,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4032985.3180600004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.759195,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 100.026217,
    "out:Primary Energy": 84.048787,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.437273,
    "out:CO2 Operational/m2": -87.367161,
    "out:Total CO2/m2": -43.929888,
    "out:Total CO2 (tons)": -115.491649,
    "out:Klimatpaverkan": -235.630723,
    "out:Initial Cost/MSEK": 4.194940330560001,
    "out:Running cost/(Apt SEK y)": 18185.421053,
    "out:Running Cost over RSP/MSEK": 5.42615,
    "out:LCP/MSEK": -1.643,
    "out:ROI% old": 8.690537,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 30,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 73875.909323,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180836.36,
    "out:DH kr savings": 73875.90932276695,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 225460.872194,
    "out:% savings (space heating)": 20.879626,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.76,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4194940.330560001,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.133777,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 99.3,
    "out:Total Energy Use Post PV": 97.026217,
    "out:Primary Energy": 81.229018,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.437273,
    "out:CO2 Operational/m2": -88.447113,
    "out:Total CO2/m2": -45.00984,
    "out:Total CO2 (tons)": -118.330842,
    "out:Klimatpaverkan": -238.469916,
    "out:Initial Cost/MSEK": 4.26066531806,
    "out:Running cost/(Apt SEK y)": 17558.605263,
    "out:Running Cost over RSP/MSEK": 5.23985,
    "out:LCP/MSEK": -1.522425,
    "out:ROI% old": 9.181128,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 34,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 8,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83407.130949,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190367.58,
    "out:DH kr savings": 83407.13094894247,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 215929.650568,
    "out:% savings (space heating)": 24.224392,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4260665.31806,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.807106,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.400133,
    "out:Electricity (inc PV)": 27.400838,
    "out:Total Energy Use Pre PV": 67.400133,
    "out:Total Energy Use Post PV": 68.400838,
    "out:Primary Energy": 84.644115,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.315661,
    "out:CO2 Operational/m2": 18.244373,
    "out:Total CO2/m2": 35.560034,
    "out:Total CO2 (tons)": 93.487308,
    "out:Klimatpaverkan": -26.651765,
    "out:Initial Cost/MSEK": 5.088359684370602,
    "out:Running cost/(Apt SEK y)": 12219.342105,
    "out:Running Cost over RSP/MSEK": 3.61928,
    "out:LCP/MSEK": -0.72955,
    "out:ROI% old": 12.237483,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 91.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1848.00931,
    "out:Return %": 11,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.800633,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 222607.052067,
    "out:EL kWh savings": -58103.089908,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106400.87,
    "out:DH kr savings": 222607.0520666135,
    "out:El kr savings": -116206.17981559937,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107281.857312,
    "out:% savings (space heating)": 62.351868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.41,
    "out:Etvv": 0,
    "out:Ef": 27.4,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5088359.684370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": 3.485029,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.485029,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.499063,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.303959,
    "out:Electricity (inc PV)": 27.327096,
    "out:Total Energy Use Pre PV": 63.303959,
    "out:Total Energy Use Post PV": 64.327096,
    "out:Primary Energy": 81.880882,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.315661,
    "out:CO2 Operational/m2": 16.791741,
    "out:Total CO2/m2": 34.107402,
    "out:Total CO2 (tons)": 89.668339,
    "out:Klimatpaverkan": -30.470734,
    "out:Initial Cost/MSEK": 5.154084671870602,
    "out:Running cost/(Apt SEK y)": 11370.289474,
    "out:Running Cost over RSP/MSEK": 3.36696,
    "out:LCP/MSEK": -0.542955,
    "out:ROI% old": 12.780792,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 103.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1841.27713,
    "out:Return %": 11,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.704459,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 231303.895139,
    "out:EL kWh savings": -57909.222234,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115485.45,
    "out:DH kr savings": 231303.89513871184,
    "out:El kr savings": -115818.4444676474,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98585.01424,
    "out:% savings (space heating)": 65.403828,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.03,
    "out:Etvv": 0,
    "out:Ef": 27.33,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5154084.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": 3.472333,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.472333,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.36546,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.060177,
    "out:Electricity (inc PV)": 26.934703,
    "out:Total Energy Use Pre PV": 65.060177,
    "out:Total Energy Use Post PV": 65.934703,
    "out:Primary Energy": 82.084818,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.030762,
    "out:CO2 Operational/m2": 17.479528,
    "out:Total CO2/m2": 35.51029,
    "out:Total CO2 (tons)": 93.356531,
    "out:Klimatpaverkan": -26.782542,
    "out:Initial Cost/MSEK": 5.316039684370603,
    "out:Running cost/(Apt SEK y)": 11754.421053,
    "out:Running Cost over RSP/MSEK": 3.48126,
    "out:LCP/MSEK": -0.81921,
    "out:ROI% old": 12.084264,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 97,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1824.21239,
    "out:Return %": 11,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.460677,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 226397.13854,
    "out:EL kWh savings": -56877.621271,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112641.9,
    "out:DH kr savings": 226397.13853995083,
    "out:El kr savings": -113755.2425421646,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 103491.770839,
    "out:% savings (space heating)": 63.681913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 43.2,
    "out:Etvv": 0,
    "out:Ef": 26.93,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5316039.6843706025,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": 3.440152,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.440152,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.083084,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.956225,
    "out:Electricity (inc PV)": 26.851794,
    "out:Total Energy Use Pre PV": 61.956225,
    "out:Total Energy Use Post PV": 62.851794,
    "out:Primary Energy": 79.328612,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.030762,
    "out:CO2 Operational/m2": 16.385853,
    "out:Total CO2/m2": 34.416615,
    "out:Total CO2 (tons)": 90.48126,
    "out:Klimatpaverkan": -29.657813,
    "out:Initial Cost/MSEK": 5.381764671870602,
    "out:Running cost/(Apt SEK y)": 11113.236842,
    "out:Running Cost over RSP/MSEK": 3.29073,
    "out:LCP/MSEK": -0.694405,
    "out:ROI% old": 12.44244,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1816.93575,
    "out:Return %": 11,
    "out:Return/kSEK/y": 598,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.356725,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 235026.503084,
    "out:EL kWh savings": -56659.65356,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121707.2,
    "out:DH kr savings": 235026.50308437238,
    "out:El kr savings": -113319.30711915794,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 94862.406294,
    "out:% savings (space heating)": 66.710192,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.85,
    "out:Etvv": 0,
    "out:Ef": 26.85,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5381764.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": 3.426429,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.426429,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.807106,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.400133,
    "out:Electricity (inc PV)": 19.503947,
    "out:Total Energy Use Pre PV": 67.400133,
    "out:Total Energy Use Post PV": 60.503947,
    "out:Primary Energy": 70.429712,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.885461,
    "out:CO2 Operational/m2": -10.26626,
    "out:Total CO2/m2": 21.619201,
    "out:Total CO2 (tons)": 56.836867,
    "out:Klimatpaverkan": -63.302207,
    "out:Initial Cost/MSEK": 5.733342920881853,
    "out:Running cost/(Apt SEK y)": 10584.631579,
    "out:Running Cost over RSP/MSEK": 3.14641,
    "out:LCP/MSEK": -0.901663,
    "out:ROI% old": 12.03905,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1321.361406,
    "out:Return %": 11,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.800633,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14392.57191,
    "out:PV (% sold (El))": 41.52839,
    "out:PV (kWh self-consumed)": 20264.61545,
    "out:PV (ratio sold/self-consumed)": 0.710232,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222607.052067,
    "out:EL kWh savings": -37342.168183,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169511.57,
    "out:DH kr savings": 222607.0520666135,
    "out:El kr savings": -74684.33636648726,
    "out:El kr sold": 21588.857865499285,
    "out:El kr saved": 40529.2309,
    "out:El kr return": 62118.088765499284,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107281.857312,
    "out:% savings (space heating)": 62.351868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.41,
    "out:Etvv": 0,
    "out:Ef": 19.5,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5733342.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -25.025604,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.498895,
    "out:Bought CO2": 2.473291,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.499063,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.303959,
    "out:Electricity (inc PV)": 19.444009,
    "out:Total Energy Use Pre PV": 63.303959,
    "out:Total Energy Use Post PV": 56.444009,
    "out:Primary Energy": 67.691325,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.885461,
    "out:CO2 Operational/m2": -11.810723,
    "out:Total CO2/m2": 20.074738,
    "out:Total CO2 (tons)": 52.776474,
    "out:Klimatpaverkan": -67.362599,
    "out:Initial Cost/MSEK": 5.799067908381852,
    "out:Running cost/(Apt SEK y)": 9736.236842,
    "out:Running Cost over RSP/MSEK": 2.89428,
    "out:LCP/MSEK": -0.715258,
    "out:ROI% old": 12.523712,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1315.857628,
    "out:Return %": 11,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.704459,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14440.694299,
    "out:PV (% sold (El))": 41.667242,
    "out:PV (kWh self-consumed)": 20216.493062,
    "out:PV (ratio sold/self-consumed)": 0.714303,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231303.895139,
    "out:EL kWh savings": -37184.591217,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178595.75,
    "out:DH kr savings": 231303.89513871184,
    "out:El kr savings": -74369.18243405325,
    "out:El kr sold": 21661.04144830774,
    "out:El kr saved": 40432.986124,
    "out:El kr return": 62094.02757230774,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98585.01424,
    "out:% savings (space heating)": 65.403828,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.03,
    "out:Etvv": 0,
    "out:Ef": 19.44,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5799067.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": -25.130131,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.593126,
    "out:Bought CO2": 2.462995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.36546,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.060177,
    "out:Electricity (inc PV)": 19.125295,
    "out:Total Energy Use Pre PV": 65.060177,
    "out:Total Energy Use Post PV": 58.125295,
    "out:Primary Energy": 68.027884,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.600562,
    "out:CO2 Operational/m2": -11.35641,
    "out:Total CO2/m2": 21.244152,
    "out:Total CO2 (tons)": 55.850863,
    "out:Klimatpaverkan": -64.288211,
    "out:Initial Cost/MSEK": 5.961022920881852,
    "out:Running cost/(Apt SEK y)": 10121.973684,
    "out:Running Cost over RSP/MSEK": 3.00904,
    "out:LCP/MSEK": -0.991973,
    "out:ROI% old": 11.908431,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1301.919945,
    "out:Return %": 11,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.460677,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14563.048491,
    "out:PV (% sold (El))": 42.020284,
    "out:PV (kWh self-consumed)": 20094.13887,
    "out:PV (ratio sold/self-consumed)": 0.724741,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226397.13854,
    "out:EL kWh savings": -36346.692301,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175548.33,
    "out:DH kr savings": 226397.13853995083,
    "out:El kr savings": -72693.3846025978,
    "out:El kr sold": 21844.5727363506,
    "out:El kr saved": 40188.27774,
    "out:El kr return": 62032.8504763506,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 103491.770839,
    "out:% savings (space heating)": 63.681913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 43.2,
    "out:Etvv": 0,
    "out:Ef": 19.13,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5961022.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -25.395786,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.832701,
    "out:Bought CO2": 2.436915,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.083084,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.956225,
    "out:Electricity (inc PV)": 19.058051,
    "out:Total Energy Use Pre PV": 61.956225,
    "out:Total Energy Use Post PV": 55.058051,
    "out:Primary Energy": 65.299875,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.600562,
    "out:CO2 Operational/m2": -12.549977,
    "out:Total CO2/m2": 20.050585,
    "out:Total CO2 (tons)": 52.712976,
    "out:Klimatpaverkan": -67.426098,
    "out:Initial Cost/MSEK": 6.026747908381853,
    "out:Running cost/(Apt SEK y)": 9481.473684,
    "out:Running Cost over RSP/MSEK": 2.81872,
    "out:LCP/MSEK": -0.867378,
    "out:ROI% old": 12.229694,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1295.982568,
    "out:Return %": 11,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.356725,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14615.501088,
    "out:PV (% sold (El))": 42.171631,
    "out:PV (kWh self-consumed)": 20041.686273,
    "out:PV (ratio sold/self-consumed)": 0.729255,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235026.503084,
    "out:EL kWh savings": -36169.907865,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184609.94,
    "out:DH kr savings": 235026.50308437238,
    "out:El kr savings": -72339.81573088706,
    "out:El kr sold": 21923.251631826122,
    "out:El kr saved": 40083.372546,
    "out:El kr return": 62006.62417782612,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 94862.406294,
    "out:% savings (space heating)": 66.710192,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.85,
    "out:Etvv": 0,
    "out:Ef": 19.06,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6026747.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -25.509401,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.935203,
    "out:Bought CO2": 2.425802,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.807106,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.400133,
    "out:Electricity (inc PV)": 17.755282,
    "out:Total Energy Use Pre PV": 67.400133,
    "out:Total Energy Use Post PV": 58.755282,
    "out:Primary Energy": 67.282115,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.455261,
    "out:CO2 Operational/m2": -69.048373,
    "out:Total CO2/m2": -22.593112,
    "out:Total CO2 (tons)": -59.397278,
    "out:Klimatpaverkan": -179.536351,
    "out:Initial Cost/MSEK": 6.3783261399306035,
    "out:Running cost/(Apt SEK y)": 9159.105263,
    "out:Running Cost over RSP/MSEK": 2.7344,
    "out:LCP/MSEK": -1.134636,
    "out:ROI% old": 11.744438,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1202.33234,
    "out:Return %": 11,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.800633,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44679.567623,
    "out:PV (% sold (El))": 64.45931,
    "out:PV (kWh self-consumed)": 24634.807098,
    "out:PV (ratio sold/self-consumed)": 1.813676,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222607.052067,
    "out:EL kWh savings": -32744.928942,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224136.55,
    "out:DH kr savings": 222607.0520666135,
    "out:El kr savings": -65489.85788439333,
    "out:El kr sold": 67019.35143451535,
    "out:El kr saved": 49269.614196,
    "out:El kr return": 116288.96563051536,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 107281.857312,
    "out:% savings (space heating)": 62.351868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.41,
    "out:Etvv": 0,
    "out:Ef": 17.76,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6378326.139930603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -83.807717,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.060306,
    "out:Bought CO2": 2.252589,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.499063,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.303959,
    "out:Electricity (inc PV)": 17.701643,
    "out:Total Energy Use Pre PV": 63.303959,
    "out:Total Energy Use Post PV": 54.701643,
    "out:Primary Energy": 64.555066,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.455261,
    "out:CO2 Operational/m2": -70.634863,
    "out:Total CO2/m2": -24.179602,
    "out:Total CO2 (tons)": -63.568159,
    "out:Klimatpaverkan": -183.707233,
    "out:Initial Cost/MSEK": 6.444051127430603,
    "out:Running cost/(Apt SEK y)": 8310.973684,
    "out:Running Cost over RSP/MSEK": 2.48236,
    "out:LCP/MSEK": -0.948321,
    "out:ROI% old": 12.183396,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1197.409323,
    "out:Return %": 11,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.704459,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44749.768191,
    "out:PV (% sold (El))": 64.560588,
    "out:PV (kWh self-consumed)": 24564.60653,
    "out:PV (ratio sold/self-consumed)": 1.821717,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231303.895139,
    "out:EL kWh savings": -32603.912043,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233220.72,
    "out:DH kr savings": 231303.89513871184,
    "out:El kr savings": -65207.82408643831,
    "out:El kr sold": 67124.65228695385,
    "out:El kr saved": 49129.21306,
    "out:El kr return": 116253.86534695385,
    "out:% solar/total": 176,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98585.01424,
    "out:% savings (space heating)": 65.403828,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.03,
    "out:Etvv": 0,
    "out:Ef": 17.7,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6444051.127430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": -83.954271,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.197662,
    "out:Bought CO2": 2.243391,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.36546,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 26.060177,
    "out:Electricity (inc PV)": 17.416688,
    "out:Total Energy Use Pre PV": 65.060177,
    "out:Total Energy Use Post PV": 56.416688,
    "out:Primary Energy": 64.952391,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.170362,
    "out:CO2 Operational/m2": -70.287469,
    "out:Total CO2/m2": -23.117107,
    "out:Total CO2 (tons)": -60.774861,
    "out:Klimatpaverkan": -180.913934,
    "out:Initial Cost/MSEK": 6.606006139930603,
    "out:Running cost/(Apt SEK y)": 8697.447368,
    "out:Running Cost over RSP/MSEK": 2.59734,
    "out:LCP/MSEK": -1.225256,
    "out:ROI% old": 11.636056,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1184.944838,
    "out:Return %": 10,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.460677,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 44928.310857,
    "out:PV (% sold (El))": 64.818172,
    "out:PV (kWh self-consumed)": 24386.063865,
    "out:PV (ratio sold/self-consumed)": 1.842376,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226397.13854,
    "out:EL kWh savings": -31854.765518,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 230080.07,
    "out:DH kr savings": 226397.13853995083,
    "out:El kr savings": -63709.53103667456,
    "out:El kr sold": 67392.46628480636,
    "out:El kr saved": 48772.12773,
    "out:El kr return": 116164.59401480635,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 103491.770839,
    "out:% savings (space heating)": 63.681913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 43.2,
    "out:Etvv": 0,
    "out:Ef": 17.42,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6606006.139930603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -84.326845,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.54695,
    "out:Bought CO2": 2.220105,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.083084,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.956225,
    "out:Electricity (inc PV)": 17.356591,
    "out:Total Energy Use Pre PV": 61.956225,
    "out:Total Energy Use Post PV": 53.356591,
    "out:Primary Energy": 62.237247,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.170362,
    "out:CO2 Operational/m2": -71.526781,
    "out:Total CO2/m2": -24.356419,
    "out:Total CO2 (tons)": -64.033011,
    "out:Klimatpaverkan": -184.172085,
    "out:Initial Cost/MSEK": 6.671731127430603,
    "out:Running cost/(Apt SEK y)": 8057.263158,
    "out:Running Cost over RSP/MSEK": 2.4071,
    "out:LCP/MSEK": -1.100741,
    "out:ROI% old": 11.928774,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 145.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1179.636375,
    "out:Return %": 11,
    "out:Return/kSEK/y": 714,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 18.356725,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45004.747435,
    "out:PV (% sold (El))": 64.928448,
    "out:PV (kWh self-consumed)": 24309.627286,
    "out:PV (ratio sold/self-consumed)": 1.851314,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 235026.503084,
    "out:EL kWh savings": -31696.770541,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239140.08,
    "out:DH kr savings": 235026.50308437238,
    "out:El kr savings": -63393.54108243031,
    "out:El kr sold": 67507.1211528482,
    "out:El kr saved": 48619.254572,
    "out:El kr return": 116126.37572484819,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 94862.406294,
    "out:% savings (space heating)": 66.710192,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.85,
    "out:Etvv": 0,
    "out:Ef": 17.36,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6671731.127430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -84.486205,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.696393,
    "out:Bought CO2": 2.210188,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.89062,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.945129,
    "out:Electricity (inc PV)": 26.776335,
    "out:Total Energy Use Pre PV": 64.945129,
    "out:Total Energy Use Post PV": 65.776335,
    "out:Primary Energy": 81.234011,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.315661,
    "out:CO2 Operational/m2": 17.464341,
    "out:Total CO2/m2": 34.780002,
    "out:Total CO2 (tons)": 91.436604,
    "out:Klimatpaverkan": -28.702469,
    "out:Initial Cost/MSEK": 5.188653559370602,
    "out:Running cost/(Apt SEK y)": 11738.5,
    "out:Running Cost over RSP/MSEK": 3.47658,
    "out:LCP/MSEK": -0.687144,
    "out:ROI% old": 12.393829,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 97,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1816.15903,
    "out:Return %": 11,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.345629,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 227645.492616,
    "out:EL kWh savings": -56461.271894,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114722.95,
    "out:DH kr savings": 227645.49261647137,
    "out:El kr savings": -112922.54378725594,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 102243.416762,
    "out:% savings (space heating)": 64.119994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.48,
    "out:Etvv": 0,
    "out:Ef": 26.78,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5188653.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": 3.424965,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.424965,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.617229,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.838454,
    "out:Electricity (inc PV)": 26.689518,
    "out:Total Energy Use Pre PV": 61.838454,
    "out:Total Energy Use Post PV": 62.689518,
    "out:Primary Energy": 78.480801,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.315661,
    "out:CO2 Operational/m2": 16.370307,
    "out:Total CO2/m2": 33.685968,
    "out:Total CO2 (tons)": 88.56039,
    "out:Klimatpaverkan": -31.578684,
    "out:Initial Cost/MSEK": 5.254378546870602,
    "out:Running cost/(Apt SEK y)": 11096.921053,
    "out:Running Cost over RSP/MSEK": 3.28594,
    "out:LCP/MSEK": -0.562229,
    "out:ROI% old": 12.757116,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1808.69178,
    "out:Return %": 11,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.238954,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 236251.235601,
    "out:EL kWh savings": -56233.030052,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123785.18,
    "out:DH kr savings": 236251.23560125695,
    "out:El kr savings": -112466.06010491554,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 93637.673778,
    "out:% savings (space heating)": 67.139984,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.14,
    "out:Etvv": 0,
    "out:Ef": 26.69,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5254378.546870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": 3.410883,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.410883,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.760488,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.666138,
    "out:Electricity (inc PV)": 26.388336,
    "out:Total Energy Use Pre PV": 63.666138,
    "out:Total Energy Use Post PV": 64.388336,
    "out:Primary Energy": 79.198144,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.030762,
    "out:CO2 Operational/m2": 17.067528,
    "out:Total CO2/m2": 35.09829,
    "out:Total CO2 (tons)": 92.273383,
    "out:Klimatpaverkan": -27.86569,
    "out:Initial Cost/MSEK": 5.416333559370601,
    "out:Running cost/(Apt SEK y)": 11490.947368,
    "out:Running Cost over RSP/MSEK": 3.40314,
    "out:LCP/MSEK": -0.841384,
    "out:ROI% old": 12.066544,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 103.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1796.62966,
    "out:Return %": 11,
    "out:Return/kSEK/y": 584,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.066638,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 230616.60897,
    "out:EL kWh savings": -55441.222754,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119734.16,
    "out:DH kr savings": 230616.60896978257,
    "out:El kr savings": -110882.44550852678,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 99272.300409,
    "out:% savings (space heating)": 65.16264,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.76,
    "out:Etvv": 0,
    "out:Ef": 26.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5416333.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": 3.388136,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.388136,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.507638,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.553239,
    "out:Electricity (inc PV)": 26.291857,
    "out:Total Energy Use Pre PV": 60.553239,
    "out:Total Energy Use Post PV": 61.291857,
    "out:Primary Energy": 76.452341,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.030762,
    "out:CO2 Operational/m2": 15.972672,
    "out:Total CO2/m2": 34.003434,
    "out:Total CO2 (tons)": 89.395008,
    "out:Klimatpaverkan": -30.744066,
    "out:Initial Cost/MSEK": 5.4820585468706025,
    "out:Running cost/(Apt SEK y)": 10848.526316,
    "out:Running Cost over RSP/MSEK": 3.21224,
    "out:LCP/MSEK": -0.716209,
    "out:ROI% old": 12.419344,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 1788.72673,
    "out:Return %": 11,
    "out:Return/kSEK/y": 608,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.953739,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 239168.349678,
    "out:EL kWh savings": -55187.579521,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128793.19,
    "out:DH kr savings": 239168.3496778311,
    "out:El kr savings": -110375.1590417227,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90720.559701,
    "out:% savings (space heating)": 68.163679,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.45,
    "out:Etvv": 0,
    "out:Ef": 26.29,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5482058.546870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": 3.373232,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.373232,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.89062,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.945129,
    "out:Electricity (inc PV)": 18.996867,
    "out:Total Energy Use Pre PV": 64.945129,
    "out:Total Energy Use Post PV": 57.996867,
    "out:Primary Energy": 67.230969,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.885461,
    "out:CO2 Operational/m2": -11.482166,
    "out:Total CO2/m2": 20.403295,
    "out:Total CO2 (tons)": 53.64025,
    "out:Klimatpaverkan": -66.498823,
    "out:Initial Cost/MSEK": 5.833636795881851,
    "out:Running cost/(Apt SEK y)": 10106.815789,
    "out:Running Cost over RSP/MSEK": 3.00459,
    "out:LCP/MSEK": -0.860137,
    "out:ROI% old": 12.179366,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1295.348983,
    "out:Return %": 11,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.345629,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14621.112249,
    "out:PV (% sold (El))": 42.187821,
    "out:PV (kWh self-consumed)": 20036.075112,
    "out:PV (ratio sold/self-consumed)": 0.729739,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 227645.492616,
    "out:EL kWh savings": -36009.055166,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177559.05,
    "out:DH kr savings": 227645.49261647137,
    "out:El kr savings": -72018.11033194077,
    "out:El kr sold": 21931.668373414854,
    "out:El kr saved": 40072.150224,
    "out:El kr return": 62003.81859741485,
    "out:% solar/total": 144,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 102243.416762,
    "out:% savings (space heating)": 64.119994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.48,
    "out:Etvv": 0,
    "out:Ef": 19,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5833636.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -25.521542,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -27.946159,
    "out:Bought CO2": 2.424617,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.617229,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.838454,
    "out:Electricity (inc PV)": 18.926482,
    "out:Total Energy Use Pre PV": 61.838454,
    "out:Total Energy Use Post PV": 54.926482,
    "out:Primary Energy": 64.507337,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.885461,
    "out:CO2 Operational/m2": -12.678954,
    "out:Total CO2/m2": 19.206507,
    "out:Total CO2 (tons)": 50.493895,
    "out:Klimatpaverkan": -69.645178,
    "out:Initial Cost/MSEK": 5.899361783381852,
    "out:Running cost/(Apt SEK y)": 9465.973684,
    "out:Running Cost over RSP/MSEK": 2.81416,
    "out:LCP/MSEK": -0.735432,
    "out:ROI% old": 12.504815,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1289.26013,
    "out:Return %": 11,
    "out:Return/kSEK/y": 661,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.238954,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14675.10991,
    "out:PV (% sold (El))": 42.343626,
    "out:PV (kWh self-consumed)": 19982.077451,
    "out:PV (ratio sold/self-consumed)": 0.734414,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236251.235601,
    "out:EL kWh savings": -35824.013043,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186615.87,
    "out:DH kr savings": 236251.23560125695,
    "out:El kr savings": -71648.02608598053,
    "out:El kr sold": 22012.664864840743,
    "out:El kr saved": 39964.154902,
    "out:El kr return": 61976.819766840745,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 93637.673778,
    "out:% savings (space heating)": 67.139984,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.14,
    "out:Etvv": 0,
    "out:Ef": 18.93,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5899361.783381851,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -25.638378,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.051598,
    "out:Bought CO2": 2.41322,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.760488,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.666138,
    "out:Electricity (inc PV)": 18.68247,
    "out:Total Energy Use Pre PV": 63.666138,
    "out:Total Energy Use Post PV": 56.68247,
    "out:Primary Energy": 65.327585,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.600562,
    "out:CO2 Operational/m2": -12.148213,
    "out:Total CO2/m2": 20.452349,
    "out:Total CO2 (tons)": 53.769213,
    "out:Klimatpaverkan": -66.36986,
    "out:Initial Cost/MSEK": 6.061316795881852,
    "out:Running cost/(Apt SEK y)": 9861.131579,
    "out:Running Cost over RSP/MSEK": 2.93169,
    "out:LCP/MSEK": -1.014917,
    "out:ROI% old": 11.893691,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 128.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1279.432422,
    "out:Return %": 11,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.066638,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14762.641013,
    "out:PV (% sold (El))": 42.596189,
    "out:PV (kWh self-consumed)": 19894.546348,
    "out:PV (ratio sold/self-consumed)": 0.742045,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 230616.60897,
    "out:EL kWh savings": -35182.505641,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182395.56,
    "out:DH kr savings": 230616.60896978257,
    "out:El kr savings": -70365.01128133078,
    "out:El kr sold": 22143.961519718003,
    "out:El kr saved": 39789.092696,
    "out:El kr return": 61933.054215718,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 99272.300409,
    "out:% savings (space heating)": 65.16264,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.76,
    "out:Etvv": 0,
    "out:Ef": 18.68,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6061316.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": -25.827605,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.222428,
    "out:Bought CO2": 2.394823,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.507638,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.553239,
    "out:Electricity (inc PV)": 18.604357,
    "out:Total Energy Use Pre PV": 60.553239,
    "out:Total Energy Use Post PV": 53.604357,
    "out:Primary Energy": 62.614841,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.600562,
    "out:CO2 Operational/m2": -13.352479,
    "out:Total CO2/m2": 19.248083,
    "out:Total CO2 (tons)": 50.603199,
    "out:Klimatpaverkan": -69.535875,
    "out:Initial Cost/MSEK": 6.127041783381851,
    "out:Running cost/(Apt SEK y)": 9219.473684,
    "out:Running Cost over RSP/MSEK": 2.74102,
    "out:LCP/MSEK": -0.889972,
    "out:ROI% old": 12.21067,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 140.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 1272.998898,
    "out:Return %": 11,
    "out:Return/kSEK/y": 670,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.953739,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14820.123605,
    "out:PV (% sold (El))": 42.762049,
    "out:PV (kWh self-consumed)": 19837.063756,
    "out:PV (ratio sold/self-consumed)": 0.747093,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 239168.349678,
    "out:EL kWh savings": -34977.14661,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191444.24,
    "out:DH kr savings": 239168.3496778311,
    "out:El kr savings": -69954.2932205977,
    "out:El kr sold": 22230.18540692046,
    "out:El kr saved": 39674.127512,
    "out:El kr return": 61904.31291892046,
    "out:% solar/total": 142,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90720.559701,
    "out:% savings (space heating)": 68.163679,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.45,
    "out:Etvv": 0,
    "out:Ef": 18.6,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6127041.783381851,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": -25.951919,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.334697,
    "out:Bought CO2": 2.382778,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.89062,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.945129,
    "out:Electricity (inc PV)": 17.301918,
    "out:Total Energy Use Pre PV": 64.945129,
    "out:Total Energy Use Post PV": 56.301918,
    "out:Primary Energy": 64.18006,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.455261,
    "out:CO2 Operational/m2": -70.463856,
    "out:Total CO2/m2": -24.008595,
    "out:Total CO2 (tons)": -63.118582,
    "out:Klimatpaverkan": -183.257655,
    "out:Initial Cost/MSEK": 6.478620014930602,
    "out:Running cost/(Apt SEK y)": 8682.657895,
    "out:Running Cost over RSP/MSEK": 2.59299,
    "out:LCP/MSEK": -1.09352,
    "out:ROI% old": 11.874442,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1179.069967,
    "out:Return %": 11,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.345629,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45012.916087,
    "out:PV (% sold (El))": 64.940232,
    "out:PV (kWh self-consumed)": 24301.458635,
    "out:PV (ratio sold/self-consumed)": 1.852272,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 227645.492616,
    "out:EL kWh savings": -31553.035257,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232058.8,
    "out:DH kr savings": 227645.49261647137,
    "out:El kr savings": -63106.0705137099,
    "out:El kr sold": 67519.37413036558,
    "out:El kr saved": 48602.91727,
    "out:El kr return": 116122.29140036559,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 102243.416762,
    "out:% savings (space heating)": 64.119994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.48,
    "out:Etvv": 0,
    "out:Ef": 17.3,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6478620.014930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -84.503232,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.712362,
    "out:Bought CO2": 2.20913,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.617229,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.838454,
    "out:Electricity (inc PV)": 17.239035,
    "out:Total Energy Use Pre PV": 61.838454,
    "out:Total Energy Use Post PV": 53.239035,
    "out:Primary Energy": 61.469932,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.455261,
    "out:CO2 Operational/m2": -71.7077,
    "out:Total CO2/m2": -25.252439,
    "out:Total CO2 (tons)": -66.388647,
    "out:Klimatpaverkan": -186.527721,
    "out:Initial Cost/MSEK": 6.544345002430603,
    "out:Running cost/(Apt SEK y)": 8042.105263,
    "out:Running Cost over RSP/MSEK": 2.40264,
    "out:LCP/MSEK": -0.968895,
    "out:ROI% old": 12.170704,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 145.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1173.627067,
    "out:Return %": 11,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 18.238954,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45091.614188,
    "out:PV (% sold (El))": 65.05377,
    "out:PV (kWh self-consumed)": 24222.760533,
    "out:PV (ratio sold/self-consumed)": 1.861539,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236251.235601,
    "out:EL kWh savings": -31387.715887,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241113.23,
    "out:DH kr savings": 236251.23560125695,
    "out:El kr savings": -62775.43177479221,
    "out:El kr sold": 67637.42128209799,
    "out:El kr saved": 48445.521066,
    "out:El kr return": 116082.94234809799,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 93637.673778,
    "out:% savings (space heating)": 67.139984,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 39.14,
    "out:Etvv": 0,
    "out:Ef": 17.24,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6544345.002430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -84.667124,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.866086,
    "out:Bought CO2": 2.198962,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.760488,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.666138,
    "out:Electricity (inc PV)": 17.021198,
    "out:Total Energy Use Pre PV": 63.666138,
    "out:Total Energy Use Post PV": 55.021198,
    "out:Primary Energy": 62.337295,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.170362,
    "out:CO2 Operational/m2": -71.253176,
    "out:Total CO2/m2": -24.082814,
    "out:Total CO2 (tons)": -63.313704,
    "out:Klimatpaverkan": -183.452777,
    "out:Initial Cost/MSEK": 6.706300014930602,
    "out:Running cost/(Apt SEK y)": 8437.815789,
    "out:Running Cost over RSP/MSEK": 2.52033,
    "out:LCP/MSEK": -1.24854,
    "out:ROI% old": 11.626083,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1164.843949,
    "out:Return %": 10,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 18.066638,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45219.131761,
    "out:PV (% sold (El))": 65.23774,
    "out:PV (kWh self-consumed)": 24095.242961,
    "out:PV (ratio sold/self-consumed)": 1.876683,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 230616.60897,
    "out:EL kWh savings": -30815.022544,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236815.26,
    "out:DH kr savings": 230616.60896978257,
    "out:El kr savings": -61630.04508888957,
    "out:El kr sold": 67828.69764095063,
    "out:El kr saved": 48190.485922,
    "out:El kr return": 116019.18356295064,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 99272.300409,
    "out:% savings (space heating)": 65.16264,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.76,
    "out:Etvv": 0,
    "out:Ef": 17.02,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6706300.014930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": -84.932568,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.115125,
    "out:Bought CO2": 2.182557,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.507638,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.553239,
    "out:Electricity (inc PV)": 16.95151,
    "out:Total Energy Use Pre PV": 60.553239,
    "out:Total Energy Use Post PV": 51.95151,
    "out:Primary Energy": 59.639716,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.170362,
    "out:CO2 Operational/m2": -72.507553,
    "out:Total CO2/m2": -25.337191,
    "out:Total CO2 (tons)": -66.61146,
    "out:Klimatpaverkan": -186.750534,
    "out:Initial Cost/MSEK": 6.772025002430603,
    "out:Running cost/(Apt SEK y)": 7796.5,
    "out:Running Cost over RSP/MSEK": 2.32976,
    "out:LCP/MSEK": -1.123695,
    "out:ROI% old": 11.915259,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 150,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 1159.095449,
    "out:Return %": 11,
    "out:Return/kSEK/y": 724,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.953739,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45302.953248,
    "out:PV (% sold (El))": 65.35867,
    "out:PV (kWh self-consumed)": 24011.421473,
    "out:PV (ratio sold/self-consumed)": 1.886725,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 239168.349678,
    "out:EL kWh savings": -30631.812834,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245859.15,
    "out:DH kr savings": 239168.3496778311,
    "out:El kr savings": -61263.62566809705,
    "out:El kr sold": 67954.42987266424,
    "out:El kr saved": 48022.842946,
    "out:El kr return": 115977.27281866423,
    "out:% solar/total": 172,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 90720.559701,
    "out:% savings (space heating)": 68.163679,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.45,
    "out:Etvv": 0,
    "out:Ef": 16.95,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6772025.002430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": -85.106993,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.278815,
    "out:Bought CO2": 2.171822,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.818695,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.699652,
    "out:Electricity (inc PV)": 38.448002,
    "out:Total Energy Use Pre PV": 43.699652,
    "out:Total Energy Use Post PV": 45.448002,
    "out:Primary Energy": 76.174622,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.754672,
    "out:CO2 Operational/m2": 7.364536,
    "out:Total CO2/m2": 25.119208,
    "out:Total CO2 (tons)": 66.038383,
    "out:Klimatpaverkan": -54.100691,
    "out:Initial Cost/MSEK": 6.149109684370602,
    "out:Running cost/(Apt SEK y)": 6540.631579,
    "out:Running Cost over RSP/MSEK": 1.9265,
    "out:LCP/MSEK": -0.09752,
    "out:ROI% old": 14.059155,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 188.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2568.97564,
    "out:Return %": 13,
    "out:Return/kSEK/y": 772,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.100152,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 311962.564295,
    "out:EL kWh savings": -87146.077469,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137670.41,
    "out:DH kr savings": 311962.5642945321,
    "out:El kr savings": -174292.15493728564,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 17926.345084,
    "out:% savings (space heating)": 93.709156,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.96,
    "out:Etvv": 0,
    "out:Ef": 38.45,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6149109.6843706025,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 4.844648,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.844648,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.071392,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.130526,
    "out:Electricity (inc PV)": 37.961991,
    "out:Total Energy Use Pre PV": 41.130526,
    "out:Total Energy Use Post PV": 42.961991,
    "out:Primary Energy": 73.727461,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.754672,
    "out:CO2 Operational/m2": 6.569438,
    "out:Total CO2/m2": 24.32411,
    "out:Total CO2 (tons)": 63.948071,
    "out:Klimatpaverkan": -56.191003,
    "out:Initial Cost/MSEK": 6.2148346718706025,
    "out:Running cost/(Apt SEK y)": 6044,
    "out:Running Cost over RSP/MSEK": 1.77916,
    "out:LCP/MSEK": -0.015905,
    "out:ROI% old": 14.249155,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2529.13682,
    "out:Return %": 13,
    "out:Return/kSEK/y": 791,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 28.531026,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 316556.222838,
    "out:EL kWh savings": -85868.35484,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144819.51,
    "out:DH kr savings": 316556.22283839225,
    "out:El kr savings": -171736.70967958274,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 13332.68654,
    "out:% savings (space heating)": 95.321196,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.94,
    "out:Etvv": 0,
    "out:Ef": 37.96,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6214834.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 4.769518,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.769518,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.198427,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.110793,
    "out:Electricity (inc PV)": 37.631395,
    "out:Total Energy Use Pre PV": 42.110793,
    "out:Total Energy Use Post PV": 43.631395,
    "out:Primary Energy": 73.884021,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.469773,
    "out:CO2 Operational/m2": 6.926818,
    "out:Total CO2/m2": 25.396591,
    "out:Total CO2 (tons)": 66.767623,
    "out:Klimatpaverkan": -53.371451,
    "out:Initial Cost/MSEK": 6.3767896843706025,
    "out:Running cost/(Apt SEK y)": 6250.210526,
    "out:Running Cost over RSP/MSEK": 1.84046,
    "out:LCP/MSEK": -0.23916,
    "out:ROI% old": 13.749933,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 195.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2527.75551,
    "out:Return %": 12,
    "out:Return/kSEK/y": 783,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.511293,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 313593.248496,
    "out:EL kWh savings": -84999.218153,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143594.81,
    "out:DH kr savings": 313593.24849623215,
    "out:El kr savings": -169998.43630631437,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 16295.660883,
    "out:% savings (space heating)": 94.281408,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 37.63,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6376789.6843706025,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 4.766914,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.766914,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.54038,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.51462,
    "out:Electricity (inc PV)": 37.107427,
    "out:Total Energy Use Pre PV": 40.51462,
    "out:Total Energy Use Post PV": 42.107427,
    "out:Primary Energy": 71.465961,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.469773,
    "out:CO2 Operational/m2": 6.488134,
    "out:Total CO2/m2": 24.957907,
    "out:Total CO2 (tons)": 65.614323,
    "out:Klimatpaverkan": -54.524751,
    "out:Initial Cost/MSEK": 6.442514671870602,
    "out:Running cost/(Apt SEK y)": 5958.789474,
    "out:Running Cost over RSP/MSEK": 1.75413,
    "out:LCP/MSEK": -0.218555,
    "out:ROI% old": 13.801088,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 209.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2486.0234,
    "out:Return %": 12,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.91512,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 317952.253069,
    "out:EL kWh savings": -83621.706594,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150708.84,
    "out:DH kr savings": 317952.2530693781,
    "out:El kr savings": -167243.4131879322,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11936.656309,
    "out:% savings (space heating)": 95.811101,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.01,
    "out:Etvv": 0,
    "out:Ef": 37.11,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6442514.671870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 4.688214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.688214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.818695,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.699652,
    "out:Electricity (inc PV)": 28.734072,
    "out:Total Energy Use Pre PV": 43.699652,
    "out:Total Energy Use Post PV": 35.734072,
    "out:Primary Energy": 58.689548,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.324472,
    "out:CO2 Operational/m2": -12.41941,
    "out:Total CO2/m2": 19.905062,
    "out:Total CO2 (tons)": 52.330396,
    "out:Klimatpaverkan": -67.808677,
    "out:Initial Cost/MSEK": 6.794092920881853,
    "out:Running cost/(Apt SEK y)": 4845.473684,
    "out:Running Cost over RSP/MSEK": 1.43609,
    "out:LCP/MSEK": -0.252093,
    "out:ROI% old": 13.755647,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 261.1,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1926.151888,
    "out:Return %": 12,
    "out:Return/kSEK/y": 836,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.100152,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 9798.037737,
    "out:PV (% sold (El))": 28.271301,
    "out:PV (kWh self-consumed)": 24859.149623,
    "out:PV (ratio sold/self-consumed)": 0.394142,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 311962.564295,
    "out:EL kWh savings": -61608.161298,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203443.3,
    "out:DH kr savings": 311962.5642945321,
    "out:El kr savings": -123216.32259571801,
    "out:El kr sold": 14697.056605878552,
    "out:El kr saved": 49718.299246,
    "out:El kr return": 64415.35585187856,
    "out:% solar/total": 178,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 17926.345084,
    "out:% savings (space heating)": 93.709156,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.96,
    "out:Etvv": 0,
    "out:Ef": 28.73,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6794092.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -14.939298,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.544156,
    "out:Bought CO2": 3.604858,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.071392,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.130526,
    "out:Electricity (inc PV)": 28.318416,
    "out:Total Energy Use Pre PV": 41.130526,
    "out:Total Energy Use Post PV": 33.318416,
    "out:Primary Energy": 56.369026,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.324472,
    "out:CO2 Operational/m2": -13.645771,
    "out:Total CO2/m2": 18.678701,
    "out:Total CO2 (tons)": 49.106294,
    "out:Klimatpaverkan": -71.03278,
    "out:Initial Cost/MSEK": 6.859817908381852,
    "out:Running cost/(Apt SEK y)": 4351.868421,
    "out:Running Cost over RSP/MSEK": 1.28962,
    "out:LCP/MSEK": -0.171348,
    "out:ROI% old": 13.928879,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 293.9,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1891.948878,
    "out:Return %": 12,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 28.531026,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10025.610741,
    "out:PV (% sold (El))": 28.927941,
    "out:PV (kWh self-consumed)": 24631.576619,
    "out:PV (ratio sold/self-consumed)": 0.407023,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 316556.222838,
    "out:EL kWh savings": -60515.401922,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210563.84,
    "out:DH kr savings": 316556.22283839225,
    "out:El kr savings": -121030.80384401127,
    "out:El kr sold": 15038.416112228562,
    "out:El kr saved": 49263.153238,
    "out:El kr return": 64301.56935022856,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 13332.68654,
    "out:% savings (space heating)": 95.321196,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.94,
    "out:Etvv": 0,
    "out:Ef": 28.32,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6859817.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -15.445691,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.986691,
    "out:Bought CO2": 3.541,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.198427,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.110793,
    "out:Electricity (inc PV)": 28.036318,
    "out:Total Energy Use Pre PV": 42.110793,
    "out:Total Energy Use Post PV": 34.036318,
    "out:Primary Energy": 56.612882,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.039573,
    "out:CO2 Operational/m2": -13.303457,
    "out:Total CO2/m2": 19.736116,
    "out:Total CO2 (tons)": 51.886237,
    "out:Klimatpaverkan": -68.252836,
    "out:Initial Cost/MSEK": 7.021772920881853,
    "out:Running cost/(Apt SEK y)": 4558.157895,
    "out:Running Cost over RSP/MSEK": 1.35095,
    "out:LCP/MSEK": -0.394633,
    "out:ROI% old": 13.482838,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 282.4,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1890.764693,
    "out:Return %": 12,
    "out:Return/kSEK/y": 847,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.511293,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10033.564263,
    "out:PV (% sold (El))": 28.95089,
    "out:PV (kWh self-consumed)": 24623.623098,
    "out:PV (ratio sold/self-consumed)": 0.407477,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 313593.248496,
    "out:EL kWh savings": -59773.766448,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209096.06,
    "out:DH kr savings": 313593.24849623215,
    "out:El kr savings": -119547.53289683626,
    "out:El kr sold": 15050.346393946904,
    "out:El kr saved": 49247.246196,
    "out:El kr return": 64297.5925899469,
    "out:% solar/total": 177,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 16295.660883,
    "out:% savings (space heating)": 94.281408,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 28.04,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7021772.9208818525,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -15.463361,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.00215,
    "out:Bought CO2": 3.538789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.54038,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.51462,
    "out:Electricity (inc PV)": 27.59023,
    "out:Total Energy Use Pre PV": 40.51462,
    "out:Total Energy Use Post PV": 32.59023,
    "out:Primary Energy": 54.335006,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.039573,
    "out:CO2 Operational/m2": -14.200848,
    "out:Total CO2/m2": 18.838725,
    "out:Total CO2 (tons)": 49.526997,
    "out:Klimatpaverkan": -70.612077,
    "out:Initial Cost/MSEK": 7.087497908381852,
    "out:Running cost/(Apt SEK y)": 4269.921053,
    "out:Running Cost over RSP/MSEK": 1.26554,
    "out:LCP/MSEK": -0.374948,
    "out:ROI% old": 13.529961,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 293.9,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1855.044861,
    "out:Return %": 12,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.91512,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10275.676726,
    "out:PV (% sold (El))": 29.649483,
    "out:PV (kWh self-consumed)": 24381.510635,
    "out:PV (ratio sold/self-consumed)": 0.421454,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 317952.253069,
    "out:EL kWh savings": -58601.001363,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216163.77,
    "out:DH kr savings": 317952.2530693781,
    "out:El kr savings": -117202.00272546535,
    "out:El kr sold": 15413.51508834475,
    "out:El kr saved": 48763.02127,
    "out:El kr return": 64176.53635834475,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11936.656309,
    "out:% savings (space heating)": 95.811101,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.01,
    "out:Etvv": 0,
    "out:Ef": 27.59,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7087497.908381852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -16.000768,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.472845,
    "out:Bought CO2": 3.472077,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.818695,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.699652,
    "out:Electricity (inc PV)": 26.029195,
    "out:Total Energy Use Pre PV": 43.699652,
    "out:Total Energy Use Post PV": 33.029195,
    "out:Primary Energy": 53.820769,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 46.894272,
    "out:CO2 Operational/m2": -66.700966,
    "out:Total CO2/m2": -19.806694,
    "out:Total CO2 (tons)": -52.071787,
    "out:Klimatpaverkan": -172.21086,
    "out:Initial Cost/MSEK": 7.439076139930603,
    "out:Running cost/(Apt SEK y)": 3389.105263,
    "out:Running Cost over RSP/MSEK": 1.01503,
    "out:LCP/MSEK": -0.476016,
    "out:ROI% old": 13.371591,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 293.9,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1744.551057,
    "out:Return %": 12,
    "out:Return/kSEK/y": 892,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 29.100152,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 37742.485952,
    "out:PV (% sold (El))": 54.451167,
    "out:PV (kWh self-consumed)": 31571.888769,
    "out:PV (ratio sold/self-consumed)": 1.195446,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 311962.564295,
    "out:EL kWh savings": -54497.04128,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259582.21,
    "out:DH kr savings": 311962.5642945321,
    "out:El kr savings": -108994.08255934117,
    "out:El kr sold": 56613.7289281594,
    "out:El kr saved": 63143.777538,
    "out:El kr return": 119757.5064661594,
    "out:% solar/total": 227,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 17926.345084,
    "out:% savings (space heating)": 93.709156,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 8.96,
    "out:Etvv": 0,
    "out:Ef": 26.03,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7439076.139930603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -69.220854,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.487422,
    "out:Bought CO2": 3.266568,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.071392,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.130526,
    "out:Electricity (inc PV)": 25.656141,
    "out:Total Energy Use Pre PV": 41.130526,
    "out:Total Energy Use Post PV": 30.656141,
    "out:Primary Energy": 51.576931,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 46.894272,
    "out:CO2 Operational/m2": -68.175633,
    "out:Total CO2/m2": -21.281361,
    "out:Total CO2 (tons)": -55.948685,
    "out:Klimatpaverkan": -176.087759,
    "out:Initial Cost/MSEK": 7.504801127430603,
    "out:Running cost/(Apt SEK y)": 2897.184211,
    "out:Running Cost over RSP/MSEK": 0.86906,
    "out:LCP/MSEK": -0.395771,
    "out:ROI% old": 13.532347,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 319.4,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1713.852683,
    "out:Return %": 12,
    "out:Return/kSEK/y": 910,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 28.531026,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38100.015195,
    "out:PV (% sold (El))": 54.966975,
    "out:PV (kWh self-consumed)": 31214.359527,
    "out:PV (ratio sold/self-consumed)": 1.220593,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 316556.222838,
    "out:EL kWh savings": -53516.282536,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 266673.68,
    "out:DH kr savings": 316556.22283839225,
    "out:El kr savings": -107032.56507276765,
    "out:El kr sold": 57150.02279224488,
    "out:El kr saved": 62428.719054,
    "out:El kr return": 119578.74184624488,
    "out:% solar/total": 224,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 13332.68654,
    "out:% savings (space heating)": 95.321196,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.94,
    "out:Etvv": 0,
    "out:Ef": 25.66,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7504801.127430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -69.975553,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.184751,
    "out:Bought CO2": 3.209198,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.198427,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.110793,
    "out:Electricity (inc PV)": 25.402697,
    "out:Total Energy Use Pre PV": 42.110793,
    "out:Total Energy Use Post PV": 31.402697,
    "out:Primary Energy": 51.872365,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 47.609373,
    "out:CO2 Operational/m2": -67.841941,
    "out:Total CO2/m2": -20.232568,
    "out:Total CO2 (tons)": -53.191409,
    "out:Klimatpaverkan": -173.330483,
    "out:Initial Cost/MSEK": 7.6667561399306035,
    "out:Running cost/(Apt SEK y)": 3103.552632,
    "out:Running Cost over RSP/MSEK": 0.93041,
    "out:LCP/MSEK": -0.619076,
    "out:ROI% old": 13.13217,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 319.4,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1712.78984,
    "out:Return %": 12,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 28.511293,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38112.47644,
    "out:PV (% sold (El))": 54.984953,
    "out:PV (kWh self-consumed)": 31201.898281,
    "out:PV (ratio sold/self-consumed)": 1.221479,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 313593.248496,
    "out:EL kWh savings": -52849.978412,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265062.01,
    "out:DH kr savings": 313593.24849623215,
    "out:El kr savings": -105699.95682337979,
    "out:El kr sold": 57168.71466069565,
    "out:El kr saved": 62403.796562,
    "out:El kr return": 119572.51122269566,
    "out:% solar/total": 224,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 16295.660883,
    "out:% savings (space heating)": 94.281408,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 25.4,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7666756.139930603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -70.001845,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.209057,
    "out:Bought CO2": 3.207212,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.54038,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.51462,
    "out:Electricity (inc PV)": 25.001846,
    "out:Total Energy Use Pre PV": 40.51462,
    "out:Total Energy Use Post PV": 30.001846,
    "out:Primary Energy": 49.675915,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 47.609373,
    "out:CO2 Operational/m2": -69.000443,
    "out:Total CO2/m2": -21.39107,
    "out:Total CO2 (tons)": -56.23711,
    "out:Klimatpaverkan": -176.376184,
    "out:Initial Cost/MSEK": 7.732481127430603,
    "out:Running cost/(Apt SEK y)": 2817.131579,
    "out:Running Cost over RSP/MSEK": 0.84552,
    "out:LCP/MSEK": -0.599911,
    "out:ROI% old": 13.177382,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 333.3,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1680.730588,
    "out:Return %": 12,
    "out:Return/kSEK/y": 913,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 27.91512,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38491.267069,
    "out:PV (% sold (El))": 55.531435,
    "out:PV (kWh self-consumed)": 30823.107653,
    "out:PV (ratio sold/self-consumed)": 1.24878,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 317952.253069,
    "out:EL kWh savings": -51796.141372,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272096.87,
    "out:DH kr savings": 317952.2530693781,
    "out:El kr savings": -103592.28274399591,
    "out:El kr sold": 57736.90060318539,
    "out:El kr saved": 61646.215306,
    "out:El kr return": 119383.11590918539,
    "out:% solar/total": 221,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11936.656309,
    "out:% savings (space heating)": 95.811101,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.01,
    "out:Etvv": 0,
    "out:Ef": 25,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7732481.127430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -70.800363,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.947686,
    "out:Bought CO2": 3.147323,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.000418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.911857,
    "out:Electricity (inc PV)": 37.354584,
    "out:Total Energy Use Pre PV": 41.911857,
    "out:Total Energy Use Post PV": 43.354584,
    "out:Primary Energy": 73.124021,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.754672,
    "out:CO2 Operational/m2": 6.900556,
    "out:Total CO2/m2": 24.655228,
    "out:Total CO2 (tons)": 64.81858,
    "out:Klimatpaverkan": -55.320494,
    "out:Initial Cost/MSEK": 6.249403559370601,
    "out:Running cost/(Apt SEK y)": 6222.684211,
    "out:Running Cost over RSP/MSEK": 1.83237,
    "out:LCP/MSEK": -0.103684,
    "out:ROI% old": 14.048701,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2513.82999,
    "out:Return %": 13,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.312357,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 314113.814039,
    "out:EL kWh savings": -84271.482199,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145570.85,
    "out:DH kr savings": 314113.81403902074,
    "out:El kr savings": -168542.96439882673,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 15775.09534,
    "out:% savings (space heating)": 94.464088,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.57,
    "out:Etvv": 0,
    "out:Ef": 37.35,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6249403.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 4.740652,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.740652,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.37249,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.306557,
    "out:Electricity (inc PV)": 36.816785,
    "out:Total Energy Use Pre PV": 39.306557,
    "out:Total Energy Use Post PV": 40.816785,
    "out:Primary Energy": 70.716563,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.754672,
    "out:CO2 Operational/m2": 6.100684,
    "out:Total CO2/m2": 23.855356,
    "out:Total CO2 (tons)": 62.715717,
    "out:Klimatpaverkan": -57.423357,
    "out:Initial Cost/MSEK": 6.315128546870603,
    "out:Running cost/(Apt SEK y)": 5721.052632,
    "out:Running Cost over RSP/MSEK": 1.68357,
    "out:LCP/MSEK": -0.020609,
    "out:ROI% old": 14.239095,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 217.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2471.45899,
    "out:Return %": 13,
    "out:Return/kSEK/y": 803,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 27.707057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 318393.635779,
    "out:EL kWh savings": -82857.608949,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152678.42,
    "out:DH kr savings": 318393.63577914773,
    "out:El kr savings": -165715.21789895874,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11495.2736,
    "out:% savings (space heating)": 95.965995,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.72,
    "out:Etvv": 0,
    "out:Ef": 36.82,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6315128.546870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 4.660748,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.660748,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.540297,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.429833,
    "out:Electricity (inc PV)": 36.679165,
    "out:Total Energy Use Pre PV": 41.429833,
    "out:Total Energy Use Post PV": 42.679165,
    "out:Primary Energy": 71.308508,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.469773,
    "out:CO2 Operational/m2": 6.836925,
    "out:Total CO2/m2": 25.306698,
    "out:Total CO2 (tons)": 66.531294,
    "out:Klimatpaverkan": -53.60778,
    "out:Initial Cost/MSEK": 6.477083559370602,
    "out:Running cost/(Apt SEK y)": 6155.973684,
    "out:Running Cost over RSP/MSEK": 1.81278,
    "out:LCP/MSEK": -0.311774,
    "out:ROI% old": 13.598073,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2480.08831,
    "out:Return %": 12,
    "out:Return/kSEK/y": 786,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 27.830333,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 315323.471873,
    "out:EL kWh savings": -82495.806052,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150331.86,
    "out:DH kr savings": 315323.47187332856,
    "out:El kr savings": -164991.61210327697,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 14565.437505,
    "out:% savings (space heating)": 94.88859,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.8,
    "out:Etvv": 0,
    "out:Ef": 36.68,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6477083.559370602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 4.677021,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.677021,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.984938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.802542,
    "out:Electricity (inc PV)": 36.109114,
    "out:Total Energy Use Pre PV": 38.802542,
    "out:Total Energy Use Post PV": 40.109114,
    "out:Primary Energy": 68.925778,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.469773,
    "out:CO2 Operational/m2": 6.03415,
    "out:Total CO2/m2": 24.503923,
    "out:Total CO2 (tons)": 64.420799,
    "out:Klimatpaverkan": -55.718275,
    "out:Initial Cost/MSEK": 6.542808546870602,
    "out:Running cost/(Apt SEK y)": 5651.315789,
    "out:Running Cost over RSP/MSEK": 1.66308,
    "out:LCP/MSEK": -0.227799,
    "out:ROI% old": 13.788334,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 225,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2436.17794,
    "out:Return %": 12,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 27.203042,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 319412.509756,
    "out:EL kWh savings": -80997.142313,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157418.23,
    "out:DH kr savings": 319412.5097557792,
    "out:El kr savings": -161994.28462591782,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 10476.399623,
    "out:% savings (space heating)": 96.323545,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.05,
    "out:Etvv": 0,
    "out:Ef": 36.11,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6542808.546870602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 4.594214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.594214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.000418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.911857,
    "out:Electricity (inc PV)": 27.800459,
    "out:Total Energy Use Pre PV": 41.911857,
    "out:Total Energy Use Post PV": 33.800459,
    "out:Primary Energy": 55.926596,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 32.324472,
    "out:CO2 Operational/m2": -13.482052,
    "out:Total CO2/m2": 18.84242,
    "out:Total CO2 (tons)": 49.536711,
    "out:Klimatpaverkan": -70.602363,
    "out:Initial Cost/MSEK": 6.894386795881852,
    "out:Running cost/(Apt SEK y)": 4531.710526,
    "out:Running Cost over RSP/MSEK": 1.34317,
    "out:LCP/MSEK": -0.259467,
    "out:ROI% old": 13.748079,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 282.4,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1878.833298,
    "out:Return %": 12,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.312357,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10114.025968,
    "out:PV (% sold (El))": 29.183055,
    "out:PV (kWh self-consumed)": 24543.161393,
    "out:PV (ratio sold/self-consumed)": 0.412091,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 314113.814039,
    "out:EL kWh savings": -59153.693278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210977.47,
    "out:DH kr savings": 314113.81403902074,
    "out:El kr savings": -118307.38655645197,
    "out:El kr sold": 15171.038951426164,
    "out:El kr saved": 49086.322786,
    "out:El kr return": 64257.36173742616,
    "out:% solar/total": 176,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 15775.09534,
    "out:% savings (space heating)": 94.464088,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.57,
    "out:Etvv": 0,
    "out:Ef": 27.8,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6894386.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -15.641956,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.158465,
    "out:Bought CO2": 3.516509,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.37249,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.306557,
    "out:Electricity (inc PV)": 27.343328,
    "out:Total Energy Use Pre PV": 39.306557,
    "out:Total Energy Use Post PV": 31.343328,
    "out:Primary Energy": 53.66434,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 32.324472,
    "out:CO2 Operational/m2": -14.74982,
    "out:Total CO2/m2": 17.574652,
    "out:Total CO2 (tons)": 46.20375,
    "out:Klimatpaverkan": -73.935324,
    "out:Initial Cost/MSEK": 6.960111783381851,
    "out:Running cost/(Apt SEK y)": 4033.315789,
    "out:Running Cost over RSP/MSEK": 1.1953,
    "out:LCP/MSEK": -0.177322,
    "out:ROI% old": 13.921759,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 319.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1842.604103,
    "out:Return %": 12,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 27.707057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10360.711852,
    "out:PV (% sold (El))": 29.894843,
    "out:PV (kWh self-consumed)": 24296.475509,
    "out:PV (ratio sold/self-consumed)": 0.426429,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 318393.635779,
    "out:EL kWh savings": -57951.896152,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218030.91,
    "out:DH kr savings": 318393.63577914773,
    "out:El kr savings": -115903.79230426636,
    "out:El kr sold": 15541.067777983772,
    "out:El kr saved": 48592.951018,
    "out:El kr return": 64134.01879598377,
    "out:% solar/total": 174,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11495.2736,
    "out:% savings (space heating)": 95.965995,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.72,
    "out:Etvv": 0,
    "out:Ef": 27.34,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6960111.783381851,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -16.189756,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.638588,
    "out:Bought CO2": 3.448832,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.540297,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.429833,
    "out:Electricity (inc PV)": 27.226523,
    "out:Total Energy Use Pre PV": 41.429833,
    "out:Total Energy Use Post PV": 33.226523,
    "out:Primary Energy": 54.293752,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.039573,
    "out:CO2 Operational/m2": -13.917786,
    "out:Total CO2/m2": 19.121787,
    "out:Total CO2 (tons)": 50.271167,
    "out:Klimatpaverkan": -69.867907,
    "out:Initial Cost/MSEK": 7.122066795881851,
    "out:Running cost/(Apt SEK y)": 4467.578947,
    "out:Running Cost over RSP/MSEK": 1.32432,
    "out:LCP/MSEK": -0.468297,
    "out:ROI% old": 13.346387,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 293.9,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1849.97365,
    "out:Return %": 12,
    "out:Return/kSEK/y": 851,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 27.830333,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10310.290599,
    "out:PV (% sold (El))": 29.749358,
    "out:PV (kWh self-consumed)": 24346.896761,
    "out:PV (ratio sold/self-consumed)": 0.423475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 315323.471873,
    "out:EL kWh savings": -57644.815877,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215499.28,
    "out:DH kr savings": 315323.47187332856,
    "out:El kr savings": -115289.63175373153,
    "out:El kr sold": 15465.435899013712,
    "out:El kr saved": 48693.793522,
    "out:El kr return": 64159.22942101371,
    "out:% solar/total": 175,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 14565.437505,
    "out:% savings (space heating)": 94.88859,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.8,
    "out:Etvv": 0,
    "out:Ef": 27.23,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7122066.795881852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -16.07769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -19.540292,
    "out:Bought CO2": 3.462602,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.984938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.802542,
    "out:Electricity (inc PV)": 26.743214,
    "out:Total Energy Use Pre PV": 38.802542,
    "out:Total Energy Use Post PV": 30.743214,
    "out:Primary Energy": 52.067158,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.039573,
    "out:CO2 Operational/m2": -15.211067,
    "out:Total CO2/m2": 17.828506,
    "out:Total CO2 (tons)": 46.871132,
    "out:Klimatpaverkan": -73.267942,
    "out:Initial Cost/MSEK": 7.187791783381852,
    "out:Running cost/(Apt SEK y)": 3966.315789,
    "out:Running Cost over RSP/MSEK": 1.17561,
    "out:LCP/MSEK": -0.385312,
    "out:ROI% old": 13.519908,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 319.4,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1812.522219,
    "out:Return %": 12,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 27.203042,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 10569.358532,
    "out:PV (% sold (El))": 30.496873,
    "out:PV (kWh self-consumed)": 24087.828829,
    "out:PV (ratio sold/self-consumed)": 0.438784,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319412.509756,
    "out:EL kWh savings": -56374.196804,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222518.15,
    "out:DH kr savings": 319412.5097557792,
    "out:El kr savings": -112748.39360880245,
    "out:El kr sold": 15854.037797665917,
    "out:El kr saved": 48175.657658,
    "out:El kr return": 64029.69545566591,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 10476.399623,
    "out:% savings (space heating)": 96.323545,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.05,
    "out:Etvv": 0,
    "out:Ef": 26.74,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7187791.783381851,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -16.651003,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -20.043616,
    "out:Bought CO2": 3.392613,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.000418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.911857,
    "out:Electricity (inc PV)": 25.190821,
    "out:Total Energy Use Pre PV": 41.911857,
    "out:Total Energy Use Post PV": 31.190821,
    "out:Primary Energy": 51.229248,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 46.894272,
    "out:CO2 Operational/m2": -68.107496,
    "out:Total CO2/m2": -21.213224,
    "out:Total CO2 (tons)": -55.769553,
    "out:Klimatpaverkan": -175.908627,
    "out:Initial Cost/MSEK": 7.539370014930602,
    "out:Running cost/(Apt SEK y)": 3077.684211,
    "out:Running Cost over RSP/MSEK": 0.9228,
    "out:LCP/MSEK": -0.48408,
    "out:ROI% old": 13.368472,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 319.4,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1702.080925,
    "out:Return %": 12,
    "out:Return/kSEK/y": 903,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 28.312357,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38238.473637,
    "out:PV (% sold (El))": 55.16673,
    "out:PV (kWh self-consumed)": 31075.901084,
    "out:PV (ratio sold/self-consumed)": 1.230486,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314113.814039,
    "out:EL kWh savings": -52292.956534,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266885.61,
    "out:DH kr savings": 314113.81403902074,
    "out:El kr savings": -104585.91306835972,
    "out:El kr sold": 57357.7104557808,
    "out:El kr saved": 62151.802168,
    "out:El kr return": 119509.5126237808,
    "out:% solar/total": 223,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 15775.09534,
    "out:% savings (space heating)": 94.464088,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 7.57,
    "out:Etvv": 0,
    "out:Ef": 25.19,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7539370.014930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -70.2674,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -73.454604,
    "out:Bought CO2": 3.187204,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.37249,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.306557,
    "out:Electricity (inc PV)": 24.779915,
    "out:Total Energy Use Pre PV": 39.306557,
    "out:Total Energy Use Post PV": 28.779915,
    "out:Primary Energy": 49.050197,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 46.894272,
    "out:CO2 Operational/m2": -69.640913,
    "out:Total CO2/m2": -22.746641,
    "out:Total CO2 (tons)": -59.800906,
    "out:Klimatpaverkan": -179.939979,
    "out:Initial Cost/MSEK": 7.605095002430603,
    "out:Running cost/(Apt SEK y)": 2581.131579,
    "out:Running Cost over RSP/MSEK": 0.77547,
    "out:LCP/MSEK": -0.402475,
    "out:ROI% old": 13.529689,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 29,
    "out:Energy use kWh/m2": 29,
    "out:Energy savings %": 348.3,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1669.565295,
    "out:Return %": 12,
    "out:Return/kSEK/y": 922,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 27.707057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38624.035969,
    "out:PV (% sold (El))": 55.722981,
    "out:PV (kWh self-consumed)": 30690.338752,
    "out:PV (ratio sold/self-consumed)": 1.258508,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 318393.635779,
    "out:EL kWh savings": -51212.684905,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273904.32,
    "out:DH kr savings": 318393.63577914773,
    "out:El kr savings": -102425.36981098149,
    "out:El kr sold": 57936.053953788854,
    "out:El kr saved": 61380.677504,
    "out:El kr return": 119316.73145778885,
    "out:% solar/total": 220,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 11495.2736,
    "out:% savings (space heating)": 95.965995,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.72,
    "out:Etvv": 0,
    "out:Ef": 24.78,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7605095.002430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -71.080849,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -74.207318,
    "out:Bought CO2": 3.126469,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.540297,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.429833,
    "out:Electricity (inc PV)": 24.674939,
    "out:Total Energy Use Pre PV": 41.429833,
    "out:Total Energy Use Post PV": 30.674939,
    "out:Primary Energy": 49.700901,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 47.609373,
    "out:CO2 Operational/m2": -68.754664,
    "out:Total CO2/m2": -21.145291,
    "out:Total CO2 (tons)": -55.590957,
    "out:Klimatpaverkan": -175.730031,
    "out:Initial Cost/MSEK": 7.767050014930602,
    "out:Running cost/(Apt SEK y)": 3015.026316,
    "out:Running Cost over RSP/MSEK": 0.90438,
    "out:LCP/MSEK": -0.69334,
    "out:ROI% old": 13.010474,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 319.4,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1676.179234,
    "out:Return %": 12,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 27.830333,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38545.347315,
    "out:PV (% sold (El))": 55.609457,
    "out:PV (kWh self-consumed)": 30769.027407,
    "out:PV (ratio sold/self-consumed)": 1.252732,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 315323.471873,
    "out:EL kWh savings": -50936.703064,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271268.09,
    "out:DH kr savings": 315323.47187332856,
    "out:El kr savings": -101873.4061283228,
    "out:El kr sold": 57818.020972414844,
    "out:El kr saved": 61538.054814,
    "out:El kr return": 119356.07578641485,
    "out:% solar/total": 221,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 14565.437505,
    "out:% savings (space heating)": 94.88859,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 6.8,
    "out:Etvv": 0,
    "out:Ef": 24.67,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7767050.014930602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -70.914568,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -74.05339,
    "out:Bought CO2": 3.138822,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.984938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.802542,
    "out:Electricity (inc PV)": 24.241162,
    "out:Total Energy Use Pre PV": 38.802542,
    "out:Total Energy Use Post PV": 28.241162,
    "out:Primary Energy": 47.563465,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 47.609373,
    "out:CO2 Operational/m2": -70.323616,
    "out:Total CO2/m2": -22.714243,
    "out:Total CO2 (tons)": -59.715731,
    "out:Klimatpaverkan": -179.854805,
    "out:Initial Cost/MSEK": 7.832775002430603,
    "out:Running cost/(Apt SEK y)": 2515.657895,
    "out:Running Cost over RSP/MSEK": 0.75622,
    "out:LCP/MSEK": -0.610905,
    "out:ROI% old": 13.171522,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 28,
    "out:Energy use kWh/m2": 28,
    "out:Energy savings %": 364.3,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1642.567432,
    "out:Return %": 12,
    "out:Return/kSEK/y": 925,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 27.203042,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 38946.716389,
    "out:PV (% sold (El))": 56.188513,
    "out:PV (kWh self-consumed)": 30367.658332,
    "out:PV (ratio sold/self-consumed)": 1.282506,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319412.509756,
    "out:EL kWh savings": -49796.30359,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278239.98,
    "out:DH kr savings": 319412.5097557792,
    "out:El kr savings": -99592.60718025257,
    "out:El kr sold": 58420.07458385339,
    "out:El kr saved": 60735.316664,
    "out:El kr return": 119155.39124785339,
    "out:% solar/total": 218,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 10476.399623,
    "out:% savings (space heating)": 96.323545,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 5.05,
    "out:Etvv": 0,
    "out:Ef": 24.24,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7832775.002430603,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -71.763552,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -74.839588,
    "out:Bought CO2": 3.076036,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 93.3,
    "out:Total Energy Use Post PV": 93.3,
    "out:Primary Energy": 81.806801,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.984789,
    "out:CO2 Operational/m2": 32.378234,
    "out:Total CO2/m2": 47.363023,
    "out:Total CO2 (tons)": 124.517359,
    "out:Klimatpaverkan": 4.378286,
    "out:Initial Cost/MSEK": 2.786938053085,
    "out:Running cost/(Apt SEK y)": 19119.736842,
    "out:Running Cost over RSP/MSEK": 5.68034,
    "out:LCP/MSEK": -0.489188,
    "out:ROI% old": 11.778159,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 11,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99839.739171,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99839.74,
    "out:DH kr savings": 99839.73917083319,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2786938.0530850003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 90.3,
    "out:Total Energy Use Post PV": 90.3,
    "out:Primary Energy": 79.529523,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.984789,
    "out:CO2 Operational/m2": 31.298282,
    "out:Total CO2/m2": 46.283071,
    "out:Total CO2 (tons)": 121.678166,
    "out:Klimatpaverkan": 1.539092,
    "out:Initial Cost/MSEK": 2.8526630405850004,
    "out:Running cost/(Apt SEK y)": 18492.947368,
    "out:Running Cost over RSP/MSEK": 5.49404,
    "out:LCP/MSEK": -0.368613,
    "out:ROI% old": 12.439755,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 11,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107537.265261,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107537.27,
    "out:DH kr savings": 107537.26526140897,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2852663.0405850005,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 90.3,
    "out:Total Energy Use Post PV": 90.3,
    "out:Primary Energy": 79.181801,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.69989,
    "out:CO2 Operational/m2": 31.298282,
    "out:Total CO2/m2": 46.998172,
    "out:Total CO2 (tons)": 123.558166,
    "out:Klimatpaverkan": 3.419093,
    "out:Initial Cost/MSEK": 3.0146180530850004,
    "out:Running cost/(Apt SEK y)": 18492.947368,
    "out:Running Cost over RSP/MSEK": 5.49404,
    "out:LCP/MSEK": -0.530568,
    "out:ROI% old": 11.771451,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 11,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 106579.17914,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106579.18,
    "out:DH kr savings": 106579.1791404237,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3014618.0530850003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 87.3,
    "out:Total Energy Use Post PV": 87.3,
    "out:Primary Energy": 76.904523,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.69989,
    "out:CO2 Operational/m2": 30.21833,
    "out:Total CO2/m2": 45.91822,
    "out:Total CO2 (tons)": 120.718973,
    "out:Klimatpaverkan": 0.579899,
    "out:Initial Cost/MSEK": 3.0803430405850007,
    "out:Running cost/(Apt SEK y)": 17866.131579,
    "out:Running Cost over RSP/MSEK": 5.30774,
    "out:LCP/MSEK": -0.409993,
    "out:ROI% old": 12.384289,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 11,
    "out:Return/kSEK/y": 341,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 114276.705231,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114276.71,
    "out:DH kr savings": 114276.70523099949,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 21.25,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3080343.0405850005,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 93.3,
    "out:Total Energy Use Post PV": 91.138626,
    "out:Primary Energy": 77.916328,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.554589,
    "out:CO2 Operational/m2": -23.951813,
    "out:Total CO2/m2": 5.602776,
    "out:Total CO2 (tons)": 14.729695,
    "out:Klimatpaverkan": -105.409379,
    "out:Initial Cost/MSEK": 3.43192128959625,
    "out:Running cost/(Apt SEK y)": 17676.921053,
    "out:Running Cost over RSP/MSEK": 5.26341,
    "out:LCP/MSEK": -0.717241,
    "out:ROI% old": 11.300126,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 42.9,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 10,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99839.739171,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154666.65,
    "out:DH kr savings": 99839.73917083319,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3431921.2895962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 90.3,
    "out:Total Energy Use Post PV": 88.138626,
    "out:Primary Energy": 75.63905,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.554589,
    "out:CO2 Operational/m2": -25.031765,
    "out:Total CO2/m2": 4.522824,
    "out:Total CO2 (tons)": 11.890502,
    "out:Klimatpaverkan": -108.248572,
    "out:Initial Cost/MSEK": 3.4976462770962504,
    "out:Running cost/(Apt SEK y)": 17050.131579,
    "out:Running Cost over RSP/MSEK": 5.07711,
    "out:LCP/MSEK": -0.596666,
    "out:ROI% old": 11.848703,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 11,
    "out:Return/kSEK/y": 372,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 107537.265261,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162364.17,
    "out:DH kr savings": 107537.26526140897,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3497646.2770962506,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 90.3,
    "out:Total Energy Use Post PV": 88.138626,
    "out:Primary Energy": 75.291328,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.26969,
    "out:CO2 Operational/m2": -25.031765,
    "out:Total CO2/m2": 5.237925,
    "out:Total CO2 (tons)": 13.770502,
    "out:Klimatpaverkan": -106.368572,
    "out:Initial Cost/MSEK": 3.6596012895962504,
    "out:Running cost/(Apt SEK y)": 17050.131579,
    "out:Running Cost over RSP/MSEK": 5.07711,
    "out:LCP/MSEK": -0.758621,
    "out:ROI% old": 11.324341,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 10,
    "out:Return/kSEK/y": 372,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 106579.17914,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161406.09,
    "out:DH kr savings": 106579.1791404237,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3659601.2895962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 87.3,
    "out:Total Energy Use Post PV": 85.138626,
    "out:Primary Energy": 73.01405,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.26969,
    "out:CO2 Operational/m2": -26.111717,
    "out:Total CO2/m2": 4.157973,
    "out:Total CO2 (tons)": 10.931309,
    "out:Klimatpaverkan": -109.207765,
    "out:Initial Cost/MSEK": 3.7253262770962507,
    "out:Running cost/(Apt SEK y)": 16423.315789,
    "out:Running Cost over RSP/MSEK": 4.89081,
    "out:LCP/MSEK": -0.638046,
    "out:ROI% old": 11.838963,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 11,
    "out:Return/kSEK/y": 396,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 114276.705231,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169103.61,
    "out:DH kr savings": 114276.70523099949,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 21.25,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3725326.2770962506,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 93.3,
    "out:Total Energy Use Post PV": 91.026217,
    "out:Primary Energy": 77.713992,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.124389,
    "out:CO2 Operational/m2": -90.607017,
    "out:Total CO2/m2": -46.482628,
    "out:Total CO2 (tons)": -122.202801,
    "out:Klimatpaverkan": -242.341875,
    "out:Initial Cost/MSEK": 4.076904508645,
    "out:Running cost/(Apt SEK y)": 16305,
    "out:Running Cost over RSP/MSEK": 4.86724,
    "out:LCP/MSEK": -0.966055,
    "out:ROI% old": 10.900602,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 42.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99839.739171,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206800.19,
    "out:DH kr savings": 99839.73917083319,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4076904.508645,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 90.3,
    "out:Total Energy Use Post PV": 88.026217,
    "out:Primary Energy": 75.436714,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.124389,
    "out:CO2 Operational/m2": -91.686969,
    "out:Total CO2/m2": -47.56258,
    "out:Total CO2 (tons)": -125.041994,
    "out:Klimatpaverkan": -245.181068,
    "out:Initial Cost/MSEK": 4.142629496145001,
    "out:Running cost/(Apt SEK y)": 15678.184211,
    "out:Running Cost over RSP/MSEK": 4.68094,
    "out:LCP/MSEK": -0.845479,
    "out:ROI% old": 11.370108,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 425,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 107537.265261,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214497.71,
    "out:DH kr savings": 107537.26526140897,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4142629.496145001,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 90.3,
    "out:Total Energy Use Post PV": 88.026217,
    "out:Primary Energy": 75.088992,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.83949,
    "out:CO2 Operational/m2": -91.686969,
    "out:Total CO2/m2": -46.847479,
    "out:Total CO2 (tons)": -123.161994,
    "out:Klimatpaverkan": -243.301068,
    "out:Initial Cost/MSEK": 4.304584508645,
    "out:Running cost/(Apt SEK y)": 15678.184211,
    "out:Running Cost over RSP/MSEK": 4.68094,
    "out:LCP/MSEK": -1.007435,
    "out:ROI% old": 10.94232,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 425,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 106579.17914,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213539.63,
    "out:DH kr savings": 106579.1791404237,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4304584.508645,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 87.3,
    "out:Total Energy Use Post PV": 85.026217,
    "out:Primary Energy": 72.811714,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.83949,
    "out:CO2 Operational/m2": -92.766921,
    "out:Total CO2/m2": -47.927431,
    "out:Total CO2 (tons)": -126.001187,
    "out:Klimatpaverkan": -246.140261,
    "out:Initial Cost/MSEK": 4.370309496145,
    "out:Running cost/(Apt SEK y)": 15051.368421,
    "out:Running Cost over RSP/MSEK": 4.49464,
    "out:LCP/MSEK": -0.886859,
    "out:ROI% old": 11.386739,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 114276.705231,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221237.15,
    "out:DH kr savings": 114276.70523099949,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 21.25,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4370309.496145001,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 89.3,
    "out:Total Energy Use Post PV": 89.3,
    "out:Primary Energy": 78.306801,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.984789,
    "out:CO2 Operational/m2": 30.938298,
    "out:Total CO2/m2": 45.923087,
    "out:Total CO2 (tons)": 120.731768,
    "out:Klimatpaverkan": 0.592695,
    "out:Initial Cost/MSEK": 2.8872319280850003,
    "out:Running cost/(Apt SEK y)": 18284,
    "out:Running Cost over RSP/MSEK": 5.43194,
    "out:LCP/MSEK": -0.341082,
    "out:ROI% old": 12.598077,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 11,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108825.65913,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108825.66,
    "out:DH kr savings": 108825.6591302872,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2887231.9280850003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 86.3,
    "out:Total Energy Use Post PV": 86.3,
    "out:Primary Energy": 76.029523,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.984789,
    "out:CO2 Operational/m2": 29.858346,
    "out:Total CO2/m2": 44.843135,
    "out:Total CO2 (tons)": 117.892575,
    "out:Klimatpaverkan": -2.246498,
    "out:Initial Cost/MSEK": 2.9529569155850006,
    "out:Running cost/(Apt SEK y)": 17657.184211,
    "out:Running Cost over RSP/MSEK": 5.24564,
    "out:LCP/MSEK": -0.220507,
    "out:ROI% old": 13.218953,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 12,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116523.185221,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116523.19,
    "out:DH kr savings": 116523.185220863,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 20,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2952956.9155850005,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 87.3,
    "out:Total Energy Use Post PV": 87.3,
    "out:Primary Energy": 76.206801,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.69989,
    "out:CO2 Operational/m2": 30.21833,
    "out:Total CO2/m2": 45.91822,
    "out:Total CO2 (tons)": 120.718973,
    "out:Klimatpaverkan": 0.579899,
    "out:Initial Cost/MSEK": 3.114911928085,
    "out:Running cost/(Apt SEK y)": 17866.131579,
    "out:Running Cost over RSP/MSEK": 5.30774,
    "out:LCP/MSEK": -0.444562,
    "out:ROI% old": 12.246849,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 11,
    "out:Return/kSEK/y": 341,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 114217.211106,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114217.21,
    "out:DH kr savings": 114217.21110595959,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3114911.9280850003,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 5.3,
    "out:Total Energy Use Pre PV": 84.3,
    "out:Total Energy Use Post PV": 84.3,
    "out:Primary Energy": 73.929523,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 15.69989,
    "out:CO2 Operational/m2": 29.138378,
    "out:Total CO2/m2": 44.838268,
    "out:Total CO2 (tons)": 117.87978,
    "out:Klimatpaverkan": -2.259294,
    "out:Initial Cost/MSEK": 3.1806369155850005,
    "out:Running cost/(Apt SEK y)": 17239.315789,
    "out:Running Cost over RSP/MSEK": 5.12143,
    "out:LCP/MSEK": -0.323977,
    "out:ROI% old": 12.830584,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 371,
    "out:Return %": 11,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121914.737197,
    "out:EL kWh savings": 7.0051e-12,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121914.74,
    "out:DH kr savings": 121914.73719653538,
    "out:El kr savings": 1.4010123210894675e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 17,
    "out:Ef": 5.3,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3180636.9155850005,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 0.699642,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.699642,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 89.3,
    "out:Total Energy Use Post PV": 87.138626,
    "out:Primary Energy": 74.416328,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.554589,
    "out:CO2 Operational/m2": -25.391749,
    "out:Total CO2/m2": 4.16284,
    "out:Total CO2 (tons)": 10.944104,
    "out:Klimatpaverkan": -109.19497,
    "out:Initial Cost/MSEK": 3.5322151645962503,
    "out:Running cost/(Apt SEK y)": 16841.184211,
    "out:Running Cost over RSP/MSEK": 5.01501,
    "out:LCP/MSEK": -0.569135,
    "out:ROI% old": 11.9839,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 11,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108825.65913,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163652.57,
    "out:DH kr savings": 108825.6591302872,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3532215.1645962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 86.3,
    "out:Total Energy Use Post PV": 84.138626,
    "out:Primary Energy": 72.13905,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.554589,
    "out:CO2 Operational/m2": -26.471701,
    "out:Total CO2/m2": 3.082888,
    "out:Total CO2 (tons)": 8.104911,
    "out:Klimatpaverkan": -112.034163,
    "out:Initial Cost/MSEK": 3.5979401520962506,
    "out:Running cost/(Apt SEK y)": 16214.368421,
    "out:Running Cost over RSP/MSEK": 4.8287,
    "out:LCP/MSEK": -0.44855,
    "out:ROI% old": 12.504734,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 11,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116523.185221,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171350.09,
    "out:DH kr savings": 116523.185220863,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 20,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3597940.1520962506,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 87.3,
    "out:Total Energy Use Post PV": 85.138626,
    "out:Primary Energy": 72.316328,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.26969,
    "out:CO2 Operational/m2": -26.111717,
    "out:Total CO2/m2": 4.157973,
    "out:Total CO2 (tons)": 10.931309,
    "out:Klimatpaverkan": -109.207765,
    "out:Initial Cost/MSEK": 3.75989516459625,
    "out:Running cost/(Apt SEK y)": 16423.315789,
    "out:Running Cost over RSP/MSEK": 4.89081,
    "out:LCP/MSEK": -0.672615,
    "out:ROI% old": 11.730114,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 11,
    "out:Return/kSEK/y": 396,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 114217.211106,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169044.12,
    "out:DH kr savings": 114217.21110595959,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3759895.1645962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.138626,
    "out:Total Energy Use Pre PV": 84.3,
    "out:Total Energy Use Post PV": 82.138626,
    "out:Primary Energy": 70.03905,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.26969,
    "out:CO2 Operational/m2": -27.191669,
    "out:Total CO2/m2": 3.078021,
    "out:Total CO2 (tons)": 8.092115,
    "out:Klimatpaverkan": -112.046958,
    "out:Initial Cost/MSEK": 3.8256201520962505,
    "out:Running cost/(Apt SEK y)": 15796.5,
    "out:Running Cost over RSP/MSEK": 4.7045,
    "out:LCP/MSEK": -0.55203,
    "out:ROI% old": 12.224311,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 220.878036,
    "out:Return %": 11,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 28974.93626,
    "out:PV (% sold (El))": 83.604408,
    "out:PV (kWh self-consumed)": 5682.251101,
    "out:PV (ratio sold/self-consumed)": 5.0992,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121914.737197,
    "out:EL kWh savings": 5682.250956,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176741.64,
    "out:DH kr savings": 121914.73719653538,
    "out:El kr savings": 11364.50191131943,
    "out:El kr sold": 43462.40438935447,
    "out:El kr saved": 11364.502202,
    "out:El kr return": 54826.90659135448,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 17,
    "out:Ef": 3.14,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3825620.1520962506,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -55.630405,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.045248,
    "out:Bought CO2": 0.414843,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 89.3,
    "out:Total Energy Use Post PV": 87.026217,
    "out:Primary Energy": 74.213992,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.124389,
    "out:CO2 Operational/m2": -92.046953,
    "out:Total CO2/m2": -47.922564,
    "out:Total CO2 (tons)": -125.988392,
    "out:Klimatpaverkan": -246.127466,
    "out:Initial Cost/MSEK": 4.177198383645001,
    "out:Running cost/(Apt SEK y)": 15469.263158,
    "out:Running Cost over RSP/MSEK": 4.61884,
    "out:LCP/MSEK": -0.817948,
    "out:ROI% old": 11.48839,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108825.65913,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215786.11,
    "out:DH kr savings": 108825.6591302872,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4177198.383645001,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 86.3,
    "out:Total Energy Use Post PV": 84.026217,
    "out:Primary Energy": 71.936714,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.124389,
    "out:CO2 Operational/m2": -93.126905,
    "out:Total CO2/m2": -49.002516,
    "out:Total CO2 (tons)": -128.827585,
    "out:Klimatpaverkan": -248.966659,
    "out:Initial Cost/MSEK": 4.242923371145,
    "out:Running cost/(Apt SEK y)": 14842.447368,
    "out:Running Cost over RSP/MSEK": 4.43253,
    "out:LCP/MSEK": -0.697363,
    "out:ROI% old": 11.937726,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 11,
    "out:Return/kSEK/y": 456,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116523.185221,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223483.63,
    "out:DH kr savings": 116523.185220863,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 20,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4242923.371145001,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.414459,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 87.3,
    "out:Total Energy Use Post PV": 85.026217,
    "out:Primary Energy": 72.113992,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.83949,
    "out:CO2 Operational/m2": -92.766921,
    "out:Total CO2/m2": -47.927431,
    "out:Total CO2 (tons)": -126.001187,
    "out:Klimatpaverkan": -246.140261,
    "out:Initial Cost/MSEK": 4.404878383645,
    "out:Running cost/(Apt SEK y)": 15051.368421,
    "out:Running Cost over RSP/MSEK": 4.49464,
    "out:LCP/MSEK": -0.921428,
    "out:ROI% old": 11.297377,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 10,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 114217.211106,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221177.66,
    "out:DH kr savings": 114217.21110595959,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 185119.570411,
    "out:% savings (space heating)": 35.03649,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.41,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4404878.383645,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.486529,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 5.3,
    "out:Electricity (inc PV)": 3.026217,
    "out:Total Energy Use Pre PV": 84.3,
    "out:Total Energy Use Post PV": 82.026217,
    "out:Primary Energy": 69.836714,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.83949,
    "out:CO2 Operational/m2": -93.846873,
    "out:Total CO2/m2": -49.007383,
    "out:Total CO2 (tons)": -128.840381,
    "out:Klimatpaverkan": -248.979454,
    "out:Initial Cost/MSEK": 4.470603371145001,
    "out:Running cost/(Apt SEK y)": 14424.578947,
    "out:Running Cost over RSP/MSEK": 4.30833,
    "out:LCP/MSEK": -0.800843,
    "out:ROI% old": 11.726637,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 212.612527,
    "out:Return %": 11,
    "out:Return/kSEK/y": 472,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 63336.600371,
    "out:PV (% sold (El))": 91.375852,
    "out:PV (kWh self-consumed)": 5977.77435,
    "out:PV (ratio sold/self-consumed)": 10.595348,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121914.737197,
    "out:EL kWh savings": 5977.77415,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228875.19,
    "out:DH kr savings": 121914.73719653538,
    "out:El kr savings": 11955.548299103082,
    "out:El kr sold": 95004.90055656887,
    "out:El kr saved": 11955.5487,
    "out:El kr return": 106960.44925656887,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177422.04432,
    "out:% savings (space heating)": 37.737762,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.49,
    "out:Etvv": 17,
    "out:Ef": 3.03,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4470603.371145001,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -122.285609,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -122.685434,
    "out:Bought CO2": 0.399825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.598408,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.752849,
    "out:Electricity (inc PV)": 26.880579,
    "out:Total Energy Use Pre PV": 53.752849,
    "out:Total Energy Use Post PV": 54.880579,
    "out:Primary Energy": 73.108184,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.717878,
    "out:CO2 Operational/m2": 13.479134,
    "out:Total CO2/m2": 32.197012,
    "out:Total CO2 (tons)": 84.645925,
    "out:Klimatpaverkan": -35.493148,
    "out:Initial Cost/MSEK": 5.298297737455602,
    "out:Running cost/(Apt SEK y)": 9413.605263,
    "out:Running Cost over RSP/MSEK": 2.78565,
    "out:LCP/MSEK": -0.105858,
    "out:ROI% old": 14.000292,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1802.69943,
    "out:Return %": 13,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.153349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257332.711223,
    "out:EL kWh savings": -56735.329307,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143862.05,
    "out:DH kr savings": 257332.7112230208,
    "out:El kr savings": -113470.65861478864,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 72556.198156,
    "out:% savings (space heating)": 74.53805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 31.79,
    "out:Etvv": 0,
    "out:Ef": 26.88,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5298297.737455602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": 3.399582,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.399582,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.020987,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.646634,
    "out:Electricity (inc PV)": 26.794987,
    "out:Total Energy Use Pre PV": 50.646634,
    "out:Total Energy Use Post PV": 51.794987,
    "out:Primary Energy": 70.896528,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.717878,
    "out:CO2 Operational/m2": 12.385161,
    "out:Total CO2/m2": 31.103039,
    "out:Total CO2 (tons)": 81.769871,
    "out:Klimatpaverkan": -38.369203,
    "out:Initial Cost/MSEK": 5.3640227249556025,
    "out:Running cost/(Apt SEK y)": 8772.078947,
    "out:Running Cost over RSP/MSEK": 2.59503,
    "out:LCP/MSEK": 0.019037,
    "out:ROI% old": 14.336415,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 150,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1795.26438,
    "out:Return %": 13,
    "out:Return/kSEK/y": 687,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.047134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 264108.749493,
    "out:EL kWh savings": -56510.30799,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151088.13,
    "out:DH kr savings": 264108.74949330045,
    "out:El kr savings": -113020.6159809855,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 65780.159885,
    "out:% savings (space heating)": 76.915946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.14,
    "out:Etvv": 0,
    "out:Ef": 26.79,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5364022.724955603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": 3.385561,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.385561,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.286055,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.373713,
    "out:Electricity (inc PV)": 26.354067,
    "out:Total Energy Use Pre PV": 51.373713,
    "out:Total Energy Use Post PV": 52.354067,
    "out:Primary Energy": 70.595177,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.432979,
    "out:CO2 Operational/m2": 12.709117,
    "out:Total CO2/m2": 32.142096,
    "out:Total CO2 (tons)": 84.501551,
    "out:Klimatpaverkan": -35.637522,
    "out:Initial Cost/MSEK": 5.525977737455602,
    "out:Running cost/(Apt SEK y)": 8943.263158,
    "out:Running Cost over RSP/MSEK": 2.64604,
    "out:LCP/MSEK": -0.193928,
    "out:ROI% old": 13.784374,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 150,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1776.15991,
    "out:Return %": 12,
    "out:Return/kSEK/y": 680,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 17.774213,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 260782.886477,
    "out:EL kWh savings": -55351.129574,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150080.63,
    "out:DH kr savings": 260782.88647654603,
    "out:El kr savings": -110702.259147444,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 69106.022902,
    "out:% savings (space heating)": 75.74881,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.77,
    "out:Etvv": 0,
    "out:Ef": 26.35,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5525977.737455602,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": 3.349533,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.349533,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.734919,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.259533,
    "out:Electricity (inc PV)": 26.258657,
    "out:Total Energy Use Pre PV": 49.259533,
    "out:Total Energy Use Post PV": 50.258657,
    "out:Primary Energy": 68.391046,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.432979,
    "out:CO2 Operational/m2": 11.974076,
    "out:Total CO2/m2": 31.407055,
    "out:Total CO2 (tons)": 82.569129,
    "out:Klimatpaverkan": -37.569945,
    "out:Initial Cost/MSEK": 5.591702724955603,
    "out:Running cost/(Apt SEK y)": 8509.578947,
    "out:Running Cost over RSP/MSEK": 2.51719,
    "out:LCP/MSEK": -0.130803,
    "out:ROI% old": 13.951539,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 160,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 1768.16731,
    "out:Return %": 12,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 17.660033,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 267489.821498,
    "out:EL kWh savings": -55100.296741,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157289.23,
    "out:DH kr savings": 267489.82149751787,
    "out:El kr savings": -110200.59348136352,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 62399.087881,
    "out:% savings (space heating)": 78.102457,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 27.16,
    "out:Etvv": 0,
    "out:Ef": 26.26,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5591702.724955603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": 3.33446,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.33446,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.598408,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.752849,
    "out:Electricity (inc PV)": 19.081393,
    "out:Total Energy Use Pre PV": 53.752849,
    "out:Total Energy Use Post PV": 47.081393,
    "out:Primary Energy": 59.069649,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.287678,
    "out:CO2 Operational/m2": -15.652751,
    "out:Total CO2/m2": 17.634927,
    "out:Total CO2 (tons)": 46.362213,
    "out:Klimatpaverkan": -73.776861,
    "out:Initial Cost/MSEK": 5.943280973966853,
    "out:Running cost/(Apt SEK y)": 7783.210526,
    "out:Running Cost over RSP/MSEK": 2.31403,
    "out:LCP/MSEK": -0.279221,
    "out:ROI% old": 13.614558,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 176.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1284.376568,
    "out:Return %": 12,
    "out:Return/kSEK/y": 725,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.153349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14718.561948,
    "out:PV (% sold (El))": 42.469003,
    "out:PV (kWh self-consumed)": 19938.625412,
    "out:PV (ratio sold/self-consumed)": 0.738193,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257332.711223,
    "out:EL kWh savings": -36231.27397,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206948.01,
    "out:DH kr savings": 257332.7112230208,
    "out:El kr savings": -72462.54793901677,
    "out:El kr sold": 22077.842922540003,
    "out:El kr saved": 39877.250824,
    "out:El kr return": 61955.093746540006,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 72556.198156,
    "out:% savings (space heating)": 74.53805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 31.79,
    "out:Etvv": 0,
    "out:Ef": 19.08,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5943280.973966853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": -25.732303,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.136381,
    "out:Bought CO2": 2.404078,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.020987,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.646634,
    "out:Electricity (inc PV)": 19.011989,
    "out:Total Energy Use Pre PV": 50.646634,
    "out:Total Energy Use Post PV": 44.011989,
    "out:Primary Energy": 56.887131,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.287678,
    "out:CO2 Operational/m2": -16.84946,
    "out:Total CO2/m2": 16.438218,
    "out:Total CO2 (tons)": 43.216065,
    "out:Klimatpaverkan": -76.923008,
    "out:Initial Cost/MSEK": 6.009005961466853,
    "out:Running cost/(Apt SEK y)": 7142.394737,
    "out:Running Cost over RSP/MSEK": 2.12362,
    "out:LCP/MSEK": -0.154536,
    "out:ROI% old": 13.918323,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 195.5,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1278.320673,
    "out:Return %": 12,
    "out:Return/kSEK/y": 749,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.047134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14772.562569,
    "out:PV (% sold (El))": 42.624817,
    "out:PV (kWh self-consumed)": 19884.624792,
    "out:PV (ratio sold/self-consumed)": 0.742914,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 264108.749493,
    "out:EL kWh savings": -36048.810895,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214169.97,
    "out:DH kr savings": 264108.74949330045,
    "out:El kr savings": -72097.62178988516,
    "out:El kr sold": 22158.843853174105,
    "out:El kr saved": 39769.249584,
    "out:El kr return": 61928.0934371741,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 65780.159885,
    "out:% savings (space heating)": 76.915946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.14,
    "out:Etvv": 0,
    "out:Ef": 19.01,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6009005.961466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -25.84906,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.241802,
    "out:Bought CO2": 2.392742,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.286055,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.373713,
    "out:Electricity (inc PV)": 18.654721,
    "out:Total Energy Use Pre PV": 51.373713,
    "out:Total Energy Use Post PV": 44.654721,
    "out:Primary Energy": 56.736354,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.002779,
    "out:CO2 Operational/m2": -16.790599,
    "out:Total CO2/m2": 17.21218,
    "out:Total CO2 (tons)": 45.250811,
    "out:Klimatpaverkan": -74.888263,
    "out:Initial Cost/MSEK": 6.170960973966853,
    "out:Running cost/(Apt SEK y)": 7315.421053,
    "out:Running Cost over RSP/MSEK": 2.17516,
    "out:LCP/MSEK": -0.368031,
    "out:ROI% old": 13.433726,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 188.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1262.777545,
    "out:Return %": 12,
    "out:Return/kSEK/y": 742,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 17.774213,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14911.907695,
    "out:PV (% sold (El))": 43.026884,
    "out:PV (kWh self-consumed)": 19745.279666,
    "out:PV (ratio sold/self-consumed)": 0.755214,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 260782.886477,
    "out:EL kWh savings": -35109.553536,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212931.64,
    "out:DH kr savings": 260782.88647654603,
    "out:El kr savings": -70219.10707246308,
    "out:El kr sold": 22367.861541977316,
    "out:El kr saved": 39490.559332,
    "out:El kr return": 61858.42087397732,
    "out:% solar/total": 142,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 69106.022902,
    "out:% savings (space heating)": 75.74881,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.77,
    "out:Etvv": 0,
    "out:Ef": 18.65,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6170960.973966853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": -26.150183,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.513824,
    "out:Bought CO2": 2.363641,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.734919,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.259533,
    "out:Electricity (inc PV)": 18.577485,
    "out:Total Energy Use Pre PV": 49.259533,
    "out:Total Energy Use Post PV": 42.577485,
    "out:Primary Energy": 54.564936,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 34.002779,
    "out:CO2 Operational/m2": -17.637069,
    "out:Total CO2/m2": 16.36571,
    "out:Total CO2 (tons)": 43.025442,
    "out:Klimatpaverkan": -77.113632,
    "out:Initial Cost/MSEK": 6.236685961466852,
    "out:Running cost/(Apt SEK y)": 6882.5,
    "out:Running Cost over RSP/MSEK": 2.04654,
    "out:LCP/MSEK": -0.305136,
    "out:ROI% old": 13.586772,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 1256.282425,
    "out:Return %": 12,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 17.660033,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14970.489851,
    "out:PV (% sold (El))": 43.195917,
    "out:PV (kWh self-consumed)": 19686.69751,
    "out:PV (ratio sold/self-consumed)": 0.760437,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267489.821498,
    "out:EL kWh savings": -34906.500138,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220132.56,
    "out:DH kr savings": 267489.82149751787,
    "out:El kr savings": -69813.00027668288,
    "out:El kr sold": 22455.734776182493,
    "out:El kr saved": 39373.39502,
    "out:El kr return": 61829.1297961825,
    "out:% solar/total": 141,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 62399.087881,
    "out:% savings (space heating)": 78.102457,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 27.16,
    "out:Etvv": 0,
    "out:Ef": 18.58,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6236685.961466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": -26.276685,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.628166,
    "out:Bought CO2": 2.351481,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.598408,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.752849,
    "out:Electricity (inc PV)": 17.377449,
    "out:Total Energy Use Pre PV": 53.752849,
    "out:Total Energy Use Post PV": 45.377449,
    "out:Primary Energy": 56.00255,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 47.857478,
    "out:CO2 Operational/m2": -74.71933,
    "out:Total CO2/m2": -26.861852,
    "out:Total CO2 (tons)": -70.619793,
    "out:Klimatpaverkan": -190.758866,
    "out:Initial Cost/MSEK": 6.588264193015601,
    "out:Running cost/(Apt SEK y)": 6359.605263,
    "out:Running Cost over RSP/MSEK": 1.9026,
    "out:LCP/MSEK": -0.512774,
    "out:ROI% old": 13.173836,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 188.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1169.26233,
    "out:Return %": 12,
    "out:Return/kSEK/y": 779,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.153349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45154.910368,
    "out:PV (% sold (El))": 65.145088,
    "out:PV (kWh self-consumed)": 24159.464354,
    "out:PV (ratio sold/self-consumed)": 1.869036,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257332.711223,
    "out:EL kWh savings": -31751.606211,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 261561.86,
    "out:DH kr savings": 257332.7112230208,
    "out:El kr savings": -63503.21242152588,
    "out:El kr sold": 67732.36555155183,
    "out:El kr saved": 48318.928708,
    "out:El kr return": 116051.29425955183,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 72556.198156,
    "out:% savings (space heating)": 74.53805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 31.79,
    "out:Etvv": 0,
    "out:Ef": 17.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6588264.1930156015,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": -84.798882,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -86.989692,
    "out:Bought CO2": 2.19081,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.020987,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.646634,
    "out:Electricity (inc PV)": 17.315432,
    "out:Total Energy Use Pre PV": 50.646634,
    "out:Total Energy Use Post PV": 42.315432,
    "out:Primary Energy": 53.833329,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 47.857478,
    "out:CO2 Operational/m2": -75.963071,
    "out:Total CO2/m2": -28.105593,
    "out:Total CO2 (tons)": -73.889587,
    "out:Klimatpaverkan": -194.028661,
    "out:Initial Cost/MSEK": 6.653989180515603,
    "out:Running cost/(Apt SEK y)": 5719.131579,
    "out:Running Cost over RSP/MSEK": 1.71228,
    "out:LCP/MSEK": -0.388179,
    "out:ROI% old": 13.452317,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 209.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1163.850534,
    "out:Return %": 12,
    "out:Return/kSEK/y": 803,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 18.047134,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45233.595574,
    "out:PV (% sold (El))": 65.258607,
    "out:PV (kWh self-consumed)": 24080.779147,
    "out:PV (ratio sold/self-consumed)": 1.878411,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 264108.749493,
    "out:EL kWh savings": -31588.563555,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268782.02,
    "out:DH kr savings": 264108.74949330045,
    "out:El kr savings": -63177.127109574154,
    "out:El kr sold": 67850.39336128673,
    "out:El kr saved": 48161.558294,
    "out:El kr return": 116011.95165528673,
    "out:% solar/total": 173,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 65780.159885,
    "out:% savings (space heating)": 76.915946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.14,
    "out:Etvv": 0,
    "out:Ef": 17.32,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6653989.180515602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -84.962671,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.143373,
    "out:Bought CO2": 2.180702,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.286055,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.373713,
    "out:Electricity (inc PV)": 16.996442,
    "out:Total Energy Use Pre PV": 51.373713,
    "out:Total Energy Use Post PV": 42.996442,
    "out:Primary Energy": 53.751452,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.572579,
    "out:CO2 Operational/m2": -76.025619,
    "out:Total CO2/m2": -27.45304,
    "out:Total CO2 (tons)": -72.174026,
    "out:Klimatpaverkan": -192.313099,
    "out:Initial Cost/MSEK": 6.815944193015602,
    "out:Running cost/(Apt SEK y)": 5893,
    "out:Running Cost over RSP/MSEK": 1.76406,
    "out:LCP/MSEK": -0.601914,
    "out:ROI% old": 13.024147,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1149.964596,
    "out:Return %": 12,
    "out:Return/kSEK/y": 796,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 17.774213,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45436.732664,
    "out:PV (% sold (El))": 65.551674,
    "out:PV (kWh self-consumed)": 23877.642058,
    "out:PV (ratio sold/self-consumed)": 1.902899,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 260782.886477,
    "out:EL kWh savings": -30749.939035,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267438.11,
    "out:DH kr savings": 260782.88647654603,
    "out:El kr savings": -61499.878070448256,
    "out:El kr sold": 68155.09899570377,
    "out:El kr saved": 47755.284116,
    "out:El kr return": 115910.38311170376,
    "out:% solar/total": 171,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 69106.022902,
    "out:% savings (space heating)": 75.74881,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.77,
    "out:Etvv": 0,
    "out:Ef": 17,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6815944.1930156015,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": -85.385203,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.539977,
    "out:Bought CO2": 2.154774,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.734919,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.259533,
    "out:Electricity (inc PV)": 16.927535,
    "out:Total Energy Use Pre PV": 49.259533,
    "out:Total Energy Use Post PV": 40.927535,
    "out:Primary Energy": 51.595026,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.572579,
    "out:CO2 Operational/m2": -76.923074,
    "out:Total CO2/m2": -28.350495,
    "out:Total CO2 (tons)": -74.533434,
    "out:Klimatpaverkan": -194.672508,
    "out:Initial Cost/MSEK": 6.881669180515602,
    "out:Running cost/(Apt SEK y)": 5460.447368,
    "out:Running Cost over RSP/MSEK": 1.63554,
    "out:LCP/MSEK": -0.539119,
    "out:ROI% old": 13.166552,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 217.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 1144.163735,
    "out:Return %": 12,
    "out:Return/kSEK/y": 813,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 17.660033,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45522.046595,
    "out:PV (% sold (El))": 65.674756,
    "out:PV (kWh self-consumed)": 23792.328126,
    "out:PV (ratio sold/self-consumed)": 1.913308,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267489.821498,
    "out:EL kWh savings": -30568.782573,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274635.33,
    "out:DH kr savings": 267489.82149751787,
    "out:El kr savings": -61137.56514672316,
    "out:El kr sold": 68283.06989270543,
    "out:El kr saved": 47584.656252,
    "out:El kr return": 115867.72614470543,
    "out:% solar/total": 171,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 62399.087881,
    "out:% savings (space heating)": 78.102457,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 27.16,
    "out:Etvv": 0,
    "out:Ef": 16.93,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6881669.180515602,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": -85.56269,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.706636,
    "out:Bought CO2": 2.143946,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.853769,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.24577,
    "out:Electricity (inc PV)": 26.175121,
    "out:Total Energy Use Pre PV": 51.24577,
    "out:Total Energy Use Post PV": 52.175121,
    "out:Primary Energy": 69.760148,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.717878,
    "out:CO2 Operational/m2": 12.692228,
    "out:Total CO2/m2": 31.410106,
    "out:Total CO2 (tons)": 82.57715,
    "out:Klimatpaverkan": -37.561924,
    "out:Initial Cost/MSEK": 5.398591612455602,
    "out:Running cost/(Apt SEK y)": 8925.552632,
    "out:Running Cost over RSP/MSEK": 2.64084,
    "out:LCP/MSEK": -0.061342,
    "out:ROI% old": 14.123393,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 150,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1767.2039,
    "out:Return %": 13,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.64627,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 261919.366112,
    "out:EL kWh savings": -54880.680647,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152158,
    "out:DH kr savings": 261919.3661124713,
    "out:El kr savings": -109761.36129310557,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 67969.543266,
    "out:% savings (space heating)": 76.147632,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.11,
    "out:Etvv": 0,
    "out:Ef": 26.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5398591.612455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": 3.332644,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.332644,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.312412,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.128626,
    "out:Electricity (inc PV)": 26.076695,
    "out:Total Energy Use Pre PV": 48.128626,
    "out:Total Energy Use Post PV": 49.076695,
    "out:Primary Energy": 67.558329,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.717878,
    "out:CO2 Operational/m2": 11.596812,
    "out:Total CO2/m2": 30.31469,
    "out:Total CO2 (tons)": 79.697302,
    "out:Klimatpaverkan": -40.441772,
    "out:Initial Cost/MSEK": 5.464316599955604,
    "out:Running cost/(Apt SEK y)": 8282.552632,
    "out:Running Cost over RSP/MSEK": 2.44977,
    "out:LCP/MSEK": 0.064003,
    "out:ROI% old": 14.453042,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 165.3,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 1759.00382,
    "out:Return %": 13,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.529126,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 268600.592148,
    "out:EL kWh savings": -54621.918751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159356.75,
    "out:DH kr savings": 268600.59214828117,
    "out:El kr savings": -109243.83750262616,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 61288.31723,
    "out:% savings (space heating)": 78.492257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 26.51,
    "out:Etvv": 0,
    "out:Ef": 26.08,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5464316.5999556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": 3.31718,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.31718,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.82806,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 24.935136,
    "out:Electricity (inc PV)": 25.739665,
    "out:Total Energy Use Pre PV": 49.935136,
    "out:Total Energy Use Post PV": 50.739665,
    "out:Primary Energy": 67.760665,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.432979,
    "out:CO2 Operational/m2": 12.291237,
    "out:Total CO2/m2": 31.724216,
    "out:Total CO2 (tons)": 83.402945,
    "out:Klimatpaverkan": -36.736129,
    "out:Initial Cost/MSEK": 5.626271612455603,
    "out:Running cost/(Apt SEK y)": 8673.631579,
    "out:Running Cost over RSP/MSEK": 2.56611,
    "out:LCP/MSEK": -0.214292,
    "out:ROI% old": 13.741605,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 154.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1745.45952,
    "out:Return %": 12,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.335636,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 264615.954461,
    "out:EL kWh savings": -53735.867083,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157144.22,
    "out:DH kr savings": 264615.95446112304,
    "out:El kr savings": -107471.73416503999,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 65272.954918,
    "out:% savings (space heating)": 77.093938,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 27.55,
    "out:Etvv": 0,
    "out:Ef": 25.74,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5626271.612455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": 3.291637,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.291637,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.312848,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 24.81007,
    "out:Electricity (inc PV)": 25.633191,
    "out:Total Energy Use Pre PV": 46.81007,
    "out:Total Energy Use Post PV": 47.633191,
    "out:Primary Energy": 65.565014,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.432979,
    "out:CO2 Operational/m2": 11.194776,
    "out:Total CO2/m2": 30.627755,
    "out:Total CO2 (tons)": 80.52035,
    "out:Klimatpaverkan": -39.618724,
    "out:Initial Cost/MSEK": 5.691996599955604,
    "out:Running cost/(Apt SEK y)": 8029.526316,
    "out:Running Cost over RSP/MSEK": 2.37472,
    "out:LCP/MSEK": -0.088627,
    "out:ROI% old": 14.06328,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 170.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 1736.7049,
    "out:Return %": 13,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.21057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 271228.445308,
    "out:EL kWh savings": -53455.947,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164316.55,
    "out:DH kr savings": 271228.44530754146,
    "out:El kr savings": -106911.89400016992,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 58660.464071,
    "out:% savings (space heating)": 79.414442,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 24.98,
    "out:Etvv": 0,
    "out:Ef": 25.63,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5691996.5999556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": 3.275128,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.275128,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.853769,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.24577,
    "out:Electricity (inc PV)": 18.509891,
    "out:Total Energy Use Pre PV": 51.24577,
    "out:Total Energy Use Post PV": 44.509891,
    "out:Primary Energy": 55.962734,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.287678,
    "out:CO2 Operational/m2": -16.93237,
    "out:Total CO2/m2": 16.355308,
    "out:Total CO2 (tons)": 42.998095,
    "out:Klimatpaverkan": -77.140979,
    "out:Initial Cost/MSEK": 6.043574848966852,
    "out:Running cost/(Apt SEK y)": 7298.578947,
    "out:Running Cost over RSP/MSEK": 2.17021,
    "out:LCP/MSEK": -0.235695,
    "out:ROI% old": 13.728582,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 188.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1255.499833,
    "out:Return %": 12,
    "out:Return/kSEK/y": 743,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.64627,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 14977.565346,
    "out:PV (% sold (El))": 43.216333,
    "out:PV (kWh self-consumed)": 19679.622015,
    "out:PV (ratio sold/self-consumed)": 0.76107,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 261919.366112,
    "out:EL kWh savings": -34728.795553,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214928.12,
    "out:DH kr savings": 261919.3661124713,
    "out:El kr savings": -69457.59110539015,
    "out:El kr sold": 22466.34801931196,
    "out:El kr saved": 39359.24403,
    "out:El kr return": 61825.59204931196,
    "out:% solar/total": 141,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 67969.543266,
    "out:% savings (space heating)": 76.147632,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.11,
    "out:Etvv": 0,
    "out:Ef": 18.51,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6043574.848966852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": -26.291954,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.641969,
    "out:Bought CO2": 2.350015,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.312412,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.128626,
    "out:Electricity (inc PV)": 18.430268,
    "out:Total Energy Use Pre PV": 48.128626,
    "out:Total Energy Use Post PV": 41.430268,
    "out:Primary Energy": 53.79476,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.287678,
    "out:CO2 Operational/m2": -18.142481,
    "out:Total CO2/m2": 15.145197,
    "out:Total CO2 (tons)": 39.816714,
    "out:Klimatpaverkan": -80.32236,
    "out:Initial Cost/MSEK": 6.109299836466852,
    "out:Running cost/(Apt SEK y)": 6656.342105,
    "out:Running Cost over RSP/MSEK": 1.97938,
    "out:LCP/MSEK": -0.11059,
    "out:ROI% old": 14.027116,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 217.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 1248.841416,
    "out:Return %": 13,
    "out:Return/kSEK/y": 767,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.529126,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 15037.858041,
    "out:PV (% sold (El))": 43.390301,
    "out:PV (kWh self-consumed)": 19619.32932,
    "out:PV (ratio sold/self-consumed)": 0.766482,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 268600.592148,
    "out:EL kWh savings": -34519.466733,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222118.45,
    "out:DH kr savings": 268600.59214828117,
    "out:El kr savings": -69038.93346645999,
    "out:El kr sold": 22556.78706166107,
    "out:El kr saved": 39238.65864,
    "out:El kr return": 61795.44570166107,
    "out:% solar/total": 141,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 61288.31723,
    "out:% savings (space heating)": 78.492257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 26.51,
    "out:Etvv": 0,
    "out:Ef": 18.43,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6109299.836466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": -26.422113,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.759662,
    "out:Bought CO2": 2.337549,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.82806,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 24.935136,
    "out:Electricity (inc PV)": 18.1581,
    "out:Total Energy Use Pre PV": 49.935136,
    "out:Total Energy Use Post PV": 43.1581,
    "out:Primary Energy": 54.113848,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 34.002779,
    "out:CO2 Operational/m2": -17.63823,
    "out:Total CO2/m2": 16.364549,
    "out:Total CO2 (tons)": 43.02239,
    "out:Klimatpaverkan": -77.116684,
    "out:Initial Cost/MSEK": 6.271254848966852,
    "out:Running cost/(Apt SEK y)": 7048.763158,
    "out:Running Cost over RSP/MSEK": 2.0961,
    "out:LCP/MSEK": -0.389265,
    "out:ROI% old": 13.398982,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1237.8539,
    "out:Return %": 12,
    "out:Return/kSEK/y": 752,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.335636,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 15137.762587,
    "out:PV (% sold (El))": 43.678566,
    "out:PV (kWh self-consumed)": 19519.424774,
    "out:PV (ratio sold/self-consumed)": 0.775523,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 264615.954461,
    "out:EL kWh savings": -33803.937224,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219714.72,
    "out:DH kr savings": 264615.95446112304,
    "out:El kr savings": -67607.87444742862,
    "out:El kr sold": 22706.643880020223,
    "out:El kr saved": 39038.849548,
    "out:El kr return": 61745.49342802022,
    "out:% solar/total": 140,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 65272.954918,
    "out:% savings (space heating)": 77.093938,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 27.55,
    "out:Etvv": 0,
    "out:Ef": 18.16,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6271254.848966852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -26.63783,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -28.954806,
    "out:Bought CO2": 2.316976,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.312848,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 24.81007,
    "out:Electricity (inc PV)": 18.072221,
    "out:Total Energy Use Pre PV": 46.81007,
    "out:Total Energy Use Post PV": 40.072221,
    "out:Primary Energy": 51.955268,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 34.002779,
    "out:CO2 Operational/m2": -18.858059,
    "out:Total CO2/m2": 15.14472,
    "out:Total CO2 (tons)": 39.81546,
    "out:Klimatpaverkan": -80.323614,
    "out:Initial Cost/MSEK": 6.336979836466853,
    "out:Running cost/(Apt SEK y)": 6405.5,
    "out:Running Cost over RSP/MSEK": 1.90496,
    "out:LCP/MSEK": -0.26385,
    "out:ROI% old": 13.690907,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 225,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 1230.758463,
    "out:Return %": 12,
    "out:Return/kSEK/y": 777,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.21057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 15202.523998,
    "out:PV (% sold (El))": 43.865429,
    "out:PV (kWh self-consumed)": 19454.663362,
    "out:PV (ratio sold/self-consumed)": 0.781433,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 271228.445308,
    "out:EL kWh savings": -33578.161384,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226875.91,
    "out:DH kr savings": 271228.44530754146,
    "out:El kr savings": -67156.32276796811,
    "out:El kr sold": 22803.785997442617,
    "out:El kr saved": 38909.326724,
    "out:El kr return": 61713.112721442616,
    "out:% solar/total": 140,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 58660.464071,
    "out:% savings (space heating)": 79.414442,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 24.98,
    "out:Etvv": 0,
    "out:Ef": 18.07,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6336979.836466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": -26.777707,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -29.081397,
    "out:Bought CO2": 2.30369,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.853769,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.24577,
    "out:Electricity (inc PV)": 16.867252,
    "out:Total Energy Use Pre PV": 51.24577,
    "out:Total Energy Use Post PV": 42.867252,
    "out:Primary Energy": 53.005984,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 47.857478,
    "out:CO2 Operational/m2": -76.224531,
    "out:Total CO2/m2": -28.367053,
    "out:Total CO2 (tons)": -74.576965,
    "out:Klimatpaverkan": -194.716039,
    "out:Initial Cost/MSEK": 6.688558068015602,
    "out:Running cost/(Apt SEK y)": 5876.552632,
    "out:Running Cost over RSP/MSEK": 1.75923,
    "out:LCP/MSEK": -0.469698,
    "out:ROI% old": 13.282513,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 202.3,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1143.464866,
    "out:Return %": 12,
    "out:Return/kSEK/y": 797,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.64627,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45532.352118,
    "out:PV (% sold (El))": 65.689624,
    "out:PV (kWh self-consumed)": 23782.022603,
    "out:PV (ratio sold/self-consumed)": 1.91457,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 261919.366112,
    "out:EL kWh savings": -30410.298602,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 269397.3,
    "out:DH kr savings": 261919.3661124713,
    "out:El kr savings": -60820.59720470111,
    "out:El kr sold": 68298.52817735693,
    "out:El kr saved": 47564.045206,
    "out:El kr return": 115862.57338335694,
    "out:% solar/total": 171,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 67969.543266,
    "out:% savings (space heating)": 76.147632,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 29.11,
    "out:Etvv": 0,
    "out:Ef": 16.87,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6688558.0680156015,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": -85.584115,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.726756,
    "out:Bought CO2": 2.142641,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.312412,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 25.128626,
    "out:Electricity (inc PV)": 16.796283,
    "out:Total Energy Use Pre PV": 48.128626,
    "out:Total Energy Use Post PV": 39.796283,
    "out:Primary Energy": 50.853587,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 47.857478,
    "out:CO2 Operational/m2": -77.487089,
    "out:Total CO2/m2": -29.629611,
    "out:Total CO2 (tons)": -77.89623,
    "out:Klimatpaverkan": -198.035303,
    "out:Initial Cost/MSEK": 6.754283055515601,
    "out:Running cost/(Apt SEK y)": 5234.684211,
    "out:Running Cost over RSP/MSEK": 1.5685,
    "out:LCP/MSEK": -0.344693,
    "out:ROI% old": 13.556668,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 225,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 1137.519383,
    "out:Return %": 12,
    "out:Return/kSEK/y": 821,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 17.529126,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45620.156233,
    "out:PV (% sold (El))": 65.816299,
    "out:PV (kWh self-consumed)": 23694.218489,
    "out:PV (ratio sold/self-consumed)": 1.925371,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 268600.592148,
    "out:EL kWh savings": -30223.721144,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276583.38,
    "out:DH kr savings": 268600.59214828117,
    "out:El kr savings": -60447.442287438105,
    "out:El kr sold": 68430.23434925659,
    "out:El kr saved": 47388.436978,
    "out:El kr return": 115818.67132725658,
    "out:% solar/total": 170,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 61288.31723,
    "out:% savings (space heating)": 78.492257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 26.51,
    "out:Etvv": 0,
    "out:Ef": 16.8,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6754283.055515601,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": -85.766721,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -87.898266,
    "out:Bought CO2": 2.131545,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.82806,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 24.935136,
    "out:Electricity (inc PV)": 16.553772,
    "out:Total Energy Use Pre PV": 49.935136,
    "out:Total Energy Use Post PV": 41.553772,
    "out:Primary Energy": 51.226058,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.572579,
    "out:CO2 Operational/m2": -77.069761,
    "out:Total CO2/m2": -28.497182,
    "out:Total CO2 (tons)": -74.919074,
    "out:Klimatpaverkan": -195.058148,
    "out:Initial Cost/MSEK": 6.916238068015602,
    "out:Running cost/(Apt SEK y)": 5627.684211,
    "out:Running Cost over RSP/MSEK": 1.68539,
    "out:LCP/MSEK": -0.623538,
    "out:ROI% old": 12.997776,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 209.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1127.710965,
    "out:Return %": 12,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.335636,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45765.742662,
    "out:PV (% sold (El))": 66.026337,
    "out:PV (kWh self-consumed)": 23548.632059,
    "out:PV (ratio sold/self-consumed)": 1.943457,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 264615.954461,
    "out:EL kWh savings": -29586.159869,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274092.25,
    "out:DH kr savings": 264615.95446112304,
    "out:El kr savings": -59172.3197389962,
    "out:El kr sold": 68648.61399355787,
    "out:El kr saved": 47097.264118,
    "out:El kr return": 115745.87811155786,
    "out:% solar/total": 169,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 65272.954918,
    "out:% savings (space heating)": 77.093938,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 27.55,
    "out:Etvv": 0,
    "out:Ef": 16.55,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6916238.0680156015,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -86.069361,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.182605,
    "out:Bought CO2": 2.113244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.312848,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 24.81007,
    "out:Electricity (inc PV)": 16.477309,
    "out:Total Energy Use Pre PV": 46.81007,
    "out:Total Energy Use Post PV": 38.477309,
    "out:Primary Energy": 49.084426,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.572579,
    "out:CO2 Operational/m2": -78.346005,
    "out:Total CO2/m2": -29.773426,
    "out:Total CO2 (tons)": -78.274319,
    "out:Klimatpaverkan": -198.413393,
    "out:Initial Cost/MSEK": 6.981963055515601,
    "out:Running cost/(Apt SEK y)": 4984.815789,
    "out:Running Cost over RSP/MSEK": 1.49437,
    "out:LCP/MSEK": -0.498243,
    "out:ROI% old": 13.266265,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 242.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 1121.379197,
    "out:Return %": 12,
    "out:Return/kSEK/y": 831,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.89314860734,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 17.21057,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 45860.199109,
    "out:PV (% sold (El))": 66.16261,
    "out:PV (kWh self-consumed)": 23454.175613,
    "out:PV (ratio sold/self-consumed)": 1.955311,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 271228.445308,
    "out:EL kWh savings": -29385.138688,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281248.47,
    "out:DH kr savings": 271228.44530754146,
    "out:El kr savings": -58770.277376293074,
    "out:El kr sold": 68790.29866281067,
    "out:El kr saved": 46908.351226,
    "out:El kr return": 115698.64988881067,
    "out:% solar/total": 168,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 58660.464071,
    "out:% savings (space heating)": 79.414442,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 24.98,
    "out:Etvv": 0,
    "out:Ef": 16.48,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6981963.055515601,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": -86.265653,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.367085,
    "out:Bought CO2": 2.101432,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.509842,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 33.658475,
    "out:Electricity (inc PV)": 35.77136,
    "out:Total Energy Use Pre PV": 35.658475,
    "out:Total Energy Use Post PV": 37.77136,
    "out:Primary Energy": 66.29192,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.156889,
    "out:CO2 Operational/m2": 5.163156,
    "out:Total CO2/m2": 24.320045,
    "out:Total CO2 (tons)": 63.937384,
    "out:Klimatpaverkan": -56.20169,
    "out:Initial Cost/MSEK": 6.359047737455603,
    "out:Running cost/(Apt SEK y)": 5075.131579,
    "out:Running Cost over RSP/MSEK": 1.49237,
    "out:LCP/MSEK": 0.126672,
    "out:ROI% old": 14.570286,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 242.1,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2356.09325,
    "out:Return %": 13,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 26.058975,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 325919.535662,
    "out:EL kWh savings": -80109.187249,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165701.16,
    "out:DH kr savings": 325919.53566214687,
    "out:El kr savings": -160218.3744971961,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3969.373717,
    "out:% savings (space heating)": 98.607038,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 2.45,
    "out:Etvv": 0,
    "out:Ef": 35.77,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6359047.737455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": 4.443188,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.443188,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.947581,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.941606,
    "out:Electricity (inc PV)": 35.137814,
    "out:Total Energy Use Pre PV": 33.941606,
    "out:Total Energy Use Post PV": 36.137814,
    "out:Primary Energy": 64.500359,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.156889,
    "out:CO2 Operational/m2": 4.70854,
    "out:Total CO2/m2": 23.865429,
    "out:Total CO2 (tons)": 62.742199,
    "out:Klimatpaverkan": -57.396875,
    "out:Initial Cost/MSEK": 6.424772724955604,
    "out:Running cost/(Apt SEK y)": 4767.026316,
    "out:Running Cost over RSP/MSEK": 1.40113,
    "out:LCP/MSEK": 0.152187,
    "out:ROI% old": 14.624108,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 261.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2305.91242,
    "out:Return %": 13,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 25.342106,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 327397.719495,
    "out:EL kWh savings": -78443.595193,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170510.53,
    "out:DH kr savings": 327397.7194954771,
    "out:El kr savings": -156887.19038565006,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2491.189883,
    "out:% savings (space heating)": 99.125773,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.61,
    "out:Etvv": 0,
    "out:Ef": 35.14,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6424772.7249556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.348556,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.348556,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.28302,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.95039,
    "out:Electricity (inc PV)": 34.78389,
    "out:Total Energy Use Pre PV": 33.95039,
    "out:Total Energy Use Post PV": 35.78389,
    "out:Primary Energy": 64.132313,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.87199,
    "out:CO2 Operational/m2": 4.709699,
    "out:Total CO2/m2": 24.581689,
    "out:Total CO2 (tons)": 64.625246,
    "out:Klimatpaverkan": -55.513828,
    "out:Initial Cost/MSEK": 6.586727737455603,
    "out:Running cost/(Apt SEK y)": 4768.236842,
    "out:Running Cost over RSP/MSEK": 1.40149,
    "out:LCP/MSEK": -0.010128,
    "out:ROI% old": 14.263749,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 261.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2306.5273,
    "out:Return %": 13,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 25.35089,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 326515.850565,
    "out:EL kWh savings": -77513.129208,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171489.59,
    "out:DH kr savings": 326515.8505647342,
    "out:El kr savings": -155026.25841623533,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3373.058814,
    "out:% savings (space heating)": 98.816302,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.96,
    "out:Etvv": 0,
    "out:Ef": 34.78,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6586727.737455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.349715,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.349715,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.793039,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.211618,
    "out:Electricity (inc PV)": 34.108488,
    "out:Total Energy Use Pre PV": 33.211618,
    "out:Total Energy Use Post PV": 35.108488,
    "out:Primary Energy": 62.372841,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.87199,
    "out:CO2 Operational/m2": 4.612175,
    "out:Total CO2/m2": 24.484165,
    "out:Total CO2 (tons)": 64.368855,
    "out:Klimatpaverkan": -55.770218,
    "out:Initial Cost/MSEK": 6.652452724955603,
    "out:Running cost/(Apt SEK y)": 4666,
    "out:Running Cost over RSP/MSEK": 1.37146,
    "out:LCP/MSEK": -0.045823,
    "out:ROI% old": 14.187313,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2254.81326,
    "out:Return %": 13,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 24.612118,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 327804.010321,
    "out:EL kWh savings": -75737.497753,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176329.01,
    "out:DH kr savings": 327804.0103212155,
    "out:El kr savings": -151474.99550666535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2084.899058,
    "out:% savings (space heating)": 99.268352,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.26,
    "out:Etvv": 0,
    "out:Ef": 34.11,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6652452.7249556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.252191,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.252191,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.509842,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 33.658475,
    "out:Electricity (inc PV)": 26.457515,
    "out:Total Energy Use Pre PV": 35.658475,
    "out:Total Energy Use Post PV": 28.457515,
    "out:Primary Energy": 49.526999,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.726689,
    "out:CO2 Operational/m2": -16.997286,
    "out:Total CO2/m2": 16.729403,
    "out:Total CO2 (tons)": 43.98159,
    "out:Klimatpaverkan": -76.157483,
    "out:Initial Cost/MSEK": 7.004030973966853,
    "out:Running cost/(Apt SEK y)": 3396.5,
    "out:Running Cost over RSP/MSEK": 1.00674,
    "out:LCP/MSEK": -0.032681,
    "out:ROI% old": 14.219057,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 28,
    "out:Energy use kWh/m2": 28,
    "out:Energy savings %": 364.3,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1744.530313,
    "out:Return %": 13,
    "out:Return/kSEK/y": 891,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 26.058975,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 11051.772057,
    "out:PV (% sold (El))": 31.888831,
    "out:PV (kWh self-consumed)": 23605.415303,
    "out:PV (ratio sold/self-consumed)": 0.468188,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 325919.535662,
    "out:EL kWh savings": -55623.094304,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231251.01,
    "out:DH kr savings": 325919.53566214687,
    "out:El kr savings": -111246.18860792708,
    "out:El kr sold": 16577.658086056894,
    "out:El kr saved": 47210.830606,
    "out:El kr return": 63788.4886920569,
    "out:% solar/total": 169,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3969.373717,
    "out:% savings (space heating)": 98.607038,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 2.45,
    "out:Etvv": 0,
    "out:Ef": 26.46,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7004030.973966853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -17.717254,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -20.982759,
    "out:Bought CO2": 3.265505,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.947581,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.941606,
    "out:Electricity (inc PV)": 25.922488,
    "out:Total Energy Use Pre PV": 33.941606,
    "out:Total Energy Use Post PV": 26.922488,
    "out:Primary Energy": 47.912772,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.726689,
    "out:CO2 Operational/m2": -18.036542,
    "out:Total CO2/m2": 15.690147,
    "out:Total CO2 (tons)": 41.249387,
    "out:Klimatpaverkan": -78.889686,
    "out:Initial Cost/MSEK": 7.069755961466853,
    "out:Running cost/(Apt SEK y)": 3092.421053,
    "out:Running Cost over RSP/MSEK": 0.91668,
    "out:LCP/MSEK": -0.008346,
    "out:ROI% old": 14.26885,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 27,
    "out:Energy use kWh/m2": 27,
    "out:Energy savings %": 381.5,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1702.122096,
    "out:Return %": 13,
    "out:Return/kSEK/y": 903,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 25.342106,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 11358.933132,
    "out:PV (% sold (El))": 32.775115,
    "out:PV (kWh self-consumed)": 23298.254229,
    "out:PV (ratio sold/self-consumed)": 0.487544,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 327397.719495,
    "out:EL kWh savings": -54216.50864,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236003.1,
    "out:DH kr savings": 327397.7194954771,
    "out:El kr savings": -108433.01728074932,
    "out:El kr sold": 17038.399698063477,
    "out:El kr saved": 46596.508458,
    "out:El kr return": 63634.90815606347,
    "out:% solar/total": 167,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2491.189883,
    "out:% savings (space heating)": 99.125773,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.61,
    "out:Etvv": 0,
    "out:Ef": 25.92,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7069755.961466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -18.396526,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -21.582676,
    "out:Bought CO2": 3.18615,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.28302,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.95039,
    "out:Electricity (inc PV)": 25.624499,
    "out:Total Energy Use Pre PV": 33.95039,
    "out:Total Energy Use Post PV": 26.624499,
    "out:Primary Energy": 47.645409,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 34.44179,
    "out:CO2 Operational/m2": -18.02817,
    "out:Total CO2/m2": 16.41362,
    "out:Total CO2 (tons)": 43.151397,
    "out:Klimatpaverkan": -76.987676,
    "out:Initial Cost/MSEK": 7.231710973966853,
    "out:Running cost/(Apt SEK y)": 3093.578947,
    "out:Running Cost over RSP/MSEK": 0.91702,
    "out:LCP/MSEK": -0.170641,
    "out:ROI% old": 13.948626,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 27,
    "out:Energy use kWh/m2": 27,
    "out:Energy savings %": 381.5,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1702.640881,
    "out:Return %": 12,
    "out:Return/kSEK/y": 903,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 25.35089,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 11355.142273,
    "out:PV (% sold (El))": 32.764177,
    "out:PV (kWh self-consumed)": 23302.045087,
    "out:PV (ratio sold/self-consumed)": 0.487302,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 326515.850565,
    "out:EL kWh savings": -53433.095737,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236682.37,
    "out:DH kr savings": 326515.8505647342,
    "out:El kr savings": -106866.19147454818,
    "out:El kr sold": 17032.713410045148,
    "out:El kr saved": 46604.090174,
    "out:El kr return": 63636.80358404515,
    "out:% solar/total": 167,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3373.058814,
    "out:% savings (space heating)": 98.816302,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.96,
    "out:Etvv": 0,
    "out:Ef": 25.62,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7231710.973966853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -18.388154,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -21.575276,
    "out:Bought CO2": 3.187122,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.793039,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.211618,
    "out:Electricity (inc PV)": 25.057002,
    "out:Total Energy Use Pre PV": 33.211618,
    "out:Total Energy Use Post PV": 26.057002,
    "out:Primary Energy": 46.080167,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 34.44179,
    "out:CO2 Operational/m2": -18.736109,
    "out:Total CO2/m2": 15.705681,
    "out:Total CO2 (tons)": 41.290226,
    "out:Klimatpaverkan": -78.848848,
    "out:Initial Cost/MSEK": 7.297435961466853,
    "out:Running cost/(Apt SEK y)": 2995.578947,
    "out:Running Cost over RSP/MSEK": 0.88821,
    "out:LCP/MSEK": -0.207556,
    "out:ROI% old": 13.879396,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 26,
    "out:Energy use kWh/m2": 26,
    "out:Energy savings %": 400,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1659.079628,
    "out:Return %": 12,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 24.612118,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 11675.857005,
    "out:PV (% sold (El))": 33.689569,
    "out:PV (kWh self-consumed)": 22981.330355,
    "out:PV (ratio sold/self-consumed)": 0.508058,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 327804.010321,
    "out:EL kWh savings": -51941.146463,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241435.5,
    "out:DH kr savings": 327804.0103212155,
    "out:El kr savings": -103882.29292613959,
    "out:El kr sold": 17513.785508228386,
    "out:El kr saved": 45962.66071,
    "out:El kr return": 63476.44621822838,
    "out:% solar/total": 165,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2084.899058,
    "out:% savings (space heating)": 99.268352,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.26,
    "out:Etvv": 0,
    "out:Ef": 25.06,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7297435.961466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -19.096093,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -22.201642,
    "out:Bought CO2": 3.105549,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.509842,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 33.658475,
    "out:Electricity (inc PV)": 23.984893,
    "out:Total Energy Use Pre PV": 35.658475,
    "out:Total Energy Use Post PV": 25.984893,
    "out:Primary Energy": 45.076279,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.296489,
    "out:CO2 Operational/m2": -72.606896,
    "out:Total CO2/m2": -24.310407,
    "out:Total CO2 (tons)": -63.912045,
    "out:Klimatpaverkan": -184.051119,
    "out:Initial Cost/MSEK": 7.649014193015602,
    "out:Running cost/(Apt SEK y)": 1949.210526,
    "out:Running Cost over RSP/MSEK": 0.58835,
    "out:LCP/MSEK": -0.259274,
    "out:ROI% old": 13.80148,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 26,
    "out:Energy use kWh/m2": 26,
    "out:Energy savings %": 400,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1581.530727,
    "out:Return %": 12,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 26.058975,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 39686.718938,
    "out:PV (% sold (El))": 57.256116,
    "out:PV (kWh self-consumed)": 29627.655784,
    "out:PV (ratio sold/self-consumed)": 1.339516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 325919.535662,
    "out:EL kWh savings": -49122.572542,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 287204.47,
    "out:DH kr savings": 325919.53566214687,
    "out:El kr savings": -98245.14508423774,
    "out:El kr sold": 59530.078406706874,
    "out:El kr saved": 59255.311568,
    "out:El kr return": 118785.38997470686,
    "out:% solar/total": 213,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3969.373717,
    "out:% savings (space heating)": 98.607038,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 2.45,
    "out:Etvv": 0,
    "out:Ef": 23.98,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7649014.1930156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -73.326864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -76.288777,
    "out:Bought CO2": 2.961913,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.947581,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.941606,
    "out:Electricity (inc PV)": 23.505094,
    "out:Total Energy Use Pre PV": 33.941606,
    "out:Total Energy Use Post PV": 24.505094,
    "out:Primary Energy": 43.561463,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.296489,
    "out:CO2 Operational/m2": -73.95728,
    "out:Total CO2/m2": -25.660791,
    "out:Total CO2 (tons)": -67.462204,
    "out:Klimatpaverkan": -187.601278,
    "out:Initial Cost/MSEK": 7.714739180515602,
    "out:Running cost/(Apt SEK y)": 1647.263158,
    "out:Running Cost over RSP/MSEK": 0.49891,
    "out:LCP/MSEK": -0.235559,
    "out:ROI% old": 13.849519,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 25,
    "out:Energy use kWh/m2": 25,
    "out:Energy savings %": 420,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1543.456982,
    "out:Return %": 12,
    "out:Return/kSEK/y": 958,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 25.342106,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 40156.257842,
    "out:PV (% sold (El))": 57.933521,
    "out:PV (kWh self-consumed)": 29158.11688,
    "out:PV (ratio sold/self-consumed)": 1.37719,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 327397.719495,
    "out:EL kWh savings": -47861.181258,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291909.74,
    "out:DH kr savings": 327397.7194954771,
    "out:El kr savings": -95722.36251511775,
    "out:El kr sold": 60234.38676227722,
    "out:El kr saved": 58316.23376,
    "out:El kr return": 118550.62052227723,
    "out:% solar/total": 209,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2491.189883,
    "out:% savings (space heating)": 99.125773,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.61,
    "out:Etvv": 0,
    "out:Ef": 23.51,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7714739.180515602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -74.317264,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -77.207952,
    "out:Bought CO2": 2.890688,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.28302,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.95039,
    "out:Electricity (inc PV)": 23.237405,
    "out:Total Energy Use Pre PV": 33.95039,
    "out:Total Energy Use Post PV": 24.237405,
    "out:Primary Energy": 43.34864,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 49.01159,
    "out:CO2 Operational/m2": -73.945092,
    "out:Total CO2/m2": -24.933502,
    "out:Total CO2 (tons)": -65.550162,
    "out:Klimatpaverkan": -185.689235,
    "out:Initial Cost/MSEK": 7.876694193015601,
    "out:Running cost/(Apt SEK y)": 1648.394737,
    "out:Running Cost over RSP/MSEK": 0.49925,
    "out:LCP/MSEK": -0.397854,
    "out:ROI% old": 13.564139,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 24,
    "out:Energy use kWh/m2": 24,
    "out:Energy savings %": 441.7,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1543.922722,
    "out:Return %": 12,
    "out:Return/kSEK/y": 958,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 25.35089,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 40150.479286,
    "out:PV (% sold (El))": 57.925184,
    "out:PV (kWh self-consumed)": 29163.895435,
    "out:PV (ratio sold/self-consumed)": 1.376719,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 326515.850565,
    "out:EL kWh savings": -47157.427036,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292426.72,
    "out:DH kr savings": 326515.8505647342,
    "out:El kr savings": -94314.8540727384,
    "out:El kr sold": 60225.7189296372,
    "out:El kr saved": 58327.79087,
    "out:El kr return": 118553.50979963719,
    "out:% solar/total": 209,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3373.058814,
    "out:% savings (space heating)": 98.816302,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.96,
    "out:Etvv": 0,
    "out:Ef": 23.24,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7876694.1930156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -74.305076,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -77.196635,
    "out:Bought CO2": 2.891559,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.793039,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.211618,
    "out:Electricity (inc PV)": 22.727688,
    "out:Total Energy Use Pre PV": 33.211618,
    "out:Total Energy Use Post PV": 23.727688,
    "out:Primary Energy": 41.887401,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 49.01159,
    "out:CO2 Operational/m2": -74.974892,
    "out:Total CO2/m2": -25.963302,
    "out:Total CO2 (tons)": -68.257505,
    "out:Klimatpaverkan": -188.396579,
    "out:Initial Cost/MSEK": 7.942419180515603,
    "out:Running cost/(Apt SEK y)": 1552.605263,
    "out:Running Cost over RSP/MSEK": 0.47109,
    "out:LCP/MSEK": -0.435419,
    "out:ROI% old": 13.502543,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 24,
    "out:Energy use kWh/m2": 24,
    "out:Energy savings %": 441.7,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 3,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1504.823725,
    "out:Return %": 12,
    "out:Return/kSEK/y": 961,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 24.612118,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 40639.629942,
    "out:PV (% sold (El))": 58.630883,
    "out:PV (kWh self-consumed)": 28674.744779,
    "out:PV (ratio sold/self-consumed)": 1.417262,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 327804.010321,
    "out:EL kWh savings": -45817.381348,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297128.69,
    "out:DH kr savings": 327804.0103212155,
    "out:El kr savings": -91634.76269534053,
    "out:El kr sold": 60959.44491342327,
    "out:El kr saved": 57349.489558,
    "out:El kr return": 118308.93447142327,
    "out:% solar/total": 206,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2084.899058,
    "out:% savings (space heating)": 99.268352,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.26,
    "out:Etvv": 0,
    "out:Ef": 22.73,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7942419.180515602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -75.334876,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -78.153272,
    "out:Bought CO2": 2.818396,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.21464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.712172,
    "out:Electricity (inc PV)": 34.448971,
    "out:Total Energy Use Pre PV": 33.712172,
    "out:Total Energy Use Post PV": 35.448971,
    "out:Primary Energy": 63.416864,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.156889,
    "out:CO2 Operational/m2": 4.678252,
    "out:Total CO2/m2": 23.835141,
    "out:Total CO2 (tons)": 62.662571,
    "out:Klimatpaverkan": -57.476502,
    "out:Initial Cost/MSEK": 6.459341612455603,
    "out:Running cost/(Apt SEK y)": 4735.263158,
    "out:Running Cost over RSP/MSEK": 1.3918,
    "out:LCP/MSEK": 0.126948,
    "out:ROI% old": 14.566478,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2289.85204,
    "out:Return %": 13,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 25.112672,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 326695.621544,
    "out:EL kWh savings": -76632.627357,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173430.37,
    "out:DH kr savings": 326695.6215439113,
    "out:El kr savings": -153265.25471412865,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3193.287835,
    "out:% savings (space heating)": 98.879388,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.81,
    "out:Etvv": 0,
    "out:Ef": 34.45,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6459341.612455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.318268,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.318268,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.747412,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 31.966505,
    "out:Electricity (inc PV)": 33.760359,
    "out:Total Energy Use Pre PV": 32.966505,
    "out:Total Energy Use Post PV": 34.760359,
    "out:Primary Energy": 61.66752,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.156889,
    "out:CO2 Operational/m2": 4.579818,
    "out:Total CO2/m2": 23.736707,
    "out:Total CO2 (tons)": 62.403789,
    "out:Klimatpaverkan": -57.735285,
    "out:Initial Cost/MSEK": 6.525066599955603,
    "out:Running cost/(Apt SEK y)": 4632.078947,
    "out:Running Cost over RSP/MSEK": 1.36149,
    "out:LCP/MSEK": 0.091533,
    "out:ROI% old": 14.486114,
    "out:Payback discounted": 8,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2237.65535,
    "out:Return %": 13,
    "out:Return/kSEK/y": 844,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 24.367005,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 327923.963677,
    "out:EL kWh savings": -74822.26682,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178279.43,
    "out:DH kr savings": 327923.96367697616,
    "out:El kr savings": -149644.53364033138,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 1964.945702,
    "out:% savings (space heating)": 99.310447,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.16,
    "out:Etvv": 0,
    "out:Ef": 33.76,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6525066.5999556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.219834,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.219834,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.063027,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.136661,
    "out:Electricity (inc PV)": 33.633795,
    "out:Total Energy Use Pre PV": 33.136661,
    "out:Total Energy Use Post PV": 34.633795,
    "out:Primary Energy": 61.708497,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.87199,
    "out:CO2 Operational/m2": 4.60228,
    "out:Total CO2/m2": 24.47427,
    "out:Total CO2 (tons)": 64.342841,
    "out:Klimatpaverkan": -55.796232,
    "out:Initial Cost/MSEK": 6.687021612455602,
    "out:Running cost/(Apt SEK y)": 4655.631579,
    "out:Running Cost over RSP/MSEK": 1.36841,
    "out:LCP/MSEK": -0.077342,
    "out:ROI% old": 14.120487,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 271.4,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2249.56627,
    "out:Return %": 13,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 24.537161,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 327094.21203,
    "out:EL kWh savings": -74489.53014,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178115.15,
    "out:DH kr savings": 327094.2120303983,
    "out:El kr savings": -148979.06027944875,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2794.697348,
    "out:% savings (space heating)": 99.019264,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.5,
    "out:Etvv": 0,
    "out:Ef": 33.63,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6687021.612455603,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.242296,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.242296,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.649021,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 31.374866,
    "out:Electricity (inc PV)": 32.916107,
    "out:Total Energy Use Pre PV": 32.374866,
    "out:Total Energy Use Post PV": 33.916107,
    "out:Primary Energy": 59.981445,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.87199,
    "out:CO2 Operational/m2": 4.501717,
    "out:Total CO2/m2": 24.373707,
    "out:Total CO2 (tons)": 64.078461,
    "out:Klimatpaverkan": -56.060612,
    "out:Initial Cost/MSEK": 6.752746599955604,
    "out:Running cost/(Apt SEK y)": 4550.236842,
    "out:Running Cost over RSP/MSEK": 1.33745,
    "out:LCP/MSEK": -0.112107,
    "out:ROI% old": 14.048548,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 282.4,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2196.24062,
    "out:Return %": 13,
    "out:Return/kSEK/y": 847,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 23.775366,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 328182.633557,
    "out:EL kWh savings": -72602.728816,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182977.18,
    "out:DH kr savings": 328182.63355723675,
    "out:El kr savings": -145205.4576323682,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 1706.275822,
    "out:% savings (space heating)": 99.401221,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 0.94,
    "out:Etvv": 0,
    "out:Ef": 32.92,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6752746.5999556035,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.141733,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.141733,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.21464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.712172,
    "out:Electricity (inc PV)": 25.342906,
    "out:Total Energy Use Pre PV": 33.712172,
    "out:Total Energy Use Post PV": 26.342906,
    "out:Primary Energy": 47.025947,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.726689,
    "out:CO2 Operational/m2": -18.255556,
    "out:Total CO2/m2": 15.471133,
    "out:Total CO2 (tons)": 40.673599,
    "out:Klimatpaverkan": -79.465474,
    "out:Initial Cost/MSEK": 7.104324848966852,
    "out:Running cost/(Apt SEK y)": 3061.973684,
    "out:Running Cost over RSP/MSEK": 0.90773,
    "out:LCP/MSEK": -0.033965,
    "out:ROI% old": 14.217416,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 26,
    "out:Energy use kWh/m2": 26,
    "out:Energy savings %": 400,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1688.578964,
    "out:Return %": 13,
    "out:Return/kSEK/y": 904,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 25.112672,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 11458.107665,
    "out:PV (% sold (El))": 33.061274,
    "out:PV (kWh self-consumed)": 23199.079696,
    "out:PV (ratio sold/self-consumed)": 0.493904,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 326695.621544,
    "out:EL kWh savings": -52692.787908,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 238497.21,
    "out:DH kr savings": 326695.6215439113,
    "out:El kr savings": -105385.57581677022,
    "out:El kr sold": 17187.161497439534,
    "out:El kr saved": 46398.159392,
    "out:El kr return": 63585.320889439536,
    "out:% solar/total": 166,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3193.287835,
    "out:% savings (space heating)": 98.879388,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.81,
    "out:Etvv": 0,
    "out:Ef": 25.34,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7104324.848966852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -18.61554,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -21.776335,
    "out:Bought CO2": 3.160795,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.747412,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 31.966505,
    "out:Electricity (inc PV)": 24.765022,
    "out:Total Energy Use Pre PV": 32.966505,
    "out:Total Energy Use Post PV": 25.765022,
    "out:Primary Energy": 45.475914,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.726689,
    "out:CO2 Operational/m2": -18.97273,
    "out:Total CO2/m2": 14.753959,
    "out:Total CO2 (tons)": 38.788149,
    "out:Klimatpaverkan": -81.350924,
    "out:Initial Cost/MSEK": 7.170049836466853,
    "out:Running cost/(Apt SEK y)": 2963.052632,
    "out:Running Cost over RSP/MSEK": 0.87866,
    "out:LCP/MSEK": -0.07062,
    "out:ROI% old": 14.14501,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 26,
    "out:Energy use kWh/m2": 26,
    "out:Energy savings %": 400,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1644.656723,
    "out:Return %": 13,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 24.367005,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 11782.898039,
    "out:PV (% sold (El))": 33.998426,
    "out:PV (kWh self-consumed)": 22874.289322,
    "out:PV (ratio sold/self-consumed)": 0.515115,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 327923.963677,
    "out:EL kWh savings": -51173.531217,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243251.25,
    "out:DH kr savings": 327923.96367697616,
    "out:El kr savings": -102347.06243476373,
    "out:El kr sold": 17674.347058480824,
    "out:El kr saved": 45748.578644,
    "out:El kr return": 63422.925702480825,
    "out:% solar/total": 164,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 1964.945702,
    "out:% savings (space heating)": 99.310447,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.16,
    "out:Etvv": 0,
    "out:Ef": 24.77,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7170049.836466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -19.332714,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -22.411244,
    "out:Bought CO2": 3.07853,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.063027,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.136661,
    "out:Electricity (inc PV)": 24.658928,
    "out:Total Energy Use Pre PV": 33.136661,
    "out:Total Energy Use Post PV": 25.658928,
    "out:Primary Energy": 45.553736,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 34.44179,
    "out:CO2 Operational/m2": -18.808387,
    "out:Total CO2/m2": 15.633403,
    "out:Total CO2 (tons)": 41.100207,
    "out:Klimatpaverkan": -79.038866,
    "out:Initial Cost/MSEK": 7.332004848966852,
    "out:Running cost/(Apt SEK y)": 2985.631579,
    "out:Running Cost over RSP/MSEK": 0.88529,
    "out:LCP/MSEK": -0.239205,
    "out:ROI% old": 13.819646,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 26,
    "out:Energy use kWh/m2": 26,
    "out:Energy savings %": 400,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1654.667506,
    "out:Return %": 12,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 24.537161,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 11708.580638,
    "out:PV (% sold (El))": 33.78399,
    "out:PV (kWh self-consumed)": 22948.606723,
    "out:PV (ratio sold/self-consumed)": 0.510209,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 327094.21203,
    "out:EL kWh savings": -50894.610155,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242867.86,
    "out:DH kr savings": 327094.2120303983,
    "out:El kr savings": -101789.22030943993,
    "out:El kr sold": 17562.87095673011,
    "out:El kr saved": 45897.213446,
    "out:El kr return": 63460.08440273011,
    "out:% solar/total": 165,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2794.697348,
    "out:% savings (space heating)": 99.019264,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.5,
    "out:Etvv": 0,
    "out:Ef": 24.66,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7332004.848966852,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -19.168371,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -22.265655,
    "out:Bought CO2": 3.097284,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.649021,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 31.374866,
    "out:Electricity (inc PV)": 24.058269,
    "out:Total Energy Use Pre PV": 32.374866,
    "out:Total Energy Use Post PV": 25.058269,
    "out:Primary Energy": 44.037336,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 34.44179,
    "out:CO2 Operational/m2": -19.547564,
    "out:Total CO2/m2": 14.894226,
    "out:Total CO2 (tons)": 39.156911,
    "out:Klimatpaverkan": -80.982162,
    "out:Initial Cost/MSEK": 7.397729836466852,
    "out:Running cost/(Apt SEK y)": 2884.631579,
    "out:Running Cost over RSP/MSEK": 0.85561,
    "out:LCP/MSEK": -0.27525,
    "out:ROI% old": 13.754181,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 25,
    "out:Energy use kWh/m2": 25,
    "out:Energy savings %": 420,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1609.904674,
    "out:Return %": 12,
    "out:Return/kSEK/y": 911,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 23.775366,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 12044.210644,
    "out:PV (% sold (El))": 34.752418,
    "out:PV (kWh self-consumed)": 22612.976717,
    "out:PV (ratio sold/self-consumed)": 0.532624,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 328182.633557,
    "out:EL kWh savings": -49315.478002,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247617.99,
    "out:DH kr savings": 328182.63355723675,
    "out:El kr savings": -98630.95600462682,
    "out:El kr sold": 18066.31596587798,
    "out:El kr saved": 45225.953434,
    "out:El kr return": 63292.26939987799,
    "out:% solar/total": 162,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 1706.275822,
    "out:% savings (space heating)": 99.401221,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 0.94,
    "out:Etvv": 0,
    "out:Ef": 24.06,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7397729.836466853,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -19.907548,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -22.920957,
    "out:Bought CO2": 3.013409,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.21464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.712172,
    "out:Electricity (inc PV)": 22.984404,
    "out:Total Energy Use Pre PV": 33.712172,
    "out:Total Energy Use Post PV": 23.984404,
    "out:Primary Energy": 42.780643,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.296489,
    "out:CO2 Operational/m2": -74.276035,
    "out:Total CO2/m2": -25.979546,
    "out:Total CO2 (tons)": -68.300211,
    "out:Klimatpaverkan": -188.439284,
    "out:Initial Cost/MSEK": 7.749308068015601,
    "out:Running cost/(Apt SEK y)": 1617.5,
    "out:Running Cost over RSP/MSEK": 0.49017,
    "out:LCP/MSEK": -0.261388,
    "out:ROI% old": 13.80385,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 24,
    "out:Energy use kWh/m2": 24,
    "out:Energy savings %": 441.7,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1531.299355,
    "out:Return %": 12,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 25.112672,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 40307.389603,
    "out:PV (% sold (El))": 58.151559,
    "out:PV (kWh self-consumed)": 29006.985118,
    "out:PV (ratio sold/self-consumed)": 1.389575,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 326695.621544,
    "out:EL kWh savings": -46492.287558,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294172.13,
    "out:DH kr savings": 326695.6215439113,
    "out:El kr savings": -92984.57511682164,
    "out:El kr sold": 60461.084405208276,
    "out:El kr saved": 58013.970236,
    "out:El kr return": 118475.05464120828,
    "out:% solar/total": 208,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 3193.287835,
    "out:% savings (space heating)": 98.879388,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.81,
    "out:Etvv": 0,
    "out:Ef": 22.98,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7749308.0680156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -74.636019,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -77.503957,
    "out:Bought CO2": 2.867938,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.747412,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 31.966505,
    "out:Electricity (inc PV)": 22.465578,
    "out:Total Energy Use Pre PV": 32.966505,
    "out:Total Energy Use Post PV": 23.465578,
    "out:Primary Energy": 41.336914,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.296489,
    "out:CO2 Operational/m2": -75.319086,
    "out:Total CO2/m2": -27.022597,
    "out:Total CO2 (tons)": -71.042391,
    "out:Klimatpaverkan": -191.181465,
    "out:Initial Cost/MSEK": 7.815033055515602,
    "out:Running cost/(Apt SEK y)": 1520.842105,
    "out:Running Cost over RSP/MSEK": 0.46176,
    "out:LCP/MSEK": -0.298703,
    "out:ROI% old": 13.739691,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 23,
    "out:Energy use kWh/m2": 23,
    "out:Energy savings %": 465.2,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 3,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1491.884126,
    "out:Return %": 12,
    "out:Return/kSEK/y": 963,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 24.367005,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 40803.580115,
    "out:PV (% sold (El))": 58.867414,
    "out:PV (kWh self-consumed)": 28510.794607,
    "out:PV (ratio sold/self-consumed)": 1.431163,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 327923.963677,
    "out:EL kWh savings": -45128.294314,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298872.75,
    "out:DH kr savings": 327923.96367697616,
    "out:El kr savings": -90256.5886282999,
    "out:El kr sold": 61205.37017194244,
    "out:El kr saved": 57021.589214,
    "out:El kr return": 118226.95938594243,
    "out:% solar/total": 205,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 1964.945702,
    "out:% savings (space heating)": 99.310447,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.16,
    "out:Etvv": 0,
    "out:Ef": 22.47,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7815033.055515601,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -75.67907,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -78.473258,
    "out:Bought CO2": 2.794188,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.063027,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 32.136661,
    "out:Electricity (inc PV)": 22.370358,
    "out:Total Energy Use Pre PV": 33.136661,
    "out:Total Energy Use Post PV": 23.370358,
    "out:Primary Energy": 41.43431,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 49.01159,
    "out:CO2 Operational/m2": -75.080019,
    "out:Total CO2/m2": -26.068429,
    "out:Total CO2 (tons)": -68.533884,
    "out:Klimatpaverkan": -188.672958,
    "out:Initial Cost/MSEK": 7.976988068015602,
    "out:Running cost/(Apt SEK y)": 1542.894737,
    "out:Running Cost over RSP/MSEK": 0.46824,
    "out:LCP/MSEK": -0.467138,
    "out:ROI% old": 13.449133,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 23,
    "out:Energy use kWh/m2": 23,
    "out:Energy savings %": 465.2,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 3,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1500.864986,
    "out:Return %": 12,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 24.537161,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 40689.741895,
    "out:PV (% sold (El))": 58.70318,
    "out:PV (kWh self-consumed)": 28624.632827,
    "out:PV (ratio sold/self-consumed)": 1.421494,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 327094.21203,
    "out:EL kWh savings": -44877.960991,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298372.9,
    "out:DH kr savings": 327094.2120303983,
    "out:El kr savings": -89755.92198199255,
    "out:El kr sold": 61034.61284175527,
    "out:El kr saved": 57249.265654,
    "out:El kr return": 118283.87849575527,
    "out:% solar/total": 205,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 2794.697348,
    "out:% savings (space heating)": 99.019264,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 1.5,
    "out:Etvv": 0,
    "out:Ef": 22.37,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7976988.0680156015,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -75.440003,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -78.250993,
    "out:Bought CO2": 2.81099,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.649021,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 31.374866,
    "out:Electricity (inc PV)": 21.831532,
    "out:Total Energy Use Pre PV": 32.374866,
    "out:Total Energy Use Post PV": 22.831532,
    "out:Primary Energy": 40.02921,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 49.01159,
    "out:CO2 Operational/m2": -76.15495,
    "out:Total CO2/m2": -27.14336,
    "out:Total CO2 (tons)": -71.359877,
    "out:Klimatpaverkan": -191.498951,
    "out:Initial Cost/MSEK": 8.042713055515602,
    "out:Running cost/(Apt SEK y)": 1444.210526,
    "out:Running Cost over RSP/MSEK": 0.43923,
    "out:LCP/MSEK": -0.503853,
    "out:ROI% old": 13.390755,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 23,
    "out:Energy use kWh/m2": 23,
    "out:Energy savings %": 465.2,
    "out:Op energy cost/MSEK": 3,
    "out:El price/MSEK": 3,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1460.716727,
    "out:Return %": 12,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074098151,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 23.775366,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 41200.80594,
    "out:PV (% sold (El))": 59.440493,
    "out:PV (kWh self-consumed)": 28113.568781,
    "out:PV (ratio sold/self-consumed)": 1.465513,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 328182.633557,
    "out:EL kWh savings": -43461.387759,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303061.07,
    "out:DH kr savings": 328182.63355723675,
    "out:El kr savings": -86922.77551735079,
    "out:El kr sold": 61801.20891072286,
    "out:El kr saved": 56227.137562,
    "out:El kr return": 118028.34647272286,
    "out:% solar/total": 202,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 1706.275822,
    "out:% savings (space heating)": 99.401221,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 0.94,
    "out:Etvv": 0,
    "out:Ef": 21.83,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8042713.055515602,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -76.514934,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.250822,
    "out:Bought CO2": 2.735888,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 109.899,
    "out:Total Energy Use Post PV": 109.899,
    "out:Primary Energy": 100.031507,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.368692,
    "out:CO2 Operational/m2": 37.305147,
    "out:Total CO2/m2": 40.673839,
    "out:Total CO2 (tons)": 106.931498,
    "out:Klimatpaverkan": -13.207575,
    "out:Initial Cost/MSEK": 2.590446653086201,
    "out:Running cost/(Apt SEK y)": 22263.342105,
    "out:Running Cost over RSP/MSEK": 6.6125,
    "out:LCP/MSEK": -1.224857,
    "out:ROI% old": 7.530913,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 174,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 66219.135952,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 42037.6,
    "out:DH kr savings": 66219.13595192708,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2590446.6530862013,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 105.899,
    "out:Total Energy Use Post PV": 105.899,
    "out:Primary Energy": 96.411631,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.368692,
    "out:CO2 Operational/m2": 35.865211,
    "out:Total CO2/m2": 39.233903,
    "out:Total CO2 (tons)": 103.145908,
    "out:Klimatpaverkan": -16.993166,
    "out:Initial Cost/MSEK": 2.656171640586201,
    "out:Running cost/(Apt SEK y)": 21427.578947,
    "out:Running Cost over RSP/MSEK": 6.3641,
    "out:LCP/MSEK": -1.042182,
    "out:ROI% old": 8.680538,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 78454.832005,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 54273.3,
    "out:DH kr savings": 78454.83200481354,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2656171.640586201,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 107.899,
    "out:Total Energy Use Post PV": 107.899,
    "out:Primary Energy": 97.406507,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.083793,
    "out:CO2 Operational/m2": 36.585179,
    "out:Total CO2/m2": 40.668972,
    "out:Total CO2 (tons)": 106.918703,
    "out:Klimatpaverkan": -13.22037,
    "out:Initial Cost/MSEK": 2.8181266530862015,
    "out:Running cost/(Apt SEK y)": 21845.473684,
    "out:Running Cost over RSP/MSEK": 6.4883,
    "out:LCP/MSEK": -1.328337,
    "out:ROI% old": 7.552079,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 190,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 72958.575922,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 48777.04,
    "out:DH kr savings": 72958.5759215176,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2818126.6530862013,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 102.899,
    "out:Total Energy Use Post PV": 102.899,
    "out:Primary Energy": 93.786631,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.083793,
    "out:CO2 Operational/m2": 34.785259,
    "out:Total CO2/m2": 38.869052,
    "out:Total CO2 (tons)": 102.186715,
    "out:Klimatpaverkan": -17.952359,
    "out:Initial Cost/MSEK": 2.883851640586201,
    "out:Running cost/(Apt SEK y)": 20800.789474,
    "out:Running Cost over RSP/MSEK": 6.17779,
    "out:LCP/MSEK": -1.083552,
    "out:ROI% old": 8.918133,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 230,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 85194.271974,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 61012.74,
    "out:DH kr savings": 85194.27197440405,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2883851.640586201,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 109.899,
    "out:Total Energy Use Post PV": 106.185671,
    "out:Primary Energy": 93.347515,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.938492,
    "out:CO2 Operational/m2": -11.220464,
    "out:Total CO2/m2": 6.718028,
    "out:Total CO2 (tons)": 17.661692,
    "out:Klimatpaverkan": -102.477382,
    "out:Initial Cost/MSEK": 3.235429889597451,
    "out:Running cost/(Apt SEK y)": 20766.842105,
    "out:Running Cost over RSP/MSEK": 6.17987,
    "out:LCP/MSEK": -1.43721,
    "out:ROI% old": 7.939859,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 66219.135952,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98904.55,
    "out:DH kr savings": 66219.13595192708,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3235429.8895974513,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 105.899,
    "out:Total Energy Use Post PV": 102.185671,
    "out:Primary Energy": 89.727639,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.938492,
    "out:CO2 Operational/m2": -12.6604,
    "out:Total CO2/m2": 5.278092,
    "out:Total CO2 (tons)": 13.876101,
    "out:Klimatpaverkan": -106.262973,
    "out:Initial Cost/MSEK": 3.301154877097451,
    "out:Running cost/(Apt SEK y)": 19931.078947,
    "out:Running Cost over RSP/MSEK": 5.93147,
    "out:LCP/MSEK": -1.254535,
    "out:ROI% old": 8.856727,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 78454.832005,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111140.24,
    "out:DH kr savings": 78454.83200481354,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3301154.877097451,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 107.899,
    "out:Total Energy Use Post PV": 104.185671,
    "out:Primary Energy": 90.722515,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.653593,
    "out:CO2 Operational/m2": -11.940432,
    "out:Total CO2/m2": 6.713161,
    "out:Total CO2 (tons)": 17.648896,
    "out:Klimatpaverkan": -102.490177,
    "out:Initial Cost/MSEK": 3.4631098895974515,
    "out:Running cost/(Apt SEK y)": 20348.973684,
    "out:Running Cost over RSP/MSEK": 6.05567,
    "out:LCP/MSEK": -1.54069,
    "out:ROI% old": 7.930197,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 247,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 72958.575922,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105643.99,
    "out:DH kr savings": 72958.5759215176,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3463109.8895974513,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 102.899,
    "out:Total Energy Use Post PV": 99.185671,
    "out:Primary Energy": 87.102639,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.653593,
    "out:CO2 Operational/m2": -13.740352,
    "out:Total CO2/m2": 4.913241,
    "out:Total CO2 (tons)": 12.916908,
    "out:Klimatpaverkan": -107.222166,
    "out:Initial Cost/MSEK": 3.528834877097451,
    "out:Running cost/(Apt SEK y)": 19304.289474,
    "out:Running Cost over RSP/MSEK": 5.74517,
    "out:LCP/MSEK": -1.295915,
    "out:ROI% old": 9.039487,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 31.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85194.271974,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117879.68,
    "out:DH kr savings": 85194.27197440405,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3528834.877097451,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 109.899,
    "out:Total Energy Use Post PV": 105.835188,
    "out:Primary Energy": 92.716645,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.508292,
    "out:CO2 Operational/m2": -76.658846,
    "out:Total CO2/m2": -44.150554,
    "out:Total CO2 (tons)": -116.07178,
    "out:Klimatpaverkan": -236.210854,
    "out:Initial Cost/MSEK": 3.8804131086462013,
    "out:Running cost/(Apt SEK y)": 19386.657895,
    "out:Running Cost over RSP/MSEK": 5.78126,
    "out:LCP/MSEK": -1.683583,
    "out:ROI% old": 8.087615,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66219.135952,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151351.04,
    "out:DH kr savings": 66219.13595192708,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3880413.1086462014,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 105.899,
    "out:Total Energy Use Post PV": 101.835188,
    "out:Primary Energy": 89.096769,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.508292,
    "out:CO2 Operational/m2": -78.098782,
    "out:Total CO2/m2": -45.59049,
    "out:Total CO2 (tons)": -119.857371,
    "out:Klimatpaverkan": -239.996445,
    "out:Initial Cost/MSEK": 3.946138096146201,
    "out:Running cost/(Apt SEK y)": 18550.921053,
    "out:Running Cost over RSP/MSEK": 5.53285,
    "out:LCP/MSEK": -1.500898,
    "out:ROI% old": 8.852199,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 78454.832005,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163586.74,
    "out:DH kr savings": 78454.83200481354,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3946138.0961462012,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 107.899,
    "out:Total Energy Use Post PV": 103.835188,
    "out:Primary Energy": 90.091645,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.223393,
    "out:CO2 Operational/m2": -77.378814,
    "out:Total CO2/m2": -44.155421,
    "out:Total CO2 (tons)": -116.084575,
    "out:Klimatpaverkan": -236.223649,
    "out:Initial Cost/MSEK": 4.108093108646202,
    "out:Running cost/(Apt SEK y)": 18968.789474,
    "out:Running Cost over RSP/MSEK": 5.65706,
    "out:LCP/MSEK": -1.787063,
    "out:ROI% old": 8.07128,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 300,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 72958.575922,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158090.48,
    "out:DH kr savings": 72958.5759215176,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4108093.1086462014,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 102.899,
    "out:Total Energy Use Post PV": 98.835188,
    "out:Primary Energy": 86.471769,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.223393,
    "out:CO2 Operational/m2": -79.178734,
    "out:Total CO2/m2": -45.955341,
    "out:Total CO2 (tons)": -120.816564,
    "out:Klimatpaverkan": -240.955638,
    "out:Initial Cost/MSEK": 4.173818096146201,
    "out:Running cost/(Apt SEK y)": 17924.105263,
    "out:Running Cost over RSP/MSEK": 5.34655,
    "out:LCP/MSEK": -1.542278,
    "out:ROI% old": 9.006964,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 31.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85194.271974,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170326.18,
    "out:DH kr savings": 85194.27197440405,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4173818.0961462012,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 106.899,
    "out:Total Energy Use Post PV": 106.899,
    "out:Primary Energy": 96.531507,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.368692,
    "out:CO2 Operational/m2": 36.225195,
    "out:Total CO2/m2": 39.593887,
    "out:Total CO2 (tons)": 104.092305,
    "out:Klimatpaverkan": -16.046768,
    "out:Initial Cost/MSEK": 2.6907405280862013,
    "out:Running cost/(Apt SEK y)": 21636.526316,
    "out:Running Cost over RSP/MSEK": 6.4262,
    "out:LCP/MSEK": -1.138851,
    "out:ROI% old": 8.239314,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 21.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 75205.055911,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51023.52,
    "out:DH kr savings": 75205.0559113811,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2690740.5280862013,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 101.899,
    "out:Total Energy Use Post PV": 101.899,
    "out:Primary Energy": 92.911631,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.368692,
    "out:CO2 Operational/m2": 34.425275,
    "out:Total CO2/m2": 37.793967,
    "out:Total CO2 (tons)": 99.360317,
    "out:Klimatpaverkan": -20.778757,
    "out:Initial Cost/MSEK": 2.756465515586202,
    "out:Running cost/(Apt SEK y)": 20591.842105,
    "out:Running Cost over RSP/MSEK": 6.11569,
    "out:LCP/MSEK": -0.894066,
    "out:ROI% old": 9.652112,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 27.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 238,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 87440.751964,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63259.22,
    "out:DH kr savings": 87440.75196426755,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2756465.515586202,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 104.899,
    "out:Total Energy Use Post PV": 104.899,
    "out:Primary Energy": 94.431507,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.083793,
    "out:CO2 Operational/m2": 35.505227,
    "out:Total CO2/m2": 39.58902,
    "out:Total CO2 (tons)": 104.07951,
    "out:Klimatpaverkan": -16.059564,
    "out:Initial Cost/MSEK": 2.9184205280862012,
    "out:Running cost/(Apt SEK y)": 21218.657895,
    "out:Running Cost over RSP/MSEK": 6.302,
    "out:LCP/MSEK": -1.242331,
    "out:ROI% old": 8.204487,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 80596.607887,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56415.07,
    "out:DH kr savings": 80596.60788705348,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2918420.5280862013,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 99.899,
    "out:Total Energy Use Post PV": 99.899,
    "out:Primary Energy": 90.811631,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.083793,
    "out:CO2 Operational/m2": 33.705307,
    "out:Total CO2/m2": 37.7891,
    "out:Total CO2 (tons)": 99.347521,
    "out:Klimatpaverkan": -20.791552,
    "out:Initial Cost/MSEK": 2.984145515586201,
    "out:Running cost/(Apt SEK y)": 20173.973684,
    "out:Running Cost over RSP/MSEK": 5.99149,
    "out:LCP/MSEK": -0.997546,
    "out:ROI% old": 9.51026,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 30,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 92832.30394,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68650.77,
    "out:DH kr savings": 92832.30393993994,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2984145.515586201,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 106.899,
    "out:Total Energy Use Post PV": 103.185671,
    "out:Primary Energy": 89.847515,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.938492,
    "out:CO2 Operational/m2": -12.300416,
    "out:Total CO2/m2": 5.638076,
    "out:Total CO2 (tons)": 14.822498,
    "out:Klimatpaverkan": -105.316575,
    "out:Initial Cost/MSEK": 3.3357237645974513,
    "out:Running cost/(Apt SEK y)": 20140.026316,
    "out:Running Cost over RSP/MSEK": 5.99357,
    "out:LCP/MSEK": -1.351204,
    "out:ROI% old": 8.498991,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75205.055911,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107890.47,
    "out:DH kr savings": 75205.0559113811,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3335723.7645974513,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 101.899,
    "out:Total Energy Use Post PV": 98.185671,
    "out:Primary Energy": 86.227639,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.938492,
    "out:CO2 Operational/m2": -14.100336,
    "out:Total CO2/m2": 3.838156,
    "out:Total CO2 (tons)": 10.09051,
    "out:Klimatpaverkan": -110.048564,
    "out:Initial Cost/MSEK": 3.4014487520974512,
    "out:Running cost/(Apt SEK y)": 19095.342105,
    "out:Running Cost over RSP/MSEK": 5.68307,
    "out:LCP/MSEK": -1.106429,
    "out:ROI% old": 9.638834,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 87440.751964,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120126.16,
    "out:DH kr savings": 87440.75196426755,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3401448.752097451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 104.899,
    "out:Total Energy Use Post PV": 101.185671,
    "out:Primary Energy": 87.747515,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.653593,
    "out:CO2 Operational/m2": -13.020384,
    "out:Total CO2/m2": 5.633209,
    "out:Total CO2 (tons)": 14.809703,
    "out:Klimatpaverkan": -105.32937,
    "out:Initial Cost/MSEK": 3.5634037645974517,
    "out:Running cost/(Apt SEK y)": 19722.157895,
    "out:Running Cost over RSP/MSEK": 5.86937,
    "out:LCP/MSEK": -1.454684,
    "out:ROI% old": 8.453875,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 28.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 80596.607887,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113282.02,
    "out:DH kr savings": 80596.60788705348,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3563403.764597452,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 99.899,
    "out:Total Energy Use Post PV": 96.185671,
    "out:Primary Energy": 84.127639,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.653593,
    "out:CO2 Operational/m2": -14.820304,
    "out:Total CO2/m2": 3.833289,
    "out:Total CO2 (tons)": 10.077714,
    "out:Klimatpaverkan": -110.061359,
    "out:Initial Cost/MSEK": 3.629128752097451,
    "out:Running cost/(Apt SEK y)": 18677.473684,
    "out:Running Cost over RSP/MSEK": 5.55886,
    "out:LCP/MSEK": -1.209899,
    "out:ROI% old": 9.523065,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 35.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 92832.30394,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125517.72,
    "out:DH kr savings": 92832.30393993994,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3629128.752097451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 106.899,
    "out:Total Energy Use Post PV": 102.835188,
    "out:Primary Energy": 89.216645,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.508292,
    "out:CO2 Operational/m2": -77.738798,
    "out:Total CO2/m2": -45.230506,
    "out:Total CO2 (tons)": -118.910973,
    "out:Klimatpaverkan": -239.050047,
    "out:Initial Cost/MSEK": 3.9807069836462015,
    "out:Running cost/(Apt SEK y)": 18759.868421,
    "out:Running Cost over RSP/MSEK": 5.59496,
    "out:LCP/MSEK": -1.597577,
    "out:ROI% old": 8.552429,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75205.055911,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160336.96,
    "out:DH kr savings": 75205.0559113811,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3980706.9836462014,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 101.899,
    "out:Total Energy Use Post PV": 97.835188,
    "out:Primary Energy": 85.596769,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.508292,
    "out:CO2 Operational/m2": -79.538718,
    "out:Total CO2/m2": -47.030426,
    "out:Total CO2 (tons)": -123.642962,
    "out:Klimatpaverkan": -243.782035,
    "out:Initial Cost/MSEK": 4.046431971146202,
    "out:Running cost/(Apt SEK y)": 17715.184211,
    "out:Running Cost over RSP/MSEK": 5.28445,
    "out:LCP/MSEK": -1.352792,
    "out:ROI% old": 9.509754,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 347,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 87440.751964,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172572.66,
    "out:DH kr savings": 87440.75196426755,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4046431.9711462017,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.202823,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 104.899,
    "out:Total Energy Use Post PV": 100.835188,
    "out:Primary Energy": 87.116645,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.223393,
    "out:CO2 Operational/m2": -78.458766,
    "out:Total CO2/m2": -45.235373,
    "out:Total CO2 (tons)": -118.923769,
    "out:Klimatpaverkan": -239.062842,
    "out:Initial Cost/MSEK": 4.208386983646202,
    "out:Running cost/(Apt SEK y)": 18341.973684,
    "out:Running Cost over RSP/MSEK": 5.47075,
    "out:LCP/MSEK": -1.701047,
    "out:ROI% old": 8.511371,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 28.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80596.607887,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165728.51,
    "out:DH kr savings": 80596.60788705348,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 218740.172521,
    "out:% savings (space heating)": 23.238103,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 83.2,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4208386.983646202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.548697,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 99.899,
    "out:Total Energy Use Post PV": 95.835188,
    "out:Primary Energy": 83.496769,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.223393,
    "out:CO2 Operational/m2": -80.258686,
    "out:Total CO2/m2": -47.035293,
    "out:Total CO2 (tons)": -123.655757,
    "out:Klimatpaverkan": -243.794831,
    "out:Initial Cost/MSEK": 4.274111971146202,
    "out:Running cost/(Apt SEK y)": 17297.315789,
    "out:Running Cost over RSP/MSEK": 5.16025,
    "out:LCP/MSEK": -1.456272,
    "out:ROI% old": 9.418297,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 35.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 92832.30394,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177964.21,
    "out:DH kr savings": 92832.30393993994,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 206504.476468,
    "out:% savings (space heating)": 27.531942,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 78.55,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4274111.971146202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 94.899,
    "out:Total Energy Use Post PV": 94.899,
    "out:Primary Energy": 87.906885,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.508282,
    "out:CO2 Operational/m2": 31.905387,
    "out:Total CO2/m2": 37.413669,
    "out:Total CO2 (tons)": 98.360513,
    "out:Klimatpaverkan": -21.77856,
    "out:Initial Cost/MSEK": 2.8003847061712017,
    "out:Running cost/(Apt SEK y)": 19129.289474,
    "out:Running Cost over RSP/MSEK": 5.68098,
    "out:LCP/MSEK": -0.503275,
    "out:ROI% old": 11.718339,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 36.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 10,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 107202.080794,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83020.54,
    "out:DH kr savings": 107202.08079381376,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2800384.7061712015,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 90.899,
    "out:Primary Energy": 84.838128,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.508282,
    "out:CO2 Operational/m2": 30.465451,
    "out:Total CO2/m2": 35.973733,
    "out:Total CO2 (tons)": 94.574923,
    "out:Klimatpaverkan": -25.564151,
    "out:Initial Cost/MSEK": 2.866109693671201,
    "out:Running cost/(Apt SEK y)": 18293.552632,
    "out:Running Cost over RSP/MSEK": 5.43258,
    "out:LCP/MSEK": -0.3206,
    "out:ROI% old": 12.687731,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 42.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 325,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117574.915718,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93393.38,
    "out:DH kr savings": 117574.9157175235,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2866109.6936712014,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 91.899,
    "out:Total Energy Use Post PV": 91.899,
    "out:Primary Energy": 85.281885,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.223383,
    "out:CO2 Operational/m2": 30.825435,
    "out:Total CO2/m2": 37.048818,
    "out:Total CO2 (tons)": 97.40132,
    "out:Klimatpaverkan": -22.737753,
    "out:Initial Cost/MSEK": 3.0280647061712016,
    "out:Running cost/(Apt SEK y)": 18502.473684,
    "out:Running Cost over RSP/MSEK": 5.49468,
    "out:LCP/MSEK": -0.544655,
    "out:ROI% old": 11.716158,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 41.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 10,
    "out:Return/kSEK/y": 317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113941.520763,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89759.98,
    "out:DH kr savings": 113941.52076340427,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3028064.7061712015,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 87.899,
    "out:Primary Energy": 82.213128,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.223383,
    "out:CO2 Operational/m2": 29.385499,
    "out:Total CO2/m2": 35.608882,
    "out:Total CO2 (tons)": 93.61573,
    "out:Klimatpaverkan": -26.523344,
    "out:Initial Cost/MSEK": 3.0937896936712015,
    "out:Running cost/(Apt SEK y)": 17666.736842,
    "out:Running Cost over RSP/MSEK": 5.24628,
    "out:LCP/MSEK": -0.36198,
    "out:ROI% old": 12.614257,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124314.355687,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 100132.82,
    "out:DH kr savings": 124314.355687114,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3093789.6936712014,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 94.899,
    "out:Total Energy Use Post PV": 91.185671,
    "out:Primary Energy": 81.222893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.078082,
    "out:CO2 Operational/m2": -16.620224,
    "out:Total CO2/m2": 3.457858,
    "out:Total CO2 (tons)": 9.090707,
    "out:Klimatpaverkan": -111.048367,
    "out:Initial Cost/MSEK": 3.4453679426824517,
    "out:Running cost/(Apt SEK y)": 17632.789474,
    "out:Running Cost over RSP/MSEK": 5.24836,
    "out:LCP/MSEK": -0.715638,
    "out:ROI% old": 11.318426,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 42.9,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 10,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107202.080794,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139887.49,
    "out:DH kr savings": 107202.08079381376,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3445367.9426824516,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 87.185671,
    "out:Primary Energy": 78.154136,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.078082,
    "out:CO2 Operational/m2": -18.06016,
    "out:Total CO2/m2": 2.017922,
    "out:Total CO2 (tons)": 5.305116,
    "out:Klimatpaverkan": -114.833958,
    "out:Initial Cost/MSEK": 3.511092930182451,
    "out:Running cost/(Apt SEK y)": 16797.052632,
    "out:Running Cost over RSP/MSEK": 4.99995,
    "out:LCP/MSEK": -0.532953,
    "out:ROI% old": 12.117269,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 11,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 117574.915718,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150260.33,
    "out:DH kr savings": 117574.9157175235,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3511092.9301824514,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 91.899,
    "out:Total Energy Use Post PV": 88.185671,
    "out:Primary Energy": 78.597893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.793183,
    "out:CO2 Operational/m2": -17.700176,
    "out:Total CO2/m2": 3.093007,
    "out:Total CO2 (tons)": 8.131514,
    "out:Klimatpaverkan": -112.00756,
    "out:Initial Cost/MSEK": 3.6730479426824516,
    "out:Running cost/(Apt SEK y)": 17005.973684,
    "out:Running Cost over RSP/MSEK": 5.06205,
    "out:LCP/MSEK": -0.757008,
    "out:ROI% old": 11.341457,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 10,
    "out:Return/kSEK/y": 374,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113941.520763,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146626.93,
    "out:DH kr savings": 113941.52076340427,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3673047.9426824516,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 84.185671,
    "out:Primary Energy": 75.529136,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.793183,
    "out:CO2 Operational/m2": -19.140112,
    "out:Total CO2/m2": 1.653071,
    "out:Total CO2 (tons)": 4.345923,
    "out:Klimatpaverkan": -115.793151,
    "out:Initial Cost/MSEK": 3.7387729301824515,
    "out:Running cost/(Apt SEK y)": 16170.236842,
    "out:Running Cost over RSP/MSEK": 4.81365,
    "out:LCP/MSEK": -0.574333,
    "out:ROI% old": 12.091209,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 11,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124314.355687,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156999.77,
    "out:DH kr savings": 124314.355687114,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3738772.9301824514,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 94.899,
    "out:Total Energy Use Post PV": 90.835188,
    "out:Primary Energy": 80.592023,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.647882,
    "out:CO2 Operational/m2": -82.058606,
    "out:Total CO2/m2": -47.410724,
    "out:Total CO2 (tons)": -124.642765,
    "out:Klimatpaverkan": -244.781839,
    "out:Initial Cost/MSEK": 4.090351161731202,
    "out:Running cost/(Apt SEK y)": 16252.631579,
    "out:Running Cost over RSP/MSEK": 4.84974,
    "out:LCP/MSEK": -0.962001,
    "out:ROI% old": 10.925887,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 42.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 10,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 107202.080794,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192333.99,
    "out:DH kr savings": 107202.08079381376,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4090351.1617312017,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 86.835188,
    "out:Primary Energy": 77.523266,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.647882,
    "out:CO2 Operational/m2": -83.498542,
    "out:Total CO2/m2": -48.85066,
    "out:Total CO2 (tons)": -128.428356,
    "out:Klimatpaverkan": -248.56743,
    "out:Initial Cost/MSEK": 4.156076149231202,
    "out:Running cost/(Apt SEK y)": 15416.868421,
    "out:Running Cost over RSP/MSEK": 4.60134,
    "out:LCP/MSEK": -0.779326,
    "out:ROI% old": 11.60693,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 10,
    "out:Return/kSEK/y": 434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 117574.915718,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202706.82,
    "out:DH kr savings": 117574.9157175235,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4156076.149231202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 91.899,
    "out:Total Energy Use Post PV": 87.835188,
    "out:Primary Energy": 77.967023,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.362983,
    "out:CO2 Operational/m2": -83.138558,
    "out:Total CO2/m2": -47.775575,
    "out:Total CO2 (tons)": -125.601958,
    "out:Klimatpaverkan": -245.741032,
    "out:Initial Cost/MSEK": 4.318031161731201,
    "out:Running cost/(Apt SEK y)": 15625.815789,
    "out:Running Cost over RSP/MSEK": 4.66344,
    "out:LCP/MSEK": -1.003381,
    "out:ROI% old": 10.966142,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 10,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113941.520763,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199073.43,
    "out:DH kr savings": 113941.52076340427,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4318031.161731201,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 83.835188,
    "out:Primary Energy": 74.898266,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.362983,
    "out:CO2 Operational/m2": -84.578494,
    "out:Total CO2/m2": -49.215511,
    "out:Total CO2 (tons)": -129.387549,
    "out:Klimatpaverkan": -249.526623,
    "out:Initial Cost/MSEK": 4.383756149231202,
    "out:Running cost/(Apt SEK y)": 14790.078947,
    "out:Running Cost over RSP/MSEK": 4.41503,
    "out:LCP/MSEK": -0.820696,
    "out:ROI% old": 11.611243,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 10,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 124314.355687,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209446.26,
    "out:DH kr savings": 124314.355687114,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4383756.149231202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 90.899,
    "out:Primary Energy": 84.406885,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.508282,
    "out:CO2 Operational/m2": 30.465451,
    "out:Total CO2/m2": 35.973733,
    "out:Total CO2 (tons)": 94.574923,
    "out:Klimatpaverkan": -25.564151,
    "out:Initial Cost/MSEK": 2.900678581171201,
    "out:Running cost/(Apt SEK y)": 18293.552632,
    "out:Running Cost over RSP/MSEK": 5.43258,
    "out:LCP/MSEK": -0.355169,
    "out:ROI% old": 12.536525,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 42.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 325,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 116188.000753,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92006.46,
    "out:DH kr savings": 116188.00075326777,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2900678.581171201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 86.899,
    "out:Total Energy Use Post PV": 86.899,
    "out:Primary Energy": 81.338128,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.508282,
    "out:CO2 Operational/m2": 29.025515,
    "out:Total CO2/m2": 34.533797,
    "out:Total CO2 (tons)": 90.789332,
    "out:Klimatpaverkan": -29.349742,
    "out:Initial Cost/MSEK": 2.9664035686712014,
    "out:Running cost/(Apt SEK y)": 17457.789474,
    "out:Running Cost over RSP/MSEK": 5.18417,
    "out:LCP/MSEK": -0.172484,
    "out:ROI% old": 13.455062,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126560.835677,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102379.3,
    "out:DH kr savings": 126560.83567697751,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2966403.5686712014,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.718768,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 88.899,
    "out:Total Energy Use Post PV": 88.899,
    "out:Primary Energy": 82.306885,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.223383,
    "out:CO2 Operational/m2": 29.745483,
    "out:Total CO2/m2": 35.968866,
    "out:Total CO2 (tons)": 94.562127,
    "out:Klimatpaverkan": -25.576946,
    "out:Initial Cost/MSEK": 3.128358581171201,
    "out:Running cost/(Apt SEK y)": 17875.684211,
    "out:Running Cost over RSP/MSEK": 5.30838,
    "out:LCP/MSEK": -0.458649,
    "out:ROI% old": 12.191286,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 341,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121579.552729,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97398.02,
    "out:DH kr savings": 121579.55272894017,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3128358.581171201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 84.899,
    "out:Total Energy Use Post PV": 84.899,
    "out:Primary Energy": 79.238128,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.223383,
    "out:CO2 Operational/m2": 28.305547,
    "out:Total CO2/m2": 34.52893,
    "out:Total CO2 (tons)": 90.776536,
    "out:Klimatpaverkan": -29.362537,
    "out:Initial Cost/MSEK": 3.1940835686712012,
    "out:Running cost/(Apt SEK y)": 17039.921053,
    "out:Running Cost over RSP/MSEK": 5.05997,
    "out:LCP/MSEK": -0.275964,
    "out:ROI% old": 13.051452,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131952.387653,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107770.85,
    "out:DH kr savings": 131952.3876526499,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3194083.5686712014,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 87.185671,
    "out:Primary Energy": 77.722893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.078082,
    "out:CO2 Operational/m2": -18.06016,
    "out:Total CO2/m2": 2.017922,
    "out:Total CO2 (tons)": 5.305116,
    "out:Klimatpaverkan": -114.833958,
    "out:Initial Cost/MSEK": 3.545661817682451,
    "out:Running cost/(Apt SEK y)": 16797.052632,
    "out:Running Cost over RSP/MSEK": 4.99995,
    "out:LCP/MSEK": -0.567522,
    "out:ROI% old": 11.99913,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 11,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116188.000753,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148873.41,
    "out:DH kr savings": 116188.00075326777,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3545661.817682451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 86.899,
    "out:Total Energy Use Post PV": 83.185671,
    "out:Primary Energy": 74.654136,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.078082,
    "out:CO2 Operational/m2": -19.500096,
    "out:Total CO2/m2": 0.577986,
    "out:Total CO2 (tons)": 1.519525,
    "out:Klimatpaverkan": -118.619549,
    "out:Initial Cost/MSEK": 3.6113868051824514,
    "out:Running cost/(Apt SEK y)": 15961.315789,
    "out:Running Cost over RSP/MSEK": 4.75155,
    "out:LCP/MSEK": -0.384847,
    "out:ROI% old": 12.76336,
    "out:Payback discounted": 9,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 56.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 11,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126560.835677,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159246.25,
    "out:DH kr savings": 126560.83567697751,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3611386.8051824514,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.718768,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 88.899,
    "out:Total Energy Use Post PV": 85.185671,
    "out:Primary Energy": 75.622893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.793183,
    "out:CO2 Operational/m2": -18.780128,
    "out:Total CO2/m2": 2.013055,
    "out:Total CO2 (tons)": 5.29232,
    "out:Klimatpaverkan": -114.846753,
    "out:Initial Cost/MSEK": 3.773341817682451,
    "out:Running cost/(Apt SEK y)": 16379.184211,
    "out:Running Cost over RSP/MSEK": 4.87575,
    "out:LCP/MSEK": -0.671002,
    "out:ROI% old": 11.745329,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 11,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121579.552729,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154264.97,
    "out:DH kr savings": 121579.55272894017,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3773341.817682451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 84.899,
    "out:Total Energy Use Post PV": 81.185671,
    "out:Primary Energy": 72.554136,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.793183,
    "out:CO2 Operational/m2": -20.220064,
    "out:Total CO2/m2": 0.573119,
    "out:Total CO2 (tons)": 1.50673,
    "out:Klimatpaverkan": -118.632344,
    "out:Initial Cost/MSEK": 3.8390668051824512,
    "out:Running cost/(Apt SEK y)": 15543.421053,
    "out:Running Cost over RSP/MSEK": 4.62735,
    "out:LCP/MSEK": -0.488327,
    "out:ROI% old": 12.468581,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 11,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131952.387653,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164637.8,
    "out:DH kr savings": 131952.3876526499,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3839066.8051824514,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 86.835188,
    "out:Primary Energy": 77.092023,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.647882,
    "out:CO2 Operational/m2": -83.498542,
    "out:Total CO2/m2": -48.85066,
    "out:Total CO2 (tons)": -128.428356,
    "out:Klimatpaverkan": -248.56743,
    "out:Initial Cost/MSEK": 4.190645036731202,
    "out:Running cost/(Apt SEK y)": 15416.868421,
    "out:Running Cost over RSP/MSEK": 4.60134,
    "out:LCP/MSEK": -0.813895,
    "out:ROI% old": 11.511184,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 10,
    "out:Return/kSEK/y": 434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116188.000753,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201319.91,
    "out:DH kr savings": 116188.00075326777,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4190645.036731202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 86.899,
    "out:Total Energy Use Post PV": 82.835188,
    "out:Primary Energy": 74.023266,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.647882,
    "out:CO2 Operational/m2": -84.938478,
    "out:Total CO2/m2": -50.290596,
    "out:Total CO2 (tons)": -132.213947,
    "out:Klimatpaverkan": -252.35302,
    "out:Initial Cost/MSEK": 4.2563700242312015,
    "out:Running cost/(Apt SEK y)": 14581.131579,
    "out:Running Cost over RSP/MSEK": 4.35293,
    "out:LCP/MSEK": -0.63121,
    "out:ROI% old": 12.167175,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 56.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 11,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 126560.835677,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211692.74,
    "out:DH kr savings": 126560.83567697751,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4256370.024231201,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.718768,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.614024,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 88.899,
    "out:Total Energy Use Post PV": 84.835188,
    "out:Primary Energy": 74.992023,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.362983,
    "out:CO2 Operational/m2": -84.21851,
    "out:Total CO2/m2": -48.855527,
    "out:Total CO2 (tons)": -128.441151,
    "out:Klimatpaverkan": -248.580225,
    "out:Initial Cost/MSEK": 4.418325036731202,
    "out:Running cost/(Apt SEK y)": 14999,
    "out:Running Cost over RSP/MSEK": 4.47713,
    "out:LCP/MSEK": -0.917365,
    "out:ROI% old": 11.31961,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 10,
    "out:Return/kSEK/y": 450,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121579.552729,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206711.46,
    "out:DH kr savings": 121579.55272894017,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 177757.227679,
    "out:% savings (space heating)": 37.620137,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 67.61,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4418325.036731202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.668479,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 84.899,
    "out:Total Energy Use Post PV": 80.835188,
    "out:Primary Energy": 71.923266,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.362983,
    "out:CO2 Operational/m2": -85.658446,
    "out:Total CO2/m2": -50.295463,
    "out:Total CO2 (tons)": -132.226742,
    "out:Klimatpaverkan": -252.365816,
    "out:Initial Cost/MSEK": 4.484050024231201,
    "out:Running cost/(Apt SEK y)": 14163.263158,
    "out:Running Cost over RSP/MSEK": 4.22873,
    "out:LCP/MSEK": -0.73469,
    "out:ROI% old": 11.945069,
    "out:Payback discounted": 10,
    "out:Atemp": 2629,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 11,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 131952.387653,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217084.29,
    "out:DH kr savings": 131952.3876526499,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 167384.392755,
    "out:% savings (space heating)": 41.260248,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 63.67,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4484050.024231201,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 92.899,
    "out:Total Energy Use Post PV": 92.899,
    "out:Primary Energy": 86.874679,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.436956,
    "out:CO2 Operational/m2": 31.185419,
    "out:Total CO2/m2": 45.622375,
    "out:Total CO2 (tons)": 119.941197,
    "out:Klimatpaverkan": -0.197877,
    "out:Initial Cost/MSEK": 4.688571653086202,
    "out:Running cost/(Apt SEK y)": 18711.421053,
    "out:Running Cost over RSP/MSEK": 5.55678,
    "out:LCP/MSEK": -2.267262,
    "out:ROI% old": 7.377544,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 110691.082162,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86509.55,
    "out:DH kr savings": 110691.08216212227,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4688571.653086201,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 88.899,
    "out:Total Energy Use Post PV": 88.899,
    "out:Primary Energy": 83.852841,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.436956,
    "out:CO2 Operational/m2": 29.745483,
    "out:Total CO2/m2": 44.182439,
    "out:Total CO2 (tons)": 116.155606,
    "out:Klimatpaverkan": -3.983468,
    "out:Initial Cost/MSEK": 4.754296640586202,
    "out:Running cost/(Apt SEK y)": 17875.684211,
    "out:Running Cost over RSP/MSEK": 5.30838,
    "out:LCP/MSEK": -2.084587,
    "out:ROI% old": 8.021947,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 341,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 120905.326115,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96723.79,
    "out:DH kr savings": 120905.32611454526,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4754296.640586202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 90.899,
    "out:Primary Energy": 84.249679,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.152057,
    "out:CO2 Operational/m2": 30.465451,
    "out:Total CO2/m2": 45.617508,
    "out:Total CO2 (tons)": 119.928401,
    "out:Klimatpaverkan": -0.210672,
    "out:Initial Cost/MSEK": 4.916251653086201,
    "out:Running cost/(Apt SEK y)": 18293.552632,
    "out:Running Cost over RSP/MSEK": 5.43258,
    "out:LCP/MSEK": -2.370742,
    "out:ROI% old": 7.396779,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 42.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 325,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 117430.522132,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93248.99,
    "out:DH kr savings": 117430.52213171279,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4916251.653086201,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 86.899,
    "out:Total Energy Use Post PV": 86.899,
    "out:Primary Energy": 81.227841,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.152057,
    "out:CO2 Operational/m2": 29.025515,
    "out:Total CO2/m2": 44.177572,
    "out:Total CO2 (tons)": 116.14281,
    "out:Klimatpaverkan": -3.996263,
    "out:Initial Cost/MSEK": 4.981976640586202,
    "out:Running cost/(Apt SEK y)": 17457.789474,
    "out:Running Cost over RSP/MSEK": 5.18417,
    "out:LCP/MSEK": -2.188057,
    "out:ROI% old": 8.011507,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 127644.766084,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103463.23,
    "out:DH kr savings": 127644.76608413577,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4981976.640586202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.718768,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 92.899,
    "out:Total Energy Use Post PV": 89.185671,
    "out:Primary Energy": 80.190687,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.006756,
    "out:CO2 Operational/m2": -17.340192,
    "out:Total CO2/m2": 11.666564,
    "out:Total CO2 (tons)": 30.67139,
    "out:Klimatpaverkan": -89.467684,
    "out:Initial Cost/MSEK": 5.333554889597451,
    "out:Running cost/(Apt SEK y)": 17214.921053,
    "out:Running Cost over RSP/MSEK": 5.12416,
    "out:LCP/MSEK": -2.479625,
    "out:ROI% old": 7.644138,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 366,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110691.082162,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143376.49,
    "out:DH kr savings": 110691.08216212227,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5333554.889597451,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 88.899,
    "out:Total Energy Use Post PV": 85.185671,
    "out:Primary Energy": 77.168849,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.006756,
    "out:CO2 Operational/m2": -18.780128,
    "out:Total CO2/m2": 10.226628,
    "out:Total CO2 (tons)": 26.885799,
    "out:Klimatpaverkan": -93.253275,
    "out:Initial Cost/MSEK": 5.399279877097452,
    "out:Running cost/(Apt SEK y)": 16379.184211,
    "out:Running Cost over RSP/MSEK": 4.87575,
    "out:LCP/MSEK": -2.29694,
    "out:ROI% old": 8.208343,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 120905.326115,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153590.74,
    "out:DH kr savings": 120905.32611454526,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5399279.877097452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 87.185671,
    "out:Primary Energy": 77.565687,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.721857,
    "out:CO2 Operational/m2": -18.06016,
    "out:Total CO2/m2": 11.661697,
    "out:Total CO2 (tons)": 30.658594,
    "out:Klimatpaverkan": -89.480479,
    "out:Initial Cost/MSEK": 5.5612348895974515,
    "out:Running cost/(Apt SEK y)": 16797.052632,
    "out:Running Cost over RSP/MSEK": 4.99995,
    "out:LCP/MSEK": -2.583095,
    "out:ROI% old": 7.650254,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117430.522132,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150115.93,
    "out:DH kr savings": 117430.52213171279,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5561234.889597451,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 86.899,
    "out:Total Energy Use Post PV": 83.185671,
    "out:Primary Energy": 74.543849,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.721857,
    "out:CO2 Operational/m2": -19.500096,
    "out:Total CO2/m2": 10.221761,
    "out:Total CO2 (tons)": 26.873004,
    "out:Klimatpaverkan": -93.26607,
    "out:Initial Cost/MSEK": 5.626959877097452,
    "out:Running cost/(Apt SEK y)": 15961.315789,
    "out:Running Cost over RSP/MSEK": 4.75155,
    "out:LCP/MSEK": -2.40042,
    "out:ROI% old": 8.191533,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 56.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 127644.766084,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160330.18,
    "out:DH kr savings": 127644.76608413577,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5626959.877097452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.718768,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 92.899,
    "out:Total Energy Use Post PV": 88.835188,
    "out:Primary Energy": 79.559817,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.576556,
    "out:CO2 Operational/m2": -82.778574,
    "out:Total CO2/m2": -39.202018,
    "out:Total CO2 (tons)": -103.062082,
    "out:Klimatpaverkan": -223.201155,
    "out:Initial Cost/MSEK": 5.978538108646201,
    "out:Running cost/(Apt SEK y)": 15834.736842,
    "out:Running Cost over RSP/MSEK": 4.72554,
    "out:LCP/MSEK": -2.725988,
    "out:ROI% old": 7.771967,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110691.082162,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195822.99,
    "out:DH kr savings": 110691.08216212227,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5978538.108646201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 88.899,
    "out:Total Energy Use Post PV": 84.835188,
    "out:Primary Energy": 76.537979,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.576556,
    "out:CO2 Operational/m2": -84.21851,
    "out:Total CO2/m2": -40.641954,
    "out:Total CO2 (tons)": -106.847673,
    "out:Klimatpaverkan": -226.986746,
    "out:Initial Cost/MSEK": 6.044263096146202,
    "out:Running cost/(Apt SEK y)": 14999,
    "out:Running Cost over RSP/MSEK": 4.47713,
    "out:LCP/MSEK": -2.543303,
    "out:ROI% old": 8.274576,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 450,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 120905.326115,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206037.23,
    "out:DH kr savings": 120905.32611454526,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6044263.096146202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 90.899,
    "out:Total Energy Use Post PV": 86.835188,
    "out:Primary Energy": 76.934817,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.291657,
    "out:CO2 Operational/m2": -83.498542,
    "out:Total CO2/m2": -39.206885,
    "out:Total CO2 (tons)": -103.074877,
    "out:Klimatpaverkan": -223.213951,
    "out:Initial Cost/MSEK": 6.206218108646201,
    "out:Running cost/(Apt SEK y)": 15416.868421,
    "out:Running Cost over RSP/MSEK": 4.60134,
    "out:LCP/MSEK": -2.829468,
    "out:ROI% old": 7.772735,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 49.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117430.522132,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202562.43,
    "out:DH kr savings": 117430.52213171279,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6206218.108646201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 86.899,
    "out:Total Energy Use Post PV": 82.835188,
    "out:Primary Energy": 73.912979,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.291657,
    "out:CO2 Operational/m2": -84.938478,
    "out:Total CO2/m2": -40.646821,
    "out:Total CO2 (tons)": -106.860468,
    "out:Klimatpaverkan": -226.999542,
    "out:Initial Cost/MSEK": 6.2719430961462015,
    "out:Running cost/(Apt SEK y)": 14581.131579,
    "out:Running Cost over RSP/MSEK": 4.35293,
    "out:LCP/MSEK": -2.646783,
    "out:ROI% old": 8.25709,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 56.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 127644.766084,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212776.67,
    "out:DH kr savings": 127644.76608413577,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6271943.096146202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.718768,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 89.899,
    "out:Total Energy Use Post PV": 89.899,
    "out:Primary Energy": 83.374679,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.436956,
    "out:CO2 Operational/m2": 30.105467,
    "out:Total CO2/m2": 44.542423,
    "out:Total CO2 (tons)": 117.102003,
    "out:Klimatpaverkan": -3.03707,
    "out:Initial Cost/MSEK": 4.788865528086201,
    "out:Running cost/(Apt SEK y)": 18084.605263,
    "out:Running Cost over RSP/MSEK": 5.37048,
    "out:LCP/MSEK": -2.181256,
    "out:ROI% old": 7.778788,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 119677.002122,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95495.47,
    "out:DH kr savings": 119677.00212157628,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4788865.528086201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.79872,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 85.899,
    "out:Total Energy Use Post PV": 85.899,
    "out:Primary Energy": 80.352841,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.436956,
    "out:CO2 Operational/m2": 28.665531,
    "out:Total CO2/m2": 43.102487,
    "out:Total CO2 (tons)": 113.316413,
    "out:Klimatpaverkan": -6.822661,
    "out:Initial Cost/MSEK": 4.854590515586201,
    "out:Running cost/(Apt SEK y)": 17248.868421,
    "out:Running Cost over RSP/MSEK": 5.12207,
    "out:LCP/MSEK": -1.998571,
    "out:ROI% old": 8.404475,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 129891.246074,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105709.71,
    "out:DH kr savings": 129891.24607399928,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4854590.515586201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 87.899,
    "out:Primary Energy": 81.274679,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.152057,
    "out:CO2 Operational/m2": 29.385499,
    "out:Total CO2/m2": 44.537556,
    "out:Total CO2 (tons)": 117.089208,
    "out:Klimatpaverkan": -3.049865,
    "out:Initial Cost/MSEK": 5.016545528086201,
    "out:Running cost/(Apt SEK y)": 17666.736842,
    "out:Running Cost over RSP/MSEK": 5.24628,
    "out:LCP/MSEK": -2.284736,
    "out:ROI% old": 7.779428,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 125068.554097,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100887.02,
    "out:DH kr savings": 125068.55409724868,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5016545.528086201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 83.899,
    "out:Primary Energy": 78.252841,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.152057,
    "out:CO2 Operational/m2": 27.945563,
    "out:Total CO2/m2": 43.09762,
    "out:Total CO2 (tons)": 113.303617,
    "out:Klimatpaverkan": -6.835456,
    "out:Initial Cost/MSEK": 5.082270515586201,
    "out:Running cost/(Apt SEK y)": 16831,
    "out:Running Cost over RSP/MSEK": 4.99787,
    "out:LCP/MSEK": -2.102051,
    "out:ROI% old": 8.377077,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 135282.79805,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111101.26,
    "out:DH kr savings": 135282.79804967166,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5082270.515586201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 89.899,
    "out:Total Energy Use Post PV": 86.185671,
    "out:Primary Energy": 76.690687,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.006756,
    "out:CO2 Operational/m2": -18.420144,
    "out:Total CO2/m2": 10.586612,
    "out:Total CO2 (tons)": 27.832197,
    "out:Klimatpaverkan": -92.306877,
    "out:Initial Cost/MSEK": 5.433848764597452,
    "out:Running cost/(Apt SEK y)": 16588.105263,
    "out:Running Cost over RSP/MSEK": 4.93785,
    "out:LCP/MSEK": -2.393609,
    "out:ROI% old": 7.992861,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119677.002122,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152362.41,
    "out:DH kr savings": 119677.00212157628,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5433848.764597452,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.79872,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 85.899,
    "out:Total Energy Use Post PV": 82.185671,
    "out:Primary Energy": 73.668849,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.006756,
    "out:CO2 Operational/m2": -19.86008,
    "out:Total CO2/m2": 9.146676,
    "out:Total CO2 (tons)": 24.046606,
    "out:Klimatpaverkan": -96.092468,
    "out:Initial Cost/MSEK": 5.499573752097452,
    "out:Running cost/(Apt SEK y)": 15752.368421,
    "out:Running Cost over RSP/MSEK": 4.68945,
    "out:LCP/MSEK": -2.210934,
    "out:ROI% old": 8.542584,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 422,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 129891.246074,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162576.66,
    "out:DH kr savings": 129891.24607399928,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5499573.752097452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 84.185671,
    "out:Primary Energy": 74.590687,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.721857,
    "out:CO2 Operational/m2": -19.140112,
    "out:Total CO2/m2": 10.581745,
    "out:Total CO2 (tons)": 27.819401,
    "out:Klimatpaverkan": -92.319672,
    "out:Initial Cost/MSEK": 5.661528764597453,
    "out:Running cost/(Apt SEK y)": 16170.236842,
    "out:Running Cost over RSP/MSEK": 4.81365,
    "out:LCP/MSEK": -2.497089,
    "out:ROI% old": 7.98482,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 125068.554097,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157753.97,
    "out:DH kr savings": 125068.55409724868,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5661528.764597452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 80.185671,
    "out:Primary Energy": 71.568849,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.721857,
    "out:CO2 Operational/m2": -20.580048,
    "out:Total CO2/m2": 9.141809,
    "out:Total CO2 (tons)": 24.03381,
    "out:Klimatpaverkan": -96.105263,
    "out:Initial Cost/MSEK": 5.727253752097452,
    "out:Running cost/(Apt SEK y)": 15334.5,
    "out:Running Cost over RSP/MSEK": 4.56525,
    "out:LCP/MSEK": -2.314414,
    "out:ROI% old": 8.512781,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135282.79805,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167968.21,
    "out:DH kr savings": 135282.79804967166,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5727253.752097452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 89.899,
    "out:Total Energy Use Post PV": 85.835188,
    "out:Primary Energy": 76.059817,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.576556,
    "out:CO2 Operational/m2": -83.858526,
    "out:Total CO2/m2": -40.28197,
    "out:Total CO2 (tons)": -105.901275,
    "out:Klimatpaverkan": -226.040349,
    "out:Initial Cost/MSEK": 6.078831983646201,
    "out:Running cost/(Apt SEK y)": 15207.947368,
    "out:Running Cost over RSP/MSEK": 4.53923,
    "out:LCP/MSEK": -2.639972,
    "out:ROI% old": 8.081581,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 442,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119677.002122,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204808.91,
    "out:DH kr savings": 119677.00212157628,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6078831.983646201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.79872,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 85.899,
    "out:Total Energy Use Post PV": 81.835188,
    "out:Primary Energy": 73.037979,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.576556,
    "out:CO2 Operational/m2": -85.298462,
    "out:Total CO2/m2": -41.721906,
    "out:Total CO2 (tons)": -109.686866,
    "out:Klimatpaverkan": -229.82594,
    "out:Initial Cost/MSEK": 6.144556971146201,
    "out:Running cost/(Apt SEK y)": 14372.184211,
    "out:Running Cost over RSP/MSEK": 4.29083,
    "out:LCP/MSEK": -2.457297,
    "out:ROI% old": 8.572651,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 129891.246074,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215023.15,
    "out:DH kr savings": 129891.24607399928,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6144556.971146202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.286902,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 83.835188,
    "out:Primary Energy": 73.959817,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.291657,
    "out:CO2 Operational/m2": -84.578494,
    "out:Total CO2/m2": -40.286837,
    "out:Total CO2 (tons)": -105.91407,
    "out:Klimatpaverkan": -226.053144,
    "out:Initial Cost/MSEK": 6.306511983646201,
    "out:Running cost/(Apt SEK y)": 14790.078947,
    "out:Running Cost over RSP/MSEK": 4.41503,
    "out:LCP/MSEK": -2.743452,
    "out:ROI% old": 8.071158,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 125068.554097,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210200.46,
    "out:DH kr savings": 125068.55409724868,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 174268.226311,
    "out:% savings (space heating)": 38.844523,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 66.29,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6306511.983646201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.401681,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 79.835188,
    "out:Primary Energy": 70.937979,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.291657,
    "out:CO2 Operational/m2": -86.01843,
    "out:Total CO2/m2": -41.726773,
    "out:Total CO2 (tons)": -109.699661,
    "out:Klimatpaverkan": -229.838735,
    "out:Initial Cost/MSEK": 6.372236971146202,
    "out:Running cost/(Apt SEK y)": 13954.315789,
    "out:Running Cost over RSP/MSEK": 4.16663,
    "out:LCP/MSEK": -2.560777,
    "out:ROI% old": 8.54479,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135282.79805,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220414.7,
    "out:DH kr savings": 135282.79804967166,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 164053.982358,
    "out:% savings (space heating)": 42.42898,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 62.4,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6372236.971146202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 82.899,
    "out:Total Energy Use Post PV": 82.899,
    "out:Primary Energy": 79.078117,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.839173,
    "out:CO2 Operational/m2": 27.585579,
    "out:Total CO2/m2": 43.424752,
    "out:Total CO2 (tons)": 114.163647,
    "out:Klimatpaverkan": -5.975426,
    "out:Initial Cost/MSEK": 4.898509706171201,
    "out:Running cost/(Apt SEK y)": 16622.052632,
    "out:Running Cost over RSP/MSEK": 4.93577,
    "out:LCP/MSEK": -1.85619,
    "out:ROI% old": 8.872436,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 56.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 137044.570471,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112863.03,
    "out:DH kr savings": 137044.57047120383,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4898509.706171202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.278832,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 79.899,
    "out:Total Energy Use Post PV": 79.899,
    "out:Primary Energy": 76.410668,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.839173,
    "out:CO2 Operational/m2": 26.505627,
    "out:Total CO2/m2": 42.3448,
    "out:Total CO2 (tons)": 111.324454,
    "out:Klimatpaverkan": -8.81462,
    "out:Initial Cost/MSEK": 4.964234693671202,
    "out:Running cost/(Apt SEK y)": 15995.236842,
    "out:Running Cost over RSP/MSEK": 4.74946,
    "out:LCP/MSEK": -1.735605,
    "out:ROI% old": 9.291117,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 146060.928591,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121879.39,
    "out:DH kr savings": 146060.92859139584,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4964234.693671201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 80.899,
    "out:Primary Energy": 76.453117,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.554274,
    "out:CO2 Operational/m2": 26.865611,
    "out:Total CO2/m2": 43.419885,
    "out:Total CO2 (tons)": 114.150852,
    "out:Klimatpaverkan": -5.988222,
    "out:Initial Cost/MSEK": 5.126189706171202,
    "out:Running cost/(Apt SEK y)": 16204.184211,
    "out:Running Cost over RSP/MSEK": 4.81157,
    "out:LCP/MSEK": -1.95967,
    "out:ROI% old": 8.824488,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 143784.010441,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119602.47,
    "out:DH kr savings": 143784.01044079434,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5126189.706171202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 76.899,
    "out:Total Energy Use Post PV": 76.899,
    "out:Primary Energy": 73.785668,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.554274,
    "out:CO2 Operational/m2": 25.425675,
    "out:Total CO2/m2": 41.979949,
    "out:Total CO2 (tons)": 110.365261,
    "out:Klimatpaverkan": -9.773813,
    "out:Initial Cost/MSEK": 5.191914693671201,
    "out:Running cost/(Apt SEK y)": 15368.447368,
    "out:Running Cost over RSP/MSEK": 4.56316,
    "out:LCP/MSEK": -1.776985,
    "out:ROI% old": 9.396286,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 152800.368561,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128618.83,
    "out:DH kr savings": 152800.36856098636,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5191914.693671201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 82.899,
    "out:Total Energy Use Post PV": 79.185671,
    "out:Primary Energy": 72.394125,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.408973,
    "out:CO2 Operational/m2": -20.940032,
    "out:Total CO2/m2": 9.468941,
    "out:Total CO2 (tons)": 24.89384,
    "out:Klimatpaverkan": -95.245233,
    "out:Initial Cost/MSEK": 5.543492942682451,
    "out:Running cost/(Apt SEK y)": 15125.552632,
    "out:Running Cost over RSP/MSEK": 4.50314,
    "out:LCP/MSEK": -2.068543,
    "out:ROI% old": 8.95503,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 137044.570471,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169729.98,
    "out:DH kr savings": 137044.57047120383,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5543492.942682451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.278832,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 79.899,
    "out:Total Energy Use Post PV": 76.185671,
    "out:Primary Energy": 69.726676,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.408973,
    "out:CO2 Operational/m2": -22.019984,
    "out:Total CO2/m2": 8.388989,
    "out:Total CO2 (tons)": 22.054647,
    "out:Klimatpaverkan": -98.084426,
    "out:Initial Cost/MSEK": 5.609217930182451,
    "out:Running cost/(Apt SEK y)": 14498.736842,
    "out:Running Cost over RSP/MSEK": 4.31684,
    "out:LCP/MSEK": -1.947968,
    "out:ROI% old": 9.324575,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 71.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 469,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146060.928591,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178746.34,
    "out:DH kr savings": 146060.92859139584,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5609217.930182451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 77.185671,
    "out:Primary Energy": 69.769125,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.124074,
    "out:CO2 Operational/m2": -21.66,
    "out:Total CO2/m2": 9.464074,
    "out:Total CO2 (tons)": 24.881045,
    "out:Klimatpaverkan": -95.258029,
    "out:Initial Cost/MSEK": 5.77117294268245,
    "out:Running cost/(Apt SEK y)": 14707.684211,
    "out:Running Cost over RSP/MSEK": 4.37894,
    "out:LCP/MSEK": -2.172023,
    "out:ROI% old": 8.909182,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 143784.010441,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176469.42,
    "out:DH kr savings": 143784.01044079434,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5771172.942682451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 76.899,
    "out:Total Energy Use Post PV": 73.185671,
    "out:Primary Energy": 67.101676,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.124074,
    "out:CO2 Operational/m2": -23.099936,
    "out:Total CO2/m2": 8.024138,
    "out:Total CO2 (tons)": 21.095454,
    "out:Klimatpaverkan": -99.04362,
    "out:Initial Cost/MSEK": 5.8368979301824515,
    "out:Running cost/(Apt SEK y)": 13871.947368,
    "out:Running Cost over RSP/MSEK": 4.13054,
    "out:LCP/MSEK": -1.989348,
    "out:ROI% old": 9.416817,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 152800.368561,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185485.78,
    "out:DH kr savings": 152800.36856098636,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5836897.930182451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 82.899,
    "out:Total Energy Use Post PV": 78.835188,
    "out:Primary Energy": 71.763255,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.978773,
    "out:CO2 Operational/m2": -86.378414,
    "out:Total CO2/m2": -41.399641,
    "out:Total CO2 (tons)": -108.839631,
    "out:Klimatpaverkan": -228.978705,
    "out:Initial Cost/MSEK": 6.188476161731202,
    "out:Running cost/(Apt SEK y)": 13745.394737,
    "out:Running Cost over RSP/MSEK": 4.10453,
    "out:LCP/MSEK": -2.314916,
    "out:ROI% old": 8.941874,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 137044.570471,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222176.48,
    "out:DH kr savings": 137044.57047120383,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6188476.161731202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.278832,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 79.899,
    "out:Total Energy Use Post PV": 75.835188,
    "out:Primary Energy": 69.095806,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.978773,
    "out:CO2 Operational/m2": -87.458366,
    "out:Total CO2/m2": -42.479593,
    "out:Total CO2 (tons)": -111.678825,
    "out:Klimatpaverkan": -231.817898,
    "out:Initial Cost/MSEK": 6.254201149231203,
    "out:Running cost/(Apt SEK y)": 13118.578947,
    "out:Running Cost over RSP/MSEK": 3.91822,
    "out:LCP/MSEK": -2.194331,
    "out:ROI% old": 9.273469,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 71.1,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146060.928591,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231192.83,
    "out:DH kr savings": 146060.92859139584,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6254201.149231203,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 76.835188,
    "out:Primary Energy": 69.138255,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.693874,
    "out:CO2 Operational/m2": -87.098382,
    "out:Total CO2/m2": -41.404508,
    "out:Total CO2 (tons)": -108.852427,
    "out:Klimatpaverkan": -228.9915,
    "out:Initial Cost/MSEK": 6.416156161731202,
    "out:Running cost/(Apt SEK y)": 13327.526316,
    "out:Running Cost over RSP/MSEK": 3.98032,
    "out:LCP/MSEK": -2.418386,
    "out:ROI% old": 8.901124,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143784.010441,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228915.92,
    "out:DH kr savings": 143784.01044079434,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6416156.161731202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 76.899,
    "out:Total Energy Use Post PV": 72.835188,
    "out:Primary Energy": 66.470806,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.693874,
    "out:CO2 Operational/m2": -88.538318,
    "out:Total CO2/m2": -42.844444,
    "out:Total CO2 (tons)": -112.638018,
    "out:Klimatpaverkan": -232.777091,
    "out:Initial Cost/MSEK": 6.481881149231203,
    "out:Running cost/(Apt SEK y)": 12491.763158,
    "out:Running Cost over RSP/MSEK": 3.73192,
    "out:LCP/MSEK": -2.235711,
    "out:ROI% old": 9.358328,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152800.368561,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237932.27,
    "out:DH kr savings": 152800.36856098636,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6481881.149231203,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 79.899,
    "out:Total Energy Use Post PV": 79.899,
    "out:Primary Energy": 75.578117,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.839173,
    "out:CO2 Operational/m2": 26.505627,
    "out:Total CO2/m2": 42.3448,
    "out:Total CO2 (tons)": 111.324454,
    "out:Klimatpaverkan": -8.81462,
    "out:Initial Cost/MSEK": 4.9988035811712015,
    "out:Running cost/(Apt SEK y)": 15995.236842,
    "out:Running Cost over RSP/MSEK": 4.74946,
    "out:LCP/MSEK": -1.770174,
    "out:ROI% old": 9.226865,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 146030.490431,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121848.95,
    "out:DH kr savings": 146030.49043065784,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4998803.581171202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 76.899,
    "out:Total Energy Use Post PV": 76.899,
    "out:Primary Energy": 72.910668,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 15.839173,
    "out:CO2 Operational/m2": 25.425675,
    "out:Total CO2/m2": 41.264848,
    "out:Total CO2 (tons)": 108.485261,
    "out:Klimatpaverkan": -11.653813,
    "out:Initial Cost/MSEK": 5.064528568671203,
    "out:Running cost/(Apt SEK y)": 15368.447368,
    "out:Running Cost over RSP/MSEK": 4.56316,
    "out:LCP/MSEK": -1.649599,
    "out:ROI% old": 9.632627,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 155046.848551,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130865.31,
    "out:DH kr savings": 155046.84855084989,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5064528.568671202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 77.899,
    "out:Total Energy Use Post PV": 77.899,
    "out:Primary Energy": 73.478117,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.554274,
    "out:CO2 Operational/m2": 25.785659,
    "out:Total CO2/m2": 42.339933,
    "out:Total CO2 (tons)": 111.311659,
    "out:Klimatpaverkan": -8.827415,
    "out:Initial Cost/MSEK": 5.226483581171202,
    "out:Running cost/(Apt SEK y)": 15577.368421,
    "out:Running Cost over RSP/MSEK": 4.62526,
    "out:LCP/MSEK": -1.873654,
    "out:ROI% old": 9.164397,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 151422.042406,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127240.51,
    "out:DH kr savings": 151422.04240633023,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5226483.581171202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.478912,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 73.899,
    "out:Total Energy Use Post PV": 73.899,
    "out:Primary Energy": 70.810668,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.554274,
    "out:CO2 Operational/m2": 24.345723,
    "out:Total CO2/m2": 40.899997,
    "out:Total CO2 (tons)": 107.526068,
    "out:Klimatpaverkan": -12.613006,
    "out:Initial Cost/MSEK": 5.292208568671201,
    "out:Running cost/(Apt SEK y)": 14741.631579,
    "out:Running Cost over RSP/MSEK": 4.37686,
    "out:LCP/MSEK": -1.690979,
    "out:ROI% old": 9.72111,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160438.400527,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136256.86,
    "out:DH kr savings": 160438.40052652225,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5292208.568671201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 79.899,
    "out:Total Energy Use Post PV": 76.185671,
    "out:Primary Energy": 68.894125,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.408973,
    "out:CO2 Operational/m2": -22.019984,
    "out:Total CO2/m2": 8.388989,
    "out:Total CO2 (tons)": 22.054647,
    "out:Klimatpaverkan": -98.084426,
    "out:Initial Cost/MSEK": 5.643786817682452,
    "out:Running cost/(Apt SEK y)": 14498.736842,
    "out:Running Cost over RSP/MSEK": 4.31684,
    "out:LCP/MSEK": -1.982537,
    "out:ROI% old": 9.267461,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 71.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 469,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146030.490431,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178715.9,
    "out:DH kr savings": 146030.49043065784,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5643786.817682452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 76.899,
    "out:Total Energy Use Post PV": 73.185671,
    "out:Primary Energy": 66.226676,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.408973,
    "out:CO2 Operational/m2": -23.099936,
    "out:Total CO2/m2": 7.309037,
    "out:Total CO2 (tons)": 19.215454,
    "out:Klimatpaverkan": -100.92362,
    "out:Initial Cost/MSEK": 5.709511805182452,
    "out:Running cost/(Apt SEK y)": 13871.947368,
    "out:Running Cost over RSP/MSEK": 4.13054,
    "out:LCP/MSEK": -1.861962,
    "out:ROI% old": 9.626918,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 155046.848551,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187732.26,
    "out:DH kr savings": 155046.84855084989,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5709511.805182452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 77.899,
    "out:Total Energy Use Post PV": 74.185671,
    "out:Primary Energy": 66.794125,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.124074,
    "out:CO2 Operational/m2": -22.739952,
    "out:Total CO2/m2": 8.384122,
    "out:Total CO2 (tons)": 22.041852,
    "out:Klimatpaverkan": -98.097222,
    "out:Initial Cost/MSEK": 5.871466817682451,
    "out:Running cost/(Apt SEK y)": 14080.868421,
    "out:Running Cost over RSP/MSEK": 4.19264,
    "out:LCP/MSEK": -2.086017,
    "out:ROI% old": 9.210281,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 485,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 151422.042406,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184107.45,
    "out:DH kr savings": 151422.04240633023,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5871466.817682452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.478912,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 73.899,
    "out:Total Energy Use Post PV": 70.185671,
    "out:Primary Energy": 64.126676,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.124074,
    "out:CO2 Operational/m2": -24.179888,
    "out:Total CO2/m2": 6.944186,
    "out:Total CO2 (tons)": 18.256261,
    "out:Klimatpaverkan": -101.882813,
    "out:Initial Cost/MSEK": 5.937191805182453,
    "out:Running cost/(Apt SEK y)": 13245.131579,
    "out:Running Cost over RSP/MSEK": 3.94423,
    "out:LCP/MSEK": -1.903332,
    "out:ROI% old": 9.706032,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 85.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160438.400527,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193123.81,
    "out:DH kr savings": 160438.40052652225,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5937191.805182452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 79.899,
    "out:Total Energy Use Post PV": 75.835188,
    "out:Primary Energy": 68.263255,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.978773,
    "out:CO2 Operational/m2": -87.458366,
    "out:Total CO2/m2": -42.479593,
    "out:Total CO2 (tons)": -111.678825,
    "out:Klimatpaverkan": -231.817898,
    "out:Initial Cost/MSEK": 6.2887700367312025,
    "out:Running cost/(Apt SEK y)": 13118.578947,
    "out:Running Cost over RSP/MSEK": 3.91822,
    "out:LCP/MSEK": -2.2289,
    "out:ROI% old": 9.222494,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 71.1,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146030.490431,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231162.4,
    "out:DH kr savings": 146030.49043065784,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6288770.036731202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 76.899,
    "out:Total Energy Use Post PV": 72.835188,
    "out:Primary Energy": 65.595806,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.978773,
    "out:CO2 Operational/m2": -88.538318,
    "out:Total CO2/m2": -43.559545,
    "out:Total CO2 (tons)": -114.518018,
    "out:Klimatpaverkan": -234.657091,
    "out:Initial Cost/MSEK": 6.354495024231202,
    "out:Running cost/(Apt SEK y)": 12491.763158,
    "out:Running Cost over RSP/MSEK": 3.73192,
    "out:LCP/MSEK": -2.108325,
    "out:ROI% old": 9.545931,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 155046.848551,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240178.75,
    "out:DH kr savings": 155046.84855084989,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6354495.024231202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.262751,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 77.899,
    "out:Total Energy Use Post PV": 73.835188,
    "out:Primary Energy": 66.163255,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.693874,
    "out:CO2 Operational/m2": -88.178334,
    "out:Total CO2/m2": -42.48446,
    "out:Total CO2 (tons)": -111.69162,
    "out:Klimatpaverkan": -231.830694,
    "out:Initial Cost/MSEK": 6.516450036731202,
    "out:Running cost/(Apt SEK y)": 12700.710526,
    "out:Running Cost over RSP/MSEK": 3.79402,
    "out:LCP/MSEK": -2.33238,
    "out:ROI% old": 9.172545,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 151422.042406,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236553.95,
    "out:DH kr savings": 151422.04240633023,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 147914.738001,
    "out:% savings (space heating)": 48.092681,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.26,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6516450.036731202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.478912,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.833173,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 73.899,
    "out:Total Energy Use Post PV": 69.835188,
    "out:Primary Energy": 63.495806,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.693874,
    "out:CO2 Operational/m2": -89.61827,
    "out:Total CO2/m2": -43.924396,
    "out:Total CO2 (tons)": -115.477211,
    "out:Klimatpaverkan": -235.616284,
    "out:Initial Cost/MSEK": 6.582175024231202,
    "out:Running cost/(Apt SEK y)": 11864.947368,
    "out:Running Cost over RSP/MSEK": 3.54561,
    "out:LCP/MSEK": -2.149695,
    "out:ROI% old": 9.620094,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.43,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 85.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 569,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160438.400527,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245570.31,
    "out:DH kr savings": 160438.40052652225,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 138898.379881,
    "out:% savings (space heating)": 51.256767,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 52.83,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6582175.024231202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 87.899,
    "out:Primary Energy": 82.429159,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.951264,
    "out:CO2 Operational/m2": 29.385499,
    "out:Total CO2/m2": 46.336763,
    "out:Total CO2 (tons)": 121.819322,
    "out:Klimatpaverkan": 1.680249,
    "out:Initial Cost/MSEK": 5.167446653086201,
    "out:Running cost/(Apt SEK y)": 17666.736842,
    "out:Running Cost over RSP/MSEK": 5.24628,
    "out:LCP/MSEK": -2.435637,
    "out:ROI% old": 7.552252,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 125717.57334,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101536.04,
    "out:DH kr savings": 125717.57333956767,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5167446.653086201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 83.899,
    "out:Primary Energy": 79.609389,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.951264,
    "out:CO2 Operational/m2": 27.945563,
    "out:Total CO2/m2": 44.896827,
    "out:Total CO2 (tons)": 118.033731,
    "out:Klimatpaverkan": -2.105342,
    "out:Initial Cost/MSEK": 5.233171640586202,
    "out:Running cost/(Apt SEK y)": 16831,
    "out:Running Cost over RSP/MSEK": 4.99787,
    "out:LCP/MSEK": -2.252952,
    "out:ROI% old": 8.13552,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 135248.794966,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111067.26,
    "out:DH kr savings": 135248.79496574317,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5233171.640586202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 84.899,
    "out:Total Energy Use Post PV": 84.899,
    "out:Primary Energy": 79.804159,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.666365,
    "out:CO2 Operational/m2": 28.305547,
    "out:Total CO2/m2": 45.971912,
    "out:Total CO2 (tons)": 120.860129,
    "out:Klimatpaverkan": 0.721056,
    "out:Initial Cost/MSEK": 5.3951266530862005,
    "out:Running cost/(Apt SEK y)": 17039.921053,
    "out:Running Cost over RSP/MSEK": 5.05997,
    "out:LCP/MSEK": -2.477007,
    "out:ROI% old": 7.726867,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 52.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 132457.013309,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108275.48,
    "out:DH kr savings": 132457.01330915818,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5395126.6530862,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 80.899,
    "out:Primary Energy": 76.984389,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.666365,
    "out:CO2 Operational/m2": 26.865611,
    "out:Total CO2/m2": 44.531976,
    "out:Total CO2 (tons)": 117.074538,
    "out:Klimatpaverkan": -3.064535,
    "out:Initial Cost/MSEK": 5.460851640586201,
    "out:Running cost/(Apt SEK y)": 16204.184211,
    "out:Running Cost over RSP/MSEK": 4.81157,
    "out:LCP/MSEK": -2.294332,
    "out:ROI% old": 8.28369,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 141988.234935,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117806.7,
    "out:DH kr savings": 141988.2349353337,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5460851.640586201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 84.185671,
    "out:Primary Energy": 75.745167,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.521064,
    "out:CO2 Operational/m2": -19.140112,
    "out:Total CO2/m2": 12.380952,
    "out:Total CO2 (tons)": 32.549515,
    "out:Klimatpaverkan": -87.589558,
    "out:Initial Cost/MSEK": 5.8124298895974515,
    "out:Running cost/(Apt SEK y)": 16170.236842,
    "out:Running Cost over RSP/MSEK": 4.81365,
    "out:LCP/MSEK": -2.64799,
    "out:ROI% old": 7.777519,
    "out:Payback discounted": 16,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 125717.57334,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158402.99,
    "out:DH kr savings": 125717.57333956767,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5812429.889597451,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 80.185671,
    "out:Primary Energy": 72.925397,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.521064,
    "out:CO2 Operational/m2": -20.580048,
    "out:Total CO2/m2": 10.941016,
    "out:Total CO2 (tons)": 28.763925,
    "out:Klimatpaverkan": -91.375149,
    "out:Initial Cost/MSEK": 5.878154877097452,
    "out:Running cost/(Apt SEK y)": 15334.5,
    "out:Running Cost over RSP/MSEK": 4.56525,
    "out:LCP/MSEK": -2.465315,
    "out:ROI% old": 8.294245,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135248.794966,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167934.21,
    "out:DH kr savings": 135248.79496574317,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5878154.877097452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 84.899,
    "out:Total Energy Use Post PV": 81.185671,
    "out:Primary Energy": 73.120167,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.236165,
    "out:CO2 Operational/m2": -20.220064,
    "out:Total CO2/m2": 12.016101,
    "out:Total CO2 (tons)": 31.590322,
    "out:Klimatpaverkan": -88.548751,
    "out:Initial Cost/MSEK": 6.040109889597451,
    "out:Running cost/(Apt SEK y)": 15543.421053,
    "out:Running Cost over RSP/MSEK": 4.62735,
    "out:LCP/MSEK": -2.68937,
    "out:ROI% old": 7.924974,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132457.013309,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165142.43,
    "out:DH kr savings": 132457.01330915818,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6040109.889597451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 77.185671,
    "out:Primary Energy": 70.300397,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.236165,
    "out:CO2 Operational/m2": -21.66,
    "out:Total CO2/m2": 10.576165,
    "out:Total CO2 (tons)": 27.804731,
    "out:Klimatpaverkan": -92.334342,
    "out:Initial Cost/MSEK": 6.105834877097453,
    "out:Running cost/(Apt SEK y)": 14707.684211,
    "out:Running Cost over RSP/MSEK": 4.37894,
    "out:LCP/MSEK": -2.506685,
    "out:ROI% old": 8.420868,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141988.234935,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174673.65,
    "out:DH kr savings": 141988.2349353337,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6105834.877097453,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 87.899,
    "out:Total Energy Use Post PV": 83.835188,
    "out:Primary Energy": 75.114297,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.090864,
    "out:CO2 Operational/m2": -84.578494,
    "out:Total CO2/m2": -38.48763,
    "out:Total CO2 (tons)": -101.183956,
    "out:Klimatpaverkan": -221.32303,
    "out:Initial Cost/MSEK": 6.457413108646201,
    "out:Running cost/(Apt SEK y)": 14790.078947,
    "out:Running Cost over RSP/MSEK": 4.41503,
    "out:LCP/MSEK": -2.894353,
    "out:ROI% old": 7.882546,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 125717.57334,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210849.48,
    "out:DH kr savings": 125717.57333956767,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6457413.108646201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 79.835188,
    "out:Primary Energy": 72.294527,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.090864,
    "out:CO2 Operational/m2": -86.01843,
    "out:Total CO2/m2": -39.927566,
    "out:Total CO2 (tons)": -104.969547,
    "out:Klimatpaverkan": -225.108621,
    "out:Initial Cost/MSEK": 6.5231380961462015,
    "out:Running cost/(Apt SEK y)": 13954.315789,
    "out:Running Cost over RSP/MSEK": 4.16663,
    "out:LCP/MSEK": -2.711678,
    "out:ROI% old": 8.347122,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135248.794966,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220380.7,
    "out:DH kr savings": 135248.79496574317,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6523138.096146202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 84.899,
    "out:Total Energy Use Post PV": 80.835188,
    "out:Primary Energy": 72.489297,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.805965,
    "out:CO2 Operational/m2": -85.658446,
    "out:Total CO2/m2": -38.852481,
    "out:Total CO2 (tons)": -102.143149,
    "out:Klimatpaverkan": -222.282223,
    "out:Initial Cost/MSEK": 6.685093108646201,
    "out:Running cost/(Apt SEK y)": 14163.263158,
    "out:Running Cost over RSP/MSEK": 4.22873,
    "out:LCP/MSEK": -2.935733,
    "out:ROI% old": 8.012197,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132457.013309,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217588.92,
    "out:DH kr savings": 132457.01330915818,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6685093.108646201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 76.835188,
    "out:Primary Energy": 69.669527,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.805965,
    "out:CO2 Operational/m2": -87.098382,
    "out:Total CO2/m2": -40.292417,
    "out:Total CO2 (tons)": -105.92874,
    "out:Klimatpaverkan": -226.067814,
    "out:Initial Cost/MSEK": 6.750818096146202,
    "out:Running cost/(Apt SEK y)": 13327.526316,
    "out:Running Cost over RSP/MSEK": 3.98032,
    "out:LCP/MSEK": -2.753048,
    "out:ROI% old": 8.459864,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141988.234935,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227120.14,
    "out:DH kr savings": 141988.2349353337,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6750818.096146202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 83.899,
    "out:Primary Energy": 78.929159,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.951264,
    "out:CO2 Operational/m2": 27.945563,
    "out:Total CO2/m2": 44.896827,
    "out:Total CO2 (tons)": 118.033731,
    "out:Klimatpaverkan": -2.105342,
    "out:Initial Cost/MSEK": 5.267740528086201,
    "out:Running cost/(Apt SEK y)": 16831,
    "out:Running Cost over RSP/MSEK": 4.99787,
    "out:LCP/MSEK": -2.287521,
    "out:ROI% old": 8.082131,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 54.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 134703.493299,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110521.96,
    "out:DH kr savings": 134703.49329902168,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5267740.528086201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 80.899,
    "out:Primary Energy": 76.109389,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.951264,
    "out:CO2 Operational/m2": 26.865611,
    "out:Total CO2/m2": 43.816875,
    "out:Total CO2 (tons)": 115.194538,
    "out:Klimatpaverkan": -4.944535,
    "out:Initial Cost/MSEK": 5.333465515586202,
    "out:Running cost/(Apt SEK y)": 16204.184211,
    "out:Running Cost over RSP/MSEK": 4.81157,
    "out:LCP/MSEK": -2.166946,
    "out:ROI% old": 8.48154,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 144234.714925,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120053.18,
    "out:DH kr savings": 144234.71492519722,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5333465.515586202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 81.899,
    "out:Total Energy Use Post PV": 81.899,
    "out:Primary Energy": 76.829159,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.666365,
    "out:CO2 Operational/m2": 27.225595,
    "out:Total CO2/m2": 44.89196,
    "out:Total CO2 (tons)": 118.020936,
    "out:Klimatpaverkan": -2.118137,
    "out:Initial Cost/MSEK": 5.495420528086202,
    "out:Running cost/(Apt SEK y)": 16413.131579,
    "out:Running Cost over RSP/MSEK": 4.87367,
    "out:LCP/MSEK": -2.391001,
    "out:ROI% old": 8.070148,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 397,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 140095.045275,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115913.51,
    "out:DH kr savings": 140095.04527469407,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5495420.528086201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 78.899,
    "out:Total Energy Use Post PV": 78.899,
    "out:Primary Energy": 74.009389,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.666365,
    "out:CO2 Operational/m2": 26.145643,
    "out:Total CO2/m2": 43.812008,
    "out:Total CO2 (tons)": 115.181743,
    "out:Klimatpaverkan": -4.957331,
    "out:Initial Cost/MSEK": 5.561145515586202,
    "out:Running cost/(Apt SEK y)": 15786.315789,
    "out:Running Cost over RSP/MSEK": 4.68736,
    "out:LCP/MSEK": -2.270416,
    "out:ROI% old": 8.453371,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 149626.266901,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125444.73,
    "out:DH kr savings": 149626.26690086958,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5561145.515586202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.838896,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 80.185671,
    "out:Primary Energy": 72.245167,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.521064,
    "out:CO2 Operational/m2": -20.580048,
    "out:Total CO2/m2": 10.941016,
    "out:Total CO2 (tons)": 28.763925,
    "out:Klimatpaverkan": -91.375149,
    "out:Initial Cost/MSEK": 5.912723764597453,
    "out:Running cost/(Apt SEK y)": 15334.5,
    "out:Running Cost over RSP/MSEK": 4.56525,
    "out:LCP/MSEK": -2.499884,
    "out:ROI% old": 8.245753,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134703.493299,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167388.91,
    "out:DH kr savings": 134703.49329902168,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5912723.764597452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 77.185671,
    "out:Primary Energy": 69.425397,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.521064,
    "out:CO2 Operational/m2": -21.66,
    "out:Total CO2/m2": 9.861064,
    "out:Total CO2 (tons)": 25.924731,
    "out:Klimatpaverkan": -94.214342,
    "out:Initial Cost/MSEK": 5.978448752097452,
    "out:Running cost/(Apt SEK y)": 14707.684211,
    "out:Running Cost over RSP/MSEK": 4.37894,
    "out:LCP/MSEK": -2.379299,
    "out:ROI% old": 8.600296,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144234.714925,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176920.13,
    "out:DH kr savings": 144234.71492519722,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5978448.752097452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 81.899,
    "out:Total Energy Use Post PV": 78.185671,
    "out:Primary Energy": 70.145167,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.236165,
    "out:CO2 Operational/m2": -21.300016,
    "out:Total CO2/m2": 10.936149,
    "out:Total CO2 (tons)": 28.751129,
    "out:Klimatpaverkan": -91.387944,
    "out:Initial Cost/MSEK": 6.140403764597452,
    "out:Running cost/(Apt SEK y)": 14916.631579,
    "out:Running Cost over RSP/MSEK": 4.44104,
    "out:LCP/MSEK": -2.603354,
    "out:ROI% old": 8.228984,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 7,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140095.045275,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172780.46,
    "out:DH kr savings": 140095.04527469407,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6140403.764597452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 78.899,
    "out:Total Energy Use Post PV": 75.185671,
    "out:Primary Energy": 67.325397,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.236165,
    "out:CO2 Operational/m2": -22.379968,
    "out:Total CO2/m2": 9.856197,
    "out:Total CO2 (tons)": 25.911936,
    "out:Klimatpaverkan": -94.227138,
    "out:Initial Cost/MSEK": 6.206128752097452,
    "out:Running cost/(Apt SEK y)": 14289.815789,
    "out:Running Cost over RSP/MSEK": 4.25474,
    "out:LCP/MSEK": -2.482779,
    "out:ROI% old": 8.570675,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 73.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 477,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149626.266901,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182311.68,
    "out:DH kr savings": 149626.26690086958,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6206128.752097452,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.838896,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 83.899,
    "out:Total Energy Use Post PV": 79.835188,
    "out:Primary Energy": 71.614297,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.090864,
    "out:CO2 Operational/m2": -86.01843,
    "out:Total CO2/m2": -39.927566,
    "out:Total CO2 (tons)": -104.969547,
    "out:Klimatpaverkan": -225.108621,
    "out:Initial Cost/MSEK": 6.5577069836462005,
    "out:Running cost/(Apt SEK y)": 13954.315789,
    "out:Running Cost over RSP/MSEK": 4.16663,
    "out:LCP/MSEK": -2.746247,
    "out:ROI% old": 8.30312,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 62.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134703.493299,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219835.4,
    "out:DH kr savings": 134703.49329902168,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6557706.983646201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 80.899,
    "out:Total Energy Use Post PV": 76.835188,
    "out:Primary Energy": 68.794527,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.090864,
    "out:CO2 Operational/m2": -87.098382,
    "out:Total CO2/m2": -41.007518,
    "out:Total CO2 (tons)": -107.80874,
    "out:Klimatpaverkan": -227.947814,
    "out:Initial Cost/MSEK": 6.623431971146202,
    "out:Running cost/(Apt SEK y)": 13327.526316,
    "out:Running Cost over RSP/MSEK": 3.98032,
    "out:LCP/MSEK": -2.625662,
    "out:ROI% old": 8.622569,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 68.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144234.714925,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229366.62,
    "out:DH kr savings": 144234.71492519722,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6623431.971146202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.571233,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 81.899,
    "out:Total Energy Use Post PV": 77.835188,
    "out:Primary Energy": 69.514297,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.805965,
    "out:CO2 Operational/m2": -86.738398,
    "out:Total CO2/m2": -39.932433,
    "out:Total CO2 (tons)": -104.982343,
    "out:Klimatpaverkan": -225.121416,
    "out:Initial Cost/MSEK": 6.785386983646201,
    "out:Running cost/(Apt SEK y)": 13536.447368,
    "out:Running Cost over RSP/MSEK": 4.04243,
    "out:LCP/MSEK": -2.849727,
    "out:ROI% old": 8.286,
    "out:Payback discounted": 15,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 7,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140095.045275,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225226.95,
    "out:DH kr savings": 140095.04527469407,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 159241.735133,
    "out:% savings (space heating)": 44.117728,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 60.57,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6785386.983646201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.945815,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 78.899,
    "out:Total Energy Use Post PV": 74.835188,
    "out:Primary Energy": 66.694527,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.805965,
    "out:CO2 Operational/m2": -87.81835,
    "out:Total CO2/m2": -41.012385,
    "out:Total CO2 (tons)": -107.821536,
    "out:Klimatpaverkan": -227.960609,
    "out:Initial Cost/MSEK": 6.851111971146202,
    "out:Running cost/(Apt SEK y)": 12909.631579,
    "out:Running Cost over RSP/MSEK": 3.85612,
    "out:LCP/MSEK": -2.729142,
    "out:ROI% old": 8.594997,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 73.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 530,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149626.266901,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234758.17,
    "out:DH kr savings": 149626.26690086958,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 149710.513507,
    "out:% savings (space heating)": 47.462494,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 56.95,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6851111.971146202,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.838896,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 71.899,
    "out:Total Energy Use Post PV": 71.899,
    "out:Primary Energy": 70.494364,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.353481,
    "out:CO2 Operational/m2": 23.625755,
    "out:Total CO2/m2": 41.979236,
    "out:Total CO2 (tons)": 110.363386,
    "out:Klimatpaverkan": -9.775687,
    "out:Initial Cost/MSEK": 5.377384706171202,
    "out:Running cost/(Apt SEK y)": 14323.763158,
    "out:Running Cost over RSP/MSEK": 4.25266,
    "out:LCP/MSEK": -1.651955,
    "out:ROI% old": 9.897084,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 80.6,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166058.875123,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141877.34,
    "out:DH kr savings": 166058.8751227603,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5377384.706171202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 68.899,
    "out:Total Energy Use Post PV": 68.899,
    "out:Primary Energy": 68.217085,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.353481,
    "out:CO2 Operational/m2": 22.545803,
    "out:Total CO2/m2": 40.899284,
    "out:Total CO2 (tons)": 107.524193,
    "out:Klimatpaverkan": -12.61488,
    "out:Initial Cost/MSEK": 5.443109693671202,
    "out:Running cost/(Apt SEK y)": 13696.947368,
    "out:Running Cost over RSP/MSEK": 4.06635,
    "out:LCP/MSEK": -1.53137,
    "out:ROI% old": 10.266558,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 88.4,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173756.401213,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149574.86,
    "out:DH kr savings": 173756.4012133361,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5443109.693671202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 69.899,
    "out:Total Energy Use Post PV": 69.899,
    "out:Primary Energy": 67.869364,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.068582,
    "out:CO2 Operational/m2": 22.905787,
    "out:Total CO2/m2": 41.974369,
    "out:Total CO2 (tons)": 110.350591,
    "out:Klimatpaverkan": -9.788482,
    "out:Initial Cost/MSEK": 5.605064706171201,
    "out:Running cost/(Apt SEK y)": 13905.894737,
    "out:Running Cost over RSP/MSEK": 4.12845,
    "out:LCP/MSEK": -1.755425,
    "out:ROI% old": 9.811636,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 85.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 172798.315092,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148616.78,
    "out:DH kr savings": 172798.31509235082,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5605064.706171202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 66.899,
    "out:Primary Energy": 65.592085,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.068582,
    "out:CO2 Operational/m2": 21.825835,
    "out:Total CO2/m2": 40.894417,
    "out:Total CO2 (tons)": 107.511398,
    "out:Klimatpaverkan": -12.627676,
    "out:Initial Cost/MSEK": 5.670789693671202,
    "out:Running cost/(Apt SEK y)": 13279.078947,
    "out:Running Cost over RSP/MSEK": 3.94215,
    "out:LCP/MSEK": -1.63485,
    "out:ROI% old": 10.167241,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180495.841183,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156314.3,
    "out:DH kr savings": 180495.8411829266,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5670789.693671202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 71.899,
    "out:Total Energy Use Post PV": 68.185671,
    "out:Primary Energy": 63.810372,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.923281,
    "out:CO2 Operational/m2": -24.899856,
    "out:Total CO2/m2": 8.023425,
    "out:Total CO2 (tons)": 21.09358,
    "out:Klimatpaverkan": -99.045494,
    "out:Initial Cost/MSEK": 6.02236794268245,
    "out:Running cost/(Apt SEK y)": 12827.263158,
    "out:Running Cost over RSP/MSEK": 3.82003,
    "out:LCP/MSEK": -1.864308,
    "out:ROI% old": 9.863372,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 91.2,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 533,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166058.875123,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198744.29,
    "out:DH kr savings": 166058.8751227603,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6022367.942682451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 68.899,
    "out:Total Energy Use Post PV": 65.185671,
    "out:Primary Energy": 61.533093,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.923281,
    "out:CO2 Operational/m2": -25.979808,
    "out:Total CO2/m2": 6.943473,
    "out:Total CO2 (tons)": 18.254386,
    "out:Klimatpaverkan": -101.884687,
    "out:Initial Cost/MSEK": 6.088092930182452,
    "out:Running cost/(Apt SEK y)": 12200.447368,
    "out:Running Cost over RSP/MSEK": 3.63373,
    "out:LCP/MSEK": -1.743733,
    "out:ROI% old": 10.194044,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173756.401213,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206441.81,
    "out:DH kr savings": 173756.4012133361,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6088092.930182451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 69.899,
    "out:Total Energy Use Post PV": 66.185671,
    "out:Primary Energy": 61.185372,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.638382,
    "out:CO2 Operational/m2": -25.619824,
    "out:Total CO2/m2": 8.018558,
    "out:Total CO2 (tons)": 21.080784,
    "out:Klimatpaverkan": -99.058289,
    "out:Initial Cost/MSEK": 6.250047942682451,
    "out:Running cost/(Apt SEK y)": 12409.394737,
    "out:Running Cost over RSP/MSEK": 3.69583,
    "out:LCP/MSEK": -1.967788,
    "out:ROI% old": 9.787948,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 97,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 172798.315092,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205483.73,
    "out:DH kr savings": 172798.31509235082,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6250047.942682451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 63.185671,
    "out:Primary Energy": 58.908093,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.638382,
    "out:CO2 Operational/m2": -26.699776,
    "out:Total CO2/m2": 6.938606,
    "out:Total CO2 (tons)": 18.241591,
    "out:Klimatpaverkan": -101.897483,
    "out:Initial Cost/MSEK": 6.315772930182451,
    "out:Running cost/(Apt SEK y)": 11782.578947,
    "out:Running Cost over RSP/MSEK": 3.50952,
    "out:LCP/MSEK": -1.847203,
    "out:ROI% old": 10.107506,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180495.841183,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213181.25,
    "out:DH kr savings": 180495.8411829266,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6315772.930182451,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 71.899,
    "out:Total Energy Use Post PV": 67.835188,
    "out:Primary Energy": 63.179502,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.493081,
    "out:CO2 Operational/m2": -90.338238,
    "out:Total CO2/m2": -42.845157,
    "out:Total CO2 (tons)": -112.639892,
    "out:Klimatpaverkan": -232.778966,
    "out:Initial Cost/MSEK": 6.667351161731202,
    "out:Running cost/(Apt SEK y)": 11447.078947,
    "out:Running Cost over RSP/MSEK": 3.42141,
    "out:LCP/MSEK": -2.110671,
    "out:ROI% old": 9.763312,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 91.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 585,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166058.875123,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251190.78,
    "out:DH kr savings": 166058.8751227603,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6667351.161731202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 68.899,
    "out:Total Energy Use Post PV": 64.835188,
    "out:Primary Energy": 60.902223,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.493081,
    "out:CO2 Operational/m2": -91.41819,
    "out:Total CO2/m2": -43.925109,
    "out:Total CO2 (tons)": -115.479085,
    "out:Klimatpaverkan": -235.618159,
    "out:Initial Cost/MSEK": 6.733076149231203,
    "out:Running cost/(Apt SEK y)": 10820.289474,
    "out:Running Cost over RSP/MSEK": 3.23511,
    "out:LCP/MSEK": -1.990096,
    "out:ROI% old": 10.063284,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 173756.401213,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258888.31,
    "out:DH kr savings": 173756.4012133361,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6733076.149231203,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 69.899,
    "out:Total Energy Use Post PV": 65.835188,
    "out:Primary Energy": 60.554502,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.208182,
    "out:CO2 Operational/m2": -91.058206,
    "out:Total CO2/m2": -42.850024,
    "out:Total CO2 (tons)": -112.652688,
    "out:Klimatpaverkan": -232.791761,
    "out:Initial Cost/MSEK": 6.895031161731202,
    "out:Running cost/(Apt SEK y)": 11029.210526,
    "out:Running Cost over RSP/MSEK": 3.29721,
    "out:LCP/MSEK": -2.214151,
    "out:ROI% old": 9.698247,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 97,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 172798.315092,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257930.22,
    "out:DH kr savings": 172798.31509235082,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6895031.161731202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 62.835188,
    "out:Primary Energy": 58.277223,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.208182,
    "out:CO2 Operational/m2": -92.138158,
    "out:Total CO2/m2": -43.929976,
    "out:Total CO2 (tons)": -115.491881,
    "out:Klimatpaverkan": -235.630954,
    "out:Initial Cost/MSEK": 6.960756149231203,
    "out:Running cost/(Apt SEK y)": 10402.394737,
    "out:Running Cost over RSP/MSEK": 3.11091,
    "out:LCP/MSEK": -2.093576,
    "out:ROI% old": 9.989022,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180495.841183,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265627.75,
    "out:DH kr savings": 180495.8411829266,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6960756.149231203,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 68.899,
    "out:Total Energy Use Post PV": 68.899,
    "out:Primary Energy": 66.994364,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.353481,
    "out:CO2 Operational/m2": 22.545803,
    "out:Total CO2/m2": 40.899284,
    "out:Total CO2 (tons)": 107.524193,
    "out:Klimatpaverkan": -12.61488,
    "out:Initial Cost/MSEK": 5.477678581171202,
    "out:Running cost/(Apt SEK y)": 13696.947368,
    "out:Running Cost over RSP/MSEK": 4.06635,
    "out:LCP/MSEK": -1.565939,
    "out:ROI% old": 10.201767,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 88.4,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175044.795082,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150863.26,
    "out:DH kr savings": 175044.79508221432,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5477678.581171202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 65.899,
    "out:Total Energy Use Post PV": 65.899,
    "out:Primary Energy": 64.717085,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.353481,
    "out:CO2 Operational/m2": 21.465851,
    "out:Total CO2/m2": 39.819332,
    "out:Total CO2 (tons)": 104.685,
    "out:Klimatpaverkan": -15.454073,
    "out:Initial Cost/MSEK": 5.543403568671201,
    "out:Running cost/(Apt SEK y)": 13070.131579,
    "out:Running Cost over RSP/MSEK": 3.88005,
    "out:LCP/MSEK": -1.445364,
    "out:ROI% old": 10.560918,
    "out:Payback discounted": 11,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 97,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182742.321173,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158560.78,
    "out:DH kr savings": 182742.3211727901,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5543403.568671201,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 66.899,
    "out:Primary Energy": 64.894364,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.068582,
    "out:CO2 Operational/m2": 21.825835,
    "out:Total CO2/m2": 40.894417,
    "out:Total CO2 (tons)": 107.511398,
    "out:Klimatpaverkan": -12.627676,
    "out:Initial Cost/MSEK": 5.705358581171201,
    "out:Running cost/(Apt SEK y)": 13279.078947,
    "out:Running Cost over RSP/MSEK": 3.94215,
    "out:LCP/MSEK": -1.669419,
    "out:ROI% old": 10.105638,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180436.347058,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156254.81,
    "out:DH kr savings": 180436.3470578867,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5705358.581171202,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 63.899,
    "out:Total Energy Use Post PV": 63.899,
    "out:Primary Energy": 62.617085,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.068582,
    "out:CO2 Operational/m2": 20.745883,
    "out:Total CO2/m2": 39.814465,
    "out:Total CO2 (tons)": 104.672205,
    "out:Klimatpaverkan": -15.466869,
    "out:Initial Cost/MSEK": 5.771083568671202,
    "out:Running cost/(Apt SEK y)": 12652.263158,
    "out:Running Cost over RSP/MSEK": 3.75585,
    "out:LCP/MSEK": -1.548844,
    "out:ROI% old": 10.451714,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 103.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188133.873148,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163952.34,
    "out:DH kr savings": 188133.8731484625,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5771083.568671201,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 68.899,
    "out:Total Energy Use Post PV": 65.185671,
    "out:Primary Energy": 60.310372,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.923281,
    "out:CO2 Operational/m2": -25.979808,
    "out:Total CO2/m2": 6.943473,
    "out:Total CO2 (tons)": 18.254386,
    "out:Klimatpaverkan": -101.884687,
    "out:Initial Cost/MSEK": 6.122661817682452,
    "out:Running cost/(Apt SEK y)": 12200.447368,
    "out:Running Cost over RSP/MSEK": 3.63373,
    "out:LCP/MSEK": -1.778302,
    "out:ROI% old": 10.136488,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175044.795082,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207730.21,
    "out:DH kr savings": 175044.79508221432,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6122661.8176824525,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 65.899,
    "out:Total Energy Use Post PV": 62.185671,
    "out:Primary Energy": 58.033093,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.923281,
    "out:CO2 Operational/m2": -27.05976,
    "out:Total CO2/m2": 5.863521,
    "out:Total CO2 (tons)": 15.415193,
    "out:Klimatpaverkan": -104.72388,
    "out:Initial Cost/MSEK": 6.1883868051824535,
    "out:Running cost/(Apt SEK y)": 11573.631579,
    "out:Running Cost over RSP/MSEK": 3.44742,
    "out:LCP/MSEK": -1.657717,
    "out:ROI% old": 10.458922,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182742.321173,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215427.73,
    "out:DH kr savings": 182742.3211727901,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6188386.805182453,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 63.185671,
    "out:Primary Energy": 58.210372,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.638382,
    "out:CO2 Operational/m2": -26.699776,
    "out:Total CO2/m2": 6.938606,
    "out:Total CO2 (tons)": 18.241591,
    "out:Klimatpaverkan": -101.897483,
    "out:Initial Cost/MSEK": 6.350341817682453,
    "out:Running cost/(Apt SEK y)": 11782.578947,
    "out:Running Cost over RSP/MSEK": 3.50952,
    "out:LCP/MSEK": -1.881772,
    "out:ROI% old": 10.052485,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180436.347058,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213121.76,
    "out:DH kr savings": 180436.3470578867,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6350341.8176824525,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 63.899,
    "out:Total Energy Use Post PV": 60.185671,
    "out:Primary Energy": 55.933093,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.638382,
    "out:CO2 Operational/m2": -27.779728,
    "out:Total CO2/m2": 5.858654,
    "out:Total CO2 (tons)": 15.402398,
    "out:Klimatpaverkan": -104.736676,
    "out:Initial Cost/MSEK": 6.416066805182453,
    "out:Running cost/(Apt SEK y)": 11155.763158,
    "out:Running Cost over RSP/MSEK": 3.32322,
    "out:LCP/MSEK": -1.761197,
    "out:ROI% old": 10.364316,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 9,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188133.873148,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220819.29,
    "out:DH kr savings": 188133.8731484625,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6416066.805182453,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 68.899,
    "out:Total Energy Use Post PV": 64.835188,
    "out:Primary Energy": 59.679502,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.493081,
    "out:CO2 Operational/m2": -91.41819,
    "out:Total CO2/m2": -43.925109,
    "out:Total CO2 (tons)": -115.479085,
    "out:Klimatpaverkan": -235.618159,
    "out:Initial Cost/MSEK": 6.767645036731202,
    "out:Running cost/(Apt SEK y)": 10820.289474,
    "out:Running Cost over RSP/MSEK": 3.23511,
    "out:LCP/MSEK": -2.024665,
    "out:ROI% old": 10.011881,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175044.795082,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260176.7,
    "out:DH kr savings": 175044.79508221432,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6767645.036731202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 65.899,
    "out:Total Energy Use Post PV": 61.835188,
    "out:Primary Energy": 57.402223,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.493081,
    "out:CO2 Operational/m2": -92.498142,
    "out:Total CO2/m2": -45.005061,
    "out:Total CO2 (tons)": -118.318279,
    "out:Klimatpaverkan": -238.457352,
    "out:Initial Cost/MSEK": 6.833370024231202,
    "out:Running cost/(Apt SEK y)": 10193.473684,
    "out:Running Cost over RSP/MSEK": 3.04881,
    "out:LCP/MSEK": -1.90409,
    "out:ROI% old": 10.30506,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 633,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 182742.321173,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 267874.23,
    "out:DH kr savings": 182742.3211727901,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6833370.024231202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.226497,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 62.835188,
    "out:Primary Energy": 57.579502,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.208182,
    "out:CO2 Operational/m2": -92.138158,
    "out:Total CO2/m2": -43.929976,
    "out:Total CO2 (tons)": -115.491881,
    "out:Klimatpaverkan": -235.630954,
    "out:Initial Cost/MSEK": 6.995325036731202,
    "out:Running cost/(Apt SEK y)": 10402.394737,
    "out:Running Cost over RSP/MSEK": 3.11091,
    "out:LCP/MSEK": -2.128145,
    "out:ROI% old": 9.939659,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180436.347058,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265568.25,
    "out:DH kr savings": 180436.3470578867,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 118900.43335,
    "out:% savings (space heating)": 58.274592,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 45.23,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6995325.036731202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.298567,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 63.899,
    "out:Total Energy Use Post PV": 59.835188,
    "out:Primary Energy": 55.302223,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 48.208182,
    "out:CO2 Operational/m2": -93.21811,
    "out:Total CO2/m2": -45.009928,
    "out:Total CO2 (tons)": -118.331074,
    "out:Klimatpaverkan": -238.470147,
    "out:Initial Cost/MSEK": 7.061050024231202,
    "out:Running cost/(Apt SEK y)": 9775.605263,
    "out:Running Cost over RSP/MSEK": 2.9246,
    "out:LCP/MSEK": -2.00756,
    "out:ROI% old": 10.224076,
    "out:Payback discounted": 12,
    "out:Atemp": 2629,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 9,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 793928.6447942974,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188133.873148,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273265.78,
    "out:DH kr savings": 188133.8731484625,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 111202.907259,
    "out:% savings (space heating)": 60.975864,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 42.3,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7061050.024231202,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 66.899,
    "out:Primary Energy": 66.5728,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.073863,
    "out:CO2 Operational/m2": 21.825835,
    "out:Total CO2/m2": 60.899698,
    "out:Total CO2 (tons)": 160.10527,
    "out:Klimatpaverkan": 39.966196,
    "out:Initial Cost/MSEK": 6.76896733442799,
    "out:Running cost/(Apt SEK y)": 13279.078947,
    "out:Running Cost over RSP/MSEK": 3.94215,
    "out:LCP/MSEK": -2.733027,
    "out:ROI% old": 8.517737,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 179314.31798,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155132.78,
    "out:DH kr savings": 179314.31798007997,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6768967.33442799,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 64.899,
    "out:Total Energy Use Post PV": 64.899,
    "out:Primary Energy": 64.473775,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.073863,
    "out:CO2 Operational/m2": 21.105867,
    "out:Total CO2/m2": 60.17973,
    "out:Total CO2 (tons)": 158.212474,
    "out:Klimatpaverkan": 38.073401,
    "out:Initial Cost/MSEK": 6.83469232192799,
    "out:Running cost/(Apt SEK y)": 12861.210526,
    "out:Running Cost over RSP/MSEK": 3.81795,
    "out:LCP/MSEK": -2.674552,
    "out:ROI% old": 8.695427,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 186409.323941,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162227.79,
    "out:DH kr savings": 186409.32394077763,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6834692.32192799,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 64.899,
    "out:Total Energy Use Post PV": 64.899,
    "out:Primary Energy": 63.9478,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.788964,
    "out:CO2 Operational/m2": 21.105867,
    "out:Total CO2/m2": 60.894831,
    "out:Total CO2 (tons)": 160.092474,
    "out:Klimatpaverkan": 39.953401,
    "out:Initial Cost/MSEK": 6.99664733442799,
    "out:Running cost/(Apt SEK y)": 12861.210526,
    "out:Running Cost over RSP/MSEK": 3.81795,
    "out:LCP/MSEK": -2.836507,
    "out:ROI% old": 8.49415,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 186053.75795,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161872.22,
    "out:DH kr savings": 186053.7579496705,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6996647.33442799,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 61.899,
    "out:Total Energy Use Post PV": 61.899,
    "out:Primary Energy": 61.848775,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.788964,
    "out:CO2 Operational/m2": 20.025915,
    "out:Total CO2/m2": 59.814879,
    "out:Total CO2 (tons)": 157.253281,
    "out:Klimatpaverkan": 37.114208,
    "out:Initial Cost/MSEK": 7.06237232192799,
    "out:Running cost/(Apt SEK y)": 12234.394737,
    "out:Running Cost over RSP/MSEK": 3.63164,
    "out:LCP/MSEK": -2.715922,
    "out:ROI% old": 8.791967,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 555,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 193148.76391,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168967.23,
    "out:DH kr savings": 193148.76391036814,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 21.25,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7062372.32192799,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 63.185671,
    "out:Primary Energy": 59.888808,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.643663,
    "out:CO2 Operational/m2": -26.699776,
    "out:Total CO2/m2": 26.943887,
    "out:Total CO2 (tons)": 70.835463,
    "out:Klimatpaverkan": -49.303611,
    "out:Initial Cost/MSEK": 7.413950570939239,
    "out:Running cost/(Apt SEK y)": 11782.578947,
    "out:Running Cost over RSP/MSEK": 3.50952,
    "out:LCP/MSEK": -2.945381,
    "out:ROI% old": 8.610351,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179314.31798,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211999.73,
    "out:DH kr savings": 179314.31798007997,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7413950.570939239,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 64.899,
    "out:Total Energy Use Post PV": 61.185671,
    "out:Primary Energy": 57.789783,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.643663,
    "out:CO2 Operational/m2": -27.419744,
    "out:Total CO2/m2": 26.223919,
    "out:Total CO2 (tons)": 68.942667,
    "out:Klimatpaverkan": -51.196406,
    "out:Initial Cost/MSEK": 7.47967555843924,
    "out:Running cost/(Apt SEK y)": 11364.710526,
    "out:Running Cost over RSP/MSEK": 3.38532,
    "out:LCP/MSEK": -2.886906,
    "out:ROI% old": 8.771905,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 186409.323941,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219094.74,
    "out:DH kr savings": 186409.32394077763,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7479675.55843924,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 64.899,
    "out:Total Energy Use Post PV": 61.185671,
    "out:Primary Energy": 57.263808,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 54.358764,
    "out:CO2 Operational/m2": -27.419744,
    "out:Total CO2/m2": 26.93902,
    "out:Total CO2 (tons)": 70.822667,
    "out:Klimatpaverkan": -49.316406,
    "out:Initial Cost/MSEK": 7.641630570939239,
    "out:Running cost/(Apt SEK y)": 11364.710526,
    "out:Running Cost over RSP/MSEK": 3.38532,
    "out:LCP/MSEK": -3.048861,
    "out:ROI% old": 8.585995,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 186053.75795,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218739.17,
    "out:DH kr savings": 186053.7579496705,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7641630.570939239,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 61.899,
    "out:Total Energy Use Post PV": 58.185671,
    "out:Primary Energy": 55.164783,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 54.358764,
    "out:CO2 Operational/m2": -28.499696,
    "out:Total CO2/m2": 25.859068,
    "out:Total CO2 (tons)": 67.983474,
    "out:Klimatpaverkan": -52.155599,
    "out:Initial Cost/MSEK": 7.7073555584392395,
    "out:Running cost/(Apt SEK y)": 10737.894737,
    "out:Running Cost over RSP/MSEK": 3.19902,
    "out:LCP/MSEK": -2.928286,
    "out:ROI% old": 8.858087,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193148.76391,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225834.18,
    "out:DH kr savings": 193148.76391036814,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 21.25,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7707355.55843924,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 66.899,
    "out:Total Energy Use Post PV": 62.835188,
    "out:Primary Energy": 59.257938,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.213463,
    "out:CO2 Operational/m2": -92.138158,
    "out:Total CO2/m2": -23.924695,
    "out:Total CO2 (tons)": -62.898009,
    "out:Klimatpaverkan": -183.037082,
    "out:Initial Cost/MSEK": 8.058933789987991,
    "out:Running cost/(Apt SEK y)": 10402.394737,
    "out:Running Cost over RSP/MSEK": 3.11091,
    "out:LCP/MSEK": -3.191754,
    "out:ROI% old": 8.627834,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179314.31798,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264446.22,
    "out:DH kr savings": 179314.31798007997,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8058933.789987992,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 17.09,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 64.899,
    "out:Total Energy Use Post PV": 60.835188,
    "out:Primary Energy": 57.158913,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.213463,
    "out:CO2 Operational/m2": -92.858126,
    "out:Total CO2/m2": -24.644663,
    "out:Total CO2 (tons)": -64.790804,
    "out:Klimatpaverkan": -184.929878,
    "out:Initial Cost/MSEK": 8.12465877748799,
    "out:Running cost/(Apt SEK y)": 9984.526316,
    "out:Running Cost over RSP/MSEK": 2.9867,
    "out:LCP/MSEK": -3.133269,
    "out:ROI% old": 8.776439,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 641,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.09,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 186409.323941,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271541.23,
    "out:DH kr savings": 186409.32394077763,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 44929.599797,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8124658.77748799,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 64.899,
    "out:Total Energy Use Post PV": 60.835188,
    "out:Primary Energy": 56.632938,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.928564,
    "out:CO2 Operational/m2": -92.858126,
    "out:Total CO2/m2": -23.929562,
    "out:Total CO2 (tons)": -62.910804,
    "out:Klimatpaverkan": -183.049878,
    "out:Initial Cost/MSEK": 8.28661378998799,
    "out:Running cost/(Apt SEK y)": 9984.526316,
    "out:Running Cost over RSP/MSEK": 2.9867,
    "out:LCP/MSEK": -3.295224,
    "out:ROI% old": 8.604911,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 641,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 186053.75795,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271185.66,
    "out:DH kr savings": 186053.7579496705,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8286613.789987991,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 14.5265,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 61.899,
    "out:Total Energy Use Post PV": 57.835188,
    "out:Primary Energy": 54.533913,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 68.928564,
    "out:CO2 Operational/m2": -93.938078,
    "out:Total CO2/m2": -25.009514,
    "out:Total CO2 (tons)": -65.749997,
    "out:Klimatpaverkan": -185.889071,
    "out:Initial Cost/MSEK": 8.35233877748799,
    "out:Running cost/(Apt SEK y)": 9357.736842,
    "out:Running Cost over RSP/MSEK": 2.8004,
    "out:LCP/MSEK": -3.174649,
    "out:ROI% old": 8.855843,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 665,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.5265,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193148.76391,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278280.67,
    "out:DH kr savings": 193148.76391036814,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 38190.159828,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 21.25,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8352338.77748799,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 63.899,
    "out:Total Energy Use Post PV": 63.899,
    "out:Primary Energy": 63.0728,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.073863,
    "out:CO2 Operational/m2": 20.745883,
    "out:Total CO2/m2": 59.819746,
    "out:Total CO2 (tons)": 157.266077,
    "out:Klimatpaverkan": 37.127003,
    "out:Initial Cost/MSEK": 6.8692612094279895,
    "out:Running cost/(Apt SEK y)": 12652.263158,
    "out:Running Cost over RSP/MSEK": 3.75585,
    "out:LCP/MSEK": -2.647021,
    "out:ROI% old": 8.780815,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 103.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 188300.23794,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164118.7,
    "out:DH kr savings": 188300.23793953398,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6869261.209427989,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 60.899,
    "out:Total Energy Use Post PV": 60.899,
    "out:Primary Energy": 60.973775,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.073863,
    "out:CO2 Operational/m2": 19.665931,
    "out:Total CO2/m2": 58.739794,
    "out:Total CO2 (tons)": 154.426883,
    "out:Klimatpaverkan": 34.28781,
    "out:Initial Cost/MSEK": 6.934986196927989,
    "out:Running cost/(Apt SEK y)": 12025.447368,
    "out:Running Cost over RSP/MSEK": 3.56954,
    "out:LCP/MSEK": -2.526436,
    "out:ROI% old": 9.081386,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 113.1,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 563,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 195395.2439,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171213.71,
    "out:DH kr savings": 195395.24390023164,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 20,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6934986.196927989,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.359184,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 61.899,
    "out:Total Energy Use Post PV": 61.899,
    "out:Primary Energy": 60.9728,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.788964,
    "out:CO2 Operational/m2": 20.025915,
    "out:Total CO2/m2": 59.814879,
    "out:Total CO2 (tons)": 157.253281,
    "out:Klimatpaverkan": 37.114208,
    "out:Initial Cost/MSEK": 7.096941209427989,
    "out:Running cost/(Apt SEK y)": 12234.394737,
    "out:Running Cost over RSP/MSEK": 3.63164,
    "out:LCP/MSEK": -2.750491,
    "out:ROI% old": 8.749141,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 109.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 555,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 193691.789915,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169510.25,
    "out:DH kr savings": 193691.78991520638,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7096941.209427989,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 9.899,
    "out:Total Energy Use Pre PV": 58.899,
    "out:Total Energy Use Post PV": 58.899,
    "out:Primary Energy": 58.873775,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.788964,
    "out:CO2 Operational/m2": 18.945963,
    "out:Total CO2/m2": 58.734927,
    "out:Total CO2 (tons)": 154.414088,
    "out:Klimatpaverkan": 34.275014,
    "out:Initial Cost/MSEK": 7.162666196927989,
    "out:Running cost/(Apt SEK y)": 11607.578947,
    "out:Running Cost over RSP/MSEK": 3.44534,
    "out:LCP/MSEK": -2.629916,
    "out:ROI% old": 9.040428,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 692.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 579,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 200786.795876,
    "out:EL kWh savings": -12090.768254,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176605.26,
    "out:DH kr savings": 200786.79587590403,
    "out:El kr savings": -24181.536508794004,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 17,
    "out:Ef": 9.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7162666.196927989,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": 1.306747,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.306747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 63.899,
    "out:Total Energy Use Post PV": 60.185671,
    "out:Primary Energy": 56.388808,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.643663,
    "out:CO2 Operational/m2": -27.779728,
    "out:Total CO2/m2": 25.863935,
    "out:Total CO2 (tons)": 67.99627,
    "out:Klimatpaverkan": -52.142804,
    "out:Initial Cost/MSEK": 7.514244445939239,
    "out:Running cost/(Apt SEK y)": 11155.763158,
    "out:Running Cost over RSP/MSEK": 3.32322,
    "out:LCP/MSEK": -2.859374,
    "out:ROI% old": 8.849611,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188300.23794,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220985.65,
    "out:DH kr savings": 188300.23793953398,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7514244.445939239,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 60.899,
    "out:Total Energy Use Post PV": 57.185671,
    "out:Primary Energy": 54.289783,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 53.643663,
    "out:CO2 Operational/m2": -28.85968,
    "out:Total CO2/m2": 24.783983,
    "out:Total CO2 (tons)": 65.157077,
    "out:Klimatpaverkan": -54.981997,
    "out:Initial Cost/MSEK": 7.579969433439239,
    "out:Running cost/(Apt SEK y)": 10528.947368,
    "out:Running Cost over RSP/MSEK": 3.13691,
    "out:LCP/MSEK": -2.738789,
    "out:ROI% old": 9.12401,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 128.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195395.2439,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228080.66,
    "out:DH kr savings": 195395.24390023164,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 20,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7579969.433439239,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.359184,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 61.899,
    "out:Total Energy Use Post PV": 58.185671,
    "out:Primary Energy": 54.288808,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 54.358764,
    "out:CO2 Operational/m2": -28.499696,
    "out:Total CO2/m2": 25.859068,
    "out:Total CO2 (tons)": 67.983474,
    "out:Klimatpaverkan": -52.155599,
    "out:Initial Cost/MSEK": 7.741924445939239,
    "out:Running cost/(Apt SEK y)": 10737.894737,
    "out:Running Cost over RSP/MSEK": 3.19902,
    "out:LCP/MSEK": -2.962854,
    "out:ROI% old": 8.818535,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193691.789915,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226377.2,
    "out:DH kr savings": 193691.78991520638,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7741924.445939239,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 6.185671,
    "out:Total Energy Use Pre PV": 58.899,
    "out:Total Energy Use Post PV": 55.185671,
    "out:Primary Energy": 52.189783,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 54.358764,
    "out:CO2 Operational/m2": -29.579648,
    "out:Total CO2/m2": 24.779116,
    "out:Total CO2 (tons)": 65.144281,
    "out:Klimatpaverkan": -54.994792,
    "out:Initial Cost/MSEK": 7.807649433439239,
    "out:Running cost/(Apt SEK y)": 10111.078947,
    "out:Running Cost over RSP/MSEK": 3.01271,
    "out:LCP/MSEK": -2.842269,
    "out:ROI% old": 9.085193,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 436.277909,
    "out:Return %": 8,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555677097,
    "out:PV (kWp installed)": 36.937315000000005,
    "out:PV (m2 panels)": 193.794935,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 34657.187361,
    "out:PV (kWh sold)": 24894.846361,
    "out:PV (% sold (El))": 71.831699,
    "out:PV (kWh self-consumed)": 9762.340999,
    "out:PV (ratio sold/self-consumed)": 2.55009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200786.795876,
    "out:EL kWh savings": -2328.42853,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233472.21,
    "out:DH kr savings": 200786.79587590403,
    "out:El kr savings": -4656.8570605088225,
    "out:El kr sold": 37342.269542082366,
    "out:El kr saved": 19524.681998,
    "out:El kr return": 56866.951540082366,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 17,
    "out:Ef": 6.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7807649.433439239,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": -47.218864,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.036887,
    "out:Bought CO2": 0.818023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 63.899,
    "out:Total Energy Use Post PV": 59.835188,
    "out:Primary Energy": 55.757938,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 68.213463,
    "out:CO2 Operational/m2": -93.21811,
    "out:Total CO2/m2": -25.004647,
    "out:Total CO2 (tons)": -65.737202,
    "out:Klimatpaverkan": -185.876276,
    "out:Initial Cost/MSEK": 8.15922766498799,
    "out:Running cost/(Apt SEK y)": 9775.605263,
    "out:Running Cost over RSP/MSEK": 2.9246,
    "out:LCP/MSEK": -3.105738,
    "out:ROI% old": 8.847984,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 116.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188300.23794,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273432.14,
    "out:DH kr savings": 188300.23793953398,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8159227.66498799,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 13.672,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 60.899,
    "out:Total Energy Use Post PV": 56.835188,
    "out:Primary Energy": 53.658913,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 68.213463,
    "out:CO2 Operational/m2": -94.298062,
    "out:Total CO2/m2": -26.084599,
    "out:Total CO2 (tons)": -68.576395,
    "out:Klimatpaverkan": -188.715469,
    "out:Initial Cost/MSEK": 8.224952652487989,
    "out:Running cost/(Apt SEK y)": 9148.789474,
    "out:Running Cost over RSP/MSEK": 2.7383,
    "out:LCP/MSEK": -2.985163,
    "out:ROI% old": 9.10086,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 128.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 13.672,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195395.2439,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280527.15,
    "out:DH kr savings": 195395.24390023164,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 35943.679838,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 20,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8224952.652487989,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.359184,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 40.184486,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 61.899,
    "out:Total Energy Use Post PV": 57.835188,
    "out:Primary Energy": 53.657938,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 68.928564,
    "out:CO2 Operational/m2": -93.938078,
    "out:Total CO2/m2": -25.009514,
    "out:Total CO2 (tons)": -65.749997,
    "out:Klimatpaverkan": -185.889071,
    "out:Initial Cost/MSEK": 8.386907664987989,
    "out:Running cost/(Apt SEK y)": 9357.736842,
    "out:Running Cost over RSP/MSEK": 2.8004,
    "out:LCP/MSEK": -3.209218,
    "out:ROI% old": 8.819341,
    "out:Payback discounted": 14,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 124.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 665,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193691.789915,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278823.7,
    "out:DH kr savings": 193691.78991520638,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 105644.990493,
    "out:% savings (space heating)": 62.926289,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 40.18,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8386907.664987989,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 37.485739,
    "out:DHW (DH)": 11.6212,
    "out:Property Electricity": 9.899,
    "out:Electricity (inc PV)": 5.835188,
    "out:Total Energy Use Pre PV": 58.899,
    "out:Total Energy Use Post PV": 54.835188,
    "out:Primary Energy": 51.558913,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 68.928564,
    "out:CO2 Operational/m2": -95.01803,
    "out:Total CO2/m2": -26.089466,
    "out:Total CO2 (tons)": -68.589191,
    "out:Klimatpaverkan": -188.728264,
    "out:Initial Cost/MSEK": 8.45263265248799,
    "out:Running cost/(Apt SEK y)": 8730.921053,
    "out:Running Cost over RSP/MSEK": 2.6141,
    "out:LCP/MSEK": -3.088643,
    "out:ROI% old": 9.065629,
    "out:Payback discounted": 13,
    "out:Atemp": 2629,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 775.1797400158418,
    "out:Facade surface/m2": 1173.670431579662,
    "out:Window surface/m2": 277.30466085404316,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 136.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 410.567136,
    "out:Return %": 8,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 68530.62694788756,
    "out:EAHP (pipework)": 68321.1219854625,
    "out:EAHP (natural ventilation grills)": 180903.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 11.6212,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555664408,
    "out:PV (kWp installed)": 73.874629,
    "out:PV (m2 panels)": 387.58987,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 69314.374721,
    "out:PV (kWh sold)": 58630.615315,
    "out:PV (% sold (El))": 84.586517,
    "out:PV (kWh self-consumed)": 10683.759406,
    "out:PV (ratio sold/self-consumed)": 5.487826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1110167.1099265341,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 152927.3864213021,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 38,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 13.672,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 469300,
    "out:DH (pipework)": 522500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 654464.8749885,
    "out:FTX (Facade)": 3207817.3412208,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200786.795876,
    "out:EL kWh savings": -1407.008932,
    "out:Sewage heat recovery cost": 227680,
    "out:Balancing hydronic system cost": 65724.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285918.7,
    "out:DH kr savings": 200786.79587590403,
    "out:El kr savings": -2814.0178649855407,
    "out:El kr sold": 87945.92297302163,
    "out:El kr saved": 21367.518812,
    "out:El kr return": 109313.44178502163,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 13933.696836,
    "out:Total_Space Heating (DH)": 98549.984532,
    "out:% savings (space heating)": 65.41612,
    "out:Total_Water Heating (DH)": 30552.127862,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 10,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 813859.6843706019,
    "out:Eupp": 37.49,
    "out:Etvv": 17,
    "out:Ef": 5.84,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8452632.65248799,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": -112.657246,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -113.428465,
    "out:Bought CO2": 0.771219,
    "out:Self-consume CO2": 0
  }
]