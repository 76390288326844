export const editableFields = new Set([
  "out:Windows(kr/m2)",
  "out:IMD (kr/apt)",
  "out:PV (kr/kWp)",
  "out:Fasadisolering (kr/m2)",
  "out:kr/heat exchanger",
  "out:number of heat exchangers",
  "out:cost/m2 injustering",
  "out:EAHP (Heatpump)",
  "out:EAHP (1000ltanks)",
  "out:EAHP (pipework)",
  "out:EAHP (natural ventilation grills)",
  "out:cost_raised roof",
  "out:EAHP (installation)",
  "out:EAHP (replacement cost)",
  "out:FTX (AHU)",
  "out:FTX (Ductwork)",
  "out:FTX (Diffusers)",
  "out:FTX (Dampers)",
  "out:FTX (Roof)",
  "out:FTX (Facade)",
  "out:FTX (Replacement Cost)",
  "out:FTX (S to F improvement cost)",
  "out:New AHU Cost/SEK",
  "out:EAHP (S to F improvement cost)",
]);
