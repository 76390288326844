export enum FormatCurrencyType {
  Decimal = "decimal",
  Integer = "",
  Unknown = "",
}

interface FormatCurrencyOption {
  type: FormatCurrencyType;
  maximumFractionDigits: number;
}

export const formatCurrency = (
  value: number | string,
  {
    type = FormatCurrencyType.Unknown,
    maximumFractionDigits = 0,
  }: FormatCurrencyOption = {
    type: FormatCurrencyType.Unknown,
    maximumFractionDigits: 0,
  },
): string => {
  if (typeof value === "string") {
    return value;
  }

  return new Intl.NumberFormat("sv-SE", {
    style: type ? type : undefined,
    maximumFractionDigits,
  }).format(value);
};
