import type { InputMap } from "packages/dataAccess";
import type { InstallationItem } from "packages/installations";
import type { Address } from "../../buildings";
import { Fagelbacksgatan37 } from "./Fagelbacksgatan37";
import { Lonngatan40A } from "./Lonngatan40A";
import { ThomsonsVag22 } from "./ThomsonsVag22";
import { Visbyringen34 } from "./Visbyringen34";
import { Oslogatan15 } from "./Oslogatan15";
import { Oslogatan19 } from "./Oslogatan19";
import { Plåtslagaren1A } from "./Plåtslagaren1A";
import { Plåtslagaren1B } from "./Plåtslagaren1B";

export const defaultInstallationFilters: Record<
  Address,
  Record<InstallationItem, InputMap>
> = {
  "Lönngatan 40": Lonngatan40A,
  "Fågelbacksgatan 37": Fagelbacksgatan37,
  "Thomsons Väg 22": ThomsonsVag22,
  "Visbyringen 4-16": Visbyringen34,
  "Visbyringen 18-22": Visbyringen34,
  "Visbyringen 24-32": Visbyringen34,
  "Visbyringen 34-42": Visbyringen34,
  "Oslogatan 15": Oslogatan15,
  "Oslogatan 17": Oslogatan15,
  "Oslogatan 19": Oslogatan19,
  "Oslogatan 21": Oslogatan15,
  "Folkparksvägen 3-9": Plåtslagaren1A,
  "Plåtslagarevägen 4A": Plåtslagaren1B,
  "Van Dürens Väg 45A": Plåtslagaren1B,
} as const;
