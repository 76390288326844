import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useSetAtom } from "jotai";
import {
  removeInstallationItemsWithNonEffectiveFilters,
  type Address,
} from "packages/buildings";
import {
  InstallationConfigurator,
  defaultInstallationFilters,
  getAvailabilityForDefaultFilter,
} from "packages/configurator";
import {
  BuildingData,
  type InputMap,
  datasetAtom,
  filterExtractor,
  useFieldValue,
} from "packages/dataAccess";
import {
  EnergyConsumptionChart,
  InstallationBreakdownTable,
} from "packages/installation-details";
import {
  type InstallationItem,
  getRawDataForListOfInstallations,
  installationItems,
  InstallationItems,
} from "packages/installations";
import { type ReactElement, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "./Header";
import { Company, CompanyId } from "packages/companies";
import {
  lowCostDatum,
  useCompanyTargetEnergyClass,
} from "packages/company-buldings";

export const BuildingDashboard = (): ReactElement => {
  const { address, company: companyId } = useParams<{
    address: Address;
    company: CompanyId;
  }>();

  if (address === undefined) {
    throw new Error("Address is undefined");
  }

  const [installationFilters, setInstallationFilters] = useState<
    (typeof defaultInstallationFilters)[Address] | undefined
  >();

  const [selectedInstallations, setSelectedInstallations] = useState<
    InstallationItem[] | undefined
  >();

  const dataset = useMemo(() => BuildingData[address], [address]);

  const targetEnergyClass = useCompanyTargetEnergyClass(companyId);

  const getFieldValue = useFieldValue();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const defaultInstallationFiltersForAddress =
      defaultInstallationFilters[address];

    const currentDatum = lowCostDatum({
      targetEnergyClass,
      dataset,
      getFieldValue,
      installationFilters: defaultInstallationFiltersForAddress,
      calculateLowCostByDefaultFilters:
        companyId === CompanyId[Company.CompanyC],
    });

    if (currentDatum) {
      const filters = filterExtractor([currentDatum]);
      const installationFilters: Record<InstallationItem, InputMap> = {
        ...defaultInstallationFiltersForAddress,
      };

      const newDefaultInstallationFilters: Record<InstallationItem, InputMap> =
        {
          ...defaultInstallationFiltersForAddress,
        };

      for (const item in installationFilters) {
        const installationItem = item as InstallationItem;
        const newInputMap: InputMap = new Map<string, number>(
          installationFilters[installationItem],
        );
        const newDefaultInputMap: InputMap = new Map<string, number>(
          installationFilters[installationItem],
        );

        const keyValues = Array.from(newInputMap.entries());

        for (const [key] of keyValues) {
          const values = filters.get(key);
          const value = values ? Array.from(values)[0] : 0;

          newInputMap.set(key, value);

          if (value !== 0) newDefaultInputMap.set(key, value);
        }

        installationFilters[installationItem] = newInputMap;

        newDefaultInstallationFilters[installationItem] = newDefaultInputMap;
      }

      setSelectedInstallations([
        ...Object.keys(installationFilters)
          .filter((item) => {
            const installation = item as InstallationItem;

            if (
              installation !== InstallationItems.nuläge &&
              Array.from(installationFilters[installation].entries()).every(
                ([_filterName, filterValue]) => filterValue === 0,
              )
            ) {
              return false;
            }

            return true;
          })
          .map((key) => key as InstallationItem),
      ]);

      setInstallationFilters(newDefaultInstallationFilters);
    } else {
      setInstallationFilters(defaultInstallationFiltersForAddress);

      setSelectedInstallations([
        ...installationItems.filter((item) => {
          return (
            getAvailabilityForDefaultFilter(item) &&
            removeInstallationItemsWithNonEffectiveFilters(
              item,
              defaultInstallationFiltersForAddress,
            )
          );
        }),
      ]);
    }
  }, [targetEnergyClass, companyId]);

  const setDataset = useSetAtom(datasetAtom);
  useEffect(() => {
    setDataset(dataset);
  }, [dataset, setDataset]);

  const selectedInstallationDataset = useMemo(() => {
    if (selectedInstallations && installationFilters) {
      return getRawDataForListOfInstallations(
        dataset,
        selectedInstallations,
        installationFilters,
      );
    }

    return undefined;
  }, [dataset, installationFilters, selectedInstallations]);

  return (
    <div>
      <div style={{ height: "100vh", overflow: "hidden" }}>
        {selectedInstallationDataset &&
        selectedInstallations &&
        installationFilters ? (
          <div style={{ height: "100%" }}>
            <div>
              <Header
                address={address}
                selectedInstallationDataset={selectedInstallationDataset}
              />
              <Divider />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "15px",
                height: "calc(100% - 160px)",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <Typography
                  marginTop={2}
                  variant="h6"
                  style={{ textAlign: "center" }}
                >
                  Renoveringsalternativ
                </Typography>
                <div
                  style={{ overflow: "auto", height: "100%", direction: "rtl" }}
                >
                  <div style={{ direction: "ltr" }}>
                    <InstallationConfigurator
                      selectedInstallations={selectedInstallations}
                      installationFilters={installationFilters}
                      dataset={dataset}
                      onSelectedInstallationChange={setSelectedInstallations}
                      onInstallationFiltersChange={setInstallationFilters}
                    />
                  </div>
                </div>
              </div>
              <div style={{ flex: 3, overflow: "auto" }}>
                <Typography
                  marginTop={2}
                  variant="h6"
                  style={{ textAlign: "center" }}
                >
                  Specifik energi{" "}
                  <span style={{ fontWeight: "normal" }}>
                    (kWh/m<sup style={{ fontSize: 14 }}>2</sup>
                    <sub style={{ fontSize: 14 }}>Atemp</sub>)
                  </span>
                </Typography>

                <div>
                  <EnergyConsumptionChart
                    selectedInstallationDataset={selectedInstallationDataset}
                    selectedInstallations={selectedInstallations}
                  />
                  <InstallationBreakdownTable
                    rawDataset={selectedInstallationDataset}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
