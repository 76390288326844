import { Address } from "packages/buildings";
import { Company, CompanyId } from "packages/companies";

export const CompanyBuildings: Record<CompanyId | "", Set<Address>> = {
  [CompanyId[Company.CompanyA]]: new Set([
    Address.visbyringen4,
    Address.visbyringen18,
    Address.visbyringen24,
    Address.visbyringen34,
    Address.oslogatan15,
    Address.oslogatan17,
    Address.oslogatan19,
    Address.oslogatan21,
  ]),
  [CompanyId[Company.CompanyB]]: new Set([
    Address.folkparksvägen3,
    Address.plåtslagarevägen4,
    Address.vandürensväg45,
  ]),
  [CompanyId[Company.CompanyC]]: new Set([
    Address.fågelbacksgatan37,
    Address.lönngatan40,
    Address.thomsonsVäg22,
  ]),
  [CompanyId[Company.CompanyD]]: new Set([]),
  "": new Set([]),
};
