import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { datasetAtom } from "../context";
import { overridesAtom } from "../context";
import { query } from "../query";
import type { InputMap, Value } from "../types";

export const useSetField = (inputMap: InputMap) => {
  const setOverride = useSetAtom(overridesAtom);
  const dataset = useAtomValue(datasetAtom);

  const allTheRows = useMemo(() => {
    return query({ dataset, inputMap });
  }, [dataset, inputMap]);

  const setFieldValue = useCallback(
    (value: Value, fieldName: string) => {
      setOverride((overrides) => {
        const updatedOverrides = new Map(overrides);
        for (const row of allTheRows) {
          const rowOverrides = new Map(updatedOverrides.get(String(row.ID)));
          rowOverrides.set(fieldName, value === "" ? undefined : value);
          updatedOverrides.set(String(row.ID), rowOverrides);
        }

        return updatedOverrides;
      });
    },
    [setOverride, allTheRows],
  );

  return setFieldValue;
};
