export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 138.44,
    "out:Total Energy Use Post PV": 138.44,
    "out:Primary Energy": 115.990512,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 48.140044,
    "out:Total CO2/m2": 48.140044,
    "out:Total CO2 (tons)": 94.932172,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 23809,
    "out:Running Cost over RSP/MSEK": 6.32907,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 3,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 134.44,
    "out:Total Energy Use Post PV": 134.44,
    "out:Primary Energy": 112.334519,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 46.700108,
    "out:Total CO2/m2": 46.700108,
    "out:Total CO2 (tons)": 92.092618,
    "out:Klimatpaverkan": -2.839554,
    "out:Initial Cost/MSEK": 0.0493,
    "out:Running cost/(Apt SEK y)": 23108.352941,
    "out:Running Cost over RSP/MSEK": 6.14274,
    "out:LCP/MSEK": 0.13703,
    "out:ROI% old": 53.993045,
    "out:Payback discounted": 3,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 48,
    "out:Return/kSEK/y": 24,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 9269.509589,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 9269.51,
    "out:DH kr savings": 9269.509588664105,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 49300,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 135.44,
    "out:Total Energy Use Post PV": 135.44,
    "out:Primary Energy": 113.365512,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.667343,
    "out:CO2 Operational/m2": 47.060092,
    "out:Total CO2/m2": 47.727435,
    "out:Total CO2 (tons)": 94.118507,
    "out:Klimatpaverkan": -0.813665,
    "out:Initial Cost/MSEK": 0.159376,
    "out:Running cost/(Apt SEK y)": 23283.529412,
    "out:Running Cost over RSP/MSEK": 6.18932,
    "out:LCP/MSEK": -0.019626,
    "out:ROI% old": 12.526532,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 2.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 11,
    "out:Return/kSEK/y": 18,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 6475.062374,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 6475.06,
    "out:DH kr savings": 6475.062374319007,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 159376,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.077952,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 130.44,
    "out:Total Energy Use Post PV": 130.44,
    "out:Primary Energy": 109.709519,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.667343,
    "out:CO2 Operational/m2": 45.260172,
    "out:Total CO2/m2": 45.927515,
    "out:Total CO2 (tons)": 90.569065,
    "out:Klimatpaverkan": -4.363107,
    "out:Initial Cost/MSEK": 0.208676,
    "out:Running cost/(Apt SEK y)": 22407.735294,
    "out:Running Cost over RSP/MSEK": 5.95641,
    "out:LCP/MSEK": 0.163984,
    "out:ROI% old": 25.511867,
    "out:Payback discounted": 5,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 23,
    "out:Return/kSEK/y": 48,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 15744.571963,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 15744.57,
    "out:DH kr savings": 15744.571962983111,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 208676,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 138.44,
    "out:Total Energy Use Post PV": 135.635375,
    "out:Primary Energy": 110.942187,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.261803,
    "out:CO2 Operational/m2": 10.302135,
    "out:Total CO2/m2": 21.563938,
    "out:Total CO2 (tons)": 42.524088,
    "out:Klimatpaverkan": -52.408084,
    "out:Initial Cost/MSEK": 0.37395486799625005,
    "out:Running cost/(Apt SEK y)": 22841.176471,
    "out:Running Cost over RSP/MSEK": 6.07873,
    "out:LCP/MSEK": -0.123615,
    "out:ROI% old": 9.563415,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 1.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 33,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 32906.2,
    "out:DH kr savings": 0,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 373954.86799625005,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 134.44,
    "out:Total Energy Use Post PV": 131.635375,
    "out:Primary Energy": 107.286194,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.261803,
    "out:CO2 Operational/m2": 8.862199,
    "out:Total CO2/m2": 20.124002,
    "out:Total CO2 (tons)": 39.684534,
    "out:Klimatpaverkan": -55.247638,
    "out:Initial Cost/MSEK": 0.42325486799625006,
    "out:Running cost/(Apt SEK y)": 22140.529412,
    "out:Running Cost over RSP/MSEK": 5.8924,
    "out:LCP/MSEK": 0.013415,
    "out:ROI% old": 14.738502,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 4.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 13,
    "out:Return/kSEK/y": 57,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 9269.509589,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 42175.71,
    "out:DH kr savings": 9269.509588664105,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 423254.86799625005,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 135.44,
    "out:Total Energy Use Post PV": 132.635375,
    "out:Primary Energy": 108.317187,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.929146,
    "out:CO2 Operational/m2": 9.222183,
    "out:Total CO2/m2": 21.151329,
    "out:Total CO2 (tons)": 41.710423,
    "out:Klimatpaverkan": -53.221749,
    "out:Initial Cost/MSEK": 0.5333308679962501,
    "out:Running cost/(Apt SEK y)": 22315.705882,
    "out:Running Cost over RSP/MSEK": 5.93899,
    "out:LCP/MSEK": -0.143251,
    "out:ROI% old": 10.44862,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 51,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 6475.062374,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 39381.26,
    "out:DH kr savings": 6475.062374319007,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 533330.86799625,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.077952,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 130.44,
    "out:Total Energy Use Post PV": 127.635375,
    "out:Primary Energy": 104.661194,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.929146,
    "out:CO2 Operational/m2": 7.422263,
    "out:Total CO2/m2": 19.351409,
    "out:Total CO2 (tons)": 38.160981,
    "out:Klimatpaverkan": -56.771192,
    "out:Initial Cost/MSEK": 0.5826308679962501,
    "out:Running cost/(Apt SEK y)": 21439.882353,
    "out:Running Cost over RSP/MSEK": 5.70608,
    "out:LCP/MSEK": 0.040359,
    "out:ROI% old": 15.275293,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 14,
    "out:Return/kSEK/y": 81,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 15744.571963,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 48650.77,
    "out:DH kr savings": 15744.571962983111,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 582630.86799625,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 138.44,
    "out:Total Energy Use Post PV": 135.377795,
    "out:Primary Energy": 110.478543,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.523606,
    "out:CO2 Operational/m2": -40.34639,
    "out:Total CO2/m2": -17.822784,
    "out:Total CO2 (tons)": -35.146532,
    "out:Klimatpaverkan": -130.078704,
    "out:Initial Cost/MSEK": 0.747909753455,
    "out:Running cost/(Apt SEK y)": 21947.205882,
    "out:Running Cost over RSP/MSEK": 5.84772,
    "out:LCP/MSEK": -0.26656,
    "out:ROI% old": 9.194196,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 2.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 63,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63301.01,
    "out:DH kr savings": 0,
    "out:El kr savings": 12077.337218182773,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 747909.753455,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 134.44,
    "out:Total Energy Use Post PV": 131.377795,
    "out:Primary Energy": 106.82255,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.523606,
    "out:CO2 Operational/m2": -41.786326,
    "out:Total CO2/m2": -19.26272,
    "out:Total CO2 (tons)": -37.986086,
    "out:Klimatpaverkan": -132.918258,
    "out:Initial Cost/MSEK": 0.797209753455,
    "out:Running cost/(Apt SEK y)": 21246.558824,
    "out:Running Cost over RSP/MSEK": 5.66139,
    "out:LCP/MSEK": -0.12953,
    "out:ROI% old": 11.964587,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 5.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 11,
    "out:Return/kSEK/y": 87,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 9269.509589,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72570.52,
    "out:DH kr savings": 9269.509588664105,
    "out:El kr savings": 12077.337218182773,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 797209.753455,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 135.44,
    "out:Total Energy Use Post PV": 132.377795,
    "out:Primary Energy": 107.853543,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.190949,
    "out:CO2 Operational/m2": -41.426342,
    "out:Total CO2/m2": -18.235393,
    "out:Total CO2 (tons)": -35.960197,
    "out:Klimatpaverkan": -130.892369,
    "out:Initial Cost/MSEK": 0.9072857534550001,
    "out:Running cost/(Apt SEK y)": 21421.735294,
    "out:Running Cost over RSP/MSEK": 5.70798,
    "out:LCP/MSEK": -0.286196,
    "out:ROI% old": 9.779404,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 4.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 81,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 6475.062374,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69776.08,
    "out:DH kr savings": 6475.062374319007,
    "out:El kr savings": 12077.337218182773,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 907285.7534550001,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.077952,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 130.44,
    "out:Total Energy Use Post PV": 127.377795,
    "out:Primary Energy": 104.19755,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.190949,
    "out:CO2 Operational/m2": -43.226262,
    "out:Total CO2/m2": -20.035313,
    "out:Total CO2 (tons)": -39.50964,
    "out:Klimatpaverkan": -134.441812,
    "out:Initial Cost/MSEK": 0.9565857534550001,
    "out:Running cost/(Apt SEK y)": 20545.941176,
    "out:Running Cost over RSP/MSEK": 5.47507,
    "out:LCP/MSEK": -0.102586,
    "out:ROI% old": 12.753692,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 12,
    "out:Return/kSEK/y": 111,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 15744.571963,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79045.59,
    "out:DH kr savings": 15744.571962983111,
    "out:El kr savings": 12077.337218182662,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 956585.7534550001,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 134.44,
    "out:Total Energy Use Post PV": 134.44,
    "out:Primary Energy": 112.490512,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 46.700108,
    "out:Total CO2/m2": 46.700108,
    "out:Total CO2 (tons)": 92.092618,
    "out:Klimatpaverkan": -2.839554,
    "out:Initial Cost/MSEK": 0.089736625,
    "out:Running cost/(Apt SEK y)": 23108.352941,
    "out:Running Cost over RSP/MSEK": 6.14274,
    "out:LCP/MSEK": 0.096593,
    "out:ROI% old": 29.662996,
    "out:Payback discounted": 4,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 27,
    "out:Return/kSEK/y": 24,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 8633.416499,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 8633.42,
    "out:DH kr savings": 8633.416499092,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 89736.625,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 129.44,
    "out:Total Energy Use Post PV": 129.44,
    "out:Primary Energy": 108.834519,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 44.900188,
    "out:Total CO2/m2": 44.900188,
    "out:Total CO2 (tons)": 88.543176,
    "out:Klimatpaverkan": -6.388996,
    "out:Initial Cost/MSEK": 0.139036625,
    "out:Running cost/(Apt SEK y)": 22232.558824,
    "out:Running Cost over RSP/MSEK": 5.90983,
    "out:LCP/MSEK": 0.280203,
    "out:ROI% old": 43.076009,
    "out:Payback discounted": 3,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 39,
    "out:Return/kSEK/y": 54,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 17902.926088,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 17902.93,
    "out:DH kr savings": 17902.926087756103,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 139036.625,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 131.44,
    "out:Total Energy Use Post PV": 131.44,
    "out:Primary Energy": 110.390512,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.667343,
    "out:CO2 Operational/m2": 45.620156,
    "out:Total CO2/m2": 46.287499,
    "out:Total CO2 (tons)": 91.278953,
    "out:Klimatpaverkan": -3.653219,
    "out:Initial Cost/MSEK": 0.249112625,
    "out:Running cost/(Apt SEK y)": 22582.882353,
    "out:Running Cost over RSP/MSEK": 6.00299,
    "out:LCP/MSEK": 0.076967,
    "out:ROI% old": 18.699517,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 5.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 17,
    "out:Return/kSEK/y": 42,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 13813.466399,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 13813.47,
    "out:DH kr savings": 13813.466398547205,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 249112.625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.638016,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 127.44,
    "out:Total Energy Use Post PV": 127.44,
    "out:Primary Energy": 106.734519,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.667343,
    "out:CO2 Operational/m2": 44.18022,
    "out:Total CO2/m2": 44.847563,
    "out:Total CO2 (tons)": 88.439399,
    "out:Klimatpaverkan": -6.492773,
    "out:Initial Cost/MSEK": 0.298412625,
    "out:Running cost/(Apt SEK y)": 21882.235294,
    "out:Running Cost over RSP/MSEK": 5.81667,
    "out:LCP/MSEK": 0.213987,
    "out:ROI% old": 24.529793,
    "out:Payback discounted": 5,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 22,
    "out:Return/kSEK/y": 66,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 23082.975987,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 23082.98,
    "out:DH kr savings": 23082.97598721131,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 298412.625,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 134.44,
    "out:Total Energy Use Post PV": 131.635375,
    "out:Primary Energy": 107.442187,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.261803,
    "out:CO2 Operational/m2": 8.862199,
    "out:Total CO2/m2": 20.124002,
    "out:Total CO2 (tons)": 39.684534,
    "out:Klimatpaverkan": -55.247638,
    "out:Initial Cost/MSEK": 0.46369149299625007,
    "out:Running cost/(Apt SEK y)": 22140.529412,
    "out:Running Cost over RSP/MSEK": 5.8924,
    "out:LCP/MSEK": -0.027021,
    "out:ROI% old": 13.453218,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 4.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 12,
    "out:Return/kSEK/y": 57,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 8633.416499,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 41539.62,
    "out:DH kr savings": 8633.416499092,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 463691.49299625005,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 129.44,
    "out:Total Energy Use Post PV": 126.635375,
    "out:Primary Energy": 103.786194,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.261803,
    "out:CO2 Operational/m2": 7.062279,
    "out:Total CO2/m2": 18.324082,
    "out:Total CO2 (tons)": 36.135092,
    "out:Klimatpaverkan": -58.79708,
    "out:Initial Cost/MSEK": 0.51299149299625,
    "out:Running cost/(Apt SEK y)": 21264.735294,
    "out:Running Cost over RSP/MSEK": 5.6595,
    "out:LCP/MSEK": 0.156579,
    "out:ROI% old": 18.64609,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 17,
    "out:Return/kSEK/y": 87,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 17902.926088,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 50809.13,
    "out:DH kr savings": 17902.926087756103,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 512991.49299625005,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 131.44,
    "out:Total Energy Use Post PV": 128.635375,
    "out:Primary Energy": 105.342187,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.929146,
    "out:CO2 Operational/m2": 7.782247,
    "out:Total CO2/m2": 19.711393,
    "out:Total CO2 (tons)": 38.870869,
    "out:Klimatpaverkan": -56.061303,
    "out:Initial Cost/MSEK": 0.6230674929962501,
    "out:Running cost/(Apt SEK y)": 21615.058824,
    "out:Running Cost over RSP/MSEK": 5.75266,
    "out:LCP/MSEK": -0.046657,
    "out:ROI% old": 13.21595,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 12,
    "out:Return/kSEK/y": 75,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 13813.466399,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46719.67,
    "out:DH kr savings": 13813.466398547205,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 623067.49299625,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.638016,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 127.44,
    "out:Total Energy Use Post PV": 124.635375,
    "out:Primary Energy": 101.686194,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.929146,
    "out:CO2 Operational/m2": 6.342311,
    "out:Total CO2/m2": 18.271457,
    "out:Total CO2 (tons)": 36.031315,
    "out:Klimatpaverkan": -58.900857,
    "out:Initial Cost/MSEK": 0.67236749299625,
    "out:Running cost/(Apt SEK y)": 20914.411765,
    "out:Running Cost over RSP/MSEK": 5.56633,
    "out:LCP/MSEK": 0.090373,
    "out:ROI% old": 16.205848,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 10.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 15,
    "out:Return/kSEK/y": 98,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 23082.975987,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55989.18,
    "out:DH kr savings": 23082.97598721131,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 672367.4929962499,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 134.44,
    "out:Total Energy Use Post PV": 131.377795,
    "out:Primary Energy": 106.978543,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.523606,
    "out:CO2 Operational/m2": -41.786326,
    "out:Total CO2/m2": -19.26272,
    "out:Total CO2 (tons)": -37.986086,
    "out:Klimatpaverkan": -132.918258,
    "out:Initial Cost/MSEK": 0.8376463784550001,
    "out:Running cost/(Apt SEK y)": 21246.558824,
    "out:Running Cost over RSP/MSEK": 5.66139,
    "out:LCP/MSEK": -0.169966,
    "out:ROI% old": 11.387008,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 5.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 87,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 8633.416499,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71934.43,
    "out:DH kr savings": 8633.416499092,
    "out:El kr savings": 12077.337218182773,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 837646.3784550001,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 129.44,
    "out:Total Energy Use Post PV": 126.377795,
    "out:Primary Energy": 103.32255,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.523606,
    "out:CO2 Operational/m2": -43.586246,
    "out:Total CO2/m2": -21.06264,
    "out:Total CO2 (tons)": -41.535528,
    "out:Klimatpaverkan": -136.467701,
    "out:Initial Cost/MSEK": 0.8869463784550001,
    "out:Running cost/(Apt SEK y)": 20370.764706,
    "out:Running Cost over RSP/MSEK": 5.42848,
    "out:LCP/MSEK": 0.013644,
    "out:ROI% old": 14.505467,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 9.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 13,
    "out:Return/kSEK/y": 117,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 17902.926088,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 81203.94,
    "out:DH kr savings": 17902.926087756103,
    "out:El kr savings": 12077.337218182662,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 886946.3784550001,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.412373,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 131.44,
    "out:Total Energy Use Post PV": 128.377795,
    "out:Primary Energy": 104.878543,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.190949,
    "out:CO2 Operational/m2": -42.866278,
    "out:Total CO2/m2": -19.675329,
    "out:Total CO2 (tons)": -38.799751,
    "out:Klimatpaverkan": -133.731923,
    "out:Initial Cost/MSEK": 0.9970223784550001,
    "out:Running cost/(Apt SEK y)": 20721.088235,
    "out:Running Cost over RSP/MSEK": 5.52165,
    "out:LCP/MSEK": -0.189602,
    "out:ROI% old": 11.56902,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 11,
    "out:Return/kSEK/y": 105,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 13813.466399,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77114.48,
    "out:DH kr savings": 13813.466398547205,
    "out:El kr savings": 12077.337218182773,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 215761.211635,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.41,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 997022.3784550001,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.638016,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.71181,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 127.44,
    "out:Total Energy Use Post PV": 124.377795,
    "out:Primary Energy": 101.22255,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.190949,
    "out:CO2 Operational/m2": -44.306214,
    "out:Total CO2/m2": -21.115265,
    "out:Total CO2 (tons)": -41.639305,
    "out:Klimatpaverkan": -136.571477,
    "out:Initial Cost/MSEK": 1.0463223784550002,
    "out:Running cost/(Apt SEK y)": 20020.441176,
    "out:Running Cost over RSP/MSEK": 5.33532,
    "out:LCP/MSEK": -0.052572,
    "out:ROI% old": 13.56793,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 12,
    "out:Return/kSEK/y": 129,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 23082.975987,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 86383.99,
    "out:DH kr savings": 23082.97598721131,
    "out:El kr savings": 12077.337218182662,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 206491.702046,
    "out:% savings (space heating)": 4.296189,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.71,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1046322.3784550002,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.79034,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.288601,
    "out:Electricity (inc PV)": 29.64237,
    "out:Total Energy Use Pre PV": 96.288601,
    "out:Total Energy Use Post PV": 96.64237,
    "out:Primary Energy": 106.815189,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.830501,
    "out:CO2 Operational/m2": 27.985258,
    "out:Total CO2/m2": 32.815759,
    "out:Total CO2 (tons)": 64.71268,
    "out:Klimatpaverkan": -30.219492,
    "out:Initial Cost/MSEK": 1.8509927682456162,
    "out:Running cost/(Apt SEK y)": 15133.205882,
    "out:Running Cost over RSP/MSEK": 4.014,
    "out:LCP/MSEK": 0.464077,
    "out:ROI% old": 17.8674,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 42.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2050.20207,
    "out:Return %": 16,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.549101,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 127217.736036,
    "out:EL kWh savings": -43783.076171,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 39651.58,
    "out:DH kr savings": 127217.73603597174,
    "out:El kr savings": -87566.15234214035,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 131710.558094,
    "out:% savings (space heating)": 38.955405,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.73,
    "out:Etvv": 0,
    "out:Ef": 29.64,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 1850992.7682456162,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": 3.86633,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.86633,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.290568,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.227756,
    "out:Electricity (inc PV)": 29.586646,
    "out:Total Energy Use Pre PV": 91.227756,
    "out:Total Energy Use Post PV": 91.586646,
    "out:Primary Energy": 103.20192,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.830501,
    "out:CO2 Operational/m2": 26.177306,
    "out:Total CO2/m2": 31.007807,
    "out:Total CO2 (tons)": 61.147399,
    "out:Klimatpaverkan": -33.784773,
    "out:Initial Cost/MSEK": 1.9002927682456163,
    "out:Running cost/(Apt SEK y)": 14250.352941,
    "out:Running Cost over RSP/MSEK": 3.77924,
    "out:LCP/MSEK": 0.649537,
    "out:ROI% old": 19.1687,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2045.94292,
    "out:Return %": 17,
    "out:Return/kSEK/y": 325,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.488256,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 136091.286933,
    "out:EL kWh savings": -43673.188437,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 48744.91,
    "out:DH kr savings": 136091.28693294572,
    "out:El kr savings": -87346.37687343529,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 122837.007197,
    "out:% savings (space heating)": 43.068077,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.22,
    "out:Etvv": 0,
    "out:Ef": 29.59,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 1900292.7682456162,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": 3.858298,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.858298,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.762629,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.908059,
    "out:Electricity (inc PV)": 29.214979,
    "out:Total Energy Use Pre PV": 93.908059,
    "out:Total Energy Use Post PV": 94.214979,
    "out:Primary Energy": 104.226188,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.497844,
    "out:CO2 Operational/m2": 27.215055,
    "out:Total CO2/m2": 32.712899,
    "out:Total CO2 (tons)": 64.509841,
    "out:Klimatpaverkan": -30.422332,
    "out:Initial Cost/MSEK": 2.010368768245616,
    "out:Running cost/(Apt SEK y)": 14738.735294,
    "out:Running Cost over RSP/MSEK": 3.90923,
    "out:LCP/MSEK": 0.409471,
    "out:ROI% old": 17.195424,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2023.56413,
    "out:Return %": 15,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 19.168559,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 131216.382359,
    "out:EL kWh savings": -42940.26107,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 45335.86,
    "out:DH kr savings": 131216.38235913077,
    "out:El kr savings": -85880.5221406952,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 127711.911771,
    "out:% savings (space heating)": 40.808679,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 66.39,
    "out:Etvv": 0,
    "out:Ef": 29.21,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2010368.7682456162,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.39896,
    "out:EL CO2": 3.816095,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.816095,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.278775,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.84239,
    "out:Electricity (inc PV)": 29.153033,
    "out:Total Energy Use Pre PV": 88.84239,
    "out:Total Energy Use Post PV": 89.153033,
    "out:Primary Energy": 100.617688,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.497844,
    "out:CO2 Operational/m2": 25.406466,
    "out:Total CO2/m2": 30.90431,
    "out:Total CO2 (tons)": 60.943303,
    "out:Klimatpaverkan": -33.988869,
    "out:Initial Cost/MSEK": 2.0596687682456163,
    "out:Running cost/(Apt SEK y)": 13855.323529,
    "out:Running Cost over RSP/MSEK": 3.67432,
    "out:LCP/MSEK": 0.595081,
    "out:ROI% old": 18.413155,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2018.9673,
    "out:Return %": 16,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 19.10289,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140058.542958,
    "out:EL kWh savings": -42818.103551,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 54422.34,
    "out:DH kr savings": 140058.54295829014,
    "out:El kr savings": -85636.2071025715,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 118869.751172,
    "out:% savings (space heating)": 44.906802,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 61.9,
    "out:Etvv": 0,
    "out:Ef": 29.15,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2059668.7682456162,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 3.807426,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.807426,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.79034,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.288601,
    "out:Electricity (inc PV)": 22.144894,
    "out:Total Energy Use Pre PV": 96.288601,
    "out:Total Energy Use Post PV": 89.144894,
    "out:Primary Energy": 93.319732,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.092304,
    "out:CO2 Operational/m2": 13.373143,
    "out:Total CO2/m2": 29.465447,
    "out:Total CO2 (tons)": 58.105865,
    "out:Klimatpaverkan": -36.826307,
    "out:Initial Cost/MSEK": 2.224947636241866,
    "out:Running cost/(Apt SEK y)": 14030.764706,
    "out:Running Cost over RSP/MSEK": 3.72864,
    "out:LCP/MSEK": 0.375482,
    "out:ROI% old": 16.696573,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1544.449167,
    "out:Return %": 15,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.549101,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5410.138796,
    "out:PV (% sold (El))": 26.924293,
    "out:PV (kWh self-consumed)": 14683.754481,
    "out:PV (ratio sold/self-consumed)": 0.368444,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 127217.736036,
    "out:EL kWh savings": -28998.052644,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77336.84,
    "out:DH kr savings": 127217.73603597174,
    "out:El kr savings": -57996.1052887158,
    "out:El kr sold": 8115.208194487273,
    "out:El kr saved": 29367.508962,
    "out:El kr return": 37482.71715648727,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 131710.558094,
    "out:% savings (space heating)": 38.955405,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.73,
    "out:Etvv": 0,
    "out:Ef": 22.14,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2224947.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -10.745785,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -13.636041,
    "out:Bought CO2": 2.890256,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.290568,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.227756,
    "out:Electricity (inc PV)": 22.097201,
    "out:Total Energy Use Pre PV": 91.227756,
    "out:Total Energy Use Post PV": 84.097201,
    "out:Primary Energy": 89.720919,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.092304,
    "out:CO2 Operational/m2": 11.521099,
    "out:Total CO2/m2": 27.613403,
    "out:Total CO2 (tons)": 54.453634,
    "out:Klimatpaverkan": -40.478538,
    "out:Initial Cost/MSEK": 2.274247636241866,
    "out:Running cost/(Apt SEK y)": 13148.176471,
    "out:Running Cost over RSP/MSEK": 3.49395,
    "out:LCP/MSEK": 0.560872,
    "out:ROI% old": 17.808841,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 64.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1540.766385,
    "out:Return %": 16,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.488256,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5427.70541,
    "out:PV (% sold (El))": 27.011716,
    "out:PV (kWh self-consumed)": 14666.187868,
    "out:PV (ratio sold/self-consumed)": 0.370083,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 136091.286933,
    "out:EL kWh savings": -28904.002043,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 86424.84,
    "out:DH kr savings": 136091.28693294572,
    "out:El kr savings": -57808.004085841814,
    "out:El kr sold": 8141.558114714197,
    "out:El kr saved": 29332.375736,
    "out:El kr return": 37473.933850714195,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 122837.007197,
    "out:% savings (space heating)": 43.068077,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.22,
    "out:Etvv": 0,
    "out:Ef": 22.1,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2274247.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -10.797909,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -13.681285,
    "out:Bought CO2": 2.883376,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.762629,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.908059,
    "out:Electricity (inc PV)": 21.779641,
    "out:Total Energy Use Pre PV": 93.908059,
    "out:Total Energy Use Post PV": 86.779641,
    "out:Primary Energy": 90.84258,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.759647,
    "out:CO2 Operational/m2": 12.325348,
    "out:Total CO2/m2": 29.084995,
    "out:Total CO2 (tons)": 57.355613,
    "out:Klimatpaverkan": -37.576559,
    "out:Initial Cost/MSEK": 2.3843236362418665,
    "out:Running cost/(Apt SEK y)": 13637.941176,
    "out:Running Cost over RSP/MSEK": 3.62429,
    "out:LCP/MSEK": 0.320456,
    "out:ROI% old": 16.205734,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 1521.438571,
    "out:Return %": 15,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 19.168559,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5520.697538,
    "out:PV (% sold (El))": 27.474504,
    "out:PV (kWh self-consumed)": 14573.19574,
    "out:PV (ratio sold/self-consumed)": 0.378825,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131216.382359,
    "out:EL kWh savings": -28277.773687,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82941.88,
    "out:DH kr savings": 131216.38235913077,
    "out:El kr savings": -56555.54737343813,
    "out:El kr sold": 8281.046306469478,
    "out:El kr saved": 29146.39148,
    "out:El kr return": 37427.437786469476,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 127711.911771,
    "out:% savings (space heating)": 40.808679,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 66.39,
    "out:Etvv": 0,
    "out:Ef": 21.78,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2384323.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.39896,
    "out:EL CO2": -11.073612,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -13.920888,
    "out:Bought CO2": 2.847276,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.278775,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.84239,
    "out:Electricity (inc PV)": 21.726803,
    "out:Total Energy Use Pre PV": 88.84239,
    "out:Total Energy Use Post PV": 81.726803,
    "out:Primary Energy": 87.250474,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.759647,
    "out:CO2 Operational/m2": 10.468402,
    "out:Total CO2/m2": 27.228049,
    "out:Total CO2 (tons)": 53.693716,
    "out:Klimatpaverkan": -41.238457,
    "out:Initial Cost/MSEK": 2.433623636241866,
    "out:Running cost/(Apt SEK y)": 12754.794118,
    "out:Running Cost over RSP/MSEK": 3.38946,
    "out:LCP/MSEK": 0.505986,
    "out:ROI% old": 17.255926,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1517.473259,
    "out:Return %": 15,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 19.10289,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5539.961012,
    "out:PV (% sold (El))": 27.570371,
    "out:PV (kWh self-consumed)": 14553.932265,
    "out:PV (ratio sold/self-consumed)": 0.38065,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 140058.542958,
    "out:EL kWh savings": -28173.577145,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92021.33,
    "out:DH kr savings": 140058.54295829014,
    "out:El kr savings": -56347.15428939115,
    "out:El kr sold": 8309.941518454916,
    "out:El kr saved": 29107.86453,
    "out:El kr return": 37417.80604845491,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 118869.751172,
    "out:% savings (space heating)": 44.906802,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 61.9,
    "out:Etvv": 0,
    "out:Ef": 21.73,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2433623.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -11.130638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -13.970509,
    "out:Bought CO2": 2.839871,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.79034,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.288601,
    "out:Electricity (inc PV)": 20.060842,
    "out:Total Energy Use Pre PV": 96.288601,
    "out:Total Energy Use Post PV": 87.060842,
    "out:Primary Energy": 89.568439,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.354107,
    "out:CO2 Operational/m2": -28.178378,
    "out:Total CO2/m2": -0.824271,
    "out:Total CO2 (tons)": -1.625463,
    "out:Klimatpaverkan": -96.557635,
    "out:Initial Cost/MSEK": 2.598902521700616,
    "out:Running cost/(Apt SEK y)": 13084.735294,
    "out:Running Cost over RSP/MSEK": 3.48391,
    "out:LCP/MSEK": 0.246257,
    "out:ROI% old": 15.639349,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1398.367882,
    "out:Return %": 14,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.549101,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21455.333214,
    "out:PV (% sold (El))": 53.387696,
    "out:PV (kWh self-consumed)": 18732.453341,
    "out:PV (ratio sold/self-consumed)": 1.145356,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 127217.736036,
    "out:EL kWh savings": -24888.301863,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109624.13,
    "out:DH kr savings": 127217.73603597174,
    "out:El kr savings": -49776.603725552,
    "out:El kr sold": 32182.99982043018,
    "out:El kr saved": 37464.906682,
    "out:El kr return": 69647.90650243018,
    "out:% solar/total": 128,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 131710.558094,
    "out:% savings (space heating)": 38.955405,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.73,
    "out:Etvv": 0,
    "out:Ef": 20.06,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2598902.521700616,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -52.297306,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -54.915481,
    "out:Bought CO2": 2.618175,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.290568,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.227756,
    "out:Electricity (inc PV)": 20.01805,
    "out:Total Energy Use Pre PV": 91.227756,
    "out:Total Energy Use Post PV": 82.01805,
    "out:Primary Energy": 85.978447,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.354107,
    "out:CO2 Operational/m2": -30.057457,
    "out:Total CO2/m2": -2.70335,
    "out:Total CO2 (tons)": -5.331007,
    "out:Klimatpaverkan": -100.263179,
    "out:Initial Cost/MSEK": 2.648202521700616,
    "out:Running cost/(Apt SEK y)": 12202.294118,
    "out:Running Cost over RSP/MSEK": 3.24925,
    "out:LCP/MSEK": 0.431617,
    "out:ROI% old": 16.614072,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1395.063575,
    "out:Return %": 15,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.488256,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21483.323321,
    "out:PV (% sold (El))": 53.457344,
    "out:PV (kWh self-consumed)": 18704.463234,
    "out:PV (ratio sold/self-consumed)": 1.148567,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 136091.286933,
    "out:EL kWh savings": -24803.916034,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118708.44,
    "out:DH kr savings": 136091.28693294572,
    "out:El kr savings": -49607.8320677954,
    "out:El kr sold": 32224.984980809146,
    "out:El kr saved": 37408.926468,
    "out:El kr return": 69633.91144880914,
    "out:% solar/total": 127,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 122837.007197,
    "out:% savings (space heating)": 43.068077,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.22,
    "out:Etvv": 0,
    "out:Ef": 20.02,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2648202.521700616,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -52.376465,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -54.988467,
    "out:Bought CO2": 2.612002,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.762629,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.908059,
    "out:Electricity (inc PV)": 19.73294,
    "out:Total Energy Use Pre PV": 93.908059,
    "out:Total Energy Use Post PV": 84.73294,
    "out:Primary Energy": 87.158518,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.02145,
    "out:CO2 Operational/m2": -29.39476,
    "out:Total CO2/m2": -1.37331,
    "out:Total CO2 (tons)": -2.708167,
    "out:Klimatpaverkan": -97.64034,
    "out:Initial Cost/MSEK": 2.758278521700616,
    "out:Running cost/(Apt SEK y)": 12692.852941,
    "out:Running Cost over RSP/MSEK": 3.37981,
    "out:LCP/MSEK": 0.190981,
    "out:ROI% old": 15.274848,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 1377.72187,
    "out:Return %": 14,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 19.168559,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21630.903874,
    "out:PV (% sold (El))": 53.824571,
    "out:PV (kWh self-consumed)": 18556.882681,
    "out:PV (ratio sold/self-consumed)": 1.165654,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131216.382359,
    "out:EL kWh savings": -24241.679081,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115179.38,
    "out:DH kr savings": 131216.38235913077,
    "out:El kr savings": -48483.35816279025,
    "out:El kr sold": 32446.35581089025,
    "out:El kr saved": 37113.765362,
    "out:El kr return": 69560.12117289024,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 127711.911771,
    "out:% savings (space heating)": 40.808679,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 66.39,
    "out:Etvv": 0,
    "out:Ef": 19.73,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2758278.521700616,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.39896,
    "out:EL CO2": -52.79372,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.37332,
    "out:Bought CO2": 2.5796,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.278775,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.84239,
    "out:Electricity (inc PV)": 19.685456,
    "out:Total Energy Use Pre PV": 88.84239,
    "out:Total Energy Use Post PV": 79.685456,
    "out:Primary Energy": 83.57605,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.02145,
    "out:CO2 Operational/m2": -31.280655,
    "out:Total CO2/m2": -3.259205,
    "out:Total CO2 (tons)": -6.427153,
    "out:Klimatpaverkan": -101.359325,
    "out:Initial Cost/MSEK": 2.807578521700616,
    "out:Running cost/(Apt SEK y)": 11809.882353,
    "out:Running Cost over RSP/MSEK": 3.14502,
    "out:LCP/MSEK": 0.376471,
    "out:ROI% old": 16.201302,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1374.163902,
    "out:Return %": 15,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 19.10289,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21661.336936,
    "out:PV (% sold (El))": 53.900299,
    "out:PV (kWh self-consumed)": 18526.449619,
    "out:PV (ratio sold/self-consumed)": 1.169211,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 140058.542958,
    "out:EL kWh savings": -24148.040628,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124254.47,
    "out:DH kr savings": 140058.54295829014,
    "out:El kr savings": -48296.081255964025,
    "out:El kr sold": 32492.00540398775,
    "out:El kr saved": 37052.899238,
    "out:El kr return": 69544.90464198776,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 118869.751172,
    "out:% savings (space heating)": 44.906802,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 61.9,
    "out:Etvv": 0,
    "out:Ef": 19.69,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2807578.521700616,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -52.879695,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.452646,
    "out:Bought CO2": 2.572951,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.091221,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.779849,
    "out:Electricity (inc PV)": 29.070505,
    "out:Total Energy Use Pre PV": 92.779849,
    "out:Total Energy Use Post PV": 93.070505,
    "out:Primary Energy": 103.36473,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.830501,
    "out:CO2 Operational/m2": 26.838146,
    "out:Total CO2/m2": 31.668647,
    "out:Total CO2 (tons)": 62.450575,
    "out:Klimatpaverkan": -32.481597,
    "out:Initial Cost/MSEK": 1.9407293932456162,
    "out:Running cost/(Apt SEK y)": 14548.705882,
    "out:Running Cost over RSP/MSEK": 3.85874,
    "out:LCP/MSEK": 0.529601,
    "out:ROI% old": 18.184106,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2014.58943,
    "out:Return %": 16,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 19.040349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 132540.399012,
    "out:EL kWh savings": -42655.358326,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47229.68,
    "out:DH kr savings": 132540.39901167128,
    "out:El kr savings": -85310.71665175512,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126387.895118,
    "out:% savings (space heating)": 41.422328,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 65.62,
    "out:Etvv": 0,
    "out:Ef": 29.07,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 1940729.3932456162,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": 3.79917,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.79917,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.613476,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.712329,
    "out:Electricity (inc PV)": 29.006611,
    "out:Total Energy Use Pre PV": 88.712329,
    "out:Total Energy Use Post PV": 89.006611,
    "out:Primary Energy": 99.757722,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.830501,
    "out:CO2 Operational/m2": 25.389297,
    "out:Total CO2/m2": 30.219798,
    "out:Total CO2 (tons)": 59.593445,
    "out:Klimatpaverkan": -35.338727,
    "out:Initial Cost/MSEK": 1.9900293932456161,
    "out:Running cost/(Apt SEK y)": 13840.235294,
    "out:Running Cost over RSP/MSEK": 3.67036,
    "out:LCP/MSEK": 0.668681,
    "out:ROI% old": 19.085935,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2009.86303,
    "out:Return %": 17,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.972829,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141370.512662,
    "out:EL kWh savings": -42529.359351,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 56311.79,
    "out:DH kr savings": 141370.51266213425,
    "out:El kr savings": -85058.71870118727,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 117557.781468,
    "out:% savings (space heating)": 45.514868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 61.13,
    "out:Etvv": 0,
    "out:Ef": 29.01,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 1990029.3932456162,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 3.790257,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.790257,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.489314,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.469276,
    "out:Electricity (inc PV)": 28.719647,
    "out:Total Energy Use Pre PV": 90.469276,
    "out:Total Energy Use Post PV": 90.719647,
    "out:Primary Energy": 101.300389,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.497844,
    "out:CO2 Operational/m2": 26.07718,
    "out:Total CO2/m2": 31.575024,
    "out:Total CO2 (tons)": 62.265951,
    "out:Klimatpaverkan": -32.666221,
    "out:Initial Cost/MSEK": 2.1001053932456166,
    "out:Running cost/(Apt SEK y)": 14162.352941,
    "out:Running Cost over RSP/MSEK": 3.75611,
    "out:LCP/MSEK": 0.472855,
    "out:ROI% old": 17.502251,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1992.84932,
    "out:Return %": 16,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.729776,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 135699.359798,
    "out:EL kWh savings": -41963.46631,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51772.43,
    "out:DH kr savings": 135699.35979828867,
    "out:El kr savings": -83926.93261975943,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 123228.934332,
    "out:% savings (space heating)": 42.886428,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 63.78,
    "out:Etvv": 0,
    "out:Ef": 28.72,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2100105.3932456165,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": 3.758172,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.758172,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.027294,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.39699,
    "out:Electricity (inc PV)": 28.651193,
    "out:Total Energy Use Pre PV": 86.39699,
    "out:Total Energy Use Post PV": 86.651193,
    "out:Primary Energy": 97.696875,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.497844,
    "out:CO2 Operational/m2": 24.627702,
    "out:Total CO2/m2": 30.125546,
    "out:Total CO2 (tons)": 59.40758,
    "out:Klimatpaverkan": -35.524592,
    "out:Initial Cost/MSEK": 2.1494053932456163,
    "out:Running cost/(Apt SEK y)": 13453.323529,
    "out:Running Cost over RSP/MSEK": 3.56758,
    "out:LCP/MSEK": 0.612085,
    "out:ROI% old": 18.353847,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 1987.7893,
    "out:Return %": 16,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.65749,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144498.463747,
    "out:EL kWh savings": -41828.475014,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60841.51,
    "out:DH kr savings": 144498.463746959,
    "out:El kr savings": -83656.95002815202,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 114429.830383,
    "out:% savings (space heating)": 46.964596,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 59.3,
    "out:Etvv": 0,
    "out:Ef": 28.65,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2149405.3932456165,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": 3.74863,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.74863,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.091221,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.779849,
    "out:Electricity (inc PV)": 21.656428,
    "out:Total Energy Use Pre PV": 92.779849,
    "out:Total Energy Use Post PV": 85.656428,
    "out:Primary Energy": 90.019391,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.092304,
    "out:CO2 Operational/m2": 11.853892,
    "out:Total CO2/m2": 27.946196,
    "out:Total CO2 (tons)": 55.109902,
    "out:Klimatpaverkan": -39.822271,
    "out:Initial Cost/MSEK": 2.314684261241866,
    "out:Running cost/(Apt SEK y)": 13448.441176,
    "out:Running Cost over RSP/MSEK": 3.57394,
    "out:LCP/MSEK": 0.440446,
    "out:ROI% old": 17.004047,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1513.698367,
    "out:Return %": 15,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 19.040349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5558.361991,
    "out:PV (% sold (El))": 27.661946,
    "out:PV (kWh self-consumed)": 14535.531287,
    "out:PV (ratio sold/self-consumed)": 0.382398,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132540.399012,
    "out:EL kWh savings": -28034.797637,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84808.35,
    "out:DH kr savings": 132540.39901167128,
    "out:El kr savings": -56069.5952733456,
    "out:El kr sold": 8337.542986231327,
    "out:El kr saved": 29071.062574,
    "out:El kr return": 37408.60556023133,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126387.895118,
    "out:% savings (space heating)": 41.422328,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 65.62,
    "out:Etvv": 0,
    "out:Ef": 21.66,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2314684.2612418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": -11.185084,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.017907,
    "out:Bought CO2": 2.832823,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.613476,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.712329,
    "out:Electricity (inc PV)": 21.601965,
    "out:Total Energy Use Pre PV": 88.712329,
    "out:Total Energy Use Post PV": 81.601965,
    "out:Primary Energy": 86.429359,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.092304,
    "out:CO2 Operational/m2": 10.35503,
    "out:Total CO2/m2": 26.447334,
    "out:Total CO2 (tons)": 52.154146,
    "out:Klimatpaverkan": -42.778027,
    "out:Initial Cost/MSEK": 2.3639842612418662,
    "out:Running cost/(Apt SEK y)": 12740.264706,
    "out:Running Cost over RSP/MSEK": 3.38563,
    "out:LCP/MSEK": 0.579456,
    "out:ROI% old": 17.787404,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1509.624643,
    "out:Return %": 16,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.972829,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5578.277156,
    "out:PV (% sold (El))": 27.761057,
    "out:PV (kWh self-consumed)": 14515.616122,
    "out:PV (ratio sold/self-consumed)": 0.384295,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141370.512662,
    "out:EL kWh savings": -27927.396594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93883.14,
    "out:DH kr savings": 141370.51266213425,
    "out:El kr savings": -55854.79318892804,
    "out:El kr sold": 8367.415733679842,
    "out:El kr saved": 29031.232244,
    "out:El kr return": 37398.64797767984,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 117557.781468,
    "out:% savings (space heating)": 45.514868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 61.13,
    "out:Etvv": 0,
    "out:Ef": 21.6,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2363984.2612418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -11.24401,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.069227,
    "out:Bought CO2": 2.825217,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.489314,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.469276,
    "out:Electricity (inc PV)": 21.35769,
    "out:Total Energy Use Pre PV": 90.469276,
    "out:Total Energy Use Post PV": 83.35769,
    "out:Primary Energy": 88.048866,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.759647,
    "out:CO2 Operational/m2": 10.86171,
    "out:Total CO2/m2": 27.621357,
    "out:Total CO2 (tons)": 54.469319,
    "out:Klimatpaverkan": -40.462853,
    "out:Initial Cost/MSEK": 2.474060261241866,
    "out:Running cost/(Apt SEK y)": 13063.470588,
    "out:Running Cost over RSP/MSEK": 3.47166,
    "out:LCP/MSEK": 0.38335,
    "out:ROI% old": 16.499252,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1494.975248,
    "out:Return %": 15,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.729776,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5650.37208,
    "out:PV (% sold (El))": 28.119847,
    "out:PV (kWh self-consumed)": 14443.521198,
    "out:PV (ratio sold/self-consumed)": 0.391205,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135699.359798,
    "out:EL kWh savings": -27445.686267,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89283.55,
    "out:DH kr savings": 135699.35979828867,
    "out:El kr savings": -54891.372533233276,
    "out:El kr sold": 8475.55811964153,
    "out:El kr saved": 28887.042396,
    "out:El kr return": 37362.600515641534,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 123228.934332,
    "out:% savings (space heating)": 42.886428,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 63.78,
    "out:Etvv": 0,
    "out:Ef": 21.36,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2474060.2612418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -11.457298,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.255166,
    "out:Bought CO2": 2.797868,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.027294,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.39699,
    "out:Electricity (inc PV)": 21.299509,
    "out:Total Energy Use Pre PV": 86.39699,
    "out:Total Energy Use Post PV": 79.299509,
    "out:Primary Energy": 84.463844,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.759647,
    "out:CO2 Operational/m2": 9.358006,
    "out:Total CO2/m2": 26.117653,
    "out:Total CO2 (tons)": 51.504015,
    "out:Klimatpaverkan": -43.428158,
    "out:Initial Cost/MSEK": 2.523360261241866,
    "out:Running cost/(Apt SEK y)": 12354.735294,
    "out:Running Cost over RSP/MSEK": 3.28321,
    "out:LCP/MSEK": 0.5225,
    "out:ROI% old": 17.243787,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 1490.622932,
    "out:Return %": 15,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.65749,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5671.903024,
    "out:PV (% sold (El))": 28.226999,
    "out:PV (kWh self-consumed)": 14421.990253,
    "out:PV (ratio sold/self-consumed)": 0.393282,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144498.463747,
    "out:EL kWh savings": -27330.953328,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98344.41,
    "out:DH kr savings": 144498.463746959,
    "out:El kr savings": -54661.90665596805,
    "out:El kr sold": 8507.854536583513,
    "out:El kr saved": 28843.980506,
    "out:El kr return": 37351.83504258351,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 114429.830383,
    "out:% savings (space heating)": 46.964596,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 59.3,
    "out:Etvv": 0,
    "out:Ef": 21.3,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2523360.2612418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": -11.521066,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.310809,
    "out:Bought CO2": 2.789743,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.091221,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.779849,
    "out:Electricity (inc PV)": 19.62222,
    "out:Total Energy Use Pre PV": 92.779849,
    "out:Total Energy Use Post PV": 83.62222,
    "out:Primary Energy": 86.357817,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.354107,
    "out:CO2 Operational/m2": -29.922689,
    "out:Total CO2/m2": -2.568582,
    "out:Total CO2 (tons)": -5.065244,
    "out:Klimatpaverkan": -99.997416,
    "out:Initial Cost/MSEK": 2.688639146700616,
    "out:Running cost/(Apt SEK y)": 12503.705882,
    "out:Running Cost over RSP/MSEK": 3.32955,
    "out:LCP/MSEK": 0.310881,
    "out:ROI% old": 15.937537,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 64.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1370.776735,
    "out:Return %": 14,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 19.040349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21690.355477,
    "out:PV (% sold (El))": 53.972506,
    "out:PV (kWh self-consumed)": 18497.431078,
    "out:PV (ratio sold/self-consumed)": 1.172614,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132540.399012,
    "out:EL kWh savings": -24023.339229,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117029.25,
    "out:DH kr savings": 132540.39901167128,
    "out:El kr savings": -48046.67845754615,
    "out:El kr sold": 32535.533215539697,
    "out:El kr saved": 36994.862156,
    "out:El kr return": 69530.3953715397,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126387.895118,
    "out:% savings (space heating)": 41.422328,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 65.62,
    "out:Etvv": 0,
    "out:Ef": 19.62,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2688639.146700616,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": -52.961665,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.528286,
    "out:Bought CO2": 2.566621,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.613476,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.712329,
    "out:Electricity (inc PV)": 19.573292,
    "out:Total Energy Use Pre PV": 88.712329,
    "out:Total Energy Use Post PV": 79.573292,
    "out:Primary Energy": 82.777748,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.354107,
    "out:CO2 Operational/m2": -31.451211,
    "out:Total CO2/m2": -4.097104,
    "out:Total CO2 (tons)": -8.07949,
    "out:Klimatpaverkan": -103.011662,
    "out:Initial Cost/MSEK": 2.737939146700616,
    "out:Running cost/(Apt SEK y)": 11795.676471,
    "out:Running Cost over RSP/MSEK": 3.14128,
    "out:LCP/MSEK": 0.449851,
    "out:ROI% old": 16.632896,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1367.121414,
    "out:Return %": 15,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.972829,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21721.719614,
    "out:PV (% sold (El))": 54.05055,
    "out:PV (kWh self-consumed)": 18466.066941,
    "out:PV (ratio sold/self-consumed)": 1.176305,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141370.512662,
    "out:EL kWh savings": -23926.853207,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126099.39,
    "out:DH kr savings": 141370.51266213425,
    "out:El kr savings": -47853.70641439055,
    "out:El kr sold": 32582.57942129003,
    "out:El kr saved": 36932.133882,
    "out:El kr return": 69514.71330329003,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 117557.781468,
    "out:% savings (space heating)": 45.514868,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 61.13,
    "out:Etvv": 0,
    "out:Ef": 19.57,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2737939.146700616,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -53.050251,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.610041,
    "out:Bought CO2": 2.55979,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.489314,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.469276,
    "out:Electricity (inc PV)": 19.353792,
    "out:Total Energy Use Pre PV": 90.469276,
    "out:Total Energy Use Post PV": 81.353792,
    "out:Primary Energy": 84.44185,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.02145,
    "out:CO2 Operational/m2": -31.050945,
    "out:Total CO2/m2": -3.029495,
    "out:Total CO2 (tons)": -5.974164,
    "out:Klimatpaverkan": -100.906337,
    "out:Initial Cost/MSEK": 2.8480151467006163,
    "out:Running cost/(Apt SEK y)": 12119.470588,
    "out:Running Cost over RSP/MSEK": 3.22747,
    "out:LCP/MSEK": 0.253585,
    "out:ROI% old": 15.557702,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 70.4,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1353.975657,
    "out:Return %": 14,
    "out:Return/kSEK/y": 397,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.729776,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21834.973316,
    "out:PV (% sold (El))": 54.332361,
    "out:PV (kWh self-consumed)": 18352.813238,
    "out:PV (ratio sold/self-consumed)": 1.189734,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135699.359798,
    "out:EL kWh savings": -23493.999182,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121463.82,
    "out:DH kr savings": 135699.35979828867,
    "out:El kr savings": -46987.99836434457,
    "out:El kr sold": 32752.459974467703,
    "out:El kr saved": 36705.626476,
    "out:El kr return": 69458.0864504677,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 123228.934332,
    "out:% savings (space heating)": 42.886428,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 63.78,
    "out:Etvv": 0,
    "out:Ef": 19.35,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2848015.1467006165,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -53.369953,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.905174,
    "out:Bought CO2": 2.535221,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.027294,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.39699,
    "out:Electricity (inc PV)": 19.301475,
    "out:Total Energy Use Pre PV": 86.39699,
    "out:Total Energy Use Post PV": 77.301475,
    "out:Primary Energy": 80.867383,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.02145,
    "out:CO2 Operational/m2": -32.586206,
    "out:Total CO2/m2": -4.564756,
    "out:Total CO2 (tons)": -9.001699,
    "out:Klimatpaverkan": -103.933872,
    "out:Initial Cost/MSEK": 2.8973151467006164,
    "out:Running cost/(Apt SEK y)": 11410.941176,
    "out:Running Cost over RSP/MSEK": 3.03907,
    "out:LCP/MSEK": 0.392685,
    "out:ROI% old": 16.221915,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 79.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 1350.069733,
    "out:Return %": 15,
    "out:Return/kSEK/y": 422,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.65749,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21868.773893,
    "out:PV (% sold (El))": 54.416468,
    "out:PV (kWh self-consumed)": 18319.012662,
    "out:PV (ratio sold/self-consumed)": 1.193775,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144498.463747,
    "out:EL kWh savings": -23390.830052,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130519.96,
    "out:DH kr savings": 144498.463746959,
    "out:El kr savings": -46781.66010441628,
    "out:El kr sold": 32803.160838960684,
    "out:El kr saved": 36638.025324,
    "out:El kr return": 69441.18616296069,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 114429.830383,
    "out:% savings (space heating)": 46.964596,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 59.3,
    "out:Etvv": 0,
    "out:Ef": 19.3,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2897315.1467006165,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": -53.465278,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.993198,
    "out:Bought CO2": 2.52792,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.977224,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.959242,
    "out:Electricity (inc PV)": 42.497028,
    "out:Total Energy Use Pre PV": 66.959242,
    "out:Total Energy Use Post PV": 67.497028,
    "out:Primary Energy": 96.959953,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.269456,
    "out:CO2 Operational/m2": 14.538556,
    "out:Total CO2/m2": 19.808012,
    "out:Total CO2 (tons)": 39.061402,
    "out:Klimatpaverkan": -55.87077,
    "out:Initial Cost/MSEK": 2.6464927682456163,
    "out:Running cost/(Apt SEK y)": 9246.264706,
    "out:Running Cost over RSP/MSEK": 2.4439,
    "out:LCP/MSEK": 1.238677,
    "out:ROI% old": 20.972069,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 2937.14694,
    "out:Return %": 19,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 32.219742,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 209673.205555,
    "out:EL kWh savings": -69132.463213,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71408.28,
    "out:DH kr savings": 209673.20555466696,
    "out:El kr savings": -138264.9264250024,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 49255.088575,
    "out:% savings (space heating)": 77.171481,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 26.31,
    "out:Etvv": 0,
    "out:Ef": 42.5,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2646492.7682456165,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": 5.538956,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.538956,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.349078,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.634268,
    "out:Electricity (inc PV)": 42.189593,
    "out:Total Energy Use Pre PV": 62.634268,
    "out:Total Energy Use Post PV": 63.189593,
    "out:Primary Energy": 93.53966,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.269456,
    "out:CO2 Operational/m2": 13.05572,
    "out:Total CO2/m2": 18.325176,
    "out:Total CO2 (tons)": 36.137249,
    "out:Klimatpaverkan": -58.794923,
    "out:Initial Cost/MSEK": 2.6957927682456164,
    "out:Running cost/(Apt SEK y)": 8507.941176,
    "out:Running Cost over RSP/MSEK": 2.24766,
    "out:LCP/MSEK": 1.385617,
    "out:ROI% old": 21.628464,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 119,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 2914.39876,
    "out:Return %": 19,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 31.894768,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 216827.90988,
    "out:EL kWh savings": -68526.201357,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79775.51,
    "out:DH kr savings": 216827.9098802756,
    "out:El kr savings": -137052.4027149072,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 42100.38425,
    "out:% savings (space heating)": 80.48751,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 22.63,
    "out:Etvv": 0,
    "out:Ef": 42.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2695792.7682456165,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.559664,
    "out:EL CO2": 5.496056,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.496056,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.598321,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.382092,
    "out:Electricity (inc PV)": 41.847552,
    "out:Total Energy Use Pre PV": 65.382092,
    "out:Total Energy Use Post PV": 65.847552,
    "out:Primary Energy": 94.541219,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.936799,
    "out:CO2 Operational/m2": 14.102383,
    "out:Total CO2/m2": 20.039182,
    "out:Total CO2 (tons)": 39.517269,
    "out:Klimatpaverkan": -55.414903,
    "out:Initial Cost/MSEK": 2.805868768245616,
    "out:Running cost/(Apt SEK y)": 9004.176471,
    "out:Running Cost over RSP/MSEK": 2.37972,
    "out:LCP/MSEK": 1.143481,
    "out:ROI% old": 20.1076,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 109.1,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 2896.74644,
    "out:Return %": 18,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 31.642592,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 212392.402428,
    "out:EL kWh savings": -67851.696466,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 76689.01,
    "out:DH kr savings": 212392.4024278619,
    "out:El kr savings": -135703.39293292188,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 46535.891702,
    "out:% savings (space heating)": 78.431762,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 24.71,
    "out:Etvv": 0,
    "out:Ef": 41.85,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2805868.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": 5.462767,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.462767,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.034046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.037762,
    "out:Electricity (inc PV)": 41.51886,
    "out:Total Energy Use Pre PV": 61.037762,
    "out:Total Energy Use Post PV": 61.51886,
    "out:Primary Energy": 91.13722,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.936799,
    "out:CO2 Operational/m2": 12.616993,
    "out:Total CO2/m2": 18.553792,
    "out:Total CO2 (tons)": 36.58808,
    "out:Klimatpaverkan": -58.344092,
    "out:Initial Cost/MSEK": 2.855168768245616,
    "out:Running cost/(Apt SEK y)": 8263.588235,
    "out:Running Cost over RSP/MSEK": 2.18289,
    "out:LCP/MSEK": 1.291011,
    "out:ROI% old": 20.745234,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2872.64334,
    "out:Return %": 19,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 31.298262,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 219421.153134,
    "out:EL kWh savings": -67203.515805,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85014.12,
    "out:DH kr savings": 219421.15313418926,
    "out:El kr savings": -134407.0316099801,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 39507.140996,
    "out:% savings (space heating)": 81.689415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 21.09,
    "out:Etvv": 0,
    "out:Ef": 41.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2855168.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": 5.417313,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.417313,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.977224,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.959242,
    "out:Electricity (inc PV)": 33.523661,
    "out:Total Energy Use Pre PV": 66.959242,
    "out:Total Energy Use Post PV": 58.523661,
    "out:Primary Energy": 80.807893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.531259,
    "out:CO2 Operational/m2": 7.235791,
    "out:Total CO2/m2": 23.76705,
    "out:Total CO2 (tons)": 46.868625,
    "out:Klimatpaverkan": -48.063547,
    "out:Initial Cost/MSEK": 3.020447636241866,
    "out:Running cost/(Apt SEK y)": 8100.941176,
    "out:Running Cost over RSP/MSEK": 2.14752,
    "out:LCP/MSEK": 1.161102,
    "out:ROI% old": 19.777343,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 133.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 2337.375681,
    "out:Return %": 18,
    "out:Return/kSEK/y": 534,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 32.219742,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2492.612036,
    "out:PV (% sold (El))": 12.404824,
    "out:PV (kWh self-consumed)": 17601.281241,
    "out:PV (ratio sold/self-consumed)": 0.141615,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 209673.205555,
    "out:EL kWh savings": -51436.982466,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110538.16,
    "out:DH kr savings": 209673.20555466696,
    "out:El kr savings": -102873.96493107466,
    "out:El kr sold": 3738.9180539142144,
    "out:El kr saved": 35202.562482,
    "out:El kr return": 38941.48053591422,
    "out:% solar/total": 120,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 49255.088575,
    "out:% savings (space heating)": 77.171481,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 26.31,
    "out:Etvv": 0,
    "out:Ef": 33.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3020447.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -1.763809,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.135643,
    "out:Bought CO2": 4.371834,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.349078,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.634268,
    "out:Electricity (inc PV)": 33.243455,
    "out:Total Energy Use Pre PV": 62.634268,
    "out:Total Energy Use Post PV": 54.243455,
    "out:Primary Energy": 77.436612,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.531259,
    "out:CO2 Operational/m2": 5.60901,
    "out:Total CO2/m2": 22.140269,
    "out:Total CO2 (tons)": 43.660613,
    "out:Klimatpaverkan": -51.271559,
    "out:Initial Cost/MSEK": 3.0697476362418663,
    "out:Running cost/(Apt SEK y)": 7363.441176,
    "out:Running Cost over RSP/MSEK": 1.9515,
    "out:LCP/MSEK": 1.307822,
    "out:ROI% old": 20.37194,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 155.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 2316.418434,
    "out:Return %": 18,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 31.894768,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2550.520402,
    "out:PV (% sold (El))": 12.693013,
    "out:PV (kWh self-consumed)": 17543.372875,
    "out:PV (ratio sold/self-consumed)": 0.145384,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216827.90988,
    "out:EL kWh savings": -50884.416202,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118884.86,
    "out:DH kr savings": 216827.9098802756,
    "out:El kr savings": -101768.83240318776,
    "out:El kr sold": 3825.780603285868,
    "out:El kr saved": 35086.74575,
    "out:El kr return": 38912.526353285866,
    "out:% solar/total": 120,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 42100.38425,
    "out:% savings (space heating)": 80.48751,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 22.63,
    "out:Etvv": 0,
    "out:Ef": 33.24,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3069747.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.559664,
    "out:EL CO2": -1.950654,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.283352,
    "out:Bought CO2": 4.332698,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.598321,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.382092,
    "out:Electricity (inc PV)": 32.931988,
    "out:Total Energy Use Pre PV": 65.382092,
    "out:Total Energy Use Post PV": 56.931988,
    "out:Primary Energy": 78.493203,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.198602,
    "out:CO2 Operational/m2": 6.542463,
    "out:Total CO2/m2": 23.741065,
    "out:Total CO2 (tons)": 46.817383,
    "out:Klimatpaverkan": -48.114789,
    "out:Initial Cost/MSEK": 3.179823636241866,
    "out:Running cost/(Apt SEK y)": 7860.352941,
    "out:Running Cost over RSP/MSEK": 2.08373,
    "out:LCP/MSEK": 1.065516,
    "out:ROI% old": 19.072666,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 142.1,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 2300.176085,
    "out:Return %": 17,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 31.642592,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2596.01108,
    "out:PV (% sold (El))": 12.919403,
    "out:PV (kWh self-consumed)": 17497.882198,
    "out:PV (ratio sold/self-consumed)": 0.148361,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 212392.402428,
    "out:EL kWh savings": -50270.203242,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115746.01,
    "out:DH kr savings": 212392.4024278619,
    "out:El kr savings": -100540.40648417322,
    "out:El kr sold": 3894.0166197356184,
    "out:El kr saved": 34995.764396,
    "out:El kr return": 38889.781015735614,
    "out:% solar/total": 119,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 46535.891702,
    "out:% savings (space heating)": 78.431762,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 24.71,
    "out:Etvv": 0,
    "out:Ef": 32.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3179823.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": -2.097153,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.399521,
    "out:Bought CO2": 4.302368,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.034046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.037762,
    "out:Electricity (inc PV)": 32.633164,
    "out:Total Energy Use Pre PV": 61.037762,
    "out:Total Energy Use Post PV": 52.633164,
    "out:Primary Energy": 75.142967,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.198602,
    "out:CO2 Operational/m2": 4.900442,
    "out:Total CO2/m2": 22.099044,
    "out:Total CO2 (tons)": 43.579317,
    "out:Klimatpaverkan": -51.352855,
    "out:Initial Cost/MSEK": 3.2291236362418663,
    "out:Running cost/(Apt SEK y)": 7120.676471,
    "out:Running Cost over RSP/MSEK": 1.88714,
    "out:LCP/MSEK": 1.212806,
    "out:ROI% old": 19.651196,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 160.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2278.027004,
    "out:Return %": 18,
    "out:Return/kSEK/y": 567,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 31.298262,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2658.945867,
    "out:PV (% sold (El))": 13.232607,
    "out:PV (kWh self-consumed)": 17434.947411,
    "out:PV (ratio sold/self-consumed)": 0.152507,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219421.153134,
    "out:EL kWh savings": -49680.92228,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124047.73,
    "out:DH kr savings": 219421.15313418926,
    "out:El kr savings": -99361.84456004141,
    "out:El kr sold": 3988.4188001714215,
    "out:El kr saved": 34869.894822,
    "out:El kr return": 38858.31362217142,
    "out:% solar/total": 119,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 39507.140996,
    "out:% savings (space heating)": 81.689415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 21.09,
    "out:Etvv": 0,
    "out:Ef": 32.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3229123.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -2.299238,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.560246,
    "out:Bought CO2": 4.261008,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.977224,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.959242,
    "out:Electricity (inc PV)": 30.263394,
    "out:Total Energy Use Pre PV": 66.959242,
    "out:Total Energy Use Post PV": 55.263394,
    "out:Primary Energy": 74.939412,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.793062,
    "out:CO2 Operational/m2": -28.450415,
    "out:Total CO2/m2": -0.657353,
    "out:Total CO2 (tons)": -1.2963,
    "out:Klimatpaverkan": -96.228472,
    "out:Initial Cost/MSEK": 3.394402521700616,
    "out:Running cost/(Apt SEK y)": 7121.441176,
    "out:Running Cost over RSP/MSEK": 1.89393,
    "out:LCP/MSEK": 1.040737,
    "out:ROI% old": 18.665772,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 150.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 2108.504777,
    "out:Return %": 17,
    "out:Return/kSEK/y": 567,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 32.219742,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16261.633565,
    "out:PV (% sold (El))": 40.464118,
    "out:PV (kWh self-consumed)": 23926.152989,
    "out:PV (ratio sold/self-consumed)": 0.679659,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 209673.205555,
    "out:EL kWh savings": -45007.73557,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144050.18,
    "out:DH kr savings": 209673.20555466696,
    "out:El kr savings": -90015.47113973384,
    "out:El kr sold": 24392.450347963055,
    "out:El kr saved": 47852.305978,
    "out:El kr return": 72244.75632596306,
    "out:% solar/total": 163,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 49255.088575,
    "out:% savings (space heating)": 77.171481,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 26.31,
    "out:Etvv": 0,
    "out:Ef": 30.26,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3394402.521700616,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -37.450015,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -41.396538,
    "out:Bought CO2": 3.946523,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.349078,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.634268,
    "out:Electricity (inc PV)": 30.013466,
    "out:Total Energy Use Pre PV": 62.634268,
    "out:Total Energy Use Post PV": 51.013466,
    "out:Primary Energy": 71.622632,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.793062,
    "out:CO2 Operational/m2": -30.238966,
    "out:Total CO2/m2": -2.445904,
    "out:Total CO2 (tons)": -4.823323,
    "out:Klimatpaverkan": -99.755495,
    "out:Initial Cost/MSEK": 3.443702521700616,
    "out:Running cost/(Apt SEK y)": 6384.852941,
    "out:Running Cost over RSP/MSEK": 1.69816,
    "out:LCP/MSEK": 1.187207,
    "out:ROI% old": 19.210677,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 170.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 2089.83351,
    "out:Return %": 17,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 31.894768,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16382.153567,
    "out:PV (% sold (El))": 40.764011,
    "out:PV (kWh self-consumed)": 23805.632988,
    "out:PV (ratio sold/self-consumed)": 0.688163,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216827.90988,
    "out:EL kWh savings": -44514.877525,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152371.39,
    "out:DH kr savings": 216827.9098802756,
    "out:El kr savings": -89029.75505075025,
    "out:El kr sold": 24573.230349848905,
    "out:El kr saved": 47611.265976,
    "out:El kr return": 72184.49632584892,
    "out:% solar/total": 162,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 42100.38425,
    "out:% savings (space heating)": 80.48751,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 22.63,
    "out:Etvv": 0,
    "out:Ef": 30.01,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3443702.521700616,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.559664,
    "out:EL CO2": -37.79863,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -41.710243,
    "out:Bought CO2": 3.911613,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.598321,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.382092,
    "out:Electricity (inc PV)": 29.735598,
    "out:Total Energy Use Pre PV": 65.382092,
    "out:Total Energy Use Post PV": 53.735598,
    "out:Primary Energy": 72.739701,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.460405,
    "out:CO2 Operational/m2": -29.430687,
    "out:Total CO2/m2": -0.970282,
    "out:Total CO2 (tons)": -1.913396,
    "out:Klimatpaverkan": -96.845568,
    "out:Initial Cost/MSEK": 3.5537785217006155,
    "out:Running cost/(Apt SEK y)": 6882.470588,
    "out:Running Cost over RSP/MSEK": 1.83057,
    "out:LCP/MSEK": 0.944721,
    "out:ROI% old": 18.083368,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 155.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 2075.36076,
    "out:Return %": 16,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 31.642592,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16476.177126,
    "out:PV (% sold (El))": 40.997971,
    "out:PV (kWh self-consumed)": 23711.609429,
    "out:PV (ratio sold/self-consumed)": 0.694857,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 212392.402428,
    "out:EL kWh savings": -43966.921798,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149172.82,
    "out:DH kr savings": 212392.4024278619,
    "out:El kr savings": -87933.84359539632,
    "out:El kr sold": 24714.265688443484,
    "out:El kr saved": 47423.218858,
    "out:El kr return": 72137.48454644348,
    "out:% solar/total": 162,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 46535.891702,
    "out:% savings (space heating)": 78.431762,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 24.71,
    "out:Etvv": 0,
    "out:Ef": 29.74,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3553778.5217006155,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": -38.070303,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -41.954856,
    "out:Bought CO2": 3.884553,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.034046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.037762,
    "out:Electricity (inc PV)": 29.468844,
    "out:Total Energy Use Pre PV": 61.037762,
    "out:Total Energy Use Post PV": 49.468844,
    "out:Primary Energy": 69.447191,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.460405,
    "out:CO2 Operational/m2": -31.242988,
    "out:Total CO2/m2": -2.782583,
    "out:Total CO2 (tons)": -5.487254,
    "out:Klimatpaverkan": -100.419426,
    "out:Initial Cost/MSEK": 3.6030785217006156,
    "out:Running cost/(Apt SEK y)": 6143.794118,
    "out:Running Cost over RSP/MSEK": 1.63424,
    "out:LCP/MSEK": 1.091751,
    "out:ROI% old": 18.61436,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 181.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2055.621129,
    "out:Return %": 17,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 31.298262,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16604.81792,
    "out:PV (% sold (El))": 41.31807,
    "out:PV (kWh self-consumed)": 23582.968635,
    "out:PV (ratio sold/self-consumed)": 0.704102,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219421.153134,
    "out:EL kWh savings": -43440.882879,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157446.61,
    "out:DH kr savings": 219421.15313418926,
    "out:El kr savings": -86881.76575857644,
    "out:El kr sold": 24907.22688027895,
    "out:El kr saved": 47165.93727,
    "out:El kr return": 72073.16415027896,
    "out:% solar/total": 161,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 39507.140996,
    "out:% savings (space heating)": 81.689415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 21.09,
    "out:Etvv": 0,
    "out:Ef": 29.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3603078.5217006155,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -38.442668,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -42.290309,
    "out:Bought CO2": 3.847641,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.146251,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.187416,
    "out:Electricity (inc PV)": 41.628269,
    "out:Total Energy Use Pre PV": 64.187416,
    "out:Total Energy Use Post PV": 64.628269,
    "out:Primary Energy": 93.737111,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.269456,
    "out:CO2 Operational/m2": 13.7167,
    "out:Total CO2/m2": 18.986156,
    "out:Total CO2 (tons)": 37.440702,
    "out:Klimatpaverkan": -57.49147,
    "out:Initial Cost/MSEK": 2.736229393245616,
    "out:Running cost/(Apt SEK y)": 8806.411765,
    "out:Running Cost over RSP/MSEK": 2.3272,
    "out:LCP/MSEK": 1.265641,
    "out:ROI% old": 20.893559,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 112.3,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 2883.11912,
    "out:Return %": 19,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 31.447916,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213283.884519,
    "out:EL kWh savings": -67419.270365,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78445.34,
    "out:DH kr savings": 213283.88451939786,
    "out:El kr savings": -134838.54073092534,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 45644.409611,
    "out:% savings (space heating)": 78.844942,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 24.18,
    "out:Etvv": 0,
    "out:Ef": 41.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2736229.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": 5.437068,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.437068,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.60319,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.836658,
    "out:Electricity (inc PV)": 41.292298,
    "out:Total Energy Use Pre PV": 60.836658,
    "out:Total Energy Use Post PV": 61.292298,
    "out:Primary Energy": 90.338696,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.269456,
    "out:CO2 Operational/m2": 12.590446,
    "out:Total CO2/m2": 17.859902,
    "out:Total CO2 (tons)": 35.219729,
    "out:Klimatpaverkan": -59.712443,
    "out:Initial Cost/MSEK": 2.785529393245616,
    "out:Running cost/(Apt SEK y)": 8240.264706,
    "out:Running Cost over RSP/MSEK": 2.17676,
    "out:LCP/MSEK": 1.366781,
    "out:ROI% old": 21.295311,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 126.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2858.56606,
    "out:Return %": 19,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 31.097158,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 220270.801215,
    "out:EL kWh savings": -66756.735515,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 86757.33,
    "out:DH kr savings": 220270.80121530683,
    "out:El kr savings": -133513.47103032397,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 38657.492915,
    "out:% savings (space heating)": 82.083205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 20.59,
    "out:Etvv": 0,
    "out:Ef": 41.29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2785529.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": 5.390766,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.390766,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.075136,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.715996,
    "out:Electricity (inc PV)": 41.096016,
    "out:Total Energy Use Pre PV": 62.715996,
    "out:Total Energy Use Post PV": 63.096016,
    "out:Primary Energy": 91.811838,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.936799,
    "out:CO2 Operational/m2": 13.294485,
    "out:Total CO2/m2": 19.231284,
    "out:Total CO2 (tons)": 37.924094,
    "out:Klimatpaverkan": -57.008078,
    "out:Initial Cost/MSEK": 2.895605393245616,
    "out:Running cost/(Apt SEK y)": 8576.588235,
    "out:Running Cost over RSP/MSEK": 2.26624,
    "out:LCP/MSEK": 1.167225,
    "out:ROI% old": 20.044316,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 119,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 2850.11972,
    "out:Return %": 18,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 30.976496,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 215396.123422,
    "out:EL kWh savings": -66369.667389,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82656.79,
    "out:DH kr savings": 215396.12342150498,
    "out:El kr savings": -132739.33477757164,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 43532.170709,
    "out:% savings (space heating)": 79.823913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 22.94,
    "out:Etvv": 0,
    "out:Ef": 41.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2895605.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": 5.374837,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.374837,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.583418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.34968,
    "out:Electricity (inc PV)": 40.742235,
    "out:Total Energy Use Pre PV": 59.34968,
    "out:Total Energy Use Post PV": 59.742235,
    "out:Primary Energy": 88.427077,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.936799,
    "out:CO2 Operational/m2": 12.166177,
    "out:Total CO2/m2": 18.102976,
    "out:Total CO2 (tons)": 35.699071,
    "out:Klimatpaverkan": -59.233102,
    "out:Initial Cost/MSEK": 2.944905393245616,
    "out:Running cost/(Apt SEK y)": 8008.617647,
    "out:Running Cost over RSP/MSEK": 2.11533,
    "out:LCP/MSEK": 1.268835,
    "out:ROI% old": 20.440822,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 2824.4776,
    "out:Return %": 18,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 30.61018,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222281.791716,
    "out:EL kWh savings": -65672.011216,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 90937.77,
    "out:DH kr savings": 222281.79171556086,
    "out:El kr savings": -131344.02243290958,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 36646.502415,
    "out:% savings (space heating)": 83.01525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 19.4,
    "out:Etvv": 0,
    "out:Ef": 40.74,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2944905.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.839696,
    "out:EL CO2": 5.326481,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.326481,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.146251,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.187416,
    "out:Electricity (inc PV)": 32.732582,
    "out:Total Energy Use Pre PV": 64.187416,
    "out:Total Energy Use Post PV": 55.732582,
    "out:Primary Energy": 77.724875,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.531259,
    "out:CO2 Operational/m2": 6.068525,
    "out:Total CO2/m2": 22.599784,
    "out:Total CO2 (tons)": 44.566777,
    "out:Klimatpaverkan": -50.365396,
    "out:Initial Cost/MSEK": 3.110184261241866,
    "out:Running cost/(Apt SEK y)": 7663.117647,
    "out:Running Cost over RSP/MSEK": 2.03135,
    "out:LCP/MSEK": 1.187536,
    "out:ROI% old": 19.74031,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 146.4,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 2287.649418,
    "out:Return %": 18,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 31.447916,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2631.424525,
    "out:PV (% sold (El))": 13.095643,
    "out:PV (kWh self-consumed)": 17462.468752,
    "out:PV (ratio sold/self-consumed)": 0.15069,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213283.884519,
    "out:EL kWh savings": -49876.974587,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117477.07,
    "out:DH kr savings": 213283.88451939786,
    "out:El kr savings": -99753.94917470866,
    "out:El kr sold": 3947.136787778165,
    "out:El kr saved": 34924.937504,
    "out:El kr return": 38872.07429177817,
    "out:% solar/total": 119,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 45644.409611,
    "out:% savings (space heating)": 78.844942,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 24.18,
    "out:Etvv": 0,
    "out:Ef": 32.73,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3110184.2612418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": -2.211107,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.490083,
    "out:Bought CO2": 4.278976,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.60319,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.836658,
    "out:Electricity (inc PV)": 32.427398,
    "out:Total Energy Use Pre PV": 60.836658,
    "out:Total Energy Use Post PV": 52.427398,
    "out:Primary Energy": 74.381876,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 16.531259,
    "out:CO2 Operational/m2": 4.781298,
    "out:Total CO2/m2": 21.312557,
    "out:Total CO2 (tons)": 42.028365,
    "out:Klimatpaverkan": -52.903807,
    "out:Initial Cost/MSEK": 3.1594842612418663,
    "out:Running cost/(Apt SEK y)": 7097.911765,
    "out:Running Cost over RSP/MSEK": 1.88115,
    "out:LCP/MSEK": 1.288436,
    "out:ROI% old": 20.11142,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 165.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2265.106687,
    "out:Return %": 18,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 31.097158,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2696.207577,
    "out:PV (% sold (El))": 13.418045,
    "out:PV (kWh self-consumed)": 17397.6857,
    "out:PV (ratio sold/self-consumed)": 0.154975,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 220270.801215,
    "out:EL kWh savings": -49275.151705,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125764.81,
    "out:DH kr savings": 220270.80121530683,
    "out:El kr savings": -98550.30340912672,
    "out:El kr sold": 4044.311365404942,
    "out:El kr saved": 34795.3714,
    "out:El kr return": 38839.68276540495,
    "out:% solar/total": 119,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 38657.492915,
    "out:% savings (space heating)": 82.083205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 20.59,
    "out:Etvv": 0,
    "out:Ef": 32.43,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3159484.2612418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -2.418382,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.655263,
    "out:Bought CO2": 4.236881,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.075136,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.715996,
    "out:Electricity (inc PV)": 32.249309,
    "out:Total Energy Use Pre PV": 62.715996,
    "out:Total Energy Use Post PV": 54.249309,
    "out:Primary Energy": 75.887765,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.198602,
    "out:CO2 Operational/m2": 5.429379,
    "out:Total CO2/m2": 22.627981,
    "out:Total CO2 (tons)": 44.622381,
    "out:Klimatpaverkan": -50.309791,
    "out:Initial Cost/MSEK": 3.2695602612418657,
    "out:Running cost/(Apt SEK y)": 7434.558824,
    "out:Running Cost over RSP/MSEK": 1.97072,
    "out:LCP/MSEK": 1.08879,
    "out:ROI% old": 19.042971,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 155.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 2257.360283,
    "out:Return %": 17,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 30.976496,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2718.76576,
    "out:PV (% sold (El))": 13.530309,
    "out:PV (kWh self-consumed)": 17375.127518,
    "out:PV (ratio sold/self-consumed)": 0.156475,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 215396.123422,
    "out:EL kWh savings": -48923.960176,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121626.35,
    "out:DH kr savings": 215396.12342150498,
    "out:El kr savings": -97847.92035252246,
    "out:El kr sold": 4078.148639727789,
    "out:El kr saved": 34750.255036,
    "out:El kr return": 38828.40367572779,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 43532.170709,
    "out:% savings (space heating)": 79.823913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 22.94,
    "out:Etvv": 0,
    "out:Ef": 32.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3269560.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": -2.490269,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.712684,
    "out:Bought CO2": 4.222415,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.583418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.34968,
    "out:Electricity (inc PV)": 31.928828,
    "out:Total Energy Use Pre PV": 59.34968,
    "out:Total Energy Use Post PV": 50.928828,
    "out:Primary Energy": 72.562944,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.198602,
    "out:CO2 Operational/m2": 4.129257,
    "out:Total CO2/m2": 21.327859,
    "out:Total CO2 (tons)": 42.05854,
    "out:Klimatpaverkan": -52.873632,
    "out:Initial Cost/MSEK": 3.318860261241866,
    "out:Running cost/(Apt SEK y)": 6867.617647,
    "out:Running Cost over RSP/MSEK": 1.82006,
    "out:LCP/MSEK": 1.19015,
    "out:ROI% old": 19.408599,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 170.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 2233.869452,
    "out:Return %": 17,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 30.61018,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2788.034531,
    "out:PV (% sold (El))": 13.875034,
    "out:PV (kWh self-consumed)": 17305.858746,
    "out:PV (ratio sold/self-consumed)": 0.161104,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222281.791716,
    "out:EL kWh savings": -48291.971608,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129879.9,
    "out:DH kr savings": 222281.79171556086,
    "out:El kr savings": -96583.94321545273,
    "out:El kr sold": 4182.05179706272,
    "out:El kr saved": 34611.717492,
    "out:El kr return": 38793.76928906272,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 36646.502415,
    "out:% savings (space heating)": 83.01525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 19.4,
    "out:Etvv": 0,
    "out:Ef": 31.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3318860.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.839696,
    "out:EL CO2": -2.710439,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.888989,
    "out:Bought CO2": 4.17855,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.146251,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.187416,
    "out:Electricity (inc PV)": 29.55758,
    "out:Total Energy Use Pre PV": 64.187416,
    "out:Total Energy Use Post PV": 52.55758,
    "out:Primary Energy": 72.009871,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.793062,
    "out:CO2 Operational/m2": -30.001015,
    "out:Total CO2/m2": -2.207953,
    "out:Total CO2 (tons)": -4.354084,
    "out:Klimatpaverkan": -99.286256,
    "out:Initial Cost/MSEK": 3.4841391467006155,
    "out:Running cost/(Apt SEK y)": 6685.794118,
    "out:Running Cost over RSP/MSEK": 1.77833,
    "out:LCP/MSEK": 1.066601,
    "out:ROI% old": 18.659005,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 160.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 2064.197344,
    "out:Return %": 17,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 31.447916,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16548.860648,
    "out:PV (% sold (El))": 41.178831,
    "out:PV (kWh self-consumed)": 23638.925906,
    "out:PV (ratio sold/self-consumed)": 0.700068,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213283.884519,
    "out:EL kWh savings": -43615.870281,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150875.43,
    "out:DH kr savings": 213283.88451939786,
    "out:El kr savings": -87231.74056280823,
    "out:El kr sold": 24823.290972642164,
    "out:El kr saved": 47277.851812,
    "out:El kr return": 72101.14278464217,
    "out:% solar/total": 161,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 45644.409611,
    "out:% savings (space heating)": 78.844942,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 24.18,
    "out:Etvv": 0,
    "out:Ef": 29.56,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3484139.1467006155,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": -38.280647,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -42.144326,
    "out:Bought CO2": 3.863679,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.60319,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.836658,
    "out:Electricity (inc PV)": 29.285151,
    "out:Total Energy Use Pre PV": 60.836658,
    "out:Total Energy Use Post PV": 49.285151,
    "out:Primary Energy": 68.725832,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.793062,
    "out:CO2 Operational/m2": -31.461144,
    "out:Total CO2/m2": -3.668082,
    "out:Total CO2 (tons)": -7.233458,
    "out:Klimatpaverkan": -102.16563,
    "out:Initial Cost/MSEK": 3.5334391467006157,
    "out:Running cost/(Apt SEK y)": 6121.558824,
    "out:Running Cost over RSP/MSEK": 1.6284,
    "out:LCP/MSEK": 1.167231,
    "out:ROI% old": 19.004835,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 181.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2044.1041,
    "out:Return %": 17,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 31.097158,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16680.18324,
    "out:PV (% sold (El))": 41.505603,
    "out:PV (kWh self-consumed)": 23507.603315,
    "out:PV (ratio sold/self-consumed)": 0.709565,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 220270.801215,
    "out:EL kWh savings": -43078.640262,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159133.8,
    "out:DH kr savings": 220270.80121530683,
    "out:El kr savings": -86157.28052469442,
    "out:El kr sold": 25020.274860334837,
    "out:El kr saved": 47015.20663,
    "out:El kr return": 72035.48149033485,
    "out:% solar/total": 160,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 38657.492915,
    "out:% savings (space heating)": 82.083205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 20.59,
    "out:Etvv": 0,
    "out:Ef": 29.29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3533439.1467006155,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -38.660824,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -42.486925,
    "out:Bought CO2": 3.826101,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.075136,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.715996,
    "out:Electricity (inc PV)": 29.126055,
    "out:Total Energy Use Pre PV": 62.715996,
    "out:Total Energy Use Post PV": 51.126055,
    "out:Primary Energy": 70.265908,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.460405,
    "out:CO2 Operational/m2": -30.872275,
    "out:Total CO2/m2": -2.41187,
    "out:Total CO2 (tons)": -4.756208,
    "out:Klimatpaverkan": -99.68838,
    "out:Initial Cost/MSEK": 3.643515146700615,
    "out:Running cost/(Apt SEK y)": 6458.558824,
    "out:Running Cost over RSP/MSEK": 1.71806,
    "out:LCP/MSEK": 0.967495,
    "out:ROI% old": 18.079127,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 170.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 2037.198057,
    "out:Return %": 16,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 30.976496,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16725.454878,
    "out:PV (% sold (El))": 41.618253,
    "out:PV (kWh self-consumed)": 23462.331676,
    "out:PV (ratio sold/self-consumed)": 0.712864,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 215396.123422,
    "out:EL kWh savings": -42764.902932,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154954.5,
    "out:DH kr savings": 215396.12342150498,
    "out:El kr savings": -85529.80586442053,
    "out:El kr sold": 25088.182317720537,
    "out:El kr saved": 46924.663352,
    "out:El kr return": 72012.84566972054,
    "out:% solar/total": 160,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 43532.170709,
    "out:% savings (space heating)": 79.823913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 22.94,
    "out:Etvv": 0,
    "out:Ef": 29.13,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3643515.146700615,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": -38.791923,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -42.605105,
    "out:Bought CO2": 3.813182,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.583418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.34968,
    "out:Electricity (inc PV)": 28.839505,
    "out:Total Energy Use Pre PV": 59.34968,
    "out:Total Energy Use Post PV": 47.839505,
    "out:Primary Energy": 67.002163,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.460405,
    "out:CO2 Operational/m2": -32.351167,
    "out:Total CO2/m2": -3.890762,
    "out:Total CO2 (tons)": -7.672583,
    "out:Klimatpaverkan": -102.604755,
    "out:Initial Cost/MSEK": 3.692815146700615,
    "out:Running cost/(Apt SEK y)": 5892.617647,
    "out:Running Cost over RSP/MSEK": 1.56767,
    "out:LCP/MSEK": 1.068585,
    "out:ROI% old": 18.419552,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 187.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 2016.250468,
    "out:Return %": 16,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 30.61018,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16863.255904,
    "out:PV (% sold (El))": 41.961146,
    "out:PV (kWh self-consumed)": 23324.530651,
    "out:PV (ratio sold/self-consumed)": 0.722984,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222281.791716,
    "out:EL kWh savings": -42199.8263,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163177.02,
    "out:DH kr savings": 222281.79171556086,
    "out:El kr savings": -84399.65259908717,
    "out:El kr sold": 25294.883855600063,
    "out:El kr saved": 46649.061302,
    "out:El kr return": 71943.94515760007,
    "out:% solar/total": 159,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 36646.502415,
    "out:% savings (space heating)": 83.01525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 19.4,
    "out:Etvv": 0,
    "out:Ef": 28.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3692815.146700615,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.839696,
    "out:EL CO2": -39.190863,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -42.96485,
    "out:Bought CO2": 3.773987,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 126.44,
    "out:Total Energy Use Post PV": 126.44,
    "out:Primary Energy": 106.696993,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.821223,
    "out:CO2 Operational/m2": 43.820236,
    "out:Total CO2/m2": 45.641459,
    "out:Total CO2 (tons)": 90.004962,
    "out:Klimatpaverkan": -4.92721,
    "out:Initial Cost/MSEK": 0.12171999965375,
    "out:Running cost/(Apt SEK y)": 21707.088235,
    "out:Running Cost over RSP/MSEK": 5.77008,
    "out:LCP/MSEK": 0.43727,
    "out:ROI% old": 65.606075,
    "out:Payback discounted": 2,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 9.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 59,
    "out:Return/kSEK/y": 71,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 23563.055668,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 23563.06,
    "out:DH kr savings": 23563.055668164383,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 121719.99965375,
    "out:Seasonal Variation ROI (%)": 18,
    "out:Seasonal Variation Payback": 5.555556,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.838096,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 122.44,
    "out:Total Energy Use Post PV": 122.44,
    "out:Primary Energy": 103.463433,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.821223,
    "out:CO2 Operational/m2": 42.3803,
    "out:Total CO2/m2": 44.201523,
    "out:Total CO2 (tons)": 87.165408,
    "out:Klimatpaverkan": -7.766764,
    "out:Initial Cost/MSEK": 0.17101999965374998,
    "out:Running cost/(Apt SEK y)": 21006.441176,
    "out:Running Cost over RSP/MSEK": 5.58376,
    "out:LCP/MSEK": 0.57429,
    "out:ROI% old": 62.257547,
    "out:Payback discounted": 2,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 13.1,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 56,
    "out:Return/kSEK/y": 95,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 31761.517272,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 31761.52,
    "out:DH kr savings": 31761.51727191196,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 171019.99965374998,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 123.44,
    "out:Total Energy Use Post PV": 123.44,
    "out:Primary Energy": 104.071993,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.488566,
    "out:CO2 Operational/m2": 42.740284,
    "out:Total CO2/m2": 45.22885,
    "out:Total CO2 (tons)": 89.191297,
    "out:Klimatpaverkan": -5.740875,
    "out:Initial Cost/MSEK": 0.28109599965375,
    "out:Running cost/(Apt SEK y)": 21181.588235,
    "out:Running Cost over RSP/MSEK": 5.63034,
    "out:LCP/MSEK": 0.417634,
    "out:ROI% old": 35.510492,
    "out:Payback discounted": 4,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 12.2,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 32,
    "out:Return/kSEK/y": 89,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 30038.118042,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 30038.12,
    "out:DH kr savings": 30038.11804248339,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 281095.99965375,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.758144,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 119.44,
    "out:Total Energy Use Post PV": 119.44,
    "out:Primary Energy": 100.838433,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.488566,
    "out:CO2 Operational/m2": 41.300348,
    "out:Total CO2/m2": 43.788914,
    "out:Total CO2 (tons)": 86.351743,
    "out:Klimatpaverkan": -8.580429,
    "out:Initial Cost/MSEK": 0.33039599965375005,
    "out:Running cost/(Apt SEK y)": 20480.970588,
    "out:Running Cost over RSP/MSEK": 5.44401,
    "out:LCP/MSEK": 0.554664,
    "out:ROI% old": 38.268364,
    "out:Payback discounted": 3,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 34,
    "out:Return/kSEK/y": 113,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 38236.579646,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 38236.58,
    "out:DH kr savings": 38236.579646230966,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 330395.99965375004,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.318208,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 126.44,
    "out:Total Energy Use Post PV": 123.635375,
    "out:Primary Energy": 101.648668,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.083026,
    "out:CO2 Operational/m2": 5.982327,
    "out:Total CO2/m2": 19.065353,
    "out:Total CO2 (tons)": 37.596878,
    "out:Klimatpaverkan": -57.335294,
    "out:Initial Cost/MSEK": 0.49567486765,
    "out:Running cost/(Apt SEK y)": 20739.264706,
    "out:Running Cost over RSP/MSEK": 5.51975,
    "out:LCP/MSEK": 0.313645,
    "out:ROI% old": 23.325198,
    "out:Payback discounted": 5,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 21,
    "out:Return/kSEK/y": 104,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 23563.055668,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56469.26,
    "out:DH kr savings": 23563.055668164383,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 495674.86765000003,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.838096,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 122.44,
    "out:Total Energy Use Post PV": 119.635375,
    "out:Primary Energy": 98.415108,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.083026,
    "out:CO2 Operational/m2": 4.542391,
    "out:Total CO2/m2": 17.625417,
    "out:Total CO2 (tons)": 34.757324,
    "out:Klimatpaverkan": -60.174848,
    "out:Initial Cost/MSEK": 0.54497486765,
    "out:Running cost/(Apt SEK y)": 20038.617647,
    "out:Running Cost over RSP/MSEK": 5.33342,
    "out:LCP/MSEK": 0.450675,
    "out:ROI% old": 26.0995,
    "out:Payback discounted": 5,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 15,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 24,
    "out:Return/kSEK/y": 128,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 31761.517272,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 64667.72,
    "out:DH kr savings": 31761.51727191196,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 544974.86765,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 123.44,
    "out:Total Energy Use Post PV": 120.635375,
    "out:Primary Energy": 99.023668,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.750369,
    "out:CO2 Operational/m2": 4.902375,
    "out:Total CO2/m2": 18.652744,
    "out:Total CO2 (tons)": 36.783213,
    "out:Klimatpaverkan": -58.148959,
    "out:Initial Cost/MSEK": 0.65505086765,
    "out:Running cost/(Apt SEK y)": 20213.764706,
    "out:Running Cost over RSP/MSEK": 5.38001,
    "out:LCP/MSEK": 0.294009,
    "out:ROI% old": 20.697629,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 19,
    "out:Return/kSEK/y": 122,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 30038.118042,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62944.32,
    "out:DH kr savings": 30038.11804248339,
    "out:El kr savings": 11061.44163945446,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 655050.86765,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.758144,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 119.44,
    "out:Total Energy Use Post PV": 116.635375,
    "out:Primary Energy": 95.790108,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.750369,
    "out:CO2 Operational/m2": 3.462439,
    "out:Total CO2/m2": 17.212808,
    "out:Total CO2 (tons)": 33.943659,
    "out:Klimatpaverkan": -60.988513,
    "out:Initial Cost/MSEK": 0.70435086765,
    "out:Running cost/(Apt SEK y)": 19513.117647,
    "out:Running Cost over RSP/MSEK": 5.19368,
    "out:LCP/MSEK": 0.431039,
    "out:ROI% old": 23.028093,
    "out:Payback discounted": 5,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 21,
    "out:Return/kSEK/y": 146,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 38236.579646,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71142.78,
    "out:DH kr savings": 38236.579646230966,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 704350.86765,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.318208,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 126.44,
    "out:Total Energy Use Post PV": 123.377795,
    "out:Primary Energy": 101.185024,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.344829,
    "out:CO2 Operational/m2": -44.666198,
    "out:Total CO2/m2": -20.321369,
    "out:Total CO2 (tons)": -40.073742,
    "out:Klimatpaverkan": -135.005914,
    "out:Initial Cost/MSEK": 0.86962975310875,
    "out:Running cost/(Apt SEK y)": 19845.294118,
    "out:Running Cost over RSP/MSEK": 5.28874,
    "out:LCP/MSEK": 0.1707,
    "out:ROI% old": 17.089867,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 12.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 15,
    "out:Return/kSEK/y": 135,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 23563.055668,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86864.07,
    "out:DH kr savings": 23563.055668164383,
    "out:El kr savings": 12077.337218182662,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 869629.75310875,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.838096,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 122.44,
    "out:Total Energy Use Post PV": 119.377795,
    "out:Primary Energy": 97.951464,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.344829,
    "out:CO2 Operational/m2": -46.106134,
    "out:Total CO2/m2": -21.761305,
    "out:Total CO2 (tons)": -42.913296,
    "out:Klimatpaverkan": -137.845468,
    "out:Initial Cost/MSEK": 0.91892975310875,
    "out:Running cost/(Apt SEK y)": 19144.647059,
    "out:Running Cost over RSP/MSEK": 5.10241,
    "out:LCP/MSEK": 0.30773,
    "out:ROI% old": 19.0697,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 17,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 31761.517272,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 95062.53,
    "out:DH kr savings": 31761.51727191196,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 918929.75310875,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 123.44,
    "out:Total Energy Use Post PV": 120.377795,
    "out:Primary Energy": 98.560024,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.012172,
    "out:CO2 Operational/m2": -45.74615,
    "out:Total CO2/m2": -20.733978,
    "out:Total CO2 (tons)": -40.887407,
    "out:Klimatpaverkan": -135.819579,
    "out:Initial Cost/MSEK": 1.0290057531087502,
    "out:Running cost/(Apt SEK y)": 19319.794118,
    "out:Running Cost over RSP/MSEK": 5.14899,
    "out:LCP/MSEK": 0.151074,
    "out:ROI% old": 16.383082,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 15,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 15,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 30038.118042,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93339.13,
    "out:DH kr savings": 30038.11804248339,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1029005.7531087502,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.758144,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 119.44,
    "out:Total Energy Use Post PV": 116.377795,
    "out:Primary Energy": 95.326464,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.012172,
    "out:CO2 Operational/m2": -47.186086,
    "out:Total CO2/m2": -22.173914,
    "out:Total CO2 (tons)": -43.726961,
    "out:Klimatpaverkan": -138.659133,
    "out:Initial Cost/MSEK": 1.07830575310875,
    "out:Running cost/(Apt SEK y)": 18619.176471,
    "out:Running Cost over RSP/MSEK": 4.96267,
    "out:LCP/MSEK": 0.288094,
    "out:ROI% old": 18.102472,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 16,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 38236.579646,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101537.59,
    "out:DH kr savings": 38236.579646230966,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1078305.75310875,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.318208,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 122.44,
    "out:Total Energy Use Post PV": 122.44,
    "out:Primary Energy": 103.196993,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.821223,
    "out:CO2 Operational/m2": 42.3803,
    "out:Total CO2/m2": 44.201523,
    "out:Total CO2 (tons)": 87.165408,
    "out:Klimatpaverkan": -7.766764,
    "out:Initial Cost/MSEK": 0.21145662465375,
    "out:Running cost/(Apt SEK y)": 21006.441176,
    "out:Running Cost over RSP/MSEK": 5.58376,
    "out:LCP/MSEK": 0.533853,
    "out:ROI% old": 50.352103,
    "out:Payback discounted": 3,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 13.1,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 45,
    "out:Return/kSEK/y": 95,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 32196.472167,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 32196.47,
    "out:DH kr savings": 32196.472167256383,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 211456.62465375,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 118.44,
    "out:Total Energy Use Post PV": 118.44,
    "out:Primary Energy": 99.963433,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 1.821223,
    "out:CO2 Operational/m2": 40.940364,
    "out:Total CO2/m2": 42.761587,
    "out:Total CO2 (tons)": 84.325854,
    "out:Klimatpaverkan": -10.606318,
    "out:Initial Cost/MSEK": 0.26075662465375,
    "out:Running cost/(Apt SEK y)": 20305.794118,
    "out:Running Cost over RSP/MSEK": 5.39743,
    "out:LCP/MSEK": 0.670883,
    "out:ROI% old": 51.040478,
    "out:Payback discounted": 3,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 46,
    "out:Return/kSEK/y": 119,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 40394.933771,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 40394.93,
    "out:DH kr savings": 40394.93377100396,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 260756.62465374998,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 119.44,
    "out:Total Energy Use Post PV": 119.44,
    "out:Primary Energy": 101.096993,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.488566,
    "out:CO2 Operational/m2": 41.300348,
    "out:Total CO2/m2": 43.788914,
    "out:Total CO2 (tons)": 86.351743,
    "out:Klimatpaverkan": -8.580429,
    "out:Initial Cost/MSEK": 0.37083262465375005,
    "out:Running cost/(Apt SEK y)": 20480.970588,
    "out:Running Cost over RSP/MSEK": 5.44401,
    "out:LCP/MSEK": 0.514227,
    "out:ROI% old": 34.095474,
    "out:Payback discounted": 4,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 31,
    "out:Return/kSEK/y": 113,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 37376.522067,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 37376.52,
    "out:DH kr savings": 37376.52206671159,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 370832.62465375004,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.318208,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 115.44,
    "out:Total Energy Use Post PV": 115.44,
    "out:Primary Energy": 97.863433,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 2.488566,
    "out:CO2 Operational/m2": 39.860412,
    "out:Total CO2/m2": 42.348978,
    "out:Total CO2 (tons)": 83.512189,
    "out:Klimatpaverkan": -11.419983,
    "out:Initial Cost/MSEK": 0.42013262465375006,
    "out:Running cost/(Apt SEK y)": 19780.323529,
    "out:Running Cost over RSP/MSEK": 5.25769,
    "out:LCP/MSEK": 0.651247,
    "out:ROI% old": 36.429993,
    "out:Payback discounted": 4,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 20,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 33,
    "out:Return/kSEK/y": 137,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 45574.98367,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 45574.98,
    "out:DH kr savings": 45574.983670459165,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 420132.62465375004,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 122.44,
    "out:Total Energy Use Post PV": 119.635375,
    "out:Primary Energy": 98.148668,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.083026,
    "out:CO2 Operational/m2": 4.542391,
    "out:Total CO2/m2": 17.625417,
    "out:Total CO2 (tons)": 34.757324,
    "out:Klimatpaverkan": -60.174848,
    "out:Initial Cost/MSEK": 0.58541149265,
    "out:Running cost/(Apt SEK y)": 20038.617647,
    "out:Running Cost over RSP/MSEK": 5.33342,
    "out:LCP/MSEK": 0.410239,
    "out:ROI% old": 24.296707,
    "out:Payback discounted": 5,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 15,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 22,
    "out:Return/kSEK/y": 128,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 32196.472167,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 65102.67,
    "out:DH kr savings": 32196.472167256383,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 585411.49265,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 118.44,
    "out:Total Energy Use Post PV": 115.635375,
    "out:Primary Energy": 94.915108,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.083026,
    "out:CO2 Operational/m2": 3.102455,
    "out:Total CO2/m2": 16.185481,
    "out:Total CO2 (tons)": 31.91777,
    "out:Klimatpaverkan": -63.014402,
    "out:Initial Cost/MSEK": 0.63471149265,
    "out:Running cost/(Apt SEK y)": 19337.970588,
    "out:Running Cost over RSP/MSEK": 5.1471,
    "out:LCP/MSEK": 0.547259,
    "out:ROI% old": 26.603088,
    "out:Payback discounted": 5,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 24,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 40394.933771,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73301.14,
    "out:DH kr savings": 40394.93377100396,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 634711.49265,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 119.44,
    "out:Total Energy Use Post PV": 116.635375,
    "out:Primary Energy": 96.048668,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.750369,
    "out:CO2 Operational/m2": 3.462439,
    "out:Total CO2/m2": 17.212808,
    "out:Total CO2 (tons)": 33.943659,
    "out:Klimatpaverkan": -60.988513,
    "out:Initial Cost/MSEK": 0.74478749265,
    "out:Running cost/(Apt SEK y)": 19513.117647,
    "out:Running Cost over RSP/MSEK": 5.19368,
    "out:LCP/MSEK": 0.390603,
    "out:ROI% old": 21.777832,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 20,
    "out:Return/kSEK/y": 146,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 37376.522067,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 70282.72,
    "out:DH kr savings": 37376.52206671159,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 744787.49265,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.318208,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 115.44,
    "out:Total Energy Use Post PV": 112.635375,
    "out:Primary Energy": 92.815108,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.750369,
    "out:CO2 Operational/m2": 2.022503,
    "out:Total CO2/m2": 15.772872,
    "out:Total CO2 (tons)": 31.104105,
    "out:Klimatpaverkan": -63.828067,
    "out:Initial Cost/MSEK": 0.79408749265,
    "out:Running cost/(Apt SEK y)": 18812.5,
    "out:Running Cost over RSP/MSEK": 5.00735,
    "out:LCP/MSEK": 0.527633,
    "out:ROI% old": 23.777876,
    "out:Payback discounted": 5,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 21,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 45574.98367,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78481.19,
    "out:DH kr savings": 45574.983670459165,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 794087.49265,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 122.44,
    "out:Total Energy Use Post PV": 119.377795,
    "out:Primary Energy": 97.685024,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.344829,
    "out:CO2 Operational/m2": -46.106134,
    "out:Total CO2/m2": -21.761305,
    "out:Total CO2 (tons)": -42.913296,
    "out:Klimatpaverkan": -137.845468,
    "out:Initial Cost/MSEK": 0.9593663781087501,
    "out:Running cost/(Apt SEK y)": 19144.647059,
    "out:Running Cost over RSP/MSEK": 5.10241,
    "out:LCP/MSEK": 0.267294,
    "out:ROI% old": 18.265925,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 17,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 32196.472167,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95497.49,
    "out:DH kr savings": 32196.472167256383,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 959366.3781087501,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.39816,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 118.44,
    "out:Total Energy Use Post PV": 115.377795,
    "out:Primary Energy": 94.451464,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.344829,
    "out:CO2 Operational/m2": -47.54607,
    "out:Total CO2/m2": -23.201241,
    "out:Total CO2 (tons)": -45.75285,
    "out:Klimatpaverkan": -140.685022,
    "out:Initial Cost/MSEK": 1.0086663781087502,
    "out:Running cost/(Apt SEK y)": 18444,
    "out:Running Cost over RSP/MSEK": 4.91608,
    "out:LCP/MSEK": 0.404324,
    "out:ROI% old": 20.012139,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 20,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 18,
    "out:Return/kSEK/y": 182,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 40394.933771,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103695.95,
    "out:DH kr savings": 40394.93377100396,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1008666.3781087501,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.463562,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 119.44,
    "out:Total Energy Use Post PV": 116.377795,
    "out:Primary Energy": 95.585024,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.012172,
    "out:CO2 Operational/m2": -47.186086,
    "out:Total CO2/m2": -22.173914,
    "out:Total CO2 (tons)": -43.726961,
    "out:Klimatpaverkan": -138.659133,
    "out:Initial Cost/MSEK": 1.11874237810875,
    "out:Running cost/(Apt SEK y)": 18619.176471,
    "out:Running Cost over RSP/MSEK": 4.96267,
    "out:LCP/MSEK": 0.247658,
    "out:ROI% old": 17.448164,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 16,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 37376.522067,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100677.54,
    "out:DH kr savings": 37376.52206671159,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 192198.155966,
    "out:% savings (space heating)": 10.920895,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.46,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1118742.37810875,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.318208,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.306128,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 115.44,
    "out:Total Energy Use Post PV": 112.377795,
    "out:Primary Energy": 92.351464,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.012172,
    "out:CO2 Operational/m2": -48.626022,
    "out:Total CO2/m2": -23.61385,
    "out:Total CO2 (tons)": -46.566515,
    "out:Klimatpaverkan": -141.498687,
    "out:Initial Cost/MSEK": 1.1680423781087501,
    "out:Running cost/(Apt SEK y)": 17918.529412,
    "out:Running Cost over RSP/MSEK": 4.77634,
    "out:LCP/MSEK": 0.384688,
    "out:ROI% old": 18.990627,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 23.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 17,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 45574.98367,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108876,
    "out:DH kr savings": 45574.983670459165,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 183999.694363,
    "out:% savings (space heating)": 14.72068,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.31,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1168042.37810875,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.746197,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.014459,
    "out:Electricity (inc PV)": 29.385775,
    "out:Total Energy Use Pre PV": 85.014459,
    "out:Total Energy Use Post PV": 85.385775,
    "out:Primary Energy": 97.718393,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.651724,
    "out:CO2 Operational/m2": 23.989245,
    "out:Total CO2/m2": 30.640969,
    "out:Total CO2 (tons)": 60.423994,
    "out:Klimatpaverkan": -34.508178,
    "out:Initial Cost/MSEK": 1.9727127678993661,
    "out:Running cost/(Apt SEK y)": 13174.647059,
    "out:Running Cost over RSP/MSEK": 3.49324,
    "out:LCP/MSEK": 0.863117,
    "out:ROI% old": 20.536115,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2031.01213,
    "out:Return %": 18,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.274959,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 148996.787291,
    "out:EL kWh savings": -43277.070802,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62442.65,
    "out:DH kr savings": 148996.78729100406,
    "out:El kr savings": -86554.14160363667,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 109931.506839,
    "out:% savings (space heating)": 49.049458,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.63,
    "out:Etvv": 0,
    "out:Ef": 29.39,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 1972712.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": 3.830141,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.830141,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.793405,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.952446,
    "out:Electricity (inc PV)": 29.32912,
    "out:Total Energy Use Pre PV": 80.952446,
    "out:Total Energy Use Post PV": 81.32912,
    "out:Primary Energy": 94.528269,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.651724,
    "out:CO2 Operational/m2": 22.541122,
    "out:Total CO2/m2": 29.192846,
    "out:Total CO2 (tons)": 57.568296,
    "out:Klimatpaverkan": -37.363877,
    "out:Initial Cost/MSEK": 2.022012767899366,
    "out:Running cost/(Apt SEK y)": 12466.794118,
    "out:Running Cost over RSP/MSEK": 3.30503,
    "out:LCP/MSEK": 1.002027,
    "out:ROI% old": 21.365133,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 70.4,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2026.67122,
    "out:Return %": 19,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.212946,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 156791.693566,
    "out:EL kWh savings": -43165.347135,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 70461,
    "out:DH kr savings": 156791.69356562232,
    "out:El kr savings": -86330.69427071937,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 102136.600564,
    "out:% savings (space heating)": 52.662205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.66,
    "out:Etvv": 0,
    "out:Ef": 29.33,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2022012.767899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": 3.821954,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.821954,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.781528,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.614813,
    "out:Electricity (inc PV)": 28.937207,
    "out:Total Energy Use Pre PV": 82.614813,
    "out:Total Energy Use Post PV": 82.937207,
    "out:Primary Energy": 95.145628,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.319067,
    "out:CO2 Operational/m2": 23.21652,
    "out:Total CO2/m2": 30.535587,
    "out:Total CO2 (tons)": 60.216181,
    "out:Klimatpaverkan": -34.715991,
    "out:Initial Cost/MSEK": 2.1320887678993663,
    "out:Running cost/(Apt SEK y)": 12777.970588,
    "out:Running Cost over RSP/MSEK": 3.38789,
    "out:LCP/MSEK": 0.809091,
    "out:ROI% old": 19.706899,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2003.03691,
    "out:Return %": 18,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.875313,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 152871.114783,
    "out:EL kWh savings": -42392.494655,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68086.13,
    "out:DH kr savings": 152871.11478297628,
    "out:El kr savings": -84784.9893093632,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 106057.179347,
    "out:% savings (space heating)": 50.845113,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 55.36,
    "out:Etvv": 0,
    "out:Ef": 28.94,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2132088.767899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": 3.777384,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.777384,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.852745,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.545525,
    "out:Electricity (inc PV)": 28.873915,
    "out:Total Energy Use Pre PV": 78.545525,
    "out:Total Energy Use Post PV": 78.873915,
    "out:Primary Energy": 91.960594,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.319067,
    "out:CO2 Operational/m2": 21.767438,
    "out:Total CO2/m2": 29.086505,
    "out:Total CO2 (tons)": 57.358591,
    "out:Klimatpaverkan": -37.573581,
    "out:Initial Cost/MSEK": 2.181388767899366,
    "out:Running cost/(Apt SEK y)": 12069.294118,
    "out:Running Cost over RSP/MSEK": 3.19945,
    "out:LCP/MSEK": 0.948231,
    "out:ROI% old": 20.495593,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 1998.18675,
    "out:Return %": 18,
    "out:Return/kSEK/y": 399,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.806025,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160618.675307,
    "out:EL kWh savings": -42267.682823,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 76083.31,
    "out:DH kr savings": 160618.67530685756,
    "out:El kr savings": -84535.3656469326,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 98309.618823,
    "out:% savings (space heating)": 54.435916,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 51.41,
    "out:Etvv": 0,
    "out:Ef": 28.87,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2181388.767899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.9992,
    "out:EL CO2": 3.768238,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.768238,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.746197,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.014459,
    "out:Electricity (inc PV)": 21.925475,
    "out:Total Energy Use Pre PV": 85.014459,
    "out:Total Energy Use Post PV": 77.925475,
    "out:Primary Energy": 84.289853,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.913527,
    "out:CO2 Operational/m2": 9.177604,
    "out:Total CO2/m2": 27.091131,
    "out:Total CO2 (tons)": 53.423713,
    "out:Klimatpaverkan": -41.508459,
    "out:Initial Cost/MSEK": 2.346667635895616,
    "out:Running cost/(Apt SEK y)": 12073.382353,
    "out:Running Cost over RSP/MSEK": 3.20818,
    "out:LCP/MSEK": 0.774222,
    "out:ROI% old": 18.998917,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 76.9,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 1527.866886,
    "out:Return %": 17,
    "out:Return/kSEK/y": 399,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.274959,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5489.64485,
    "out:PV (% sold (El))": 27.319966,
    "out:PV (kWh self-consumed)": 14604.248427,
    "out:PV (ratio sold/self-consumed)": 0.375894,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 148996.787291,
    "out:EL kWh savings": -28565.358351,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100100.54,
    "out:DH kr savings": 148996.78729100406,
    "out:El kr savings": -57130.71670268832,
    "out:El kr sold": 8234.467275408457,
    "out:El kr saved": 29208.496854,
    "out:El kr return": 37442.96412940846,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 109931.506839,
    "out:% savings (space heating)": 49.049458,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.63,
    "out:Etvv": 0,
    "out:Ef": 21.93,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2346667.6358956164,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -10.9815,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -13.840782,
    "out:Bought CO2": 2.859282,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.793405,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.952446,
    "out:Electricity (inc PV)": 21.877082,
    "out:Total Energy Use Pre PV": 80.952446,
    "out:Total Energy Use Post PV": 73.877082,
    "out:Primary Energy": 81.114601,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.913527,
    "out:CO2 Operational/m2": 7.684027,
    "out:Total CO2/m2": 25.597554,
    "out:Total CO2 (tons)": 50.478379,
    "out:Klimatpaverkan": -44.453793,
    "out:Initial Cost/MSEK": 2.3959676358956163,
    "out:Running cost/(Apt SEK y)": 11365.794118,
    "out:Running Cost over RSP/MSEK": 3.02003,
    "out:LCP/MSEK": 0.913072,
    "out:ROI% old": 19.729816,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 1524.119787,
    "out:Return %": 18,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.212946,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5507.722377,
    "out:PV (% sold (El))": 27.409931,
    "out:PV (kWh self-consumed)": 14586.170901,
    "out:PV (ratio sold/self-consumed)": 0.377599,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 156791.693566,
    "out:EL kWh savings": -28469.92735,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108113.42,
    "out:DH kr savings": 156791.69356562232,
    "out:El kr savings": -56939.854699654694,
    "out:El kr sold": 8261.58356494626,
    "out:El kr saved": 29172.341802,
    "out:El kr return": 37433.92536694626,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 102136.600564,
    "out:% savings (space heating)": 52.662205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.66,
    "out:Etvv": 0,
    "out:Ef": 21.88,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2395967.6358956164,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -11.035141,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -13.887424,
    "out:Bought CO2": 2.852283,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.781528,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.614813,
    "out:Electricity (inc PV)": 21.542825,
    "out:Total Energy Use Pre PV": 82.614813,
    "out:Total Energy Use Post PV": 75.542825,
    "out:Primary Energy": 81.83574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.58087,
    "out:CO2 Operational/m2": 8.109764,
    "out:Total CO2/m2": 26.690634,
    "out:Total CO2 (tons)": 52.633933,
    "out:Klimatpaverkan": -42.298239,
    "out:Initial Cost/MSEK": 2.506043635895616,
    "out:Running cost/(Apt SEK y)": 11678.411765,
    "out:Running Cost over RSP/MSEK": 3.10328,
    "out:LCP/MSEK": 0.719746,
    "out:ROI% old": 18.388632,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 1503.744349,
    "out:Return %": 16,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.875313,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5607.155027,
    "out:PV (% sold (El))": 27.904772,
    "out:PV (kWh self-consumed)": 14486.73825,
    "out:PV (ratio sold/self-consumed)": 0.387054,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152871.114783,
    "out:EL kWh savings": -27810.772508,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105660.3,
    "out:DH kr savings": 152871.11478297628,
    "out:El kr savings": -55621.54501544407,
    "out:El kr sold": 8410.732540946843,
    "out:El kr saved": 28973.4765,
    "out:El kr return": 37384.209040946844,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 106057.179347,
    "out:% savings (space heating)": 50.845113,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 55.36,
    "out:Etvv": 0,
    "out:Ef": 21.54,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2506043.6358956164,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -11.329372,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.14361,
    "out:Bought CO2": 2.814238,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.852745,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.545525,
    "out:Electricity (inc PV)": 21.48893,
    "out:Total Energy Use Pre PV": 78.545525,
    "out:Total Energy Use Post PV": 71.48893,
    "out:Primary Energy": 78.667621,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.58087,
    "out:CO2 Operational/m2": 6.609007,
    "out:Total CO2/m2": 25.189877,
    "out:Total CO2 (tons)": 49.67444,
    "out:Klimatpaverkan": -45.257732,
    "out:Initial Cost/MSEK": 2.5553436358956163,
    "out:Running cost/(Apt SEK y)": 10970.058824,
    "out:Running Cost over RSP/MSEK": 2.91492,
    "out:LCP/MSEK": 0.858806,
    "out:ROI% old": 19.086893,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 1499.56849,
    "out:Return %": 17,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.806025,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5627.714656,
    "out:PV (% sold (El))": 28.007089,
    "out:PV (kWh self-consumed)": 14466.178621,
    "out:PV (ratio sold/self-consumed)": 0.389026,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160618.675307,
    "out:EL kWh savings": -27704.491562,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113651.26,
    "out:DH kr savings": 160618.67530685756,
    "out:El kr savings": -55408.98312315602,
    "out:El kr sold": 8441.571984208453,
    "out:El kr saved": 28932.357242,
    "out:El kr return": 37373.92922620845,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 98309.618823,
    "out:% savings (space heating)": 54.435916,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 51.41,
    "out:Etvv": 0,
    "out:Ef": 21.49,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2555343.6358956164,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.9992,
    "out:EL CO2": -11.390193,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.196635,
    "out:Bought CO2": 2.806442,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.746197,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 29.014459,
    "out:Electricity (inc PV)": 19.863919,
    "out:Total Energy Use Pre PV": 85.014459,
    "out:Total Energy Use Post PV": 75.863919,
    "out:Primary Energy": 80.579052,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.17533,
    "out:CO2 Operational/m2": -32.495499,
    "out:Total CO2/m2": -3.320169,
    "out:Total CO2 (tons)": -6.547374,
    "out:Klimatpaverkan": -101.479546,
    "out:Initial Cost/MSEK": 2.720622521354366,
    "out:Running cost/(Apt SEK y)": 11128.029412,
    "out:Running Cost over RSP/MSEK": 2.96363,
    "out:LCP/MSEK": 0.644817,
    "out:ROI% old": 17.671586,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 1383.489707,
    "out:Return %": 16,
    "out:Return/kSEK/y": 431,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.274959,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21581.652304,
    "out:PV (% sold (El))": 53.702018,
    "out:PV (kWh self-consumed)": 18606.13425,
    "out:PV (ratio sold/self-consumed)": 1.159921,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 148996.787291,
    "out:EL kWh savings": -24499.969684,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132369.33,
    "out:DH kr savings": 148996.78729100406,
    "out:El kr savings": -48999.93936865351,
    "out:El kr sold": 32372.47845662869,
    "out:El kr saved": 37212.2685,
    "out:El kr return": 69584.74695662869,
    "out:% solar/total": 127,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 109931.506839,
    "out:% savings (space heating)": 49.049458,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.63,
    "out:Etvv": 0,
    "out:Ef": 19.86,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2720622.521354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -52.654603,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.24498,
    "out:Bought CO2": 2.590377,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.793405,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.952446,
    "out:Electricity (inc PV)": 19.820464,
    "out:Total Energy Use Pre PV": 80.952446,
    "out:Total Energy Use Post PV": 71.820464,
    "out:Primary Energy": 77.412688,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.17533,
    "out:CO2 Operational/m2": -34.016483,
    "out:Total CO2/m2": -4.841153,
    "out:Total CO2 (tons)": -9.546754,
    "out:Klimatpaverkan": -104.478927,
    "out:Initial Cost/MSEK": 2.769922521354366,
    "out:Running cost/(Apt SEK y)": 10420.617647,
    "out:Running Cost over RSP/MSEK": 2.77552,
    "out:LCP/MSEK": 0.783627,
    "out:ROI% old": 18.327227,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 1380.127588,
    "out:Return %": 16,
    "out:Return/kSEK/y": 455,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.212946,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21610.347799,
    "out:PV (% sold (El))": 53.773421,
    "out:PV (kWh self-consumed)": 18577.438755,
    "out:PV (ratio sold/self-consumed)": 1.163258,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 156791.693566,
    "out:EL kWh savings": -24414.276419,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140378.66,
    "out:DH kr savings": 156791.69356562232,
    "out:El kr savings": -48828.55283874579,
    "out:El kr sold": 32415.521699146317,
    "out:El kr saved": 37154.87751,
    "out:El kr return": 69570.39920914632,
    "out:% solar/total": 127,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 102136.600564,
    "out:% savings (space heating)": 52.662205,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.66,
    "out:Etvv": 0,
    "out:Ef": 19.82,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2769922.521354366,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -52.735651,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.319746,
    "out:Bought CO2": 2.584095,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.781528,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.614813,
    "out:Electricity (inc PV)": 19.520178,
    "out:Total Energy Use Pre PV": 82.614813,
    "out:Total Energy Use Post PV": 73.520178,
    "out:Primary Energy": 78.194975,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.842673,
    "out:CO2 Operational/m2": -33.739225,
    "out:Total CO2/m2": -3.896552,
    "out:Total CO2 (tons)": -7.684001,
    "out:Klimatpaverkan": -102.616173,
    "out:Initial Cost/MSEK": 2.879998521354366,
    "out:Running cost/(Apt SEK y)": 10734.088235,
    "out:Running Cost over RSP/MSEK": 2.85899,
    "out:LCP/MSEK": 0.590081,
    "out:ROI% old": 17.212707,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 1361.844826,
    "out:Return %": 15,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.875313,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21767.076376,
    "out:PV (% sold (El))": 54.163412,
    "out:PV (kWh self-consumed)": 18420.710179,
    "out:PV (ratio sold/self-consumed)": 1.181663,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 152871.114783,
    "out:EL kWh savings": -23822.112393,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137877.5,
    "out:DH kr savings": 152871.11478297628,
    "out:El kr savings": -47644.22478628058,
    "out:El kr sold": 32650.614564083684,
    "out:El kr saved": 36841.420358,
    "out:El kr return": 69492.0349220837,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 106057.179347,
    "out:% savings (space heating)": 50.845113,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 55.36,
    "out:Etvv": 0,
    "out:Ef": 19.52,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2879998.521354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -53.178361,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.728289,
    "out:Bought CO2": 2.549928,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.852745,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.545525,
    "out:Electricity (inc PV)": 19.471754,
    "out:Total Energy Use Pre PV": 78.545525,
    "out:Total Energy Use Post PV": 69.471754,
    "out:Primary Energy": 75.036704,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.842673,
    "out:CO2 Operational/m2": -35.270306,
    "out:Total CO2/m2": -5.427633,
    "out:Total CO2 (tons)": -10.703293,
    "out:Klimatpaverkan": -105.635465,
    "out:Initial Cost/MSEK": 2.929298521354366,
    "out:Running cost/(Apt SEK y)": 10025.882353,
    "out:Running Cost over RSP/MSEK": 2.67067,
    "out:LCP/MSEK": 0.729101,
    "out:ROI% old": 17.841424,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 1358.097553,
    "out:Return %": 16,
    "out:Return/kSEK/y": 469,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.806025,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21799.361751,
    "out:PV (% sold (El))": 54.243748,
    "out:PV (kWh self-consumed)": 18388.424804,
    "out:PV (ratio sold/self-consumed)": 1.185494,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 160618.675307,
    "out:EL kWh savings": -23726.62026,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145864.48,
    "out:DH kr savings": 160618.67530685756,
    "out:El kr savings": -47453.240519239924,
    "out:El kr sold": 32699.042626228038,
    "out:El kr saved": 36776.849608,
    "out:El kr return": 69475.89223422803,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 98309.618823,
    "out:% savings (space heating)": 54.435916,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 51.41,
    "out:Etvv": 0,
    "out:Ef": 19.47,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2929298.521354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.9992,
    "out:EL CO2": -53.269506,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.81243,
    "out:Bought CO2": 2.542924,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.133827,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.47942,
    "out:Electricity (inc PV)": 28.785532,
    "out:Total Energy Use Pre PV": 81.47942,
    "out:Total Energy Use Post PV": 81.785532,
    "out:Primary Energy": 94.289691,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.651724,
    "out:CO2 Operational/m2": 22.838663,
    "out:Total CO2/m2": 29.490387,
    "out:Total CO2 (tons)": 58.155047,
    "out:Klimatpaverkan": -36.777126,
    "out:Initial Cost/MSEK": 2.062449392899366,
    "out:Running cost/(Apt SEK y)": 12587.088235,
    "out:Running Cost over RSP/MSEK": 3.33718,
    "out:LCP/MSEK": 0.929441,
    "out:ROI% old": 20.723556,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 1993.5594,
    "out:Return %": 18,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.73992,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154148.381229,
    "out:EL kWh savings": -42093.391537,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69961.6,
    "out:DH kr savings": 154148.38122881422,
    "out:El kr savings": -84186.7830747813,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104779.912901,
    "out:% savings (space heating)": 51.437095,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 54.61,
    "out:Etvv": 0,
    "out:Ef": 28.79,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2062449.3928993659,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": 3.759511,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.759511,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.212653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.407826,
    "out:Electricity (inc PV)": 28.719319,
    "out:Total Energy Use Pre PV": 77.407826,
    "out:Total Energy Use Post PV": 77.719319,
    "out:Primary Energy": 91.106893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.651724,
    "out:CO2 Operational/m2": 21.389276,
    "out:Total CO2/m2": 28.041,
    "out:Total CO2 (tons)": 55.296855,
    "out:Klimatpaverkan": -39.635317,
    "out:Initial Cost/MSEK": 2.111749392899366,
    "out:Running cost/(Apt SEK y)": 11878.147059,
    "out:Running Cost over RSP/MSEK": 3.14867,
    "out:LCP/MSEK": 1.068651,
    "out:ROI% old": 21.514999,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 76.9,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 1988.54782,
    "out:Return %": 19,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.668326,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 161880.936804,
    "out:EL kWh savings": -41962.819494,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77955.3,
    "out:DH kr savings": 161880.93680382805,
    "out:El kr savings": -83925.63898768472,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 97047.357326,
    "out:% savings (space heating)": 55.020943,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 50.67,
    "out:Etvv": 0,
    "out:Ef": 28.72,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2111749.392899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": 3.75006,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.75006,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.591817,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.150696,
    "out:Electricity (inc PV)": 28.415802,
    "out:Total Energy Use Pre PV": 80.150696,
    "out:Total Energy Use Post PV": 80.415802,
    "out:Primary Energy": 92.239818,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.319067,
    "out:CO2 Operational/m2": 22.435285,
    "out:Total CO2/m2": 29.754352,
    "out:Total CO2 (tons)": 58.675586,
    "out:Klimatpaverkan": -36.256587,
    "out:Initial Cost/MSEK": 2.2218253928993663,
    "out:Running cost/(Apt SEK y)": 12373.794118,
    "out:Running Cost over RSP/MSEK": 3.28058,
    "out:LCP/MSEK": 0.826665,
    "out:ROI% old": 19.600936,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 1970.54872,
    "out:Return %": 17,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.411196,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157189.225125,
    "out:EL kWh savings": -41364.283935,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74460.66,
    "out:DH kr savings": 157189.22512460337,
    "out:El kr savings": -82728.56787048285,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 101739.069005,
    "out:% savings (space heating)": 52.846451,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 52.83,
    "out:Etvv": 0,
    "out:Ef": 28.42,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2221825.392899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": 3.716117,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.716117,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.690207,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.073173,
    "out:Electricity (inc PV)": 28.34307,
    "out:Total Energy Use Pre PV": 76.073173,
    "out:Total Energy Use Post PV": 76.34307,
    "out:Primary Energy": 89.062018,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.319067,
    "out:CO2 Operational/m2": 20.985115,
    "out:Total CO2/m2": 28.304182,
    "out:Total CO2 (tons)": 55.81585,
    "out:Klimatpaverkan": -39.116322,
    "out:Initial Cost/MSEK": 2.271125392899366,
    "out:Running cost/(Apt SEK y)": 11664.176471,
    "out:Running Cost over RSP/MSEK": 3.09189,
    "out:LCP/MSEK": 0.966055,
    "out:ROI% old": 20.362341,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1965.12211,
    "out:Return %": 18,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.333673,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164883.200489,
    "out:EL kWh savings": -41220.856423,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82441.49,
    "out:DH kr savings": 164883.2004893869,
    "out:El kr savings": -82441.71284589994,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 94045.093641,
    "out:% savings (space heating)": 56.412419,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 48.91,
    "out:Etvv": 0,
    "out:Ef": 28.34,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2271125.392899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": 3.705883,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.705883,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.133827,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.47942,
    "out:Electricity (inc PV)": 21.413725,
    "out:Total Energy Use Pre PV": 81.47942,
    "out:Total Energy Use Post PV": 74.413725,
    "out:Primary Energy": 81.020438,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.913527,
    "out:CO2 Operational/m2": 7.630789,
    "out:Total CO2/m2": 25.544316,
    "out:Total CO2 (tons)": 50.373394,
    "out:Klimatpaverkan": -44.558778,
    "out:Initial Cost/MSEK": 2.4364042608956162,
    "out:Running cost/(Apt SEK y)": 11488.147059,
    "out:Running Cost over RSP/MSEK": 3.05272,
    "out:LCP/MSEK": 0.839946,
    "out:ROI% old": 19.210687,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 1495.586207,
    "out:Return %": 17,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.73992,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5647.357783,
    "out:PV (% sold (El))": 28.104846,
    "out:PV (kWh self-consumed)": 14446.535494,
    "out:PV (ratio sold/self-consumed)": 0.390914,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154148.381229,
    "out:EL kWh savings": -27556.187293,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107507.04,
    "out:DH kr savings": 154148.38122881422,
    "out:El kr savings": -55112.37458600929,
    "out:El kr sold": 8471.03667455823,
    "out:El kr saved": 28893.070988,
    "out:El kr return": 37364.10766255823,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104779.912901,
    "out:% savings (space heating)": 51.437095,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 54.61,
    "out:Etvv": 0,
    "out:Ef": 21.41,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2436404.2608956164,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": -11.448363,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.247371,
    "out:Bought CO2": 2.799008,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.212653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.407826,
    "out:Electricity (inc PV)": 21.357412,
    "out:Total Energy Use Pre PV": 77.407826,
    "out:Total Energy Use Post PV": 70.357412,
    "out:Primary Energy": 77.855461,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.913527,
    "out:CO2 Operational/m2": 6.127718,
    "out:Total CO2/m2": 24.041245,
    "out:Total CO2 (tons)": 47.409338,
    "out:Klimatpaverkan": -47.522834,
    "out:Initial Cost/MSEK": 2.4857042608956164,
    "out:Running cost/(Apt SEK y)": 10779.529412,
    "out:Running Cost over RSP/MSEK": 2.86429,
    "out:LCP/MSEK": 0.979076,
    "out:ROI% old": 19.912609,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 97.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 1491.275214,
    "out:Return %": 18,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.668326,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5668.670255,
    "out:PV (% sold (El))": 28.210911,
    "out:PV (kWh self-consumed)": 14425.223022,
    "out:PV (ratio sold/self-consumed)": 0.392969,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 161880.936804,
    "out:EL kWh savings": -27445.138051,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115493.67,
    "out:DH kr savings": 161880.93680382805,
    "out:El kr savings": -54890.27610116992,
    "out:El kr sold": 8503.005382815445,
    "out:El kr saved": 28850.446044,
    "out:El kr return": 37353.451426815445,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 97047.357326,
    "out:% savings (space heating)": 55.020943,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 50.67,
    "out:Etvv": 0,
    "out:Ef": 21.36,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2485704.2608956164,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": -11.511498,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.302458,
    "out:Bought CO2": 2.79096,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.591817,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.150696,
    "out:Electricity (inc PV)": 21.099575,
    "out:Total Energy Use Pre PV": 80.150696,
    "out:Total Energy Use Post PV": 73.099575,
    "out:Primary Energy": 79.070609,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.58087,
    "out:CO2 Operational/m2": 6.979735,
    "out:Total CO2/m2": 25.560605,
    "out:Total CO2 (tons)": 50.405516,
    "out:Klimatpaverkan": -44.526656,
    "out:Initial Cost/MSEK": 2.595780260895616,
    "out:Running cost/(Apt SEK y)": 11276.294118,
    "out:Running Cost over RSP/MSEK": 2.99649,
    "out:LCP/MSEK": 0.7368,
    "out:ROI% old": 18.340646,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 89,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 1475.809134,
    "out:Return %": 16,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.411196,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5745.571543,
    "out:PV (% sold (El))": 28.59362,
    "out:PV (kWh self-consumed)": 14348.321734,
    "out:PV (ratio sold/self-consumed)": 0.400435,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157189.225125,
    "out:EL kWh savings": -26936.683457,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111934.22,
    "out:DH kr savings": 157189.22512460337,
    "out:El kr savings": -53873.3669143831,
    "out:El kr sold": 8618.357314577344,
    "out:El kr saved": 28696.643468,
    "out:El kr return": 37315.00078257734,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 101739.069005,
    "out:% savings (space heating)": 52.846451,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 52.83,
    "out:Etvv": 0,
    "out:Ef": 21.1,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2595780.2608956164,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -11.739433,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.50152,
    "out:Bought CO2": 2.762087,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.690207,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.073173,
    "out:Electricity (inc PV)": 21.037856,
    "out:Total Energy Use Pre PV": 76.073173,
    "out:Total Energy Use Post PV": 69.037856,
    "out:Primary Energy": 75.912633,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.58087,
    "out:CO2 Operational/m2": 5.470751,
    "out:Total CO2/m2": 24.051621,
    "out:Total CO2 (tons)": 47.429799,
    "out:Klimatpaverkan": -47.502373,
    "out:Initial Cost/MSEK": 2.6450802608956163,
    "out:Running cost/(Apt SEK y)": 10567,
    "out:Running Cost over RSP/MSEK": 2.80789,
    "out:LCP/MSEK": 0.8761,
    "out:ROI% old": 19.017408,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1471.151335,
    "out:Return %": 17,
    "out:Return/kSEK/y": 450,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.333673,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5768.818902,
    "out:PV (% sold (El))": 28.709314,
    "out:PV (kWh self-consumed)": 14325.074375,
    "out:PV (ratio sold/self-consumed)": 0.402708,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164883.200489,
    "out:EL kWh savings": -26814.973582,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119906.48,
    "out:DH kr savings": 164883.2004893869,
    "out:El kr savings": -53629.94716431118,
    "out:El kr sold": 8653.228352943317,
    "out:El kr saved": 28650.14875,
    "out:El kr return": 37303.37710294332,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 94045.093641,
    "out:% savings (space heating)": 56.412419,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 48.91,
    "out:Etvv": 0,
    "out:Ef": 21.04,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2645080.2608956164,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -11.808481,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.561872,
    "out:Bought CO2": 2.753391,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.133827,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.47942,
    "out:Electricity (inc PV)": 19.404159,
    "out:Total Energy Use Pre PV": 81.47942,
    "out:Total Energy Use Post PV": 72.404159,
    "out:Primary Energy": 77.403219,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.17533,
    "out:CO2 Operational/m2": -34.277432,
    "out:Total CO2/m2": -5.102102,
    "out:Total CO2 (tons)": -10.061346,
    "out:Klimatpaverkan": -104.993518,
    "out:Initial Cost/MSEK": 2.810359146354366,
    "out:Running cost/(Apt SEK y)": 10544.147059,
    "out:Running Cost over RSP/MSEK": 2.80852,
    "out:LCP/MSEK": 0.710191,
    "out:ROI% old": 17.895781,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 1354.523916,
    "out:Return %": 16,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.73992,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21830.232485,
    "out:PV (% sold (El))": 54.320564,
    "out:PV (kWh self-consumed)": 18357.554069,
    "out:PV (ratio sold/self-consumed)": 1.189169,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154148.381229,
    "out:EL kWh savings": -23593.322912,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139707.08,
    "out:DH kr savings": 154148.38122881422,
    "out:El kr savings": -47186.645823828214,
    "out:El kr sold": 32745.348728036093,
    "out:El kr saved": 36715.108138,
    "out:El kr return": 69460.4568660361,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104779.912901,
    "out:% savings (space heating)": 51.437095,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 54.61,
    "out:Etvv": 0,
    "out:Ef": 19.4,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2810359.146354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": -53.356584,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.892829,
    "out:Bought CO2": 2.536245,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.212653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.407826,
    "out:Electricity (inc PV)": 19.353541,
    "out:Total Energy Use Pre PV": 77.407826,
    "out:Total Energy Use Post PV": 68.353541,
    "out:Primary Energy": 74.248493,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.17533,
    "out:CO2 Operational/m2": -35.811765,
    "out:Total CO2/m2": -6.636435,
    "out:Total CO2 (tons)": -13.087051,
    "out:Klimatpaverkan": -108.019223,
    "out:Initial Cost/MSEK": 2.859659146354366,
    "out:Running cost/(Apt SEK y)": 9835.676471,
    "out:Running Cost over RSP/MSEK": 2.62014,
    "out:LCP/MSEK": 0.849271,
    "out:ROI% old": 18.528332,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 1350.655151,
    "out:Return %": 17,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.668326,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21863.705009,
    "out:PV (% sold (El))": 54.403855,
    "out:PV (kWh self-consumed)": 18324.081546,
    "out:PV (ratio sold/self-consumed)": 1.193168,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 161880.936804,
    "out:EL kWh savings": -23493.50421,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 147689.49,
    "out:DH kr savings": 161880.93680382805,
    "out:El kr savings": -46987.00842028737,
    "out:El kr sold": 32795.557513568725,
    "out:El kr saved": 36648.163092,
    "out:El kr return": 69443.72060556873,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 97047.357326,
    "out:% savings (space heating)": 55.020943,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 50.67,
    "out:Etvv": 0,
    "out:Ef": 19.35,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2859659.146354366,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": -53.450981,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.979995,
    "out:Bought CO2": 2.529014,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.591817,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.150696,
    "out:Electricity (inc PV)": 19.121781,
    "out:Total Energy Use Pre PV": 80.150696,
    "out:Total Energy Use Post PV": 71.121781,
    "out:Primary Energy": 75.51058,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.842673,
    "out:CO2 Operational/m2": -35.071869,
    "out:Total CO2/m2": -5.229196,
    "out:Total CO2 (tons)": -10.311975,
    "out:Klimatpaverkan": -105.244147,
    "out:Initial Cost/MSEK": 2.969735146354366,
    "out:Running cost/(Apt SEK y)": 10333.117647,
    "out:Running Cost over RSP/MSEK": 2.75251,
    "out:LCP/MSEK": 0.606825,
    "out:ROI% old": 17.204805,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 1336.774265,
    "out:Return %": 15,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.411196,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21984.42687,
    "out:PV (% sold (El))": 54.704249,
    "out:PV (kWh self-consumed)": 18203.359685,
    "out:PV (ratio sold/self-consumed)": 1.207713,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 157189.225125,
    "out:EL kWh savings": -23036.473464,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144092.92,
    "out:DH kr savings": 157189.22512460337,
    "out:El kr savings": -46072.94692744606,
    "out:El kr sold": 32976.640304449276,
    "out:El kr saved": 36406.71937,
    "out:El kr return": 69383.35967444928,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 101739.069005,
    "out:% savings (space heating)": 52.846451,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 52.83,
    "out:Etvv": 0,
    "out:Ef": 19.12,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2969735.146354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -53.791037,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.294107,
    "out:Bought CO2": 2.50307,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.690207,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.073173,
    "out:Electricity (inc PV)": 19.066308,
    "out:Total Energy Use Pre PV": 76.073173,
    "out:Total Energy Use Post PV": 67.066308,
    "out:Primary Energy": 72.363846,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.842673,
    "out:CO2 Operational/m2": -36.614674,
    "out:Total CO2/m2": -6.772001,
    "out:Total CO2 (tons)": -13.354387,
    "out:Klimatpaverkan": -108.286559,
    "out:Initial Cost/MSEK": 3.019035146354366,
    "out:Running cost/(Apt SEK y)": 9624.029412,
    "out:Running Cost over RSP/MSEK": 2.56396,
    "out:LCP/MSEK": 0.746075,
    "out:ROI% old": 17.816052,
    "out:Payback discounted": 7,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1332.593572,
    "out:Return %": 16,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.333673,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22020.982759,
    "out:PV (% sold (El))": 54.795212,
    "out:PV (kWh self-consumed)": 18166.803795,
    "out:PV (ratio sold/self-consumed)": 1.212155,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164883.200489,
    "out:EL kWh savings": -22927.080701,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152060.51,
    "out:DH kr savings": 164883.2004893869,
    "out:El kr savings": -45854.161402798185,
    "out:El kr sold": 33031.47413898599,
    "out:El kr saved": 36333.60759,
    "out:El kr return": 69365.081728986,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 94045.093641,
    "out:% savings (space heating)": 56.412419,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 48.91,
    "out:Etvv": 0,
    "out:Ef": 19.07,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3019035.146354366,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -53.893906,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.389163,
    "out:Bought CO2": 2.495257,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.570705,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.885821,
    "out:Electricity (inc PV)": 41.473936,
    "out:Total Energy Use Pre PV": 57.885821,
    "out:Total Energy Use Post PV": 58.473936,
    "out:Primary Energy": 88.450803,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.090679,
    "out:CO2 Operational/m2": 11.516983,
    "out:Total CO2/m2": 18.607662,
    "out:Total CO2 (tons)": 36.694312,
    "out:Klimatpaverkan": -58.237861,
    "out:Initial Cost/MSEK": 2.768212767899366,
    "out:Running cost/(Apt SEK y)": 7720.470588,
    "out:Running Cost over RSP/MSEK": 2.03851,
    "out:LCP/MSEK": 1.522347,
    "out:ROI% old": 22.141981,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2862.00747,
    "out:Return %": 20,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 31.146321,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226250.861981,
    "out:EL kWh savings": -67114.925672,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92021.01,
    "out:DH kr savings": 226250.86198101012,
    "out:El kr savings": -134229.85134373742,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 32677.432149,
    "out:% savings (space heating)": 84.854816,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 17.74,
    "out:Etvv": 0,
    "out:Ef": 41.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2768212.767899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": 5.397255,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.397255,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.687814,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.499595,
    "out:Electricity (inc PV)": 41.113427,
    "out:Total Energy Use Pre PV": 54.499595,
    "out:Total Energy Use Post PV": 55.113427,
    "out:Primary Energy": 85.493635,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.090679,
    "out:CO2 Operational/m2": 10.386047,
    "out:Total CO2/m2": 17.476726,
    "out:Total CO2 (tons)": 34.464106,
    "out:Klimatpaverkan": -60.468067,
    "out:Initial Cost/MSEK": 2.817512767899366,
    "out:Running cost/(Apt SEK y)": 7150.205882,
    "out:Running Cost over RSP/MSEK": 1.88699,
    "out:LCP/MSEK": 1.624567,
    "out:ROI% old": 22.522803,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 150.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2834.97165,
    "out:Return %": 20,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 30.760095,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231935.923362,
    "out:EL kWh savings": -66404.001883,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99127.92,
    "out:DH kr savings": 231935.9233616597,
    "out:El kr savings": -132808.00376554136,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 26992.370768,
    "out:% savings (space heating)": 87.489702,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 14.77,
    "out:Etvv": 0,
    "out:Ef": 41.11,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2817512.767899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": 5.346271,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.346271,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.385288,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.250038,
    "out:Electricity (inc PV)": 40.763136,
    "out:Total Energy Use Pre PV": 55.250038,
    "out:Total Energy Use Post PV": 55.763136,
    "out:Primary Energy": 86.079083,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.758022,
    "out:CO2 Operational/m2": 10.713087,
    "out:Total CO2/m2": 18.471109,
    "out:Total CO2 (tons)": 36.425029,
    "out:Klimatpaverkan": -58.507143,
    "out:Initial Cost/MSEK": 2.927588767899366,
    "out:Running cost/(Apt SEK y)": 7296.411765,
    "out:Running Cost over RSP/MSEK": 1.92596,
    "out:LCP/MSEK": 1.475521,
    "out:ROI% old": 21.485795,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 146.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 2817.50266,
    "out:Return %": 19,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 30.510538,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 228588.50444,
    "out:EL kWh savings": -65713.227991,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97162.05,
    "out:DH kr savings": 228588.50444014766,
    "out:El kr savings": -131426.455981675,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 30339.78969,
    "out:% savings (space heating)": 85.938256,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 16.34,
    "out:Etvv": 0,
    "out:Ef": 40.76,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2927588.767899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": 5.313327,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.313327,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.590603,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.837083,
    "out:Electricity (inc PV)": 40.371826,
    "out:Total Energy Use Pre PV": 52.837083,
    "out:Total Energy Use Post PV": 53.371826,
    "out:Primary Energy": 83.145526,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.758022,
    "out:CO2 Operational/m2": 9.938606,
    "out:Total CO2/m2": 17.696628,
    "out:Total CO2 (tons)": 34.897752,
    "out:Klimatpaverkan": -60.03442,
    "out:Initial Cost/MSEK": 2.976888767899366,
    "out:Running cost/(Apt SEK y)": 6898.176471,
    "out:Running Cost over RSP/MSEK": 1.82021,
    "out:LCP/MSEK": 1.531971,
    "out:ROI% old": 21.637451,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 160.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 2788.59581,
    "out:Return %": 19,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 30.097583,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 234099.623579,
    "out:EL kWh savings": -64941.564626,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104216.49,
    "out:DH kr savings": 234099.62357874174,
    "out:El kr savings": -129883.12925245636,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 24828.670551,
    "out:% savings (space heating)": 88.492524,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 13.47,
    "out:Etvv": 0,
    "out:Ef": 40.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2976888.767899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.679792,
    "out:EL CO2": 5.258814,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.258814,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.570705,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.885821,
    "out:Electricity (inc PV)": 32.592348,
    "out:Total Energy Use Pre PV": 57.885821,
    "out:Total Energy Use Post PV": 49.592348,
    "out:Primary Energy": 72.463944,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.352482,
    "out:CO2 Operational/m2": 3.730549,
    "out:Total CO2/m2": 22.083031,
    "out:Total CO2 (tons)": 43.54774,
    "out:Klimatpaverkan": -51.384433,
    "out:Initial Cost/MSEK": 3.1421676358956154,
    "out:Running cost/(Apt SEK y)": 6578,
    "out:Running Cost over RSP/MSEK": 1.74287,
    "out:LCP/MSEK": 1.444032,
    "out:ROI% old": 20.850938,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 176,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2268.264176,
    "out:Return %": 19,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 31.146321,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2687.057105,
    "out:PV (% sold (El))": 13.372506,
    "out:PV (kWh self-consumed)": 17406.836172,
    "out:PV (ratio sold/self-consumed)": 0.154368,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226250.861981,
    "out:EL kWh savings": -49600.433123,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131080.58,
    "out:DH kr savings": 226250.86198101012,
    "out:El kr savings": -99200.86624673537,
    "out:El kr sold": 4030.5856575603393,
    "out:El kr saved": 34813.672344,
    "out:El kr return": 38844.25800156034,
    "out:% solar/total": 119,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 32677.432149,
    "out:% savings (space heating)": 84.854816,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 17.74,
    "out:Etvv": 0,
    "out:Ef": 32.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3142167.6358956154,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": -2.389179,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.631956,
    "out:Bought CO2": 4.242777,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.687814,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.499595,
    "out:Electricity (inc PV)": 32.265094,
    "out:Total Energy Use Pre PV": 54.499595,
    "out:Total Energy Use Post PV": 46.265094,
    "out:Primary Energy": 69.566635,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.352482,
    "out:CO2 Operational/m2": 2.419826,
    "out:Total CO2/m2": 20.772308,
    "out:Total CO2 (tons)": 40.962994,
    "out:Klimatpaverkan": -53.969179,
    "out:Initial Cost/MSEK": 3.1914676358956156,
    "out:Running cost/(Apt SEK y)": 6008.794118,
    "out:Running Cost over RSP/MSEK": 1.59162,
    "out:LCP/MSEK": 1.545982,
    "out:ROI% old": 21.205873,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 200,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2243.478218,
    "out:Return %": 19,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 30.760095,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2759.498138,
    "out:PV (% sold (El))": 13.733019,
    "out:PV (kWh self-consumed)": 17334.395139,
    "out:PV (ratio sold/self-consumed)": 0.159192,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231935.923362,
    "out:EL kWh savings": -48955.088198,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138164.99,
    "out:DH kr savings": 231935.9233616597,
    "out:El kr savings": -97910.17639612145,
    "out:El kr sold": 4139.247207107462,
    "out:El kr saved": 34668.790278,
    "out:El kr return": 38808.03748510746,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 26992.370768,
    "out:% savings (space heating)": 87.489702,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 14.77,
    "out:Etvv": 0,
    "out:Ef": 32.27,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3191467.6358956154,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": -2.61995,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.816442,
    "out:Bought CO2": 4.196492,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.385288,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.250038,
    "out:Electricity (inc PV)": 31.947746,
    "out:Total Energy Use Pre PV": 55.250038,
    "out:Total Energy Use Post PV": 46.947746,
    "out:Primary Energy": 70.211381,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.019825,
    "out:CO2 Operational/m2": 2.628893,
    "out:Total CO2/m2": 21.648718,
    "out:Total CO2 (tons)": 42.691274,
    "out:Klimatpaverkan": -52.240898,
    "out:Initial Cost/MSEK": 3.3015436358956154,
    "out:Running cost/(Apt SEK y)": 6155.705882,
    "out:Running Cost over RSP/MSEK": 1.63077,
    "out:LCP/MSEK": 1.396756,
    "out:ROI% old": 20.329452,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 193.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 2227.486653,
    "out:Return %": 18,
    "out:Return/kSEK/y": 600,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 30.510538,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2807.155547,
    "out:PV (% sold (El))": 13.970192,
    "out:PV (kWh self-consumed)": 17286.73773,
    "out:PV (ratio sold/self-consumed)": 0.162388,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228588.50444,
    "out:EL kWh savings": -48329.277906,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136140.68,
    "out:DH kr savings": 228588.50444014766,
    "out:El kr savings": -96658.55581176605,
    "out:El kr sold": 4210.733320699688,
    "out:El kr saved": 34573.47546,
    "out:El kr return": 38784.208780699686,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 30339.78969,
    "out:% savings (space heating)": 85.938256,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 16.34,
    "out:Etvv": 0,
    "out:Ef": 31.95,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3301543.6358956154,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": -2.770867,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -6.937499,
    "out:Bought CO2": 4.166632,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.590603,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.837083,
    "out:Electricity (inc PV)": 31.593889,
    "out:Total Energy Use Pre PV": 52.837083,
    "out:Total Energy Use Post PV": 44.593889,
    "out:Primary Energy": 67.345239,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.019825,
    "out:CO2 Operational/m2": 1.655841,
    "out:Total CO2/m2": 20.675666,
    "out:Total CO2 (tons)": 40.772416,
    "out:Klimatpaverkan": -54.159757,
    "out:Initial Cost/MSEK": 3.3508436358956155,
    "out:Running cost/(Apt SEK y)": 5758.647059,
    "out:Running Cost over RSP/MSEK": 1.52531,
    "out:LCP/MSEK": 1.452916,
    "out:ROI% old": 20.47996,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 2201.06714,
    "out:Return %": 18,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 30.097583,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2887.41577,
    "out:PV (% sold (El))": 14.369618,
    "out:PV (kWh self-consumed)": 17206.477508,
    "out:PV (ratio sold/self-consumed)": 0.16781,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 234099.623579,
    "out:EL kWh savings": -47631.471862,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143167.8,
    "out:DH kr savings": 234099.62357874174,
    "out:El kr savings": -95262.94372308668,
    "out:El kr sold": 4331.123654802848,
    "out:El kr saved": 34412.955016,
    "out:El kr return": 38744.078670802846,
    "out:% solar/total": 117,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 24828.670551,
    "out:% savings (space heating)": 88.492524,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 13.47,
    "out:Etvv": 0,
    "out:Ef": 31.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3350843.6358956154,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.679792,
    "out:EL CO2": -3.023951,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.141261,
    "out:Bought CO2": 4.11731,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.570705,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.885821,
    "out:Electricity (inc PV)": 29.432418,
    "out:Total Energy Use Pre PV": 57.885821,
    "out:Total Energy Use Post PV": 46.432418,
    "out:Primary Energy": 66.77607,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.614285,
    "out:CO2 Operational/m2": -32.487723,
    "out:Total CO2/m2": -2.873438,
    "out:Total CO2 (tons)": -5.66642,
    "out:Klimatpaverkan": -100.598592,
    "out:Initial Cost/MSEK": 3.516122521354366,
    "out:Running cost/(Apt SEK y)": 5601.5,
    "out:Running Cost over RSP/MSEK": 1.49008,
    "out:LCP/MSEK": 1.322867,
    "out:ROI% old": 19.660415,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 200,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2046.918779,
    "out:Return %": 18,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 31.146321,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16661.745723,
    "out:PV (% sold (El))": 41.459725,
    "out:PV (kWh self-consumed)": 23526.040831,
    "out:PV (ratio sold/self-consumed)": 0.708226,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 226250.861981,
    "out:EL kWh savings": -43369.050803,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164505.38,
    "out:DH kr savings": 226250.86198101012,
    "out:El kr savings": -86738.10160627132,
    "out:El kr sold": 24992.618585072392,
    "out:El kr saved": 47052.081662,
    "out:El kr return": 72044.70024707238,
    "out:% solar/total": 160,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 32677.432149,
    "out:% savings (space heating)": 84.854816,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 17.74,
    "out:Etvv": 0,
    "out:Ef": 29.43,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3516122.521354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": -38.607451,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -42.438817,
    "out:Bought CO2": 3.831366,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.687814,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.499595,
    "out:Electricity (inc PV)": 29.140164,
    "out:Total Energy Use Pre PV": 54.499595,
    "out:Total Energy Use Post PV": 43.140164,
    "out:Primary Energy": 63.941761,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.614285,
    "out:CO2 Operational/m2": -33.987651,
    "out:Total CO2/m2": -4.373366,
    "out:Total CO2 (tons)": -8.624278,
    "out:Klimatpaverkan": -103.556451,
    "out:Initial Cost/MSEK": 3.565422521354366,
    "out:Running cost/(Apt SEK y)": 5033.352941,
    "out:Running Cost over RSP/MSEK": 1.33911,
    "out:LCP/MSEK": 1.424537,
    "out:ROI% old": 19.993463,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 220.9,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2024.819915,
    "out:Return %": 18,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 30.760095,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16806.81048,
    "out:PV (% sold (El))": 41.820692,
    "out:PV (kWh self-consumed)": 23380.976075,
    "out:PV (ratio sold/self-consumed)": 0.718824,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231935.923362,
    "out:EL kWh savings": -42792.725882,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171560.69,
    "out:DH kr savings": 231935.9233616597,
    "out:El kr savings": -85585.45176363738,
    "out:El kr sold": 25210.215720124375,
    "out:El kr saved": 46761.95215,
    "out:El kr return": 71972.16787012437,
    "out:% solar/total": 159,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 26992.370768,
    "out:% savings (space heating)": 87.489702,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 14.77,
    "out:Etvv": 0,
    "out:Ef": 29.14,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3565422.521354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": -39.027427,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -42.81745,
    "out:Bought CO2": 3.790023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.385288,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.250038,
    "out:Electricity (inc PV)": 28.856428,
    "out:Total Energy Use Pre PV": 55.250038,
    "out:Total Energy Use Post PV": 43.856428,
    "out:Primary Energy": 64.647008,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.281628,
    "out:CO2 Operational/m2": -33.899887,
    "out:Total CO2/m2": -3.618259,
    "out:Total CO2 (tons)": -7.135207,
    "out:Klimatpaverkan": -102.067379,
    "out:Initial Cost/MSEK": 3.675498521354366,
    "out:Running cost/(Apt SEK y)": 5180.970588,
    "out:Running Cost over RSP/MSEK": 1.37845,
    "out:LCP/MSEK": 1.275121,
    "out:ROI% old": 19.241782,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 213.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 2010.557157,
    "out:Return %": 17,
    "out:Return/kSEK/y": 633,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 30.510538,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16900.875609,
    "out:PV (% sold (El))": 42.054756,
    "out:PV (kWh self-consumed)": 23286.910945,
    "out:PV (ratio sold/self-consumed)": 0.725767,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 228588.50444,
    "out:EL kWh savings": -42233.198457,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169473.42,
    "out:DH kr savings": 228588.50444014766,
    "out:El kr savings": -84466.39691494561,
    "out:El kr sold": 25351.31341392122,
    "out:El kr saved": 46573.82189,
    "out:El kr return": 71925.13530392121,
    "out:% solar/total": 159,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 30339.78969,
    "out:% savings (space heating)": 85.938256,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 16.34,
    "out:Etvv": 0,
    "out:Ef": 28.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3675498.521354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": -39.299647,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -43.062979,
    "out:Bought CO2": 3.763332,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.590603,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.837083,
    "out:Electricity (inc PV)": 28.53974,
    "out:Total Energy Use Pre PV": 52.837083,
    "out:Total Energy Use Post PV": 41.53974,
    "out:Primary Energy": 61.847771,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.281628,
    "out:CO2 Operational/m2": -35.07207,
    "out:Total CO2/m2": -4.790442,
    "out:Total CO2 (tons)": -9.446752,
    "out:Klimatpaverkan": -104.378924,
    "out:Initial Cost/MSEK": 3.724798521354366,
    "out:Running cost/(Apt SEK y)": 4785.029412,
    "out:Running Cost over RSP/MSEK": 1.27329,
    "out:LCP/MSEK": 1.330981,
    "out:ROI% old": 19.390426,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 228.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 1986.984679,
    "out:Return %": 17,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 30.097583,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17057.64508,
    "out:PV (% sold (El))": 42.444848,
    "out:PV (kWh self-consumed)": 23130.141475,
    "out:PV (ratio sold/self-consumed)": 0.737464,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 234099.623579,
    "out:EL kWh savings": -41608.689685,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176468.71,
    "out:DH kr savings": 234099.62357874174,
    "out:El kr savings": -83217.37937074072,
    "out:El kr sold": 25586.46761929372,
    "out:El kr saved": 46260.28295,
    "out:El kr return": 71846.75056929371,
    "out:% solar/total": 158,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 24828.670551,
    "out:% savings (space heating)": 88.492524,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 13.47,
    "out:Etvv": 0,
    "out:Ef": 28.54,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3724798.521354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.679792,
    "out:EL CO2": -39.751862,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -43.471073,
    "out:Bought CO2": 3.719211,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.000173,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.035073,
    "out:Electricity (inc PV)": 40.521464,
    "out:Total Energy Use Pre PV": 55.035073,
    "out:Total Energy Use Post PV": 55.521464,
    "out:Primary Energy": 85.292144,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.090679,
    "out:CO2 Operational/m2": 10.68471,
    "out:Total CO2/m2": 17.775389,
    "out:Total CO2 (tons)": 35.053069,
    "out:Klimatpaverkan": -59.879103,
    "out:Initial Cost/MSEK": 2.857949392899366,
    "out:Running cost/(Apt SEK y)": 7271.470588,
    "out:Running Cost over RSP/MSEK": 1.91941,
    "out:LCP/MSEK": 1.551711,
    "out:ROI% old": 22.042078,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 146.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 2802.45511,
    "out:Return %": 20,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 30.295573,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 229347.951264,
    "out:EL kWh savings": -65236.650779,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98874.65,
    "out:DH kr savings": 229347.95126405076,
    "out:El kr savings": -130473.30155857377,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 29580.342866,
    "out:% savings (space heating)": 86.290241,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 15.88,
    "out:Etvv": 0,
    "out:Ef": 40.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2857949.392899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": 5.28495,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.28495,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.236374,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.612759,
    "out:Electricity (inc PV)": 40.120283,
    "out:Total Energy Use Pre PV": 51.612759,
    "out:Total Energy Use Post PV": 52.120283,
    "out:Primary Energy": 82.366155,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.090679,
    "out:CO2 Operational/m2": 9.549009,
    "out:Total CO2/m2": 16.639688,
    "out:Total CO2 (tons)": 32.813467,
    "out:Klimatpaverkan": -62.118706,
    "out:Initial Cost/MSEK": 2.907249392899366,
    "out:Running cost/(Apt SEK y)": 6697,
    "out:Running Cost over RSP/MSEK": 1.76679,
    "out:LCP/MSEK": 1.655031,
    "out:ROI% old": 22.418245,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 165.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 2772.89313,
    "out:Return %": 20,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 29.873259,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 234798.163207,
    "out:EL kWh savings": -64445.521802,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105907.12,
    "out:DH kr savings": 234798.16320712387,
    "out:El kr savings": -128891.04360310452,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 24130.130923,
    "out:% savings (space heating)": 88.816279,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 13.05,
    "out:Etvv": 0,
    "out:Ef": 40.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2907249.392899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.319808,
    "out:EL CO2": 5.229201,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.229201,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.094842,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.513415,
    "out:Electricity (inc PV)": 39.934039,
    "out:Total Energy Use Pre PV": 53.513415,
    "out:Total Energy Use Post PV": 53.934039,
    "out:Primary Energy": 83.409169,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.758022,
    "out:CO2 Operational/m2": 10.255863,
    "out:Total CO2/m2": 18.013885,
    "out:Total CO2 (tons)": 35.523383,
    "out:Klimatpaverkan": -59.408789,
    "out:Initial Cost/MSEK": 3.0173253928993664,
    "out:Running cost/(Apt SEK y)": 7035.794118,
    "out:Running Cost over RSP/MSEK": 1.85692,
    "out:LCP/MSEK": 1.454825,
    "out:ROI% old": 21.173672,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 155.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2765.93905,
    "out:Return %": 19,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 29.773915,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231133.264099,
    "out:EL kWh savings": -64078.248612,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102976.77,
    "out:DH kr savings": 231133.2640992585,
    "out:El kr savings": -128156.49722464086,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 27795.030031,
    "out:% savings (space heating)": 87.117689,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 14.82,
    "out:Etvv": 0,
    "out:Ef": 39.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3017325.3928993666,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": 5.216087,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.216087,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.40759,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.067905,
    "out:Electricity (inc PV)": 39.507266,
    "out:Total Energy Use Pre PV": 50.067905,
    "out:Total Energy Use Post PV": 50.507266,
    "out:Primary Energy": 80.502802,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.758022,
    "out:CO2 Operational/m2": 9.1171,
    "out:Total CO2/m2": 16.875122,
    "out:Total CO2 (tons)": 33.277743,
    "out:Klimatpaverkan": -61.65443,
    "out:Initial Cost/MSEK": 3.0666253928993665,
    "out:Running cost/(Apt SEK y)": 6458.647059,
    "out:Running Cost over RSP/MSEK": 1.70359,
    "out:LCP/MSEK": 1.558855,
    "out:ROI% old": 21.547557,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 170.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2734.75335,
    "out:Return %": 19,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 29.328405,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236432.52535,
    "out:EL kWh savings": -63236.652208,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109959.22,
    "out:DH kr savings": 236432.52534960525,
    "out:El kr savings": -126473.30441533668,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 22495.76878,
    "out:% savings (space heating)": 89.573766,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 12.07,
    "out:Etvv": 0,
    "out:Ef": 39.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3066625.3928993666,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": 5.157276,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.157276,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.000173,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.035073,
    "out:Electricity (inc PV)": 31.729104,
    "out:Total Energy Use Pre PV": 55.035073,
    "out:Total Energy Use Post PV": 46.729104,
    "out:Primary Energy": 69.465896,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.352482,
    "out:CO2 Operational/m2": 2.497666,
    "out:Total CO2/m2": 20.850148,
    "out:Total CO2 (tons)": 41.116494,
    "out:Klimatpaverkan": -53.815678,
    "out:Initial Cost/MSEK": 3.2319042608956154,
    "out:Running cost/(Apt SEK y)": 6131.382353,
    "out:Running Cost over RSP/MSEK": 1.62437,
    "out:LCP/MSEK": 1.472796,
    "out:ROI% old": 20.795789,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 193.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 2213.727272,
    "out:Return %": 19,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 30.295573,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2848.78932,
    "out:PV (% sold (El))": 14.177389,
    "out:PV (kWh self-consumed)": 17245.103958,
    "out:PV (ratio sold/self-consumed)": 0.165194,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 229347.951264,
    "out:EL kWh savings": -47898.115857,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137824.9,
    "out:DH kr savings": 229347.95126405076,
    "out:El kr savings": -95796.23171391568,
    "out:El kr sold": 4273.183979279328,
    "out:El kr saved": 34490.207916,
    "out:El kr return": 38763.391895279325,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 29580.342866,
    "out:% savings (space heating)": 86.290241,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 15.88,
    "out:Etvv": 0,
    "out:Ef": 31.73,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3231904.2608956154,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": -2.902094,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.043038,
    "out:Bought CO2": 4.140944,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.236374,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.612759,
    "out:Electricity (inc PV)": 31.366907,
    "out:Total Energy Use Pre PV": 51.612759,
    "out:Total Energy Use Post PV": 43.366907,
    "out:Primary Energy": 66.610079,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.352482,
    "out:CO2 Operational/m2": 1.156617,
    "out:Total CO2/m2": 19.509099,
    "out:Total CO2 (tons)": 38.471945,
    "out:Klimatpaverkan": -56.460227,
    "out:Initial Cost/MSEK": 3.2812042608956156,
    "out:Running cost/(Apt SEK y)": 5558.117647,
    "out:Running Cost over RSP/MSEK": 1.47206,
    "out:LCP/MSEK": 1.575806,
    "out:ROI% old": 21.146461,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 220.9,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 2186.738105,
    "out:Return %": 19,
    "out:Return/kSEK/y": 621,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 29.873259,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2931.591656,
    "out:PV (% sold (El))": 14.589466,
    "out:PV (kWh self-consumed)": 17162.301621,
    "out:PV (ratio sold/self-consumed)": 0.170816,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 234798.163207,
    "out:EL kWh savings": -47183.863332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144827.82,
    "out:DH kr savings": 234798.16320712387,
    "out:El kr savings": -94367.72666333478,
    "out:El kr sold": 4397.387483916075,
    "out:El kr saved": 34324.603242,
    "out:El kr return": 38721.990725916075,
    "out:% solar/total": 117,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 24130.130923,
    "out:% savings (space heating)": 88.816279,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 13.05,
    "out:Etvv": 0,
    "out:Ef": 31.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3281204.2608956154,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.319808,
    "out:EL CO2": -3.163191,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.253755,
    "out:Bought CO2": 4.090564,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.094842,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.513415,
    "out:Electricity (inc PV)": 31.199033,
    "out:Total Energy Use Pre PV": 53.513415,
    "out:Total Energy Use Post PV": 45.199033,
    "out:Primary Energy": 67.686158,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.019825,
    "out:CO2 Operational/m2": 1.814541,
    "out:Total CO2/m2": 20.834366,
    "out:Total CO2 (tons)": 41.085372,
    "out:Klimatpaverkan": -53.8468,
    "out:Initial Cost/MSEK": 3.3912802608956154,
    "out:Running cost/(Apt SEK y)": 5897.205882,
    "out:Running Cost over RSP/MSEK": 1.56226,
    "out:LCP/MSEK": 1.37553,
    "out:ROI% old": 20.080111,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2180.397472,
    "out:Return %": 18,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 29.773915,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2951.222695,
    "out:PV (% sold (El))": 14.687162,
    "out:PV (kWh self-consumed)": 17142.670583,
    "out:PV (ratio sold/self-consumed)": 0.172157,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231133.264099,
    "out:EL kWh savings": -46852.815785,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141854.47,
    "out:DH kr savings": 231133.2640992585,
    "out:El kr savings": -93705.6315690595,
    "out:El kr sold": 4426.834042301591,
    "out:El kr saved": 34285.341166,
    "out:El kr return": 38712.17520830159,
    "out:% solar/total": 117,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 27795.030031,
    "out:% savings (space heating)": 87.117689,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 14.82,
    "out:Etvv": 0,
    "out:Ef": 31.2,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3391280.2608956154,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": -3.225235,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.303964,
    "out:Bought CO2": 4.078729,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.40759,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.067905,
    "out:Electricity (inc PV)": 30.814846,
    "out:Total Energy Use Pre PV": 50.067905,
    "out:Total Energy Use Post PV": 41.814846,
    "out:Primary Energy": 64.856446,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.019825,
    "out:CO2 Operational/m2": 0.453796,
    "out:Total CO2/m2": 19.473621,
    "out:Total CO2 (tons)": 38.401983,
    "out:Klimatpaverkan": -56.530189,
    "out:Initial Cost/MSEK": 3.4405802608956155,
    "out:Running cost/(Apt SEK y)": 5321.352941,
    "out:Running Cost over RSP/MSEK": 1.40927,
    "out:LCP/MSEK": 1.47922,
    "out:ROI% old": 20.427617,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 228.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2152.000399,
    "out:Return %": 18,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 29.328405,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3039.991968,
    "out:PV (% sold (El))": 15.128935,
    "out:PV (kWh self-consumed)": 17053.901309,
    "out:PV (ratio sold/self-consumed)": 0.178258,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236432.52535,
    "out:EL kWh savings": -46095.198977,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148802.12,
    "out:DH kr savings": 236432.52534960525,
    "out:El kr savings": -92190.39795346493,
    "out:El kr sold": 4559.987952574953,
    "out:El kr saved": 34107.802618,
    "out:El kr return": 38667.79057057496,
    "out:% solar/total": 116,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 22495.76878,
    "out:% savings (space heating)": 89.573766,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 12.07,
    "out:Etvv": 0,
    "out:Ef": 30.81,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3440580.2608956154,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -3.506028,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.531748,
    "out:Bought CO2": 4.02572,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.000173,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.035073,
    "out:Electricity (inc PV)": 28.660812,
    "out:Total Energy Use Pre PV": 55.035073,
    "out:Total Energy Use Post PV": 43.660812,
    "out:Primary Energy": 63.942971,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.614285,
    "out:CO2 Operational/m2": -34.135015,
    "out:Total CO2/m2": -4.52073,
    "out:Total CO2 (tons)": -8.91488,
    "out:Klimatpaverkan": -103.847052,
    "out:Initial Cost/MSEK": 3.605859146354366,
    "out:Running cost/(Apt SEK y)": 5157.235294,
    "out:Running Cost over RSP/MSEK": 1.37221,
    "out:LCP/MSEK": 1.351001,
    "out:ROI% old": 19.638118,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 213.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 1998.281912,
    "out:Return %": 18,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 30.295573,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16982.335628,
    "out:PV (% sold (El))": 42.257455,
    "out:PV (kWh self-consumed)": 23205.450926,
    "out:PV (ratio sold/self-consumed)": 0.731825,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 229347.951264,
    "out:EL kWh savings": -41847.443683,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171126.57,
    "out:DH kr savings": 229347.95126405076,
    "out:El kr savings": -83694.88736634515,
    "out:El kr sold": 25473.50344244281,
    "out:El kr saved": 46410.901852,
    "out:El kr return": 71884.40529444281,
    "out:% solar/total": 158,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 29580.342866,
    "out:% savings (space heating)": 86.290241,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 15.88,
    "out:Etvv": 0,
    "out:Ef": 28.66,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3605859.146354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": -39.534775,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -43.275132,
    "out:Bought CO2": 3.740357,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.236374,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.612759,
    "out:Electricity (inc PV)": 28.336423,
    "out:Total Energy Use Pre PV": 51.612759,
    "out:Total Energy Use Post PV": 40.336423,
    "out:Primary Energy": 61.155207,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.614285,
    "out:CO2 Operational/m2": -35.678637,
    "out:Total CO2/m2": -6.064352,
    "out:Total CO2 (tons)": -11.958903,
    "out:Klimatpaverkan": -106.891075,
    "out:Initial Cost/MSEK": 3.6551591463543662,
    "out:Running cost/(Apt SEK y)": 4585.117647,
    "out:Running Cost over RSP/MSEK": 1.2202,
    "out:LCP/MSEK": 1.453711,
    "out:ROI% old": 19.967354,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 245,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 1974.195014,
    "out:Return %": 18,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 29.873259,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17143.18777,
    "out:PV (% sold (El))": 42.657706,
    "out:PV (kWh self-consumed)": 23044.598784,
    "out:PV (ratio sold/self-consumed)": 0.743913,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 234798.163207,
    "out:EL kWh savings": -41207.748538,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178097.45,
    "out:DH kr savings": 234798.16320712387,
    "out:El kr savings": -82415.49707638442,
    "out:El kr sold": 25714.781655480754,
    "out:El kr saved": 46089.197568,
    "out:El kr return": 71803.97922348075,
    "out:% solar/total": 157,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 24130.130923,
    "out:% savings (space heating)": 88.816279,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 13.05,
    "out:Etvv": 0,
    "out:Ef": 28.34,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3655159.146354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.319808,
    "out:EL CO2": -39.998445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -43.693713,
    "out:Bought CO2": 3.695268,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.094842,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.513415,
    "out:Electricity (inc PV)": 28.186041,
    "out:Total Energy Use Pre PV": 53.513415,
    "out:Total Energy Use Post PV": 42.186041,
    "out:Primary Energy": 62.262773,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.281628,
    "out:CO2 Operational/m2": -35.068104,
    "out:Total CO2/m2": -4.786476,
    "out:Total CO2 (tons)": -9.438931,
    "out:Klimatpaverkan": -104.371103,
    "out:Initial Cost/MSEK": 3.765235146354366,
    "out:Running cost/(Apt SEK y)": 4924.470588,
    "out:Running Cost over RSP/MSEK": 1.31048,
    "out:LCP/MSEK": 1.253355,
    "out:ROI% old": 19.04108,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 228.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 1968.534537,
    "out:Return %": 17,
    "out:Return/kSEK/y": 642,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 29.773915,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17181.144806,
    "out:PV (% sold (El))": 42.752155,
    "out:PV (kWh self-consumed)": 23006.641748,
    "out:PV (ratio sold/self-consumed)": 0.746791,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231133.264099,
    "out:EL kWh savings": -40911.195217,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175082.59,
    "out:DH kr savings": 231133.2640992585,
    "out:El kr savings": -81822.39043409732,
    "out:El kr sold": 25771.717209375725,
    "out:El kr saved": 46013.283496,
    "out:El kr return": 71785.00070537571,
    "out:% solar/total": 157,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 27795.030031,
    "out:% savings (space heating)": 87.117689,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 14.82,
    "out:Etvv": 0,
    "out:Ef": 28.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3765235.146354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": -40.10788,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -43.792551,
    "out:Bought CO2": 3.684671,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.40759,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.067905,
    "out:Electricity (inc PV)": 27.841805,
    "out:Total Energy Use Pre PV": 50.067905,
    "out:Total Energy Use Post PV": 38.841805,
    "out:Primary Energy": 59.504972,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.281628,
    "out:CO2 Operational/m2": -36.640578,
    "out:Total CO2/m2": -6.35895,
    "out:Total CO2 (tons)": -12.53985,
    "out:Klimatpaverkan": -107.472022,
    "out:Initial Cost/MSEK": 3.814535146354366,
    "out:Running cost/(Apt SEK y)": 4349.823529,
    "out:Running Cost over RSP/MSEK": 1.1578,
    "out:LCP/MSEK": 1.356735,
    "out:ROI% old": 19.366786,
    "out:Payback discounted": 6,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 253.8,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 1943.176556,
    "out:Return %": 17,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 29.328405,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17352.186475,
    "out:PV (% sold (El))": 43.177761,
    "out:PV (kWh self-consumed)": 22835.60008,
    "out:PV (ratio sold/self-consumed)": 0.759874,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236432.52535,
    "out:EL kWh savings": -40232.361786,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181996.08,
    "out:DH kr savings": 236432.52534960525,
    "out:El kr savings": -80464.72357161154,
    "out:El kr sold": 26028.279712624644,
    "out:El kr saved": 45671.20016,
    "out:El kr return": 71699.47987262465,
    "out:% solar/total": 156,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 22495.76878,
    "out:% savings (space heating)": 89.573766,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 12.07,
    "out:Etvv": 0,
    "out:Ef": 27.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3814535.146354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -40.600402,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.237596,
    "out:Bought CO2": 3.637194,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 118.44,
    "out:Total Energy Use Post PV": 118.44,
    "out:Primary Energy": 100.392374,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.408091,
    "out:CO2 Operational/m2": 40.940364,
    "out:Total CO2/m2": 53.348455,
    "out:Total CO2 (tons)": 105.203159,
    "out:Klimatpaverkan": 10.270987,
    "out:Initial Cost/MSEK": 1.777875,
    "out:Running cost/(Apt SEK y)": 20305.794118,
    "out:Running Cost over RSP/MSEK": 5.39743,
    "out:LCP/MSEK": -0.846235,
    "out:ROI% old": 7.485983,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 7,
    "out:Return/kSEK/y": 119,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 39547.967314,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 39547.97,
    "out:DH kr savings": 39547.96731424139,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1777875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 114.44,
    "out:Total Energy Use Post PV": 114.44,
    "out:Primary Energy": 97.445387,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.408091,
    "out:CO2 Operational/m2": 39.500428,
    "out:Total CO2/m2": 51.908519,
    "out:Total CO2 (tons)": 102.363605,
    "out:Klimatpaverkan": 7.431433,
    "out:Initial Cost/MSEK": 1.827175,
    "out:Running cost/(Apt SEK y)": 19605.147059,
    "out:Running Cost over RSP/MSEK": 5.2111,
    "out:LCP/MSEK": -0.709205,
    "out:ROI% old": 8.740816,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 21.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 143,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 47019.842025,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 47019.84,
    "out:DH kr savings": 47019.842024985424,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1827175,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 115.44,
    "out:Total Energy Use Post PV": 115.44,
    "out:Primary Energy": 97.767374,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.075434,
    "out:CO2 Operational/m2": 39.860412,
    "out:Total CO2/m2": 52.935846,
    "out:Total CO2 (tons)": 104.389494,
    "out:Klimatpaverkan": 9.457322,
    "out:Initial Cost/MSEK": 1.937251,
    "out:Running cost/(Apt SEK y)": 19780.323529,
    "out:Running Cost over RSP/MSEK": 5.25769,
    "out:LCP/MSEK": -0.865871,
    "out:ROI% old": 7.900591,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 20,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 7,
    "out:Return/kSEK/y": 137,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 46023.029689,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46023.03,
    "out:DH kr savings": 46023.0296885604,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1937251,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 111.44,
    "out:Primary Energy": 94.820387,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.075434,
    "out:CO2 Operational/m2": 38.420476,
    "out:Total CO2/m2": 51.49591,
    "out:Total CO2 (tons)": 101.54994,
    "out:Klimatpaverkan": 6.617768,
    "out:Initial Cost/MSEK": 1.986551,
    "out:Running cost/(Apt SEK y)": 19079.676471,
    "out:Running Cost over RSP/MSEK": 5.07136,
    "out:LCP/MSEK": -0.728841,
    "out:ROI% old": 9.044462,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 53494.904399,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 53494.9,
    "out:DH kr savings": 53494.90439930443,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1986551,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 118.44,
    "out:Total Energy Use Post PV": 115.635375,
    "out:Primary Energy": 95.344049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.669894,
    "out:CO2 Operational/m2": 3.102455,
    "out:Total CO2/m2": 26.772349,
    "out:Total CO2 (tons)": 52.795075,
    "out:Klimatpaverkan": -42.137097,
    "out:Initial Cost/MSEK": 2.15182986799625,
    "out:Running cost/(Apt SEK y)": 19337.970588,
    "out:Running Cost over RSP/MSEK": 5.1471,
    "out:LCP/MSEK": -0.96986,
    "out:ROI% old": 7.846943,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 7,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 39547.967314,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 72454.17,
    "out:DH kr savings": 39547.96731424139,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2151829.8679962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 114.44,
    "out:Total Energy Use Post PV": 111.635375,
    "out:Primary Energy": 92.397062,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.669894,
    "out:CO2 Operational/m2": 1.662519,
    "out:Total CO2/m2": 25.332413,
    "out:Total CO2 (tons)": 49.955521,
    "out:Klimatpaverkan": -44.976651,
    "out:Initial Cost/MSEK": 2.2011298679962503,
    "out:Running cost/(Apt SEK y)": 18637.323529,
    "out:Running Cost over RSP/MSEK": 4.96077,
    "out:LCP/MSEK": -0.83283,
    "out:ROI% old": 8.880504,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 23.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 47019.842025,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79926.04,
    "out:DH kr savings": 47019.842024985424,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2201129.8679962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 115.44,
    "out:Total Energy Use Post PV": 112.635375,
    "out:Primary Energy": 92.719049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.337237,
    "out:CO2 Operational/m2": 2.022503,
    "out:Total CO2/m2": 26.35974,
    "out:Total CO2 (tons)": 51.98141,
    "out:Klimatpaverkan": -42.950762,
    "out:Initial Cost/MSEK": 2.31120586799625,
    "out:Running cost/(Apt SEK y)": 18812.5,
    "out:Running Cost over RSP/MSEK": 5.00735,
    "out:LCP/MSEK": -0.989486,
    "out:ROI% old": 8.169638,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 7,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 46023.029689,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78929.23,
    "out:DH kr savings": 46023.0296885604,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2311205.8679962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 108.635375,
    "out:Primary Energy": 89.772062,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.337237,
    "out:CO2 Operational/m2": 0.582567,
    "out:Total CO2/m2": 24.919804,
    "out:Total CO2 (tons)": 49.141856,
    "out:Klimatpaverkan": -45.790316,
    "out:Initial Cost/MSEK": 2.3605058679962503,
    "out:Running cost/(Apt SEK y)": 18111.852941,
    "out:Running Cost over RSP/MSEK": 4.82102,
    "out:LCP/MSEK": -0.852456,
    "out:ROI% old": 9.126676,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 26.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 194,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53494.904399,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 86401.11,
    "out:DH kr savings": 53494.90439930443,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2360505.8679962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 118.44,
    "out:Total Energy Use Post PV": 115.377795,
    "out:Primary Energy": 94.880405,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.931697,
    "out:CO2 Operational/m2": -47.54607,
    "out:Total CO2/m2": -12.614373,
    "out:Total CO2 (tons)": -24.875545,
    "out:Klimatpaverkan": -119.807717,
    "out:Initial Cost/MSEK": 2.525784753455,
    "out:Running cost/(Apt SEK y)": 18444,
    "out:Running Cost over RSP/MSEK": 4.91608,
    "out:LCP/MSEK": -1.112795,
    "out:ROI% old": 7.991802,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 20,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 7,
    "out:Return/kSEK/y": 182,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 39547.967314,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102848.98,
    "out:DH kr savings": 39547.96731424139,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2525784.753455,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 114.44,
    "out:Total Energy Use Post PV": 111.377795,
    "out:Primary Energy": 91.933418,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.931697,
    "out:CO2 Operational/m2": -48.986006,
    "out:Total CO2/m2": -14.054309,
    "out:Total CO2 (tons)": -27.715099,
    "out:Klimatpaverkan": -122.647271,
    "out:Initial Cost/MSEK": 2.575084753455,
    "out:Running cost/(Apt SEK y)": 17743.352941,
    "out:Running Cost over RSP/MSEK": 4.72976,
    "out:LCP/MSEK": -0.975775,
    "out:ROI% old": 8.87244,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 47019.842025,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110320.86,
    "out:DH kr savings": 47019.842024985424,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2575084.753455,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 115.44,
    "out:Total Energy Use Post PV": 112.377795,
    "out:Primary Energy": 92.255405,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.59904,
    "out:CO2 Operational/m2": -48.626022,
    "out:Total CO2/m2": -13.026982,
    "out:Total CO2 (tons)": -25.68921,
    "out:Klimatpaverkan": -120.621382,
    "out:Initial Cost/MSEK": 2.6851607534549995,
    "out:Running cost/(Apt SEK y)": 17918.529412,
    "out:Running Cost over RSP/MSEK": 4.77634,
    "out:LCP/MSEK": -1.132431,
    "out:ROI% old": 8.260905,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 23.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 7,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 46023.029689,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109324.04,
    "out:DH kr savings": 46023.0296885604,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2685160.7534549995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 108.377795,
    "out:Primary Energy": 89.308418,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.59904,
    "out:CO2 Operational/m2": -50.065958,
    "out:Total CO2/m2": -14.466918,
    "out:Total CO2 (tons)": -28.528764,
    "out:Klimatpaverkan": -123.460936,
    "out:Initial Cost/MSEK": 2.7344607534549996,
    "out:Running cost/(Apt SEK y)": 17217.882353,
    "out:Running Cost over RSP/MSEK": 4.59001,
    "out:LCP/MSEK": -0.995401,
    "out:ROI% old": 9.085416,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 27.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53494.904399,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116795.92,
    "out:DH kr savings": 53494.90439930443,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2734460.7534549995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 114.44,
    "out:Total Energy Use Post PV": 114.44,
    "out:Primary Energy": 96.892374,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.408091,
    "out:CO2 Operational/m2": 39.500428,
    "out:Total CO2/m2": 51.908519,
    "out:Total CO2 (tons)": 102.363605,
    "out:Klimatpaverkan": 7.431433,
    "out:Initial Cost/MSEK": 1.867611625,
    "out:Running cost/(Apt SEK y)": 19605.147059,
    "out:Running Cost over RSP/MSEK": 5.2111,
    "out:LCP/MSEK": -0.749642,
    "out:ROI% old": 8.551564,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 21.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 143,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 48181.383813,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 48181.38,
    "out:DH kr savings": 48181.383813333385,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1867611.625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 110.44,
    "out:Total Energy Use Post PV": 110.44,
    "out:Primary Energy": 93.945387,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.408091,
    "out:CO2 Operational/m2": 38.060492,
    "out:Total CO2/m2": 50.468583,
    "out:Total CO2 (tons)": 99.524051,
    "out:Klimatpaverkan": 4.591879,
    "out:Initial Cost/MSEK": 1.916911625,
    "out:Running cost/(Apt SEK y)": 18904.529412,
    "out:Running Cost over RSP/MSEK": 5.02478,
    "out:LCP/MSEK": -0.612622,
    "out:ROI% old": 9.720174,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 9,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 55653.258524,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55653.26,
    "out:DH kr savings": 55653.25852407743,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1916911.625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 111.44,
    "out:Primary Energy": 94.792374,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.075434,
    "out:CO2 Operational/m2": 38.420476,
    "out:Total CO2/m2": 51.49591,
    "out:Total CO2 (tons)": 101.54994,
    "out:Klimatpaverkan": 6.617768,
    "out:Initial Cost/MSEK": 2.026987625,
    "out:Running cost/(Apt SEK y)": 19079.676471,
    "out:Running Cost over RSP/MSEK": 5.07136,
    "out:LCP/MSEK": -0.769278,
    "out:ROI% old": 8.864033,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 53361.433713,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53361.43,
    "out:DH kr savings": 53361.433712788596,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2026987.6249999998,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 107.44,
    "out:Primary Energy": 91.845387,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.075434,
    "out:CO2 Operational/m2": 36.98054,
    "out:Total CO2/m2": 50.055974,
    "out:Total CO2 (tons)": 98.710386,
    "out:Klimatpaverkan": 3.778214,
    "out:Initial Cost/MSEK": 2.076287625,
    "out:Running cost/(Apt SEK y)": 18379.029412,
    "out:Running Cost over RSP/MSEK": 4.88503,
    "out:LCP/MSEK": -0.632248,
    "out:ROI% old": 9.93559,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 9,
    "out:Return/kSEK/y": 185,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 60833.308424,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60833.31,
    "out:DH kr savings": 60833.30842353263,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2076287.625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 114.44,
    "out:Total Energy Use Post PV": 111.635375,
    "out:Primary Energy": 91.844049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.669894,
    "out:CO2 Operational/m2": 1.662519,
    "out:Total CO2/m2": 25.332413,
    "out:Total CO2 (tons)": 49.955521,
    "out:Klimatpaverkan": -44.976651,
    "out:Initial Cost/MSEK": 2.24156649299625,
    "out:Running cost/(Apt SEK y)": 18637.323529,
    "out:Running Cost over RSP/MSEK": 4.96077,
    "out:LCP/MSEK": -0.873266,
    "out:ROI% old": 8.720305,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 23.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 48181.383813,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 81087.59,
    "out:DH kr savings": 48181.383813333385,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2241566.4929962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 110.44,
    "out:Total Energy Use Post PV": 107.635375,
    "out:Primary Energy": 88.897062,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.669894,
    "out:CO2 Operational/m2": 0.222583,
    "out:Total CO2/m2": 23.892477,
    "out:Total CO2 (tons)": 47.115967,
    "out:Klimatpaverkan": -47.816205,
    "out:Initial Cost/MSEK": 2.2908664929962503,
    "out:Running cost/(Apt SEK y)": 17936.705882,
    "out:Running Cost over RSP/MSEK": 4.77444,
    "out:LCP/MSEK": -0.736236,
    "out:ROI% old": 9.694585,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 27.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 55653.258524,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88559.46,
    "out:DH kr savings": 55653.25852407743,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2290866.4929962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 108.635375,
    "out:Primary Energy": 89.744049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.337237,
    "out:CO2 Operational/m2": 0.582567,
    "out:Total CO2/m2": 24.919804,
    "out:Total CO2 (tons)": 49.141856,
    "out:Klimatpaverkan": -45.790316,
    "out:Initial Cost/MSEK": 2.40094249299625,
    "out:Running cost/(Apt SEK y)": 18111.852941,
    "out:Running Cost over RSP/MSEK": 4.82102,
    "out:LCP/MSEK": -0.892892,
    "out:ROI% old": 8.972964,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 26.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 194,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53361.433713,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86267.64,
    "out:DH kr savings": 53361.433712788596,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2400942.4929962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 104.635375,
    "out:Primary Energy": 86.797062,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.337237,
    "out:CO2 Operational/m2": -0.857369,
    "out:Total CO2/m2": 23.479868,
    "out:Total CO2 (tons)": 46.302302,
    "out:Klimatpaverkan": -48.62987,
    "out:Initial Cost/MSEK": 2.4502424929962503,
    "out:Running cost/(Apt SEK y)": 17411.205882,
    "out:Running Cost over RSP/MSEK": 4.6347,
    "out:LCP/MSEK": -0.755872,
    "out:ROI% old": 9.878731,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 31.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 60833.308424,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93739.51,
    "out:DH kr savings": 60833.30842353263,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2450242.4929962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 114.44,
    "out:Total Energy Use Post PV": 111.377795,
    "out:Primary Energy": 91.380405,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.931697,
    "out:CO2 Operational/m2": -48.986006,
    "out:Total CO2/m2": -14.054309,
    "out:Total CO2 (tons)": -27.715099,
    "out:Klimatpaverkan": -122.647271,
    "out:Initial Cost/MSEK": 2.615521378455,
    "out:Running cost/(Apt SEK y)": 17743.352941,
    "out:Running Cost over RSP/MSEK": 4.72976,
    "out:LCP/MSEK": -1.016211,
    "out:ROI% old": 8.73527,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 48181.383813,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111482.4,
    "out:DH kr savings": 48181.383813333385,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2615521.378455,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 110.44,
    "out:Total Energy Use Post PV": 107.377795,
    "out:Primary Energy": 88.433418,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.931697,
    "out:CO2 Operational/m2": -50.425942,
    "out:Total CO2/m2": -15.494245,
    "out:Total CO2 (tons)": -30.554653,
    "out:Klimatpaverkan": -125.486825,
    "out:Initial Cost/MSEK": 2.664821378455,
    "out:Running cost/(Apt SEK y)": 17042.735294,
    "out:Running Cost over RSP/MSEK": 4.54343,
    "out:LCP/MSEK": -0.879181,
    "out:ROI% old": 9.572553,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 230,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 55653.258524,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118954.27,
    "out:DH kr savings": 55653.25852407743,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2664821.378455,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.357624,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 108.377795,
    "out:Primary Energy": 89.280405,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.59904,
    "out:CO2 Operational/m2": -50.065958,
    "out:Total CO2/m2": -14.466918,
    "out:Total CO2 (tons)": -28.528764,
    "out:Klimatpaverkan": -123.460936,
    "out:Initial Cost/MSEK": 2.774897378455,
    "out:Running cost/(Apt SEK y)": 17217.882353,
    "out:Running Cost over RSP/MSEK": 4.59001,
    "out:LCP/MSEK": -1.035837,
    "out:ROI% old": 8.953021,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 27.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53361.433713,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116662.45,
    "out:DH kr savings": 53361.433712788596,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 176213.24432,
    "out:% savings (space heating)": 18.329507,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.36,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2774897.378455,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.568641,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 104.377795,
    "out:Primary Energy": 86.333418,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.59904,
    "out:CO2 Operational/m2": -51.505894,
    "out:Total CO2/m2": -15.906854,
    "out:Total CO2 (tons)": -31.368318,
    "out:Klimatpaverkan": -126.30049,
    "out:Initial Cost/MSEK": 2.824197378455,
    "out:Running cost/(Apt SEK y)": 16517.235294,
    "out:Running Cost over RSP/MSEK": 4.40368,
    "out:LCP/MSEK": -0.898807,
    "out:ROI% old": 9.739253,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 60833.308424,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124134.32,
    "out:DH kr savings": 60833.30842353263,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 168741.36961,
    "out:% savings (space heating)": 21.792537,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.57,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2824197.378455,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.238138,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.833283,
    "out:Electricity (inc PV)": 29.220044,
    "out:Total Energy Use Pre PV": 76.833283,
    "out:Total Energy Use Post PV": 77.220044,
    "out:Primary Energy": 91.540833,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.238592,
    "out:CO2 Operational/m2": 21.085456,
    "out:Total CO2/m2": 38.324048,
    "out:Total CO2 (tons)": 75.575027,
    "out:Klimatpaverkan": -19.357145,
    "out:Initial Cost/MSEK": 3.6288677682456165,
    "out:Running cost/(Apt SEK y)": 11752.352941,
    "out:Running Cost over RSP/MSEK": 3.11507,
    "out:LCP/MSEK": -0.414868,
    "out:ROI% old": 12.65251,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 79.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2018.32981,
    "out:Return %": 11,
    "out:Return/kSEK/y": 410,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.093783,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 163802.680495,
    "out:EL kWh savings": -42950.249251,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77902.18,
    "out:DH kr savings": 163802.68049492277,
    "out:El kr savings": -85900.49850185003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 95125.613635,
    "out:% savings (space heating)": 55.911624,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 50.07,
    "out:Etvv": 0,
    "out:Ef": 29.22,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3628867.7682456165,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": 3.806224,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.806224,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.666475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.767429,
    "out:Electricity (inc PV)": 29.159784,
    "out:Total Energy Use Pre PV": 73.767429,
    "out:Total Energy Use Post PV": 74.159784,
    "out:Primary Energy": 88.640047,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.238592,
    "out:CO2 Operational/m2": 19.996811,
    "out:Total CO2/m2": 37.235403,
    "out:Total CO2 (tons)": 73.428219,
    "out:Klimatpaverkan": -21.503953,
    "out:Initial Cost/MSEK": 3.6781677682456166,
    "out:Running cost/(Apt SEK y)": 11219.235294,
    "out:Running Cost over RSP/MSEK": 2.97331,
    "out:LCP/MSEK": -0.322408,
    "out:ROI% old": 13.033508,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2013.72003,
    "out:Return %": 12,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.027929,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 170846.000338,
    "out:EL kWh savings": -42831.416524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85183.17,
    "out:DH kr savings": 170846.00033809236,
    "out:El kr savings": -85662.83304811076,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 88082.293792,
    "out:% savings (space heating)": 59.176029,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 46.48,
    "out:Etvv": 0,
    "out:Ef": 29.16,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3678167.7682456165,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.19928,
    "out:EL CO2": 3.797531,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.797531,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.327974,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.417121,
    "out:Electricity (inc PV)": 28.75286,
    "out:Total Energy Use Pre PV": 74.417121,
    "out:Total Energy Use Post PV": 74.75286,
    "out:Primary Energy": 88.982342,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.905935,
    "out:CO2 Operational/m2": 20.310551,
    "out:Total CO2/m2": 38.216486,
    "out:Total CO2 (tons)": 75.362915,
    "out:Klimatpaverkan": -19.569258,
    "out:Initial Cost/MSEK": 3.788243768245616,
    "out:Running cost/(Apt SEK y)": 11353.735294,
    "out:Running Cost over RSP/MSEK": 3.00921,
    "out:LCP/MSEK": -0.468384,
    "out:ROI% old": 12.519409,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 84,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1989.19847,
    "out:Return %": 11,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.677621,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 167569.524121,
    "out:EL kWh savings": -42028.96235,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83511.6,
    "out:DH kr savings": 167569.52412068145,
    "out:El kr savings": -84057.92469933207,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 91358.770009,
    "out:% savings (space heating)": 57.657463,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 47.86,
    "out:Etvv": 0,
    "out:Ef": 28.75,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3788243.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": 3.751287,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.751287,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.779123,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.344354,
    "out:Electricity (inc PV)": 28.685803,
    "out:Total Energy Use Pre PV": 71.344354,
    "out:Total Energy Use Post PV": 71.685803,
    "out:Primary Energy": 86.086765,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.905935,
    "out:CO2 Operational/m2": 19.220993,
    "out:Total CO2/m2": 37.126928,
    "out:Total CO2 (tons)": 73.214306,
    "out:Klimatpaverkan": -21.717866,
    "out:Initial Cost/MSEK": 3.837543768245616,
    "out:Running cost/(Apt SEK y)": 10819.823529,
    "out:Running Cost over RSP/MSEK": 2.86725,
    "out:LCP/MSEK": -0.375724,
    "out:ROI% old": 12.887038,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1984.10478,
    "out:Return %": 12,
    "out:Return/kSEK/y": 442,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.604854,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 174567.858697,
    "out:EL kWh savings": -41896.725938,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 90774.41,
    "out:DH kr savings": 174567.85869725049,
    "out:El kr savings": -83793.45187604305,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 84360.435433,
    "out:% savings (space heating)": 60.901019,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 44.3,
    "out:Etvv": 0,
    "out:Ef": 28.69,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3837543.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": 3.741681,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.741681,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.238138,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.833283,
    "out:Electricity (inc PV)": 21.783962,
    "out:Total Energy Use Pre PV": 76.833283,
    "out:Total Energy Use Post PV": 69.783962,
    "out:Primary Energy": 78.155886,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.500395,
    "out:CO2 Operational/m2": 6.140675,
    "out:Total CO2/m2": 34.64107,
    "out:Total CO2 (tons)": 68.312194,
    "out:Klimatpaverkan": -26.619978,
    "out:Initial Cost/MSEK": 4.002822636241866,
    "out:Running cost/(Apt SEK y)": 10651.852941,
    "out:Running Cost over RSP/MSEK": 2.83021,
    "out:LCP/MSEK": -0.503963,
    "out:ROI% old": 12.487117,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 97.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1516.923489,
    "out:Return %": 11,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.093783,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5542.636908,
    "out:PV (% sold (El))": 27.583688,
    "out:PV (kWh self-consumed)": 14551.256369,
    "out:PV (ratio sold/self-consumed)": 0.380904,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163802.680495,
    "out:EL kWh savings": -28286.294699,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115544.05,
    "out:DH kr savings": 163802.68049492277,
    "out:El kr savings": -56572.58939842334,
    "out:El kr sold": 8313.955362749542,
    "out:El kr saved": 29102.512738,
    "out:El kr return": 37416.468100749546,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 95125.613635,
    "out:% savings (space heating)": 55.911624,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 50.07,
    "out:Etvv": 0,
    "out:Ef": 21.78,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4002822.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -11.138557,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -13.977402,
    "out:Bought CO2": 2.838845,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.666475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.767429,
    "out:Electricity (inc PV)": 21.732561,
    "out:Total Energy Use Pre PV": 73.767429,
    "out:Total Energy Use Post PV": 66.732561,
    "out:Primary Energy": 75.271046,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.500395,
    "out:CO2 Operational/m2": 5.003366,
    "out:Total CO2/m2": 33.503761,
    "out:Total CO2 (tons)": 66.069421,
    "out:Klimatpaverkan": -28.862752,
    "out:Initial Cost/MSEK": 4.052122636241866,
    "out:Running cost/(Apt SEK y)": 10119.029412,
    "out:Running Cost over RSP/MSEK": 2.68853,
    "out:LCP/MSEK": -0.411583,
    "out:ROI% old": 12.834684,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 1512.948886,
    "out:Return %": 11,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.027929,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5562.019052,
    "out:PV (% sold (El))": 27.680146,
    "out:PV (kWh self-consumed)": 14531.874226,
    "out:PV (ratio sold/self-consumed)": 0.382746,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 170846.000338,
    "out:EL kWh savings": -28184.931921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122819.17,
    "out:DH kr savings": 170846.00033809236,
    "out:El kr savings": -56369.86384270392,
    "out:El kr sold": 8343.028577288156,
    "out:El kr saved": 29063.748452,
    "out:El kr return": 37406.777029288154,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 88082.293792,
    "out:% savings (space heating)": 59.176029,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 46.48,
    "out:Etvv": 0,
    "out:Ef": 21.73,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4052122.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.19928,
    "out:EL CO2": -11.195914,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.027337,
    "out:Bought CO2": 2.831423,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.327974,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.417121,
    "out:Electricity (inc PV)": 21.385932,
    "out:Total Energy Use Pre PV": 74.417121,
    "out:Total Energy Use Post PV": 67.385932,
    "out:Primary Energy": 75.721872,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.167738,
    "out:CO2 Operational/m2": 5.055972,
    "out:Total CO2/m2": 34.22371,
    "out:Total CO2 (tons)": 67.48916,
    "out:Klimatpaverkan": -27.443012,
    "out:Initial Cost/MSEK": 4.162198636241866,
    "out:Running cost/(Apt SEK y)": 10255.088235,
    "out:Running Cost over RSP/MSEK": 2.72482,
    "out:LCP/MSEK": -0.557949,
    "out:ROI% old": 12.370694,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1491.834776,
    "out:Return %": 11,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.677621,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5665.89987,
    "out:PV (% sold (El))": 28.197123,
    "out:PV (kWh self-consumed)": 14427.993408,
    "out:PV (ratio sold/self-consumed)": 0.392702,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 167569.524121,
    "out:EL kWh savings": -27501.379494,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121065.61,
    "out:DH kr savings": 167569.52412068145,
    "out:El kr savings": -55002.758987672474,
    "out:El kr sold": 8498.849804534122,
    "out:El kr saved": 28855.986816,
    "out:El kr return": 37354.83662053412,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 91358.770009,
    "out:% savings (space heating)": 57.657463,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 47.86,
    "out:Etvv": 0,
    "out:Ef": 21.39,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4162198.6362418663,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -11.503292,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.295297,
    "out:Bought CO2": 2.792005,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.779123,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.344354,
    "out:Electricity (inc PV)": 21.328922,
    "out:Total Energy Use Pre PV": 71.344354,
    "out:Total Energy Use Post PV": 64.328922,
    "out:Primary Energy": 72.84438,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.167738,
    "out:CO2 Operational/m2": 3.911709,
    "out:Total CO2/m2": 33.079447,
    "out:Total CO2 (tons)": 65.232673,
    "out:Klimatpaverkan": -29.699499,
    "out:Initial Cost/MSEK": 4.211498636241866,
    "out:Running cost/(Apt SEK y)": 9721.470588,
    "out:Running Cost over RSP/MSEK": 2.58294,
    "out:LCP/MSEK": -0.465369,
    "out:ROI% old": 12.70715,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1487.455031,
    "out:Return %": 11,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.604854,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5687.625928,
    "out:PV (% sold (El))": 28.305246,
    "out:PV (kWh self-consumed)": 14406.267349,
    "out:PV (ratio sold/self-consumed)": 0.394802,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174567.858697,
    "out:EL kWh savings": -27388.955767,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128321.39,
    "out:DH kr savings": 174567.85869725049,
    "out:El kr savings": -54777.91153467423,
    "out:El kr sold": 8531.438892299122,
    "out:El kr saved": 28812.534698,
    "out:El kr return": 37343.97359029912,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 84360.435433,
    "out:% savings (space heating)": 60.901019,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 44.3,
    "out:Etvv": 0,
    "out:Ef": 21.33,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4211498.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -11.567603,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.351432,
    "out:Bought CO2": 2.783829,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.238138,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.833283,
    "out:Electricity (inc PV)": 19.736823,
    "out:Total Energy Use Pre PV": 76.833283,
    "out:Total Energy Use Post PV": 67.736823,
    "out:Primary Energy": 74.471035,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.762198,
    "out:CO2 Operational/m2": -35.612393,
    "out:Total CO2/m2": 4.149805,
    "out:Total CO2 (tons)": 8.183416,
    "out:Klimatpaverkan": -86.748756,
    "out:Initial Cost/MSEK": 4.376777521700616,
    "out:Running cost/(Apt SEK y)": 9706.970588,
    "out:Running Cost over RSP/MSEK": 2.58577,
    "out:LCP/MSEK": -0.633478,
    "out:ROI% old": 12.218056,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1373.670587,
    "out:Return %": 11,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.093783,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21665.558882,
    "out:PV (% sold (El))": 53.910804,
    "out:PV (kWh self-consumed)": 18522.227672,
    "out:PV (ratio sold/self-consumed)": 1.169706,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163802.680495,
    "out:EL kWh savings": -24249.336358,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147802.35,
    "out:DH kr savings": 163802.68049492277,
    "out:El kr savings": -48498.6727156757,
    "out:El kr sold": 32498.338323596865,
    "out:El kr saved": 37044.455344,
    "out:El kr return": 69542.79366759687,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 95125.613635,
    "out:% savings (space heating)": 55.911624,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 50.07,
    "out:Etvv": 0,
    "out:Ef": 19.74,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4376777.521700616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -52.891625,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.463654,
    "out:Bought CO2": 2.572029,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.666475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.767429,
    "out:Electricity (inc PV)": 19.690631,
    "out:Total Energy Use Pre PV": 73.767429,
    "out:Total Energy Use Post PV": 64.690631,
    "out:Primary Energy": 71.595572,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.762198,
    "out:CO2 Operational/m2": -36.778675,
    "out:Total CO2/m2": 2.983523,
    "out:Total CO2 (tons)": 5.883508,
    "out:Klimatpaverkan": -89.048665,
    "out:Initial Cost/MSEK": 4.426077521700616,
    "out:Running cost/(Apt SEK y)": 9174.294118,
    "out:Running Cost over RSP/MSEK": 2.44414,
    "out:LCP/MSEK": -0.541148,
    "out:ROI% old": 12.539094,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 112.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 1370.10424,
    "out:Return %": 11,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 19.027929,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21696.12066,
    "out:PV (% sold (El))": 53.986852,
    "out:PV (kWh self-consumed)": 18491.665895,
    "out:PV (ratio sold/self-consumed)": 1.173292,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 170846.000338,
    "out:EL kWh savings": -24158.245729,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155073.69,
    "out:DH kr savings": 170846.00033809236,
    "out:El kr savings": -48316.49145714385,
    "out:El kr sold": 32544.180989820357,
    "out:El kr saved": 36983.33179,
    "out:El kr return": 69527.51277982036,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 88082.293792,
    "out:% savings (space heating)": 59.176029,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 46.48,
    "out:Etvv": 0,
    "out:Ef": 19.69,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4426077.521700616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.19928,
    "out:EL CO2": -52.977955,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.543319,
    "out:Bought CO2": 2.565364,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.779123,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.344354,
    "out:Electricity (inc PV)": 19.327924,
    "out:Total Energy Use Pre PV": 71.344354,
    "out:Total Energy Use Post PV": 62.327924,
    "out:Primary Energy": 69.242583,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.429541,
    "out:CO2 Operational/m2": -38.055462,
    "out:Total CO2/m2": 2.374079,
    "out:Total CO2 (tons)": 4.681684,
    "out:Klimatpaverkan": -90.250488,
    "out:Initial Cost/MSEK": 4.585453521700615,
    "out:Running cost/(Apt SEK y)": 8777.794118,
    "out:Running Cost over RSP/MSEK": 2.33883,
    "out:LCP/MSEK": -0.595214,
    "out:ROI% old": 12.431361,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1347.226637,
    "out:Return %": 11,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.604854,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21893.409185,
    "out:PV (% sold (El))": 54.477768,
    "out:PV (kWh self-consumed)": 18294.37737,
    "out:PV (ratio sold/self-consumed)": 1.196729,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 174567.858697,
    "out:EL kWh savings": -23442.987483,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160522,
    "out:DH kr savings": 174567.85869725049,
    "out:El kr savings": -46885.97496644665,
    "out:El kr sold": 32840.11377761052,
    "out:El kr saved": 36588.75474,
    "out:El kr return": 69428.8685176105,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 84360.435433,
    "out:% savings (space heating)": 60.901019,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 44.3,
    "out:Etvv": 0,
    "out:Ef": 19.33,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4585453.521700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -53.534774,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.05738,
    "out:Bought CO2": 2.522606,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.697217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.276593,
    "out:Electricity (inc PV)": 28.594664,
    "out:Total Energy Use Pre PV": 74.276593,
    "out:Total Energy Use Post PV": 74.594664,
    "out:Primary Energy": 88.131404,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.238592,
    "out:CO2 Operational/m2": 20.292,
    "out:Total CO2/m2": 37.530592,
    "out:Total CO2 (tons)": 74.010332,
    "out:Klimatpaverkan": -20.921841,
    "out:Initial Cost/MSEK": 3.718604393245616,
    "out:Running cost/(Apt SEK y)": 11337.441176,
    "out:Running Cost over RSP/MSEK": 3.00493,
    "out:LCP/MSEK": -0.394464,
    "out:ROI% old": 12.770306,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 84,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1979.36151,
    "out:Return %": 11,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.537093,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 168813.376997,
    "out:EL kWh savings": -41716.99982,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85379.38,
    "out:DH kr savings": 168813.37699658776,
    "out:El kr savings": -83433.99963926336,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 90114.917133,
    "out:% savings (space heating)": 58.233959,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 47.14,
    "out:Etvv": 0,
    "out:Ef": 28.59,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3718604.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": 3.732736,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.732736,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.159823,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.200354,
    "out:Electricity (inc PV)": 28.525334,
    "out:Total Energy Use Pre PV": 70.200354,
    "out:Total Energy Use Post PV": 70.525334,
    "out:Primary Energy": 85.237569,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.238592,
    "out:CO2 Operational/m2": 18.842,
    "out:Total CO2/m2": 36.080592,
    "out:Total CO2 (tons)": 71.150932,
    "out:Klimatpaverkan": -23.781241,
    "out:Initial Cost/MSEK": 3.767904393245616,
    "out:Running cost/(Apt SEK y)": 10627.970588,
    "out:Running Cost over RSP/MSEK": 2.81628,
    "out:LCP/MSEK": -0.255114,
    "out:ROI% old": 13.318468,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1974.02478,
    "out:Return %": 12,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.460854,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 175789.118368,
    "out:EL kWh savings": -41580.281052,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92628.56,
    "out:DH kr savings": 175789.11836785066,
    "out:El kr savings": -83160.56210345615,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83139.175762,
    "out:% savings (space heating)": 61.467043,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.58,
    "out:Etvv": 0,
    "out:Ef": 28.53,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3767904.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": 3.722672,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.722672,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.201947,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.933705,
    "out:Electricity (inc PV)": 28.210433,
    "out:Total Energy Use Pre PV": 71.933705,
    "out:Total Energy Use Post PV": 72.210433,
    "out:Primary Energy": 86.092647,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.905935,
    "out:CO2 Operational/m2": 19.526769,
    "out:Total CO2/m2": 37.432704,
    "out:Total CO2 (tons)": 73.817297,
    "out:Klimatpaverkan": -21.114876,
    "out:Initial Cost/MSEK": 3.877980393245616,
    "out:Running cost/(Apt SEK y)": 10947.352941,
    "out:Running Cost over RSP/MSEK": 2.90131,
    "out:LCP/MSEK": -0.45022,
    "out:ROI% old": 12.627191,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1955.35935,
    "out:Return %": 11,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.194205,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 171762.049607,
    "out:EL kWh savings": -40959.296244,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89843.46,
    "out:DH kr savings": 171762.04960704857,
    "out:El kr savings": -81918.5924876587,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 87166.244523,
    "out:% savings (space heating)": 59.600596,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.4,
    "out:Etvv": 0,
    "out:Ef": 28.21,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3877980.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": 3.687473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.687473,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.68893,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.850079,
    "out:Electricity (inc PV)": 28.132614,
    "out:Total Energy Use Pre PV": 68.850079,
    "out:Total Energy Use Post PV": 69.132614,
    "out:Primary Energy": 83.205321,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.905935,
    "out:CO2 Operational/m2": 18.435777,
    "out:Total CO2/m2": 36.341712,
    "out:Total CO2 (tons)": 71.66586,
    "out:Klimatpaverkan": -23.266312,
    "out:Initial Cost/MSEK": 3.927280393245616,
    "out:Running cost/(Apt SEK y)": 10412.176471,
    "out:Running Cost over RSP/MSEK": 2.75901,
    "out:LCP/MSEK": -0.35722,
    "out:ROI% old": 12.986304,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1949.50553,
    "out:Return %": 12,
    "out:Return/kSEK/y": 455,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.110579,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 178689.719532,
    "out:EL kWh savings": -40805.837167,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 97078.05,
    "out:DH kr savings": 178689.71953153246,
    "out:El kr savings": -81611.67433391606,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80238.574599,
    "out:% savings (space heating)": 62.8114,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.87,
    "out:Etvv": 0,
    "out:Ef": 28.13,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3927280.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": 3.676433,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.676433,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.697217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.276593,
    "out:Electricity (inc PV)": 21.251478,
    "out:Total Energy Use Pre PV": 74.276593,
    "out:Total Energy Use Post PV": 67.251478,
    "out:Primary Energy": 74.913669,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.500395,
    "out:CO2 Operational/m2": 4.931651,
    "out:Total CO2/m2": 33.432046,
    "out:Total CO2 (tons)": 65.927999,
    "out:Klimatpaverkan": -29.004174,
    "out:Initial Cost/MSEK": 4.092559261241866,
    "out:Running cost/(Apt SEK y)": 10239.382353,
    "out:Running Cost over RSP/MSEK": 2.7207,
    "out:LCP/MSEK": -0.484189,
    "out:ROI% old": 12.595576,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1483.378434,
    "out:Return %": 11,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.537093,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5707.885271,
    "out:PV (% sold (El))": 28.406069,
    "out:PV (kWh self-consumed)": 14386.008006,
    "out:PV (ratio sold/self-consumed)": 0.396766,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 168813.376997,
    "out:EL kWh savings": -27236.236191,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122902.73,
    "out:DH kr savings": 168813.37699658776,
    "out:El kr savings": -54472.472381016945,
    "out:El kr sold": 8561.827906334016,
    "out:El kr saved": 28772.016012,
    "out:El kr return": 37333.843918334016,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 90114.917133,
    "out:% savings (space heating)": 58.233959,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 47.14,
    "out:Etvv": 0,
    "out:Ef": 21.25,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4092559.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -11.627613,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.403832,
    "out:Bought CO2": 2.776219,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.159823,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.200354,
    "out:Electricity (inc PV)": 21.192587,
    "out:Total Energy Use Pre PV": 70.200354,
    "out:Total Energy Use Post PV": 63.192587,
    "out:Primary Energy": 72.038625,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.500395,
    "out:CO2 Operational/m2": 3.424044,
    "out:Total CO2/m2": 31.924439,
    "out:Total CO2 (tons)": 62.954997,
    "out:Klimatpaverkan": -31.977175,
    "out:Initial Cost/MSEK": 4.1418592612418665,
    "out:Running cost/(Apt SEK y)": 9530.235294,
    "out:Running Cost over RSP/MSEK": 2.53213,
    "out:LCP/MSEK": -0.344919,
    "out:ROI% old": 13.096051,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 119,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1478.794009,
    "out:Return %": 12,
    "out:Return/kSEK/y": 485,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.460854,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5730.700631,
    "out:PV (% sold (El))": 28.519613,
    "out:PV (kWh self-consumed)": 14363.192646,
    "out:PV (ratio sold/self-consumed)": 0.398985,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175789.118368,
    "out:EL kWh savings": -27120.103132,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130144.96,
    "out:DH kr savings": 175789.11836785066,
    "out:El kr savings": -54240.20626358984,
    "out:El kr sold": 8596.05094662012,
    "out:El kr saved": 28726.385292,
    "out:El kr return": 37322.43623862012,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83139.175762,
    "out:% savings (space heating)": 61.467043,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.58,
    "out:Etvv": 0,
    "out:Ef": 21.19,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4141859.2612418667,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": -11.695284,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.462944,
    "out:Bought CO2": 2.76766,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.201947,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.933705,
    "out:Electricity (inc PV)": 20.925337,
    "out:Total Energy Use Pre PV": 71.933705,
    "out:Total Energy Use Post PV": 64.925337,
    "out:Primary Energy": 72.979475,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.167738,
    "out:CO2 Operational/m2": 3.906224,
    "out:Total CO2/m2": 33.073962,
    "out:Total CO2 (tons)": 65.221857,
    "out:Klimatpaverkan": -29.710315,
    "out:Initial Cost/MSEK": 4.251935261241866,
    "out:Running cost/(Apt SEK y)": 9850.823529,
    "out:Running Cost over RSP/MSEK": 2.61747,
    "out:LCP/MSEK": -0.540335,
    "out:ROI% old": 12.470288,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 112.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1462.777438,
    "out:Return %": 11,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.194205,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5810.808822,
    "out:PV (% sold (El))": 28.918282,
    "out:PV (kWh self-consumed)": 14283.084456,
    "out:PV (ratio sold/self-consumed)": 0.406832,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171762.049607,
    "out:EL kWh savings": -26593.086101,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127292.09,
    "out:DH kr savings": 171762.04960704857,
    "out:El kr savings": -53186.172202656824,
    "out:El kr sold": 8716.21323267022,
    "out:El kr saved": 28566.168912,
    "out:El kr return": 37282.38214467022,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 87166.244523,
    "out:% savings (space heating)": 59.600596,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.4,
    "out:Etvv": 0,
    "out:Ef": 20.93,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4251935.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -11.933072,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.670827,
    "out:Bought CO2": 2.737755,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.68893,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.850079,
    "out:Electricity (inc PV)": 20.859337,
    "out:Total Energy Use Pre PV": 68.850079,
    "out:Total Energy Use Post PV": 61.859337,
    "out:Primary Energy": 70.113423,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.167738,
    "out:CO2 Operational/m2": 2.751319,
    "out:Total CO2/m2": 31.919057,
    "out:Total CO2 (tons)": 62.944384,
    "out:Klimatpaverkan": -31.987788,
    "out:Initial Cost/MSEK": 4.3012352612418665,
    "out:Running cost/(Apt SEK y)": 9316,
    "out:Running Cost over RSP/MSEK": 2.47527,
    "out:LCP/MSEK": -0.447435,
    "out:ROI% old": 12.799645,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1457.759995,
    "out:Return %": 11,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.110579,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5836.120202,
    "out:PV (% sold (El))": 29.044248,
    "out:PV (kWh self-consumed)": 14257.773075,
    "out:PV (ratio sold/self-consumed)": 0.409329,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 178689.719532,
    "out:EL kWh savings": -26462.934094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134518.03,
    "out:DH kr savings": 178689.71953153246,
    "out:El kr savings": -52925.868187608874,
    "out:El kr sold": 8754.180302883902,
    "out:El kr saved": 28515.54615,
    "out:El kr return": 37269.7264528839,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80238.574599,
    "out:% savings (space heating)": 62.8114,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.87,
    "out:Etvv": 0,
    "out:Ef": 20.86,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4301235.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -12.008025,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.73641,
    "out:Bought CO2": 2.728385,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.697217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.276593,
    "out:Electricity (inc PV)": 19.258293,
    "out:Total Energy Use Pre PV": 74.276593,
    "out:Total Energy Use Post PV": 65.258293,
    "out:Primary Energy": 71.325936,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.762198,
    "out:CO2 Operational/m2": -37.065053,
    "out:Total CO2/m2": 2.697145,
    "out:Total CO2 (tons)": 5.31877,
    "out:Klimatpaverkan": -89.613402,
    "out:Initial Cost/MSEK": 4.466514146700615,
    "out:Running cost/(Apt SEK y)": 9295.882353,
    "out:Running Cost over RSP/MSEK": 2.47663,
    "out:LCP/MSEK": -0.614074,
    "out:ROI% old": 12.321657,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 112.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1343.567924,
    "out:Return %": 11,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.537093,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21925.163103,
    "out:PV (% sold (El))": 54.556782,
    "out:PV (kWh self-consumed)": 18262.623452,
    "out:PV (ratio sold/self-consumed)": 1.200548,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 168813.376997,
    "out:EL kWh savings": -23305.675143,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155089.77,
    "out:DH kr savings": 168813.37699658776,
    "out:El kr savings": -46611.350286570836,
    "out:El kr sold": 32887.74465470865,
    "out:El kr saved": 36525.246904,
    "out:El kr return": 69412.99155870866,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 90114.917133,
    "out:% savings (space heating)": 58.233959,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 47.14,
    "out:Etvv": 0,
    "out:Ef": 19.26,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4466514.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -53.624317,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.140084,
    "out:Bought CO2": 2.515767,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.159823,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.200354,
    "out:Electricity (inc PV)": 19.205355,
    "out:Total Energy Use Pre PV": 70.200354,
    "out:Total Energy Use Post PV": 61.205355,
    "out:Primary Energy": 68.461607,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.762198,
    "out:CO2 Operational/m2": -38.605907,
    "out:Total CO2/m2": 1.156291,
    "out:Total CO2 (tons)": 2.280206,
    "out:Klimatpaverkan": -92.651966,
    "out:Initial Cost/MSEK": 4.515814146700615,
    "out:Running cost/(Apt SEK y)": 8586.941176,
    "out:Running Cost over RSP/MSEK": 2.28811,
    "out:LCP/MSEK": -0.474854,
    "out:ROI% old": 12.78352,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 126.2,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1339.453286,
    "out:Return %": 11,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.460854,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21961.034203,
    "out:PV (% sold (El))": 54.646041,
    "out:PV (kWh self-consumed)": 18226.752352,
    "out:PV (ratio sold/self-consumed)": 1.204879,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175789.118368,
    "out:EL kWh savings": -23201.281401,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162328.11,
    "out:DH kr savings": 175789.11836785066,
    "out:El kr savings": -46402.56280250092,
    "out:El kr sold": 32941.551304297514,
    "out:El kr saved": 36453.504704,
    "out:El kr return": 69395.05600829751,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83139.175762,
    "out:% savings (space heating)": 61.467043,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.58,
    "out:Etvv": 0,
    "out:Ef": 19.21,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4515814.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": -53.725235,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.233312,
    "out:Bought CO2": 2.508077,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.201947,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.933705,
    "out:Electricity (inc PV)": 18.965252,
    "out:Total Energy Use Pre PV": 71.933705,
    "out:Total Energy Use Post PV": 62.965252,
    "out:Primary Energy": 69.451322,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.429541,
    "out:CO2 Operational/m2": -38.240112,
    "out:Total CO2/m2": 2.189429,
    "out:Total CO2 (tons)": 4.317554,
    "out:Klimatpaverkan": -90.614618,
    "out:Initial Cost/MSEK": 4.625890146700615,
    "out:Running cost/(Apt SEK y)": 8908.176471,
    "out:Running Cost over RSP/MSEK": 2.37363,
    "out:LCP/MSEK": -0.67045,
    "out:ROI% old": 12.215224,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 119,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1325.077534,
    "out:Return %": 11,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.194205,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22086.966751,
    "out:PV (% sold (El))": 54.959401,
    "out:PV (kWh self-consumed)": 18100.819804,
    "out:PV (ratio sold/self-consumed)": 1.220219,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171762.049607,
    "out:EL kWh savings": -22727.798258,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159436.9,
    "out:DH kr savings": 171762.04960704857,
    "out:El kr savings": -45455.59651575742,
    "out:El kr sold": 33130.45012639043,
    "out:El kr saved": 36201.639608,
    "out:El kr return": 69332.08973439044,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 87166.244523,
    "out:% savings (space heating)": 59.600596,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.4,
    "out:Etvv": 0,
    "out:Ef": 18.97,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4625890.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -54.079408,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.560621,
    "out:Bought CO2": 2.481213,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.68893,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.850079,
    "out:Electricity (inc PV)": 18.906024,
    "out:Total Energy Use Pre PV": 68.850079,
    "out:Total Energy Use Post PV": 59.906024,
    "out:Primary Energy": 66.597459,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.429541,
    "out:CO2 Operational/m2": -39.431545,
    "out:Total CO2/m2": 0.997996,
    "out:Total CO2 (tons)": 1.968048,
    "out:Klimatpaverkan": -92.964124,
    "out:Initial Cost/MSEK": 4.675190146700615,
    "out:Running cost/(Apt SEK y)": 8373.588235,
    "out:Running Cost over RSP/MSEK": 2.23148,
    "out:LCP/MSEK": -0.5776,
    "out:ROI% old": 12.520774,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1320.574152,
    "out:Return %": 11,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.110579,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22126.608618,
    "out:PV (% sold (El))": 55.058043,
    "out:PV (kWh self-consumed)": 18061.177937,
    "out:PV (ratio sold/self-consumed)": 1.225092,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 178689.719532,
    "out:EL kWh savings": -22611.000635,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166657.63,
    "out:DH kr savings": 178689.71953153246,
    "out:El kr savings": -45222.00127025347,
    "out:El kr sold": 33189.91292653378,
    "out:El kr saved": 36122.355874,
    "out:El kr return": 69312.26880053378,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80238.574599,
    "out:% savings (space heating)": 62.8114,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.87,
    "out:Etvv": 0,
    "out:Ef": 18.91,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4675190.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -54.190889,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.663687,
    "out:Bought CO2": 2.472798,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.32675,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.018553,
    "out:Electricity (inc PV)": 40.657861,
    "out:Total Energy Use Pre PV": 51.018553,
    "out:Total Energy Use Post PV": 51.657861,
    "out:Primary Energy": 82.771842,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.677547,
    "out:CO2 Operational/m2": 9.242593,
    "out:Total CO2/m2": 26.92014,
    "out:Total CO2 (tons)": 53.086519,
    "out:Klimatpaverkan": -41.845653,
    "out:Initial Cost/MSEK": 4.424367768245617,
    "out:Running cost/(Apt SEK y)": 6568.911765,
    "out:Running Cost over RSP/MSEK": 1.73258,
    "out:LCP/MSEK": 0.172122,
    "out:ROI% old": 14.841475,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 165.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2801.29871,
    "out:Return %": 13,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 30.279053,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 236591.941839,
    "out:EL kWh savings": -65505.625679,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105580.69,
    "out:DH kr savings": 236591.941838821,
    "out:El kr savings": -131011.2513576723,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 22336.352291,
    "out:% savings (space heating)": 89.647652,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 12.33,
    "out:Etvv": 0,
    "out:Ef": 40.66,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4424367.768245617,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": 5.282769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.282769,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.008951,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.572739,
    "out:Electricity (inc PV)": 40.238409,
    "out:Total Energy Use Pre PV": 48.572739,
    "out:Total Energy Use Post PV": 49.238409,
    "out:Primary Energy": 80.146434,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.677547,
    "out:CO2 Operational/m2": 8.463774,
    "out:Total CO2/m2": 26.141321,
    "out:Total CO2 (tons)": 51.550688,
    "out:Klimatpaverkan": -43.381484,
    "out:Initial Cost/MSEK": 4.473667768245617,
    "out:Running cost/(Apt SEK y)": 6166.882353,
    "out:Running Cost over RSP/MSEK": 1.62582,
    "out:LCP/MSEK": 0.229582,
    "out:ROI% old": 15.018837,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 181.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2770.09173,
    "out:Return %": 13,
    "out:Return/kSEK/y": 600,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.833239,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 241162.641731,
    "out:EL kWh savings": -64678.466287,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111805.71,
    "out:DH kr savings": 241162.6417310501,
    "out:El kr savings": -129356.93257403727,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 17765.652399,
    "out:% savings (space heating)": 91.766058,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 9.92,
    "out:Etvv": 0,
    "out:Ef": 40.24,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4473667.768245617,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 5.223918,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.223918,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.318195,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.329175,
    "out:Electricity (inc PV)": 39.885309,
    "out:Total Energy Use Pre PV": 49.329175,
    "out:Total Energy Use Post PV": 49.885309,
    "out:Primary Energy": 80.447464,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.34489,
    "out:CO2 Operational/m2": 8.791606,
    "out:Total CO2/m2": 27.136496,
    "out:Total CO2 (tons)": 53.513173,
    "out:Klimatpaverkan": -41.418999,
    "out:Initial Cost/MSEK": 4.583743768245616,
    "out:Running cost/(Apt SEK y)": 6313.794118,
    "out:Running Cost over RSP/MSEK": 1.66498,
    "out:LCP/MSEK": 0.080346,
    "out:ROI% old": 14.536122,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 176,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2753.04225,
    "out:Return %": 13,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 29.589675,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 238580.812414,
    "out:EL kWh savings": -63982.153047,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110616.51,
    "out:DH kr savings": 238580.81241379626,
    "out:El kr savings": -127964.30609353045,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 20347.481716,
    "out:% savings (space heating)": 90.569444,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 11.13,
    "out:Etvv": 0,
    "out:Ef": 39.89,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4583743.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": 5.191766,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.191766,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.098917,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.853507,
    "out:Electricity (inc PV)": 39.433875,
    "out:Total Energy Use Pre PV": 46.853507,
    "out:Total Energy Use Post PV": 47.433875,
    "out:Primary Energy": 77.846577,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.34489,
    "out:CO2 Operational/m2": 8.008846,
    "out:Total CO2/m2": 26.353736,
    "out:Total CO2 (tons)": 51.96957,
    "out:Klimatpaverkan": -42.962602,
    "out:Initial Cost/MSEK": 4.633043768245616,
    "out:Running cost/(Apt SEK y)": 5908.294118,
    "out:Running Cost over RSP/MSEK": 1.55731,
    "out:LCP/MSEK": 0.138716,
    "out:ROI% old": 14.713438,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 193.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2719.74549,
    "out:Return %": 13,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 29.114007,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 242957.228883,
    "out:EL kWh savings": -63091.925147,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116773.38,
    "out:DH kr savings": 242957.22888279395,
    "out:El kr savings": -126183.85029460352,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 15971.065247,
    "out:% savings (space heating)": 92.597805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.83,
    "out:Etvv": 0,
    "out:Ef": 39.43,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4633043.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": 5.128974,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.128974,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.32675,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.018553,
    "out:Electricity (inc PV)": 31.852472,
    "out:Total Energy Use Pre PV": 51.018553,
    "out:Total Energy Use Post PV": 42.852472,
    "out:Primary Energy": 66.922142,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.93935,
    "out:CO2 Operational/m2": 1.0476,
    "out:Total CO2/m2": 29.98695,
    "out:Total CO2 (tons)": 59.134269,
    "out:Klimatpaverkan": -35.797903,
    "out:Initial Cost/MSEK": 4.798322636241866,
    "out:Running cost/(Apt SEK y)": 5428.852941,
    "out:Running Cost over RSP/MSEK": 1.43755,
    "out:LCP/MSEK": 0.093197,
    "out:ROI% old": 14.563184,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 220.9,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2212.670466,
    "out:Return %": 13,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 30.279053,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2851.998718,
    "out:PV (% sold (El))": 14.193361,
    "out:PV (kWh self-consumed)": 17241.894559,
    "out:PV (ratio sold/self-consumed)": 0.165411,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236591.941839,
    "out:EL kWh savings": -48141.397567,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144587.14,
    "out:DH kr savings": 236591.941838821,
    "out:El kr savings": -96282.79513404363,
    "out:El kr sold": 4277.998077283646,
    "out:El kr saved": 34483.789118,
    "out:El kr return": 38761.78719528364,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 22336.352291,
    "out:% savings (space heating)": 89.647652,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 12.33,
    "out:Etvv": 0,
    "out:Ef": 31.85,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4798322.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -2.912224,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.051195,
    "out:Bought CO2": 4.138971,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.008951,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.572739,
    "out:Electricity (inc PV)": 31.47345,
    "out:Total Energy Use Pre PV": 48.572739,
    "out:Total Energy Use Post PV": 40.47345,
    "out:Primary Energy": 64.369508,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.93935,
    "out:CO2 Operational/m2": 0.051695,
    "out:Total CO2/m2": 28.991045,
    "out:Total CO2 (tons)": 57.170344,
    "out:Klimatpaverkan": -37.761828,
    "out:Initial Cost/MSEK": 4.847622636241867,
    "out:Running cost/(Apt SEK y)": 5028.117647,
    "out:Running Cost over RSP/MSEK": 1.33112,
    "out:LCP/MSEK": 0.150327,
    "out:ROI% old": 14.728722,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 245,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2184.183474,
    "out:Return %": 13,
    "out:Return/kSEK/y": 639,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.833239,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2939.48935,
    "out:PV (% sold (El))": 14.62877,
    "out:PV (kWh self-consumed)": 17154.403928,
    "out:PV (ratio sold/self-consumed)": 0.171355,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 241162.641731,
    "out:EL kWh savings": -47393.96614,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150783.94,
    "out:DH kr savings": 241162.6417310501,
    "out:El kr savings": -94787.93227962659,
    "out:El kr sold": 4409.234024778476,
    "out:El kr saved": 34308.807856,
    "out:El kr return": 38718.04188077847,
    "out:% solar/total": 117,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 17765.652399,
    "out:% savings (space heating)": 91.766058,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 9.92,
    "out:Etvv": 0,
    "out:Ef": 31.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4847622.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -3.188161,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.273956,
    "out:Bought CO2": 4.085795,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.318195,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.329175,
    "out:Electricity (inc PV)": 31.155128,
    "out:Total Energy Use Pre PV": 49.329175,
    "out:Total Energy Use Post PV": 41.155128,
    "out:Primary Energy": 64.733138,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.606693,
    "out:CO2 Operational/m2": 0.259001,
    "out:Total CO2/m2": 29.865694,
    "out:Total CO2 (tons)": 58.895152,
    "out:Klimatpaverkan": -36.03702,
    "out:Initial Cost/MSEK": 4.957698636241866,
    "out:Running cost/(Apt SEK y)": 5175.735294,
    "out:Running Cost over RSP/MSEK": 1.37046,
    "out:LCP/MSEK": 0.000911,
    "out:ROI% old": 14.28834,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 236.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2168.646279,
    "out:Return %": 13,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 29.589675,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 2987.746074,
    "out:PV (% sold (El))": 14.868926,
    "out:PV (kWh self-consumed)": 17106.147203,
    "out:PV (ratio sold/self-consumed)": 0.174659,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238580.812414,
    "out:EL kWh savings": -46766.23512,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149529.96,
    "out:DH kr savings": 238580.81241379626,
    "out:El kr savings": -93532.47023904922,
    "out:El kr sold": 4481.619111624462,
    "out:El kr saved": 34212.294406,
    "out:El kr return": 38693.91351762446,
    "out:% solar/total": 117,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 20347.481716,
    "out:% savings (space heating)": 90.569444,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 11.13,
    "out:Etvv": 0,
    "out:Ef": 31.16,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4957698.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -3.340839,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.397633,
    "out:Bought CO2": 4.056794,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.098917,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.853507,
    "out:Electricity (inc PV)": 30.748819,
    "out:Total Energy Use Pre PV": 46.853507,
    "out:Total Energy Use Post PV": 38.748819,
    "out:Primary Energy": 62.213476,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.606693,
    "out:CO2 Operational/m2": -0.762731,
    "out:Total CO2/m2": 28.843962,
    "out:Total CO2 (tons)": 56.880296,
    "out:Klimatpaverkan": -38.051876,
    "out:Initial Cost/MSEK": 5.006998636241867,
    "out:Running cost/(Apt SEK y)": 4771.647059,
    "out:Running Cost over RSP/MSEK": 1.26315,
    "out:LCP/MSEK": 0.058921,
    "out:ROI% old": 14.453826,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 253.8,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2138.356684,
    "out:Return %": 13,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 29.114007,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3083.141462,
    "out:PV (% sold (El))": 15.343674,
    "out:PV (kWh self-consumed)": 17010.751815,
    "out:PV (ratio sold/self-consumed)": 0.181247,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 242957.228883,
    "out:EL kWh savings": -45964.993725,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155651.95,
    "out:DH kr savings": 242957.22888279395,
    "out:El kr savings": -91929.98745041074,
    "out:El kr sold": 4624.712193673016,
    "out:El kr saved": 34021.50363,
    "out:El kr return": 38646.21582367302,
    "out:% solar/total": 116,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 15971.065247,
    "out:% savings (space heating)": 92.597805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.83,
    "out:Etvv": 0,
    "out:Ef": 30.75,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5006998.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -3.642603,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.642851,
    "out:Bought CO2": 4.000248,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.32675,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.018553,
    "out:Electricity (inc PV)": 28.771197,
    "out:Total Energy Use Pre PV": 51.018553,
    "out:Total Energy Use Post PV": 39.771197,
    "out:Primary Energy": 61.375847,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.201153,
    "out:CO2 Operational/m2": -35.593041,
    "out:Total CO2/m2": 4.608112,
    "out:Total CO2 (tons)": 9.087197,
    "out:Klimatpaverkan": -85.844975,
    "out:Initial Cost/MSEK": 5.172277521700616,
    "out:Running cost/(Apt SEK y)": 4454.764706,
    "out:Running Cost over RSP/MSEK": 1.1854,
    "out:LCP/MSEK": -0.028608,
    "out:ROI% old": 14.206701,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 245,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 1997.338985,
    "out:Return %": 13,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 30.279053,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 16988.60567,
    "out:PV (% sold (El))": 42.273056,
    "out:PV (kWh self-consumed)": 23199.180885,
    "out:PV (ratio sold/self-consumed)": 0.732293,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236591.941839,
    "out:EL kWh savings": -42065.122916,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177944.6,
    "out:DH kr savings": 236591.941838821,
    "out:El kr savings": -84130.2458315129,
    "out:El kr sold": 25482.908505287844,
    "out:El kr saved": 46398.36177,
    "out:El kr return": 71881.27027528785,
    "out:% solar/total": 158,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 22336.352291,
    "out:% savings (space heating)": 89.647652,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 12.33,
    "out:Etvv": 0,
    "out:Ef": 28.77,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5172277.521700616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -39.552865,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -43.291457,
    "out:Bought CO2": 3.738592,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.008951,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.572739,
    "out:Electricity (inc PV)": 28.431862,
    "out:Total Energy Use Pre PV": 48.572739,
    "out:Total Energy Use Post PV": 37.431862,
    "out:Primary Energy": 58.89465,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.201153,
    "out:CO2 Operational/m2": -36.802649,
    "out:Total CO2/m2": 3.398504,
    "out:Total CO2 (tons)": 6.70185,
    "out:Klimatpaverkan": -88.230322,
    "out:Initial Cost/MSEK": 5.221577521700616,
    "out:Running cost/(Apt SEK y)": 4055.235294,
    "out:Running Cost over RSP/MSEK": 1.07929,
    "out:LCP/MSEK": 0.028202,
    "out:ROI% old": 14.362873,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 273,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1971.914479,
    "out:Return %": 13,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.833239,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17158.466053,
    "out:PV (% sold (El))": 42.695723,
    "out:PV (kWh self-consumed)": 23029.320502,
    "out:PV (ratio sold/self-consumed)": 0.74507,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 241162.641731,
    "out:EL kWh savings": -41395.954257,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184108.43,
    "out:DH kr savings": 241162.6417310501,
    "out:El kr savings": -82791.90851414451,
    "out:El kr sold": 25737.699078825477,
    "out:El kr saved": 46058.641004,
    "out:El kr return": 71796.34008282548,
    "out:% solar/total": 157,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 17765.652399,
    "out:% savings (space heating)": 91.766058,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 9.92,
    "out:Etvv": 0,
    "out:Ef": 28.43,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5221577.521700616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -40.042505,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -43.733503,
    "out:Bought CO2": 3.690998,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.318195,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.329175,
    "out:Electricity (inc PV)": 28.146718,
    "out:Total Energy Use Pre PV": 49.329175,
    "out:Total Energy Use Post PV": 38.146718,
    "out:Primary Energy": 59.318,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.868496,
    "out:CO2 Operational/m2": -36.711348,
    "out:Total CO2/m2": 4.157148,
    "out:Total CO2 (tons)": 8.197896,
    "out:Klimatpaverkan": -86.734276,
    "out:Initial Cost/MSEK": 5.331653521700615,
    "out:Running cost/(Apt SEK y)": 4203.5,
    "out:Running Cost over RSP/MSEK": 1.1188,
    "out:LCP/MSEK": -0.121384,
    "out:ROI% old": 13.960477,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 263.2,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 1958.042506,
    "out:Return %": 13,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 29.589675,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17251.716727,
    "out:PV (% sold (El))": 42.92776,
    "out:PV (kWh self-consumed)": 22936.069828,
    "out:PV (ratio sold/self-consumed)": 0.752165,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238580.812414,
    "out:EL kWh savings": -40833.650257,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182791.09,
    "out:DH kr savings": 238580.81241379626,
    "out:El kr savings": -81667.30051313169,
    "out:El kr sold": 25877.57509023106,
    "out:El kr saved": 45872.139656,
    "out:El kr return": 71749.71474623107,
    "out:% solar/total": 156,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 20347.481716,
    "out:% savings (space heating)": 90.569444,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 11.13,
    "out:Etvv": 0,
    "out:Ef": 28.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5331653.521700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -40.311188,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -43.976215,
    "out:Bought CO2": 3.665027,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.098917,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.853507,
    "out:Electricity (inc PV)": 27.78266,
    "out:Total Energy Use Pre PV": 46.853507,
    "out:Total Energy Use Post PV": 35.78266,
    "out:Primary Energy": 56.87439,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.868496,
    "out:CO2 Operational/m2": -37.958668,
    "out:Total CO2/m2": 2.909828,
    "out:Total CO2 (tons)": 5.738181,
    "out:Klimatpaverkan": -89.193991,
    "out:Initial Cost/MSEK": 5.380953521700615,
    "out:Running cost/(Apt SEK y)": 3800.705882,
    "out:Running Cost over RSP/MSEK": 1.01184,
    "out:LCP/MSEK": -0.063724,
    "out:ROI% old": 14.116537,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 1930.98914,
    "out:Return %": 13,
    "out:Return/kSEK/y": 680,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 29.114007,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17434.932291,
    "out:PV (% sold (El))": 43.383659,
    "out:PV (kWh self-consumed)": 22752.854264,
    "out:PV (ratio sold/self-consumed)": 0.766275,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242957.228883,
    "out:EL kWh savings": -40115.727839,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188878.17,
    "out:DH kr savings": 242957.22888279395,
    "out:El kr savings": -80231.45567812647,
    "out:El kr sold": 26152.398436784017,
    "out:El kr saved": 45505.708528,
    "out:El kr return": 71658.10696478402,
    "out:% solar/total": 155,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 15971.065247,
    "out:% savings (space heating)": 92.597805,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.83,
    "out:Etvv": 0,
    "out:Ef": 27.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5380953.521700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -40.83854,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.452915,
    "out:Bought CO2": 3.614375,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.992414,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.09623,
    "out:Electricity (inc PV)": 39.623542,
    "out:Total Energy Use Pre PV": 49.09623,
    "out:Total Energy Use Post PV": 49.623542,
    "out:Primary Energy": 79.675932,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.677547,
    "out:CO2 Operational/m2": 8.760855,
    "out:Total CO2/m2": 26.438402,
    "out:Total CO2 (tons)": 52.136532,
    "out:Klimatpaverkan": -42.79564,
    "out:Initial Cost/MSEK": 4.514104393245616,
    "out:Running cost/(Apt SEK y)": 6286.764706,
    "out:Running Cost over RSP/MSEK": 1.65787,
    "out:LCP/MSEK": 0.157096,
    "out:ROI% old": 14.782872,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 176,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2736.7361,
    "out:Return %": 13,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 29.35673,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 239223.252583,
    "out:EL kWh savings": -63465.948493,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112291.36,
    "out:DH kr savings": 239223.2525829353,
    "out:El kr savings": -126931.8969858476,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 19705.041547,
    "out:% savings (space heating)": 90.867199,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 10.74,
    "out:Etvv": 0,
    "out:Ef": 39.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4514104.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": 5.161015,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.161015,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.807917,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.610022,
    "out:Electricity (inc PV)": 39.160636,
    "out:Total Energy Use Pre PV": 46.610022,
    "out:Total Energy Use Post PV": 47.160636,
    "out:Primary Energy": 77.083838,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.677547,
    "out:CO2 Operational/m2": 7.976704,
    "out:Total CO2/m2": 25.654251,
    "out:Total CO2 (tons)": 50.590186,
    "out:Klimatpaverkan": -44.341986,
    "out:Initial Cost/MSEK": 4.563404393245616,
    "out:Running cost/(Apt SEK y)": 5880.029412,
    "out:Running Cost over RSP/MSEK": 1.54989,
    "out:LCP/MSEK": 0.215776,
    "out:ROI% old": 14.961199,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 193.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2702.70154,
    "out:Return %": 13,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.870522,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 243531.080916,
    "out:EL kWh savings": -62553.097808,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118424.89,
    "out:DH kr savings": 243531.08091596793,
    "out:El kr savings": -125106.19561630499,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 15397.213214,
    "out:% savings (space heating)": 92.863771,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.48,
    "out:Etvv": 0,
    "out:Ef": 39.16,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4563404.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": 5.096832,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.096832,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.234219,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.529986,
    "out:Electricity (inc PV)": 38.986645,
    "out:Total Energy Use Pre PV": 47.529986,
    "out:Total Energy Use Post PV": 47.986645,
    "out:Primary Energy": 77.830886,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.34489,
    "out:CO2 Operational/m2": 8.326122,
    "out:Total CO2/m2": 26.671012,
    "out:Total CO2 (tons)": 52.595239,
    "out:Klimatpaverkan": -42.336934,
    "out:Initial Cost/MSEK": 4.673480393245616,
    "out:Running cost/(Apt SEK y)": 6045.911765,
    "out:Running Cost over RSP/MSEK": 1.59403,
    "out:LCP/MSEK": 0.06156,
    "out:ROI% old": 14.473887,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 187.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2697.09902,
    "out:Return %": 13,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 28.790486,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 240718.413209,
    "out:EL kWh savings": -62209.987536,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116298.44,
    "out:DH kr savings": 240718.41320936952,
    "out:El kr savings": -124419.97507263506,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 18209.880921,
    "out:% savings (space heating)": 91.560169,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 9.84,
    "out:Etvv": 0,
    "out:Ef": 38.99,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4673480.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 5.086266,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.086266,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.131951,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.01886,
    "out:Electricity (inc PV)": 38.49548,
    "out:Total Energy Use Pre PV": 45.01886,
    "out:Total Energy Use Post PV": 45.49548,
    "out:Primary Energy": 75.260457,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.34489,
    "out:CO2 Operational/m2": 7.538682,
    "out:Total CO2/m2": 25.883572,
    "out:Total CO2 (tons)": 51.042407,
    "out:Klimatpaverkan": -43.889765,
    "out:Initial Cost/MSEK": 4.722780393245616,
    "out:Running cost/(Apt SEK y)": 5636.294118,
    "out:Running Cost over RSP/MSEK": 1.48528,
    "out:LCP/MSEK": 0.12101,
    "out:ROI% old": 14.651751,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2661.3202,
    "out:Return %": 13,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 28.27936,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244864.085945,
    "out:EL kWh savings": -61241.4101,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122381.27,
    "out:DH kr savings": 244864.08594502808,
    "out:El kr savings": -122482.82020064947,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14064.208185,
    "out:% savings (space heating)": 93.481586,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.67,
    "out:Etvv": 0,
    "out:Ef": 38.5,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4722780.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 5.018794,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.018794,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.992414,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.09623,
    "out:Electricity (inc PV)": 30.919471,
    "out:Total Energy Use Pre PV": 49.09623,
    "out:Total Energy Use Post PV": 40.919471,
    "out:Primary Energy": 64.008604,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.93935,
    "out:CO2 Operational/m2": 0.111783,
    "out:Total CO2/m2": 29.051133,
    "out:Total CO2 (tons)": 57.288838,
    "out:Klimatpaverkan": -37.643335,
    "out:Initial Cost/MSEK": 4.8880592612418665,
    "out:Running cost/(Apt SEK y)": 5149.382353,
    "out:Running Cost over RSP/MSEK": 1.36353,
    "out:LCP/MSEK": 0.077481,
    "out:ROI% old": 14.512157,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 236.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2153.804037,
    "out:Return %": 13,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 29.35673,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3034.310633,
    "out:PV (% sold (El))": 15.100661,
    "out:PV (kWh self-consumed)": 17059.582645,
    "out:PV (ratio sold/self-consumed)": 0.177865,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 239223.252583,
    "out:EL kWh savings": -46301.519489,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151171.68,
    "out:DH kr savings": 239223.2525829353,
    "out:El kr savings": -92603.03897731942,
    "out:El kr sold": 4551.465949017227,
    "out:El kr saved": 34119.16529,
    "out:El kr return": 38670.631239017224,
    "out:% solar/total": 116,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 19705.041547,
    "out:% savings (space heating)": 90.867199,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 10.74,
    "out:Etvv": 0,
    "out:Ef": 30.92,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4888059.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -3.488057,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.517144,
    "out:Bought CO2": 4.029087,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.807917,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.610022,
    "out:Electricity (inc PV)": 30.503184,
    "out:Total Energy Use Pre PV": 46.610022,
    "out:Total Energy Use Post PV": 38.503184,
    "out:Primary Energy": 61.500424,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.93935,
    "out:CO2 Operational/m2": -0.918851,
    "out:Total CO2/m2": 28.020499,
    "out:Total CO2 (tons)": 55.256427,
    "out:Klimatpaverkan": -39.675745,
    "out:Initial Cost/MSEK": 4.937359261241866,
    "out:Running cost/(Apt SEK y)": 4744.117647,
    "out:Running Cost over RSP/MSEK": 1.25591,
    "out:LCP/MSEK": 0.135801,
    "out:ROI% old": 14.678639,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 253.8,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2122.878972,
    "out:Return %": 13,
    "out:Return/kSEK/y": 648,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.870522,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3132.542747,
    "out:PV (% sold (El))": 15.589526,
    "out:PV (kWh self-consumed)": 16961.350531,
    "out:PV (ratio sold/self-consumed)": 0.184687,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 243531.080916,
    "out:EL kWh savings": -45480.601477,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157268.69,
    "out:DH kr savings": 243531.08091596793,
    "out:El kr savings": -90961.20295440596,
    "out:El kr sold": 4698.814119965059,
    "out:El kr saved": 33922.701062,
    "out:El kr return": 38621.51518196506,
    "out:% solar/total": 116,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 15397.213214,
    "out:% savings (space heating)": 92.863771,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.48,
    "out:Etvv": 0,
    "out:Ef": 30.5,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4937359.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -3.798723,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.77007,
    "out:Bought CO2": 3.971347,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.234219,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.529986,
    "out:Electricity (inc PV)": 30.3469,
    "out:Total Energy Use Pre PV": 47.529986,
    "out:Total Energy Use Post PV": 39.3469,
    "out:Primary Energy": 62.279345,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.606693,
    "out:CO2 Operational/m2": -0.610427,
    "out:Total CO2/m2": 28.996266,
    "out:Total CO2 (tons)": 57.18064,
    "out:Klimatpaverkan": -37.751532,
    "out:Initial Cost/MSEK": 5.0474352612418665,
    "out:Running cost/(Apt SEK y)": 4910.235294,
    "out:Running Cost over RSP/MSEK": 1.30012,
    "out:LCP/MSEK": -0.018485,
    "out:ROI% old": 14.233396,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 253.8,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2117.795342,
    "out:Return %": 13,
    "out:Return/kSEK/y": 643,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 28.790486,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3148.899577,
    "out:PV (% sold (El))": 15.670928,
    "out:PV (kWh self-consumed)": 16944.9937,
    "out:PV (ratio sold/self-consumed)": 0.185831,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 240718.413209,
    "out:EL kWh savings": -45172.409411,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155096.94,
    "out:DH kr savings": 240718.41320936952,
    "out:El kr savings": -90344.81882277325,
    "out:El kr sold": 4723.349366005054,
    "out:El kr saved": 33889.9874,
    "out:El kr return": 38613.33676600505,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 18209.880921,
    "out:% savings (space heating)": 91.560169,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 9.84,
    "out:Etvv": 0,
    "out:Ef": 30.35,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5047435.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -3.850283,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.812136,
    "out:Bought CO2": 3.961853,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.131951,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.01886,
    "out:Electricity (inc PV)": 29.906267,
    "out:Total Energy Use Pre PV": 45.01886,
    "out:Total Energy Use Post PV": 36.906267,
    "out:Primary Energy": 59.799874,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.606693,
    "out:CO2 Operational/m2": -1.662125,
    "out:Total CO2/m2": 27.944568,
    "out:Total CO2 (tons)": 55.106691,
    "out:Klimatpaverkan": -39.825481,
    "out:Initial Cost/MSEK": 5.096735261241866,
    "out:Running cost/(Apt SEK y)": 4502.176471,
    "out:Running Cost over RSP/MSEK": 1.19176,
    "out:LCP/MSEK": 0.040575,
    "out:ROI% old": 14.399442,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 273,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2085.375915,
    "out:Return %": 13,
    "out:Return/kSEK/y": 656,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 28.27936,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3254.226212,
    "out:PV (% sold (El))": 16.195101,
    "out:PV (kWh self-consumed)": 16839.667066,
    "out:PV (ratio sold/self-consumed)": 0.193248,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244864.085945,
    "out:EL kWh savings": -44303.481085,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161138.46,
    "out:DH kr savings": 244864.08594502808,
    "out:El kr savings": -88606.96217030889,
    "out:El kr sold": 4881.339317522568,
    "out:El kr saved": 33679.334132,
    "out:El kr return": 38560.67344952257,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14064.208185,
    "out:% savings (space heating)": 93.481586,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.67,
    "out:Etvv": 0,
    "out:Ef": 29.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5096735.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -4.182013,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.0833,
    "out:Bought CO2": 3.901287,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.992414,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.09623,
    "out:Electricity (inc PV)": 27.935554,
    "out:Total Energy Use Pre PV": 49.09623,
    "out:Total Energy Use Post PV": 37.935554,
    "out:Primary Energy": 58.637553,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.201153,
    "out:CO2 Operational/m2": -36.969156,
    "out:Total CO2/m2": 3.231997,
    "out:Total CO2 (tons)": 6.373498,
    "out:Klimatpaverkan": -88.558674,
    "out:Initial Cost/MSEK": 5.262014146700615,
    "out:Running cost/(Apt SEK y)": 4177.794118,
    "out:Running Cost over RSP/MSEK": 1.11205,
    "out:LCP/MSEK": -0.044994,
    "out:ROI% old": 14.163561,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 263.2,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 1944.787477,
    "out:Return %": 13,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 29.35673,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17341.275219,
    "out:PV (% sold (El))": 43.15061,
    "out:PV (kWh self-consumed)": 22846.511336,
    "out:PV (ratio sold/self-consumed)": 0.759034,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 239223.252583,
    "out:EL kWh savings": -40417.234824,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184400.7,
    "out:DH kr savings": 239223.2525829353,
    "out:El kr savings": -80834.46964898625,
    "out:El kr sold": 26011.912828482826,
    "out:El kr saved": 45693.022672,
    "out:El kr return": 71704.93550048282,
    "out:% solar/total": 156,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 19705.041547,
    "out:% savings (space heating)": 90.867199,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 10.74,
    "out:Etvv": 0,
    "out:Ef": 27.94,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5262014.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -40.568996,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.209206,
    "out:Bought CO2": 3.64021,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.807917,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.610022,
    "out:Electricity (inc PV)": 27.562618,
    "out:Total Energy Use Pre PV": 46.610022,
    "out:Total Energy Use Post PV": 35.562618,
    "out:Primary Energy": 56.207405,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 40.201153,
    "out:CO2 Operational/m2": -38.229998,
    "out:Total CO2/m2": 1.971155,
    "out:Total CO2 (tons)": 3.887118,
    "out:Klimatpaverkan": -91.045054,
    "out:Initial Cost/MSEK": 5.311314146700615,
    "out:Running cost/(Apt SEK y)": 3773.823529,
    "out:Running Cost over RSP/MSEK": 1.00477,
    "out:LCP/MSEK": 0.012986,
    "out:ROI% old": 14.320642,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 1917.160731,
    "out:Return %": 13,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.870522,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17529.197984,
    "out:PV (% sold (El))": 43.618222,
    "out:PV (kWh self-consumed)": 22658.588571,
    "out:PV (ratio sold/self-consumed)": 0.773623,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 243531.080916,
    "out:EL kWh savings": -39681.80499,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190461.27,
    "out:DH kr savings": 243531.08091596793,
    "out:El kr savings": -79363.6099799569,
    "out:El kr sold": 26293.796975554742,
    "out:El kr saved": 45317.177142,
    "out:El kr return": 71610.97411755474,
    "out:% solar/total": 154,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 15397.213214,
    "out:% savings (space heating)": 92.863771,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.48,
    "out:Etvv": 0,
    "out:Ef": 27.56,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5311314.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -41.10987,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.698353,
    "out:Bought CO2": 3.588483,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.234219,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.529986,
    "out:Electricity (inc PV)": 27.422615,
    "out:Total Energy Use Pre PV": 47.529986,
    "out:Total Energy Use Post PV": 36.422615,
    "out:Primary Energy": 57.015632,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.868496,
    "out:CO2 Operational/m2": -37.959394,
    "out:Total CO2/m2": 2.909102,
    "out:Total CO2 (tons)": 5.736749,
    "out:Klimatpaverkan": -89.195423,
    "out:Initial Cost/MSEK": 5.421390146700615,
    "out:Running cost/(Apt SEK y)": 3940.176471,
    "out:Running Cost over RSP/MSEK": 1.04904,
    "out:LCP/MSEK": -0.14136,
    "out:ROI% old": 13.913221,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 1912.61805,
    "out:Return %": 12,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 28.790486,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17560.237003,
    "out:PV (% sold (El))": 43.695457,
    "out:PV (kWh self-consumed)": 22627.549551,
    "out:PV (ratio sold/self-consumed)": 0.776056,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 240718.413209,
    "out:EL kWh savings": -39405.719058,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188247.33,
    "out:DH kr savings": 240718.41320936952,
    "out:El kr savings": -78811.43811603621,
    "out:El kr sold": 26340.355504971038,
    "out:El kr saved": 45255.099102,
    "out:El kr return": 71595.45460697103,
    "out:% solar/total": 154,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 18209.880921,
    "out:% savings (space heating)": 91.560169,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 9.84,
    "out:Etvv": 0,
    "out:Ef": 27.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5421390.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -41.19925,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.779228,
    "out:Bought CO2": 3.579978,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.131951,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.01886,
    "out:Electricity (inc PV)": 27.027856,
    "out:Total Energy Use Pre PV": 45.01886,
    "out:Total Energy Use Post PV": 34.027856,
    "out:Primary Energy": 54.618734,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 40.868496,
    "out:CO2 Operational/m2": -39.252284,
    "out:Total CO2/m2": 1.616212,
    "out:Total CO2 (tons)": 3.18717,
    "out:Klimatpaverkan": -91.745002,
    "out:Initial Cost/MSEK": 5.4706901467006155,
    "out:Running cost/(Apt SEK y)": 3533.5,
    "out:Running Cost over RSP/MSEK": 0.94105,
    "out:LCP/MSEK": -0.08267,
    "out:ROI% old": 14.069836,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 305.9,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1883.638759,
    "out:Return %": 13,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 28.27936,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17759.239116,
    "out:PV (% sold (El))": 44.190638,
    "out:PV (kWh self-consumed)": 22428.547439,
    "out:PV (ratio sold/self-consumed)": 0.791814,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244864.085945,
    "out:EL kWh savings": -38627.254265,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194248.44,
    "out:DH kr savings": 244864.08594502808,
    "out:El kr savings": -77254.50853003119,
    "out:El kr sold": 26638.858674155486,
    "out:El kr saved": 44857.094878,
    "out:El kr return": 71495.9535521555,
    "out:% solar/total": 153,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14064.208185,
    "out:% savings (space heating)": 93.481586,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.67,
    "out:Etvv": 0,
    "out:Ef": 27.03,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5470690.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -41.772172,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.297885,
    "out:Bought CO2": 3.525713,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 111.44,
    "out:Primary Energy": 94.412459,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.491943,
    "out:CO2 Operational/m2": 38.420476,
    "out:Total CO2/m2": 51.912419,
    "out:Total CO2 (tons)": 102.371296,
    "out:Klimatpaverkan": 7.439124,
    "out:Initial Cost/MSEK": 1.89959499965375,
    "out:Running cost/(Apt SEK y)": 19079.676471,
    "out:Running Cost over RSP/MSEK": 5.07136,
    "out:LCP/MSEK": -0.641885,
    "out:ROI% old": 9.458482,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 54709.617621,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54709.62,
    "out:DH kr savings": 54709.61762112619,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1899594.99965375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 107.44,
    "out:Primary Energy": 91.737286,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.491943,
    "out:CO2 Operational/m2": 36.98054,
    "out:Total CO2/m2": 50.472483,
    "out:Total CO2 (tons)": 99.531742,
    "out:Klimatpaverkan": 4.59957,
    "out:Initial Cost/MSEK": 1.9488949996537501,
    "out:Running cost/(Apt SEK y)": 18379.029412,
    "out:Running Cost over RSP/MSEK": 4.88503,
    "out:LCP/MSEK": -0.504855,
    "out:ROI% old": 10.585046,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 9,
    "out:Return/kSEK/y": 185,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 61492.326409,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 61492.33,
    "out:DH kr savings": 61492.32640883003,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1948894.99965375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 107.44,
    "out:Primary Energy": 91.787459,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.159286,
    "out:CO2 Operational/m2": 36.98054,
    "out:Total CO2/m2": 51.139826,
    "out:Total CO2 (tons)": 100.847743,
    "out:Klimatpaverkan": 5.91557,
    "out:Initial Cost/MSEK": 2.05897099965375,
    "out:Running cost/(Apt SEK y)": 18379.029412,
    "out:Running Cost over RSP/MSEK": 4.88503,
    "out:LCP/MSEK": -0.614931,
    "out:ROI% old": 10.019152,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 9,
    "out:Return/kSEK/y": 185,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 61184.679995,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61184.68,
    "out:DH kr savings": 61184.6799954452,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2058970.9996537499,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 104.44,
    "out:Primary Energy": 89.112286,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.159286,
    "out:CO2 Operational/m2": 35.900588,
    "out:Total CO2/m2": 50.059874,
    "out:Total CO2 (tons)": 98.718077,
    "out:Klimatpaverkan": 3.785905,
    "out:Initial Cost/MSEK": 2.10827099965375,
    "out:Running cost/(Apt SEK y)": 17853.558824,
    "out:Running Cost over RSP/MSEK": 4.74529,
    "out:LCP/MSEK": -0.524491,
    "out:ROI% old": 10.731746,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 67967.388783,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 67967.39,
    "out:DH kr savings": 67967.38878314904,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2108270.99965375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 108.635375,
    "out:Primary Energy": 89.364134,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.753746,
    "out:CO2 Operational/m2": 0.582567,
    "out:Total CO2/m2": 25.336313,
    "out:Total CO2 (tons)": 49.963212,
    "out:Klimatpaverkan": -44.96896,
    "out:Initial Cost/MSEK": 2.27354986765,
    "out:Running cost/(Apt SEK y)": 18111.852941,
    "out:Running Cost over RSP/MSEK": 4.82102,
    "out:LCP/MSEK": -0.7655,
    "out:ROI% old": 9.475742,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 26.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 194,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 54709.617621,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87615.82,
    "out:DH kr savings": 54709.61762112619,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2273549.86765,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 104.635375,
    "out:Primary Energy": 86.688961,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.753746,
    "out:CO2 Operational/m2": -0.857369,
    "out:Total CO2/m2": 23.896377,
    "out:Total CO2 (tons)": 47.123658,
    "out:Klimatpaverkan": -47.808514,
    "out:Initial Cost/MSEK": 2.32284986765,
    "out:Running cost/(Apt SEK y)": 17411.205882,
    "out:Running Cost over RSP/MSEK": 4.6347,
    "out:LCP/MSEK": -0.62848,
    "out:ROI% old": 10.420512,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 31.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61492.326409,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94398.53,
    "out:DH kr savings": 61492.32640883003,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2322849.86765,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 104.635375,
    "out:Primary Energy": 86.739134,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.421089,
    "out:CO2 Operational/m2": -0.857369,
    "out:Total CO2/m2": 24.56372,
    "out:Total CO2 (tons)": 48.439659,
    "out:Klimatpaverkan": -46.492514,
    "out:Initial Cost/MSEK": 2.4329258676500003,
    "out:Running cost/(Apt SEK y)": 17411.205882,
    "out:Running Cost over RSP/MSEK": 4.6347,
    "out:LCP/MSEK": -0.738556,
    "out:ROI% old": 9.949044,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 31.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61184.679995,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94090.88,
    "out:DH kr savings": 61184.6799954452,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2432925.8676500004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 101.635375,
    "out:Primary Energy": 84.063961,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.421089,
    "out:CO2 Operational/m2": -1.937321,
    "out:Total CO2/m2": 23.483768,
    "out:Total CO2 (tons)": 46.309993,
    "out:Klimatpaverkan": -48.622179,
    "out:Initial Cost/MSEK": 2.4822258676500004,
    "out:Running cost/(Apt SEK y)": 16885.735294,
    "out:Running Cost over RSP/MSEK": 4.49495,
    "out:LCP/MSEK": -0.648106,
    "out:ROI% old": 10.555733,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 67967.388783,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 100873.59,
    "out:DH kr savings": 67967.38878314904,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2482225.8676500004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 108.377795,
    "out:Primary Energy": 88.90049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.015549,
    "out:CO2 Operational/m2": -50.065958,
    "out:Total CO2/m2": -14.050409,
    "out:Total CO2 (tons)": -27.707408,
    "out:Klimatpaverkan": -122.63958,
    "out:Initial Cost/MSEK": 2.64750475310875,
    "out:Running cost/(Apt SEK y)": 17217.882353,
    "out:Running Cost over RSP/MSEK": 4.59001,
    "out:LCP/MSEK": -0.908445,
    "out:ROI% old": 9.383822,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 27.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 54709.617621,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118010.63,
    "out:DH kr savings": 54709.61762112619,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2647504.75310875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 104.377795,
    "out:Primary Energy": 86.225317,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.015549,
    "out:CO2 Operational/m2": -51.505894,
    "out:Total CO2/m2": -15.490345,
    "out:Total CO2 (tons)": -30.546962,
    "out:Klimatpaverkan": -125.479134,
    "out:Initial Cost/MSEK": 2.6968047531087502,
    "out:Running cost/(Apt SEK y)": 16517.235294,
    "out:Running Cost over RSP/MSEK": 4.40368,
    "out:LCP/MSEK": -0.771415,
    "out:ROI% old": 10.199319,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 61492.326409,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124793.34,
    "out:DH kr savings": 61492.32640883003,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2696804.75310875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 104.377795,
    "out:Primary Energy": 86.27549,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.682892,
    "out:CO2 Operational/m2": -51.505894,
    "out:Total CO2/m2": -14.823002,
    "out:Total CO2 (tons)": -29.230962,
    "out:Klimatpaverkan": -124.163134,
    "out:Initial Cost/MSEK": 2.8068807531087496,
    "out:Running cost/(Apt SEK y)": 16517.235294,
    "out:Running Cost over RSP/MSEK": 4.40368,
    "out:LCP/MSEK": -0.881491,
    "out:ROI% old": 9.799337,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61184.679995,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124485.69,
    "out:DH kr savings": 61184.6799954452,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2806880.7531087496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 101.377795,
    "out:Primary Energy": 83.600317,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.682892,
    "out:CO2 Operational/m2": -52.585846,
    "out:Total CO2/m2": -15.902954,
    "out:Total CO2 (tons)": -31.360627,
    "out:Klimatpaverkan": -126.292799,
    "out:Initial Cost/MSEK": 2.8561807531087497,
    "out:Running cost/(Apt SEK y)": 15991.764706,
    "out:Running Cost over RSP/MSEK": 4.26394,
    "out:LCP/MSEK": -0.791051,
    "out:ROI% old": 10.329128,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 67967.388783,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131268.4,
    "out:DH kr savings": 67967.38878314904,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2856180.7531087496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 106.44,
    "out:Total Energy Use Post PV": 106.44,
    "out:Primary Energy": 90.912459,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.491943,
    "out:CO2 Operational/m2": 36.620556,
    "out:Total CO2/m2": 50.112499,
    "out:Total CO2 (tons)": 98.821854,
    "out:Klimatpaverkan": 3.889681,
    "out:Initial Cost/MSEK": 1.98933162465375,
    "out:Running cost/(Apt SEK y)": 18203.882353,
    "out:Running Cost over RSP/MSEK": 4.83845,
    "out:LCP/MSEK": -0.498712,
    "out:ROI% old": 10.704385,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 191,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63343.03412,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63343.03,
    "out:DH kr savings": 63343.034120218195,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1989331.62465375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 103.44,
    "out:Total Energy Use Post PV": 103.44,
    "out:Primary Energy": 88.237286,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.491943,
    "out:CO2 Operational/m2": 35.540604,
    "out:Total CO2/m2": 49.032547,
    "out:Total CO2 (tons)": 96.692188,
    "out:Klimatpaverkan": 1.760016,
    "out:Initial Cost/MSEK": 2.03863162465375,
    "out:Running cost/(Apt SEK y)": 17678.411765,
    "out:Running Cost over RSP/MSEK": 4.6987,
    "out:LCP/MSEK": -0.408262,
    "out:ROI% old": 11.42482,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 34,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 208,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 70125.742908,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 70125.74,
    "out:DH kr savings": 70125.74290792203,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2038631.62465375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 104.44,
    "out:Primary Energy": 88.812459,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.159286,
    "out:CO2 Operational/m2": 35.900588,
    "out:Total CO2/m2": 50.059874,
    "out:Total CO2 (tons)": 98.718077,
    "out:Klimatpaverkan": 3.785905,
    "out:Initial Cost/MSEK": 2.14870762465375,
    "out:Running cost/(Apt SEK y)": 17853.558824,
    "out:Running Cost over RSP/MSEK": 4.74529,
    "out:LCP/MSEK": -0.564928,
    "out:ROI% old": 10.529785,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 9,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 68523.08402,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68523.08,
    "out:DH kr savings": 68523.08401967339,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2148707.62465375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 100.44,
    "out:Total Energy Use Post PV": 100.44,
    "out:Primary Energy": 86.137286,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.159286,
    "out:CO2 Operational/m2": 34.460652,
    "out:Total CO2/m2": 48.619938,
    "out:Total CO2 (tons)": 95.878523,
    "out:Klimatpaverkan": 0.946351,
    "out:Initial Cost/MSEK": 2.19800762465375,
    "out:Running cost/(Apt SEK y)": 17152.911765,
    "out:Running Cost over RSP/MSEK": 4.55896,
    "out:LCP/MSEK": -0.427898,
    "out:ROI% old": 11.50464,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 38,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75305.792807,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75305.79,
    "out:DH kr savings": 75305.79280737723,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2198007.62465375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 106.44,
    "out:Total Energy Use Post PV": 103.635375,
    "out:Primary Energy": 85.864134,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.753746,
    "out:CO2 Operational/m2": -1.217353,
    "out:Total CO2/m2": 23.536393,
    "out:Total CO2 (tons)": 46.41377,
    "out:Klimatpaverkan": -48.518403,
    "out:Initial Cost/MSEK": 2.3632864926500003,
    "out:Running cost/(Apt SEK y)": 17236.058824,
    "out:Running Cost over RSP/MSEK": 4.58811,
    "out:LCP/MSEK": -0.622326,
    "out:ROI% old": 10.523843,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 223,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 63343.03412,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96249.24,
    "out:DH kr savings": 63343.034120218195,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2363286.4926500004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 103.44,
    "out:Total Energy Use Post PV": 100.635375,
    "out:Primary Energy": 83.188961,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.753746,
    "out:CO2 Operational/m2": -2.297305,
    "out:Total CO2/m2": 22.456441,
    "out:Total CO2 (tons)": 44.284104,
    "out:Klimatpaverkan": -50.648068,
    "out:Initial Cost/MSEK": 2.4125864926500005,
    "out:Running cost/(Apt SEK y)": 16710.558824,
    "out:Running Cost over RSP/MSEK": 4.44837,
    "out:LCP/MSEK": -0.531886,
    "out:ROI% old": 11.13624,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 241,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 70125.742908,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103031.94,
    "out:DH kr savings": 70125.74290792203,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2412586.4926500004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 101.635375,
    "out:Primary Energy": 83.764134,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.421089,
    "out:CO2 Operational/m2": -1.937321,
    "out:Total CO2/m2": 23.483768,
    "out:Total CO2 (tons)": 46.309993,
    "out:Klimatpaverkan": -48.622179,
    "out:Initial Cost/MSEK": 2.5226624926500003,
    "out:Running cost/(Apt SEK y)": 16885.735294,
    "out:Running Cost over RSP/MSEK": 4.49495,
    "out:LCP/MSEK": -0.688542,
    "out:ROI% old": 10.386532,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68523.08402,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101429.29,
    "out:DH kr savings": 68523.08401967339,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2522662.4926500004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 100.44,
    "out:Total Energy Use Post PV": 97.635375,
    "out:Primary Energy": 81.088961,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.421089,
    "out:CO2 Operational/m2": -3.377257,
    "out:Total CO2/m2": 22.043832,
    "out:Total CO2 (tons)": 43.470439,
    "out:Klimatpaverkan": -51.461733,
    "out:Initial Cost/MSEK": 2.5719624926500004,
    "out:Running cost/(Apt SEK y)": 16185.088235,
    "out:Running Cost over RSP/MSEK": 4.30862,
    "out:LCP/MSEK": -0.551512,
    "out:ROI% old": 11.222392,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75305.792807,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108211.99,
    "out:DH kr savings": 75305.79280737723,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2571962.4926500004,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 106.44,
    "out:Total Energy Use Post PV": 103.377795,
    "out:Primary Energy": 85.40049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.015549,
    "out:CO2 Operational/m2": -51.865878,
    "out:Total CO2/m2": -15.850329,
    "out:Total CO2 (tons)": -31.256851,
    "out:Klimatpaverkan": -126.189023,
    "out:Initial Cost/MSEK": 2.7372413781087497,
    "out:Running cost/(Apt SEK y)": 16342.088235,
    "out:Running Cost over RSP/MSEK": 4.3571,
    "out:LCP/MSEK": -0.765271,
    "out:ROI% old": 10.291749,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 34,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 63343.03412,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126644.05,
    "out:DH kr savings": 63343.034120218195,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2737241.3781087496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 103.44,
    "out:Total Energy Use Post PV": 100.377795,
    "out:Primary Energy": 82.725317,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.015549,
    "out:CO2 Operational/m2": -52.94583,
    "out:Total CO2/m2": -16.930281,
    "out:Total CO2 (tons)": -33.386516,
    "out:Klimatpaverkan": -128.318688,
    "out:Initial Cost/MSEK": 2.78654137810875,
    "out:Running cost/(Apt SEK y)": 15816.617647,
    "out:Running Cost over RSP/MSEK": 4.21736,
    "out:LCP/MSEK": -0.674831,
    "out:ROI% old": 10.826068,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 38,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 70125.742908,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133426.76,
    "out:DH kr savings": 70125.74290792203,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2786541.3781087496,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.669161,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 101.377795,
    "out:Primary Energy": 83.30049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.682892,
    "out:CO2 Operational/m2": -52.585846,
    "out:Total CO2/m2": -15.902954,
    "out:Total CO2 (tons)": -31.360627,
    "out:Klimatpaverkan": -126.292799,
    "out:Initial Cost/MSEK": 2.896617378108749,
    "out:Running cost/(Apt SEK y)": 15991.764706,
    "out:Running Cost over RSP/MSEK": 4.26394,
    "out:LCP/MSEK": -0.831487,
    "out:ROI% old": 10.184934,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 68523.08402,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131824.1,
    "out:DH kr savings": 68523.08401967339,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 161051.594013,
    "out:% savings (space heating)": 25.356558,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.67,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2896617.378108749,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.229653,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 100.44,
    "out:Total Energy Use Post PV": 97.377795,
    "out:Primary Energy": 80.625317,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.682892,
    "out:CO2 Operational/m2": -54.025782,
    "out:Total CO2/m2": -17.34289,
    "out:Total CO2 (tons)": -34.200181,
    "out:Klimatpaverkan": -129.132353,
    "out:Initial Cost/MSEK": 2.9459173781087493,
    "out:Running cost/(Apt SEK y)": 15291.117647,
    "out:Running Cost over RSP/MSEK": 4.07761,
    "out:LCP/MSEK": -0.694457,
    "out:ROI% old": 10.918064,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 42.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 75305.792807,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138606.81,
    "out:DH kr savings": 75305.79280737723,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 154268.885226,
    "out:% savings (space heating)": 28.500177,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.23,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2945917.378108749,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.279953,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.611987,
    "out:Electricity (inc PV)": 29.016797,
    "out:Total Energy Use Pre PV": 69.611987,
    "out:Total Energy Use Post PV": 70.016797,
    "out:Primary Energy": 85.716743,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.322444,
    "out:CO2 Operational/m2": 18.536355,
    "out:Total CO2/m2": 36.858799,
    "out:Total CO2 (tons)": 72.685556,
    "out:Klimatpaverkan": -22.246616,
    "out:Initial Cost/MSEK": 3.750587767899366,
    "out:Running cost/(Apt SEK y)": 10500.558824,
    "out:Running Cost over RSP/MSEK": 2.78224,
    "out:LCP/MSEK": -0.203758,
    "out:ROI% old": 13.509616,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 97.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2002.83909,
    "out:Return %": 12,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.872487,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 177524.222108,
    "out:EL kWh savings": -42549.446144,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92425.33,
    "out:DH kr savings": 177524.22210815584,
    "out:El kr savings": -85098.89228750969,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 81404.072022,
    "out:% savings (space heating)": 62.27122,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.05,
    "out:Etvv": 0,
    "out:Ef": 29.02,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3750587.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": 3.777011,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.777011,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.077475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.54016,
    "out:Electricity (inc PV)": 28.953171,
    "out:Total Energy Use Pre PV": 66.54016,
    "out:Total Energy Use Post PV": 66.953171,
    "out:Primary Energy": 83.090349,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.322444,
    "out:CO2 Operational/m2": 17.446921,
    "out:Total CO2/m2": 35.769365,
    "out:Total CO2 (tons)": 70.537192,
    "out:Klimatpaverkan": -24.39498,
    "out:Initial Cost/MSEK": 3.799887767899366,
    "out:Running cost/(Apt SEK y)": 9966.735294,
    "out:Running Cost over RSP/MSEK": 2.64031,
    "out:LCP/MSEK": -0.111128,
    "out:ROI% old": 13.867928,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1997.8112,
    "out:Return %": 12,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.80066,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 183839.509089,
    "out:EL kWh savings": -42423.975665,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98991.56,
    "out:DH kr savings": 183839.50908923836,
    "out:El kr savings": -84847.95132900297,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75088.785041,
    "out:% savings (space heating)": 65.1982,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 39.82,
    "out:Etvv": 0,
    "out:Ef": 28.95,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3799887.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": 3.767529,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.767529,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.444261,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.173257,
    "out:Electricity (inc PV)": 28.527407,
    "out:Total Energy Use Pre PV": 67.173257,
    "out:Total Energy Use Post PV": 67.527407,
    "out:Primary Energy": 83.175273,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.989787,
    "out:CO2 Operational/m2": 17.758471,
    "out:Total CO2/m2": 36.748258,
    "out:Total CO2 (tons)": 72.467569,
    "out:Klimatpaverkan": -22.464603,
    "out:Initial Cost/MSEK": 3.9099637678993657,
    "out:Running cost/(Apt SEK y)": 10099.352941,
    "out:Running Cost over RSP/MSEK": 2.6757,
    "out:LCP/MSEK": -0.256594,
    "out:ROI% old": 13.348206,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1972.12799,
    "out:Return %": 12,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.433757,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 181144.206941,
    "out:EL kWh savings": -41584.369008,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97975.47,
    "out:DH kr savings": 181144.20694142475,
    "out:El kr savings": -83168.73801592871,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 77784.087189,
    "out:% savings (space heating)": 63.948994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.92,
    "out:Etvv": 0,
    "out:Ef": 28.53,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3909963.7678993656,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": 3.719095,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.719095,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.26959,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.093004,
    "out:Electricity (inc PV)": 28.453952,
    "out:Total Energy Use Pre PV": 64.093004,
    "out:Total Energy Use Post PV": 64.453952,
    "out:Primary Energy": 80.556419,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.989787,
    "out:CO2 Operational/m2": 16.667925,
    "out:Total CO2/m2": 35.657712,
    "out:Total CO2 (tons)": 70.317012,
    "out:Klimatpaverkan": -24.61516,
    "out:Initial Cost/MSEK": 3.959263767899366,
    "out:Running cost/(Apt SEK y)": 9564.558824,
    "out:Running Cost over RSP/MSEK": 2.53351,
    "out:LCP/MSEK": -0.163704,
    "out:ROI% old": 13.695043,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1966.51028,
    "out:Return %": 12,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.353504,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 187404.658515,
    "out:EL kWh savings": -41439.51574,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104525.63,
    "out:DH kr savings": 187404.65851533724,
    "out:El kr savings": -82879.031479181,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 71523.635615,
    "out:% savings (space heating)": 66.850559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.72,
    "out:Etvv": 0,
    "out:Ef": 28.45,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3959263.7678993656,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": 3.708501,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.708501,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.279953,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.611987,
    "out:Electricity (inc PV)": 21.610647,
    "out:Total Energy Use Pre PV": 69.611987,
    "out:Total Energy Use Post PV": 62.610647,
    "out:Primary Energy": 72.385673,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.584247,
    "out:CO2 Operational/m2": 3.427495,
    "out:Total CO2/m2": 33.011742,
    "out:Total CO2 (tons)": 65.099159,
    "out:Klimatpaverkan": -29.833013,
    "out:Initial Cost/MSEK": 4.124542635895617,
    "out:Running cost/(Apt SEK y)": 9401.029412,
    "out:Running Cost over RSP/MSEK": 2.49763,
    "out:LCP/MSEK": -0.293103,
    "out:ROI% old": 13.270528,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 119,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1503.573989,
    "out:Return %": 12,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.872487,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5607.992867,
    "out:PV (% sold (El))": 27.908941,
    "out:PV (kWh self-consumed)": 14485.900411,
    "out:PV (ratio sold/self-consumed)": 0.387135,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177524.222108,
    "out:EL kWh savings": -27944.517499,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130047.18,
    "out:DH kr savings": 177524.22210815584,
    "out:El kr savings": -55889.03499890751,
    "out:El kr sold": 8411.989299996209,
    "out:El kr saved": 28971.800822,
    "out:El kr return": 37383.79012199621,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 81404.072022,
    "out:% savings (space heating)": 62.27122,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.05,
    "out:Etvv": 0,
    "out:Ef": 21.61,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4124542.635895617,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -11.331849,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.145769,
    "out:Bought CO2": 2.81392,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.077475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.54016,
    "out:Electricity (inc PV)": 21.556426,
    "out:Total Energy Use Pre PV": 66.54016,
    "out:Total Energy Use Post PV": 59.556426,
    "out:Primary Energy": 69.776208,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.584247,
    "out:CO2 Operational/m2": 2.284483,
    "out:Total CO2/m2": 31.86873,
    "out:Total CO2 (tons)": 62.845139,
    "out:Klimatpaverkan": -32.087033,
    "out:Initial Cost/MSEK": 4.173842635895617,
    "out:Running cost/(Apt SEK y)": 8867.529412,
    "out:Running Cost over RSP/MSEK": 2.35578,
    "out:LCP/MSEK": -0.200553,
    "out:ROI% old": 13.599287,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1499.245241,
    "out:Return %": 12,
    "out:Return/kSEK/y": 508,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.80066,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5629.308849,
    "out:PV (% sold (El))": 28.015023,
    "out:PV (kWh self-consumed)": 14464.584428,
    "out:PV (ratio sold/self-consumed)": 0.389179,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 183839.509089,
    "out:EL kWh savings": -27837.593681,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136608.29,
    "out:DH kr savings": 183839.50908923836,
    "out:El kr savings": -55675.187362545126,
    "out:El kr sold": 8443.963274089032,
    "out:El kr saved": 28929.168856,
    "out:El kr return": 37373.132130089034,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75088.785041,
    "out:% savings (space heating)": 65.1982,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 39.82,
    "out:Etvv": 0,
    "out:Ef": 21.56,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4173842.635895617,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": -11.394909,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.200748,
    "out:Bought CO2": 2.805839,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.444261,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.173257,
    "out:Electricity (inc PV)": 21.194347,
    "out:Total Energy Use Pre PV": 67.173257,
    "out:Total Energy Use Post PV": 60.194347,
    "out:Primary Energy": 69.975765,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.25159,
    "out:CO2 Operational/m2": 2.320012,
    "out:Total CO2/m2": 32.571602,
    "out:Total CO2 (tons)": 64.231203,
    "out:Klimatpaverkan": -30.700969,
    "out:Initial Cost/MSEK": 4.283918635895616,
    "out:Running cost/(Apt SEK y)": 9001.735294,
    "out:Running Cost over RSP/MSEK": 2.39159,
    "out:LCP/MSEK": -0.346439,
    "out:ROI% old": 13.130435,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1477.165103,
    "out:Return %": 12,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.433757,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5738.813523,
    "out:PV (% sold (El))": 28.559988,
    "out:PV (kWh self-consumed)": 14355.079755,
    "out:PV (ratio sold/self-consumed)": 0.399776,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 181144.206941,
    "out:EL kWh savings": -27123.573852,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135505.28,
    "out:DH kr savings": 181144.20694142475,
    "out:El kr savings": -54247.14770399108,
    "out:El kr sold": 8608.22028426197,
    "out:El kr saved": 28710.15951,
    "out:El kr return": 37318.37979426197,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 77784.087189,
    "out:% savings (space heating)": 63.948994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.92,
    "out:Etvv": 0,
    "out:Ef": 21.19,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4283918.635895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -11.719364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.483983,
    "out:Bought CO2": 2.764619,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.26959,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.093004,
    "out:Electricity (inc PV)": 21.131963,
    "out:Total Energy Use Pre PV": 64.093004,
    "out:Total Energy Use Post PV": 57.131963,
    "out:Primary Energy": 67.376838,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.25159,
    "out:CO2 Operational/m2": 1.16862,
    "out:Total CO2/m2": 31.42021,
    "out:Total CO2 (tons)": 61.960658,
    "out:Klimatpaverkan": -32.971515,
    "out:Initial Cost/MSEK": 4.333218635895617,
    "out:Running cost/(Apt SEK y)": 8467.294118,
    "out:Running Cost over RSP/MSEK": 2.24949,
    "out:LCP/MSEK": -0.253639,
    "out:ROI% old": 13.449521,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 142.1,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1472.342629,
    "out:Return %": 12,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.353504,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5762.862051,
    "out:PV (% sold (El))": 28.679669,
    "out:PV (kWh self-consumed)": 14331.031226,
    "out:PV (ratio sold/self-consumed)": 0.402125,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 187404.658515,
    "out:EL kWh savings": -27000.552597,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142047.85,
    "out:DH kr savings": 187404.65851533724,
    "out:El kr savings": -54001.1051937675,
    "out:El kr sold": 8644.293076600145,
    "out:El kr saved": 28662.062452,
    "out:El kr return": 37306.35552860014,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 71523.635615,
    "out:% savings (space heating)": 66.850559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.72,
    "out:Etvv": 0,
    "out:Ef": 21.13,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4333218.635895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -11.790804,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.546419,
    "out:Bought CO2": 2.755615,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.279953,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.611987,
    "out:Electricity (inc PV)": 19.58109,
    "out:Total Energy Use Pre PV": 69.611987,
    "out:Total Energy Use Post PV": 60.58109,
    "out:Primary Energy": 68.73247,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.84605,
    "out:CO2 Operational/m2": -38.422733,
    "out:Total CO2/m2": 2.423317,
    "out:Total CO2 (tons)": 4.778781,
    "out:Klimatpaverkan": -90.153391,
    "out:Initial Cost/MSEK": 4.498497521354366,
    "out:Running cost/(Apt SEK y)": 8456.676471,
    "out:Running Cost over RSP/MSEK": 2.25334,
    "out:LCP/MSEK": -0.422768,
    "out:ROI% old": 12.943147,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 126.2,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 1361.691956,
    "out:Return %": 12,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.872487,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21768.392617,
    "out:PV (% sold (El))": 54.166687,
    "out:PV (kWh self-consumed)": 18419.393937,
    "out:PV (ratio sold/self-consumed)": 1.181819,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177524.222108,
    "out:EL kWh savings": -23942.230864,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162292.35,
    "out:DH kr savings": 177524.22210815584,
    "out:El kr savings": -47884.46172816853,
    "out:El kr sold": 32652.588925870477,
    "out:El kr saved": 36838.787874,
    "out:El kr return": 69491.37679987049,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 81404.072022,
    "out:% savings (space heating)": 62.27122,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.05,
    "out:Etvv": 0,
    "out:Ef": 19.58,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4498497.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.759344,
    "out:EL CO2": -53.182077,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.73172,
    "out:Bought CO2": 2.549643,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.077475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.54016,
    "out:Electricity (inc PV)": 19.532393,
    "out:Total Energy Use Pre PV": 66.54016,
    "out:Total Energy Use Post PV": 57.532393,
    "out:Primary Energy": 66.132948,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.84605,
    "out:CO2 Operational/m2": -39.597177,
    "out:Total CO2/m2": 1.248873,
    "out:Total CO2 (tons)": 2.462778,
    "out:Klimatpaverkan": -92.469395,
    "out:Initial Cost/MSEK": 4.547797521354366,
    "out:Running cost/(Apt SEK y)": 7923.352941,
    "out:Running Cost over RSP/MSEK": 2.11154,
    "out:LCP/MSEK": -0.330268,
    "out:ROI% old": 13.248265,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1357.807462,
    "out:Return %": 12,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.80066,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21801.865391,
    "out:PV (% sold (El))": 54.249978,
    "out:PV (kWh self-consumed)": 18385.921164,
    "out:PV (ratio sold/self-consumed)": 1.185791,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 183839.509089,
    "out:EL kWh savings": -23846.200375,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168849.91,
    "out:DH kr savings": 183839.50908923836,
    "out:El kr savings": -47692.40074906559,
    "out:El kr sold": 32702.79808602388,
    "out:El kr saved": 36771.842328,
    "out:El kr return": 69474.64041402387,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75088.785041,
    "out:% savings (space heating)": 65.1982,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 39.82,
    "out:Etvv": 0,
    "out:Ef": 19.53,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4547797.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": -53.276569,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.818951,
    "out:Bought CO2": 2.542382,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.444261,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.173257,
    "out:Electricity (inc PV)": 19.206937,
    "out:Total Energy Use Pre PV": 67.173257,
    "out:Total Energy Use Post PV": 58.206937,
    "out:Primary Energy": 66.398427,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.513393,
    "out:CO2 Operational/m2": -39.721758,
    "out:Total CO2/m2": 1.791635,
    "out:Total CO2 (tons)": 3.533104,
    "out:Klimatpaverkan": -91.399068,
    "out:Initial Cost/MSEK": 4.657873521354366,
    "out:Running cost/(Apt SEK y)": 8058.5,
    "out:Running Cost over RSP/MSEK": 2.14759,
    "out:LCP/MSEK": -0.476394,
    "out:ROI% old": 12.824614,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1337.991303,
    "out:Return %": 11,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.433757,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21973.795233,
    "out:PV (% sold (El))": 54.677794,
    "out:PV (kWh self-consumed)": 18213.991322,
    "out:PV (ratio sold/self-consumed)": 1.206424,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 181144.206941,
    "out:EL kWh savings": -23204.401105,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167696.1,
    "out:DH kr savings": 181144.20694142475,
    "out:El kr savings": -46408.80221086156,
    "out:El kr sold": 32960.692848919156,
    "out:El kr saved": 36427.982644,
    "out:El kr return": 69388.67549291917,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 77784.087189,
    "out:% savings (space heating)": 63.948994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.92,
    "out:Etvv": 0,
    "out:Ef": 19.21,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4657873.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -53.761134,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.266479,
    "out:Bought CO2": 2.505345,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.26959,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.093004,
    "out:Electricity (inc PV)": 19.150885,
    "out:Total Energy Use Pre PV": 64.093004,
    "out:Total Energy Use Post PV": 55.150885,
    "out:Primary Energy": 63.810898,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.513393,
    "out:CO2 Operational/m2": -40.908151,
    "out:Total CO2/m2": 0.605242,
    "out:Total CO2 (tons)": 1.193537,
    "out:Klimatpaverkan": -93.738635,
    "out:Initial Cost/MSEK": 4.707173521354366,
    "out:Running cost/(Apt SEK y)": 7524.264706,
    "out:Running Cost over RSP/MSEK": 2.00554,
    "out:LCP/MSEK": -0.383644,
    "out:ROI% old": 13.121402,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 150.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1333.662829,
    "out:Return %": 12,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.353504,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22011.629737,
    "out:PV (% sold (El))": 54.771939,
    "out:PV (kWh self-consumed)": 18176.156818,
    "out:PV (ratio sold/self-consumed)": 1.211017,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 187404.658515,
    "out:EL kWh savings": -23093.866555,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174234.37,
    "out:DH kr savings": 187404.65851533724,
    "out:El kr savings": -46187.733110081725,
    "out:El kr sold": 33017.444604901204,
    "out:El kr saved": 36352.313636,
    "out:El kr return": 69369.7582409012,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 71523.635615,
    "out:% savings (space heating)": 66.850559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.72,
    "out:Etvv": 0,
    "out:Ef": 19.15,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4707173.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -53.867575,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.36483,
    "out:Bought CO2": 2.497255,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.840416,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.024574,
    "out:Electricity (inc PV)": 28.360787,
    "out:Total Energy Use Pre PV": 67.024574,
    "out:Total Energy Use Post PV": 67.360787,
    "out:Primary Energy": 82.330795,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.322444,
    "out:CO2 Operational/m2": 17.738844,
    "out:Total CO2/m2": 36.061288,
    "out:Total CO2 (tons)": 71.112864,
    "out:Klimatpaverkan": -23.819308,
    "out:Initial Cost/MSEK": 3.840324392899366,
    "out:Running cost/(Apt SEK y)": 10082.088235,
    "out:Running Cost over RSP/MSEK": 2.67117,
    "out:LCP/MSEK": -0.182424,
    "out:ROI% old": 13.607109,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1961.72018,
    "out:Return %": 12,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.285074,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182334.98935,
    "out:EL kWh savings": -41255.794349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99823.4,
    "out:DH kr savings": 182334.98935026306,
    "out:El kr savings": -82511.58869793944,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 76593.30478,
    "out:% savings (space heating)": 64.500892,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.22,
    "out:Etvv": 0,
    "out:Ef": 28.36,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3840324.3928993656,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": 3.699468,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.699468,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.674891,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.941549,
    "out:Electricity (inc PV)": 28.284195,
    "out:Total Energy Use Pre PV": 63.941549,
    "out:Total Energy Use Post PV": 64.284195,
    "out:Primary Energy": 79.714341,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.322444,
    "out:CO2 Operational/m2": 16.647932,
    "out:Total CO2/m2": 34.970376,
    "out:Total CO2 (tons)": 68.961585,
    "out:Klimatpaverkan": -25.970587,
    "out:Initial Cost/MSEK": 3.8896243928993655,
    "out:Running cost/(Apt SEK y)": 9546.970588,
    "out:Running Cost over RSP/MSEK": 2.52889,
    "out:LCP/MSEK": -0.089444,
    "out:ROI% old": 13.957205,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1955.90843,
    "out:Return %": 12,
    "out:Return/kSEK/y": 485,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.202049,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188577.405011,
    "out:EL kWh savings": -41104.754916,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106367.9,
    "out:DH kr savings": 188577.4050111329,
    "out:El kr savings": -82209.50983247644,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 70350.889119,
    "out:% savings (space heating)": 67.394098,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.03,
    "out:Etvv": 0,
    "out:Ef": 28.28,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3889624.3928993656,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": 3.688508,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.688508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.406843,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.66299,
    "out:Electricity (inc PV)": 27.954661,
    "out:Total Energy Use Pre PV": 64.66299,
    "out:Total Energy Use Post PV": 64.954661,
    "out:Primary Energy": 80.308823,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.989787,
    "out:CO2 Operational/m2": 16.971144,
    "out:Total CO2/m2": 35.960931,
    "out:Total CO2 (tons)": 70.91496,
    "out:Klimatpaverkan": -24.017212,
    "out:Initial Cost/MSEK": 3.9997003928993653,
    "out:Running cost/(Apt SEK y)": 9689.823529,
    "out:Running Cost over RSP/MSEK": 2.56698,
    "out:LCP/MSEK": -0.23761,
    "out:ROI% old": 13.437042,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 112.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1936.4093,
    "out:Return %": 12,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.92349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185161.99547,
    "out:EL kWh savings": -40454.913831,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104252.17,
    "out:DH kr savings": 185161.9954696904,
    "out:El kr savings": -80909.82766134266,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 73766.29866,
    "out:% savings (space heating)": 65.81114,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.56,
    "out:Etvv": 0,
    "out:Ef": 27.95,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3999700.392899365,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": 3.651736,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.651736,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.262857,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.573438,
    "out:Electricity (inc PV)": 27.87036,
    "out:Total Energy Use Pre PV": 61.573438,
    "out:Total Energy Use Post PV": 61.87036,
    "out:Primary Energy": 77.698281,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.989787,
    "out:CO2 Operational/m2": 15.87937,
    "out:Total CO2/m2": 34.869157,
    "out:Total CO2 (tons)": 68.761982,
    "out:Klimatpaverkan": -26.170191,
    "out:Initial Cost/MSEK": 4.049000392899365,
    "out:Running cost/(Apt SEK y)": 9153.970588,
    "out:Running Cost over RSP/MSEK": 2.42451,
    "out:LCP/MSEK": -0.14444,
    "out:ROI% old": 13.776099,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1930.14066,
    "out:Return %": 12,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.833938,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191361.93622,
    "out:EL kWh savings": -40288.672249,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110784.59,
    "out:DH kr savings": 191361.9362201048,
    "out:El kr savings": -80577.3444981221,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 67566.35791,
    "out:% savings (space heating)": 68.68466,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 35.4,
    "out:Etvv": 0,
    "out:Ef": 27.87,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4049000.392899365,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": 3.639914,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.639914,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.840416,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.024574,
    "out:Electricity (inc PV)": 21.052889,
    "out:Total Energy Use Pre PV": 67.024574,
    "out:Total Energy Use Post PV": 60.052889,
    "out:Primary Energy": 69.176578,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.584247,
    "out:CO2 Operational/m2": 2.187537,
    "out:Total CO2/m2": 31.771784,
    "out:Total CO2 (tons)": 62.653962,
    "out:Klimatpaverkan": -32.278211,
    "out:Initial Cost/MSEK": 4.2142792608956166,
    "out:Running cost/(Apt SEK y)": 8985.147059,
    "out:Running Cost over RSP/MSEK": 2.38723,
    "out:LCP/MSEK": -0.272439,
    "out:ROI% old": 13.36219,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1468.23247,
    "out:Return %": 12,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.285074,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5783.433254,
    "out:PV (% sold (El))": 28.782044,
    "out:PV (kWh self-consumed)": 14310.460023,
    "out:PV (ratio sold/self-consumed)": 0.40414,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182334.98935,
    "out:EL kWh savings": -26844.61866,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137320.9,
    "out:DH kr savings": 182334.98935026306,
    "out:El kr savings": -53689.23731973866,
    "out:El kr sold": 8675.14988117716,
    "out:El kr saved": 28620.920046,
    "out:El kr return": 37296.06992717716,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 76593.30478,
    "out:% savings (space heating)": 64.500892,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.22,
    "out:Etvv": 0,
    "out:Ef": 21.05,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4214279.260895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -11.851839,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.59978,
    "out:Bought CO2": 2.747941,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.674891,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.941549,
    "out:Electricity (inc PV)": 20.987906,
    "out:Total Energy Use Pre PV": 63.941549,
    "out:Total Energy Use Post PV": 56.987906,
    "out:Primary Energy": 66.581021,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.584247,
    "out:CO2 Operational/m2": 1.033372,
    "out:Total CO2/m2": 30.617619,
    "out:Total CO2 (tons)": 60.377948,
    "out:Klimatpaverkan": -34.554224,
    "out:Initial Cost/MSEK": 4.263579260895616,
    "out:Running cost/(Apt SEK y)": 8450.411765,
    "out:Running Cost over RSP/MSEK": 2.24504,
    "out:LCP/MSEK": -0.179549,
    "out:ROI% old": 13.68411,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 142.1,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1463.248211,
    "out:Return %": 12,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.202049,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5808.44089,
    "out:PV (% sold (El))": 28.906498,
    "out:PV (kWh self-consumed)": 14285.452387,
    "out:PV (ratio sold/self-consumed)": 0.406598,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188577.405011,
    "out:EL kWh savings": -26716.472176,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143857.12,
    "out:DH kr savings": 188577.4050111329,
    "out:El kr savings": -53432.94435292254,
    "out:El kr sold": 8712.661334949818,
    "out:El kr saved": 28570.904774,
    "out:El kr return": 37283.56610894982,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 70350.889119,
    "out:% savings (space heating)": 67.394098,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.03,
    "out:Etvv": 0,
    "out:Ef": 20.99,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4263579.260895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -11.926052,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.664686,
    "out:Bought CO2": 2.738634,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.406843,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.66299,
    "out:Electricity (inc PV)": 20.708512,
    "out:Total Energy Use Pre PV": 64.66299,
    "out:Total Energy Use Post PV": 57.708512,
    "out:Primary Energy": 67.265755,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.25159,
    "out:CO2 Operational/m2": 1.143054,
    "out:Total CO2/m2": 31.394644,
    "out:Total CO2 (tons)": 61.910242,
    "out:Klimatpaverkan": -33.021931,
    "out:Initial Cost/MSEK": 4.3736552608956165,
    "out:Running cost/(Apt SEK y)": 8594.5,
    "out:Running Cost over RSP/MSEK": 2.28346,
    "out:LCP/MSEK": -0.328045,
    "out:ROI% old": 13.214217,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1446.54501,
    "out:Return %": 12,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.92349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5893.033324,
    "out:PV (% sold (El))": 29.327484,
    "out:PV (kWh self-consumed)": 14200.859954,
    "out:PV (ratio sold/self-consumed)": 0.414977,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185161.99547,
    "out:EL kWh savings": -26165.507177,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141670.53,
    "out:DH kr savings": 185161.9954696904,
    "out:El kr savings": -52331.01435322067,
    "out:El kr sold": 8839.549985735863,
    "out:El kr saved": 28401.719908,
    "out:El kr return": 37241.269893735865,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 73766.29866,
    "out:% savings (space heating)": 65.81114,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.56,
    "out:Etvv": 0,
    "out:Ef": 20.71,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4373655.260895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": -12.176354,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.883796,
    "out:Bought CO2": 2.707442,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.262857,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.573438,
    "out:Electricity (inc PV)": 20.637134,
    "out:Total Energy Use Pre PV": 61.573438,
    "out:Total Energy Use Post PV": 54.637134,
    "out:Primary Energy": 64.678474,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.25159,
    "out:CO2 Operational/m2": -0.017769,
    "out:Total CO2/m2": 30.233821,
    "out:Total CO2 (tons)": 59.621098,
    "out:Klimatpaverkan": -35.311074,
    "out:Initial Cost/MSEK": 4.422955260895616,
    "out:Running cost/(Apt SEK y)": 8059.029412,
    "out:Running Cost over RSP/MSEK": 2.14108,
    "out:LCP/MSEK": -0.234965,
    "out:ROI% old": 13.526799,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 150.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1441.181725,
    "out:Return %": 12,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.833938,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5920.368192,
    "out:PV (% sold (El))": 29.46352,
    "out:PV (kWh self-consumed)": 14173.525085,
    "out:PV (ratio sold/self-consumed)": 0.417706,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191361.93622,
    "out:EL kWh savings": -26024.749752,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148192.99,
    "out:DH kr savings": 191361.9362201048,
    "out:El kr savings": -52049.49950494656,
    "out:El kr sold": 8880.552288305225,
    "out:El kr saved": 28347.05017,
    "out:El kr return": 37227.60245830522,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 67566.35791,
    "out:% savings (space heating)": 68.68466,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 35.4,
    "out:Etvv": 0,
    "out:Ef": 20.64,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4422955.260895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": -12.257225,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.95465,
    "out:Bought CO2": 2.697425,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.840416,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.024574,
    "out:Electricity (inc PV)": 19.079818,
    "out:Total Energy Use Pre PV": 67.024574,
    "out:Total Energy Use Post PV": 58.079818,
    "out:Primary Energy": 65.62505,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.84605,
    "out:CO2 Operational/m2": -39.919107,
    "out:Total CO2/m2": 0.926943,
    "out:Total CO2 (tons)": 1.827932,
    "out:Klimatpaverkan": -93.104241,
    "out:Initial Cost/MSEK": 4.588234146354366,
    "out:Running cost/(Apt SEK y)": 8042.294118,
    "out:Running Cost over RSP/MSEK": 2.14333,
    "out:LCP/MSEK": -0.402494,
    "out:ROI% old": 13.032527,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1329.973767,
    "out:Return %": 12,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.285074,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22043.949343,
    "out:PV (% sold (El))": 54.85236,
    "out:PV (kWh self-consumed)": 18143.837212,
    "out:PV (ratio sold/self-consumed)": 1.214955,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 182334.98935,
    "out:EL kWh savings": -22953.722423,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169493.47,
    "out:DH kr savings": 182334.98935026306,
    "out:El kr savings": -45907.44484587851,
    "out:El kr sold": 33065.92401447792,
    "out:El kr saved": 36287.674424,
    "out:El kr return": 69353.59843847791,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 76593.30478,
    "out:% savings (space heating)": 64.500892,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.22,
    "out:Etvv": 0,
    "out:Ef": 19.08,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4588234.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -53.958483,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.448845,
    "out:Bought CO2": 2.490362,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.674891,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.941549,
    "out:Electricity (inc PV)": 19.02142,
    "out:Total Energy Use Pre PV": 63.941549,
    "out:Total Energy Use Post PV": 55.02142,
    "out:Primary Energy": 63.041346,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.84605,
    "out:CO2 Operational/m2": -41.109538,
    "out:Total CO2/m2": -0.263488,
    "out:Total CO2 (tons)": -0.519598,
    "out:Klimatpaverkan": -95.451771,
    "out:Initial Cost/MSEK": 4.6375341463543664,
    "out:Running cost/(Apt SEK y)": 7507.735294,
    "out:Running Cost over RSP/MSEK": 2.0012,
    "out:LCP/MSEK": -0.309664,
    "out:ROI% old": 13.331808,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 150.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1325.500074,
    "out:Return %": 12,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.202049,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22083.251517,
    "out:PV (% sold (El))": 54.950156,
    "out:PV (kWh self-consumed)": 18104.535038,
    "out:PV (ratio sold/self-consumed)": 1.219764,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188577.405011,
    "out:EL kWh savings": -22838.56156,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176025.16,
    "out:DH kr savings": 188577.4050111329,
    "out:El kr savings": -45677.12312056378,
    "out:El kr sold": 33124.87727567922,
    "out:El kr saved": 36209.070076,
    "out:El kr return": 69333.94735167922,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 70350.889119,
    "out:% savings (space heating)": 67.394098,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.03,
    "out:Etvv": 0,
    "out:Ef": 19.02,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4637534.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -54.068962,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.550964,
    "out:Bought CO2": 2.482002,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.406843,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.66299,
    "out:Electricity (inc PV)": 18.770714,
    "out:Total Energy Use Pre PV": 64.66299,
    "out:Total Energy Use Post PV": 55.770714,
    "out:Primary Energy": 63.777718,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.513393,
    "out:CO2 Operational/m2": -41.121671,
    "out:Total CO2/m2": 0.391722,
    "out:Total CO2 (tons)": 0.772476,
    "out:Klimatpaverkan": -94.159696,
    "out:Initial Cost/MSEK": 4.747610146354366,
    "out:Running cost/(Apt SEK y)": 7652.529412,
    "out:Running Cost over RSP/MSEK": 2.03979,
    "out:LCP/MSEK": -0.45833,
    "out:ROI% old": 12.906584,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 146.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1310.50833,
    "out:Return %": 12,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.92349,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22215.695338,
    "out:PV (% sold (El))": 55.279719,
    "out:PV (kWh self-consumed)": 17972.091216,
    "out:PV (ratio sold/self-consumed)": 1.236122,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185161.99547,
    "out:EL kWh savings": -22344.1693,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173797.2,
    "out:DH kr savings": 185161.9954696904,
    "out:El kr savings": -44688.33859940278,
    "out:El kr sold": 33323.54300752957,
    "out:El kr saved": 35944.182432,
    "out:El kr return": 69267.72543952957,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 73766.29866,
    "out:% savings (space heating)": 65.81114,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.56,
    "out:Etvv": 0,
    "out:Ef": 18.77,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4747610.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": -54.441079,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.895073,
    "out:Bought CO2": 2.453994,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.262857,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.573438,
    "out:Electricity (inc PV)": 18.706668,
    "out:Total Energy Use Pre PV": 61.573438,
    "out:Total Energy Use Post PV": 52.706668,
    "out:Primary Energy": 61.203635,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.513393,
    "out:CO2 Operational/m2": -42.321733,
    "out:Total CO2/m2": -0.80834,
    "out:Total CO2 (tons)": -1.594047,
    "out:Klimatpaverkan": -96.526219,
    "out:Initial Cost/MSEK": 4.796910146354366,
    "out:Running cost/(Apt SEK y)": 7117.294118,
    "out:Running Cost over RSP/MSEK": 1.89748,
    "out:LCP/MSEK": -0.36532,
    "out:ROI% old": 13.197752,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 160.4,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 34,
    "out:El bought/kWh/m2": 1305.69473,
    "out:Return %": 12,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.833938,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22258.428263,
    "out:PV (% sold (El))": 55.386052,
    "out:PV (kWh self-consumed)": 17929.358292,
    "out:PV (ratio sold/self-consumed)": 1.241451,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 191361.93622,
    "out:EL kWh savings": -22217.87058,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180313.84,
    "out:DH kr savings": 191361.9362201048,
    "out:El kr savings": -44435.74116080033,
    "out:El kr sold": 33387.6423947243,
    "out:El kr saved": 35858.716584,
    "out:El kr return": 69246.35897872431,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 67566.35791,
    "out:% savings (space heating)": 68.68466,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 35.4,
    "out:Etvv": 0,
    "out:Ef": 18.71,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4796910.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.239456,
    "out:EL CO2": -54.561189,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.006191,
    "out:Bought CO2": 2.445002,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.180111,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.945273,
    "out:Electricity (inc PV)": 39.64015,
    "out:Total Energy Use Pre PV": 45.945273,
    "out:Total Energy Use Post PV": 46.64015,
    "out:Primary Energy": 77.572171,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.761399,
    "out:CO2 Operational/m2": 7.660976,
    "out:Total CO2/m2": 26.422375,
    "out:Total CO2 (tons)": 52.104927,
    "out:Klimatpaverkan": -42.827246,
    "out:Initial Cost/MSEK": 4.546087767899366,
    "out:Running cost/(Apt SEK y)": 5743.764706,
    "out:Running Cost over RSP/MSEK": 1.51353,
    "out:LCP/MSEK": 0.269452,
    "out:ROI% old": 15.132446,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 193.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2726.16911,
    "out:Return %": 14,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.205773,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244769.11442,
    "out:EL kWh savings": -63498.699471,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117771.72,
    "out:DH kr savings": 244769.11441953786,
    "out:El kr savings": -126997.39894163416,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14159.179711,
    "out:% savings (space heating)": 93.437569,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8,
    "out:Etvv": 0,
    "out:Ef": 39.64,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4546087.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 5.141088,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.141088,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.420524,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.436206,
    "out:Electricity (inc PV)": 39.160732,
    "out:Total Energy Use Pre PV": 43.436206,
    "out:Total Energy Use Post PV": 44.160732,
    "out:Primary Energy": 75.264554,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.761399,
    "out:CO2 Operational/m2": 6.873807,
    "out:Total CO2/m2": 25.635206,
    "out:Total CO2 (tons)": 50.552629,
    "out:Klimatpaverkan": -44.379543,
    "out:Initial Cost/MSEK": 4.595387767899366,
    "out:Running cost/(Apt SEK y)": 5334.411765,
    "out:Running Cost over RSP/MSEK": 1.40484,
    "out:LCP/MSEK": 0.328842,
    "out:ROI% old": 15.307988,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 213.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2690.53442,
    "out:Return %": 14,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 28.696706,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248239.020184,
    "out:EL kWh savings": -62553.28712,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123132.45,
    "out:DH kr savings": 248239.02018413076,
    "out:El kr savings": -125106.57424032687,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10689.273946,
    "out:% savings (space heating)": 95.045785,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.14,
    "out:Etvv": 0,
    "out:Ef": 39.16,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4595387.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 5.073887,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.073887,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.37141,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.195334,
    "out:Electricity (inc PV)": 38.800475,
    "out:Total Energy Use Pre PV": 44.195334,
    "out:Total Energy Use Post PV": 44.800475,
    "out:Primary Energy": 75.299255,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.428742,
    "out:CO2 Operational/m2": 7.201994,
    "out:Total CO2/m2": 26.630736,
    "out:Total CO2 (tons)": 52.515814,
    "out:Klimatpaverkan": -42.416358,
    "out:Initial Cost/MSEK": 4.705463767899366,
    "out:Running cost/(Apt SEK y)": 5481.617647,
    "out:Running Cost over RSP/MSEK": 1.44408,
    "out:LCP/MSEK": 0.179526,
    "out:ROI% old": 14.830753,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2673.67338,
    "out:Return %": 13,
    "out:Return/kSEK/y": 623,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.455834,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246363.872884,
    "out:EL kWh savings": -61842.860275,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122678.15,
    "out:DH kr savings": 246363.87288372975,
    "out:El kr savings": -123685.72055018831,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12564.421246,
    "out:% savings (space heating)": 94.176701,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.02,
    "out:Etvv": 0,
    "out:Ef": 38.8,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4705463.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 5.04209,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.04209,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.71893,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.65381,
    "out:Electricity (inc PV)": 38.285372,
    "out:Total Energy Use Pre PV": 42.65381,
    "out:Total Energy Use Post PV": 43.285372,
    "out:Primary Energy": 73.018996,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.428742,
    "out:CO2 Operational/m2": 6.770524,
    "out:Total CO2/m2": 26.199266,
    "out:Total CO2 (tons)": 51.664956,
    "out:Klimatpaverkan": -43.267217,
    "out:Initial Cost/MSEK": 4.754763767899366,
    "out:Running cost/(Apt SEK y)": 5243.647059,
    "out:Running Cost over RSP/MSEK": 1.38099,
    "out:LCP/MSEK": 0.193316,
    "out:ROI% old": 14.866534,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 220.9,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2635.7667,
    "out:Return %": 13,
    "out:Return/kSEK/y": 631,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 27.91431,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 249622.563632,
    "out:EL kWh savings": -60827.0771,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127968.41,
    "out:DH kr savings": 249622.56363211246,
    "out:El kr savings": -121654.15420074482,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 9305.730498,
    "out:% savings (space heating)": 95.687023,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 38.29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4754763.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 4.970604,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.970604,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.180111,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.945273,
    "out:Electricity (inc PV)": 30.934419,
    "out:Total Energy Use Pre PV": 45.945273,
    "out:Total Energy Use Post PV": 37.934419,
    "out:Primary Energy": 61.901855,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.023202,
    "out:CO2 Operational/m2": -1.064151,
    "out:Total CO2/m2": 28.959051,
    "out:Total CO2 (tons)": 57.107252,
    "out:Klimatpaverkan": -37.82492,
    "out:Initial Cost/MSEK": 4.9200426358956175,
    "out:Running cost/(Apt SEK y)": 4606.852941,
    "out:Running Cost over RSP/MSEK": 1.2193,
    "out:LCP/MSEK": 0.189727,
    "out:ROI% old": 14.836602,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 263.2,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2144.194793,
    "out:Return %": 13,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.205773,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3064.644474,
    "out:PV (% sold (El))": 15.251621,
    "out:PV (kWh self-consumed)": 17029.248803,
    "out:PV (ratio sold/self-consumed)": 0.179964,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244769.11442,
    "out:EL kWh savings": -46330.996946,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156704.09,
    "out:DH kr savings": 244769.11441953786,
    "out:El kr savings": -92661.99389272755,
    "out:El kr sold": 4596.9667115239545,
    "out:El kr saved": 34058.497606,
    "out:El kr return": 38655.464317523954,
    "out:% solar/total": 116,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14159.179711,
    "out:% savings (space heating)": 93.437569,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8,
    "out:Etvv": 0,
    "out:Ef": 30.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4920042.635895617,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -3.584039,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.595187,
    "out:Bought CO2": 4.011148,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.420524,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.436206,
    "out:Electricity (inc PV)": 30.503271,
    "out:Total Energy Use Pre PV": 43.436206,
    "out:Total Energy Use Post PV": 35.503271,
    "out:Primary Energy": 59.681124,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.023202,
    "out:CO2 Operational/m2": -2.110892,
    "out:Total CO2/m2": 27.91231,
    "out:Total CO2 (tons)": 55.043079,
    "out:Klimatpaverkan": -39.889094,
    "out:Initial Cost/MSEK": 4.969342635895617,
    "out:Running cost/(Apt SEK y)": 4199,
    "out:Running Cost over RSP/MSEK": 1.111,
    "out:LCP/MSEK": 0.248727,
    "out:ROI% old": 15.000748,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2111.841057,
    "out:Return %": 13,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 28.696706,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3168.107739,
    "out:PV (% sold (El))": 15.76652,
    "out:PV (kWh self-consumed)": 16925.785538,
    "out:PV (ratio sold/self-consumed)": 0.187176,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248239.020184,
    "out:EL kWh savings": -45480.773041,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162029.64,
    "out:DH kr savings": 248239.02018413076,
    "out:El kr savings": -90961.54608242578,
    "out:El kr sold": 4752.161609151457,
    "out:El kr saved": 33851.571076,
    "out:El kr return": 38603.73268515146,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10689.273946,
    "out:% savings (space heating)": 95.045785,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.14,
    "out:Etvv": 0,
    "out:Ef": 30.5,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4969342.635895617,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -3.910812,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.861543,
    "out:Bought CO2": 3.950731,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.37141,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.195334,
    "out:Electricity (inc PV)": 30.179771,
    "out:Total Energy Use Pre PV": 44.195334,
    "out:Total Energy Use Post PV": 36.179771,
    "out:Primary Energy": 59.781988,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.690545,
    "out:CO2 Operational/m2": -1.907075,
    "out:Total CO2/m2": 28.78347,
    "out:Total CO2 (tons)": 56.761006,
    "out:Klimatpaverkan": -38.171166,
    "out:Initial Cost/MSEK": 5.079418635895617,
    "out:Running cost/(Apt SEK y)": 4346.941176,
    "out:Running Cost over RSP/MSEK": 1.15043,
    "out:LCP/MSEK": 0.099221,
    "out:ROI% old": 14.564771,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2096.560227,
    "out:Return %": 13,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.455834,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3217.637812,
    "out:PV (% sold (El))": 16.013013,
    "out:PV (kWh self-consumed)": 16876.255465,
    "out:PV (ratio sold/self-consumed)": 0.190661,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246363.872884,
    "out:EL kWh savings": -44842.831004,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161504.67,
    "out:DH kr savings": 246363.87288372975,
    "out:El kr savings": -89685.66200866779,
    "out:El kr sold": 4826.456718222489,
    "out:El kr saved": 33752.51093,
    "out:El kr return": 38578.96764822249,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12564.421246,
    "out:% savings (space heating)": 94.176701,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.02,
    "out:Etvv": 0,
    "out:Ef": 30.18,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5079418.635895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -4.066979,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.989164,
    "out:Bought CO2": 3.922185,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.71893,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.65381,
    "out:Electricity (inc PV)": 29.71802,
    "out:Total Energy Use Pre PV": 42.65381,
    "out:Total Energy Use Post PV": 34.71802,
    "out:Primary Energy": 57.597762,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.690545,
    "out:CO2 Operational/m2": -2.621968,
    "out:Total CO2/m2": 28.068577,
    "out:Total CO2 (tons)": 55.351237,
    "out:Klimatpaverkan": -39.580935,
    "out:Initial Cost/MSEK": 5.128718635895616,
    "out:Running cost/(Apt SEK y)": 4110.647059,
    "out:Running Cost over RSP/MSEK": 1.08776,
    "out:LCP/MSEK": 0.112591,
    "out:ROI% old": 14.59933,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 294.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2062.27059,
    "out:Return %": 13,
    "out:Return/kSEK/y": 670,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 27.91431,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3330.906059,
    "out:PV (% sold (El))": 16.576708,
    "out:PV (kWh self-consumed)": 16762.987219,
    "out:PV (ratio sold/self-consumed)": 0.198706,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 249622.563632,
    "out:EL kWh savings": -43932.25798,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166754.41,
    "out:DH kr savings": 249622.56363211246,
    "out:El kr savings": -87864.5159593885,
    "out:El kr sold": 4996.359087904216,
    "out:El kr saved": 33525.974438,
    "out:El kr return": 38522.333525904214,
    "out:% solar/total": 114,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 9305.730498,
    "out:% savings (space heating)": 95.687023,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 29.72,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5128718.635895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -4.421888,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.279995,
    "out:Bought CO2": 3.858107,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.180111,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.945273,
    "out:Electricity (inc PV)": 27.948949,
    "out:Total Energy Use Pre PV": 45.945273,
    "out:Total Energy Use Post PV": 34.948949,
    "out:Primary Energy": 56.528009,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.285005,
    "out:CO2 Operational/m2": -38.216627,
    "out:Total CO2/m2": 3.068378,
    "out:Total CO2 (tons)": 6.050842,
    "out:Klimatpaverkan": -88.88133,
    "out:Initial Cost/MSEK": 5.293997521354366,
    "out:Running cost/(Apt SEK y)": 3635.647059,
    "out:Running Cost over RSP/MSEK": 0.96792,
    "out:LCP/MSEK": 0.067152,
    "out:ROI% old": 14.466923,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 294.3,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1936.204352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 686,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.205773,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17399.487032,
    "out:PV (% sold (El))": 43.29546,
    "out:PV (kWh self-consumed)": 22788.299523,
    "out:PV (ratio sold/self-consumed)": 0.763527,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244769.11442,
    "out:EL kWh savings": -40443.649766,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189981.05,
    "out:DH kr savings": 244769.11441953786,
    "out:El kr savings": -80887.29953204036,
    "out:El kr sold": 26099.2305477634,
    "out:El kr saved": 45576.599046,
    "out:El kr return": 71675.8295937634,
    "out:% solar/total": 155,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14159.179711,
    "out:% savings (space heating)": 93.437569,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8,
    "out:Etvv": 0,
    "out:Ef": 27.95,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5293997.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -40.736515,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.360654,
    "out:Bought CO2": 3.624139,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.420524,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.436206,
    "out:Electricity (inc PV)": 27.562695,
    "out:Total Energy Use Pre PV": 43.436206,
    "out:Total Energy Use Post PV": 32.562695,
    "out:Primary Energy": 54.388087,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 41.285005,
    "out:CO2 Operational/m2": -39.504181,
    "out:Total CO2/m2": 1.780824,
    "out:Total CO2 (tons)": 3.511785,
    "out:Klimatpaverkan": -91.420387,
    "out:Initial Cost/MSEK": 5.3432975213543665,
    "out:Running cost/(Apt SEK y)": 3229.176471,
    "out:Running Cost over RSP/MSEK": 0.85999,
    "out:LCP/MSEK": 0.125782,
    "out:ROI% old": 14.622003,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1907.296936,
    "out:Return %": 13,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 28.696706,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17596.653043,
    "out:PV (% sold (El))": 43.786072,
    "out:PV (kWh self-consumed)": 22591.133512,
    "out:PV (ratio sold/self-consumed)": 0.778919,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 248239.020184,
    "out:EL kWh savings": -39681.956834,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195270.09,
    "out:DH kr savings": 248239.02018413076,
    "out:El kr savings": -79363.91366797447,
    "out:El kr sold": 26394.97956413293,
    "out:El kr saved": 45182.267024,
    "out:El kr return": 71577.24658813293,
    "out:% solar/total": 154,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10689.273946,
    "out:% savings (space heating)": 95.045785,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.14,
    "out:Etvv": 0,
    "out:Ef": 27.56,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5343297.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -41.304101,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.874115,
    "out:Bought CO2": 3.570014,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.37141,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.195334,
    "out:Electricity (inc PV)": 27.272918,
    "out:Total Energy Use Pre PV": 44.195334,
    "out:Total Energy Use Post PV": 33.272918,
    "out:Primary Energy": 54.549652,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 41.952348,
    "out:CO2 Operational/m2": -39.414026,
    "out:Total CO2/m2": 2.538322,
    "out:Total CO2 (tons)": 5.005571,
    "out:Klimatpaverkan": -89.926601,
    "out:Initial Cost/MSEK": 5.453373521354366,
    "out:Running cost/(Apt SEK y)": 3377.794118,
    "out:Running Cost over RSP/MSEK": 0.89959,
    "out:LCP/MSEK": -0.023894,
    "out:ROI% old": 14.223123,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1893.638146,
    "out:Return %": 13,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.455834,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17690.329673,
    "out:PV (% sold (El))": 44.019169,
    "out:PV (kWh self-consumed)": 22497.456882,
    "out:PV (ratio sold/self-consumed)": 0.786326,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 246363.872884,
    "out:EL kWh savings": -39110.516557,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194678.33,
    "out:DH kr savings": 246363.87288372975,
    "out:El kr savings": -78221.03311390532,
    "out:El kr sold": 26535.494509700984,
    "out:El kr saved": 44994.913764,
    "out:El kr return": 71530.40827370097,
    "out:% solar/total": 153,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12564.421246,
    "out:% savings (space heating)": 94.176701,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.02,
    "out:Etvv": 0,
    "out:Ef": 27.27,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5453373.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -41.57393,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.118367,
    "out:Bought CO2": 3.544437,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.71893,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.65381,
    "out:Electricity (inc PV)": 26.859158,
    "out:Total Energy Use Pre PV": 42.65381,
    "out:Total Energy Use Post PV": 31.859158,
    "out:Primary Energy": 52.45181,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 41.952348,
    "out:CO2 Operational/m2": -40.383778,
    "out:Total CO2/m2": 1.56857,
    "out:Total CO2 (tons)": 3.09322,
    "out:Klimatpaverkan": -91.838952,
    "out:Initial Cost/MSEK": 5.5026735213543665,
    "out:Running cost/(Apt SEK y)": 3142.911765,
    "out:Running Cost over RSP/MSEK": 0.8373,
    "out:LCP/MSEK": -0.010904,
    "out:ROI% old": 14.257407,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 331.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1862.974953,
    "out:Return %": 13,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 27.91431,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17902.346868,
    "out:PV (% sold (El))": 44.546735,
    "out:PV (kWh self-consumed)": 22285.439687,
    "out:PV (ratio sold/self-consumed)": 0.80332,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 249622.563632,
    "out:EL kWh savings": -38294.58179,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199886.92,
    "out:DH kr savings": 249622.56363211246,
    "out:El kr savings": -76589.16357956802,
    "out:El kr sold": 26853.52030163574,
    "out:El kr saved": 44570.879374,
    "out:El kr return": 71424.39967563574,
    "out:% solar/total": 152,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 9305.730498,
    "out:% savings (space heating)": 95.687023,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 26.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5502673.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -42.183698,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.670716,
    "out:Bought CO2": 3.487018,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.114007,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.941668,
    "out:Electricity (inc PV)": 38.515766,
    "out:Total Energy Use Pre PV": 43.941668,
    "out:Total Energy Use Post PV": 44.515766,
    "out:Primary Energy": 74.545309,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.761399,
    "out:CO2 Operational/m2": 7.168508,
    "out:Total CO2/m2": 25.929907,
    "out:Total CO2 (tons)": 51.13378,
    "out:Klimatpaverkan": -43.798393,
    "out:Initial Cost/MSEK": 4.635824392899366,
    "out:Running cost/(Apt SEK y)": 5452.205882,
    "out:Running Cost over RSP/MSEK": 1.43634,
    "out:LCP/MSEK": 0.256906,
    "out:ROI% old": 15.077392,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2655.91676,
    "out:Return %": 13,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.202168,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246871.471629,
    "out:EL kWh savings": -61281.414095,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124308.64,
    "out:DH kr savings": 246871.4716290737,
    "out:El kr savings": -122562.82818927466,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12056.822501,
    "out:% savings (space heating)": 94.41196,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.71,
    "out:Etvv": 0,
    "out:Ef": 38.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4635824.392899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 5.008604,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.008604,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.499318,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.388692,
    "out:Electricity (inc PV)": 37.988363,
    "out:Total Energy Use Pre PV": 41.388692,
    "out:Total Energy Use Post PV": 41.988363,
    "out:Primary Energy": 72.274479,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 18.761399,
    "out:CO2 Operational/m2": 6.375542,
    "out:Total CO2/m2": 25.136941,
    "out:Total CO2 (tons)": 49.570051,
    "out:Klimatpaverkan": -45.362122,
    "out:Initial Cost/MSEK": 4.685124392899366,
    "out:Running cost/(Apt SEK y)": 5037.735294,
    "out:Running Cost over RSP/MSEK": 1.32632,
    "out:LCP/MSEK": 0.317626,
    "out:ROI% old": 15.254207,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 228.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2617.20844,
    "out:Return %": 14,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 27.649192,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 250055.638521,
    "out:EL kWh savings": -60241.375319,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129572.89,
    "out:DH kr savings": 250055.63852114824,
    "out:El kr savings": -120482.75063702675,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 8872.655609,
    "out:% savings (space heating)": 95.887743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.01,
    "out:Etvv": 0,
    "out:Ef": 37.99,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4685124.392899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 4.935606,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.935606,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.5192,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.325912,
    "out:Electricity (inc PV)": 37.822463,
    "out:Total Energy Use Pre PV": 43.325912,
    "out:Total Energy Use Post PV": 43.822463,
    "out:Primary Energy": 72.743509,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.428742,
    "out:CO2 Operational/m2": 7.087223,
    "out:Total CO2/m2": 26.515965,
    "out:Total CO2 (tons)": 52.289486,
    "out:Klimatpaverkan": -42.642686,
    "out:Initial Cost/MSEK": 4.795200392899365,
    "out:Running cost/(Apt SEK y)": 5380.764706,
    "out:Running Cost over RSP/MSEK": 1.41757,
    "out:LCP/MSEK": 0.1163,
    "out:ROI% old": 14.632191,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 213.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2612.81384,
    "out:Return %": 13,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.586412,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248044.431101,
    "out:EL kWh savings": -59914.2205,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128215.99,
    "out:DH kr savings": 248044.4311008817,
    "out:El kr savings": -119828.44099920156,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10883.863029,
    "out:% savings (space heating)": 94.955598,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 37.82,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4795200.392899365,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 4.927319,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.927319,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.002173,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.743342,
    "out:Electricity (inc PV)": 37.263953,
    "out:Total Energy Use Pre PV": 40.743342,
    "out:Total Energy Use Post PV": 41.263953,
    "out:Primary Energy": 70.496527,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.428742,
    "out:CO2 Operational/m2": 6.290351,
    "out:Total CO2/m2": 25.719093,
    "out:Total CO2 (tons)": 50.718054,
    "out:Klimatpaverkan": -44.214118,
    "out:Initial Cost/MSEK": 4.8445003928993655,
    "out:Running cost/(Apt SEK y)": 4962.882353,
    "out:Running Cost over RSP/MSEK": 1.30664,
    "out:LCP/MSEK": 0.17793,
    "out:ROI% old": 14.810402,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 236.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2572.03394,
    "out:Return %": 13,
    "out:Return/kSEK/y": 641,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.003842,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 251036.008518,
    "out:EL kWh savings": -58812.838716,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133410.33,
    "out:DH kr savings": 251036.00851782277,
    "out:El kr savings": -117625.67743186127,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 7892.285612,
    "out:% savings (space heating)": 96.34212,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 4.4,
    "out:Etvv": 0,
    "out:Ef": 37.26,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4844500.392899365,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 4.850415,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.850415,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.114007,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.941668,
    "out:Electricity (inc PV)": 29.924446,
    "out:Total Energy Use Pre PV": 43.941668,
    "out:Total Energy Use Post PV": 35.924446,
    "out:Primary Energy": 59.080933,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.023202,
    "out:CO2 Operational/m2": -2.072622,
    "out:Total CO2/m2": 27.95058,
    "out:Total CO2 (tons)": 55.118547,
    "out:Klimatpaverkan": -39.813625,
    "out:Initial Cost/MSEK": 5.009779260895616,
    "out:Running cost/(Apt SEK y)": 4318.294118,
    "out:Running Cost over RSP/MSEK": 1.14289,
    "out:LCP/MSEK": 0.176401,
    "out:ROI% old": 14.788733,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2080.486822,
    "out:Return %": 13,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.202168,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3270.341968,
    "out:PV (% sold (El))": 16.275303,
    "out:PV (kWh self-consumed)": 16823.55131,
    "out:PV (ratio sold/self-consumed)": 0.194391,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246871.471629,
    "out:EL kWh savings": -44339.330075,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163098.32,
    "out:DH kr savings": 246871.4716290737,
    "out:El kr savings": -88678.66015045372,
    "out:El kr sold": 4905.512951509038,
    "out:El kr saved": 33647.10262,
    "out:El kr return": 38552.615571509035,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12056.822501,
    "out:% savings (space heating)": 94.41196,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.71,
    "out:Etvv": 0,
    "out:Ef": 29.92,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5009779.260895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -4.232526,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.124677,
    "out:Bought CO2": 3.892151,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.499318,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.388692,
    "out:Electricity (inc PV)": 29.452204,
    "out:Total Energy Use Pre PV": 41.388692,
    "out:Total Energy Use Post PV": 33.452204,
    "out:Primary Energy": 56.909393,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.023202,
    "out:CO2 Operational/m2": -3.157828,
    "out:Total CO2/m2": 26.865374,
    "out:Total CO2 (tons)": 52.978521,
    "out:Klimatpaverkan": -41.953652,
    "out:Initial Cost/MSEK": 5.059079260895617,
    "out:Running cost/(Apt SEK y)": 3905.558824,
    "out:Running Cost over RSP/MSEK": 1.03331,
    "out:LCP/MSEK": 0.236681,
    "out:ROI% old": 14.954048,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2045.515866,
    "out:Return %": 13,
    "out:Return/kSEK/y": 677,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 27.649192,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3387.186727,
    "out:PV (% sold (El))": 16.856797,
    "out:PV (kWh self-consumed)": 16706.70655,
    "out:PV (ratio sold/self-consumed)": 0.202744,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 250055.638521,
    "out:EL kWh savings": -43408.068797,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168320.28,
    "out:DH kr savings": 250055.63852114824,
    "out:El kr savings": -86816.13759478246,
    "out:El kr sold": 5080.78009099513,
    "out:El kr saved": 33413.4131,
    "out:El kr return": 38494.19319099513,
    "out:% solar/total": 114,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 8872.655609,
    "out:% savings (space heating)": 95.887743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.01,
    "out:Etvv": 0,
    "out:Ef": 29.45,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5059079.260895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -4.597764,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.424556,
    "out:Bought CO2": 3.826792,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.5192,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.325912,
    "out:Electricity (inc PV)": 29.303918,
    "out:Total Energy Use Pre PV": 43.325912,
    "out:Total Energy Use Post PV": 35.303918,
    "out:Primary Energy": 57.410128,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.690545,
    "out:CO2 Operational/m2": -2.479704,
    "out:Total CO2/m2": 28.210841,
    "out:Total CO2 (tons)": 55.631782,
    "out:Klimatpaverkan": -39.300391,
    "out:Initial Cost/MSEK": 5.169155260895616,
    "out:Running cost/(Apt SEK y)": 4248.794118,
    "out:Running Cost over RSP/MSEK": 1.12461,
    "out:LCP/MSEK": 0.035305,
    "out:ROI% old": 14.383284,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 294.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2041.551343,
    "out:Return %": 13,
    "out:Return/kSEK/y": 665,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.586412,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3400.618297,
    "out:PV (% sold (El))": 16.923641,
    "out:PV (kWh self-consumed)": 16693.27498,
    "out:PV (ratio sold/self-consumed)": 0.203712,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248044.431101,
    "out:EL kWh savings": -43115.648788,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166914.06,
    "out:DH kr savings": 248044.4311008817,
    "out:El kr savings": -86231.29757697327,
    "out:El kr sold": 5100.927445852356,
    "out:El kr saved": 33386.54996,
    "out:El kr return": 38487.477405852354,
    "out:% solar/total": 114,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10883.863029,
    "out:% savings (space heating)": 94.955598,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 29.3,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5169155.260895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -4.639608,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.45899,
    "out:Bought CO2": 3.819382,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.002173,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.743342,
    "out:Electricity (inc PV)": 28.805558,
    "out:Total Energy Use Pre PV": 40.743342,
    "out:Total Energy Use Post PV": 32.805558,
    "out:Primary Energy": 55.271416,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 30.690545,
    "out:CO2 Operational/m2": -3.592215,
    "out:Total CO2/m2": 27.09833,
    "out:Total CO2 (tons)": 53.43791,
    "out:Klimatpaverkan": -41.494262,
    "out:Initial Cost/MSEK": 5.218455260895617,
    "out:Running cost/(Apt SEK y)": 3832.735294,
    "out:Running Cost over RSP/MSEK": 1.01415,
    "out:LCP/MSEK": 0.096465,
    "out:ROI% old": 14.54979,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2004.820521,
    "out:Return %": 13,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.003842,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3526.978696,
    "out:PV (% sold (El))": 17.55249,
    "out:PV (kWh self-consumed)": 16566.914581,
    "out:PV (ratio sold/self-consumed)": 0.212893,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 251036.008518,
    "out:EL kWh savings": -42132.882812,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172060.71,
    "out:DH kr savings": 251036.00851782277,
    "out:El kr savings": -84265.76562334724,
    "out:El kr sold": 5290.468044730117,
    "out:El kr saved": 33133.829162,
    "out:El kr return": 38424.297206730116,
    "out:% solar/total": 113,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 7892.285612,
    "out:% savings (space heating)": 96.34212,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 4.4,
    "out:Etvv": 0,
    "out:Ef": 28.81,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5218455.260895616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -5.032151,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.782879,
    "out:Bought CO2": 3.750728,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.114007,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.941668,
    "out:Electricity (inc PV)": 27.044147,
    "out:Total Energy Use Pre PV": 43.941668,
    "out:Total Energy Use Post PV": 33.044147,
    "out:Primary Energy": 53.896395,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 41.285005,
    "out:CO2 Operational/m2": -39.699118,
    "out:Total CO2/m2": 1.585887,
    "out:Total CO2 (tons)": 3.127369,
    "out:Klimatpaverkan": -91.804803,
    "out:Initial Cost/MSEK": 5.383734146354366,
    "out:Running cost/(Apt SEK y)": 3349.823529,
    "out:Running Cost over RSP/MSEK": 0.89223,
    "out:LCP/MSEK": 0.053106,
    "out:ROI% old": 14.42663,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1879.26695,
    "out:Return %": 13,
    "out:Return/kSEK/y": 696,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.202168,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17789.457741,
    "out:PV (% sold (El))": 44.265831,
    "out:PV (kWh self-consumed)": 22398.328813,
    "out:PV (ratio sold/self-consumed)": 0.794231,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 246871.471629,
    "out:EL kWh savings": -38659.380119,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196236.9,
    "out:DH kr savings": 246871.4716290737,
    "out:El kr savings": -77318.76023774552,
    "out:El kr sold": 26684.18661224441,
    "out:El kr saved": 44796.657626,
    "out:El kr return": 71480.8442382444,
    "out:% solar/total": 153,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12056.822501,
    "out:% savings (space heating)": 94.41196,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.71,
    "out:Etvv": 0,
    "out:Ef": 27.04,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5383734.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -41.859022,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.376548,
    "out:Bought CO2": 3.517526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.499318,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.388692,
    "out:Electricity (inc PV)": 26.620915,
    "out:Total Energy Use Pre PV": 41.388692,
    "out:Total Energy Use Post PV": 30.620915,
    "out:Primary Energy": 51.813073,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 41.285005,
    "out:CO2 Operational/m2": -41.043824,
    "out:Total CO2/m2": 0.241181,
    "out:Total CO2 (tons)": 0.475609,
    "out:Klimatpaverkan": -94.456563,
    "out:Initial Cost/MSEK": 5.433034146354366,
    "out:Running cost/(Apt SEK y)": 2938.558824,
    "out:Running Cost over RSP/MSEK": 0.78304,
    "out:LCP/MSEK": 0.112996,
    "out:ROI% old": 14.582828,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1847.985411,
    "out:Return %": 13,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 27.649192,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18006.745297,
    "out:PV (% sold (El))": 44.806512,
    "out:PV (kWh self-consumed)": 22181.041258,
    "out:PV (ratio sold/self-consumed)": 0.811808,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 250055.638521,
    "out:EL kWh savings": -37824.766567,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201416.22,
    "out:DH kr savings": 250055.63852114824,
    "out:El kr savings": -75649.53313324862,
    "out:El kr sold": 27010.11794526934,
    "out:El kr saved": 44362.082516,
    "out:El kr return": 71372.20046126934,
    "out:% solar/total": 151,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 8872.655609,
    "out:% savings (space heating)": 95.887743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.01,
    "out:Etvv": 0,
    "out:Ef": 26.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5433034.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -42.48376,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.942707,
    "out:Bought CO2": 3.458947,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.5192,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.325912,
    "out:Electricity (inc PV)": 26.487972,
    "out:Total Energy Use Pre PV": 43.325912,
    "out:Total Energy Use Post PV": 32.487972,
    "out:Primary Energy": 52.341426,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 41.952348,
    "out:CO2 Operational/m2": -40.395059,
    "out:Total CO2/m2": 1.557289,
    "out:Total CO2 (tons)": 3.070974,
    "out:Klimatpaverkan": -91.861198,
    "out:Initial Cost/MSEK": 5.543110146354366,
    "out:Running cost/(Apt SEK y)": 3281.941176,
    "out:Running Cost over RSP/MSEK": 0.87438,
    "out:LCP/MSEK": -0.08842,
    "out:ROI% old": 14.057838,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 331.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1844.438008,
    "out:Return %": 13,
    "out:Return/kSEK/y": 698,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.586412,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18031.50573,
    "out:PV (% sold (El))": 44.868124,
    "out:PV (kWh self-consumed)": 22156.280824,
    "out:PV (ratio sold/self-consumed)": 0.813833,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 248044.431101,
    "out:EL kWh savings": -37562.602955,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199966.48,
    "out:DH kr savings": 248044.4311008817,
    "out:El kr savings": -75125.2059109376,
    "out:El kr sold": 27047.258595416366,
    "out:El kr saved": 44312.561648,
    "out:El kr return": 71359.82024341637,
    "out:% solar/total": 151,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10883.863029,
    "out:% savings (space heating)": 94.955598,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 26.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5543110.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -42.554963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -46.007266,
    "out:Bought CO2": 3.452303,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.002173,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.743342,
    "out:Electricity (inc PV)": 26.040929,
    "out:Total Energy Use Pre PV": 40.743342,
    "out:Total Energy Use Post PV": 30.040929,
    "out:Primary Energy": 50.295084,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 41.952348,
    "out:CO2 Operational/m2": -41.778473,
    "out:Total CO2/m2": 0.173875,
    "out:Total CO2 (tons)": 0.342882,
    "out:Klimatpaverkan": -94.589291,
    "out:Initial Cost/MSEK": 5.592410146354366,
    "out:Running cost/(Apt SEK y)": 2867.441176,
    "out:Running Cost over RSP/MSEK": 0.76434,
    "out:LCP/MSEK": -0.02768,
    "out:ROI% old": 14.215006,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 360,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1811.557771,
    "out:Return %": 13,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.003842,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18262.493912,
    "out:PV (% sold (El))": 45.442896,
    "out:PV (kWh self-consumed)": 21925.292643,
    "out:PV (ratio sold/self-consumed)": 0.832942,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 251036.008518,
    "out:EL kWh savings": -36681.034109,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205067.68,
    "out:DH kr savings": 251036.00851782277,
    "out:El kr savings": -73362.06821701177,
    "out:El kr sold": 27393.74086808312,
    "out:El kr saved": 43850.585286,
    "out:El kr return": 71244.32615408313,
    "out:% solar/total": 149,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 7892.285612,
    "out:% savings (space heating)": 96.34212,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 4.4,
    "out:Etvv": 0,
    "out:Ef": 26.04,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5592410.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -43.218409,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -46.609136,
    "out:Bought CO2": 3.390727,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 111.44,
    "out:Primary Energy": 94.952012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.248483,
    "out:CO2 Operational/m2": 38.420476,
    "out:Total CO2/m2": 53.668959,
    "out:Total CO2 (tons)": 105.835193,
    "out:Klimatpaverkan": 10.903021,
    "out:Initial Cost/MSEK": 2.18375,
    "out:Running cost/(Apt SEK y)": 19079.676471,
    "out:Running Cost over RSP/MSEK": 5.07136,
    "out:LCP/MSEK": -0.92604,
    "out:ROI% old": 8.227721,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 7,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 53341.61807,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53341.62,
    "out:DH kr savings": 53341.618069643046,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2183750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 108.44,
    "out:Total Energy Use Post PV": 108.44,
    "out:Primary Energy": 92.252315,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.248483,
    "out:CO2 Operational/m2": 37.340524,
    "out:Total CO2/m2": 52.589007,
    "out:Total CO2 (tons)": 103.705528,
    "out:Klimatpaverkan": 8.773356,
    "out:Initial Cost/MSEK": 2.23305,
    "out:Running cost/(Apt SEK y)": 18554.205882,
    "out:Running Cost over RSP/MSEK": 4.93161,
    "out:LCP/MSEK": -0.83559,
    "out:ROI% old": 8.940111,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 27.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 179,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 60186.508655,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60186.51,
    "out:DH kr savings": 60186.50865514155,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2233050,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 108.44,
    "out:Total Energy Use Post PV": 108.44,
    "out:Primary Energy": 92.327012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.915826,
    "out:CO2 Operational/m2": 37.340524,
    "out:Total CO2/m2": 53.25635,
    "out:Total CO2 (tons)": 105.021528,
    "out:Klimatpaverkan": 10.089356,
    "out:Initial Cost/MSEK": 2.343126,
    "out:Running cost/(Apt SEK y)": 18554.205882,
    "out:Running Cost over RSP/MSEK": 4.93161,
    "out:LCP/MSEK": -0.945666,
    "out:ROI% old": 8.52012,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 27.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 179,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 59816.680444,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 59816.68,
    "out:DH kr savings": 59816.68044396205,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2343126,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 104.44,
    "out:Primary Energy": 89.627315,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.915826,
    "out:CO2 Operational/m2": 35.900588,
    "out:Total CO2/m2": 51.816414,
    "out:Total CO2 (tons)": 102.181974,
    "out:Klimatpaverkan": 7.249802,
    "out:Initial Cost/MSEK": 2.392426,
    "out:Running cost/(Apt SEK y)": 17853.558824,
    "out:Running Cost over RSP/MSEK": 4.74529,
    "out:LCP/MSEK": -0.808646,
    "out:ROI% old": 9.457107,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 66661.571029,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66661.57,
    "out:DH kr savings": 66661.57102946055,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2392426,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 108.635375,
    "out:Primary Energy": 89.903687,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.510286,
    "out:CO2 Operational/m2": 0.582567,
    "out:Total CO2/m2": 27.092853,
    "out:Total CO2 (tons)": 53.427109,
    "out:Klimatpaverkan": -41.505063,
    "out:Initial Cost/MSEK": 2.55770486799625,
    "out:Running cost/(Apt SEK y)": 18111.852941,
    "out:Running Cost over RSP/MSEK": 4.82102,
    "out:LCP/MSEK": -1.049655,
    "out:ROI% old": 8.423009,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 26.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 194,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 53341.61807,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86247.82,
    "out:DH kr savings": 53341.618069643046,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2557704.8679962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 108.44,
    "out:Total Energy Use Post PV": 105.635375,
    "out:Primary Energy": 87.20399,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.510286,
    "out:CO2 Operational/m2": -0.497385,
    "out:Total CO2/m2": 26.012901,
    "out:Total CO2 (tons)": 51.297444,
    "out:Klimatpaverkan": -43.634729,
    "out:Initial Cost/MSEK": 2.6070048679962503,
    "out:Running cost/(Apt SEK y)": 17586.382353,
    "out:Running Cost over RSP/MSEK": 4.68128,
    "out:LCP/MSEK": -0.959215,
    "out:ROI% old": 9.029464,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 212,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 60186.508655,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93092.71,
    "out:DH kr savings": 60186.50865514155,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2607004.8679962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 108.44,
    "out:Total Energy Use Post PV": 105.635375,
    "out:Primary Energy": 87.278687,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.177629,
    "out:CO2 Operational/m2": -0.497385,
    "out:Total CO2/m2": 26.680244,
    "out:Total CO2 (tons)": 52.613444,
    "out:Klimatpaverkan": -42.318728,
    "out:Initial Cost/MSEK": 2.71708086799625,
    "out:Running cost/(Apt SEK y)": 17586.382353,
    "out:Running Cost over RSP/MSEK": 4.68128,
    "out:LCP/MSEK": -1.069291,
    "out:ROI% old": 8.663657,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 212,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 59816.680444,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92722.88,
    "out:DH kr savings": 59816.68044396205,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2717080.8679962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 101.635375,
    "out:Primary Energy": 84.57899,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.177629,
    "out:CO2 Operational/m2": -1.937321,
    "out:Total CO2/m2": 25.240308,
    "out:Total CO2 (tons)": 49.77389,
    "out:Klimatpaverkan": -45.158282,
    "out:Initial Cost/MSEK": 2.7663808679962503,
    "out:Running cost/(Apt SEK y)": 16885.735294,
    "out:Running Cost over RSP/MSEK": 4.49495,
    "out:LCP/MSEK": -0.932261,
    "out:ROI% old": 9.471478,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66661.571029,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99567.77,
    "out:DH kr savings": 66661.57102946055,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2766380.8679962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 111.44,
    "out:Total Energy Use Post PV": 108.377795,
    "out:Primary Energy": 89.440043,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.772089,
    "out:CO2 Operational/m2": -50.065958,
    "out:Total CO2/m2": -12.293869,
    "out:Total CO2 (tons)": -24.243511,
    "out:Klimatpaverkan": -119.175683,
    "out:Initial Cost/MSEK": 2.931659753455,
    "out:Running cost/(Apt SEK y)": 17217.882353,
    "out:Running Cost over RSP/MSEK": 4.59001,
    "out:LCP/MSEK": -1.1926,
    "out:ROI% old": 8.474283,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 27.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53341.61807,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116642.63,
    "out:DH kr savings": 53341.618069643046,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2931659.753455,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 108.44,
    "out:Total Energy Use Post PV": 105.377795,
    "out:Primary Energy": 86.740346,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.772089,
    "out:CO2 Operational/m2": -51.14591,
    "out:Total CO2/m2": -13.373821,
    "out:Total CO2 (tons)": -26.373177,
    "out:Klimatpaverkan": -121.305349,
    "out:Initial Cost/MSEK": 2.980959753455,
    "out:Running cost/(Apt SEK y)": 16692.411765,
    "out:Running Cost over RSP/MSEK": 4.45027,
    "out:LCP/MSEK": -1.10216,
    "out:ROI% old": 9.003812,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 31.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 242,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 60186.508655,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123487.52,
    "out:DH kr savings": 60186.50865514155,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2980959.753455,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 108.44,
    "out:Total Energy Use Post PV": 105.377795,
    "out:Primary Energy": 86.815043,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.439432,
    "out:CO2 Operational/m2": -51.14591,
    "out:Total CO2/m2": -12.706478,
    "out:Total CO2 (tons)": -25.057176,
    "out:Klimatpaverkan": -119.989348,
    "out:Initial Cost/MSEK": 3.0910357534549995,
    "out:Running cost/(Apt SEK y)": 16692.411765,
    "out:Running Cost over RSP/MSEK": 4.45027,
    "out:LCP/MSEK": -1.212236,
    "out:ROI% old": 8.683174,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 31.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 242,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 59816.680444,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123117.7,
    "out:DH kr savings": 59816.68044396205,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3091035.7534549995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 101.377795,
    "out:Primary Energy": 84.115346,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.439432,
    "out:CO2 Operational/m2": -52.585846,
    "out:Total CO2/m2": -14.146414,
    "out:Total CO2 (tons)": -27.89673,
    "out:Klimatpaverkan": -122.828902,
    "out:Initial Cost/MSEK": 3.1403357534549996,
    "out:Running cost/(Apt SEK y)": 15991.764706,
    "out:Running Cost over RSP/MSEK": 4.26394,
    "out:LCP/MSEK": -1.075206,
    "out:ROI% old": 9.394491,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66661.571029,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129962.59,
    "out:DH kr savings": 66661.57102946055,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3140335.7534549995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 107.44,
    "out:Primary Energy": 91.452012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.248483,
    "out:CO2 Operational/m2": 36.98054,
    "out:Total CO2/m2": 52.229023,
    "out:Total CO2 (tons)": 102.995639,
    "out:Klimatpaverkan": 8.063467,
    "out:Initial Cost/MSEK": 2.273486625,
    "out:Running cost/(Apt SEK y)": 18379.029412,
    "out:Running Cost over RSP/MSEK": 4.88503,
    "out:LCP/MSEK": -0.829447,
    "out:ROI% old": 9.073791,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 185,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 61975.034569,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61975.03,
    "out:DH kr savings": 61975.03456873504,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2273486.625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 103.44,
    "out:Total Energy Use Post PV": 103.44,
    "out:Primary Energy": 88.752315,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.248483,
    "out:CO2 Operational/m2": 35.540604,
    "out:Total CO2/m2": 50.789087,
    "out:Total CO2 (tons)": 100.156085,
    "out:Klimatpaverkan": 5.223913,
    "out:Initial Cost/MSEK": 2.322786625,
    "out:Running cost/(Apt SEK y)": 17678.411765,
    "out:Running Cost over RSP/MSEK": 4.6987,
    "out:LCP/MSEK": -0.692417,
    "out:ROI% old": 10.02718,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 34,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 9,
    "out:Return/kSEK/y": 208,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 68819.925154,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68819.93,
    "out:DH kr savings": 68819.92515423356,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2322786.625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 104.44,
    "out:Primary Energy": 89.352012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.915826,
    "out:CO2 Operational/m2": 35.900588,
    "out:Total CO2/m2": 51.816414,
    "out:Total CO2 (tons)": 102.181974,
    "out:Klimatpaverkan": 7.249802,
    "out:Initial Cost/MSEK": 2.432862625,
    "out:Running cost/(Apt SEK y)": 17853.558824,
    "out:Running Cost over RSP/MSEK": 4.74529,
    "out:LCP/MSEK": -0.849083,
    "out:ROI% old": 9.29992,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 8,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 67155.084468,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 67155.08,
    "out:DH kr savings": 67155.08446819025,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2432862.625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 101.44,
    "out:Total Energy Use Post PV": 101.44,
    "out:Primary Energy": 86.652315,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.915826,
    "out:CO2 Operational/m2": 34.820636,
    "out:Total CO2/m2": 50.736462,
    "out:Total CO2 (tons)": 100.052309,
    "out:Klimatpaverkan": 5.120137,
    "out:Initial Cost/MSEK": 2.482162625,
    "out:Running cost/(Apt SEK y)": 17328.088235,
    "out:Running Cost over RSP/MSEK": 4.60554,
    "out:LCP/MSEK": -0.758633,
    "out:ROI% old": 9.919518,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 9,
    "out:Return/kSEK/y": 220,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 73999.975054,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73999.98,
    "out:DH kr savings": 73999.97505368876,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2482162.625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 104.635375,
    "out:Primary Energy": 86.403687,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.510286,
    "out:CO2 Operational/m2": -0.857369,
    "out:Total CO2/m2": 25.652917,
    "out:Total CO2 (tons)": 50.587555,
    "out:Klimatpaverkan": -44.344617,
    "out:Initial Cost/MSEK": 2.64744149299625,
    "out:Running cost/(Apt SEK y)": 17411.205882,
    "out:Running Cost over RSP/MSEK": 4.6347,
    "out:LCP/MSEK": -0.953071,
    "out:ROI% old": 9.142897,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 31.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61975.034569,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94881.24,
    "out:DH kr savings": 61975.03456873504,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2647441.4929962503,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 103.44,
    "out:Total Energy Use Post PV": 100.635375,
    "out:Primary Energy": 83.70399,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.510286,
    "out:CO2 Operational/m2": -2.297305,
    "out:Total CO2/m2": 24.212981,
    "out:Total CO2 (tons)": 47.748001,
    "out:Klimatpaverkan": -47.184171,
    "out:Initial Cost/MSEK": 2.6967414929962503,
    "out:Running cost/(Apt SEK y)": 16710.558824,
    "out:Running Cost over RSP/MSEK": 4.44837,
    "out:LCP/MSEK": -0.816041,
    "out:ROI% old": 9.962817,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 241,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68819.925154,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101726.13,
    "out:DH kr savings": 68819.92515423356,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2696741.4929962503,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 101.635375,
    "out:Primary Energy": 84.303687,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.177629,
    "out:CO2 Operational/m2": -1.937321,
    "out:Total CO2/m2": 25.240308,
    "out:Total CO2 (tons)": 49.77389,
    "out:Klimatpaverkan": -45.158282,
    "out:Initial Cost/MSEK": 2.8068174929962497,
    "out:Running cost/(Apt SEK y)": 16885.735294,
    "out:Running Cost over RSP/MSEK": 4.49495,
    "out:LCP/MSEK": -0.972697,
    "out:ROI% old": 9.335026,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 8,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 67155.084468,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100061.29,
    "out:DH kr savings": 67155.08446819025,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2806817.49299625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 101.44,
    "out:Total Energy Use Post PV": 98.635375,
    "out:Primary Energy": 81.60399,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.177629,
    "out:CO2 Operational/m2": -3.017273,
    "out:Total CO2/m2": 24.160356,
    "out:Total CO2 (tons)": 47.644225,
    "out:Klimatpaverkan": -47.287947,
    "out:Initial Cost/MSEK": 2.85611749299625,
    "out:Running cost/(Apt SEK y)": 16360.264706,
    "out:Running Cost over RSP/MSEK": 4.35521,
    "out:LCP/MSEK": -0.882257,
    "out:ROI% old": 9.872843,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 39.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 9,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 73999.975054,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106906.18,
    "out:DH kr savings": 73999.97505368876,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2856117.49299625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 107.44,
    "out:Total Energy Use Post PV": 104.377795,
    "out:Primary Energy": 85.940043,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.772089,
    "out:CO2 Operational/m2": -51.505894,
    "out:Total CO2/m2": -13.733805,
    "out:Total CO2 (tons)": -27.083065,
    "out:Klimatpaverkan": -122.015237,
    "out:Initial Cost/MSEK": 3.0213963784549995,
    "out:Running cost/(Apt SEK y)": 16517.235294,
    "out:Running Cost over RSP/MSEK": 4.40368,
    "out:LCP/MSEK": -1.096006,
    "out:ROI% old": 9.103596,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61975.034569,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125276.05,
    "out:DH kr savings": 61975.03456873504,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3021396.3784549995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 103.44,
    "out:Total Energy Use Post PV": 100.377795,
    "out:Primary Energy": 83.240346,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.772089,
    "out:CO2 Operational/m2": -52.94583,
    "out:Total CO2/m2": -15.173741,
    "out:Total CO2 (tons)": -29.922619,
    "out:Klimatpaverkan": -124.854791,
    "out:Initial Cost/MSEK": 3.0706963784549997,
    "out:Running cost/(Apt SEK y)": 15816.617647,
    "out:Running Cost over RSP/MSEK": 4.21736,
    "out:LCP/MSEK": -0.958986,
    "out:ROI% old": 9.824249,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 38,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68819.925154,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132120.94,
    "out:DH kr savings": 68819.92515423356,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3070696.3784549995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.558464,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.362872,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 104.44,
    "out:Total Energy Use Post PV": 101.377795,
    "out:Primary Energy": 83.840043,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.439432,
    "out:CO2 Operational/m2": -52.585846,
    "out:Total CO2/m2": -14.146414,
    "out:Total CO2 (tons)": -27.89673,
    "out:Klimatpaverkan": -122.828902,
    "out:Initial Cost/MSEK": 3.1807723784549995,
    "out:Running cost/(Apt SEK y)": 15991.764706,
    "out:Running Cost over RSP/MSEK": 4.26394,
    "out:LCP/MSEK": -1.115642,
    "out:ROI% old": 9.275061,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 8,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 67155.084468,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130456.1,
    "out:DH kr savings": 67155.08446819025,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 162419.593565,
    "out:% savings (space heating)": 24.722524,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.36,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3180772.3784549995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.891833,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 101.44,
    "out:Total Energy Use Post PV": 98.377795,
    "out:Primary Energy": 81.140346,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.439432,
    "out:CO2 Operational/m2": -53.665798,
    "out:Total CO2/m2": -15.226366,
    "out:Total CO2 (tons)": -30.026395,
    "out:Klimatpaverkan": -124.958568,
    "out:Initial Cost/MSEK": 3.2300723784549996,
    "out:Running cost/(Apt SEK y)": 15466.294118,
    "out:Running Cost over RSP/MSEK": 4.12419,
    "out:LCP/MSEK": -1.025192,
    "out:ROI% old": 9.751573,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 9,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 73999.975054,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137300.99,
    "out:DH kr savings": 73999.97505368876,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 155574.702979,
    "out:% savings (space heating)": 27.894962,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 78.89,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3230072.3784549995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.6187,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.719552,
    "out:Electricity (inc PV)": 29.119398,
    "out:Total Energy Use Pre PV": 70.719552,
    "out:Total Energy Use Post PV": 71.119398,
    "out:Primary Energy": 86.177634,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.078984,
    "out:CO2 Operational/m2": 18.910539,
    "out:Total CO2/m2": 38.989523,
    "out:Total CO2 (tons)": 76.887344,
    "out:Klimatpaverkan": -18.044828,
    "out:Initial Cost/MSEK": 4.034742768245616,
    "out:Running cost/(Apt SEK y)": 10688.176471,
    "out:Running Cost over RSP/MSEK": 2.8321,
    "out:LCP/MSEK": -0.537773,
    "out:ROI% old": 12.381635,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2010.36864,
    "out:Return %": 11,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.980052,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 176856.212986,
    "out:EL kWh savings": -42751.775327,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91352.66,
    "out:DH kr savings": 176856.2129855387,
    "out:El kr savings": -85503.5506549028,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 82072.081145,
    "out:% savings (space heating)": 61.961615,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.41,
    "out:Etvv": 0,
    "out:Ef": 29.12,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4034742.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": 3.791211,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.791211,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.388805,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.646478,
    "out:Electricity (inc PV)": 29.054676,
    "out:Total Energy Use Pre PV": 66.646478,
    "out:Total Energy Use Post PV": 67.054676,
    "out:Primary Energy": 83.527557,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.078984,
    "out:CO2 Operational/m2": 17.460956,
    "out:Total CO2/m2": 37.53994,
    "out:Total CO2 (tons)": 74.028766,
    "out:Klimatpaverkan": -20.903406,
    "out:Initial Cost/MSEK": 4.084042768245617,
    "out:Running cost/(Apt SEK y)": 9979.058824,
    "out:Running Cost over RSP/MSEK": 2.64355,
    "out:LCP/MSEK": -0.398523,
    "out:ROI% old": 12.891708,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2005.25346,
    "out:Return %": 12,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.906978,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 183225.566294,
    "out:EL kWh savings": -42624.143536,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 97977.28,
    "out:DH kr savings": 183225.56629374676,
    "out:El kr savings": -85248.28707214612,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75702.727836,
    "out:% savings (space heating)": 64.913653,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.15,
    "out:Etvv": 0,
    "out:Ef": 29.05,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4084042.7682456165,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": 3.781564,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.781564,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.7673,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.285582,
    "out:Electricity (inc PV)": 28.635974,
    "out:Total Energy Use Pre PV": 68.285582,
    "out:Total Energy Use Post PV": 68.635974,
    "out:Primary Energy": 83.631592,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.746327,
    "out:CO2 Operational/m2": 18.133283,
    "out:Total CO2/m2": 38.87961,
    "out:Total CO2 (tons)": 76.670595,
    "out:Klimatpaverkan": -18.261577,
    "out:Initial Cost/MSEK": 4.194118768245616,
    "out:Running cost/(Apt SEK y)": 10287.529412,
    "out:Running Cost over RSP/MSEK": 2.72571,
    "out:LCP/MSEK": -0.590759,
    "out:ROI% old": 12.273513,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1979.99074,
    "out:Return %": 11,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.546082,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 180507.173997,
    "out:EL kWh savings": -41798.463144,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96910.25,
    "out:DH kr savings": 180507.17399659829,
    "out:El kr savings": -83596.92628868208,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 78421.120133,
    "out:% savings (space heating)": 63.653745,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.25,
    "out:Etvv": 0,
    "out:Ef": 28.64,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4194118.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": 3.733923,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.733923,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.565228,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.204077,
    "out:Electricity (inc PV)": 28.561011,
    "out:Total Energy Use Pre PV": 65.204077,
    "out:Total Energy Use Post PV": 65.561011,
    "out:Primary Energy": 80.989367,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.746327,
    "out:CO2 Operational/m2": 17.042572,
    "out:Total CO2/m2": 37.788899,
    "out:Total CO2 (tons)": 74.519713,
    "out:Klimatpaverkan": -20.412459,
    "out:Initial Cost/MSEK": 4.243418768245616,
    "out:Running cost/(Apt SEK y)": 9752.588235,
    "out:Running Cost over RSP/MSEK": 2.58348,
    "out:LCP/MSEK": -0.497829,
    "out:ROI% old": 12.609745,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 109.1,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1974.28539,
    "out:Return %": 11,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.464577,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 186821.660346,
    "out:EL kWh savings": -41650.6361,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103520.39,
    "out:DH kr savings": 186821.66034563453,
    "out:El kr savings": -83301.27219959053,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 72106.633784,
    "out:% savings (space heating)": 66.580354,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.03,
    "out:Etvv": 0,
    "out:Ef": 28.56,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4243418.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": 3.723164,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.723164,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.6187,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.719552,
    "out:Electricity (inc PV)": 21.698119,
    "out:Total Energy Use Pre PV": 70.719552,
    "out:Total Energy Use Post PV": 63.698119,
    "out:Primary Energy": 72.819332,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.340787,
    "out:CO2 Operational/m2": 3.881628,
    "out:Total CO2/m2": 35.222415,
    "out:Total CO2 (tons)": 69.458606,
    "out:Klimatpaverkan": -25.473566,
    "out:Initial Cost/MSEK": 4.408697636241866,
    "out:Running cost/(Apt SEK y)": 9588.205882,
    "out:Running Cost over RSP/MSEK": 2.54737,
    "out:LCP/MSEK": -0.626998,
    "out:ROI% old": 12.254024,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1510.060333,
    "out:Return %": 11,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.980052,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5576.142218,
    "out:PV (% sold (El))": 27.750432,
    "out:PV (kWh self-consumed)": 14517.751059,
    "out:PV (ratio sold/self-consumed)": 0.384091,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 176856.212986,
    "out:EL kWh savings": -28117.012293,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128986.4,
    "out:DH kr savings": 176856.2129855387,
    "out:El kr savings": -56234.02458685115,
    "out:El kr sold": 8364.213327394033,
    "out:El kr saved": 29035.502118,
    "out:El kr return": 37399.71544539403,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 82072.081145,
    "out:% savings (space heating)": 61.961615,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.41,
    "out:Etvv": 0,
    "out:Ef": 21.7,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4408697.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": -11.2377,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.06373,
    "out:Bought CO2": 2.82603,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.388805,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.646478,
    "out:Electricity (inc PV)": 21.642935,
    "out:Total Energy Use Pre PV": 66.646478,
    "out:Total Energy Use Post PV": 59.642935,
    "out:Primary Energy": 70.186423,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.340787,
    "out:CO2 Operational/m2": 2.377774,
    "out:Total CO2/m2": 33.718561,
    "out:Total CO2 (tons)": 66.493006,
    "out:Klimatpaverkan": -28.439166,
    "out:Initial Cost/MSEK": 4.457997636241866,
    "out:Running cost/(Apt SEK y)": 8879.411765,
    "out:Running Cost over RSP/MSEK": 2.3589,
    "out:LCP/MSEK": -0.487828,
    "out:ROI% old": 12.722464,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1505.653408,
    "out:Return %": 11,
    "out:Return/kSEK/y": 508,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.906978,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5597.767753,
    "out:PV (% sold (El))": 27.858055,
    "out:PV (kWh self-consumed)": 14496.125524,
    "out:PV (ratio sold/self-consumed)": 0.386156,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 183225.566294,
    "out:EL kWh savings": -28008.189439,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135605.84,
    "out:DH kr savings": 183225.56629374676,
    "out:El kr savings": -56016.37887826915,
    "out:El kr sold": 8396.651629701133,
    "out:El kr saved": 28992.251048,
    "out:El kr return": 37388.90267770113,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75702.727836,
    "out:% savings (space heating)": 64.913653,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.15,
    "out:Etvv": 0,
    "out:Ef": 21.64,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4457997.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": -11.301618,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.11942,
    "out:Bought CO2": 2.817802,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.7673,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.285582,
    "out:Electricity (inc PV)": 21.286577,
    "out:Total Energy Use Pre PV": 68.285582,
    "out:Total Energy Use Post PV": 61.286577,
    "out:Primary Energy": 70.402678,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.00813,
    "out:CO2 Operational/m2": 2.779714,
    "out:Total CO2/m2": 34.787844,
    "out:Total CO2 (tons)": 68.601632,
    "out:Klimatpaverkan": -26.33054,
    "out:Initial Cost/MSEK": 4.568073636241866,
    "out:Running cost/(Apt SEK y)": 9189.441176,
    "out:Running Cost over RSP/MSEK": 2.44147,
    "out:LCP/MSEK": -0.680474,
    "out:ROI% old": 12.157672,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 126.2,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1483.919103,
    "out:Return %": 11,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.546082,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5705.195971,
    "out:PV (% sold (El))": 28.392686,
    "out:PV (kWh self-consumed)": 14388.697306,
    "out:PV (ratio sold/self-consumed)": 0.396505,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180507.173997,
    "out:EL kWh savings": -27305.451423,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134454.07,
    "out:DH kr savings": 180507.17399659829,
    "out:El kr savings": -54610.902845019526,
    "out:El kr sold": 8557.793956900141,
    "out:El kr saved": 28777.394612,
    "out:El kr return": 37335.18856890014,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 78421.120133,
    "out:% savings (space heating)": 63.653745,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.25,
    "out:Etvv": 0,
    "out:Ef": 21.29,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4568073.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -11.619646,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.396874,
    "out:Bought CO2": 2.777228,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.565228,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.204077,
    "out:Electricity (inc PV)": 21.222889,
    "out:Total Energy Use Pre PV": 65.204077,
    "out:Total Energy Use Post PV": 58.222889,
    "out:Primary Energy": 67.780747,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.00813,
    "out:CO2 Operational/m2": 1.627433,
    "out:Total CO2/m2": 33.635563,
    "out:Total CO2 (tons)": 66.329334,
    "out:Klimatpaverkan": -28.602838,
    "out:Initial Cost/MSEK": 4.617373636241866,
    "out:Running cost/(Apt SEK y)": 8654.852941,
    "out:Running Cost over RSP/MSEK": 2.29933,
    "out:LCP/MSEK": -0.587634,
    "out:ROI% old": 12.467632,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1479.017832,
    "out:Return %": 11,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.464577,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5729.585958,
    "out:PV (% sold (El))": 28.514066,
    "out:PV (kWh self-consumed)": 14364.307319,
    "out:PV (ratio sold/self-consumed)": 0.398877,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 186821.660346,
    "out:EL kWh savings": -27179.858679,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141056.32,
    "out:DH kr savings": 186821.66034563453,
    "out:El kr savings": -54359.71735849867,
    "out:El kr sold": 8594.378937023439,
    "out:El kr saved": 28728.614638,
    "out:El kr return": 37322.993575023436,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 72106.633784,
    "out:% savings (space heating)": 66.580354,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.03,
    "out:Etvv": 0,
    "out:Ef": 21.22,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4617373.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": -11.691975,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.460053,
    "out:Bought CO2": 2.768078,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.6187,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.719552,
    "out:Electricity (inc PV)": 19.659676,
    "out:Total Energy Use Pre PV": 70.719552,
    "out:Total Energy Use Post PV": 61.659676,
    "out:Primary Energy": 69.150135,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.60259,
    "out:CO2 Operational/m2": -37.921443,
    "out:Total CO2/m2": 4.681147,
    "out:Total CO2 (tons)": 9.231222,
    "out:Klimatpaverkan": -85.70095,
    "out:Initial Cost/MSEK": 4.782652521700616,
    "out:Running cost/(Apt SEK y)": 8643.588235,
    "out:Running Cost over RSP/MSEK": 2.30302,
    "out:LCP/MSEK": -0.756603,
    "out:ROI% old": 12.025753,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 1367.512368,
    "out:Return %": 11,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.980052,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21718.363551,
    "out:PV (% sold (El))": 54.042199,
    "out:PV (kWh self-consumed)": 18469.423004,
    "out:PV (ratio sold/self-consumed)": 1.175909,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 176856.212986,
    "out:EL kWh savings": -24097.202465,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161239.35,
    "out:DH kr savings": 176856.2129855387,
    "out:El kr savings": -48194.404930086195,
    "out:El kr sold": 32577.545326412703,
    "out:El kr saved": 36938.846008,
    "out:El kr return": 69516.3913344127,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 82072.081145,
    "out:% savings (space heating)": 61.961615,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 43.41,
    "out:Etvv": 0,
    "out:Ef": 19.66,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4782652.521700616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.119328,
    "out:EL CO2": -53.040771,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.601292,
    "out:Bought CO2": 2.560521,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.388805,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.646478,
    "out:Electricity (inc PV)": 19.610097,
    "out:Total Energy Use Pre PV": 66.646478,
    "out:Total Energy Use Post PV": 57.610097,
    "out:Primary Energy": 66.527315,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.60259,
    "out:CO2 Operational/m2": -39.457344,
    "out:Total CO2/m2": 3.145246,
    "out:Total CO2 (tons)": 6.202425,
    "out:Klimatpaverkan": -88.729747,
    "out:Initial Cost/MSEK": 4.831952521700616,
    "out:Running cost/(Apt SEK y)": 7934.970588,
    "out:Running Cost over RSP/MSEK": 2.11459,
    "out:LCP/MSEK": -0.617473,
    "out:ROI% old": 12.460151,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1363.557862,
    "out:Return %": 11,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.906978,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21752.33614,
    "out:PV (% sold (El))": 54.126734,
    "out:PV (kWh self-consumed)": 18435.450415,
    "out:PV (ratio sold/self-consumed)": 1.179919,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 183225.566294,
    "out:EL kWh savings": -23999.432671,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167855.21,
    "out:DH kr savings": 183225.56629374676,
    "out:El kr savings": -47998.8653427821,
    "out:El kr sold": 32628.504209523744,
    "out:El kr saved": 36870.90083,
    "out:El kr return": 69499.40503952374,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75702.727836,
    "out:% savings (space heating)": 64.913653,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.15,
    "out:Etvv": 0,
    "out:Ef": 19.61,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4831952.521700616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": -53.136736,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.689866,
    "out:Bought CO2": 2.55313,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.7673,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.285582,
    "out:Electricity (inc PV)": 19.289848,
    "out:Total Energy Use Pre PV": 68.285582,
    "out:Total Energy Use Post PV": 59.289848,
    "out:Primary Energy": 66.808565,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.269933,
    "out:CO2 Operational/m2": -39.213072,
    "out:Total CO2/m2": 4.056861,
    "out:Total CO2 (tons)": 8.00013,
    "out:Klimatpaverkan": -86.932042,
    "out:Initial Cost/MSEK": 4.942028521700615,
    "out:Running cost/(Apt SEK y)": 8245.911765,
    "out:Running Cost over RSP/MSEK": 2.1974,
    "out:LCP/MSEK": -0.810359,
    "out:ROI% old": 11.943245,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 133.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1344.053196,
    "out:Return %": 11,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.546082,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21920.942473,
    "out:PV (% sold (El))": 54.54628,
    "out:PV (kWh self-consumed)": 18266.844082,
    "out:PV (ratio sold/self-consumed)": 1.20004,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180507.173997,
    "out:EL kWh savings": -23367.901607,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166652.78,
    "out:DH kr savings": 180507.17399659829,
    "out:El kr savings": -46735.803213765306,
    "out:El kr sold": 32881.41370985929,
    "out:El kr saved": 36533.688164,
    "out:El kr return": 69415.1018738593,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 78421.120133,
    "out:% savings (space heating)": 63.653745,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.25,
    "out:Etvv": 0,
    "out:Ef": 19.29,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4942028.521700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -53.612432,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.129106,
    "out:Bought CO2": 2.516674,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.565228,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.204077,
    "out:Electricity (inc PV)": 19.232589,
    "out:Total Energy Use Pre PV": 65.204077,
    "out:Total Energy Use Post PV": 56.232589,
    "out:Primary Energy": 64.198207,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.269933,
    "out:CO2 Operational/m2": -40.400894,
    "out:Total CO2/m2": 2.869039,
    "out:Total CO2 (tons)": 5.657745,
    "out:Klimatpaverkan": -89.274427,
    "out:Initial Cost/MSEK": 4.991328521700615,
    "out:Running cost/(Apt SEK y)": 7711.529412,
    "out:Running Cost over RSP/MSEK": 2.05531,
    "out:LCP/MSEK": -0.717569,
    "out:ROI% old": 12.231957,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 146.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 1339.65417,
    "out:Return %": 11,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.464577,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21959.280898,
    "out:PV (% sold (El))": 54.641678,
    "out:PV (kWh self-consumed)": 18228.505657,
    "out:PV (ratio sold/self-consumed)": 1.204667,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 186821.660346,
    "out:EL kWh savings": -23254.986852,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173250.61,
    "out:DH kr savings": 186821.66034563453,
    "out:El kr savings": -46509.973704710304,
    "out:El kr sold": 32938.92134634552,
    "out:El kr saved": 36457.011314,
    "out:El kr return": 69395.93266034553,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 72106.633784,
    "out:% savings (space heating)": 66.580354,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.03,
    "out:Etvv": 0,
    "out:Ef": 19.23,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4991328.521700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.319408,
    "out:EL CO2": -53.720302,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.228755,
    "out:Bought CO2": 2.508453,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.158076,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.138529,
    "out:Electricity (inc PV)": 28.471294,
    "out:Total Energy Use Pre PV": 67.138529,
    "out:Total Energy Use Post PV": 67.471294,
    "out:Primary Energy": 82.785625,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.078984,
    "out:CO2 Operational/m2": 17.753887,
    "out:Total CO2/m2": 37.832871,
    "out:Total CO2 (tons)": 74.606426,
    "out:Klimatpaverkan": -20.325746,
    "out:Initial Cost/MSEK": 4.124479393245616,
    "out:Running cost/(Apt SEK y)": 10095.323529,
    "out:Running Cost over RSP/MSEK": 2.67464,
    "out:LCP/MSEK": -0.470049,
    "out:ROI% old": 12.657632,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1969.69703,
    "out:Return %": 11,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.399029,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 181708.563794,
    "out:EL kWh savings": -41473.714166,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98761.14,
    "out:DH kr savings": 181708.56379404984,
    "out:El kr savings": -82947.42833113503,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 77219.730336,
    "out:% savings (space heating)": 64.21056,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.55,
    "out:Etvv": 0,
    "out:Ef": 28.47,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4124479.3932456155,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": 3.714511,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.714511,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.965046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.054284,
    "out:Electricity (inc PV)": 28.393084,
    "out:Total Energy Use Pre PV": 64.054284,
    "out:Total Energy Use Post PV": 64.393084,
    "out:Primary Energy": 80.145887,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.078984,
    "out:CO2 Operational/m2": 16.662814,
    "out:Total CO2/m2": 36.741798,
    "out:Total CO2 (tons)": 72.45483,
    "out:Klimatpaverkan": -22.477342,
    "out:Initial Cost/MSEK": 4.173779393245616,
    "out:Running cost/(Apt SEK y)": 9560.058824,
    "out:Running Cost over RSP/MSEK": 2.53233,
    "out:LCP/MSEK": -0.377039,
    "out:ROI% old": 12.995211,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1963.79988,
    "out:Return %": 12,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.314784,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188005.219318,
    "out:EL kWh savings": -41319.484037,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105366.25,
    "out:DH kr savings": 188005.21931805526,
    "out:El kr savings": -82638.96807330316,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 70923.074812,
    "out:% savings (space heating)": 67.128904,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.34,
    "out:Etvv": 0,
    "out:Ef": 28.39,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4173779.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": 3.70339,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.70339,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.711103,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.781006,
    "out:Electricity (inc PV)": 28.069349,
    "out:Total Energy Use Pre PV": 65.781006,
    "out:Total Energy Use Post PV": 66.069349,
    "out:Primary Energy": 80.760448,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.746327,
    "out:CO2 Operational/m2": 17.346707,
    "out:Total CO2/m2": 38.093034,
    "out:Total CO2 (tons)": 75.119467,
    "out:Klimatpaverkan": -19.812705,
    "out:Initial Cost/MSEK": 4.283855393245616,
    "out:Running cost/(Apt SEK y)": 9878.676471,
    "out:Running Cost over RSP/MSEK": 2.61716,
    "out:LCP/MSEK": -0.571945,
    "out:ROI% old": 12.378402,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 109.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1944.67042,
    "out:Return %": 11,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.041506,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 184561.994715,
    "out:EL kWh savings": -40681.07858,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 103199.84,
    "out:DH kr savings": 184561.99471500472,
    "out:El kr savings": -81362.15715949157,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 74366.299415,
    "out:% savings (space heating)": 65.533054,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.87,
    "out:Etvv": 0,
    "out:Ef": 28.07,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4283855.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": 3.667315,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.667315,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.541087,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.689786,
    "out:Electricity (inc PV)": 27.983815,
    "out:Total Energy Use Pre PV": 62.689786,
    "out:Total Energy Use Post PV": 62.983815,
    "out:Primary Energy": 78.126329,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.746327,
    "out:CO2 Operational/m2": 16.254713,
    "out:Total CO2/m2": 37.00104,
    "out:Total CO2 (tons)": 72.966055,
    "out:Klimatpaverkan": -21.966117,
    "out:Initial Cost/MSEK": 4.333155393245616,
    "out:Running cost/(Apt SEK y)": 9342.617647,
    "out:Running Cost over RSP/MSEK": 2.47464,
    "out:LCP/MSEK": -0.478725,
    "out:ROI% old": 12.707434,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 119,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 1938.28502,
    "out:Return %": 11,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.950286,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190813.266628,
    "out:EL kWh savings": -40512.405522,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109788.46,
    "out:DH kr savings": 190813.26662838657,
    "out:El kr savings": -81024.81104398984,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 68115.027502,
    "out:% savings (space heating)": 68.430365,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 35.69,
    "out:Etvv": 0,
    "out:Ef": 27.98,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4333155.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": 3.655273,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.655273,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.158076,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.138529,
    "out:Electricity (inc PV)": 21.14669,
    "out:Total Energy Use Pre PV": 67.138529,
    "out:Total Energy Use Post PV": 60.14669,
    "out:Primary Energy": 69.601338,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.340787,
    "out:CO2 Operational/m2": 2.289117,
    "out:Total CO2/m2": 33.629904,
    "out:Total CO2 (tons)": 66.318175,
    "out:Klimatpaverkan": -28.613998,
    "out:Initial Cost/MSEK": 4.498434261241866,
    "out:Running cost/(Apt SEK y)": 8997.852941,
    "out:Running Cost over RSP/MSEK": 2.39057,
    "out:LCP/MSEK": -0.559934,
    "out:ROI% old": 12.507526,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1475.077968,
    "out:Return %": 11,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.399029,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5749.217104,
    "out:PV (% sold (El))": 28.611763,
    "out:PV (kWh self-consumed)": 14344.676173,
    "out:PV (ratio sold/self-consumed)": 0.400791,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 181708.563794,
    "out:EL kWh savings": -27029.594243,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136273.2,
    "out:DH kr savings": 181708.56379404984,
    "out:El kr savings": -54059.18848512528,
    "out:El kr sold": 8623.825656373605,
    "out:El kr saved": 28689.352346,
    "out:El kr return": 37313.17800237361,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 77219.730336,
    "out:% savings (space heating)": 64.21056,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.55,
    "out:Etvv": 0,
    "out:Ef": 21.15,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4498434.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -11.750259,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.510981,
    "out:Bought CO2": 2.760722,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.965046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.054284,
    "out:Electricity (inc PV)": 21.080293,
    "out:Total Energy Use Pre PV": 64.054284,
    "out:Total Energy Use Post PV": 57.080293,
    "out:Primary Energy": 66.982863,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.340787,
    "out:CO2 Operational/m2": 1.134094,
    "out:Total CO2/m2": 32.474881,
    "out:Total CO2 (tons)": 64.040469,
    "out:Klimatpaverkan": -30.891703,
    "out:Initial Cost/MSEK": 4.5477342612418665,
    "out:Running cost/(Apt SEK y)": 8462.970588,
    "out:Running Cost over RSP/MSEK": 2.24835,
    "out:LCP/MSEK": -0.467014,
    "out:ROI% old": 12.818691,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 142.1,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1470.01675,
    "out:Return %": 11,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.314784,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5774.497896,
    "out:PV (% sold (El))": 28.737576,
    "out:PV (kWh self-consumed)": 14319.395381,
    "out:PV (ratio sold/self-consumed)": 0.403264,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188005.219318,
    "out:EL kWh savings": -26898.659351,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142869.65,
    "out:DH kr savings": 188005.21931805526,
    "out:El kr savings": -53797.31870198679,
    "out:El kr sold": 8661.746844731075,
    "out:El kr saved": 28638.790762,
    "out:El kr return": 37300.53760673107,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 70923.074812,
    "out:% savings (space heating)": 67.128904,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.34,
    "out:Etvv": 0,
    "out:Ef": 21.08,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4547734.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -11.82533,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.576602,
    "out:Bought CO2": 2.751272,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.711103,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.781006,
    "out:Electricity (inc PV)": 20.805696,
    "out:Total Energy Use Pre PV": 65.781006,
    "out:Total Energy Use Post PV": 58.805696,
    "out:Primary Energy": 67.685873,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.00813,
    "out:CO2 Operational/m2": 1.60932,
    "out:Total CO2/m2": 33.61745,
    "out:Total CO2 (tons)": 66.293615,
    "out:Klimatpaverkan": -28.638557,
    "out:Initial Cost/MSEK": 4.657810261241866,
    "out:Running cost/(Apt SEK y)": 8782.823529,
    "out:Running Cost over RSP/MSEK": 2.3335,
    "out:LCP/MSEK": -0.66224,
    "out:ROI% old": 12.254594,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 133.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1453.617818,
    "out:Return %": 11,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.041506,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5857.09294,
    "out:PV (% sold (El))": 29.148622,
    "out:PV (kWh self-consumed)": 14236.800337,
    "out:PV (ratio sold/self-consumed)": 0.411405,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184561.994715,
    "out:EL kWh savings": -26357.154036,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140633.33,
    "out:DH kr savings": 184561.99471500472,
    "out:El kr savings": -52714.30807137867,
    "out:El kr sold": 8785.63941064223,
    "out:El kr saved": 28473.600674,
    "out:El kr return": 37259.24008464223,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 74366.299415,
    "out:% savings (space heating)": 65.533054,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.87,
    "out:Etvv": 0,
    "out:Ef": 20.81,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4657810.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": -12.070072,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.790723,
    "out:Bought CO2": 2.720651,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.541087,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.689786,
    "out:Electricity (inc PV)": 20.733211,
    "out:Total Energy Use Pre PV": 62.689786,
    "out:Total Energy Use Post PV": 55.733211,
    "out:Primary Energy": 65.075242,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.00813,
    "out:CO2 Operational/m2": 0.447249,
    "out:Total CO2/m2": 32.455379,
    "out:Total CO2 (tons)": 64.002011,
    "out:Klimatpaverkan": -30.930161,
    "out:Initial Cost/MSEK": 4.7071102612418665,
    "out:Running cost/(Apt SEK y)": 8247.176471,
    "out:Running Cost over RSP/MSEK": 2.19108,
    "out:LCP/MSEK": -0.56912,
    "out:ROI% old": 12.558478,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 146.4,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 1448.150428,
    "out:Return %": 11,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.950286,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5884.863358,
    "out:PV (% sold (El))": 29.286825,
    "out:PV (kWh self-consumed)": 14209.02992,
    "out:PV (ratio sold/self-consumed)": 0.414164,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190813.266628,
    "out:EL kWh savings": -26214.213607,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 147212.13,
    "out:DH kr savings": 190813.26662838657,
    "out:El kr savings": -52428.42721485209,
    "out:El kr sold": 8827.295036682402,
    "out:El kr saved": 28418.05984,
    "out:El kr return": 37245.3548766824,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 68115.027502,
    "out:% savings (space heating)": 68.430365,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 35.69,
    "out:Etvv": 0,
    "out:Ef": 20.73,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4707110.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": -12.152191,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.862631,
    "out:Bought CO2": 2.71044,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.158076,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.138529,
    "out:Electricity (inc PV)": 19.164116,
    "out:Total Energy Use Pre PV": 67.138529,
    "out:Total Energy Use Post PV": 58.164116,
    "out:Primary Energy": 66.032705,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.60259,
    "out:CO2 Operational/m2": -39.76779,
    "out:Total CO2/m2": 2.8348,
    "out:Total CO2 (tons)": 5.590226,
    "out:Klimatpaverkan": -89.341946,
    "out:Initial Cost/MSEK": 4.872389146700615,
    "out:Running cost/(Apt SEK y)": 8054.705882,
    "out:Running Cost over RSP/MSEK": 2.14659,
    "out:LCP/MSEK": -0.689909,
    "out:ROI% old": 12.262919,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1336.117987,
    "out:Return %": 11,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.399029,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21990.16226,
    "out:PV (% sold (El))": 54.718521,
    "out:PV (kWh self-consumed)": 18197.624294,
    "out:PV (ratio sold/self-consumed)": 1.208408,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 181708.563794,
    "out:EL kWh savings": -23119.958089,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168453.89,
    "out:DH kr savings": 181708.56379404984,
    "out:El kr savings": -46239.916177098414,
    "out:El kr sold": 32985.24339064107,
    "out:El kr saved": 36395.248588,
    "out:El kr return": 69380.49197864108,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 77219.730336,
    "out:% savings (space heating)": 64.21056,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 40.55,
    "out:Etvv": 0,
    "out:Ef": 19.16,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4872389.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -53.807166,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.30901,
    "out:Bought CO2": 2.501844,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.965046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.054284,
    "out:Electricity (inc PV)": 19.104451,
    "out:Total Energy Use Pre PV": 64.054284,
    "out:Total Energy Use Post PV": 55.104451,
    "out:Primary Energy": 63.426348,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.60259,
    "out:CO2 Operational/m2": -40.959575,
    "out:Total CO2/m2": 1.643015,
    "out:Total CO2 (tons)": 3.240026,
    "out:Klimatpaverkan": -91.692146,
    "out:Initial Cost/MSEK": 4.921689146700615,
    "out:Running cost/(Apt SEK y)": 7520.029412,
    "out:Running Cost over RSP/MSEK": 2.00443,
    "out:LCP/MSEK": -0.597049,
    "out:ROI% old": 12.552717,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 150.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1331.575244,
    "out:Return %": 11,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 18.314784,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22029.902615,
    "out:PV (% sold (El))": 54.817407,
    "out:PV (kWh self-consumed)": 18157.88394,
    "out:PV (ratio sold/self-consumed)": 1.213242,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188005.219318,
    "out:EL kWh savings": -23002.298702,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175045.48,
    "out:DH kr savings": 188005.21931805526,
    "out:El kr savings": -46004.59740349481,
    "out:El kr sold": 33044.853922504866,
    "out:El kr saved": 36315.76788,
    "out:El kr return": 69360.62180250487,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 70923.074812,
    "out:% savings (space heating)": 67.128904,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 37.34,
    "out:Etvv": 0,
    "out:Ef": 19.1,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4921689.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -53.918999,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.412353,
    "out:Bought CO2": 2.493354,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.711103,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.781006,
    "out:Electricity (inc PV)": 18.85789,
    "out:Total Energy Use Pre PV": 65.781006,
    "out:Total Energy Use Post PV": 56.85789,
    "out:Primary Energy": 64.179822,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.269933,
    "out:CO2 Operational/m2": -40.603749,
    "out:Total CO2/m2": 2.666184,
    "out:Total CO2 (tons)": 5.257715,
    "out:Klimatpaverkan": -89.674457,
    "out:Initial Cost/MSEK": 5.031765146700615,
    "out:Running cost/(Apt SEK y)": 7840.558824,
    "out:Running Cost over RSP/MSEK": 2.08976,
    "out:LCP/MSEK": -0.792455,
    "out:ROI% old": 12.03585,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 142.1,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 1316.856389,
    "out:Return %": 11,
    "out:Return/kSEK/y": 543,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 18.041506,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22159.456106,
    "out:PV (% sold (El))": 55.139778,
    "out:PV (kWh self-consumed)": 18028.330449,
    "out:PV (ratio sold/self-consumed)": 1.229146,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184561.994715,
    "out:EL kWh savings": -22516.080382,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172769.02,
    "out:DH kr savings": 184561.99471500472,
    "out:El kr savings": -45032.1607632789,
    "out:El kr sold": 33239.18415827407,
    "out:El kr saved": 36056.660898,
    "out:El kr return": 69295.84505627406,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 74366.299415,
    "out:% savings (space heating)": 65.533054,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.87,
    "out:Etvv": 0,
    "out:Ef": 18.86,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5031765.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 13.679392,
    "out:EL CO2": -54.283141,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.748994,
    "out:Bought CO2": 2.465853,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 34.541087,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.689786,
    "out:Electricity (inc PV)": 18.792865,
    "out:Total Energy Use Pre PV": 62.689786,
    "out:Total Energy Use Post PV": 53.792865,
    "out:Primary Energy": 61.582619,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.269933,
    "out:CO2 Operational/m2": -41.805739,
    "out:Total CO2/m2": 1.464194,
    "out:Total CO2 (tons)": 2.887391,
    "out:Klimatpaverkan": -92.044782,
    "out:Initial Cost/MSEK": 5.081065146700615,
    "out:Running cost/(Apt SEK y)": 7305.147059,
    "out:Running Cost over RSP/MSEK": 1.9474,
    "out:LCP/MSEK": -0.699395,
    "out:ROI% old": 12.319324,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 155.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 1311.949227,
    "out:Return %": 11,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.950286,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22202.918432,
    "out:PV (% sold (El))": 55.247926,
    "out:PV (kWh self-consumed)": 17984.868123,
    "out:PV (ratio sold/self-consumed)": 1.234533,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190813.266628,
    "out:EL kWh savings": -22387.851074,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179341.94,
    "out:DH kr savings": 190813.26662838657,
    "out:El kr savings": -44775.70214845324,
    "out:El kr sold": 33304.3776474646,
    "out:El kr saved": 35969.736246,
    "out:El kr return": 69274.1138934646,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 68115.027502,
    "out:% savings (space heating)": 68.430365,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 35.69,
    "out:Etvv": 0,
    "out:Ef": 18.79,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5081065.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": -54.405179,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.861864,
    "out:Bought CO2": 2.456685,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.191117,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.152153,
    "out:Electricity (inc PV)": 39.844393,
    "out:Total Energy Use Pre PV": 46.152153,
    "out:Total Energy Use Post PV": 46.844393,
    "out:Primary Energy": 77.955138,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.517939,
    "out:CO2 Operational/m2": 7.688285,
    "out:Total CO2/m2": 28.206224,
    "out:Total CO2 (tons)": 55.622677,
    "out:Klimatpaverkan": -39.309495,
    "out:Initial Cost/MSEK": 4.830242768245617,
    "out:Running cost/(Apt SEK y)": 5767.764706,
    "out:Running Cost over RSP/MSEK": 1.51984,
    "out:LCP/MSEK": -0.021013,
    "out:ROI% old": 14.223568,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 193.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2740.65071,
    "out:Return %": 13,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.412653,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 244747.410586,
    "out:EL kWh savings": -63901.46669,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116944.48,
    "out:DH kr savings": 244747.41058628313,
    "out:El kr savings": -127802.93338020159,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14180.883544,
    "out:% savings (space heating)": 93.42751,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.02,
    "out:Etvv": 0,
    "out:Ef": 39.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4830242.768245617,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 5.168397,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.168397,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.420653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.636827,
    "out:Electricity (inc PV)": 39.36,
    "out:Total Energy Use Pre PV": 43.636827,
    "out:Total Energy Use Post PV": 44.36,
    "out:Primary Energy": 75.626809,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.517939,
    "out:CO2 Operational/m2": 6.90029,
    "out:Total CO2/m2": 27.418229,
    "out:Total CO2 (tons)": 54.068751,
    "out:Klimatpaverkan": -40.863422,
    "out:Initial Cost/MSEK": 4.879542768245617,
    "out:Running cost/(Apt SEK y)": 5357.676471,
    "out:Running Cost over RSP/MSEK": 1.41096,
    "out:LCP/MSEK": 0.038567,
    "out:ROI% old": 14.398627,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 213.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2704.57789,
    "out:Return %": 13,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 28.897327,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248238.765796,
    "out:EL kWh savings": -62946.243639,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122346.28,
    "out:DH kr savings": 248238.76579611606,
    "out:El kr savings": -125892.48727776,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10689.528334,
    "out:% savings (space heating)": 95.045667,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.14,
    "out:Etvv": 0,
    "out:Ef": 39.36,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4879542.768245617,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 5.10037,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.10037,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.375811,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.404215,
    "out:Electricity (inc PV)": 39.007838,
    "out:Total Energy Use Pre PV": 44.404215,
    "out:Total Energy Use Post PV": 45.007838,
    "out:Primary Energy": 75.67983,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.185282,
    "out:CO2 Operational/m2": 7.229568,
    "out:Total CO2/m2": 28.41485,
    "out:Total CO2 (tons)": 56.034087,
    "out:Klimatpaverkan": -38.898085,
    "out:Initial Cost/MSEK": 4.989618768245616,
    "out:Running cost/(Apt SEK y)": 5505.852941,
    "out:Running Cost over RSP/MSEK": 1.45045,
    "out:LCP/MSEK": -0.110999,
    "out:ROI% old": 13.967915,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2688.29505,
    "out:Return %": 12,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.664715,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 246355.194111,
    "out:EL kWh savings": -62251.780135,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121851.63,
    "out:DH kr savings": 246355.19411122805,
    "out:El kr savings": -124503.56026946705,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12573.100019,
    "out:% savings (space heating)": 94.172678,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.03,
    "out:Etvv": 0,
    "out:Ef": 39.01,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4989618.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 5.069664,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.069664,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.713528,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.856107,
    "out:Electricity (inc PV)": 38.486164,
    "out:Total Energy Use Pre PV": 42.856107,
    "out:Total Energy Use Post PV": 43.486164,
    "out:Primary Energy": 73.380082,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.185282,
    "out:CO2 Operational/m2": 6.797229,
    "out:Total CO2/m2": 27.982511,
    "out:Total CO2 (tons)": 55.181515,
    "out:Klimatpaverkan": -39.750657,
    "out:Initial Cost/MSEK": 5.038918768245616,
    "out:Running cost/(Apt SEK y)": 5267.117647,
    "out:Running Cost over RSP/MSEK": 1.38715,
    "out:LCP/MSEK": -0.096999,
    "out:ROI% old": 14.010715,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 220.9,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2649.92749,
    "out:Return %": 13,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.116607,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 249633.216377,
    "out:EL kWh savings": -61223.038947,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127187.14,
    "out:DH kr savings": 249633.21637672832,
    "out:El kr savings": -122446.07789452541,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 9295.077753,
    "out:% savings (space heating)": 95.691961,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 38.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5038918.768245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 4.997309,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.997309,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.191117,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.152153,
    "out:Electricity (inc PV)": 31.118272,
    "out:Total Energy Use Pre PV": 46.152153,
    "out:Total Energy Use Post PV": 38.118272,
    "out:Primary Energy": 62.248121,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.779742,
    "out:CO2 Operational/m2": -0.932715,
    "out:Total CO2/m2": 30.847027,
    "out:Total CO2 (tons)": 60.830341,
    "out:Klimatpaverkan": -34.101831,
    "out:Initial Cost/MSEK": 5.204197636241866,
    "out:Running cost/(Apt SEK y)": 4630.235294,
    "out:Running Cost over RSP/MSEK": 1.22545,
    "out:LCP/MSEK": -0.100578,
    "out:ROI% old": 14.009625,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 263.2,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2157.365629,
    "out:Return %": 13,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.412653,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3023.097227,
    "out:PV (% sold (El))": 15.044856,
    "out:PV (kWh self-consumed)": 17070.79605,
    "out:PV (ratio sold/self-consumed)": 0.177092,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244747.410586,
    "out:EL kWh savings": -46693.555083,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155894.95,
    "out:DH kr savings": 244747.41058628313,
    "out:El kr savings": -93387.11016664603,
    "out:El kr sold": 4534.645840876971,
    "out:El kr saved": 34141.5921,
    "out:El kr return": 38676.23794087697,
    "out:% solar/total": 116,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14180.883544,
    "out:% savings (space heating)": 93.42751,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.02,
    "out:Etvv": 0,
    "out:Ef": 31.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5204197.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -3.452603,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.488339,
    "out:Bought CO2": 4.035736,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.420653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.636827,
    "out:Electricity (inc PV)": 30.682375,
    "out:Total Energy Use Pre PV": 43.636827,
    "out:Total Energy Use Post PV": 35.682375,
    "out:Primary Energy": 60.007084,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.779742,
    "out:CO2 Operational/m2": -1.981563,
    "out:Total CO2/m2": 29.798179,
    "out:Total CO2 (tons)": 58.762012,
    "out:Klimatpaverkan": -36.17016,
    "out:Initial Cost/MSEK": 5.253497636241867,
    "out:Running cost/(Apt SEK y)": 4221.676471,
    "out:Running Cost over RSP/MSEK": 1.11696,
    "out:LCP/MSEK": -0.041388,
    "out:ROI% old": 14.17317,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2124.581983,
    "out:Return %": 13,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 28.897327,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3127.078283,
    "out:PV (% sold (El))": 15.562332,
    "out:PV (kWh self-consumed)": 16966.814994,
    "out:PV (ratio sold/self-consumed)": 0.184306,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248238.765796,
    "out:EL kWh savings": -45833.96615,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161261.45,
    "out:DH kr savings": 248238.76579611606,
    "out:El kr savings": -91667.93229926146,
    "out:El kr sold": 4690.617425048533,
    "out:El kr saved": 33933.629988,
    "out:El kr return": 38624.247413048535,
    "out:% solar/total": 116,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10689.528334,
    "out:% savings (space heating)": 95.045667,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.14,
    "out:Etvv": 0,
    "out:Ef": 30.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5253497.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -3.781483,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.75601,
    "out:Bought CO2": 3.974527,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.375811,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.404215,
    "out:Electricity (inc PV)": 30.365932,
    "out:Total Energy Use Pre PV": 44.404215,
    "out:Total Energy Use Post PV": 36.365932,
    "out:Primary Energy": 60.1244,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.447085,
    "out:CO2 Operational/m2": -1.771597,
    "out:Total CO2/m2": 30.675488,
    "out:Total CO2 (tons)": 60.492066,
    "out:Klimatpaverkan": -34.440106,
    "out:Initial Cost/MSEK": 5.363573636241866,
    "out:Running cost/(Apt SEK y)": 4370.529412,
    "out:Running Cost over RSP/MSEK": 1.15663,
    "out:LCP/MSEK": -0.191134,
    "out:ROI% old": 13.776636,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2109.810579,
    "out:Return %": 12,
    "out:Return/kSEK/y": 661,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.664715,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3174.67176,
    "out:PV (% sold (El))": 15.799187,
    "out:PV (kWh self-consumed)": 16919.221517,
    "out:PV (ratio sold/self-consumed)": 0.187637,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246355.194111,
    "out:EL kWh savings": -45209.940518,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160697.32,
    "out:DH kr savings": 246355.19411122805,
    "out:El kr savings": -90419.88103511251,
    "out:El kr sold": 4762.007639917467,
    "out:El kr saved": 33838.443034,
    "out:El kr return": 38600.45067391747,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12573.100019,
    "out:% savings (space heating)": 94.172678,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.03,
    "out:Etvv": 0,
    "out:Ef": 30.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5363573.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -3.931501,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.87844,
    "out:Bought CO2": 3.946939,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.713528,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.856107,
    "out:Electricity (inc PV)": 29.897919,
    "out:Total Energy Use Pre PV": 42.856107,
    "out:Total Energy Use Post PV": 34.897919,
    "out:Primary Energy": 57.921241,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.447085,
    "out:CO2 Operational/m2": -2.488706,
    "out:Total CO2/m2": 29.958379,
    "out:Total CO2 (tons)": 59.077927,
    "out:Klimatpaverkan": -35.854245,
    "out:Initial Cost/MSEK": 5.412873636241867,
    "out:Running cost/(Apt SEK y)": 4133.470588,
    "out:Running Cost over RSP/MSEK": 1.09377,
    "out:LCP/MSEK": -0.177574,
    "out:ROI% old": 13.81706,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 294.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2075.069611,
    "out:Return %": 12,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.116607,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3288.248261,
    "out:PV (% sold (El))": 16.364416,
    "out:PV (kWh self-consumed)": 16805.645016,
    "out:PV (ratio sold/self-consumed)": 0.195663,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 249633.216377,
    "out:EL kWh savings": -44287.018828,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165991.55,
    "out:DH kr savings": 249633.21637672832,
    "out:El kr savings": -88574.03765640556,
    "out:El kr sold": 4932.372391349036,
    "out:El kr saved": 33611.290032,
    "out:El kr return": 38543.66242334903,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 9295.077753,
    "out:% savings (space heating)": 95.691961,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 29.9,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5412873.636241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -4.288626,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.170653,
    "out:Bought CO2": 3.882027,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.191117,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.152153,
    "out:Electricity (inc PV)": 28.113701,
    "out:Total Energy Use Pre PV": 46.152153,
    "out:Total Energy Use Post PV": 35.113701,
    "out:Primary Energy": 56.839893,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.041545,
    "out:CO2 Operational/m2": -37.987131,
    "out:Total CO2/m2": 5.054414,
    "out:Total CO2 (tons)": 9.967305,
    "out:Klimatpaverkan": -84.964867,
    "out:Initial Cost/MSEK": 5.578152521700616,
    "out:Running cost/(Apt SEK y)": 3658.470588,
    "out:Running Cost over RSP/MSEK": 0.97392,
    "out:LCP/MSEK": -0.223003,
    "out:ROI% old": 13.714602,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 294.3,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 1947.968537,
    "out:Return %": 12,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 29.412653,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17319.75635,
    "out:PV (% sold (El))": 43.097065,
    "out:PV (kWh self-consumed)": 22868.030205,
    "out:PV (ratio sold/self-consumed)": 0.757379,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244747.410586,
    "out:EL kWh savings": -40768.540729,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189189.96,
    "out:DH kr savings": 244747.41058628313,
    "out:El kr savings": -81537.08145760378,
    "out:El kr sold": 25979.63452452432,
    "out:El kr saved": 45736.06041,
    "out:El kr return": 71715.69493452432,
    "out:% solar/total": 156,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 14180.883544,
    "out:% savings (space heating)": 93.42751,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 8.02,
    "out:Etvv": 0,
    "out:Ef": 28.11,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5578152.521700616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -40.507019,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.153185,
    "out:Bought CO2": 3.646166,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.420653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.636827,
    "out:Electricity (inc PV)": 27.723136,
    "out:Total Energy Use Pre PV": 43.636827,
    "out:Total Energy Use Post PV": 32.723136,
    "out:Primary Energy": 54.680454,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 43.041545,
    "out:CO2 Operational/m2": -39.280041,
    "out:Total CO2/m2": 3.761504,
    "out:Total CO2 (tons)": 7.417686,
    "out:Klimatpaverkan": -87.514486,
    "out:Initial Cost/MSEK": 5.627452521700616,
    "out:Running cost/(Apt SEK y)": 3251.323529,
    "out:Running Cost over RSP/MSEK": 0.86581,
    "out:LCP/MSEK": -0.164193,
    "out:ROI% old": 13.868899,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1918.682461,
    "out:Return %": 12,
    "out:Return/kSEK/y": 699,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 28.897327,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17518.808969,
    "out:PV (% sold (El))": 43.592371,
    "out:PV (kWh self-consumed)": 22668.977586,
    "out:PV (ratio sold/self-consumed)": 0.77281,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248238.765796,
    "out:EL kWh savings": -39998.346504,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194520.29,
    "out:DH kr savings": 248238.76579611606,
    "out:El kr savings": -79996.69300855498,
    "out:El kr sold": 26278.21345327268,
    "out:El kr saved": 45337.955172,
    "out:El kr return": 71616.16862527268,
    "out:% solar/total": 155,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10689.528334,
    "out:% savings (space heating)": 95.045667,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.14,
    "out:Etvv": 0,
    "out:Ef": 27.72,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5627452.521700616,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -41.079961,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.671294,
    "out:Bought CO2": 3.591333,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.375811,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.404215,
    "out:Electricity (inc PV)": 27.439664,
    "out:Total Energy Use Pre PV": 44.404215,
    "out:Total Energy Use Post PV": 33.439664,
    "out:Primary Energy": 54.857117,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 43.708888,
    "out:CO2 Operational/m2": -39.179984,
    "out:Total CO2/m2": 4.528904,
    "out:Total CO2 (tons)": 8.930999,
    "out:Klimatpaverkan": -86.001173,
    "out:Initial Cost/MSEK": 5.737528521700617,
    "out:Running cost/(Apt SEK y)": 3400.823529,
    "out:Running Cost over RSP/MSEK": 0.90564,
    "out:LCP/MSEK": -0.314099,
    "out:ROI% old": 13.503649,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1905.48216,
    "out:Return %": 12,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.664715,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17609.083052,
    "out:PV (% sold (El))": 43.817002,
    "out:PV (kWh self-consumed)": 22578.703503,
    "out:PV (ratio sold/self-consumed)": 0.779898,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246355.194111,
    "out:EL kWh savings": -39439.339688,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193890.14,
    "out:DH kr savings": 246355.19411122805,
    "out:El kr savings": -78878.67937592338,
    "out:El kr sold": 26413.62457736003,
    "out:El kr saved": 45157.407006,
    "out:El kr return": 71571.03158336003,
    "out:% solar/total": 154,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12573.100019,
    "out:% savings (space heating)": 94.172678,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 7.03,
    "out:Etvv": 0,
    "out:Ef": 27.44,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5737528.521700617,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -41.339888,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.906504,
    "out:Bought CO2": 3.566616,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.713528,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.856107,
    "out:Electricity (inc PV)": 27.020374,
    "out:Total Energy Use Pre PV": 42.856107,
    "out:Total Energy Use Post PV": 32.020374,
    "out:Primary Energy": 52.74166,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 43.708888,
    "out:CO2 Operational/m2": -40.155494,
    "out:Total CO2/m2": 3.553394,
    "out:Total CO2 (tons)": 7.007293,
    "out:Klimatpaverkan": -87.924879,
    "out:Initial Cost/MSEK": 5.786828521700617,
    "out:Running cost/(Apt SEK y)": 3165.235294,
    "out:Running Cost over RSP/MSEK": 0.84317,
    "out:LCP/MSEK": -0.300929,
    "out:ROI% old": 13.542824,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 331.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 1874.422631,
    "out:Return %": 12,
    "out:Return/kSEK/y": 702,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 28.116607,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17822.982438,
    "out:PV (% sold (El))": 44.349251,
    "out:PV (kWh self-consumed)": 22364.804116,
    "out:PV (ratio sold/self-consumed)": 0.796921,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 249633.216377,
    "out:EL kWh savings": -38612.49976,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199142.69,
    "out:DH kr savings": 249633.21637672832,
    "out:El kr savings": -77224.9995203253,
    "out:El kr sold": 26734.473657591472,
    "out:El kr saved": 44729.608232,
    "out:El kr return": 71464.08188959147,
    "out:% solar/total": 152,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 9295.077753,
    "out:% savings (space heating)": 95.691961,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 27.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5786828.521700617,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -41.955414,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.463869,
    "out:Bought CO2": 3.508455,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.116887,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.151011,
    "out:Electricity (inc PV)": 38.72407,
    "out:Total Energy Use Pre PV": 44.151011,
    "out:Total Energy Use Post PV": 44.72407,
    "out:Primary Energy": 74.925163,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.517939,
    "out:CO2 Operational/m2": 7.196143,
    "out:Total CO2/m2": 27.714082,
    "out:Total CO2 (tons)": 54.652173,
    "out:Klimatpaverkan": -40.279999,
    "out:Initial Cost/MSEK": 4.919979393245616,
    "out:Running cost/(Apt SEK y)": 5476.470588,
    "out:Running Cost over RSP/MSEK": 1.44273,
    "out:LCP/MSEK": -0.033639,
    "out:ROI% old": 14.188039,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2670.57077,
    "out:Return %": 13,
    "out:Return/kSEK/y": 623,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.411511,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246865.792269,
    "out:EL kWh savings": -61692.189606,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123481.41,
    "out:DH kr savings": 246865.79226874537,
    "out:El kr savings": -123384.37921276795,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12062.501861,
    "out:% savings (space heating)": 94.409328,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.71,
    "out:Etvv": 0,
    "out:Ef": 38.72,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4919979.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 5.036239,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.036239,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.492151,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.591525,
    "out:Electricity (inc PV)": 38.18975,
    "out:Total Energy Use Pre PV": 41.591525,
    "out:Total Energy Use Post PV": 42.18975,
    "out:Primary Energy": 72.635111,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.517939,
    "out:CO2 Operational/m2": 6.402318,
    "out:Total CO2/m2": 26.920257,
    "out:Total CO2 (tons)": 53.08675,
    "out:Klimatpaverkan": -41.845422,
    "out:Initial Cost/MSEK": 4.969279393245616,
    "out:Running cost/(Apt SEK y)": 5061.264706,
    "out:Running Cost over RSP/MSEK": 1.3325,
    "out:LCP/MSEK": 0.027291,
    "out:ROI% old": 14.364169,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 228.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2631.40675,
    "out:Return %": 13,
    "out:Return/kSEK/y": 637,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 27.852025,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 250069.771846,
    "out:EL kWh savings": -60638.510505,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128792.75,
    "out:DH kr savings": 250069.77184596527,
    "out:El kr savings": -121277.02101094298,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 8858.522284,
    "out:% savings (space heating)": 95.894293,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.01,
    "out:Etvv": 0,
    "out:Ef": 38.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4969279.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 4.962382,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.962382,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.518706,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.536278,
    "out:Electricity (inc PV)": 38.032024,
    "out:Total Energy Use Pre PV": 43.536278,
    "out:Total Energy Use Post PV": 44.032024,
    "out:Primary Energy": 73.122398,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.185282,
    "out:CO2 Operational/m2": 7.114993,
    "out:Total CO2/m2": 28.300275,
    "out:Total CO2 (tons)": 55.808146,
    "out:Klimatpaverkan": -39.124027,
    "out:Initial Cost/MSEK": 5.079355393245616,
    "out:Running cost/(Apt SEK y)": 5405.176471,
    "out:Running Cost over RSP/MSEK": 1.42398,
    "out:LCP/MSEK": -0.174265,
    "out:ROI% old": 13.795592,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 213.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2627.53946,
    "out:Return %": 12,
    "out:Return/kSEK/y": 626,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.796778,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248045.405269,
    "out:EL kWh savings": -60327.474815,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127390.46,
    "out:DH kr savings": 248045.40526893802,
    "out:El kr savings": -120654.94963098146,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10882.888861,
    "out:% savings (space heating)": 94.956049,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 38.03,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5079355.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 4.955089,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.955089,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.992935,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.946802,
    "out:Electricity (inc PV)": 37.466916,
    "out:Total Energy Use Pre PV": 40.946802,
    "out:Total Energy Use Post PV": 41.466916,
    "out:Primary Energy": 70.85595,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.185282,
    "out:CO2 Operational/m2": 6.317209,
    "out:Total CO2/m2": 27.502491,
    "out:Total CO2 (tons)": 54.234915,
    "out:Klimatpaverkan": -40.697257,
    "out:Initial Cost/MSEK": 5.128655393245616,
    "out:Running cost/(Apt SEK y)": 4986.470588,
    "out:Running Cost over RSP/MSEK": 1.31285,
    "out:LCP/MSEK": -0.112435,
    "out:ROI% old": 13.972529,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 236.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2586.27614,
    "out:Return %": 12,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.207302,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 251054.225855,
    "out:EL kWh savings": -59213.081775,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132628.06,
    "out:DH kr savings": 251054.2258548759,
    "out:El kr savings": -118426.1635501368,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 7874.068275,
    "out:% savings (space heating)": 96.350564,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 4.39,
    "out:Etvv": 0,
    "out:Ef": 37.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5128655.393245616,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 4.877273,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.877273,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.116887,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.151011,
    "out:Electricity (inc PV)": 30.111221,
    "out:Total Energy Use Pre PV": 44.151011,
    "out:Total Energy Use Post PV": 36.111221,
    "out:Primary Energy": 59.422035,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.779742,
    "out:CO2 Operational/m2": -1.935923,
    "out:Total CO2/m2": 29.843819,
    "out:Total CO2 (tons)": 58.852014,
    "out:Klimatpaverkan": -36.080158,
    "out:Initial Cost/MSEK": 5.2939342612418665,
    "out:Running cost/(Apt SEK y)": 4341.941176,
    "out:Running Cost over RSP/MSEK": 1.14911,
    "out:LCP/MSEK": -0.113974,
    "out:ROI% old": 13.978154,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2093.750341,
    "out:Return %": 13,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.411511,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3226.801628,
    "out:PV (% sold (El))": 16.058618,
    "out:PV (kWh self-consumed)": 16867.091649,
    "out:PV (ratio sold/self-consumed)": 0.191308,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246865.792269,
    "out:EL kWh savings": -44707.650397,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162290.69,
    "out:DH kr savings": 246865.79226874537,
    "out:El kr savings": -89415.30079303838,
    "out:El kr sold": 4840.20244215895,
    "out:El kr saved": 33734.183298,
    "out:El kr return": 38574.385740158956,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12062.501861,
    "out:% savings (space heating)": 94.409328,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.71,
    "out:Etvv": 0,
    "out:Ef": 30.11,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5293934.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -4.095827,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.012762,
    "out:Bought CO2": 3.916935,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.492151,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.591525,
    "out:Electricity (inc PV)": 29.632397,
    "out:Total Energy Use Pre PV": 41.591525,
    "out:Total Energy Use Post PV": 33.632397,
    "out:Primary Energy": 57.231876,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.779742,
    "out:CO2 Operational/m2": -3.023162,
    "out:Total CO2/m2": 28.75658,
    "out:Total CO2 (tons)": 56.707979,
    "out:Klimatpaverkan": -38.224193,
    "out:Initial Cost/MSEK": 5.343234261241866,
    "out:Running cost/(Apt SEK y)": 3928.441176,
    "out:Running Cost over RSP/MSEK": 1.03933,
    "out:LCP/MSEK": -0.053494,
    "out:ROI% old": 14.142692,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 305.9,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2058.332519,
    "out:Return %": 13,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 27.852025,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3344.090334,
    "out:PV (% sold (El))": 16.642322,
    "out:PV (kWh self-consumed)": 16749.802943,
    "out:PV (ratio sold/self-consumed)": 0.19965,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 250069.771846,
    "out:EL kWh savings": -43763.409414,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167559.09,
    "out:DH kr savings": 250069.77184596527,
    "out:El kr savings": -87526.81882786647,
    "out:El kr sold": 5016.135501186186,
    "out:El kr saved": 33499.605886,
    "out:El kr return": 38515.741387186186,
    "out:% solar/total": 114,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 8858.522284,
    "out:% savings (space heating)": 95.894293,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.01,
    "out:Etvv": 0,
    "out:Ef": 29.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5343234.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -4.463098,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.313845,
    "out:Bought CO2": 3.850747,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.518706,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.536278,
    "out:Electricity (inc PV)": 29.491249,
    "out:Total Energy Use Pre PV": 43.536278,
    "out:Total Energy Use Post PV": 35.491249,
    "out:Primary Energy": 57.749003,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.447085,
    "out:CO2 Operational/m2": -2.339784,
    "out:Total CO2/m2": 30.107301,
    "out:Total CO2 (tons)": 59.371601,
    "out:Klimatpaverkan": -35.560571,
    "out:Initial Cost/MSEK": 5.4533102612418665,
    "out:Running cost/(Apt SEK y)": 4272.529412,
    "out:Running Cost over RSP/MSEK": 1.13085,
    "out:LCP/MSEK": -0.25509,
    "out:ROI% old": 13.617469,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 294.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2054.840263,
    "out:Return %": 12,
    "out:Return/kSEK/y": 664,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.796778,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3355.797809,
    "out:PV (% sold (El))": 16.700585,
    "out:PV (kWh self-consumed)": 16738.095469,
    "out:PV (ratio sold/self-consumed)": 0.200489,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248045.405269,
    "out:EL kWh savings": -43485.065542,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166108.97,
    "out:DH kr savings": 248045.40526893802,
    "out:El kr savings": -86970.13108368473,
    "out:El kr sold": 5033.696712798884,
    "out:El kr saved": 33476.190938,
    "out:El kr return": 38509.88765079888,
    "out:% solar/total": 114,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10882.888861,
    "out:% savings (space heating)": 94.956049,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 29.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5453310.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -4.499688,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.343908,
    "out:Bought CO2": 3.84422,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.992935,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.946802,
    "out:Electricity (inc PV)": 28.986504,
    "out:Total Energy Use Pre PV": 40.946802,
    "out:Total Energy Use Post PV": 32.986504,
    "out:Primary Energy": 55.591208,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 32.447085,
    "out:CO2 Operational/m2": -3.454258,
    "out:Total CO2/m2": 28.992827,
    "out:Total CO2 (tons)": 57.173858,
    "out:Klimatpaverkan": -37.758314,
    "out:Initial Cost/MSEK": 5.502610261241866,
    "out:Running cost/(Apt SEK y)": 3855.676471,
    "out:Running Cost over RSP/MSEK": 1.02019,
    "out:LCP/MSEK": -0.19373,
    "out:ROI% old": 13.782758,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2017.636911,
    "out:Return %": 12,
    "out:Return/kSEK/y": 678,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.207302,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3482.49222,
    "out:PV (% sold (El))": 17.331097,
    "out:PV (kWh self-consumed)": 16611.401057,
    "out:PV (ratio sold/self-consumed)": 0.209645,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 251054.225855,
    "out:EL kWh savings": -42489.708344,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171298.55,
    "out:DH kr savings": 251054.2258548759,
    "out:El kr savings": -84979.41668860287,
    "out:El kr sold": 5223.738330466287,
    "out:El kr saved": 33222.802114,
    "out:El kr return": 38446.540444466285,
    "out:% solar/total": 113,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 7874.068275,
    "out:% savings (space heating)": 96.350564,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 4.39,
    "out:Etvv": 0,
    "out:Ef": 28.99,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5502610.261241866,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -4.894194,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.668876,
    "out:Bought CO2": 3.774682,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.116887,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.151011,
    "out:Electricity (inc PV)": 27.211508,
    "out:Total Energy Use Pre PV": 44.151011,
    "out:Total Energy Use Post PV": 33.211508,
    "out:Primary Energy": 54.202551,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 43.041545,
    "out:CO2 Operational/m2": -39.463767,
    "out:Total CO2/m2": 3.577778,
    "out:Total CO2 (tons)": 7.055379,
    "out:Klimatpaverkan": -87.876794,
    "out:Initial Cost/MSEK": 5.667889146700615,
    "out:Running cost/(Apt SEK y)": 3372.882353,
    "out:Running Cost over RSP/MSEK": 0.8983,
    "out:LCP/MSEK": -0.237119,
    "out:ROI% old": 13.688064,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1891.126107,
    "out:Return %": 12,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 28.411511,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17707.594034,
    "out:PV (% sold (El))": 44.062128,
    "out:PV (kWh self-consumed)": 22480.192521,
    "out:PV (ratio sold/self-consumed)": 0.787698,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246865.792269,
    "out:EL kWh savings": -38989.41603,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195448.35,
    "out:DH kr savings": 246865.79226874537,
    "out:El kr savings": -77978.8320599038,
    "out:El kr sold": 26561.391051101396,
    "out:El kr saved": 44960.385042,
    "out:El kr return": 71521.77609310139,
    "out:% solar/total": 153,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 12062.501861,
    "out:% savings (space heating)": 94.409328,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6.71,
    "out:Etvv": 0,
    "out:Ef": 27.21,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5667889.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -41.623671,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.163404,
    "out:Bought CO2": 3.539733,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.492151,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.591525,
    "out:Electricity (inc PV)": 26.782406,
    "out:Total Energy Use Pre PV": 41.591525,
    "out:Total Energy Use Post PV": 30.782406,
    "out:Primary Energy": 52.101892,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 43.041545,
    "out:CO2 Operational/m2": -40.814165,
    "out:Total CO2/m2": 2.22738,
    "out:Total CO2 (tons)": 4.392394,
    "out:Klimatpaverkan": -90.539779,
    "out:Initial Cost/MSEK": 5.717189146700615,
    "out:Running cost/(Apt SEK y)": 2960.911765,
    "out:Running Cost over RSP/MSEK": 0.78892,
    "out:LCP/MSEK": -0.177039,
    "out:ROI% old": 13.843341,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1859.452114,
    "out:Return %": 12,
    "out:Return/kSEK/y": 709,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 27.852025,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17926.851229,
    "out:PV (% sold (El))": 44.60771,
    "out:PV (kWh self-consumed)": 22260.935326,
    "out:PV (ratio sold/self-consumed)": 0.805305,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 250069.771846,
    "out:EL kWh savings": -38143.226837,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200673.6,
    "out:DH kr savings": 250069.77184596527,
    "out:El kr savings": -76286.45367406857,
    "out:El kr sold": 26890.276842945095,
    "out:El kr saved": 44521.870652,
    "out:El kr return": 71412.1474949451,
    "out:% solar/total": 152,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 8858.522284,
    "out:% savings (space heating)": 95.894293,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 5.01,
    "out:Etvv": 0,
    "out:Ef": 26.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5717189.146700615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -42.254101,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.734522,
    "out:Bought CO2": 3.480421,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.518706,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.536278,
    "out:Electricity (inc PV)": 26.655909,
    "out:Total Energy Use Pre PV": 43.536278,
    "out:Total Energy Use Post PV": 32.655909,
    "out:Primary Energy": 52.645391,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 43.708888,
    "out:CO2 Operational/m2": -40.156696,
    "out:Total CO2/m2": 3.552192,
    "out:Total CO2 (tons)": 7.004923,
    "out:Klimatpaverkan": -87.927249,
    "out:Initial Cost/MSEK": 5.827265146700617,
    "out:Running cost/(Apt SEK y)": 3305.117647,
    "out:Running Cost over RSP/MSEK": 0.88048,
    "out:LCP/MSEK": -0.378675,
    "out:ROI% old": 13.357381,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 318.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 1856.327978,
    "out:Return %": 12,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.796778,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 17948.603336,
    "out:PV (% sold (El))": 44.661836,
    "out:PV (kWh self-consumed)": 22239.183219,
    "out:PV (ratio sold/self-consumed)": 0.807071,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248045.405269,
    "out:EL kWh savings": -37893.774739,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199180.76,
    "out:DH kr savings": 248045.40526893802,
    "out:El kr savings": -75787.54947722725,
    "out:El kr sold": 26922.90500406349,
    "out:El kr saved": 44478.366438,
    "out:El kr return": 71401.27144206349,
    "out:% solar/total": 152,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 10882.888861,
    "out:% savings (space heating)": 94.956049,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 26.66,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5827265.146700617,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -42.3166,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -45.79117,
    "out:Bought CO2": 3.47457,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.992935,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.946802,
    "out:Electricity (inc PV)": 26.20329,
    "out:Total Energy Use Pre PV": 40.946802,
    "out:Total Energy Use Post PV": 30.20329,
    "out:Primary Energy": 50.581423,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 43.708888,
    "out:CO2 Operational/m2": -41.546183,
    "out:Total CO2/m2": 2.162705,
    "out:Total CO2 (tons)": 4.264855,
    "out:Klimatpaverkan": -90.667318,
    "out:Initial Cost/MSEK": 5.876565146700617,
    "out:Running cost/(Apt SEK y)": 2889.852941,
    "out:Running Cost over RSP/MSEK": 0.77023,
    "out:LCP/MSEK": -0.317725,
    "out:ROI% old": 13.513336,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 360,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 1823.033339,
    "out:Return %": 12,
    "out:Return/kSEK/y": 711,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 27.207302,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18181.571351,
    "out:PV (% sold (El))": 45.241535,
    "out:PV (kWh self-consumed)": 22006.215204,
    "out:PV (ratio sold/self-consumed)": 0.826202,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 251054.225855,
    "out:EL kWh savings": -37001.210019,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204324.16,
    "out:DH kr savings": 251054.2258548759,
    "out:El kr savings": -74002.42003803012,
    "out:El kr sold": 27272.357026596415,
    "out:El kr saved": 44012.430408,
    "out:El kr return": 71284.78743459642,
    "out:% solar/total": 150,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 7874.068275,
    "out:% savings (space heating)": 96.350564,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 4.39,
    "out:Etvv": 0,
    "out:Ef": 26.2,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5876565.146700617,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -42.986119,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -46.398336,
    "out:Bought CO2": 3.412217,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 99.44,
    "out:Total Energy Use Post PV": 99.44,
    "out:Primary Energy": 85.801362,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.332334,
    "out:CO2 Operational/m2": 34.100668,
    "out:Total CO2/m2": 50.433002,
    "out:Total CO2 (tons)": 99.453886,
    "out:Klimatpaverkan": 4.521713,
    "out:Initial Cost/MSEK": 2.30546999965375,
    "out:Running cost/(Apt SEK y)": 16977.764706,
    "out:Running Cost over RSP/MSEK": 4.51238,
    "out:LCP/MSEK": -0.48878,
    "out:ROI% old": 11.257017,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 39.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 232,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 76542.439009,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 76542.44,
    "out:DH kr savings": 76542.4390088669,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2305469.99965375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 97.44,
    "out:Total Energy Use Post PV": 97.44,
    "out:Primary Energy": 83.517603,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.332334,
    "out:CO2 Operational/m2": 33.3807,
    "out:Total CO2/m2": 49.713034,
    "out:Total CO2 (tons)": 98.034109,
    "out:Klimatpaverkan": 3.101936,
    "out:Initial Cost/MSEK": 2.35476999965375,
    "out:Running cost/(Apt SEK y)": 16627.441176,
    "out:Running Cost over RSP/MSEK": 4.41921,
    "out:LCP/MSEK": -0.44491,
    "out:ROI% old": 11.586573,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 42.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 244,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82332.746824,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82332.75,
    "out:DH kr savings": 82332.74682440072,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2354769.99965375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 96.44,
    "out:Total Energy Use Post PV": 96.44,
    "out:Primary Energy": 83.176362,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.999677,
    "out:CO2 Operational/m2": 33.020716,
    "out:Total CO2/m2": 50.020393,
    "out:Total CO2 (tons)": 98.640221,
    "out:Klimatpaverkan": 3.708048,
    "out:Initial Cost/MSEK": 2.46484599965375,
    "out:Running cost/(Apt SEK y)": 16452.294118,
    "out:Running Cost over RSP/MSEK": 4.37263,
    "out:LCP/MSEK": -0.508406,
    "out:ROI% old": 11.339103,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 83017.501383,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83017.5,
    "out:DH kr savings": 83017.50138318591,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2464845.99965375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.038576,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 93.44,
    "out:Total Energy Use Post PV": 93.44,
    "out:Primary Energy": 80.892603,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.999677,
    "out:CO2 Operational/m2": 31.940764,
    "out:Total CO2/m2": 48.940441,
    "out:Total CO2 (tons)": 96.510555,
    "out:Klimatpaverkan": 1.578383,
    "out:Initial Cost/MSEK": 2.51414599965375,
    "out:Running cost/(Apt SEK y)": 15926.794118,
    "out:Running Cost over RSP/MSEK": 4.23289,
    "out:LCP/MSEK": -0.417966,
    "out:ROI% old": 11.910775,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 11,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88807.809199,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88807.81,
    "out:DH kr savings": 88807.80919871973,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 21.25,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2514145.99965375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 99.44,
    "out:Total Energy Use Post PV": 96.635375,
    "out:Primary Energy": 80.753037,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.594137,
    "out:CO2 Operational/m2": -3.737241,
    "out:Total CO2/m2": 23.856896,
    "out:Total CO2 (tons)": 47.045802,
    "out:Klimatpaverkan": -47.886371,
    "out:Initial Cost/MSEK": 2.67942486765,
    "out:Running cost/(Apt SEK y)": 16009.941176,
    "out:Running Cost over RSP/MSEK": 4.26204,
    "out:LCP/MSEK": -0.612395,
    "out:ROI% old": 11.020649,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 42.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 76542.439009,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109448.64,
    "out:DH kr savings": 76542.4390088669,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2679424.86765,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 97.44,
    "out:Total Energy Use Post PV": 94.635375,
    "out:Primary Energy": 78.469278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.594137,
    "out:CO2 Operational/m2": -4.457209,
    "out:Total CO2/m2": 23.136928,
    "out:Total CO2 (tons)": 45.626025,
    "out:Klimatpaverkan": -49.306148,
    "out:Initial Cost/MSEK": 2.72872486765,
    "out:Running cost/(Apt SEK y)": 15659.617647,
    "out:Running Cost over RSP/MSEK": 4.16888,
    "out:LCP/MSEK": -0.568535,
    "out:ROI% old": 11.309259,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 45.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 82332.746824,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115238.95,
    "out:DH kr savings": 82332.74682440072,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2728724.86765,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 96.44,
    "out:Total Energy Use Post PV": 93.635375,
    "out:Primary Energy": 78.128037,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.26148,
    "out:CO2 Operational/m2": -4.817193,
    "out:Total CO2/m2": 23.444287,
    "out:Total CO2 (tons)": 46.232137,
    "out:Klimatpaverkan": -48.700036,
    "out:Initial Cost/MSEK": 2.8388008676499994,
    "out:Running cost/(Apt SEK y)": 15484.441176,
    "out:Running Cost over RSP/MSEK": 4.12229,
    "out:LCP/MSEK": -0.632021,
    "out:ROI% old": 11.105192,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 283,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83017.501383,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115923.7,
    "out:DH kr savings": 83017.50138318591,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2838800.8676499994,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.038576,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 93.44,
    "out:Total Energy Use Post PV": 90.635375,
    "out:Primary Energy": 75.844278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.26148,
    "out:CO2 Operational/m2": -5.897145,
    "out:Total CO2/m2": 22.364335,
    "out:Total CO2 (tons)": 44.102471,
    "out:Klimatpaverkan": -50.829701,
    "out:Initial Cost/MSEK": 2.8881008676499995,
    "out:Running cost/(Apt SEK y)": 14958.970588,
    "out:Running Cost over RSP/MSEK": 3.98255,
    "out:LCP/MSEK": -0.541581,
    "out:ROI% old": 11.606836,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88807.809199,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121714.01,
    "out:DH kr savings": 88807.80919871973,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 21.25,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2888100.8676499994,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 99.44,
    "out:Total Energy Use Post PV": 96.377795,
    "out:Primary Energy": 80.289393,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.85594,
    "out:CO2 Operational/m2": -54.385766,
    "out:Total CO2/m2": -15.529826,
    "out:Total CO2 (tons)": -30.624819,
    "out:Klimatpaverkan": -125.556991,
    "out:Initial Cost/MSEK": 3.05337975310875,
    "out:Running cost/(Apt SEK y)": 15115.970588,
    "out:Running Cost over RSP/MSEK": 4.03103,
    "out:LCP/MSEK": -0.75534,
    "out:ROI% old": 10.751739,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 296,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 76542.439009,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139843.45,
    "out:DH kr savings": 76542.4390088669,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3053379.75310875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 97.44,
    "out:Total Energy Use Post PV": 94.377795,
    "out:Primary Energy": 78.005634,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.85594,
    "out:CO2 Operational/m2": -55.105734,
    "out:Total CO2/m2": -16.249794,
    "out:Total CO2 (tons)": -32.044596,
    "out:Klimatpaverkan": -126.976768,
    "out:Initial Cost/MSEK": 3.10267975310875,
    "out:Running cost/(Apt SEK y)": 14765.647059,
    "out:Running Cost over RSP/MSEK": 3.93787,
    "out:LCP/MSEK": -0.71148,
    "out:ROI% old": 11.009838,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 82332.746824,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145633.76,
    "out:DH kr savings": 82332.74682440072,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3102679.75310875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 96.44,
    "out:Total Energy Use Post PV": 93.377795,
    "out:Primary Energy": 77.664393,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.523283,
    "out:CO2 Operational/m2": -55.465718,
    "out:Total CO2/m2": -15.942435,
    "out:Total CO2 (tons)": -31.438484,
    "out:Klimatpaverkan": -126.370656,
    "out:Initial Cost/MSEK": 3.2127557531087496,
    "out:Running cost/(Apt SEK y)": 14590.5,
    "out:Running Cost over RSP/MSEK": 3.89128,
    "out:LCP/MSEK": -0.774966,
    "out:ROI% old": 10.839782,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 83017.501383,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146318.52,
    "out:DH kr savings": 83017.50138318591,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3212755.7531087496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.038576,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 93.44,
    "out:Total Energy Use Post PV": 90.377795,
    "out:Primary Energy": 75.380634,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.523283,
    "out:CO2 Operational/m2": -56.54567,
    "out:Total CO2/m2": -17.022387,
    "out:Total CO2 (tons)": -33.568149,
    "out:Klimatpaverkan": -128.500321,
    "out:Initial Cost/MSEK": 3.2620557531087497,
    "out:Running cost/(Apt SEK y)": 14065,
    "out:Running Cost over RSP/MSEK": 3.75154,
    "out:LCP/MSEK": -0.684526,
    "out:ROI% old": 11.28793,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 88807.809199,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152108.82,
    "out:DH kr savings": 88807.80919871973,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 21.25,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3262055.7531087496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 95.44,
    "out:Total Energy Use Post PV": 95.44,
    "out:Primary Energy": 82.301362,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.332334,
    "out:CO2 Operational/m2": 32.660732,
    "out:Total CO2/m2": 48.993066,
    "out:Total CO2 (tons)": 96.614332,
    "out:Klimatpaverkan": 1.682159,
    "out:Initial Cost/MSEK": 2.39520662465375,
    "out:Running cost/(Apt SEK y)": 16277.117647,
    "out:Running Cost over RSP/MSEK": 4.32605,
    "out:LCP/MSEK": -0.392187,
    "out:ROI% old": 11.946598,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 45.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 11,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85175.855508,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85175.86,
    "out:DH kr savings": 85175.8555079589,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2395206.62465375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 92.44,
    "out:Total Energy Use Post PV": 92.44,
    "out:Primary Energy": 80.017603,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.332334,
    "out:CO2 Operational/m2": 31.58078,
    "out:Total CO2/m2": 47.913114,
    "out:Total CO2 (tons)": 94.484666,
    "out:Klimatpaverkan": -0.447506,
    "out:Initial Cost/MSEK": 2.44450662465375,
    "out:Running cost/(Apt SEK y)": 15751.647059,
    "out:Running Cost over RSP/MSEK": 4.1863,
    "out:LCP/MSEK": -0.301737,
    "out:ROI% old": 12.522363,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 11,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90966.163323,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 90966.16,
    "out:DH kr savings": 90966.16332349271,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 20,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2444506.62465375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 92.44,
    "out:Total Energy Use Post PV": 92.44,
    "out:Primary Energy": 80.201362,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.999677,
    "out:CO2 Operational/m2": 31.58078,
    "out:Total CO2/m2": 48.580457,
    "out:Total CO2 (tons)": 95.800667,
    "out:Klimatpaverkan": 0.868494,
    "out:Initial Cost/MSEK": 2.55458262465375,
    "out:Running cost/(Apt SEK y)": 15751.647059,
    "out:Running Cost over RSP/MSEK": 4.1863,
    "out:LCP/MSEK": -0.411813,
    "out:ROI% old": 11.982779,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 11,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90355.905407,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90355.91,
    "out:DH kr savings": 90355.90540741412,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2554582.62465375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 7.44,
    "out:Total Energy Use Pre PV": 90.44,
    "out:Total Energy Use Post PV": 90.44,
    "out:Primary Energy": 77.917603,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.999677,
    "out:CO2 Operational/m2": 30.860812,
    "out:Total CO2/m2": 47.860489,
    "out:Total CO2 (tons)": 94.38089,
    "out:Klimatpaverkan": -0.551282,
    "out:Initial Cost/MSEK": 2.60388262465375,
    "out:Running cost/(Apt SEK y)": 15401.323529,
    "out:Running Cost over RSP/MSEK": 4.09314,
    "out:LCP/MSEK": -0.367953,
    "out:ROI% old": 12.267011,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 520.8,
    "out:Return %": 11,
    "out:Return/kSEK/y": 286,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 96146.213223,
    "out:EL kWh savings": 5.2545e-12,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96146.21,
    "out:DH kr savings": 96146.21322294792,
    "out:El kr savings": 1.050892766940592e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 17,
    "out:Ef": 7.44,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2603882.62465375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": 0.98214,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 0.98214,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 95.44,
    "out:Total Energy Use Post PV": 92.635375,
    "out:Primary Energy": 77.253037,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.594137,
    "out:CO2 Operational/m2": -5.177177,
    "out:Total CO2/m2": 22.41696,
    "out:Total CO2 (tons)": 44.206248,
    "out:Klimatpaverkan": -50.725925,
    "out:Initial Cost/MSEK": 2.7691614926499994,
    "out:Running cost/(Apt SEK y)": 15309.294118,
    "out:Running Cost over RSP/MSEK": 4.07571,
    "out:LCP/MSEK": -0.515801,
    "out:ROI% old": 11.624767,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 289,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85175.855508,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118082.06,
    "out:DH kr savings": 85175.8555079589,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2769161.4926499994,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 92.44,
    "out:Total Energy Use Post PV": 89.635375,
    "out:Primary Energy": 74.969278,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.594137,
    "out:CO2 Operational/m2": -6.257129,
    "out:Total CO2/m2": 21.337008,
    "out:Total CO2 (tons)": 42.076582,
    "out:Klimatpaverkan": -52.85559,
    "out:Initial Cost/MSEK": 2.8184614926499996,
    "out:Running cost/(Apt SEK y)": 14783.823529,
    "out:Running Cost over RSP/MSEK": 3.93597,
    "out:LCP/MSEK": -0.425361,
    "out:ROI% old": 12.129718,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 11,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90966.163323,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123872.37,
    "out:DH kr savings": 90966.16332349271,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 20,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2818461.4926499994,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 92.44,
    "out:Total Energy Use Post PV": 89.635375,
    "out:Primary Energy": 75.153037,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.26148,
    "out:CO2 Operational/m2": -6.257129,
    "out:Total CO2/m2": 22.004351,
    "out:Total CO2 (tons)": 43.392583,
    "out:Klimatpaverkan": -51.53959,
    "out:Initial Cost/MSEK": 2.9285374926499994,
    "out:Running cost/(Apt SEK y)": 14783.823529,
    "out:Running Cost over RSP/MSEK": 3.93597,
    "out:LCP/MSEK": -0.535437,
    "out:ROI% old": 11.673794,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 10,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90355.905407,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123262.11,
    "out:DH kr savings": 90355.90540741412,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2928537.4926499994,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.635375,
    "out:Total Energy Use Pre PV": 90.44,
    "out:Total Energy Use Post PV": 87.635375,
    "out:Primary Energy": 72.869278,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.26148,
    "out:CO2 Operational/m2": -6.977097,
    "out:Total CO2/m2": 21.284383,
    "out:Total CO2 (tons)": 41.972806,
    "out:Klimatpaverkan": -52.959367,
    "out:Initial Cost/MSEK": 2.9778374926499995,
    "out:Running cost/(Apt SEK y)": 14433.5,
    "out:Running Cost over RSP/MSEK": 3.84281,
    "out:LCP/MSEK": -0.491577,
    "out:ROI% old": 11.927447,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 56.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 326.918899,
    "out:Return %": 11,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 14563.173375,
    "out:PV (% sold (El))": 72.475618,
    "out:PV (kWh self-consumed)": 5530.719903,
    "out:PV (ratio sold/self-consumed)": 2.633142,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 96146.213223,
    "out:EL kWh savings": 5530.72082,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129052.41,
    "out:DH kr savings": 96146.21322294792,
    "out:El kr savings": 11061.441639454515,
    "out:El kr sold": 21844.760061778656,
    "out:El kr saved": 11061.439806,
    "out:El kr return": 32906.199867778654,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 17,
    "out:Ef": 4.64,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2977837.4926499994,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -36.855769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -37.468764,
    "out:Bought CO2": 0.612995,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 95.44,
    "out:Total Energy Use Post PV": 92.377795,
    "out:Primary Energy": 76.789393,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.85594,
    "out:CO2 Operational/m2": -55.825702,
    "out:Total CO2/m2": -16.969762,
    "out:Total CO2 (tons)": -33.464373,
    "out:Klimatpaverkan": -128.396545,
    "out:Initial Cost/MSEK": 3.1431163781087497,
    "out:Running cost/(Apt SEK y)": 14415.323529,
    "out:Running Cost over RSP/MSEK": 3.8447,
    "out:LCP/MSEK": -0.658746,
    "out:ROI% old": 11.29166,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85175.855508,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148476.87,
    "out:DH kr savings": 85175.8555079589,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3143116.3781087496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 92.44,
    "out:Total Energy Use Post PV": 89.377795,
    "out:Primary Energy": 74.505634,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.85594,
    "out:CO2 Operational/m2": -56.905654,
    "out:Total CO2/m2": -18.049714,
    "out:Total CO2 (tons)": -35.594038,
    "out:Klimatpaverkan": -130.52621,
    "out:Initial Cost/MSEK": 3.19241637810875,
    "out:Running cost/(Apt SEK y)": 13889.852941,
    "out:Running Cost over RSP/MSEK": 3.70496,
    "out:LCP/MSEK": -0.568306,
    "out:ROI% old": 11.742605,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 11,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 90966.163323,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154267.18,
    "out:DH kr savings": 90966.16332349271,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 20,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3192416.3781087496,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.597751,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 92.44,
    "out:Total Energy Use Post PV": 89.377795,
    "out:Primary Energy": 74.689393,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.523283,
    "out:CO2 Operational/m2": -56.905654,
    "out:Total CO2/m2": -17.382371,
    "out:Total CO2 (tons)": -34.278038,
    "out:Klimatpaverkan": -129.21021,
    "out:Initial Cost/MSEK": 3.302492378108749,
    "out:Running cost/(Apt SEK y)": 13889.852941,
    "out:Running Cost over RSP/MSEK": 3.70496,
    "out:LCP/MSEK": -0.678382,
    "out:ROI% old": 11.35121,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 90355.905407,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153656.92,
    "out:DH kr savings": 90355.90540741412,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 139218.772626,
    "out:% savings (space heating)": 35.475533,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.6,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3302492.378108749,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.59864,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.661489,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 7.44,
    "out:Electricity (inc PV)": 4.377795,
    "out:Total Energy Use Pre PV": 90.44,
    "out:Total Energy Use Post PV": 87.377795,
    "out:Primary Energy": 72.405634,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.523283,
    "out:CO2 Operational/m2": -57.625622,
    "out:Total CO2/m2": -18.102339,
    "out:Total CO2 (tons)": -35.697815,
    "out:Klimatpaverkan": -130.629987,
    "out:Initial Cost/MSEK": 3.3517923781087493,
    "out:Running cost/(Apt SEK y)": 13539.529412,
    "out:Running Cost over RSP/MSEK": 3.61179,
    "out:LCP/MSEK": -0.634512,
    "out:ROI% old": 11.581352,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 307.994161,
    "out:Return %": 10,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 34149.118331,
    "out:PV (% sold (El))": 84.973872,
    "out:PV (kWh self-consumed)": 6038.668224,
    "out:PV (ratio sold/self-consumed)": 5.655074,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 96146.213223,
    "out:EL kWh savings": 6038.668609,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159447.23,
    "out:DH kr savings": 96146.21322294792,
    "out:El kr savings": 12077.337218182716,
    "out:El kr sold": 51223.6774961371,
    "out:El kr saved": 12077.336448,
    "out:El kr return": 63301.0139441371,
    "out:% solar/total": 41,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 133428.46481,
    "out:% savings (space heating)": 38.159197,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.66,
    "out:Etvv": 17,
    "out:Ef": 4.38,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3351792.378108749,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -87.504294,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -88.082877,
    "out:Bought CO2": 0.578583,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.2582,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.293909,
    "out:Electricity (inc PV)": 28.730485,
    "out:Total Energy Use Pre PV": 59.293909,
    "out:Total Energy Use Post PV": 59.730485,
    "out:Primary Energy": 77.325151,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.162835,
    "out:CO2 Operational/m2": 14.894526,
    "out:Total CO2/m2": 36.057361,
    "out:Total CO2 (tons)": 71.10512,
    "out:Klimatpaverkan": -23.827052,
    "out:Initial Cost/MSEK": 4.156462767899366,
    "out:Running cost/(Apt SEK y)": 8712.058824,
    "out:Running Cost over RSP/MSEK": 2.30673,
    "out:LCP/MSEK": -0.134123,
    "out:ROI% old": 13.824736,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 1980.57363,
    "out:Return %": 12,
    "out:Return/kSEK/y": 513,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.554409,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 197287.120167,
    "out:EL kWh savings": -41984.838847,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113317.44,
    "out:DH kr savings": 197287.1201666357,
    "out:El kr savings": -83969.67769423057,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 61641.173963,
    "out:% savings (space heating)": 71.430836,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 32.93,
    "out:Etvv": 0,
    "out:Ef": 28.73,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4156462.7678993656,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": 3.735022,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.735022,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.58944,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.212848,
    "out:Electricity (inc PV)": 28.657025,
    "out:Total Energy Use Pre PV": 57.212848,
    "out:Total Energy Use Post PV": 57.657025,
    "out:Primary Energy": 75.097711,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.162835,
    "out:CO2 Operational/m2": 14.163858,
    "out:Total CO2/m2": 35.326693,
    "out:Total CO2 (tons)": 69.664243,
    "out:Klimatpaverkan": -25.26793,
    "out:Initial Cost/MSEK": 4.205762767899366,
    "out:Running cost/(Apt SEK y)": 8352.323529,
    "out:Running Cost over RSP/MSEK": 2.21109,
    "out:LCP/MSEK": -0.087783,
    "out:ROI% old": 13.987543,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 1974.89936,
    "out:Return %": 12,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.473348,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 202549.915191,
    "out:EL kWh savings": -41839.975719,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118869.96,
    "out:DH kr savings": 202549.91519087434,
    "out:El kr savings": -83679.95143748174,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 56378.378939,
    "out:% savings (space heating)": 73.870012,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.23,
    "out:Etvv": 0,
    "out:Ef": 28.66,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4205762.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": 3.724322,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.724322,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.530938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.822322,
    "out:Electricity (inc PV)": 28.202831,
    "out:Total Energy Use Pre PV": 57.822322,
    "out:Total Energy Use Post PV": 58.202831,
    "out:Primary Energy": 74.813018,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.830178,
    "out:CO2 Operational/m2": 14.472289,
    "out:Total CO2/m2": 36.302467,
    "out:Total CO2 (tons)": 71.588469,
    "out:Klimatpaverkan": -23.343703,
    "out:Initial Cost/MSEK": 4.315838767899366,
    "out:Running cost/(Apt SEK y)": 8482.176471,
    "out:Running Cost over RSP/MSEK": 2.24577,
    "out:LCP/MSEK": -0.232539,
    "out:ROI% old": 13.515995,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 137.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 30,
    "out:El bought/kWh/m2": 1947.56254,
    "out:Return %": 12,
    "out:Return/kSEK/y": 521,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.082822,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200693.281028,
    "out:EL kWh savings": -40944.305099,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118804.67,
    "out:DH kr savings": 200693.2810275436,
    "out:El kr savings": -81888.61019792547,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 58235.013103,
    "out:% savings (space heating)": 73.009508,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.92,
    "out:Etvv": 0,
    "out:Ef": 28.2,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4315838.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.79952,
    "out:EL CO2": 3.672769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.672769,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.890653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.732632,
    "out:Electricity (inc PV)": 28.12025,
    "out:Total Energy Use Pre PV": 54.732632,
    "out:Total Energy Use Post PV": 55.12025,
    "out:Primary Energy": 72.592574,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.830178,
    "out:CO2 Operational/m2": 13.380497,
    "out:Total CO2/m2": 35.210675,
    "out:Total CO2 (tons)": 69.435455,
    "out:Klimatpaverkan": -25.496717,
    "out:Initial Cost/MSEK": 4.365138767899366,
    "out:Running cost/(Apt SEK y)": 7946.294118,
    "out:Running Cost over RSP/MSEK": 2.10329,
    "out:LCP/MSEK": -0.139359,
    "out:ROI% old": 13.829637,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 150.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 1941.28424,
    "out:Return %": 12,
    "out:Return/kSEK/y": 539,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 17.993132,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205899.923349,
    "out:EL kWh savings": -40781.455358,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124337.01,
    "out:DH kr savings": 205899.92334853605,
    "out:El kr savings": -81562.910715097,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 53028.370782,
    "out:% savings (space heating)": 75.422658,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 28.26,
    "out:Etvv": 0,
    "out:Ef": 28.12,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4365138.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.719568,
    "out:EL CO2": 3.660929,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.660929,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.2582,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.293909,
    "out:Electricity (inc PV)": 21.366905,
    "out:Total Energy Use Pre PV": 59.293909,
    "out:Total Energy Use Post PV": 52.366905,
    "out:Primary Energy": 64.070707,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.424638,
    "out:CO2 Operational/m2": -0.452762,
    "out:Total CO2/m2": 31.971876,
    "out:Total CO2 (tons)": 63.048543,
    "out:Klimatpaverkan": -31.883629,
    "out:Initial Cost/MSEK": 4.530417635895615,
    "out:Running cost/(Apt SEK y)": 7613.911765,
    "out:Running Cost over RSP/MSEK": 2.02248,
    "out:LCP/MSEK": -0.223828,
    "out:ROI% old": 13.579921,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 165.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 1484.419984,
    "out:Return %": 12,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.554409,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5702.704727,
    "out:PV (% sold (El))": 28.380288,
    "out:PV (kWh self-consumed)": 14391.188551,
    "out:PV (ratio sold/self-consumed)": 0.396264,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 197287.120167,
    "out:EL kWh savings": -27463.858248,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150913.46,
    "out:DH kr savings": 197287.1201666357,
    "out:El kr savings": -54927.716495334345,
    "out:El kr sold": 8554.057089958764,
    "out:El kr saved": 28782.377102,
    "out:El kr return": 37336.43419195876,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 61641.173963,
    "out:% savings (space heating)": 71.430836,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 32.93,
    "out:Etvv": 0,
    "out:Ef": 21.37,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4530417.635895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": -11.612266,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.390429,
    "out:Bought CO2": 2.778163,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.58944,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.212848,
    "out:Electricity (inc PV)": 21.304465,
    "out:Total Energy Use Pre PV": 57.212848,
    "out:Total Energy Use Post PV": 50.304465,
    "out:Primary Energy": 61.863103,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.424638,
    "out:CO2 Operational/m2": -1.244648,
    "out:Total CO2/m2": 31.17999,
    "out:Total CO2 (tons)": 61.486944,
    "out:Klimatpaverkan": -33.445228,
    "out:Initial Cost/MSEK": 4.579717635895616,
    "out:Running cost/(Apt SEK y)": 7254.558824,
    "out:Running Cost over RSP/MSEK": 1.92693,
    "out:LCP/MSEK": -0.177578,
    "out:ROI% old": 13.731789,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 176,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 1479.545159,
    "out:Return %": 12,
    "out:Return/kSEK/y": 563,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.473348,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5726.959904,
    "out:PV (% sold (El))": 28.500997,
    "out:PV (kWh self-consumed)": 14366.933373,
    "out:PV (ratio sold/self-consumed)": 0.398621,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 202549.915191,
    "out:EL kWh savings": -27340.726561,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156458.9,
    "out:DH kr savings": 202549.91519087434,
    "out:El kr savings": -54681.45312109801,
    "out:El kr sold": 8590.439856665633,
    "out:El kr saved": 28733.866746,
    "out:El kr return": 37324.30660266563,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 56378.378939,
    "out:% savings (space heating)": 73.870012,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.23,
    "out:Etvv": 0,
    "out:Ef": 21.3,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4579717.635895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": -11.684184,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.453246,
    "out:Bought CO2": 2.769062,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.530938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.822322,
    "out:Electricity (inc PV)": 20.918889,
    "out:Total Energy Use Pre PV": 57.822322,
    "out:Total Energy Use Post PV": 50.918889,
    "out:Primary Energy": 61.701922,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.091981,
    "out:CO2 Operational/m2": -1.233421,
    "out:Total CO2/m2": 31.85856,
    "out:Total CO2 (tons)": 62.825084,
    "out:Klimatpaverkan": -32.107088,
    "out:Initial Cost/MSEK": 4.689793635895615,
    "out:Running cost/(Apt SEK y)": 7386.147059,
    "out:Running Cost over RSP/MSEK": 1.96206,
    "out:LCP/MSEK": -0.322784,
    "out:ROI% old": 13.302474,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 170.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 30,
    "out:El bought/kWh/m2": 1456.09524,
    "out:Return %": 12,
    "out:Return/kSEK/y": 558,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.082822,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5844.536923,
    "out:PV (% sold (El))": 29.086135,
    "out:PV (kWh self-consumed)": 14249.356354,
    "out:PV (ratio sold/self-consumed)": 0.410161,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200693.281028,
    "out:EL kWh savings": -26580.370645,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156299.35,
    "out:DH kr savings": 200693.2810275436,
    "out:El kr savings": -53160.741289186655,
    "out:El kr sold": 8766.805384947278,
    "out:El kr saved": 28498.712708,
    "out:El kr return": 37265.51809294728,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 58235.013103,
    "out:% savings (space heating)": 73.009508,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.92,
    "out:Etvv": 0,
    "out:Ef": 20.92,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4689793.635895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.79952,
    "out:EL CO2": -12.032941,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.758218,
    "out:Bought CO2": 2.725277,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.890653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.732632,
    "out:Electricity (inc PV)": 20.848851,
    "out:Total Energy Use Pre PV": 54.732632,
    "out:Total Energy Use Post PV": 47.848851,
    "out:Primary Energy": 59.504056,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.091981,
    "out:CO2 Operational/m2": -2.394027,
    "out:Total CO2/m2": 30.697954,
    "out:Total CO2 (tons)": 60.536369,
    "out:Klimatpaverkan": -34.395803,
    "out:Initial Cost/MSEK": 4.739093635895616,
    "out:Running cost/(Apt SEK y)": 6850.676471,
    "out:Running Cost over RSP/MSEK": 1.81968,
    "out:LCP/MSEK": -0.229704,
    "out:ROI% old": 13.593286,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 187.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 1450.718014,
    "out:Return %": 12,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 17.993132,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5871.813346,
    "out:PV (% sold (El))": 29.22188,
    "out:PV (kWh self-consumed)": 14222.079932,
    "out:PV (ratio sold/self-consumed)": 0.412866,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205899.923349,
    "out:EL kWh savings": -26442.255701,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161823.13,
    "out:DH kr savings": 205899.92334853605,
    "out:El kr savings": -52884.511401218006,
    "out:El kr sold": 8807.720018746675,
    "out:El kr saved": 28444.159864,
    "out:El kr return": 37251.879882746674,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 53028.370782,
    "out:% savings (space heating)": 75.422658,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 28.26,
    "out:Etvv": 0,
    "out:Ef": 20.85,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4739093.635895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.719568,
    "out:EL CO2": -12.113595,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.82883,
    "out:Bought CO2": 2.715235,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.2582,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.293909,
    "out:Electricity (inc PV)": 19.362076,
    "out:Total Energy Use Pre PV": 59.293909,
    "out:Total Energy Use Post PV": 50.362076,
    "out:Primary Energy": 60.462015,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.686441,
    "out:CO2 Operational/m2": -42.441922,
    "out:Total CO2/m2": 1.244519,
    "out:Total CO2 (tons)": 2.454192,
    "out:Klimatpaverkan": -92.477981,
    "out:Initial Cost/MSEK": 4.904372521354366,
    "out:Running cost/(Apt SEK y)": 6670.382353,
    "out:Running Cost over RSP/MSEK": 1.7784,
    "out:LCP/MSEK": -0.353703,
    "out:ROI% old": 13.255431,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 176,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 1344.502744,
    "out:Return %": 12,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.554409,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21917.035685,
    "out:PV (% sold (El))": 54.536558,
    "out:PV (kWh self-consumed)": 18270.75087,
    "out:PV (ratio sold/self-consumed)": 1.19957,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 197287.120167,
    "out:EL kWh savings": -23510.335231,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183142,
    "out:DH kr savings": 197287.1201666357,
    "out:El kr savings": -47020.67046223333,
    "out:El kr sold": 32875.5535278616,
    "out:El kr saved": 36541.50174,
    "out:El kr return": 69417.05526786161,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 61641.173963,
    "out:% savings (space heating)": 71.430836,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 32.93,
    "out:Etvv": 0,
    "out:Ef": 19.36,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4904372.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": -53.601426,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.118941,
    "out:Bought CO2": 2.517515,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.58944,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 28.212848,
    "out:Electricity (inc PV)": 19.305932,
    "out:Total Energy Use Pre PV": 57.212848,
    "out:Total Energy Use Post PV": 48.305932,
    "out:Primary Energy": 58.265744,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.686441,
    "out:CO2 Operational/m2": -43.269149,
    "out:Total CO2/m2": 0.417292,
    "out:Total CO2 (tons)": 0.8229,
    "out:Klimatpaverkan": -94.109272,
    "out:Initial Cost/MSEK": 4.953672521354366,
    "out:Running cost/(Apt SEK y)": 6311.205882,
    "out:Running Cost over RSP/MSEK": 1.68291,
    "out:LCP/MSEK": -0.307513,
    "out:ROI% old": 13.39889,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 187.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 1340.127447,
    "out:Return %": 12,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 18.473348,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 21955.150716,
    "out:PV (% sold (El))": 54.631401,
    "out:PV (kWh self-consumed)": 18232.635839,
    "out:PV (ratio sold/self-consumed)": 1.204168,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 202549.915191,
    "out:EL kWh savings": -23399.619257,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188683.4,
    "out:DH kr savings": 202549.91519087434,
    "out:El kr savings": -46799.23851343246,
    "out:El kr sold": 32932.72607381221,
    "out:El kr saved": 36465.271678,
    "out:El kr return": 69397.99775181222,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 56378.378939,
    "out:% savings (space heating)": 73.870012,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.23,
    "out:Etvv": 0,
    "out:Ef": 19.31,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4953672.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": -53.708685,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.218022,
    "out:Bought CO2": 2.509337,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.530938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.822322,
    "out:Electricity (inc PV)": 18.959465,
    "out:Total Energy Use Pre PV": 57.822322,
    "out:Total Energy Use Post PV": 48.959465,
    "out:Primary Energy": 58.174959,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.353784,
    "out:CO2 Operational/m2": -43.428424,
    "out:Total CO2/m2": 0.92536,
    "out:Total CO2 (tons)": 1.82481,
    "out:Klimatpaverkan": -93.107362,
    "out:Initial Cost/MSEK": 5.063748521354366,
    "out:Running cost/(Apt SEK y)": 6443.794118,
    "out:Running Cost over RSP/MSEK": 1.71829,
    "out:LCP/MSEK": -0.452969,
    "out:ROI% old": 13.007811,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 181.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 30,
    "out:El bought/kWh/m2": 1319.079949,
    "out:Return %": 12,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 18.082822,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22139.797094,
    "out:PV (% sold (El))": 55.09086,
    "out:PV (kWh self-consumed)": 18047.989461,
    "out:PV (ratio sold/self-consumed)": 1.226718,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200693.281028,
    "out:EL kWh savings": -22716.386293,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188470.2,
    "out:DH kr savings": 200693.2810275436,
    "out:El kr savings": -45432.77258643805,
    "out:El kr sold": 33209.69564125271,
    "out:El kr saved": 36095.978922,
    "out:El kr return": 69305.67456325272,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 58235.013103,
    "out:% savings (space heating)": 73.009508,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.92,
    "out:Etvv": 0,
    "out:Ef": 18.96,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5063748.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.79952,
    "out:EL CO2": -54.227944,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.697951,
    "out:Bought CO2": 2.470007,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.890653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.732632,
    "out:Electricity (inc PV)": 18.896616,
    "out:Total Energy Use Pre PV": 54.732632,
    "out:Total Energy Use Post PV": 45.896616,
    "out:Primary Energy": 55.990033,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.353784,
    "out:CO2 Operational/m2": -44.628258,
    "out:Total CO2/m2": -0.274474,
    "out:Total CO2 (tons)": -0.541263,
    "out:Klimatpaverkan": -95.473435,
    "out:Initial Cost/MSEK": 5.113048521354366,
    "out:Running cost/(Apt SEK y)": 5908.529412,
    "out:Running Cost over RSP/MSEK": 1.57597,
    "out:LCP/MSEK": -0.359949,
    "out:ROI% old": 13.280028,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 200,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 1314.253746,
    "out:Return %": 12,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 17.993132,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22182.497746,
    "out:PV (% sold (El))": 55.197112,
    "out:PV (kWh self-consumed)": 18005.288809,
    "out:PV (ratio sold/self-consumed)": 1.231999,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205899.923349,
    "out:EL kWh savings": -22592.448058,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193988.77,
    "out:DH kr savings": 205899.92334853605,
    "out:El kr savings": -45184.89611610847,
    "out:El kr sold": 33273.74661871567,
    "out:El kr saved": 36010.577618,
    "out:El kr return": 69284.32423671568,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 53028.370782,
    "out:% savings (space heating)": 75.422658,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 28.26,
    "out:Etvv": 0,
    "out:Ef": 18.9,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5113048.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.719568,
    "out:EL CO2": -54.347826,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.808816,
    "out:Bought CO2": 2.46099,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.963299,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.662667,
    "out:Electricity (inc PV)": 28.023734,
    "out:Total Energy Use Pre PV": 56.662667,
    "out:Total Energy Use Post PV": 57.023734,
    "out:Primary Energy": 73.978105,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.162835,
    "out:CO2 Operational/m2": 14.091229,
    "out:Total CO2/m2": 35.254064,
    "out:Total CO2 (tons)": 69.521018,
    "out:Klimatpaverkan": -25.411154,
    "out:Initial Cost/MSEK": 4.246199392899366,
    "out:Running cost/(Apt SEK y)": 8288.5,
    "out:Running Cost over RSP/MSEK": 2.19432,
    "out:LCP/MSEK": -0.111449,
    "out:ROI% old": 13.910759,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 142.1,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 1936.38669,
    "out:Return %": 12,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 17.923167,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201812.6652,
    "out:EL kWh savings": -40591.125795,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120630.41,
    "out:DH kr savings": 201812.66520025439,
    "out:El kr savings": -81182.25158909136,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 57115.62893,
    "out:% savings (space heating)": 73.528315,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.26,
    "out:Etvv": 0,
    "out:Ef": 28.02,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4246199.392899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": 3.651693,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.651693,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.333226,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.569883,
    "out:Electricity (inc PV)": 27.937945,
    "out:Total Energy Use Pre PV": 53.569883,
    "out:Total Energy Use Post PV": 53.937945,
    "out:Primary Energy": 71.760117,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.162835,
    "out:CO2 Operational/m2": 12.999029,
    "out:Total CO2/m2": 34.161864,
    "out:Total CO2 (tons)": 67.3672,
    "out:Klimatpaverkan": -27.564973,
    "out:Initial Cost/MSEK": 4.295499392899366,
    "out:Running cost/(Apt SEK y)": 7752.264706,
    "out:Running Cost over RSP/MSEK": 2.05174,
    "out:LCP/MSEK": -0.018169,
    "out:ROI% old": 14.225288,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 155.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1929.89181,
    "out:Return %": 13,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 17.830383,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 206999.169456,
    "out:EL kWh savings": -40421.949877,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126155.27,
    "out:DH kr savings": 206999.16945608275,
    "out:El kr savings": -80843.89975353146,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 51929.124674,
    "out:% savings (space heating)": 75.932132,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 27.61,
    "out:Etvv": 0,
    "out:Ef": 27.94,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4295499.392899366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": 3.639445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.639445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.618239,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.274261,
    "out:Electricity (inc PV)": 27.587511,
    "out:Total Energy Use Pre PV": 55.274261,
    "out:Total Energy Use Post PV": 55.587511,
    "out:Primary Energy": 71.979209,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.830178,
    "out:CO2 Operational/m2": 13.679973,
    "out:Total CO2/m2": 35.510151,
    "out:Total CO2 (tons)": 70.026022,
    "out:Klimatpaverkan": -24.90615,
    "out:Initial Cost/MSEK": 4.405575392899365,
    "out:Running cost/(Apt SEK y)": 8068.294118,
    "out:Running Cost over RSP/MSEK": 2.13589,
    "out:LCP/MSEK": -0.212395,
    "out:ROI% old": 13.596992,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 146.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1909.19827,
    "out:Return %": 12,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.534761,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 204465.123674,
    "out:EL kWh savings": -39730.893989,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125003.34,
    "out:DH kr savings": 204465.12367359124,
    "out:El kr savings": -79461.78797763253,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 54463.170456,
    "out:% savings (space heating)": 74.757664,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 28.7,
    "out:Etvv": 0,
    "out:Ef": 27.59,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4405575.392899365,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": 3.600421,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.600421,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.016344,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.172938,
    "out:Electricity (inc PV)": 27.493316,
    "out:Total Energy Use Pre PV": 52.172938,
    "out:Total Energy Use Post PV": 52.493316,
    "out:Primary Energy": 69.767665,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.830178,
    "out:CO2 Operational/m2": 12.586645,
    "out:Total CO2/m2": 34.416823,
    "out:Total CO2 (tons)": 67.869979,
    "out:Klimatpaverkan": -27.062193,
    "out:Initial Cost/MSEK": 4.454875392899365,
    "out:Running cost/(Apt SEK y)": 7531.058824,
    "out:Running Cost over RSP/MSEK": 1.99306,
    "out:LCP/MSEK": -0.118865,
    "out:ROI% old": 13.904542,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 165.4,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1902.10566,
    "out:Return %": 12,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.433438,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 209596.06091,
    "out:EL kWh savings": -39545.141438,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130505.78,
    "out:DH kr savings": 209596.06091020728,
    "out:El kr savings": -79090.28287615601,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 49332.23322,
    "out:% savings (space heating)": 77.135727,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 26.07,
    "out:Etvv": 0,
    "out:Ef": 27.49,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4454875.392899365,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": 3.587045,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 3.587045,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.963299,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.662667,
    "out:Electricity (inc PV)": 20.767037,
    "out:Total Energy Use Pre PV": 56.662667,
    "out:Total Energy Use Post PV": 49.767037,
    "out:Primary Energy": 60.916051,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.424638,
    "out:CO2 Operational/m2": -1.737108,
    "out:Total CO2/m2": 30.68753,
    "out:Total CO2 (tons)": 60.515813,
    "out:Klimatpaverkan": -34.41636,
    "out:Initial Cost/MSEK": 4.620154260895616,
    "out:Running cost/(Apt SEK y)": 7193.176471,
    "out:Running Cost over RSP/MSEK": 1.91079,
    "out:LCP/MSEK": -0.201874,
    "out:ROI% old": 13.66151,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 176,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 1446.52566,
    "out:Return %": 12,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 17.923167,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5893.131848,
    "out:PV (% sold (El))": 29.327974,
    "out:PV (kWh self-consumed)": 14200.761429,
    "out:PV (ratio sold/self-consumed)": 0.414987,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201812.6652,
    "out:EL kWh savings": -26280.918483,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158090.53,
    "out:DH kr savings": 201812.66520025439,
    "out:El kr savings": -52561.836966564435,
    "out:El kr sold": 8839.697772624544,
    "out:El kr saved": 28401.522858,
    "out:El kr return": 37241.22063062454,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 57115.62893,
    "out:% savings (space heating)": 73.528315,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.26,
    "out:Etvv": 0,
    "out:Ef": 20.77,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4620154.260895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": -12.176644,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.88405,
    "out:Bought CO2": 2.707406,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.333226,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.569883,
    "out:Electricity (inc PV)": 20.694353,
    "out:Total Energy Use Pre PV": 53.569883,
    "out:Total Energy Use Post PV": 46.694353,
    "out:Primary Energy": 58.721651,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.424638,
    "out:CO2 Operational/m2": -2.900854,
    "out:Total CO2/m2": 29.523784,
    "out:Total CO2 (tons)": 58.220905,
    "out:Klimatpaverkan": -36.711267,
    "out:Initial Cost/MSEK": 4.669454260895615,
    "out:Running cost/(Apt SEK y)": 6657.352941,
    "out:Running Cost over RSP/MSEK": 1.76832,
    "out:LCP/MSEK": -0.108704,
    "out:ROI% old": 13.953145,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 193.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1440.968883,
    "out:Return %": 12,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 17.830383,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 5921.454173,
    "out:PV (% sold (El))": 29.468924,
    "out:PV (kWh self-consumed)": 14172.439104,
    "out:PV (ratio sold/self-consumed)": 0.417815,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 206999.169456,
    "out:EL kWh savings": -26137.585627,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163606.18,
    "out:DH kr savings": 206999.16945608275,
    "out:El kr savings": -52275.17125399245,
    "out:El kr sold": 8882.18126001239,
    "out:El kr saved": 28344.878208,
    "out:El kr return": 37227.05946801239,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 51929.124674,
    "out:% savings (space heating)": 75.932132,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 27.61,
    "out:Etvv": 0,
    "out:Ef": 20.69,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4669454.260895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": -12.260438,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -14.957465,
    "out:Bought CO2": 2.697027,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.618239,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.274261,
    "out:Electricity (inc PV)": 20.397945,
    "out:Total Energy Use Pre PV": 55.274261,
    "out:Total Energy Use Post PV": 48.397945,
    "out:Primary Energy": 59.03799,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.091981,
    "out:CO2 Operational/m2": -2.449079,
    "out:Total CO2/m2": 30.642902,
    "out:Total CO2 (tons)": 60.427806,
    "out:Klimatpaverkan": -34.504366,
    "out:Initial Cost/MSEK": 4.779530260895616,
    "out:Running cost/(Apt SEK y)": 6974.705882,
    "out:Running Cost over RSP/MSEK": 1.85282,
    "out:LCP/MSEK": -0.30328,
    "out:ROI% old": 13.379229,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 187.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1423.286758,
    "out:Return %": 12,
    "out:Return/kSEK/y": 572,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.534761,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 6011.997076,
    "out:PV (% sold (El))": 29.919523,
    "out:PV (kWh self-consumed)": 14081.896202,
    "out:PV (ratio sold/self-consumed)": 0.426931,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 204465.123674,
    "out:EL kWh savings": -25553.069017,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162376.98,
    "out:DH kr savings": 204465.12367359124,
    "out:El kr savings": -51106.13803441148,
    "out:El kr sold": 9017.995613288665,
    "out:El kr saved": 28163.792404,
    "out:El kr return": 37181.788017288665,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 54463.170456,
    "out:% savings (space heating)": 74.757664,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 28.7,
    "out:Etvv": 0,
    "out:Ef": 20.4,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4779530.260895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": -12.528631,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -15.192623,
    "out:Bought CO2": 2.663992,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.016344,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.172938,
    "out:Electricity (inc PV)": 20.318347,
    "out:Total Energy Use Pre PV": 52.172938,
    "out:Total Energy Use Post PV": 45.318347,
    "out:Primary Energy": 56.852721,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.091981,
    "out:CO2 Operational/m2": -3.621405,
    "out:Total CO2/m2": 29.470576,
    "out:Total CO2 (tons)": 58.115979,
    "out:Klimatpaverkan": -36.816193,
    "out:Initial Cost/MSEK": 4.828830260895615,
    "out:Running cost/(Apt SEK y)": 6437.941176,
    "out:Running Cost over RSP/MSEK": 1.7101,
    "out:LCP/MSEK": -0.20986,
    "out:ROI% old": 13.664859,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1417.234129,
    "out:Return %": 12,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.433438,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 6043.256172,
    "out:PV (% sold (El))": 30.075088,
    "out:PV (kWh self-consumed)": 14050.637106,
    "out:PV (ratio sold/self-consumed)": 0.430105,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 209596.06091,
    "out:EL kWh savings": -25396.101752,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167868.74,
    "out:DH kr savings": 209596.06091020728,
    "out:El kr savings": -50792.20350426312,
    "out:El kr sold": 9064.884257390357,
    "out:El kr saved": 28101.274212,
    "out:El kr return": 37166.15846939036,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 49332.23322,
    "out:% savings (space heating)": 77.135727,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 26.07,
    "out:Etvv": 0,
    "out:Ef": 20.32,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4828830.260895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -12.621005,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -15.273686,
    "out:Bought CO2": 2.652681,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.963299,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.662667,
    "out:Electricity (inc PV)": 18.823207,
    "out:Total Energy Use Pre PV": 56.662667,
    "out:Total Energy Use Post PV": 47.823207,
    "out:Primary Energy": 57.417157,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.686441,
    "out:CO2 Operational/m2": -44.001977,
    "out:Total CO2/m2": -0.315536,
    "out:Total CO2 (tons)": -0.622237,
    "out:Klimatpaverkan": -95.554409,
    "out:Initial Cost/MSEK": 4.994109146354366,
    "out:Running cost/(Apt SEK y)": 6251.235294,
    "out:Running Cost over RSP/MSEK": 1.66713,
    "out:LCP/MSEK": -0.332169,
    "out:ROI% old": 13.33554,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 187.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 1310.490963,
    "out:Return %": 12,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 17.923167,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22215.849415,
    "out:PV (% sold (El))": 55.280102,
    "out:PV (kWh self-consumed)": 17971.93714,
    "out:PV (ratio sold/self-consumed)": 1.236141,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 201812.6652,
    "out:EL kWh savings": -22447.685502,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190241.07,
    "out:DH kr savings": 201812.66520025439,
    "out:El kr savings": -44895.371003371176,
    "out:El kr sold": 33323.77412222859,
    "out:El kr saved": 35943.87428,
    "out:El kr return": 69267.6484022286,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 57115.62893,
    "out:% savings (space heating)": 73.528315,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 30.26,
    "out:Etvv": 0,
    "out:Ef": 18.82,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4994109.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": -54.441513,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.895474,
    "out:Bought CO2": 2.453961,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.333226,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.569883,
    "out:Electricity (inc PV)": 18.758012,
    "out:Total Energy Use Pre PV": 53.569883,
    "out:Total Energy Use Post PV": 44.758012,
    "out:Primary Energy": 55.236238,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 43.686441,
    "out:CO2 Operational/m2": -45.206377,
    "out:Total CO2/m2": -1.519936,
    "out:Total CO2 (tons)": -2.997314,
    "out:Klimatpaverkan": -97.929486,
    "out:Initial Cost/MSEK": 5.043409146354366,
    "out:Running cost/(Apt SEK y)": 5715.647059,
    "out:Running Cost over RSP/MSEK": 1.52472,
    "out:LCP/MSEK": -0.239059,
    "out:ROI% old": 13.608567,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 206.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 1305.5037,
    "out:Return %": 12,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 17.830383,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22260.126459,
    "out:PV (% sold (El))": 55.390277,
    "out:PV (kWh self-consumed)": 17927.660096,
    "out:PV (ratio sold/self-consumed)": 1.241664,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 206999.169456,
    "out:EL kWh savings": -22319.120954,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195751.12,
    "out:DH kr savings": 206999.16945608275,
    "out:El kr savings": -44638.2419085067,
    "out:El kr sold": 33390.18968872769,
    "out:El kr saved": 35855.320192,
    "out:El kr return": 69245.50988072768,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 51929.124674,
    "out:% savings (space heating)": 75.932132,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 27.61,
    "out:Etvv": 0,
    "out:Ef": 18.76,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5043409.146354366,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": -54.565961,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.010606,
    "out:Bought CO2": 2.444645,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.618239,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.274261,
    "out:Electricity (inc PV)": 18.492137,
    "out:Total Energy Use Pre PV": 55.274261,
    "out:Total Energy Use Post PV": 46.492137,
    "out:Primary Energy": 55.607536,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.353784,
    "out:CO2 Operational/m2": -44.884541,
    "out:Total CO2/m2": -0.530757,
    "out:Total CO2 (tons)": -1.046653,
    "out:Klimatpaverkan": -95.978825,
    "out:Initial Cost/MSEK": 5.153485146354366,
    "out:Running cost/(Apt SEK y)": 6033.735294,
    "out:Running Cost over RSP/MSEK": 1.60941,
    "out:LCP/MSEK": -0.433825,
    "out:ROI% old": 13.08313,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 200,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1289.634338,
    "out:Return %": 12,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.534761,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22401.565127,
    "out:PV (% sold (El))": 55.742222,
    "out:PV (kWh self-consumed)": 17786.221428,
    "out:PV (ratio sold/self-consumed)": 1.25949,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204465.123674,
    "out:EL kWh savings": -21794.815424,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194477.84,
    "out:DH kr savings": 204465.12367359124,
    "out:El kr savings": -43589.63084788723,
    "out:El kr sold": 33602.347690026785,
    "out:El kr saved": 35572.442856,
    "out:El kr return": 69174.79054602678,
    "out:% solar/total": 121,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 54463.170456,
    "out:% savings (space heating)": 74.757664,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 28.7,
    "out:Etvv": 0,
    "out:Ef": 18.49,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5153485.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": -54.964093,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.379098,
    "out:Bought CO2": 2.415005,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.016344,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 27.172938,
    "out:Electricity (inc PV)": 18.420769,
    "out:Total Energy Use Pre PV": 52.172938,
    "out:Total Energy Use Post PV": 43.420769,
    "out:Primary Energy": 53.43708,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.353784,
    "out:CO2 Operational/m2": -46.10147,
    "out:Total CO2/m2": -1.747686,
    "out:Total CO2 (tons)": -3.446437,
    "out:Klimatpaverkan": -98.378609,
    "out:Initial Cost/MSEK": 5.202785146354366,
    "out:Running cost/(Apt SEK y)": 5497.235294,
    "out:Running Cost over RSP/MSEK": 1.46677,
    "out:LCP/MSEK": -0.340485,
    "out:ROI% old": 13.350816,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 220.9,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1284.202454,
    "out:Return %": 12,
    "out:Return/kSEK/y": 623,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 443700,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 22185,
    "out:EAHP (replacement cost)": 336177.01458330534,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 17.433438,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 22450.176561,
    "out:PV (% sold (El))": 55.863183,
    "out:PV (kWh self-consumed)": 17737.609994,
    "out:PV (ratio sold/self-consumed)": 1.265682,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 209596.06091,
    "out:EL kWh savings": -21654.07772,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199963.17,
    "out:DH kr savings": 209596.06091020728,
    "out:El kr savings": -43308.1554396153,
    "out:El kr sold": 33675.26484141228,
    "out:El kr saved": 35475.219988,
    "out:El kr return": 69150.48482941228,
    "out:% solar/total": 121,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 49332.23322,
    "out:% savings (space heating)": 77.135727,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 26.07,
    "out:Etvv": 0,
    "out:Ef": 18.42,
    "out:heatPumpQuantity": 3,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5202785.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -55.10107,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.505929,
    "out:Bought CO2": 2.404859,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.548984,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.993672,
    "out:Electricity (inc PV)": 37.795935,
    "out:Total Energy Use Pre PV": 39.993672,
    "out:Total Energy Use Post PV": 40.795935,
    "out:Primary Energy": 70.374972,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.60179,
    "out:CO2 Operational/m2": 5.963413,
    "out:Total CO2/m2": 27.565203,
    "out:Total CO2 (tons)": 54.358583,
    "out:Klimatpaverkan": -40.573589,
    "out:Initial Cost/MSEK": 4.951962767899366,
    "out:Running cost/(Apt SEK y)": 4816.735294,
    "out:Running Cost over RSP/MSEK": 1.26769,
    "out:LCP/MSEK": 0.109417,
    "out:ROI% old": 14.601368,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 236.6,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2589.55704,
    "out:Return %": 13,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 27.254172,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 253901.697391,
    "out:EL kWh savings": -59861.907281,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134177.88,
    "out:DH kr savings": 253901.69739148635,
    "out:El kr savings": -119723.81456115314,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 5026.596739,
    "out:% savings (space heating)": 97.670296,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 3.01,
    "out:Etvv": 0,
    "out:Ef": 37.8,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4951962.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": 4.883461,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.883461,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.700453,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.361026,
    "out:Electricity (inc PV)": 37.196571,
    "out:Total Energy Use Pre PV": 38.361026,
    "out:Total Energy Use Post PV": 39.196571,
    "out:Primary Energy": 68.550726,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.60179,
    "out:CO2 Operational/m2": 5.519914,
    "out:Total CO2/m2": 27.121704,
    "out:Total CO2 (tons)": 53.484003,
    "out:Klimatpaverkan": -41.448169,
    "out:Initial Cost/MSEK": 5.001262767899366,
    "out:Running cost/(Apt SEK y)": 4568.205882,
    "out:Running Cost over RSP/MSEK": 1.20182,
    "out:LCP/MSEK": 0.125987,
    "out:ROI% old": 14.645587,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 253.8,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2545.27182,
    "out:Return %": 13,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 26.621526,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 255575.00062,
    "out:EL kWh savings": -58679.961404,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138215.08,
    "out:DH kr savings": 255575.00062021884,
    "out:El kr savings": -117359.92280849817,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3353.29351,
    "out:% savings (space heating)": 98.445831,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 37.2,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5001262.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": 4.799946,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.799946,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.119911,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.128694,
    "out:Electricity (inc PV)": 36.826621,
    "out:Total Energy Use Pre PV": 38.128694,
    "out:Total Energy Use Post PV": 38.826621,
    "out:Primary Energy": 68.201446,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 22.269133,
    "out:CO2 Operational/m2": 5.489245,
    "out:Total CO2/m2": 27.758378,
    "out:Total CO2 (tons)": 54.739525,
    "out:Klimatpaverkan": -40.192648,
    "out:Initial Cost/MSEK": 5.111338767899366,
    "out:Running cost/(Apt SEK y)": 4541.235294,
    "out:Running Cost over RSP/MSEK": 1.19474,
    "out:LCP/MSEK": 0.022991,
    "out:ROI% old": 14.349973,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 253.8,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2529.00858,
    "out:Return %": 13,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 26.389194,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 254747.829396,
    "out:EL kWh savings": -57950.419962,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138846.99,
    "out:DH kr savings": 254747.82939640063,
    "out:El kr savings": -115900.83992414962,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 4180.464734,
    "out:% savings (space heating)": 98.062458,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.46,
    "out:Etvv": 0,
    "out:Ef": 36.83,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5111338.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": 4.769277,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.769277,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.389746,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.46018,
    "out:Electricity (inc PV)": 36.186803,
    "out:Total Energy Use Pre PV": 36.46018,
    "out:Total Energy Use Post PV": 37.186803,
    "out:Primary Energy": 66.408213,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 22.269133,
    "out:CO2 Operational/m2": 5.041011,
    "out:Total CO2/m2": 27.310144,
    "out:Total CO2 (tons)": 53.855607,
    "out:Klimatpaverkan": -41.076565,
    "out:Initial Cost/MSEK": 5.160638767899366,
    "out:Running cost/(Apt SEK y)": 4288.529412,
    "out:Running Cost over RSP/MSEK": 1.12777,
    "out:LCP/MSEK": 0.040661,
    "out:ROI% old": 14.398273,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 273,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2482.2126,
    "out:Return %": 13,
    "out:Return/kSEK/y": 664,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 25.72068,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 256187.71486,
    "out:EL kWh savings": -56688.698793,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142810.32,
    "out:DH kr savings": 256187.71485963944,
    "out:El kr savings": -113377.3975862711,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2740.57927,
    "out:% savings (space heating)": 98.729809,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.64,
    "out:Etvv": 0,
    "out:Ef": 36.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5160638.767899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.681027,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.681027,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.548984,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.993672,
    "out:Electricity (inc PV)": 29.280225,
    "out:Total Energy Use Pre PV": 39.993672,
    "out:Total Energy Use Post PV": 32.280225,
    "out:Primary Energy": 55.046694,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 32.863593,
    "out:CO2 Operational/m2": -3.782611,
    "out:Total CO2/m2": 29.080982,
    "out:Total CO2 (tons)": 57.3477,
    "out:Klimatpaverkan": -37.584472,
    "out:Initial Cost/MSEK": 5.325917635895616,
    "out:Running cost/(Apt SEK y)": 3685.823529,
    "out:Running Cost over RSP/MSEK": 0.975,
    "out:LCP/MSEK": 0.028152,
    "out:ROI% old": 14.361227,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 32,
    "out:Energy use kWh/m2": 32,
    "out:Energy savings %": 331.2,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2020.591258,
    "out:Return %": 13,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 27.254172,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3472.310356,
    "out:PV (% sold (El))": 17.280426,
    "out:PV (kWh self-consumed)": 16621.582922,
    "out:PV (ratio sold/self-consumed)": 0.208904,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 253901.697391,
    "out:EL kWh savings": -43068.92619,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172972.31,
    "out:DH kr savings": 253901.69739148635,
    "out:El kr savings": -86137.85237957133,
    "out:El kr sold": 5208.465533666507,
    "out:El kr saved": 33243.165844,
    "out:El kr return": 38451.63137766651,
    "out:% solar/total": 113,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 5026.596739,
    "out:% savings (space heating)": 97.670296,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 3.01,
    "out:Etvv": 0,
    "out:Ef": 29.28,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5325917.635895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -4.862563,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.642767,
    "out:Bought CO2": 3.780204,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.700453,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.361026,
    "out:Electricity (inc PV)": 28.745532,
    "out:Total Energy Use Pre PV": 38.361026,
    "out:Total Energy Use Post PV": 30.745532,
    "out:Primary Energy": 53.338856,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 32.863593,
    "out:CO2 Operational/m2": -4.574059,
    "out:Total CO2/m2": 28.289534,
    "out:Total CO2 (tons)": 55.786964,
    "out:Klimatpaverkan": -39.145208,
    "out:Initial Cost/MSEK": 5.375217635895615,
    "out:Running cost/(Apt SEK y)": 3439.323529,
    "out:Running Cost over RSP/MSEK": 0.90965,
    "out:LCP/MSEK": 0.044202,
    "out:ROI% old": 14.403191,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1980.772512,
    "out:Return %": 13,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 26.621526,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3611.644911,
    "out:PV (% sold (El))": 17.973843,
    "out:PV (kWh self-consumed)": 16482.248366,
    "out:PV (ratio sold/self-consumed)": 0.219123,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 255575.00062,
    "out:EL kWh savings": -42014.511533,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176963.44,
    "out:DH kr savings": 255575.00062021884,
    "out:El kr savings": -84029.0230656613,
    "out:El kr sold": 5417.467367205572,
    "out:El kr saved": 32964.496732,
    "out:El kr return": 38381.96409920557,
    "out:% solar/total": 112,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3353.29351,
    "out:% savings (space heating)": 98.445831,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 28.75,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5375217.635895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -5.294027,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.999812,
    "out:Bought CO2": 3.705785,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.119911,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.128694,
    "out:Electricity (inc PV)": 28.416292,
    "out:Total Energy Use Pre PV": 38.128694,
    "out:Total Energy Use Post PV": 30.416292,
    "out:Primary Energy": 53.062854,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.530936,
    "out:CO2 Operational/m2": -4.7349,
    "out:Total CO2/m2": 28.796036,
    "out:Total CO2 (tons)": 56.785786,
    "out:Klimatpaverkan": -38.146386,
    "out:Initial Cost/MSEK": 5.485293635895616,
    "out:Running cost/(Apt SEK y)": 3413.147059,
    "out:Running Cost over RSP/MSEK": 0.90277,
    "out:LCP/MSEK": -0.058994,
    "out:ROI% old": 14.132073,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 360,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1966.181318,
    "out:Return %": 13,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 26.389194,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3663.853816,
    "out:PV (% sold (El))": 18.233668,
    "out:PV (kWh self-consumed)": 16430.039462,
    "out:PV (ratio sold/self-consumed)": 0.222997,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 254747.829396,
    "out:EL kWh savings": -41365.250215,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177513.11,
    "out:DH kr savings": 254747.82939640063,
    "out:El kr savings": -82730.50043059453,
    "out:El kr sold": 5495.780723686716,
    "out:El kr saved": 32860.078924,
    "out:El kr return": 38355.85964768672,
    "out:% solar/total": 112,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 4180.464734,
    "out:% savings (space heating)": 98.062458,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.46,
    "out:Etvv": 0,
    "out:Ef": 28.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5485293.635895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -5.454868,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.133386,
    "out:Bought CO2": 3.678518,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.389746,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.46018,
    "out:Electricity (inc PV)": 27.848468,
    "out:Total Energy Use Pre PV": 36.46018,
    "out:Total Energy Use Post PV": 28.848468,
    "out:Primary Energy": 51.39921,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.530936,
    "out:CO2 Operational/m2": -5.56579,
    "out:Total CO2/m2": 27.965146,
    "out:Total CO2 (tons)": 55.147271,
    "out:Klimatpaverkan": -39.784901,
    "out:Initial Cost/MSEK": 5.534593635895615,
    "out:Running cost/(Apt SEK y)": 3162.676471,
    "out:Running Cost over RSP/MSEK": 0.83638,
    "out:LCP/MSEK": -0.041904,
    "out:ROI% old": 14.177554,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 29,
    "out:Energy use kWh/m2": 29,
    "out:Energy savings %": 375.9,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1924.295282,
    "out:Return %": 13,
    "out:Return/kSEK/y": 702,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 25.72068,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3817.536869,
    "out:PV (% sold (El))": 18.998493,
    "out:PV (kWh self-consumed)": 16276.356408,
    "out:PV (ratio sold/self-consumed)": 0.234545,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 256187.71486,
    "out:EL kWh savings": -40245.501223,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181423.02,
    "out:DH kr savings": 256187.71485963944,
    "out:El kr savings": -80491.00244513071,
    "out:El kr sold": 5726.305303588739,
    "out:El kr saved": 32552.712816,
    "out:El kr return": 38279.01811958874,
    "out:% solar/total": 111,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2740.57927,
    "out:% savings (space heating)": 98.729809,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.64,
    "out:Etvv": 0,
    "out:Ef": 27.85,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5534593.635895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -5.925774,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.526045,
    "out:Bought CO2": 3.600271,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.548984,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.993672,
    "out:Electricity (inc PV)": 26.466716,
    "out:Total Energy Use Pre PV": 39.993672,
    "out:Total Energy Use Post PV": 29.466716,
    "out:Primary Energy": 49.982378,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.125396,
    "out:CO2 Operational/m2": -41.852747,
    "out:Total CO2/m2": 2.272649,
    "out:Total CO2 (tons)": 4.481664,
    "out:Klimatpaverkan": -90.450508,
    "out:Initial Cost/MSEK": 5.699872521354366,
    "out:Running cost/(Apt SEK y)": 2719.852941,
    "out:Running Cost over RSP/MSEK": 0.72501,
    "out:LCP/MSEK": -0.095813,
    "out:ROI% old": 14.045577,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 29,
    "out:Energy use kWh/m2": 29,
    "out:Energy savings %": 375.9,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 1825.678054,
    "out:Return %": 13,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 27.254172,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18162.972135,
    "out:PV (% sold (El))": 45.195254,
    "out:PV (kWh self-consumed)": 22024.81442,
    "out:PV (ratio sold/self-consumed)": 0.824659,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 253901.697391,
    "out:EL kWh savings": -37520.686121,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206104.78,
    "out:DH kr savings": 253901.69739148635,
    "out:El kr savings": -75041.37224209124,
    "out:El kr sold": 27244.45820189194,
    "out:El kr saved": 44049.62884,
    "out:El kr return": 71294.08704189194,
    "out:% solar/total": 150,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 5026.596739,
    "out:% savings (space heating)": 97.670296,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 3.01,
    "out:Etvv": 0,
    "out:Ef": 26.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5699872.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -42.932699,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -46.349869,
    "out:Bought CO2": 3.41717,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.700453,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.361026,
    "out:Electricity (inc PV)": 25.987056,
    "out:Total Energy Use Pre PV": 38.361026,
    "out:Total Energy Use Post PV": 27.987056,
    "out:Primary Energy": 48.373599,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.125396,
    "out:CO2 Operational/m2": -42.936553,
    "out:Total CO2/m2": 1.188843,
    "out:Total CO2 (tons)": 2.344399,
    "out:Klimatpaverkan": -92.587774,
    "out:Initial Cost/MSEK": 5.7491725213543665,
    "out:Running cost/(Apt SEK y)": 2475.029412,
    "out:Running Cost over RSP/MSEK": 0.66011,
    "out:LCP/MSEK": -0.080213,
    "out:ROI% old": 14.0864,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 28,
    "out:Energy use kWh/m2": 28,
    "out:Energy savings %": 392.9,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1790.016866,
    "out:Return %": 13,
    "out:Return/kSEK/y": 725,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 26.621526,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18415.098019,
    "out:PV (% sold (El))": 45.822623,
    "out:PV (kWh self-consumed)": 21772.688536,
    "out:PV (ratio sold/self-consumed)": 0.845789,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255575.00062,
    "out:EL kWh savings": -36574.796546,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210048.05,
    "out:DH kr savings": 255575.00062021884,
    "out:El kr savings": -73149.59309272877,
    "out:El kr sold": 27622.647028830062,
    "out:El kr saved": 43545.377072,
    "out:El kr return": 71168.02410083007,
    "out:% solar/total": 148,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3353.29351,
    "out:% savings (space heating)": 98.445831,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 25.99,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5749172.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -43.656521,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -47.006908,
    "out:Bought CO2": 3.350387,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.119911,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 36.128694,
    "out:Electricity (inc PV)": 25.691521,
    "out:Total Energy Use Pre PV": 38.128694,
    "out:Total Energy Use Post PV": 27.691521,
    "out:Primary Energy": 48.158266,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.792739,
    "out:CO2 Operational/m2": -43.203957,
    "out:Total CO2/m2": 1.588782,
    "out:Total CO2 (tons)": 3.133078,
    "out:Klimatpaverkan": -91.799094,
    "out:Initial Cost/MSEK": 5.859248521354366,
    "out:Running cost/(Apt SEK y)": 2449.441176,
    "out:Running Cost over RSP/MSEK": 0.65338,
    "out:LCP/MSEK": -0.183559,
    "out:ROI% old": 13.838171,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 28,
    "out:Energy use kWh/m2": 28,
    "out:Energy savings %": 392.9,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1776.941625,
    "out:Return %": 12,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 26.389194,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18508.509748,
    "out:PV (% sold (El))": 46.055061,
    "out:PV (kWh self-consumed)": 21679.276806,
    "out:PV (ratio sold/self-consumed)": 0.853742,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 254747.829396,
    "out:EL kWh savings": -35992.001493,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210526.59,
    "out:DH kr savings": 254747.82939640063,
    "out:El kr savings": -71984.00298534679,
    "out:El kr sold": 27762.764622614202,
    "out:El kr saved": 43358.553612,
    "out:El kr return": 71121.31823461421,
    "out:% solar/total": 148,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 4180.464734,
    "out:% savings (space heating)": 98.062458,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.46,
    "out:Etvv": 0,
    "out:Ef": 25.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5859248.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -43.923925,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -47.249829,
    "out:Bought CO2": 3.325904,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.389746,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.46018,
    "out:Electricity (inc PV)": 25.181387,
    "out:Total Energy Use Pre PV": 36.46018,
    "out:Total Energy Use Post PV": 26.181387,
    "out:Primary Energy": 46.598465,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.792739,
    "out:CO2 Operational/m2": -44.33875,
    "out:Total CO2/m2": 0.453989,
    "out:Total CO2 (tons)": 0.895266,
    "out:Klimatpaverkan": -94.036906,
    "out:Initial Cost/MSEK": 5.9085485213543665,
    "out:Running cost/(Apt SEK y)": 2200.735294,
    "out:Running Cost over RSP/MSEK": 0.58745,
    "out:LCP/MSEK": -0.166929,
    "out:ROI% old": 13.882114,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 26,
    "out:Energy use kWh/m2": 26,
    "out:Energy savings %": 430.8,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1739.384685,
    "out:Return %": 12,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 25.72068,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18779.545172,
    "out:PV (% sold (El))": 46.729484,
    "out:PV (kWh self-consumed)": 21408.241383,
    "out:PV (ratio sold/self-consumed)": 0.877211,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 256187.71486,
    "out:EL kWh savings": -34986.017187,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214385,
    "out:DH kr savings": 256187.71485963944,
    "out:El kr savings": -69972.03437303624,
    "out:El kr sold": 28169.317757913712,
    "out:El kr saved": 42816.482766,
    "out:El kr return": 70985.80052391371,
    "out:% solar/total": 146,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2740.57927,
    "out:% savings (space heating)": 98.729809,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.64,
    "out:Etvv": 0,
    "out:Ef": 25.18,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5908548.521354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -44.698734,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -47.95433,
    "out:Bought CO2": 3.255596,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.990742,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.836169,
    "out:Electricity (inc PV)": 36.49822,
    "out:Total Energy Use Pre PV": 37.836169,
    "out:Total Energy Use Post PV": 38.49822,
    "out:Primary Energy": 67.480999,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.60179,
    "out:CO2 Operational/m2": 5.450629,
    "out:Total CO2/m2": 27.052419,
    "out:Total CO2 (tons)": 53.347373,
    "out:Klimatpaverkan": -41.584799,
    "out:Initial Cost/MSEK": 5.041699392899366,
    "out:Running cost/(Apt SEK y)": 4507.323529,
    "out:Running Cost over RSP/MSEK": 1.18582,
    "out:LCP/MSEK": 0.101551,
    "out:ROI% old": 14.573459,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 263.2,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2508.53183,
    "out:Return %": 13,
    "out:Return/kSEK/y": 656,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 26.096669,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 255002.550679,
    "out:EL kWh savings": -57302.813153,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140396.92,
    "out:DH kr savings": 255002.55067912588,
    "out:El kr savings": -114605.62630527417,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3925.743451,
    "out:% savings (space heating)": 98.180515,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.29,
    "out:Etvv": 0,
    "out:Ef": 36.5,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5041699.392899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": 4.730661,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.730661,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.298884,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.156047,
    "out:Electricity (inc PV)": 35.844512,
    "out:Total Energy Use Pre PV": 36.156047,
    "out:Total Energy Use Post PV": 36.844512,
    "out:Primary Energy": 65.698417,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 21.60179,
    "out:CO2 Operational/m2": 5.000863,
    "out:Total CO2/m2": 26.602653,
    "out:Total CO2 (tons)": 52.460435,
    "out:Klimatpaverkan": -42.471738,
    "out:Initial Cost/MSEK": 5.090999392899366,
    "out:Running cost/(Apt SEK y)": 4253.264706,
    "out:Running Cost over RSP/MSEK": 1.1185,
    "out:LCP/MSEK": 0.119571,
    "out:ROI% old": 14.621238,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 273,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2460.92329,
    "out:Return %": 13,
    "out:Return/kSEK/y": 665,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 25.416547,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 256366.894734,
    "out:EL kWh savings": -56013.700902,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144339.49,
    "out:DH kr savings": 256366.89473399773,
    "out:El kr savings": -112027.40180422876,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2561.399396,
    "out:% savings (space heating)": 98.812855,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.51,
    "out:Etvv": 0,
    "out:Ef": 35.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5090999.392899366,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.640879,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.640879,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.708535,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.125687,
    "out:Electricity (inc PV)": 35.698729,
    "out:Total Energy Use Pre PV": 37.125687,
    "out:Total Energy Use Post PV": 37.698729,
    "out:Primary Energy": 65.760608,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 22.269133,
    "out:CO2 Operational/m2": 5.35684,
    "out:Total CO2/m2": 27.625973,
    "out:Total CO2 (tons)": 54.478422,
    "out:Klimatpaverkan": -40.45375,
    "out:Initial Cost/MSEK": 5.201075392899365,
    "out:Running cost/(Apt SEK y)": 4424.911765,
    "out:Running Cost over RSP/MSEK": 1.16416,
    "out:LCP/MSEK": -0.036165,
    "out:ROI% old": 14.186379,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 263.2,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2458.79809,
    "out:Return %": 13,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 25.386187,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 255559.062915,
    "out:EL kWh savings": -55726.216809,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144106.63,
    "out:DH kr savings": 255559.0629152975,
    "out:El kr savings": -111452.43361899021,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3369.231215,
    "out:% savings (space heating)": 98.438444,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.93,
    "out:Etvv": 0,
    "out:Ef": 35.7,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5201075.392899365,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": 4.636872,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.636872,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.103251,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.41933,
    "out:Electricity (inc PV)": 35.013087,
    "out:Total Energy Use Pre PV": 35.41933,
    "out:Total Energy Use Post PV": 36.013087,
    "out:Primary Energy": 64.002509,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 22.269133,
    "out:CO2 Operational/m2": 4.903611,
    "out:Total CO2/m2": 27.172744,
    "out:Total CO2 (tons)": 53.584654,
    "out:Klimatpaverkan": -41.347518,
    "out:Initial Cost/MSEK": 5.2503753928993655,
    "out:Running cost/(Apt SEK y)": 4167.794118,
    "out:Running Cost over RSP/MSEK": 1.09604,
    "out:LCP/MSEK": -0.017345,
    "out:ROI% old": 14.238519,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 283.3,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2409.3531,
    "out:Return %": 13,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 24.67983,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 256752.683032,
    "out:EL kWh savings": -54374.130707,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148004.42,
    "out:DH kr savings": 256752.6830322999,
    "out:El kr savings": -108748.26141466382,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2175.611098,
    "out:% savings (space heating)": 98.991658,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.26,
    "out:Etvv": 0,
    "out:Ef": 35.01,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5250375.392899365,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 4.543627,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.543627,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.990742,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.836169,
    "out:Electricity (inc PV)": 28.124604,
    "out:Total Energy Use Pre PV": 37.836169,
    "out:Total Energy Use Post PV": 30.124604,
    "out:Primary Energy": 52.40849,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 32.863593,
    "out:CO2 Operational/m2": -4.939453,
    "out:Total CO2/m2": 27.92414,
    "out:Total CO2 (tons)": 55.066407,
    "out:Klimatpaverkan": -39.865765,
    "out:Initial Cost/MSEK": 5.415654260895615,
    "out:Running cost/(Apt SEK y)": 3380.176471,
    "out:Running Cost over RSP/MSEK": 0.8941,
    "out:LCP/MSEK": 0.019316,
    "out:ROI% old": 14.336666,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 30,
    "out:Energy use kWh/m2": 30,
    "out:Energy savings %": 360,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1947.834735,
    "out:Return %": 13,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 26.096669,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3730.522014,
    "out:PV (% sold (El))": 18.565452,
    "out:PV (kWh self-consumed)": 16363.371263,
    "out:PV (ratio sold/self-consumed)": 0.22798,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 255002.550679,
    "out:EL kWh savings": -40790.041446,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179018.25,
    "out:DH kr savings": 255002.55067912588,
    "out:El kr savings": -81580.08289208972,
    "out:El kr sold": 5595.783020880057,
    "out:El kr saved": 32726.742526,
    "out:El kr return": 38322.52554688006,
    "out:% solar/total": 112,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3925.743451,
    "out:% savings (space heating)": 98.180515,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.29,
    "out:Etvv": 0,
    "out:Ef": 28.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5415654.260895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -5.659421,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.303661,
    "out:Bought CO2": 3.64424,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.298884,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.156047,
    "out:Electricity (inc PV)": 27.545684,
    "out:Total Energy Use Pre PV": 36.156047,
    "out:Total Energy Use Post PV": 28.545684,
    "out:Primary Energy": 50.760526,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 32.863593,
    "out:CO2 Operational/m2": -5.783996,
    "out:Total CO2/m2": 27.079597,
    "out:Total CO2 (tons)": 53.400968,
    "out:Klimatpaverkan": -41.531204,
    "out:Initial Cost/MSEK": 5.464954260895616,
    "out:Running cost/(Apt SEK y)": 3128.441176,
    "out:Running Cost over RSP/MSEK": 0.82737,
    "out:LCP/MSEK": 0.036746,
    "out:ROI% old": 14.38177,
    "out:Payback discounted": 8,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 29,
    "out:Energy use kWh/m2": 29,
    "out:Energy savings %": 375.9,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1905.288664,
    "out:Return %": 13,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 25.416547,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3888.754097,
    "out:PV (% sold (El))": 19.352915,
    "out:PV (kWh self-consumed)": 16205.13918,
    "out:PV (ratio sold/self-consumed)": 0.23997,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 256366.894734,
    "out:EL kWh savings": -39648.41114,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182903.2,
    "out:DH kr savings": 256366.89473399773,
    "out:El kr savings": -79296.82228009595,
    "out:El kr sold": 5833.131145969026,
    "out:El kr saved": 32410.27836,
    "out:El kr return": 38243.40950596903,
    "out:% solar/total": 110,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2561.399396,
    "out:% savings (space heating)": 98.812855,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.51,
    "out:Etvv": 0,
    "out:Ef": 27.55,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5464954.260895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -6.14398,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.708758,
    "out:Bought CO2": 3.564778,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.708535,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.125687,
    "out:Electricity (inc PV)": 27.416933,
    "out:Total Energy Use Pre PV": 37.125687,
    "out:Total Energy Use Post PV": 29.416933,
    "out:Primary Energy": 50.853375,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.530936,
    "out:CO2 Operational/m2": -5.445921,
    "out:Total CO2/m2": 28.085015,
    "out:Total CO2 (tons)": 55.383653,
    "out:Klimatpaverkan": -39.548519,
    "out:Initial Cost/MSEK": 5.575030260895615,
    "out:Running cost/(Apt SEK y)": 3300.205882,
    "out:Running Cost over RSP/MSEK": 0.87306,
    "out:LCP/MSEK": -0.11902,
    "out:ROI% old": 13.980731,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 29,
    "out:Energy use kWh/m2": 29,
    "out:Energy savings %": 375.9,
    "out:Op energy cost/MSEK": 6,
    "out:El price/MSEK": 6,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1903.392916,
    "out:Return %": 13,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 25.386187,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 3895.893307,
    "out:PV (% sold (El))": 19.388444,
    "out:PV (kWh self-consumed)": 16197.99997,
    "out:PV (ratio sold/self-consumed)": 0.240517,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 255559.062915,
    "out:EL kWh savings": -39394.514153,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182613.87,
    "out:DH kr savings": 255559.0629152975,
    "out:El kr savings": -78789.02830674071,
    "out:El kr sold": 5843.839961166733,
    "out:El kr saved": 32395.99994,
    "out:El kr return": 38239.839901166735,
    "out:% solar/total": 110,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3369.231215,
    "out:% savings (space heating)": 98.438444,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.93,
    "out:Etvv": 0,
    "out:Ef": 27.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5575030.260895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -6.165889,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.727127,
    "out:Bought CO2": 3.561238,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.103251,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.41933,
    "out:Electricity (inc PV)": 26.812575,
    "out:Total Energy Use Pre PV": 35.41933,
    "out:Total Energy Use Post PV": 27.812575,
    "out:Primary Energy": 49.241587,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 33.530936,
    "out:CO2 Operational/m2": -6.322007,
    "out:Total CO2/m2": 27.208929,
    "out:Total CO2 (tons)": 53.656011,
    "out:Klimatpaverkan": -41.276161,
    "out:Initial Cost/MSEK": 5.624330260895616,
    "out:Running cost/(Apt SEK y)": 3045.588235,
    "out:Running Cost over RSP/MSEK": 0.80557,
    "out:LCP/MSEK": -0.10083,
    "out:ROI% old": 14.029607,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 28,
    "out:Energy use kWh/m2": 28,
    "out:Energy savings %": 392.9,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 5,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1859.370896,
    "out:Return %": 13,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 24.67983,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 4064.446543,
    "out:PV (% sold (El))": 20.227272,
    "out:PV (kWh self-consumed)": 16029.446735,
    "out:PV (ratio sold/self-consumed)": 0.253561,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 256752.683032,
    "out:EL kWh savings": -38202.720108,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186443.91,
    "out:DH kr savings": 256752.6830322999,
    "out:El kr savings": -76405.44021694707,
    "out:El kr sold": 6096.669813882199,
    "out:El kr saved": 32058.89347,
    "out:El kr return": 38155.5632838822,
    "out:% solar/total": 109,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2175.611098,
    "out:% savings (space heating)": 98.991658,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.26,
    "out:Etvv": 0,
    "out:Ef": 26.81,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5624330.260895615,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -6.681991,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.161036,
    "out:Bought CO2": 3.479045,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.990742,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.836169,
    "out:Electricity (inc PV)": 25.429464,
    "out:Total Energy Use Pre PV": 37.836169,
    "out:Total Energy Use Post PV": 27.429464,
    "out:Primary Energy": 47.557238,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.125396,
    "out:CO2 Operational/m2": -43.541954,
    "out:Total CO2/m2": 0.583442,
    "out:Total CO2 (tons)": 1.150548,
    "out:Klimatpaverkan": -93.781625,
    "out:Initial Cost/MSEK": 5.789609146354366,
    "out:Running cost/(Apt SEK y)": 2417.235294,
    "out:Running Cost over RSP/MSEK": 0.64491,
    "out:LCP/MSEK": -0.105449,
    "out:ROI% old": 14.025521,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 27,
    "out:Energy use kWh/m2": 27,
    "out:Energy savings %": 411.1,
    "out:Op energy cost/MSEK": 5,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1760.495227,
    "out:Return %": 13,
    "out:Return/kSEK/y": 727,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 26.096669,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18626.6312,
    "out:PV (% sold (El))": 46.348985,
    "out:PV (kWh self-consumed)": 21561.155354,
    "out:PV (ratio sold/self-consumed)": 0.863898,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255002.550679,
    "out:EL kWh savings": -35475.225059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211992.05,
    "out:DH kr savings": 255002.55067912588,
    "out:El kr savings": -70950.45011759776,
    "out:El kr sold": 27939.94680073519,
    "out:El kr saved": 43122.310708,
    "out:El kr return": 71062.25750873519,
    "out:% solar/total": 147,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3925.743451,
    "out:% savings (space heating)": 98.180515,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 2.29,
    "out:Etvv": 0,
    "out:Ef": 25.43,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5789609.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -44.261922,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -47.557034,
    "out:Bought CO2": 3.295112,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.298884,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.156047,
    "out:Electricity (inc PV)": 24.909151,
    "out:Total Energy Use Pre PV": 36.156047,
    "out:Total Energy Use Post PV": 25.909151,
    "out:Primary Energy": 46.014767,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.125396,
    "out:CO2 Operational/m2": -44.694133,
    "out:Total CO2/m2": -0.568737,
    "out:Total CO2 (tons)": -1.121549,
    "out:Klimatpaverkan": -96.053722,
    "out:Initial Cost/MSEK": 5.838909146354366,
    "out:Running cost/(Apt SEK y)": 2167.264706,
    "out:Running Cost over RSP/MSEK": 0.57865,
    "out:LCP/MSEK": -0.088489,
    "out:ROI% old": 14.069213,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 26,
    "out:Energy use kWh/m2": 26,
    "out:Energy savings %": 430.8,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1722.332994,
    "out:Return %": 13,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 25.416547,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18903.945503,
    "out:PV (% sold (El))": 47.039031,
    "out:PV (kWh self-consumed)": 21283.841052,
    "out:PV (ratio sold/self-consumed)": 0.888183,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 256366.894734,
    "out:EL kWh savings": -34449.167763,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215824.48,
    "out:DH kr savings": 256366.89473399773,
    "out:El kr savings": -68898.33552696643,
    "out:El kr sold": 28355.918254198208,
    "out:El kr saved": 42567.682104,
    "out:El kr return": 70923.60035819821,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2561.399396,
    "out:% savings (space heating)": 98.812855,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.51,
    "out:Etvv": 0,
    "out:Ef": 24.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5838909.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -45.054117,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.277803,
    "out:Bought CO2": 3.223686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.708535,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 35.125687,
    "out:Electricity (inc PV)": 24.793347,
    "out:Total Energy Use Pre PV": 37.125687,
    "out:Total Energy Use Post PV": 26.793347,
    "out:Primary Energy": 46.130921,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.792739,
    "out:CO2 Operational/m2": -44.369723,
    "out:Total CO2/m2": 0.423016,
    "out:Total CO2 (tons)": 0.834188,
    "out:Klimatpaverkan": -94.097985,
    "out:Initial Cost/MSEK": 5.948985146354366,
    "out:Running cost/(Apt SEK y)": 2339.088235,
    "out:Running Cost over RSP/MSEK": 0.62435,
    "out:LCP/MSEK": -0.244265,
    "out:ROI% old": 13.699143,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 27,
    "out:Energy use kWh/m2": 27,
    "out:Energy savings %": 411.1,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 1720.632016,
    "out:Return %": 12,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 25.386187,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 18916.402031,
    "out:PV (% sold (El))": 47.070027,
    "out:PV (kWh self-consumed)": 21271.384524,
    "out:PV (ratio sold/self-consumed)": 0.889289,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255559.062915,
    "out:EL kWh savings": -34220.802262,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215492.06,
    "out:DH kr savings": 255559.0629152975,
    "out:El kr savings": -68441.6045245631,
    "out:El kr sold": 28374.603046173404,
    "out:El kr saved": 42542.769048,
    "out:El kr return": 70917.3720941734,
    "out:% solar/total": 145,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 3369.231215,
    "out:% savings (space heating)": 98.438444,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.93,
    "out:Etvv": 0,
    "out:Ef": 24.79,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5948985.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -45.089691,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.310194,
    "out:Bought CO2": 3.220503,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.103251,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 34.41933,
    "out:Electricity (inc PV)": 24.249828,
    "out:Total Energy Use Pre PV": 35.41933,
    "out:Total Energy Use Post PV": 25.249828,
    "out:Primary Energy": 44.628642,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 44.792739,
    "out:CO2 Operational/m2": -45.563299,
    "out:Total CO2/m2": -0.77056,
    "out:Total CO2 (tons)": -1.519544,
    "out:Klimatpaverkan": -96.451717,
    "out:Initial Cost/MSEK": 5.998285146354366,
    "out:Running cost/(Apt SEK y)": 2086.294118,
    "out:Running Cost over RSP/MSEK": 0.55735,
    "out:LCP/MSEK": -0.226565,
    "out:ROI% old": 13.746119,
    "out:Payback discounted": 9,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 25,
    "out:Energy use kWh/m2": 25,
    "out:Energy savings %": 452,
    "out:Op energy cost/MSEK": 4,
    "out:El price/MSEK": 4,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 1681.122723,
    "out:Return %": 12,
    "out:Return/kSEK/y": 739,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 24.67983,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 19209.138466,
    "out:PV (% sold (El))": 47.798449,
    "out:PV (kWh self-consumed)": 20978.648089,
    "out:PV (ratio sold/self-consumed)": 0.915652,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 256752.683032,
    "out:EL kWh savings": -33148.982732,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219268.43,
    "out:DH kr savings": 256752.6830322999,
    "out:El kr savings": -66297.96546464079,
    "out:El kr sold": 28813.707698587124,
    "out:El kr saved": 41957.296178,
    "out:El kr return": 70771.00387658711,
    "out:% solar/total": 143,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 2175.611098,
    "out:% savings (space heating)": 98.991658,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 1.26,
    "out:Etvv": 0,
    "out:Ef": 24.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5998285.146354366,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -45.923283,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -49.069889,
    "out:Bought CO2": 3.146606,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 118.039,
    "out:Total Energy Use Post PV": 118.039,
    "out:Primary Energy": 104.678075,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.601872,
    "out:CO2 Operational/m2": 39.747548,
    "out:Total CO2/m2": 43.34942,
    "out:Total CO2 (tons)": 85.485061,
    "out:Klimatpaverkan": -9.447111,
    "out:Initial Cost/MSEK": 2.066612358774367,
    "out:Running cost/(Apt SEK y)": 19963.470588,
    "out:Running Cost over RSP/MSEK": 5.30475,
    "out:LCP/MSEK": -1.042292,
    "out:ROI% old": 7.080739,
    "out:Payback discounted": 17,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 6,
    "out:Return/kSEK/y": 131,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 49670.663104,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 31532.21,
    "out:DH kr savings": 49670.66310367731,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2066612.3587743673,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 113.039,
    "out:Total Energy Use Post PV": 113.039,
    "out:Primary Energy": 101.022082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.601872,
    "out:CO2 Operational/m2": 37.947628,
    "out:Total CO2/m2": 41.5495,
    "out:Total CO2 (tons)": 81.935619,
    "out:Klimatpaverkan": -12.996554,
    "out:Initial Cost/MSEK": 2.1159123587743673,
    "out:Running cost/(Apt SEK y)": 19087.676471,
    "out:Running Cost over RSP/MSEK": 5.07184,
    "out:LCP/MSEK": -0.858682,
    "out:ROI% old": 8.488267,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 58940.172692,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 40801.72,
    "out:DH kr savings": 58940.17269234142,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2115912.3587743673,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 115.039,
    "out:Total Energy Use Post PV": 115.039,
    "out:Primary Energy": 102.053075,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.269215,
    "out:CO2 Operational/m2": 38.667596,
    "out:Total CO2/m2": 42.936811,
    "out:Total CO2 (tons)": 84.671396,
    "out:Klimatpaverkan": -10.260776,
    "out:Initial Cost/MSEK": 2.225988358774367,
    "out:Running cost/(Apt SEK y)": 19438,
    "out:Running Cost over RSP/MSEK": 5.165,
    "out:LCP/MSEK": -1.061918,
    "out:ROI% old": 7.470646,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 20,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 149,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 56145.725478,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 38007.27,
    "out:DH kr savings": 56145.72547799632,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2225988.3587743673,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 110.039,
    "out:Total Energy Use Post PV": 110.039,
    "out:Primary Energy": 98.397082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.269215,
    "out:CO2 Operational/m2": 36.867676,
    "out:Total CO2/m2": 41.136891,
    "out:Total CO2 (tons)": 81.121954,
    "out:Klimatpaverkan": -13.810219,
    "out:Initial Cost/MSEK": 2.2752883587743673,
    "out:Running cost/(Apt SEK y)": 18562.205882,
    "out:Running Cost over RSP/MSEK": 4.93209,
    "out:LCP/MSEK": -0.878308,
    "out:ROI% old": 8.771133,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 65415.235067,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 47276.78,
    "out:DH kr savings": 65415.235066660425,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2275288.3587743673,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 118.039,
    "out:Total Energy Use Post PV": 113.92842,
    "out:Primary Energy": 97.279031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.863675,
    "out:CO2 Operational/m2": 8.463827,
    "out:Total CO2/m2": 23.327502,
    "out:Total CO2 (tons)": 46.001837,
    "out:Klimatpaverkan": -48.930336,
    "out:Initial Cost/MSEK": 2.4405672267706175,
    "out:Running cost/(Apt SEK y)": 18957.794118,
    "out:Running Cost over RSP/MSEK": 5.04453,
    "out:LCP/MSEK": -1.156027,
    "out:ROI% old": 7.518978,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 21.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 49670.663104,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 65726.08,
    "out:DH kr savings": 49670.66310367731,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2440567.2267706175,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 113.039,
    "out:Total Energy Use Post PV": 108.92842,
    "out:Primary Energy": 93.623038,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.863675,
    "out:CO2 Operational/m2": 6.663907,
    "out:Total CO2/m2": 21.527582,
    "out:Total CO2 (tons)": 42.452394,
    "out:Klimatpaverkan": -52.479778,
    "out:Initial Cost/MSEK": 2.4898672267706177,
    "out:Running cost/(Apt SEK y)": 18081.970588,
    "out:Running Cost over RSP/MSEK": 4.81162,
    "out:LCP/MSEK": -0.972417,
    "out:ROI% old": 8.706431,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 26.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 195,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 58940.172692,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74995.59,
    "out:DH kr savings": 58940.17269234142,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2489867.2267706175,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 115.039,
    "out:Total Energy Use Post PV": 110.92842,
    "out:Primary Energy": 94.654031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.531018,
    "out:CO2 Operational/m2": 7.383875,
    "out:Total CO2/m2": 22.914893,
    "out:Total CO2 (tons)": 45.188172,
    "out:Klimatpaverkan": -49.744001,
    "out:Initial Cost/MSEK": 2.5999432267706175,
    "out:Running cost/(Apt SEK y)": 18432.294118,
    "out:Running Cost over RSP/MSEK": 4.90478,
    "out:LCP/MSEK": -1.175653,
    "out:ROI% old": 7.82594,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 183,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 56145.725478,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 72201.14,
    "out:DH kr savings": 56145.72547799632,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2599943.2267706175,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 110.039,
    "out:Total Energy Use Post PV": 105.92842,
    "out:Primary Energy": 90.998038,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.531018,
    "out:CO2 Operational/m2": 5.583955,
    "out:Total CO2/m2": 21.114973,
    "out:Total CO2 (tons)": 41.638729,
    "out:Klimatpaverkan": -53.293443,
    "out:Initial Cost/MSEK": 2.649243226770618,
    "out:Running cost/(Apt SEK y)": 17556.5,
    "out:Running Cost over RSP/MSEK": 4.67187,
    "out:LCP/MSEK": -0.992043,
    "out:ROI% old": 8.936245,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 213,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 65415.235067,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 81470.65,
    "out:DH kr savings": 65415.235066660425,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2649243.226770618,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 118.039,
    "out:Total Energy Use Post PV": 113.350253,
    "out:Primary Energy": 96.23833,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.125478,
    "out:CO2 Operational/m2": -40.552659,
    "out:Total CO2/m2": -14.427181,
    "out:Total CO2 (tons)": -28.450403,
    "out:Klimatpaverkan": -123.382575,
    "out:Initial Cost/MSEK": 2.8145221122293678,
    "out:Running cost/(Apt SEK y)": 18054.529412,
    "out:Running Cost over RSP/MSEK": 4.81105,
    "out:LCP/MSEK": -1.296502,
    "out:ROI% old": 7.705038,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 49670.663104,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96436.99,
    "out:DH kr savings": 49670.66310367731,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2814522.1122293677,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 113.039,
    "out:Total Energy Use Post PV": 108.350253,
    "out:Primary Energy": 92.582337,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.125478,
    "out:CO2 Operational/m2": -42.352579,
    "out:Total CO2/m2": -16.227101,
    "out:Total CO2 (tons)": -31.999845,
    "out:Klimatpaverkan": -126.932017,
    "out:Initial Cost/MSEK": 2.863822112229368,
    "out:Running cost/(Apt SEK y)": 17178.735294,
    "out:Running Cost over RSP/MSEK": 4.57814,
    "out:LCP/MSEK": -1.112892,
    "out:ROI% old": 8.734232,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 27.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 225,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 58940.172692,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105706.5,
    "out:DH kr savings": 58940.17269234142,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2863822.1122293677,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 115.039,
    "out:Total Energy Use Post PV": 110.350253,
    "out:Primary Energy": 93.61333,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.792821,
    "out:CO2 Operational/m2": -41.632611,
    "out:Total CO2/m2": -14.83979,
    "out:Total CO2 (tons)": -29.264068,
    "out:Klimatpaverkan": -124.19624,
    "out:Initial Cost/MSEK": 2.9738981122293673,
    "out:Running cost/(Apt SEK y)": 17529.029412,
    "out:Running Cost over RSP/MSEK": 4.67131,
    "out:LCP/MSEK": -1.316138,
    "out:ROI% old": 7.963382,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 56145.725478,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102912.05,
    "out:DH kr savings": 56145.72547799632,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2973898.1122293673,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.078352,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 110.039,
    "out:Total Energy Use Post PV": 105.350253,
    "out:Primary Energy": 89.957337,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.792821,
    "out:CO2 Operational/m2": -43.432531,
    "out:Total CO2/m2": -16.63971,
    "out:Total CO2 (tons)": -32.81351,
    "out:Klimatpaverkan": -127.745682,
    "out:Initial Cost/MSEK": 3.0231981122293674,
    "out:Running cost/(Apt SEK y)": 16653.235294,
    "out:Running Cost over RSP/MSEK": 4.4384,
    "out:LCP/MSEK": -1.132528,
    "out:ROI% old": 8.934106,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 31.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 243,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 65415.235067,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112181.56,
    "out:DH kr savings": 65415.235066660425,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3023198.1122293673,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 114.039,
    "out:Total Energy Use Post PV": 114.039,
    "out:Primary Energy": 101.178075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.601872,
    "out:CO2 Operational/m2": 38.307612,
    "out:Total CO2/m2": 41.909484,
    "out:Total CO2 (tons)": 82.645507,
    "out:Klimatpaverkan": -12.286665,
    "out:Initial Cost/MSEK": 2.1563489837743672,
    "out:Running cost/(Apt SEK y)": 19262.852941,
    "out:Running Cost over RSP/MSEK": 5.11842,
    "out:LCP/MSEK": -0.945699,
    "out:ROI% old": 8.020501,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 21.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 155,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 58304.079603,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 40165.62,
    "out:DH kr savings": 58304.079602769314,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2156348.9837743673,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 109.039,
    "out:Total Energy Use Post PV": 109.039,
    "out:Primary Energy": 97.522082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 3.601872,
    "out:CO2 Operational/m2": 36.507692,
    "out:Total CO2/m2": 40.109564,
    "out:Total CO2 (tons)": 79.096065,
    "out:Klimatpaverkan": -15.836107,
    "out:Initial Cost/MSEK": 2.2056489837743674,
    "out:Running cost/(Apt SEK y)": 18387.058824,
    "out:Running Cost over RSP/MSEK": 4.88551,
    "out:LCP/MSEK": -0.762089,
    "out:ROI% old": 9.349759,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 26.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 184,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 67573.589191,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 49435.13,
    "out:DH kr savings": 67573.58919143342,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2205648.9837743673,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 111.039,
    "out:Total Energy Use Post PV": 111.039,
    "out:Primary Energy": 99.078075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.269215,
    "out:CO2 Operational/m2": 37.22766,
    "out:Total CO2/m2": 41.496875,
    "out:Total CO2 (tons)": 81.831842,
    "out:Klimatpaverkan": -13.10033,
    "out:Initial Cost/MSEK": 2.315724983774367,
    "out:Running cost/(Apt SEK y)": 18737.352941,
    "out:Running Cost over RSP/MSEK": 4.97867,
    "out:LCP/MSEK": -0.965325,
    "out:ROI% old": 8.330622,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 172,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 63484.129502,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 45345.67,
    "out:DH kr savings": 63484.12950222452,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2315724.9837743673,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 106.039,
    "out:Total Energy Use Post PV": 106.039,
    "out:Primary Energy": 95.422082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.269215,
    "out:CO2 Operational/m2": 35.42774,
    "out:Total CO2/m2": 39.696955,
    "out:Total CO2 (tons)": 78.2824,
    "out:Klimatpaverkan": -16.649772,
    "out:Initial Cost/MSEK": 2.3650249837743673,
    "out:Running cost/(Apt SEK y)": 17861.558824,
    "out:Running Cost over RSP/MSEK": 4.74576,
    "out:LCP/MSEK": -0.781715,
    "out:ROI% old": 9.563837,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 72753.639091,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 54615.18,
    "out:DH kr savings": 72753.63909088862,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2365024.9837743673,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 114.039,
    "out:Total Energy Use Post PV": 109.92842,
    "out:Primary Energy": 93.779031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.863675,
    "out:CO2 Operational/m2": 7.023891,
    "out:Total CO2/m2": 21.887566,
    "out:Total CO2 (tons)": 43.162283,
    "out:Klimatpaverkan": -51.76989,
    "out:Initial Cost/MSEK": 2.5303038517706176,
    "out:Running cost/(Apt SEK y)": 18257.147059,
    "out:Running Cost over RSP/MSEK": 4.8582,
    "out:LCP/MSEK": -1.059434,
    "out:ROI% old": 8.30431,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 58304.079603,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74359.5,
    "out:DH kr savings": 58304.079602769314,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2530303.8517706175,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 109.039,
    "out:Total Energy Use Post PV": 104.92842,
    "out:Primary Energy": 90.123038,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.863675,
    "out:CO2 Operational/m2": 5.223971,
    "out:Total CO2/m2": 20.087646,
    "out:Total CO2 (tons)": 39.61284,
    "out:Klimatpaverkan": -55.319332,
    "out:Initial Cost/MSEK": 2.5796038517706177,
    "out:Running cost/(Apt SEK y)": 17381.352941,
    "out:Running Cost over RSP/MSEK": 4.62529,
    "out:LCP/MSEK": -0.875824,
    "out:ROI% old": 9.435447,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 31.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 219,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 67573.589191,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83629,
    "out:DH kr savings": 67573.58919143342,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2579603.8517706175,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 111.039,
    "out:Total Energy Use Post PV": 106.92842,
    "out:Primary Energy": 91.679031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.531018,
    "out:CO2 Operational/m2": 5.943939,
    "out:Total CO2/m2": 21.474957,
    "out:Total CO2 (tons)": 42.348618,
    "out:Klimatpaverkan": -52.583555,
    "out:Initial Cost/MSEK": 2.6896798517706175,
    "out:Running cost/(Apt SEK y)": 17731.676471,
    "out:Running Cost over RSP/MSEK": 4.71845,
    "out:LCP/MSEK": -1.07906,
    "out:ROI% old": 8.554497,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 207,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63484.129502,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79539.54,
    "out:DH kr savings": 63484.12950222452,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2689679.8517706175,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 106.039,
    "out:Total Energy Use Post PV": 101.92842,
    "out:Primary Energy": 88.023038,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.531018,
    "out:CO2 Operational/m2": 4.144019,
    "out:Total CO2/m2": 19.675037,
    "out:Total CO2 (tons)": 38.799175,
    "out:Klimatpaverkan": -56.132997,
    "out:Initial Cost/MSEK": 2.7389798517706176,
    "out:Running cost/(Apt SEK y)": 16855.852941,
    "out:Running Cost over RSP/MSEK": 4.48554,
    "out:LCP/MSEK": -0.89545,
    "out:ROI% old": 9.615311,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 72753.639091,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88809.05,
    "out:DH kr savings": 72753.63909088862,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2738979.8517706175,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 114.039,
    "out:Total Energy Use Post PV": 109.350253,
    "out:Primary Energy": 92.73833,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.125478,
    "out:CO2 Operational/m2": -41.992595,
    "out:Total CO2/m2": -15.867117,
    "out:Total CO2 (tons)": -31.289957,
    "out:Klimatpaverkan": -126.222129,
    "out:Initial Cost/MSEK": 2.9042587372293673,
    "out:Running cost/(Apt SEK y)": 17353.882353,
    "out:Running Cost over RSP/MSEK": 4.62472,
    "out:LCP/MSEK": -1.199909,
    "out:ROI% old": 8.383501,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 26.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 219,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 58304.079603,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105070.41,
    "out:DH kr savings": 58304.079602769314,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2904258.7372293673,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 109.039,
    "out:Total Energy Use Post PV": 104.350253,
    "out:Primary Energy": 89.082337,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.125478,
    "out:CO2 Operational/m2": -43.792515,
    "out:Total CO2/m2": -17.667037,
    "out:Total CO2 (tons)": -34.839399,
    "out:Klimatpaverkan": -129.771571,
    "out:Initial Cost/MSEK": 2.9535587372293675,
    "out:Running cost/(Apt SEK y)": 16478.088235,
    "out:Running Cost over RSP/MSEK": 4.39181,
    "out:LCP/MSEK": -1.016299,
    "out:ROI% old": 9.370101,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 32.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 67573.589191,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114339.92,
    "out:DH kr savings": 67573.58919143342,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2953558.7372293673,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.224411,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 111.039,
    "out:Total Energy Use Post PV": 106.350253,
    "out:Primary Energy": 90.63833,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.792821,
    "out:CO2 Operational/m2": -43.072547,
    "out:Total CO2/m2": -16.279726,
    "out:Total CO2 (tons)": -32.103622,
    "out:Klimatpaverkan": -127.035794,
    "out:Initial Cost/MSEK": 3.063634737229367,
    "out:Running cost/(Apt SEK y)": 16828.411765,
    "out:Running Cost over RSP/MSEK": 4.48498,
    "out:LCP/MSEK": -1.219545,
    "out:ROI% old": 8.598983,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 63484.129502,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110250.46,
    "out:DH kr savings": 63484.12950222452,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 166090.547699,
    "out:% savings (space heating)": 23.021128,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 84.22,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3063634.737229367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.523848,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 106.039,
    "out:Total Energy Use Post PV": 101.350253,
    "out:Primary Energy": 86.982337,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.792821,
    "out:CO2 Operational/m2": -44.872467,
    "out:Total CO2/m2": -18.079646,
    "out:Total CO2 (tons)": -35.653064,
    "out:Klimatpaverkan": -130.585236,
    "out:Initial Cost/MSEK": 3.112934737229367,
    "out:Running cost/(Apt SEK y)": 15952.588235,
    "out:Running Cost over RSP/MSEK": 4.25207,
    "out:LCP/MSEK": -1.035935,
    "out:ROI% old": 9.531658,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.85,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 72753.639091,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119519.97,
    "out:DH kr savings": 72753.63909088862,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 156821.038111,
    "out:% savings (space heating)": 27.317317,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 79.52,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3112934.737229367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 106.039,
    "out:Total Energy Use Post PV": 106.039,
    "out:Primary Energy": 95.384556,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.423095,
    "out:CO2 Operational/m2": 35.42774,
    "out:Total CO2/m2": 40.850835,
    "out:Total CO2 (tons)": 80.557851,
    "out:Klimatpaverkan": -14.374321,
    "out:Initial Cost/MSEK": 2.1883323584281174,
    "out:Running cost/(Apt SEK y)": 17861.558824,
    "out:Running Cost over RSP/MSEK": 4.74576,
    "out:LCP/MSEK": -0.605022,
    "out:ROI% old": 10.336051,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 73233.718772,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55095.26,
    "out:DH kr savings": 73233.7187718417,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2188332.3584281174,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 102.039,
    "out:Total Energy Use Post PV": 102.039,
    "out:Primary Energy": 92.150996,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.423095,
    "out:CO2 Operational/m2": 33.987804,
    "out:Total CO2/m2": 39.410899,
    "out:Total CO2 (tons)": 77.718297,
    "out:Klimatpaverkan": -17.213875,
    "out:Initial Cost/MSEK": 2.2376323584281175,
    "out:Running cost/(Apt SEK y)": 17160.911765,
    "out:Running Cost over RSP/MSEK": 4.55944,
    "out:LCP/MSEK": -0.468002,
    "out:ROI% old": 11.297847,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 10,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 81432.180376,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63293.72,
    "out:DH kr savings": 81432.18037558928,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2237632.3584281174,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 103.039,
    "out:Total Energy Use Post PV": 103.039,
    "out:Primary Energy": 92.759556,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.090438,
    "out:CO2 Operational/m2": 34.347788,
    "out:Total CO2/m2": 40.438226,
    "out:Total CO2 (tons)": 79.744186,
    "out:Klimatpaverkan": -15.187986,
    "out:Initial Cost/MSEK": 2.3477083584281173,
    "out:Running cost/(Apt SEK y)": 17336.088235,
    "out:Running Cost over RSP/MSEK": 4.60602,
    "out:LCP/MSEK": -0.624658,
    "out:ROI% old": 10.484692,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 34,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 220,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 79708.781146,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61570.32,
    "out:DH kr savings": 79708.7811461607,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2347708.3584281174,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.758544,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 99.039,
    "out:Total Energy Use Post PV": 99.039,
    "out:Primary Energy": 89.525996,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.090438,
    "out:CO2 Operational/m2": 32.907852,
    "out:Total CO2/m2": 38.99829,
    "out:Total CO2 (tons)": 76.904632,
    "out:Klimatpaverkan": -18.02754,
    "out:Initial Cost/MSEK": 2.3970083584281174,
    "out:Running cost/(Apt SEK y)": 16635.441176,
    "out:Running Cost over RSP/MSEK": 4.41969,
    "out:LCP/MSEK": -0.487628,
    "out:ROI% old": 11.379542,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 39.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 10,
    "out:Return/kSEK/y": 244,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 87907.24275,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69768.79,
    "out:DH kr savings": 87907.24274990828,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2397008.3584281174,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 106.039,
    "out:Total Energy Use Post PV": 101.92842,
    "out:Primary Energy": 87.985512,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.684898,
    "out:CO2 Operational/m2": 4.144019,
    "out:Total CO2/m2": 20.828917,
    "out:Total CO2 (tons)": 41.074627,
    "out:Klimatpaverkan": -53.857546,
    "out:Initial Cost/MSEK": 2.5622872264243672,
    "out:Running cost/(Apt SEK y)": 16855.852941,
    "out:Running Cost over RSP/MSEK": 4.48554,
    "out:LCP/MSEK": -0.718757,
    "out:ROI% old": 10.278373,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 73233.718772,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89289.13,
    "out:DH kr savings": 73233.7187718417,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2562287.226424367,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 102.039,
    "out:Total Energy Use Post PV": 97.92842,
    "out:Primary Energy": 84.751952,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.684898,
    "out:CO2 Operational/m2": 2.704083,
    "out:Total CO2/m2": 19.388981,
    "out:Total CO2 (tons)": 38.235073,
    "out:Klimatpaverkan": -56.6971,
    "out:Initial Cost/MSEK": 2.6115872264243674,
    "out:Running cost/(Apt SEK y)": 16155.235294,
    "out:Running Cost over RSP/MSEK": 4.29922,
    "out:LCP/MSEK": -0.581737,
    "out:ROI% old": 11.103538,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 10,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 81432.180376,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 97487.6,
    "out:DH kr savings": 81432.18037558928,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2611587.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 103.039,
    "out:Total Energy Use Post PV": 98.92842,
    "out:Primary Energy": 85.360512,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.352241,
    "out:CO2 Operational/m2": 3.064067,
    "out:Total CO2/m2": 20.416308,
    "out:Total CO2 (tons)": 40.260962,
    "out:Klimatpaverkan": -54.671211,
    "out:Initial Cost/MSEK": 2.721663226424367,
    "out:Running cost/(Apt SEK y)": 16330.382353,
    "out:Running Cost over RSP/MSEK": 4.3458,
    "out:LCP/MSEK": -0.738393,
    "out:ROI% old": 10.409969,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 39.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 79708.781146,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95764.2,
    "out:DH kr savings": 79708.7811461607,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2721663.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.758544,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 99.039,
    "out:Total Energy Use Post PV": 94.92842,
    "out:Primary Energy": 82.126952,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.352241,
    "out:CO2 Operational/m2": 1.624131,
    "out:Total CO2/m2": 18.976372,
    "out:Total CO2 (tons)": 37.421408,
    "out:Klimatpaverkan": -57.510765,
    "out:Initial Cost/MSEK": 2.7709632264243673,
    "out:Running cost/(Apt SEK y)": 15629.735294,
    "out:Running Cost over RSP/MSEK": 4.15947,
    "out:LCP/MSEK": -0.601363,
    "out:ROI% old": 11.185383,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 45.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 10,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 87907.24275,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103962.66,
    "out:DH kr savings": 87907.24274990828,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2770963.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 106.039,
    "out:Total Energy Use Post PV": 101.350253,
    "out:Primary Energy": 86.944811,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.946701,
    "out:CO2 Operational/m2": -44.872467,
    "out:Total CO2/m2": -16.925766,
    "out:Total CO2 (tons)": -33.377612,
    "out:Klimatpaverkan": -128.309785,
    "out:Initial Cost/MSEK": 2.936242111883117,
    "out:Running cost/(Apt SEK y)": 15952.588235,
    "out:Running Cost over RSP/MSEK": 4.25207,
    "out:LCP/MSEK": -0.859242,
    "out:ROI% old": 10.105239,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 73233.718772,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120000.05,
    "out:DH kr savings": 73233.7187718417,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2936242.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.838496,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 102.039,
    "out:Total Energy Use Post PV": 97.350253,
    "out:Primary Energy": 83.711251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.946701,
    "out:CO2 Operational/m2": -46.312403,
    "out:Total CO2/m2": -18.365702,
    "out:Total CO2 (tons)": -36.217166,
    "out:Klimatpaverkan": -131.149339,
    "out:Initial Cost/MSEK": 2.9855421118831167,
    "out:Running cost/(Apt SEK y)": 15251.970588,
    "out:Running Cost over RSP/MSEK": 4.06574,
    "out:LCP/MSEK": -0.722212,
    "out:ROI% old": 10.829955,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 42.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 10,
    "out:Return/kSEK/y": 291,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 81432.180376,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128198.51,
    "out:DH kr savings": 81432.18037558928,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2985542.111883117,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 103.039,
    "out:Total Energy Use Post PV": 98.350253,
    "out:Primary Energy": 84.319811,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.614044,
    "out:CO2 Operational/m2": -45.952419,
    "out:Total CO2/m2": -17.338375,
    "out:Total CO2 (tons)": -34.191277,
    "out:Klimatpaverkan": -129.12345,
    "out:Initial Cost/MSEK": 3.095618111883118,
    "out:Running cost/(Apt SEK y)": 15427.117647,
    "out:Running Cost over RSP/MSEK": 4.11232,
    "out:LCP/MSEK": -0.878868,
    "out:ROI% old": 10.229898,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 79708.781146,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126475.11,
    "out:DH kr savings": 79708.7811461607,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3095618.111883118,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.758544,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 99.039,
    "out:Total Energy Use Post PV": 94.350253,
    "out:Primary Energy": 81.086251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.614044,
    "out:CO2 Operational/m2": -47.392355,
    "out:Total CO2/m2": -18.778311,
    "out:Total CO2 (tons)": -37.030831,
    "out:Klimatpaverkan": -131.963004,
    "out:Initial Cost/MSEK": 3.144918111883118,
    "out:Running cost/(Apt SEK y)": 14726.470588,
    "out:Running Cost over RSP/MSEK": 3.926,
    "out:LCP/MSEK": -0.741848,
    "out:ROI% old": 10.915887,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 10,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 87907.24275,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134673.57,
    "out:DH kr savings": 87907.24274990828,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3144918.111883118,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 102.039,
    "out:Total Energy Use Post PV": 102.039,
    "out:Primary Energy": 91.884556,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.423095,
    "out:CO2 Operational/m2": 33.987804,
    "out:Total CO2/m2": 39.410899,
    "out:Total CO2 (tons)": 77.718297,
    "out:Klimatpaverkan": -17.213875,
    "out:Initial Cost/MSEK": 2.2780689834281174,
    "out:Running cost/(Apt SEK y)": 17160.911765,
    "out:Running Cost over RSP/MSEK": 4.55944,
    "out:LCP/MSEK": -0.508439,
    "out:ROI% old": 11.097306,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 10,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 81867.135271,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63728.68,
    "out:DH kr savings": 81867.1352709337,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2278068.9834281174,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 98.039,
    "out:Total Energy Use Post PV": 98.039,
    "out:Primary Energy": 88.650996,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.423095,
    "out:CO2 Operational/m2": 32.547868,
    "out:Total CO2/m2": 37.970963,
    "out:Total CO2 (tons)": 74.878743,
    "out:Klimatpaverkan": -20.053429,
    "out:Initial Cost/MSEK": 2.3273689834281175,
    "out:Running cost/(Apt SEK y)": 16460.294118,
    "out:Running Cost over RSP/MSEK": 4.37311,
    "out:LCP/MSEK": -0.371409,
    "out:ROI% old": 12.005954,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 11,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 90065.596875,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71927.14,
    "out:DH kr savings": 90065.59687468127,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2327368.9834281174,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 99.039,
    "out:Total Energy Use Post PV": 99.039,
    "out:Primary Energy": 89.784556,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.090438,
    "out:CO2 Operational/m2": 32.907852,
    "out:Total CO2/m2": 38.99829,
    "out:Total CO2 (tons)": 76.904632,
    "out:Klimatpaverkan": -18.02754,
    "out:Initial Cost/MSEK": 2.4374449834281173,
    "out:Running cost/(Apt SEK y)": 16635.441176,
    "out:Running Cost over RSP/MSEK": 4.41969,
    "out:LCP/MSEK": -0.528065,
    "out:ROI% old": 11.190758,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 39.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 10,
    "out:Return/kSEK/y": 244,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 87047.18517,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68908.73,
    "out:DH kr savings": 87047.1851703889,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2437444.9834281174,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 95.039,
    "out:Total Energy Use Post PV": 95.039,
    "out:Primary Energy": 86.550996,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 6.090438,
    "out:CO2 Operational/m2": 31.467916,
    "out:Total CO2/m2": 37.558354,
    "out:Total CO2 (tons)": 74.065078,
    "out:Klimatpaverkan": -20.867094,
    "out:Initial Cost/MSEK": 2.4867449834281175,
    "out:Running cost/(Apt SEK y)": 15934.794118,
    "out:Running Cost over RSP/MSEK": 4.23336,
    "out:LCP/MSEK": -0.391035,
    "out:ROI% old": 12.039318,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 45.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 11,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 95245.646774,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77107.19,
    "out:DH kr savings": 95245.64677413648,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2486744.9834281174,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 102.039,
    "out:Total Energy Use Post PV": 97.92842,
    "out:Primary Energy": 84.485512,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.684898,
    "out:CO2 Operational/m2": 2.704083,
    "out:Total CO2/m2": 19.388981,
    "out:Total CO2 (tons)": 38.235073,
    "out:Klimatpaverkan": -56.6971,
    "out:Initial Cost/MSEK": 2.6520238514243673,
    "out:Running cost/(Apt SEK y)": 16155.235294,
    "out:Running Cost over RSP/MSEK": 4.29922,
    "out:LCP/MSEK": -0.622174,
    "out:ROI% old": 10.934237,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 10,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 81867.135271,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97922.55,
    "out:DH kr savings": 81867.1352709337,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2652023.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 98.039,
    "out:Total Energy Use Post PV": 93.92842,
    "out:Primary Energy": 81.251952,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.684898,
    "out:CO2 Operational/m2": 1.264147,
    "out:Total CO2/m2": 17.949045,
    "out:Total CO2 (tons)": 35.395519,
    "out:Klimatpaverkan": -59.536653,
    "out:Initial Cost/MSEK": 2.7013238514243674,
    "out:Running cost/(Apt SEK y)": 15454.588235,
    "out:Running Cost over RSP/MSEK": 4.11289,
    "out:LCP/MSEK": -0.485144,
    "out:ROI% old": 11.720074,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 11,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90065.596875,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106121.01,
    "out:DH kr savings": 90065.59687468127,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2701323.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 99.039,
    "out:Total Energy Use Post PV": 94.92842,
    "out:Primary Energy": 82.385512,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.352241,
    "out:CO2 Operational/m2": 1.624131,
    "out:Total CO2/m2": 18.976372,
    "out:Total CO2 (tons)": 37.421408,
    "out:Klimatpaverkan": -57.510765,
    "out:Initial Cost/MSEK": 2.811399851424367,
    "out:Running cost/(Apt SEK y)": 15629.735294,
    "out:Running Cost over RSP/MSEK": 4.15947,
    "out:LCP/MSEK": -0.6418,
    "out:ROI% old": 11.024503,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 45.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 10,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 87047.18517,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 103102.6,
    "out:DH kr savings": 87047.1851703889,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2811399.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 95.039,
    "out:Total Energy Use Post PV": 90.92842,
    "out:Primary Energy": 79.151952,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.352241,
    "out:CO2 Operational/m2": 0.184195,
    "out:Total CO2/m2": 17.536436,
    "out:Total CO2 (tons)": 34.581854,
    "out:Klimatpaverkan": -60.350318,
    "out:Initial Cost/MSEK": 2.8606998514243673,
    "out:Running cost/(Apt SEK y)": 14929.088235,
    "out:Running Cost over RSP/MSEK": 3.97314,
    "out:LCP/MSEK": -0.50477,
    "out:ROI% old": 11.765003,
    "out:Payback discounted": 10,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 11,
    "out:Return/kSEK/y": 302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95245.646774,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111301.06,
    "out:DH kr savings": 95245.64677413648,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2860699.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 102.039,
    "out:Total Energy Use Post PV": 97.350253,
    "out:Primary Energy": 83.444811,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.946701,
    "out:CO2 Operational/m2": -46.312403,
    "out:Total CO2/m2": -18.365702,
    "out:Total CO2 (tons)": -36.217166,
    "out:Klimatpaverkan": -131.149339,
    "out:Initial Cost/MSEK": 3.025978736883118,
    "out:Running cost/(Apt SEK y)": 15251.970588,
    "out:Running Cost over RSP/MSEK": 4.06574,
    "out:LCP/MSEK": -0.762649,
    "out:ROI% old": 10.685232,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 42.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 10,
    "out:Return/kSEK/y": 291,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 81867.135271,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128633.46,
    "out:DH kr savings": 81867.1352709337,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3025978.736883118,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.39856,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 98.039,
    "out:Total Energy Use Post PV": 93.350253,
    "out:Primary Energy": 80.211251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.946701,
    "out:CO2 Operational/m2": -47.752339,
    "out:Total CO2/m2": -19.805638,
    "out:Total CO2 (tons)": -39.05672,
    "out:Klimatpaverkan": -133.988893,
    "out:Initial Cost/MSEK": 3.075278736883118,
    "out:Running cost/(Apt SEK y)": 14551.323529,
    "out:Running Cost over RSP/MSEK": 3.87942,
    "out:LCP/MSEK": -0.625629,
    "out:ROI% old": 11.379456,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 10,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90065.596875,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136831.92,
    "out:DH kr savings": 90065.59687468127,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3075278.736883118,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.2756,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 99.039,
    "out:Total Energy Use Post PV": 94.350253,
    "out:Primary Energy": 81.344811,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.614044,
    "out:CO2 Operational/m2": -47.392355,
    "out:Total CO2/m2": -18.778311,
    "out:Total CO2 (tons)": -37.030831,
    "out:Klimatpaverkan": -131.963004,
    "out:Initial Cost/MSEK": 3.185354736883118,
    "out:Running cost/(Apt SEK y)": 14726.470588,
    "out:Running Cost over RSP/MSEK": 3.926,
    "out:LCP/MSEK": -0.782285,
    "out:ROI% old": 10.777315,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 10,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 87047.18517,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133813.51,
    "out:DH kr savings": 87047.1851703889,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 142527.492031,
    "out:% savings (space heating)": 33.942023,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 72.28,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3185354.736883118,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.118166,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 95.039,
    "out:Total Energy Use Post PV": 90.350253,
    "out:Primary Energy": 78.111251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.614044,
    "out:CO2 Operational/m2": -48.832291,
    "out:Total CO2/m2": -20.218247,
    "out:Total CO2 (tons)": -39.870385,
    "out:Klimatpaverkan": -134.802558,
    "out:Initial Cost/MSEK": 3.234654736883118,
    "out:Running cost/(Apt SEK y)": 14025.852941,
    "out:Running Cost over RSP/MSEK": 3.73967,
    "out:LCP/MSEK": -0.645255,
    "out:ROI% old": 11.435974,
    "out:Payback discounted": 11,
    "out:Atemp": 1972,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 10,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95245.646774,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142011.97,
    "out:DH kr savings": 95245.64677413648,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 134329.030427,
    "out:% savings (space heating)": 37.741808,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 68.12,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3234654.736883118,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 98.039,
    "out:Total Energy Use Post PV": 98.039,
    "out:Primary Energy": 89.079937,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.009963,
    "out:CO2 Operational/m2": 32.547868,
    "out:Total CO2/m2": 48.557831,
    "out:Total CO2 (tons)": 95.756048,
    "out:Klimatpaverkan": 0.823876,
    "out:Initial Cost/MSEK": 3.8444873587743666,
    "out:Running cost/(Apt SEK y)": 16460.294118,
    "out:Running Cost over RSP/MSEK": 4.37311,
    "out:LCP/MSEK": -1.888527,
    "out:ROI% old": 7.268143,
    "out:Payback discounted": 17,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 6,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 89218.630418,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71080.17,
    "out:DH kr savings": 89218.6304179187,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3844487.358774367,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 94.039,
    "out:Total Energy Use Post PV": 94.039,
    "out:Primary Energy": 86.13295,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.009963,
    "out:CO2 Operational/m2": 31.107932,
    "out:Total CO2/m2": 47.117895,
    "out:Total CO2 (tons)": 92.916494,
    "out:Klimatpaverkan": -2.015678,
    "out:Initial Cost/MSEK": 3.8937873587743668,
    "out:Running cost/(Apt SEK y)": 15759.647059,
    "out:Running Cost over RSP/MSEK": 4.18678,
    "out:LCP/MSEK": -1.751497,
    "out:ROI% old": 7.859737,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 96690.505129,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78552.05,
    "out:DH kr savings": 96690.50512866274,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3893787.358774367,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 95.039,
    "out:Total Energy Use Post PV": 95.039,
    "out:Primary Energy": 86.454937,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.677306,
    "out:CO2 Operational/m2": 31.467916,
    "out:Total CO2/m2": 48.145222,
    "out:Total CO2 (tons)": 94.942383,
    "out:Klimatpaverkan": 0.010211,
    "out:Initial Cost/MSEK": 4.0038633587743675,
    "out:Running cost/(Apt SEK y)": 15934.794118,
    "out:Running Cost over RSP/MSEK": 4.23336,
    "out:LCP/MSEK": -1.908153,
    "out:ROI% old": 7.477457,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 45.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 95693.692792,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77555.24,
    "out:DH kr savings": 95693.6927922377,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4003863.3587743677,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 91.039,
    "out:Primary Energy": 83.50795,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.677306,
    "out:CO2 Operational/m2": 30.02798,
    "out:Total CO2/m2": 46.705286,
    "out:Total CO2 (tons)": 92.102829,
    "out:Klimatpaverkan": -2.829343,
    "out:Initial Cost/MSEK": 4.053163358774368,
    "out:Running cost/(Apt SEK y)": 15234.176471,
    "out:Running Cost over RSP/MSEK": 4.04704,
    "out:LCP/MSEK": -1.771133,
    "out:ROI% old": 8.043206,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 103165.567503,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85027.11,
    "out:DH kr savings": 103165.56750298175,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4053163.358774368,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 98.039,
    "out:Total Energy Use Post PV": 93.92842,
    "out:Primary Energy": 81.680893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.271766,
    "out:CO2 Operational/m2": 1.264147,
    "out:Total CO2/m2": 28.535913,
    "out:Total CO2 (tons)": 56.272824,
    "out:Klimatpaverkan": -38.659349,
    "out:Initial Cost/MSEK": 4.218442226770618,
    "out:Running cost/(Apt SEK y)": 15454.588235,
    "out:Running Cost over RSP/MSEK": 4.11289,
    "out:LCP/MSEK": -2.002262,
    "out:ROI% old": 7.505072,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 89218.630418,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105274.05,
    "out:DH kr savings": 89218.6304179187,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4218442.226770618,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 94.039,
    "out:Total Energy Use Post PV": 89.92842,
    "out:Primary Energy": 78.733906,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.271766,
    "out:CO2 Operational/m2": -0.175789,
    "out:Total CO2/m2": 27.095977,
    "out:Total CO2 (tons)": 53.43327,
    "out:Klimatpaverkan": -41.498903,
    "out:Initial Cost/MSEK": 4.267742226770618,
    "out:Running cost/(Apt SEK y)": 14753.941176,
    "out:Running Cost over RSP/MSEK": 3.92656,
    "out:LCP/MSEK": -1.865232,
    "out:ROI% old": 8.042091,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 96690.505129,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112745.92,
    "out:DH kr savings": 96690.50512866274,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4267742.226770618,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 95.039,
    "out:Total Energy Use Post PV": 90.92842,
    "out:Primary Energy": 79.055893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.939109,
    "out:CO2 Operational/m2": 0.184195,
    "out:Total CO2/m2": 28.123304,
    "out:Total CO2 (tons)": 55.459159,
    "out:Klimatpaverkan": -39.473014,
    "out:Initial Cost/MSEK": 4.377818226770618,
    "out:Running cost/(Apt SEK y)": 14929.088235,
    "out:Running Cost over RSP/MSEK": 3.97314,
    "out:LCP/MSEK": -2.021888,
    "out:ROI% old": 7.68788,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 95693.692792,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111749.11,
    "out:DH kr savings": 95693.6927922377,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4377818.226770618,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 86.92842,
    "out:Primary Energy": 76.108906,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.939109,
    "out:CO2 Operational/m2": -1.255741,
    "out:Total CO2/m2": 26.683368,
    "out:Total CO2 (tons)": 52.619605,
    "out:Klimatpaverkan": -42.312568,
    "out:Initial Cost/MSEK": 4.427118226770618,
    "out:Running cost/(Apt SEK y)": 14228.470588,
    "out:Running Cost over RSP/MSEK": 3.78682,
    "out:LCP/MSEK": -1.884868,
    "out:ROI% old": 8.203498,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103165.567503,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119220.98,
    "out:DH kr savings": 103165.56750298175,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4427118.226770618,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 98.039,
    "out:Total Energy Use Post PV": 93.350253,
    "out:Primary Energy": 80.640192,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.533569,
    "out:CO2 Operational/m2": -47.752339,
    "out:Total CO2/m2": -9.21877,
    "out:Total CO2 (tons)": -18.179415,
    "out:Klimatpaverkan": -113.111588,
    "out:Initial Cost/MSEK": 4.592397112229368,
    "out:Running cost/(Apt SEK y)": 14551.323529,
    "out:Running Cost over RSP/MSEK": 3.87942,
    "out:LCP/MSEK": -2.142747,
    "out:ROI% old": 7.620203,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 89218.630418,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135984.96,
    "out:DH kr savings": 89218.6304179187,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4592397.112229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 94.039,
    "out:Total Energy Use Post PV": 89.350253,
    "out:Primary Energy": 77.693205,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.533569,
    "out:CO2 Operational/m2": -49.192275,
    "out:Total CO2/m2": -10.658706,
    "out:Total CO2 (tons)": -21.018969,
    "out:Klimatpaverkan": -115.951142,
    "out:Initial Cost/MSEK": 4.641697112229368,
    "out:Running cost/(Apt SEK y)": 13850.676471,
    "out:Running Cost over RSP/MSEK": 3.69309,
    "out:LCP/MSEK": -2.005717,
    "out:ROI% old": 8.112735,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 96690.505129,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143456.83,
    "out:DH kr savings": 96690.50512866274,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4641697.112229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 95.039,
    "out:Total Energy Use Post PV": 90.350253,
    "out:Primary Energy": 78.015192,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.200912,
    "out:CO2 Operational/m2": -48.832291,
    "out:Total CO2/m2": -9.631379,
    "out:Total CO2 (tons)": -18.99308,
    "out:Klimatpaverkan": -113.925253,
    "out:Initial Cost/MSEK": 4.751773112229368,
    "out:Running cost/(Apt SEK y)": 14025.852941,
    "out:Running Cost over RSP/MSEK": 3.73967,
    "out:LCP/MSEK": -2.162373,
    "out:ROI% old": 7.784763,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 95693.692792,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142460.02,
    "out:DH kr savings": 95693.6927922377,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4751773.112229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 86.350253,
    "out:Primary Energy": 75.068205,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.200912,
    "out:CO2 Operational/m2": -50.272227,
    "out:Total CO2/m2": -11.071315,
    "out:Total CO2 (tons)": -21.832634,
    "out:Klimatpaverkan": -116.764807,
    "out:Initial Cost/MSEK": 4.801073112229368,
    "out:Running cost/(Apt SEK y)": 13325.205882,
    "out:Running Cost over RSP/MSEK": 3.55334,
    "out:LCP/MSEK": -2.025343,
    "out:ROI% old": 8.259255,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103165.567503,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149931.89,
    "out:DH kr savings": 103165.56750298175,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4801073.112229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 94.039,
    "out:Total Energy Use Post PV": 94.039,
    "out:Primary Energy": 85.579937,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.009963,
    "out:CO2 Operational/m2": 31.107932,
    "out:Total CO2/m2": 47.117895,
    "out:Total CO2 (tons)": 92.916494,
    "out:Klimatpaverkan": -2.015678,
    "out:Initial Cost/MSEK": 3.9342239837743676,
    "out:Running cost/(Apt SEK y)": 15759.647059,
    "out:Running Cost over RSP/MSEK": 4.18678,
    "out:LCP/MSEK": -1.791934,
    "out:ROI% old": 7.778953,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 97852.046917,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79713.59,
    "out:DH kr savings": 97852.0469170107,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3934223.9837743677,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 90.039,
    "out:Total Energy Use Post PV": 90.039,
    "out:Primary Energy": 82.63295,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.009963,
    "out:CO2 Operational/m2": 29.667996,
    "out:Total CO2/m2": 45.677959,
    "out:Total CO2 (tons)": 90.07694,
    "out:Klimatpaverkan": -4.855232,
    "out:Initial Cost/MSEK": 3.9835239837743677,
    "out:Running cost/(Apt SEK y)": 15059,
    "out:Running Cost over RSP/MSEK": 4.00045,
    "out:LCP/MSEK": -1.654904,
    "out:ROI% old": 8.350897,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 105323.921628,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 87185.46,
    "out:DH kr savings": 105323.92162775474,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3983523.9837743677,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 91.039,
    "out:Primary Energy": 83.479937,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.677306,
    "out:CO2 Operational/m2": 30.02798,
    "out:Total CO2/m2": 46.705286,
    "out:Total CO2 (tons)": 92.102829,
    "out:Klimatpaverkan": -2.829343,
    "out:Initial Cost/MSEK": 4.093599983774368,
    "out:Running cost/(Apt SEK y)": 15234.176471,
    "out:Running Cost over RSP/MSEK": 4.04704,
    "out:LCP/MSEK": -1.81157,
    "out:ROI% old": 7.963755,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 103032.096816,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84893.64,
    "out:DH kr savings": 103032.0968164659,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4093599.983774368,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 87.039,
    "out:Primary Energy": 80.53295,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.677306,
    "out:CO2 Operational/m2": 28.588044,
    "out:Total CO2/m2": 45.26535,
    "out:Total CO2 (tons)": 89.263275,
    "out:Klimatpaverkan": -5.668897,
    "out:Initial Cost/MSEK": 4.142899983774368,
    "out:Running cost/(Apt SEK y)": 14533.529412,
    "out:Running Cost over RSP/MSEK": 3.86071,
    "out:LCP/MSEK": -1.67454,
    "out:ROI% old": 8.511498,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 110503.971527,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92365.51,
    "out:DH kr savings": 110503.97152720994,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4142899.9837743677,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 94.039,
    "out:Total Energy Use Post PV": 89.92842,
    "out:Primary Energy": 78.180893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.271766,
    "out:CO2 Operational/m2": -0.175789,
    "out:Total CO2/m2": 27.095977,
    "out:Total CO2 (tons)": 53.43327,
    "out:Klimatpaverkan": -41.498903,
    "out:Initial Cost/MSEK": 4.308178851770618,
    "out:Running cost/(Apt SEK y)": 14753.941176,
    "out:Running Cost over RSP/MSEK": 3.92656,
    "out:LCP/MSEK": -1.905669,
    "out:ROI% old": 7.966608,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97852.046917,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113907.46,
    "out:DH kr savings": 97852.0469170107,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4308178.851770618,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 90.039,
    "out:Total Energy Use Post PV": 85.92842,
    "out:Primary Energy": 75.233906,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.271766,
    "out:CO2 Operational/m2": -1.615725,
    "out:Total CO2/m2": 25.656041,
    "out:Total CO2 (tons)": 50.593716,
    "out:Klimatpaverkan": -44.338456,
    "out:Initial Cost/MSEK": 4.357478851770618,
    "out:Running cost/(Apt SEK y)": 14053.294118,
    "out:Running Cost over RSP/MSEK": 3.74024,
    "out:LCP/MSEK": -1.768649,
    "out:ROI% old": 8.487313,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105323.921628,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121379.34,
    "out:DH kr savings": 105323.92162775474,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4357478.851770618,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 86.92842,
    "out:Primary Energy": 76.080893,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.939109,
    "out:CO2 Operational/m2": -1.255741,
    "out:Total CO2/m2": 26.683368,
    "out:Total CO2 (tons)": 52.619605,
    "out:Klimatpaverkan": -42.312568,
    "out:Initial Cost/MSEK": 4.467554851770617,
    "out:Running cost/(Apt SEK y)": 14228.470588,
    "out:Running Cost over RSP/MSEK": 3.78682,
    "out:LCP/MSEK": -1.925305,
    "out:ROI% old": 8.129247,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103032.096816,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119087.51,
    "out:DH kr savings": 103032.0968164659,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4467554.851770617,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 82.92842,
    "out:Primary Energy": 73.133906,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.939109,
    "out:CO2 Operational/m2": -2.695677,
    "out:Total CO2/m2": 25.243432,
    "out:Total CO2 (tons)": 49.780051,
    "out:Klimatpaverkan": -45.152121,
    "out:Initial Cost/MSEK": 4.516854851770617,
    "out:Running cost/(Apt SEK y)": 13527.823529,
    "out:Running Cost over RSP/MSEK": 3.60049,
    "out:LCP/MSEK": -1.788275,
    "out:ROI% old": 8.629835,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110503.971527,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126559.39,
    "out:DH kr savings": 110503.97152720994,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4516854.851770617,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 94.039,
    "out:Total Energy Use Post PV": 89.350253,
    "out:Primary Energy": 77.140192,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.533569,
    "out:CO2 Operational/m2": -49.192275,
    "out:Total CO2/m2": -10.658706,
    "out:Total CO2 (tons)": -21.018969,
    "out:Klimatpaverkan": -115.951142,
    "out:Initial Cost/MSEK": 4.682133737229368,
    "out:Running cost/(Apt SEK y)": 13850.676471,
    "out:Running Cost over RSP/MSEK": 3.69309,
    "out:LCP/MSEK": -2.046154,
    "out:ROI% old": 8.04267,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97852.046917,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144618.37,
    "out:DH kr savings": 97852.0469170107,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4682133.737229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.518688,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 90.039,
    "out:Total Energy Use Post PV": 85.350253,
    "out:Primary Energy": 74.193205,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.533569,
    "out:CO2 Operational/m2": -50.632211,
    "out:Total CO2/m2": -12.098642,
    "out:Total CO2 (tons)": -23.858523,
    "out:Klimatpaverkan": -118.790696,
    "out:Initial Cost/MSEK": 4.731433737229367,
    "out:Running cost/(Apt SEK y)": 13150.029412,
    "out:Running Cost over RSP/MSEK": 3.50676,
    "out:LCP/MSEK": -1.909124,
    "out:ROI% old": 8.521458,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105323.921628,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152090.25,
    "out:DH kr savings": 105323.92162775474,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4731433.737229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.169662,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 86.350253,
    "out:Primary Energy": 75.040192,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.200912,
    "out:CO2 Operational/m2": -50.272227,
    "out:Total CO2/m2": -11.071315,
    "out:Total CO2 (tons)": -21.832634,
    "out:Klimatpaverkan": -116.764807,
    "out:Initial Cost/MSEK": 4.841509737229367,
    "out:Running cost/(Apt SEK y)": 13325.205882,
    "out:Running Cost over RSP/MSEK": 3.55334,
    "out:LCP/MSEK": -2.06578,
    "out:ROI% old": 8.190273,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103032.096816,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149798.42,
    "out:DH kr savings": 103032.0968164659,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 126542.580385,
    "out:% savings (space heating)": 41.350635,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 64.17,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4841509.737229367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.380679,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 82.350253,
    "out:Primary Energy": 72.093205,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.200912,
    "out:CO2 Operational/m2": -51.712163,
    "out:Total CO2/m2": -12.511251,
    "out:Total CO2 (tons)": -24.672188,
    "out:Klimatpaverkan": -119.604361,
    "out:Initial Cost/MSEK": 4.890809737229366,
    "out:Running cost/(Apt SEK y)": 12624.558824,
    "out:Running Cost over RSP/MSEK": 3.36702,
    "out:LCP/MSEK": -1.92876,
    "out:ROI% old": 8.651942,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110503.971527,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157270.3,
    "out:DH kr savings": 110503.97152720994,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 119070.705674,
    "out:% savings (space heating)": 44.813665,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 60.38,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4890809.737229367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 90.039,
    "out:Total Energy Use Post PV": 90.039,
    "out:Primary Energy": 83.100021,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.093815,
    "out:CO2 Operational/m2": 29.667996,
    "out:Total CO2/m2": 46.761811,
    "out:Total CO2 (tons)": 92.214297,
    "out:Klimatpaverkan": -2.717876,
    "out:Initial Cost/MSEK": 3.9662073584281177,
    "out:Running cost/(Apt SEK y)": 15059,
    "out:Running Cost over RSP/MSEK": 4.00045,
    "out:LCP/MSEK": -1.637587,
    "out:ROI% old": 8.387358,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 104380.280725,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86241.82,
    "out:DH kr savings": 104380.28072480351,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3966207.358428118,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 87.039,
    "out:Primary Energy": 80.424849,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.093815,
    "out:CO2 Operational/m2": 28.588044,
    "out:Total CO2/m2": 45.681859,
    "out:Total CO2 (tons)": 90.084631,
    "out:Klimatpaverkan": -4.847541,
    "out:Initial Cost/MSEK": 4.015507358428118,
    "out:Running cost/(Apt SEK y)": 14533.529412,
    "out:Running Cost over RSP/MSEK": 3.86071,
    "out:LCP/MSEK": -1.547147,
    "out:ROI% old": 8.781527,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 111162.989513,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93024.53,
    "out:DH kr savings": 111162.98951250734,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4015507.358428118,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 87.039,
    "out:Primary Energy": 80.475021,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.761158,
    "out:CO2 Operational/m2": 28.588044,
    "out:Total CO2/m2": 46.349202,
    "out:Total CO2 (tons)": 91.400632,
    "out:Klimatpaverkan": -3.531541,
    "out:Initial Cost/MSEK": 4.125583358428117,
    "out:Running cost/(Apt SEK y)": 14533.529412,
    "out:Running Cost over RSP/MSEK": 3.86071,
    "out:LCP/MSEK": -1.657223,
    "out:ROI% old": 8.547224,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 110855.343099,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92716.89,
    "out:DH kr savings": 110855.34309912252,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4125583.3584281174,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 84.039,
    "out:Primary Energy": 77.799849,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.761158,
    "out:CO2 Operational/m2": 27.508092,
    "out:Total CO2/m2": 45.26925,
    "out:Total CO2 (tons)": 89.270966,
    "out:Klimatpaverkan": -5.661206,
    "out:Initial Cost/MSEK": 4.174883358428118,
    "out:Running cost/(Apt SEK y)": 14008.058824,
    "out:Running Cost over RSP/MSEK": 3.72096,
    "out:LCP/MSEK": -1.566773,
    "out:ROI% old": 8.924492,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 64.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 117638.051887,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99499.59,
    "out:DH kr savings": 117638.05188682635,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4174883.358428118,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 90.039,
    "out:Total Energy Use Post PV": 85.92842,
    "out:Primary Energy": 75.700977,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.355618,
    "out:CO2 Operational/m2": -1.615725,
    "out:Total CO2/m2": 26.739893,
    "out:Total CO2 (tons)": 52.731072,
    "out:Klimatpaverkan": -42.2011,
    "out:Initial Cost/MSEK": 4.3401622264243676,
    "out:Running cost/(Apt SEK y)": 14053.294118,
    "out:Running Cost over RSP/MSEK": 3.74024,
    "out:LCP/MSEK": -1.751332,
    "out:ROI% old": 8.521176,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 104380.280725,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120435.7,
    "out:DH kr savings": 104380.28072480351,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4340162.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 82.92842,
    "out:Primary Energy": 73.025805,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.355618,
    "out:CO2 Operational/m2": -2.695677,
    "out:Total CO2/m2": 25.659941,
    "out:Total CO2 (tons)": 50.601407,
    "out:Klimatpaverkan": -44.330766,
    "out:Initial Cost/MSEK": 4.389462226424367,
    "out:Running cost/(Apt SEK y)": 13527.823529,
    "out:Running Cost over RSP/MSEK": 3.60049,
    "out:LCP/MSEK": -1.660882,
    "out:ROI% old": 8.880294,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 111162.989513,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127218.4,
    "out:DH kr savings": 111162.98951250734,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4389462.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 82.92842,
    "out:Primary Energy": 73.075977,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.022961,
    "out:CO2 Operational/m2": -2.695677,
    "out:Total CO2/m2": 26.327284,
    "out:Total CO2 (tons)": 51.917407,
    "out:Klimatpaverkan": -43.014765,
    "out:Initial Cost/MSEK": 4.4995382264243675,
    "out:Running cost/(Apt SEK y)": 13527.823529,
    "out:Running Cost over RSP/MSEK": 3.60049,
    "out:LCP/MSEK": -1.770958,
    "out:ROI% old": 8.663048,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110855.343099,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126910.76,
    "out:DH kr savings": 110855.34309912252,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4499538.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 79.92842,
    "out:Primary Energy": 70.400805,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.022961,
    "out:CO2 Operational/m2": -3.775629,
    "out:Total CO2/m2": 25.247332,
    "out:Total CO2 (tons)": 49.787742,
    "out:Klimatpaverkan": -45.144431,
    "out:Initial Cost/MSEK": 4.548838226424367,
    "out:Running cost/(Apt SEK y)": 13002.352941,
    "out:Running Cost over RSP/MSEK": 3.46074,
    "out:LCP/MSEK": -1.680508,
    "out:ROI% old": 9.008046,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 367,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117638.051887,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133693.47,
    "out:DH kr savings": 117638.05188682635,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4548838.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 90.039,
    "out:Total Energy Use Post PV": 85.350253,
    "out:Primary Energy": 74.660276,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.617421,
    "out:CO2 Operational/m2": -50.632211,
    "out:Total CO2/m2": -11.01479,
    "out:Total CO2 (tons)": -21.721167,
    "out:Klimatpaverkan": -116.653339,
    "out:Initial Cost/MSEK": 4.714117111883117,
    "out:Running cost/(Apt SEK y)": 13150.029412,
    "out:Running Cost over RSP/MSEK": 3.50676,
    "out:LCP/MSEK": -1.891807,
    "out:ROI% old": 8.55276,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 104380.280725,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151146.61,
    "out:DH kr savings": 104380.28072480351,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4714117.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 82.350253,
    "out:Primary Energy": 71.985104,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.617421,
    "out:CO2 Operational/m2": -51.712163,
    "out:Total CO2/m2": -12.094742,
    "out:Total CO2 (tons)": -23.850833,
    "out:Klimatpaverkan": -118.783005,
    "out:Initial Cost/MSEK": 4.763417111883117,
    "out:Running cost/(Apt SEK y)": 12624.558824,
    "out:Running Cost over RSP/MSEK": 3.36702,
    "out:LCP/MSEK": -1.801367,
    "out:ROI% old": 8.883329,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 111162.989513,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157929.32,
    "out:DH kr savings": 111162.98951250734,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4763417.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 82.350253,
    "out:Primary Energy": 72.035276,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.284764,
    "out:CO2 Operational/m2": -51.712163,
    "out:Total CO2/m2": -11.427399,
    "out:Total CO2 (tons)": -22.534832,
    "out:Klimatpaverkan": -117.467004,
    "out:Initial Cost/MSEK": 4.873493111883117,
    "out:Running cost/(Apt SEK y)": 12624.558824,
    "out:Running Cost over RSP/MSEK": 3.36702,
    "out:LCP/MSEK": -1.911443,
    "out:ROI% old": 8.682684,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110855.343099,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157621.67,
    "out:DH kr savings": 110855.34309912252,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4873493.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 79.350253,
    "out:Primary Energy": 69.360104,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.284764,
    "out:CO2 Operational/m2": -52.792115,
    "out:Total CO2/m2": -12.507351,
    "out:Total CO2 (tons)": -24.664498,
    "out:Klimatpaverkan": -119.59667,
    "out:Initial Cost/MSEK": 4.9227931118831165,
    "out:Running cost/(Apt SEK y)": 12099.088235,
    "out:Running Cost over RSP/MSEK": 3.22727,
    "out:LCP/MSEK": -1.820993,
    "out:ROI% old": 9.001278,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117638.051887,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164404.38,
    "out:DH kr savings": 117638.05188682635,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4922793.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 86.039,
    "out:Total Energy Use Post PV": 86.039,
    "out:Primary Energy": 79.600021,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.093815,
    "out:CO2 Operational/m2": 28.22806,
    "out:Total CO2/m2": 45.321875,
    "out:Total CO2 (tons)": 89.374743,
    "out:Klimatpaverkan": -5.55743,
    "out:Initial Cost/MSEK": 4.055943983428118,
    "out:Running cost/(Apt SEK y)": 14358.352941,
    "out:Running Cost over RSP/MSEK": 3.81413,
    "out:LCP/MSEK": -1.541004,
    "out:ROI% old": 8.85804,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 113013.697224,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94875.24,
    "out:DH kr savings": 113013.6972238955,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4055943.983428118,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 83.039,
    "out:Primary Energy": 76.924849,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.093815,
    "out:CO2 Operational/m2": 27.148108,
    "out:Total CO2/m2": 44.241923,
    "out:Total CO2 (tons)": 87.245077,
    "out:Klimatpaverkan": -7.687095,
    "out:Initial Cost/MSEK": 4.105243983428117,
    "out:Running cost/(Apt SEK y)": 13832.882353,
    "out:Running Cost over RSP/MSEK": 3.67438,
    "out:LCP/MSEK": -1.450554,
    "out:ROI% old": 9.237975,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 119796.406012,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101657.95,
    "out:DH kr savings": 119796.40601159935,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4105243.9834281174,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 83.039,
    "out:Primary Energy": 77.500021,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.761158,
    "out:CO2 Operational/m2": 27.148108,
    "out:Total CO2/m2": 44.909266,
    "out:Total CO2 (tons)": 88.561078,
    "out:Klimatpaverkan": -6.371095,
    "out:Initial Cost/MSEK": 4.215319983428117,
    "out:Running cost/(Apt SEK y)": 13832.882353,
    "out:Running Cost over RSP/MSEK": 3.67438,
    "out:LCP/MSEK": -1.56063,
    "out:ROI% old": 8.996741,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 118193.747123,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100055.29,
    "out:DH kr savings": 118193.74712335071,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4215319.983428117,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 80.039,
    "out:Total Energy Use Post PV": 80.039,
    "out:Primary Energy": 74.824849,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 17.761158,
    "out:CO2 Operational/m2": 26.068156,
    "out:Total CO2/m2": 43.829314,
    "out:Total CO2 (tons)": 86.431412,
    "out:Klimatpaverkan": -8.50076,
    "out:Initial Cost/MSEK": 4.264619983428117,
    "out:Running cost/(Apt SEK y)": 13307.411765,
    "out:Running Cost over RSP/MSEK": 3.53464,
    "out:LCP/MSEK": -1.47019,
    "out:ROI% old": 9.360841,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124976.455911,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106838,
    "out:DH kr savings": 124976.45591105455,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4264619.983428117,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.478912,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 86.039,
    "out:Total Energy Use Post PV": 81.92842,
    "out:Primary Energy": 72.200977,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.355618,
    "out:CO2 Operational/m2": -3.055661,
    "out:Total CO2/m2": 25.299957,
    "out:Total CO2 (tons)": 49.891518,
    "out:Klimatpaverkan": -45.040654,
    "out:Initial Cost/MSEK": 4.429898851424367,
    "out:Running cost/(Apt SEK y)": 13352.676471,
    "out:Running Cost over RSP/MSEK": 3.55391,
    "out:LCP/MSEK": -1.654739,
    "out:ROI% old": 8.949447,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113013.697224,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129069.11,
    "out:DH kr savings": 113013.6972238955,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4429898.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 78.92842,
    "out:Primary Energy": 69.525805,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.355618,
    "out:CO2 Operational/m2": -4.135613,
    "out:Total CO2/m2": 24.220005,
    "out:Total CO2 (tons)": 47.761853,
    "out:Klimatpaverkan": -47.17032,
    "out:Initial Cost/MSEK": 4.479198851424367,
    "out:Running cost/(Apt SEK y)": 12827.176471,
    "out:Running Cost over RSP/MSEK": 3.41416,
    "out:LCP/MSEK": -1.564289,
    "out:ROI% old": 9.296656,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119796.406012,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135851.82,
    "out:DH kr savings": 119796.40601159935,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4479198.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 78.92842,
    "out:Primary Energy": 70.100977,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.022961,
    "out:CO2 Operational/m2": -4.135613,
    "out:Total CO2/m2": 24.887348,
    "out:Total CO2 (tons)": 49.077853,
    "out:Klimatpaverkan": -45.854319,
    "out:Initial Cost/MSEK": 4.589274851424368,
    "out:Running cost/(Apt SEK y)": 12827.176471,
    "out:Running Cost over RSP/MSEK": 3.41416,
    "out:LCP/MSEK": -1.674365,
    "out:ROI% old": 9.073671,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118193.747123,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134249.16,
    "out:DH kr savings": 118193.74712335071,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4589274.851424368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 80.039,
    "out:Total Energy Use Post PV": 75.92842,
    "out:Primary Energy": 67.425805,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.022961,
    "out:CO2 Operational/m2": -5.215565,
    "out:Total CO2/m2": 23.807396,
    "out:Total CO2 (tons)": 46.948188,
    "out:Klimatpaverkan": -47.983985,
    "out:Initial Cost/MSEK": 4.6385748514243685,
    "out:Running cost/(Apt SEK y)": 12301.705882,
    "out:Running Cost over RSP/MSEK": 3.27442,
    "out:LCP/MSEK": -1.583925,
    "out:ROI% old": 9.4076,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124976.455911,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141031.87,
    "out:DH kr savings": 124976.45591105455,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4638574.851424368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.478912,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 86.039,
    "out:Total Energy Use Post PV": 81.350253,
    "out:Primary Energy": 71.160276,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.617421,
    "out:CO2 Operational/m2": -52.072147,
    "out:Total CO2/m2": -12.454726,
    "out:Total CO2 (tons)": -24.560721,
    "out:Klimatpaverkan": -119.492893,
    "out:Initial Cost/MSEK": 4.8038537368831165,
    "out:Running cost/(Apt SEK y)": 12449.411765,
    "out:Running Cost over RSP/MSEK": 3.32043,
    "out:LCP/MSEK": -1.795214,
    "out:ROI% old": 8.947102,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 70.4,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113013.697224,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159780.02,
    "out:DH kr savings": 113013.6972238955,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4803853.736883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 78.350253,
    "out:Primary Energy": 68.485104,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.617421,
    "out:CO2 Operational/m2": -53.152099,
    "out:Total CO2/m2": -13.534678,
    "out:Total CO2 (tons)": -26.690387,
    "out:Klimatpaverkan": -121.622559,
    "out:Initial Cost/MSEK": 4.853153736883117,
    "out:Running cost/(Apt SEK y)": 11923.911765,
    "out:Running Cost over RSP/MSEK": 3.18069,
    "out:LCP/MSEK": -1.704774,
    "out:ROI% old": 9.267553,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 76.9,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119796.406012,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166562.73,
    "out:DH kr savings": 119796.40601159935,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4853153.736883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.481199,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 78.350253,
    "out:Primary Energy": 69.060276,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.284764,
    "out:CO2 Operational/m2": -53.152099,
    "out:Total CO2/m2": -12.867335,
    "out:Total CO2 (tons)": -25.374386,
    "out:Klimatpaverkan": -120.306558,
    "out:Initial Cost/MSEK": 4.963229736883117,
    "out:Running cost/(Apt SEK y)": 11923.911765,
    "out:Running Cost over RSP/MSEK": 3.18069,
    "out:LCP/MSEK": -1.81485,
    "out:ROI% old": 9.062014,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 76.9,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118193.747123,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164960.07,
    "out:DH kr savings": 118193.74712335071,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 111380.930078,
    "out:% savings (space heating)": 48.377686,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 56.48,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4963229.736883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.041691,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 80.039,
    "out:Total Energy Use Post PV": 75.350253,
    "out:Primary Energy": 66.385104,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.284764,
    "out:CO2 Operational/m2": -54.232051,
    "out:Total CO2/m2": -13.947287,
    "out:Total CO2 (tons)": -27.504052,
    "out:Klimatpaverkan": -122.436224,
    "out:Initial Cost/MSEK": 5.012529736883117,
    "out:Running cost/(Apt SEK y)": 11398.441176,
    "out:Running Cost over RSP/MSEK": 3.04094,
    "out:LCP/MSEK": -1.7244,
    "out:ROI% old": 9.371173,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 84,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 422,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.38,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124976.455911,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171742.78,
    "out:DH kr savings": 124976.45591105455,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 104598.22129,
    "out:% savings (space heating)": 51.521304,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.04,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5012529.736883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.478912,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 91.039,
    "out:Primary Energy": 83.639574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.850355,
    "out:CO2 Operational/m2": 30.02798,
    "out:Total CO2/m2": 48.878335,
    "out:Total CO2 (tons)": 96.388082,
    "out:Klimatpaverkan": 1.45591,
    "out:Initial Cost/MSEK": 4.250362358774367,
    "out:Running cost/(Apt SEK y)": 15234.176471,
    "out:Running Cost over RSP/MSEK": 4.04704,
    "out:LCP/MSEK": -1.968332,
    "out:ROI% old": 7.670035,
    "out:Payback discounted": 16,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 103012.281173,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84873.82,
    "out:DH kr savings": 103012.28117332036,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4250362.358774367,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 88.039,
    "out:Total Energy Use Post PV": 88.039,
    "out:Primary Energy": 80.939877,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.850355,
    "out:CO2 Operational/m2": 28.948028,
    "out:Total CO2/m2": 47.798383,
    "out:Total CO2 (tons)": 94.258417,
    "out:Klimatpaverkan": -0.673756,
    "out:Initial Cost/MSEK": 4.299662358774367,
    "out:Running cost/(Apt SEK y)": 14708.676471,
    "out:Running Cost over RSP/MSEK": 3.90729,
    "out:LCP/MSEK": -1.877882,
    "out:ROI% old": 8.046413,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 56.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 109857.171759,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91718.71,
    "out:DH kr savings": 109857.17175881886,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4299662.358774367,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 88.039,
    "out:Total Energy Use Post PV": 88.039,
    "out:Primary Energy": 81.014574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.517698,
    "out:CO2 Operational/m2": 28.948028,
    "out:Total CO2/m2": 48.465726,
    "out:Total CO2 (tons)": 95.574417,
    "out:Klimatpaverkan": 0.642245,
    "out:Initial Cost/MSEK": 4.4097383587743675,
    "out:Running cost/(Apt SEK y)": 14708.676471,
    "out:Running Cost over RSP/MSEK": 3.90729,
    "out:LCP/MSEK": -1.987958,
    "out:ROI% old": 7.845558,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 56.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 109487.343548,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91348.89,
    "out:DH kr savings": 109487.34354763936,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4409738.358774368,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 84.039,
    "out:Primary Energy": 78.314877,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.517698,
    "out:CO2 Operational/m2": 27.508092,
    "out:Total CO2/m2": 47.02579,
    "out:Total CO2 (tons)": 92.734863,
    "out:Klimatpaverkan": -2.197309,
    "out:Initial Cost/MSEK": 4.459038358774368,
    "out:Running cost/(Apt SEK y)": 14008.058824,
    "out:Running Cost over RSP/MSEK": 3.72096,
    "out:LCP/MSEK": -1.850928,
    "out:ROI% old": 8.355773,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 64.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 116332.234133,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98193.78,
    "out:DH kr savings": 116332.23413313787,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4459038.358774368,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 86.92842,
    "out:Primary Energy": 76.24053,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.112158,
    "out:CO2 Operational/m2": -1.255741,
    "out:Total CO2/m2": 28.856417,
    "out:Total CO2 (tons)": 56.904858,
    "out:Klimatpaverkan": -38.027315,
    "out:Initial Cost/MSEK": 4.624317226770618,
    "out:Running cost/(Apt SEK y)": 14228.470588,
    "out:Running Cost over RSP/MSEK": 3.78682,
    "out:LCP/MSEK": -2.082067,
    "out:ROI% old": 7.853669,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103012.281173,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119067.7,
    "out:DH kr savings": 103012.28117332036,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4624317.226770618,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 88.039,
    "out:Total Energy Use Post PV": 83.92842,
    "out:Primary Energy": 73.540833,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.112158,
    "out:CO2 Operational/m2": -2.335693,
    "out:Total CO2/m2": 27.776465,
    "out:Total CO2 (tons)": 54.775192,
    "out:Klimatpaverkan": -40.15698,
    "out:Initial Cost/MSEK": 4.673617226770618,
    "out:Running cost/(Apt SEK y)": 13702.970588,
    "out:Running Cost over RSP/MSEK": 3.64707,
    "out:LCP/MSEK": -1.991617,
    "out:ROI% old": 8.197994,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 64.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109857.171759,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125912.59,
    "out:DH kr savings": 109857.17175881886,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4673617.226770618,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 88.039,
    "out:Total Energy Use Post PV": 83.92842,
    "out:Primary Energy": 73.61553,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.779501,
    "out:CO2 Operational/m2": -2.335693,
    "out:Total CO2/m2": 28.443808,
    "out:Total CO2 (tons)": 56.091193,
    "out:Klimatpaverkan": -38.84098,
    "out:Initial Cost/MSEK": 4.783693226770618,
    "out:Running cost/(Apt SEK y)": 13702.970588,
    "out:Running Cost over RSP/MSEK": 3.64707,
    "out:LCP/MSEK": -2.101693,
    "out:ROI% old": 8.009353,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 64.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109487.343548,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125542.76,
    "out:DH kr savings": 109487.34354763936,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4783693.226770618,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 79.92842,
    "out:Primary Energy": 70.915833,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.779501,
    "out:CO2 Operational/m2": -3.775629,
    "out:Total CO2/m2": 27.003872,
    "out:Total CO2 (tons)": 53.251639,
    "out:Klimatpaverkan": -41.680534,
    "out:Initial Cost/MSEK": 4.832993226770618,
    "out:Running cost/(Apt SEK y)": 13002.352941,
    "out:Running Cost over RSP/MSEK": 3.46074,
    "out:LCP/MSEK": -1.964663,
    "out:ROI% old": 8.478419,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 367,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 116332.234133,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132387.65,
    "out:DH kr savings": 116332.23413313787,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4832993.226770618,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 91.039,
    "out:Total Energy Use Post PV": 86.350253,
    "out:Primary Energy": 75.199829,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.373961,
    "out:CO2 Operational/m2": -50.272227,
    "out:Total CO2/m2": -8.898266,
    "out:Total CO2 (tons)": -17.547382,
    "out:Klimatpaverkan": -112.479554,
    "out:Initial Cost/MSEK": 4.998272112229368,
    "out:Running cost/(Apt SEK y)": 13325.205882,
    "out:Running Cost over RSP/MSEK": 3.55334,
    "out:LCP/MSEK": -2.222542,
    "out:ROI% old": 7.933399,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103012.281173,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149778.61,
    "out:DH kr savings": 103012.28117332036,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4998272.112229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 88.039,
    "out:Total Energy Use Post PV": 83.350253,
    "out:Primary Energy": 72.500132,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.373961,
    "out:CO2 Operational/m2": -51.352179,
    "out:Total CO2/m2": -9.978218,
    "out:Total CO2 (tons)": -19.677047,
    "out:Klimatpaverkan": -114.609219,
    "out:Initial Cost/MSEK": 5.047572112229368,
    "out:Running cost/(Apt SEK y)": 12799.735294,
    "out:Running Cost over RSP/MSEK": 3.4136,
    "out:LCP/MSEK": -2.132102,
    "out:ROI% old": 8.251407,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 374,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109857.171759,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156623.5,
    "out:DH kr savings": 109857.17175881886,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5047572.112229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 88.039,
    "out:Total Energy Use Post PV": 83.350253,
    "out:Primary Energy": 72.574829,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.041304,
    "out:CO2 Operational/m2": -51.352179,
    "out:Total CO2/m2": -9.310875,
    "out:Total CO2 (tons)": -18.361047,
    "out:Klimatpaverkan": -113.293219,
    "out:Initial Cost/MSEK": 5.157648112229368,
    "out:Running cost/(Apt SEK y)": 12799.735294,
    "out:Running Cost over RSP/MSEK": 3.4136,
    "out:LCP/MSEK": -2.242178,
    "out:ROI% old": 8.075303,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 374,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109487.343548,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156253.67,
    "out:DH kr savings": 109487.34354763936,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5157648.112229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 79.350253,
    "out:Primary Energy": 69.875132,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.041304,
    "out:CO2 Operational/m2": -52.792115,
    "out:Total CO2/m2": -10.750811,
    "out:Total CO2 (tons)": -21.200601,
    "out:Klimatpaverkan": -116.132773,
    "out:Initial Cost/MSEK": 5.206948112229368,
    "out:Running cost/(Apt SEK y)": 12099.088235,
    "out:Running Cost over RSP/MSEK": 3.22727,
    "out:LCP/MSEK": -2.105148,
    "out:ROI% old": 8.510058,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 116332.234133,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163098.56,
    "out:DH kr savings": 116332.23413313787,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5206948.112229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 87.039,
    "out:Primary Energy": 80.139574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.850355,
    "out:CO2 Operational/m2": 28.588044,
    "out:Total CO2/m2": 47.438399,
    "out:Total CO2 (tons)": 93.548528,
    "out:Klimatpaverkan": -1.383644,
    "out:Initial Cost/MSEK": 4.340098983774368,
    "out:Running cost/(Apt SEK y)": 14533.529412,
    "out:Running Cost over RSP/MSEK": 3.86071,
    "out:LCP/MSEK": -1.871739,
    "out:ROI% old": 8.124765,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 58.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 111645.697672,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93507.24,
    "out:DH kr savings": 111645.69767241235,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4340098.983774368,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 83.039,
    "out:Primary Energy": 77.439877,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.850355,
    "out:CO2 Operational/m2": 27.148108,
    "out:Total CO2/m2": 45.998463,
    "out:Total CO2 (tons)": 90.708974,
    "out:Klimatpaverkan": -4.223198,
    "out:Initial Cost/MSEK": 4.389398983774368,
    "out:Running cost/(Apt SEK y)": 13832.882353,
    "out:Running Cost over RSP/MSEK": 3.67438,
    "out:LCP/MSEK": -1.734709,
    "out:ROI% old": 8.63994,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 118490.588258,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 100352.13,
    "out:DH kr savings": 118490.58825791086,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4389398.983774368,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 84.039,
    "out:Primary Energy": 78.039574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.517698,
    "out:CO2 Operational/m2": 27.508092,
    "out:Total CO2/m2": 47.02579,
    "out:Total CO2 (tons)": 92.734863,
    "out:Klimatpaverkan": -2.197309,
    "out:Initial Cost/MSEK": 4.499474983774368,
    "out:Running cost/(Apt SEK y)": 14008.058824,
    "out:Running Cost over RSP/MSEK": 3.72096,
    "out:LCP/MSEK": -1.891365,
    "out:ROI% old": 8.28068,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 64.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 116825.747572,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98687.29,
    "out:DH kr savings": 116825.74757186756,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4499474.983774368,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 81.039,
    "out:Total Energy Use Post PV": 81.039,
    "out:Primary Energy": 75.339877,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.517698,
    "out:CO2 Operational/m2": 26.42814,
    "out:Total CO2/m2": 45.945838,
    "out:Total CO2 (tons)": 90.605198,
    "out:Klimatpaverkan": -4.326974,
    "out:Initial Cost/MSEK": 4.548774983774368,
    "out:Running cost/(Apt SEK y)": 13482.558824,
    "out:Running Cost over RSP/MSEK": 3.58122,
    "out:LCP/MSEK": -1.800925,
    "out:ROI% old": 8.629796,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 70.4,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 123670.638157,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105532.18,
    "out:DH kr savings": 123670.63815736608,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4548774.983774368,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.838896,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 82.92842,
    "out:Primary Energy": 72.74053,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.112158,
    "out:CO2 Operational/m2": -2.695677,
    "out:Total CO2/m2": 27.416481,
    "out:Total CO2 (tons)": 54.065304,
    "out:Klimatpaverkan": -40.866869,
    "out:Initial Cost/MSEK": 4.714053851770618,
    "out:Running cost/(Apt SEK y)": 13527.823529,
    "out:Running Cost over RSP/MSEK": 3.60049,
    "out:LCP/MSEK": -1.985474,
    "out:ROI% old": 8.268831,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 111645.697672,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127701.11,
    "out:DH kr savings": 111645.69767241235,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4714053.851770618,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 78.92842,
    "out:Primary Energy": 70.040833,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.112158,
    "out:CO2 Operational/m2": -4.135613,
    "out:Total CO2/m2": 25.976545,
    "out:Total CO2 (tons)": 51.22575,
    "out:Klimatpaverkan": -43.706423,
    "out:Initial Cost/MSEK": 4.763353851770618,
    "out:Running cost/(Apt SEK y)": 12827.176471,
    "out:Running Cost over RSP/MSEK": 3.41416,
    "out:LCP/MSEK": -1.848444,
    "out:ROI% old": 8.74207,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118490.588258,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134546,
    "out:DH kr savings": 118490.58825791086,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4763353.851770618,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 79.92842,
    "out:Primary Energy": 70.64053,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.779501,
    "out:CO2 Operational/m2": -3.775629,
    "out:Total CO2/m2": 27.003872,
    "out:Total CO2 (tons)": 53.251639,
    "out:Klimatpaverkan": -41.680534,
    "out:Initial Cost/MSEK": 4.873429851770617,
    "out:Running cost/(Apt SEK y)": 13002.352941,
    "out:Running Cost over RSP/MSEK": 3.46074,
    "out:LCP/MSEK": -2.0051,
    "out:ROI% old": 8.408071,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 367,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 116825.747572,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132881.16,
    "out:DH kr savings": 116825.74757186756,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4873429.851770617,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 81.039,
    "out:Total Energy Use Post PV": 76.92842,
    "out:Primary Energy": 67.940833,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.779501,
    "out:CO2 Operational/m2": -4.855581,
    "out:Total CO2/m2": 25.92392,
    "out:Total CO2 (tons)": 51.121973,
    "out:Klimatpaverkan": -43.810199,
    "out:Initial Cost/MSEK": 4.922729851770617,
    "out:Running cost/(Apt SEK y)": 12476.852941,
    "out:Running Cost over RSP/MSEK": 3.321,
    "out:LCP/MSEK": -1.91466,
    "out:ROI% old": 8.72939,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 79.2,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 123670.638157,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139726.05,
    "out:DH kr savings": 123670.63815736608,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4922729.851770617,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.838896,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 87.039,
    "out:Total Energy Use Post PV": 82.350253,
    "out:Primary Energy": 71.699829,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.373961,
    "out:CO2 Operational/m2": -51.712163,
    "out:Total CO2/m2": -10.338202,
    "out:Total CO2 (tons)": -20.386936,
    "out:Klimatpaverkan": -115.319108,
    "out:Initial Cost/MSEK": 5.088008737229368,
    "out:Running cost/(Apt SEK y)": 12624.558824,
    "out:Running Cost over RSP/MSEK": 3.36702,
    "out:LCP/MSEK": -2.125959,
    "out:ROI% old": 8.316613,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 68.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 7,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 111645.697672,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158412.02,
    "out:DH kr savings": 111645.69767241235,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5088008.737229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 83.039,
    "out:Total Energy Use Post PV": 78.350253,
    "out:Primary Energy": 69.000132,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.373961,
    "out:CO2 Operational/m2": -53.152099,
    "out:Total CO2/m2": -11.778138,
    "out:Total CO2 (tons)": -23.226489,
    "out:Klimatpaverkan": -118.158662,
    "out:Initial Cost/MSEK": 5.137308737229367,
    "out:Running cost/(Apt SEK y)": 11923.911765,
    "out:Running Cost over RSP/MSEK": 3.18069,
    "out:LCP/MSEK": -1.988929,
    "out:ROI% old": 8.754945,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 76.9,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118490.588258,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165256.91,
    "out:DH kr savings": 118490.58825791086,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5137308.737229368,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.17491,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 84.039,
    "out:Total Energy Use Post PV": 79.350253,
    "out:Primary Energy": 69.599829,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.041304,
    "out:CO2 Operational/m2": -52.792115,
    "out:Total CO2/m2": -10.750811,
    "out:Total CO2 (tons)": -21.200601,
    "out:Klimatpaverkan": -116.132773,
    "out:Initial Cost/MSEK": 5.247384737229367,
    "out:Running cost/(Apt SEK y)": 12099.088235,
    "out:Running Cost over RSP/MSEK": 3.22727,
    "out:LCP/MSEK": -2.145585,
    "out:ROI% old": 8.444479,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 116825.747572,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163592.07,
    "out:DH kr savings": 116825.74757186756,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 112748.92963,
    "out:% savings (space heating)": 47.743652,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 57.17,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5247384.737229367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.918848,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.703871,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 81.039,
    "out:Total Energy Use Post PV": 76.350253,
    "out:Primary Energy": 66.900132,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.041304,
    "out:CO2 Operational/m2": -53.872067,
    "out:Total CO2/m2": -11.830763,
    "out:Total CO2 (tons)": -23.330266,
    "out:Klimatpaverkan": -118.262438,
    "out:Initial Cost/MSEK": 5.296684737229366,
    "out:Running cost/(Apt SEK y)": 11573.588235,
    "out:Running Cost over RSP/MSEK": 3.08752,
    "out:LCP/MSEK": -2.055135,
    "out:ROI% old": 8.7428,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 123670.638157,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170436.96,
    "out:DH kr savings": 123670.63815736608,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 105904.039044,
    "out:% savings (space heating)": 50.91609,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 53.7,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5296684.737229367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.838896,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 79.039,
    "out:Total Energy Use Post PV": 79.039,
    "out:Primary Energy": 74.488925,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.934206,
    "out:CO2 Operational/m2": 25.708172,
    "out:Total CO2/m2": 45.642378,
    "out:Total CO2 (tons)": 90.006775,
    "out:Klimatpaverkan": -4.925398,
    "out:Initial Cost/MSEK": 4.372082358428118,
    "out:Running cost/(Apt SEK y)": 13132.235294,
    "out:Running Cost over RSP/MSEK": 3.48806,
    "out:LCP/MSEK": -1.531072,
    "out:ROI% old": 9.282958,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 74.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 126213.102113,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108074.65,
    "out:DH kr savings": 126213.10211254422,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4372082.358428118,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 76.039,
    "out:Total Energy Use Post PV": 76.039,
    "out:Primary Energy": 72.205165,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.934206,
    "out:CO2 Operational/m2": 24.62822,
    "out:Total CO2/m2": 44.562426,
    "out:Total CO2 (tons)": 87.877109,
    "out:Klimatpaverkan": -7.055063,
    "out:Initial Cost/MSEK": 4.421382358428118,
    "out:Running cost/(Apt SEK y)": 12606.764706,
    "out:Running Cost over RSP/MSEK": 3.34831,
    "out:LCP/MSEK": -1.440622,
    "out:ROI% old": 9.630989,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132003.409928,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113864.95,
    "out:DH kr savings": 132003.40992807804,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4421382.358428118,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 76.039,
    "out:Total Energy Use Post PV": 76.039,
    "out:Primary Energy": 71.863925,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.601549,
    "out:CO2 Operational/m2": 24.62822,
    "out:Total CO2/m2": 45.229769,
    "out:Total CO2 (tons)": 89.19311,
    "out:Klimatpaverkan": -5.739063,
    "out:Initial Cost/MSEK": 4.531458358428117,
    "out:Running cost/(Apt SEK y)": 12606.764706,
    "out:Running Cost over RSP/MSEK": 3.34831,
    "out:LCP/MSEK": -1.550698,
    "out:ROI% old": 9.397038,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132688.164487,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114549.71,
    "out:DH kr savings": 132688.1644868632,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4531458.358428117,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 73.039,
    "out:Total Energy Use Post PV": 73.039,
    "out:Primary Energy": 69.580165,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.601549,
    "out:CO2 Operational/m2": 23.548268,
    "out:Total CO2/m2": 44.149817,
    "out:Total CO2 (tons)": 87.063444,
    "out:Klimatpaverkan": -7.868728,
    "out:Initial Cost/MSEK": 4.580758358428117,
    "out:Running cost/(Apt SEK y)": 12081.294118,
    "out:Running Cost over RSP/MSEK": 3.20856,
    "out:LCP/MSEK": -1.460248,
    "out:ROI% old": 9.731732,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 89,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 399,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138478.472302,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120340.02,
    "out:DH kr savings": 138478.47230239704,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4580758.358428117,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.959024,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 79.039,
    "out:Total Energy Use Post PV": 74.92842,
    "out:Primary Energy": 67.089881,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.196009,
    "out:CO2 Operational/m2": -5.575549,
    "out:Total CO2/m2": 25.62046,
    "out:Total CO2 (tons)": 50.52355,
    "out:Klimatpaverkan": -44.408622,
    "out:Initial Cost/MSEK": 4.7460372264243675,
    "out:Running cost/(Apt SEK y)": 12126.529412,
    "out:Running Cost over RSP/MSEK": 3.22783,
    "out:LCP/MSEK": -1.644797,
    "out:ROI% old": 9.334825,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 84,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 397,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126213.102113,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142268.52,
    "out:DH kr savings": 126213.10211254422,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4746037.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 76.039,
    "out:Total Energy Use Post PV": 71.92842,
    "out:Primary Energy": 64.806121,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.196009,
    "out:CO2 Operational/m2": -6.655501,
    "out:Total CO2/m2": 24.540508,
    "out:Total CO2 (tons)": 48.393885,
    "out:Klimatpaverkan": -46.538288,
    "out:Initial Cost/MSEK": 4.795337226424367,
    "out:Running cost/(Apt SEK y)": 11601.058824,
    "out:Running Cost over RSP/MSEK": 3.08809,
    "out:LCP/MSEK": -1.554357,
    "out:ROI% old": 9.655153,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132003.409928,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148058.83,
    "out:DH kr savings": 132003.40992807804,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4795337.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 76.039,
    "out:Total Energy Use Post PV": 71.92842,
    "out:Primary Energy": 64.464881,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.863352,
    "out:CO2 Operational/m2": -6.655501,
    "out:Total CO2/m2": 25.207851,
    "out:Total CO2 (tons)": 49.709885,
    "out:Klimatpaverkan": -45.222287,
    "out:Initial Cost/MSEK": 4.9054132264243675,
    "out:Running cost/(Apt SEK y)": 11601.058824,
    "out:Running Cost over RSP/MSEK": 3.08809,
    "out:LCP/MSEK": -1.664433,
    "out:ROI% old": 9.438494,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132688.164487,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148743.58,
    "out:DH kr savings": 132688.1644868632,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4905413.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 73.039,
    "out:Total Energy Use Post PV": 68.92842,
    "out:Primary Energy": 62.181121,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.863352,
    "out:CO2 Operational/m2": -7.735453,
    "out:Total CO2/m2": 24.127899,
    "out:Total CO2 (tons)": 47.58022,
    "out:Klimatpaverkan": -47.351953,
    "out:Initial Cost/MSEK": 4.954713226424367,
    "out:Running cost/(Apt SEK y)": 11075.588235,
    "out:Running Cost over RSP/MSEK": 2.94835,
    "out:LCP/MSEK": -1.573993,
    "out:ROI% old": 9.747486,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138478.472302,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154533.89,
    "out:DH kr savings": 138478.47230239704,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4954713.226424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.959024,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 79.039,
    "out:Total Energy Use Post PV": 74.350253,
    "out:Primary Energy": 66.04918,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.457812,
    "out:CO2 Operational/m2": -54.592035,
    "out:Total CO2/m2": -12.134223,
    "out:Total CO2 (tons)": -23.928689,
    "out:Klimatpaverkan": -118.860861,
    "out:Initial Cost/MSEK": 5.119992111883117,
    "out:Running cost/(Apt SEK y)": 11223.294118,
    "out:Running Cost over RSP/MSEK": 2.99436,
    "out:LCP/MSEK": -1.785282,
    "out:ROI% old": 9.304451,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126213.102113,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172979.43,
    "out:DH kr savings": 126213.10211254422,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5119992.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 76.039,
    "out:Total Energy Use Post PV": 71.350253,
    "out:Primary Energy": 63.76542,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.457812,
    "out:CO2 Operational/m2": -55.671987,
    "out:Total CO2/m2": -13.214175,
    "out:Total CO2 (tons)": -26.058355,
    "out:Klimatpaverkan": -120.990527,
    "out:Initial Cost/MSEK": 5.1692921118831165,
    "out:Running cost/(Apt SEK y)": 10697.794118,
    "out:Running Cost over RSP/MSEK": 2.85461,
    "out:LCP/MSEK": -1.694832,
    "out:ROI% old": 9.601923,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132003.409928,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178769.74,
    "out:DH kr savings": 132003.40992807804,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5169292.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 76.039,
    "out:Total Energy Use Post PV": 71.350253,
    "out:Primary Energy": 63.42418,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.125155,
    "out:CO2 Operational/m2": -55.671987,
    "out:Total CO2/m2": -12.546832,
    "out:Total CO2 (tons)": -24.742354,
    "out:Klimatpaverkan": -119.674526,
    "out:Initial Cost/MSEK": 5.279368111883117,
    "out:Running cost/(Apt SEK y)": 10697.794118,
    "out:Running Cost over RSP/MSEK": 2.85461,
    "out:LCP/MSEK": -1.804908,
    "out:ROI% old": 9.40172,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132688.164487,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179454.49,
    "out:DH kr savings": 132688.1644868632,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5279368.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 23.038976,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 73.039,
    "out:Total Energy Use Post PV": 68.350253,
    "out:Primary Energy": 61.14042,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.125155,
    "out:CO2 Operational/m2": -56.751939,
    "out:Total CO2/m2": -13.626784,
    "out:Total CO2 (tons)": -26.87202,
    "out:Klimatpaverkan": -121.804192,
    "out:Initial Cost/MSEK": 5.3286681118831165,
    "out:Running cost/(Apt SEK y)": 10172.323529,
    "out:Running Cost over RSP/MSEK": 2.71487,
    "out:LCP/MSEK": -1.714468,
    "out:ROI% old": 9.689368,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 464,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138478.472302,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185244.8,
    "out:DH kr savings": 138478.47230239704,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5328668.111883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.959024,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 75.039,
    "out:Total Energy Use Post PV": 75.039,
    "out:Primary Energy": 70.988925,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.934206,
    "out:CO2 Operational/m2": 24.268236,
    "out:Total CO2/m2": 44.202442,
    "out:Total CO2 (tons)": 87.167221,
    "out:Klimatpaverkan": -7.764952,
    "out:Initial Cost/MSEK": 4.461818983428117,
    "out:Running cost/(Apt SEK y)": 12431.617647,
    "out:Running Cost over RSP/MSEK": 3.30173,
    "out:LCP/MSEK": -1.434479,
    "out:ROI% old": 9.692844,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 84,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134846.518612,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116708.06,
    "out:DH kr savings": 134846.51861163622,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4461818.983428117,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 72.039,
    "out:Primary Energy": 68.705165,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 19.934206,
    "out:CO2 Operational/m2": 23.188284,
    "out:Total CO2/m2": 43.12249,
    "out:Total CO2 (tons)": 85.037555,
    "out:Klimatpaverkan": -9.894617,
    "out:Initial Cost/MSEK": 4.5111189834281165,
    "out:Running cost/(Apt SEK y)": 11906.117647,
    "out:Running Cost over RSP/MSEK": 3.16198,
    "out:LCP/MSEK": -1.344029,
    "out:ROI% old": 10.029472,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140636.826427,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122498.37,
    "out:DH kr savings": 140636.82642717002,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4511118.983428117,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 72.039,
    "out:Primary Energy": 68.888925,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.601549,
    "out:CO2 Operational/m2": 23.188284,
    "out:Total CO2/m2": 43.789833,
    "out:Total CO2 (tons)": 86.353556,
    "out:Klimatpaverkan": -8.578617,
    "out:Initial Cost/MSEK": 4.621194983428117,
    "out:Running cost/(Apt SEK y)": 11906.117647,
    "out:Running Cost over RSP/MSEK": 3.16198,
    "out:LCP/MSEK": -1.454105,
    "out:ROI% old": 9.790572,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140026.568511,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121888.11,
    "out:DH kr savings": 140026.56851109143,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4621194.983428117,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 69.039,
    "out:Total Energy Use Post PV": 69.039,
    "out:Primary Energy": 66.605165,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 20.601549,
    "out:CO2 Operational/m2": 22.108332,
    "out:Total CO2/m2": 42.709881,
    "out:Total CO2 (tons)": 84.22389,
    "out:Klimatpaverkan": -10.708282,
    "out:Initial Cost/MSEK": 4.670494983428117,
    "out:Running cost/(Apt SEK y)": 11380.647059,
    "out:Running Cost over RSP/MSEK": 3.02224,
    "out:LCP/MSEK": -1.363665,
    "out:ROI% old": 10.114651,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 9,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 145816.876327,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127678.42,
    "out:DH kr savings": 145816.87632662524,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4670494.983428117,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 75.039,
    "out:Total Energy Use Post PV": 70.92842,
    "out:Primary Energy": 63.589881,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.196009,
    "out:CO2 Operational/m2": -7.015485,
    "out:Total CO2/m2": 24.180524,
    "out:Total CO2 (tons)": 47.683996,
    "out:Klimatpaverkan": -47.248176,
    "out:Initial Cost/MSEK": 4.835773851424367,
    "out:Running cost/(Apt SEK y)": 11425.911765,
    "out:Running Cost over RSP/MSEK": 3.04151,
    "out:LCP/MSEK": -1.548214,
    "out:ROI% old": 9.712022,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134846.518612,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150901.93,
    "out:DH kr savings": 134846.51861163622,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4835773.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 67.92842,
    "out:Primary Energy": 61.306121,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.196009,
    "out:CO2 Operational/m2": -8.095437,
    "out:Total CO2/m2": 23.100572,
    "out:Total CO2 (tons)": 45.554331,
    "out:Klimatpaverkan": -49.377842,
    "out:Initial Cost/MSEK": 4.885073851424367,
    "out:Running cost/(Apt SEK y)": 10900.411765,
    "out:Running Cost over RSP/MSEK": 2.90176,
    "out:LCP/MSEK": -1.457764,
    "out:ROI% old": 10.022688,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140636.826427,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156692.24,
    "out:DH kr savings": 140636.82642717002,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4885073.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 67.92842,
    "out:Primary Energy": 61.489881,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.863352,
    "out:CO2 Operational/m2": -8.095437,
    "out:Total CO2/m2": 23.767915,
    "out:Total CO2 (tons)": 46.870331,
    "out:Klimatpaverkan": -48.061841,
    "out:Initial Cost/MSEK": 4.995149851424367,
    "out:Running cost/(Apt SEK y)": 10900.411765,
    "out:Running Cost over RSP/MSEK": 2.90176,
    "out:LCP/MSEK": -1.56784,
    "out:ROI% old": 9.801822,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140026.568511,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156081.98,
    "out:DH kr savings": 140026.56851109143,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4995149.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 69.039,
    "out:Total Energy Use Post PV": 64.92842,
    "out:Primary Energy": 59.206121,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.863352,
    "out:CO2 Operational/m2": -9.175389,
    "out:Total CO2/m2": 22.687963,
    "out:Total CO2 (tons)": 44.740666,
    "out:Klimatpaverkan": -50.191507,
    "out:Initial Cost/MSEK": 5.044449851424367,
    "out:Running cost/(Apt SEK y)": 10374.941176,
    "out:Running Cost over RSP/MSEK": 2.76202,
    "out:LCP/MSEK": -1.4774,
    "out:ROI% old": 10.101767,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 112.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 9,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 145816.876327,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161872.29,
    "out:DH kr savings": 145816.87632662524,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5044449.851424367,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 75.039,
    "out:Total Energy Use Post PV": 70.350253,
    "out:Primary Energy": 62.54918,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.457812,
    "out:CO2 Operational/m2": -56.031971,
    "out:Total CO2/m2": -13.574159,
    "out:Total CO2 (tons)": -26.768243,
    "out:Klimatpaverkan": -121.700415,
    "out:Initial Cost/MSEK": 5.209728736883117,
    "out:Running cost/(Apt SEK y)": 10522.647059,
    "out:Running Cost over RSP/MSEK": 2.80803,
    "out:LCP/MSEK": -1.688689,
    "out:ROI% old": 9.655123,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 97.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134846.518612,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181612.85,
    "out:DH kr savings": 134846.51861163622,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5209728.736883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 67.350253,
    "out:Primary Energy": 60.26542,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.457812,
    "out:CO2 Operational/m2": -57.111923,
    "out:Total CO2/m2": -14.654111,
    "out:Total CO2 (tons)": -28.897909,
    "out:Klimatpaverkan": -123.830081,
    "out:Initial Cost/MSEK": 5.259028736883117,
    "out:Running cost/(Apt SEK y)": 9997.147059,
    "out:Running Cost over RSP/MSEK": 2.66829,
    "out:LCP/MSEK": -1.598249,
    "out:ROI% old": 9.944204,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 140636.826427,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187403.15,
    "out:DH kr savings": 140636.82642717002,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5259028.736883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.409789,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 67.350253,
    "out:Primary Energy": 60.44918,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.125155,
    "out:CO2 Operational/m2": -57.111923,
    "out:Total CO2/m2": -13.986768,
    "out:Total CO2 (tons)": -27.581908,
    "out:Klimatpaverkan": -122.51408,
    "out:Initial Cost/MSEK": 5.369104736883117,
    "out:Running cost/(Apt SEK y)": 9997.147059,
    "out:Running Cost over RSP/MSEK": 2.66829,
    "out:LCP/MSEK": -1.708325,
    "out:ROI% old": 9.740331,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140026.568511,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186792.9,
    "out:DH kr savings": 140026.56851109143,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 89548.10869,
    "out:% savings (space heating)": 58.49666,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 45.41,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5369104.736883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.473527,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 69.039,
    "out:Total Energy Use Post PV": 64.350253,
    "out:Primary Energy": 58.16542,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.125155,
    "out:CO2 Operational/m2": -58.191875,
    "out:Total CO2/m2": -15.06672,
    "out:Total CO2 (tons)": -29.711574,
    "out:Klimatpaverkan": -124.643746,
    "out:Initial Cost/MSEK": 5.418404736883117,
    "out:Running cost/(Apt SEK y)": 9471.676471,
    "out:Running Cost over RSP/MSEK": 2.52854,
    "out:LCP/MSEK": -1.617875,
    "out:ROI% old": 10.020161,
    "out:Payback discounted": 12,
    "out:Atemp": 1972,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 9,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 716652.6461840633,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 145816.876327,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192583.2,
    "out:DH kr savings": 145816.87632662524,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 83757.800875,
    "out:% savings (space heating)": 61.180325,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 42.47,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5418404.736883117,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 75.039,
    "out:Total Energy Use Post PV": 75.039,
    "out:Primary Energy": 71.094403,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.910527,
    "out:CO2 Operational/m2": 24.268236,
    "out:Total CO2/m2": 66.178763,
    "out:Total CO2 (tons)": 130.504528,
    "out:Klimatpaverkan": 35.572356,
    "out:Initial Cost/MSEK": 5.270207633392041,
    "out:Running cost/(Apt SEK y)": 12431.617647,
    "out:Running Cost over RSP/MSEK": 3.30173,
    "out:LCP/MSEK": -2.242868,
    "out:ROI% old": 8.206074,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 84,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 134819.670422,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116681.21,
    "out:DH kr savings": 134819.67042168413,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5270207.633392042,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 72.039,
    "out:Primary Energy": 68.96494,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.910527,
    "out:CO2 Operational/m2": 23.188284,
    "out:Total CO2/m2": 65.098811,
    "out:Total CO2 (tons)": 128.374863,
    "out:Klimatpaverkan": 33.44269,
    "out:Initial Cost/MSEK": 5.319507633392042,
    "out:Running cost/(Apt SEK y)": 11906.117647,
    "out:Running Cost over RSP/MSEK": 3.16198,
    "out:LCP/MSEK": -2.152418,
    "out:ROI% old": 8.505325,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 140218.770587,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122080.31,
    "out:DH kr savings": 140218.77058680248,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5319507.633392042,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 72.039,
    "out:Primary Energy": 68.469403,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.57787,
    "out:CO2 Operational/m2": 23.188284,
    "out:Total CO2/m2": 65.766154,
    "out:Total CO2 (tons)": 129.690863,
    "out:Klimatpaverkan": 34.758691,
    "out:Initial Cost/MSEK": 5.429583633392042,
    "out:Running cost/(Apt SEK y)": 11906.117647,
    "out:Running Cost over RSP/MSEK": 3.16198,
    "out:LCP/MSEK": -2.262494,
    "out:ROI% old": 8.332894,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 91.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 7,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 141294.732796,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123156.28,
    "out:DH kr savings": 141294.73279600314,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5429583.633392042,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 69.039,
    "out:Total Energy Use Post PV": 69.039,
    "out:Primary Energy": 66.33994,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.57787,
    "out:CO2 Operational/m2": 22.108332,
    "out:Total CO2/m2": 64.686202,
    "out:Total CO2 (tons)": 127.561198,
    "out:Klimatpaverkan": 32.629025,
    "out:Initial Cost/MSEK": 5.478883633392042,
    "out:Running cost/(Apt SEK y)": 11380.647059,
    "out:Running Cost over RSP/MSEK": 3.02224,
    "out:LCP/MSEK": -2.172054,
    "out:ROI% old": 8.622273,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 146693.832961,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128555.38,
    "out:DH kr savings": 146693.8329611215,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 21.25,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5478883.633392042,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 75.039,
    "out:Total Energy Use Post PV": 70.92842,
    "out:Primary Energy": 63.695359,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.17233,
    "out:CO2 Operational/m2": -7.015485,
    "out:Total CO2/m2": 46.156845,
    "out:Total CO2 (tons)": 91.021304,
    "out:Klimatpaverkan": -3.910869,
    "out:Initial Cost/MSEK": 5.644162501388291,
    "out:Running cost/(Apt SEK y)": 11425.911765,
    "out:Running Cost over RSP/MSEK": 3.04151,
    "out:LCP/MSEK": -2.356603,
    "out:ROI% old": 8.321012,
    "out:Payback discounted": 15,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 7,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134819.670422,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150875.09,
    "out:DH kr savings": 134819.67042168413,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5644162.501388291,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 67.92842,
    "out:Primary Energy": 61.565896,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.17233,
    "out:CO2 Operational/m2": -8.095437,
    "out:Total CO2/m2": 45.076893,
    "out:Total CO2 (tons)": 88.891638,
    "out:Klimatpaverkan": -6.040534,
    "out:Initial Cost/MSEK": 5.693462501388291,
    "out:Running cost/(Apt SEK y)": 10900.411765,
    "out:Running Cost over RSP/MSEK": 2.90176,
    "out:LCP/MSEK": -2.266153,
    "out:ROI% old": 8.599613,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140218.770587,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156274.19,
    "out:DH kr savings": 140218.77058680248,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5693462.501388291,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 67.92842,
    "out:Primary Energy": 61.070359,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.839673,
    "out:CO2 Operational/m2": -8.095437,
    "out:Total CO2/m2": 45.744236,
    "out:Total CO2 (tons)": 90.207639,
    "out:Klimatpaverkan": -4.724534,
    "out:Initial Cost/MSEK": 5.8035385013882905,
    "out:Running cost/(Apt SEK y)": 10900.411765,
    "out:Running Cost over RSP/MSEK": 2.90176,
    "out:LCP/MSEK": -2.376229,
    "out:ROI% old": 8.436503,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141294.732796,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157350.15,
    "out:DH kr savings": 141294.73279600314,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5803538.501388291,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 69.039,
    "out:Total Energy Use Post PV": 64.92842,
    "out:Primary Energy": 58.940896,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.839673,
    "out:CO2 Operational/m2": -9.175389,
    "out:Total CO2/m2": 44.664284,
    "out:Total CO2 (tons)": 88.077973,
    "out:Klimatpaverkan": -6.854199,
    "out:Initial Cost/MSEK": 5.852838501388291,
    "out:Running cost/(Apt SEK y)": 10374.941176,
    "out:Running Cost over RSP/MSEK": 2.76202,
    "out:LCP/MSEK": -2.285789,
    "out:ROI% old": 8.70652,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 112.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146693.832961,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162749.25,
    "out:DH kr savings": 146693.8329611215,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 21.25,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5852838.501388291,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 75.039,
    "out:Total Energy Use Post PV": 70.350253,
    "out:Primary Energy": 62.654658,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 64.434133,
    "out:CO2 Operational/m2": -56.031971,
    "out:Total CO2/m2": 8.402162,
    "out:Total CO2 (tons)": 16.569064,
    "out:Klimatpaverkan": -78.363108,
    "out:Initial Cost/MSEK": 6.018117386847042,
    "out:Running cost/(Apt SEK y)": 10522.647059,
    "out:Running Cost over RSP/MSEK": 2.80803,
    "out:LCP/MSEK": -2.497077,
    "out:ROI% old": 8.35819,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 97.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134819.670422,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181586,
    "out:DH kr savings": 134819.67042168413,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6018117.386847042,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 21.89,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 67.350253,
    "out:Primary Energy": 60.525195,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 64.434133,
    "out:CO2 Operational/m2": -57.111923,
    "out:Total CO2/m2": 7.32221,
    "out:Total CO2 (tons)": 14.439399,
    "out:Klimatpaverkan": -80.492773,
    "out:Initial Cost/MSEK": 6.067417386847041,
    "out:Running cost/(Apt SEK y)": 9997.147059,
    "out:Running Cost over RSP/MSEK": 2.66829,
    "out:LCP/MSEK": -2.406637,
    "out:ROI% old": 8.619294,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.89,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140218.770587,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186985.1,
    "out:DH kr savings": 140218.77058680248,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 43167.082495,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6067417.386847042,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 72.039,
    "out:Total Energy Use Post PV": 67.350253,
    "out:Primary Energy": 60.029658,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 65.101476,
    "out:CO2 Operational/m2": -57.111923,
    "out:Total CO2/m2": 7.989553,
    "out:Total CO2 (tons)": 15.755399,
    "out:Klimatpaverkan": -79.176773,
    "out:Initial Cost/MSEK": 6.177493386847042,
    "out:Running cost/(Apt SEK y)": 9997.147059,
    "out:Running Cost over RSP/MSEK": 2.66829,
    "out:LCP/MSEK": -2.516713,
    "out:ROI% old": 8.465708,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141294.732796,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188061.06,
    "out:DH kr savings": 141294.73279600314,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6177493.386847042,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.59904,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 18.6065,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 69.039,
    "out:Total Energy Use Post PV": 64.350253,
    "out:Primary Energy": 57.900195,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 65.101476,
    "out:CO2 Operational/m2": -58.191875,
    "out:Total CO2/m2": 6.909601,
    "out:Total CO2 (tons)": 13.625734,
    "out:Klimatpaverkan": -81.306438,
    "out:Initial Cost/MSEK": 6.226793386847041,
    "out:Running cost/(Apt SEK y)": 9471.676471,
    "out:Running Cost over RSP/MSEK": 2.52854,
    "out:LCP/MSEK": -2.426263,
    "out:ROI% old": 8.719301,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.6065,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146693.832961,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193460.16,
    "out:DH kr savings": 146693.8329611215,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 36692.020121,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 21.25,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6226793.386847042,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.519088,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 71.039,
    "out:Total Energy Use Post PV": 71.039,
    "out:Primary Energy": 67.594403,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.910527,
    "out:CO2 Operational/m2": 22.8283,
    "out:Total CO2/m2": 64.738827,
    "out:Total CO2 (tons)": 127.664974,
    "out:Klimatpaverkan": 32.732802,
    "out:Initial Cost/MSEK": 5.359944258392042,
    "out:Running cost/(Apt SEK y)": 11730.970588,
    "out:Running Cost over RSP/MSEK": 3.1154,
    "out:LCP/MSEK": -2.146274,
    "out:ROI% old": 8.565308,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 94.4,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 143453.086921,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125314.63,
    "out:DH kr savings": 143453.08692077614,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5359944.258392042,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 68.039,
    "out:Total Energy Use Post PV": 68.039,
    "out:Primary Energy": 65.46494,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.910527,
    "out:CO2 Operational/m2": 21.748348,
    "out:Total CO2/m2": 63.658875,
    "out:Total CO2 (tons)": 125.535309,
    "out:Klimatpaverkan": 30.603137,
    "out:Initial Cost/MSEK": 5.409244258392041,
    "out:Running cost/(Apt SEK y)": 11205.470588,
    "out:Running Cost over RSP/MSEK": 2.97565,
    "out:LCP/MSEK": -2.055824,
    "out:ROI% old": 8.85632,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 148852.187086,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130713.73,
    "out:DH kr savings": 148852.1870858945,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 20,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5409244.258392042,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 68.039,
    "out:Total Energy Use Post PV": 68.039,
    "out:Primary Energy": 65.494403,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.57787,
    "out:CO2 Operational/m2": 21.748348,
    "out:Total CO2/m2": 64.326218,
    "out:Total CO2 (tons)": 126.851309,
    "out:Klimatpaverkan": 31.919137,
    "out:Initial Cost/MSEK": 5.519320258392041,
    "out:Running cost/(Apt SEK y)": 11205.470588,
    "out:Running Cost over RSP/MSEK": 2.97565,
    "out:LCP/MSEK": -2.1659,
    "out:ROI% old": 8.679692,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 102.9,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 148633.13682,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130494.68,
    "out:DH kr savings": 148633.13682023133,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5519320.258392041,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 12.039,
    "out:Total Energy Use Pre PV": 65.039,
    "out:Total Energy Use Post PV": 65.039,
    "out:Primary Energy": 63.36494,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.57787,
    "out:CO2 Operational/m2": 20.668396,
    "out:Total CO2/m2": 63.246266,
    "out:Total CO2 (tons)": 124.721644,
    "out:Klimatpaverkan": 29.789472,
    "out:Initial Cost/MSEK": 5.56862025839204,
    "out:Running cost/(Apt SEK y)": 10680,
    "out:Running Cost over RSP/MSEK": 2.83591,
    "out:LCP/MSEK": -2.07546,
    "out:ROI% old": 8.961338,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 112.3,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 842.73,
    "out:Return %": 8,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 154032.236985,
    "out:EL kWh savings": -9069.228524,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135893.78,
    "out:DH kr savings": 154032.2369853497,
    "out:El kr savings": -18138.457048572003,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 17,
    "out:Ef": 12.04,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5568620.258392041,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": 1.589244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.589244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 71.039,
    "out:Total Energy Use Post PV": 66.92842,
    "out:Primary Energy": 60.195359,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.17233,
    "out:CO2 Operational/m2": -8.455421,
    "out:Total CO2/m2": 44.716909,
    "out:Total CO2 (tons)": 88.18175,
    "out:Klimatpaverkan": -6.750423,
    "out:Initial Cost/MSEK": 5.733899126388291,
    "out:Running cost/(Apt SEK y)": 10725.264706,
    "out:Running Cost over RSP/MSEK": 2.85518,
    "out:LCP/MSEK": -2.260009,
    "out:ROI% old": 8.655018,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 106,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 143453.086921,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159508.5,
    "out:DH kr savings": 143453.08692077614,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5733899.126388291,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 68.039,
    "out:Total Energy Use Post PV": 63.92842,
    "out:Primary Energy": 58.065896,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.17233,
    "out:CO2 Operational/m2": -9.535373,
    "out:Total CO2/m2": 43.636957,
    "out:Total CO2 (tons)": 86.052084,
    "out:Klimatpaverkan": -8.880088,
    "out:Initial Cost/MSEK": 5.783199126388291,
    "out:Running cost/(Apt SEK y)": 10199.794118,
    "out:Running Cost over RSP/MSEK": 2.71544,
    "out:LCP/MSEK": -2.169569,
    "out:ROI% old": 8.926424,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 463,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 148852.187086,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164907.6,
    "out:DH kr savings": 148852.1870858945,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 20,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5783199.126388291,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 68.039,
    "out:Total Energy Use Post PV": 63.92842,
    "out:Primary Energy": 58.095359,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.839673,
    "out:CO2 Operational/m2": -9.535373,
    "out:Total CO2/m2": 44.3043,
    "out:Total CO2 (tons)": 87.368085,
    "out:Klimatpaverkan": -7.564088,
    "out:Initial Cost/MSEK": 5.893275126388291,
    "out:Running cost/(Apt SEK y)": 10199.794118,
    "out:Running Cost over RSP/MSEK": 2.71544,
    "out:LCP/MSEK": -2.279645,
    "out:ROI% old": 8.759694,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 463,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 148633.13682,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164688.55,
    "out:DH kr savings": 148633.13682023133,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5893275.126388291,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.92842,
    "out:Total Energy Use Pre PV": 65.039,
    "out:Total Energy Use Post PV": 60.92842,
    "out:Primary Energy": 55.965896,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 53.839673,
    "out:CO2 Operational/m2": -10.615325,
    "out:Total CO2/m2": 43.224348,
    "out:Total CO2 (tons)": 85.238419,
    "out:Klimatpaverkan": -9.693753,
    "out:Initial Cost/MSEK": 5.942575126388291,
    "out:Running cost/(Apt SEK y)": 9674.294118,
    "out:Running Cost over RSP/MSEK": 2.57569,
    "out:LCP/MSEK": -2.189195,
    "out:ROI% old": 9.022976,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 126.2,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 559.976863,
    "out:Return %": 8,
    "out:Return/kSEK/y": 481,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555624594,
    "out:PV (kWp installed)": 21.415888,
    "out:PV (m2 panels)": 112.36038,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 20093.893277,
    "out:PV (kWh sold)": 11987.829345,
    "out:PV (% sold (El))": 59.659067,
    "out:PV (kWh self-consumed)": 8106.063933,
    "out:PV (ratio sold/self-consumed)": 1.478872,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154032.236985,
    "out:EL kWh savings": -963.164296,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170087.65,
    "out:DH kr savings": 154032.2369853497,
    "out:El kr savings": -1926.328591359769,
    "out:El kr sold": 17981.744016996086,
    "out:El kr saved": 16212.127866,
    "out:El kr return": 34193.871882996085,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 17,
    "out:Ef": 7.93,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5942575.126388291,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": -29.694477,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -30.743313,
    "out:Bought CO2": 1.048836,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 71.039,
    "out:Total Energy Use Post PV": 66.350253,
    "out:Primary Energy": 59.154658,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 64.434133,
    "out:CO2 Operational/m2": -57.471907,
    "out:Total CO2/m2": 6.962226,
    "out:Total CO2 (tons)": 13.72951,
    "out:Klimatpaverkan": -81.202662,
    "out:Initial Cost/MSEK": 6.107854011847041,
    "out:Running cost/(Apt SEK y)": 9822,
    "out:Running Cost over RSP/MSEK": 2.62171,
    "out:LCP/MSEK": -2.400494,
    "out:ROI% old": 8.671177,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 109.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 143453.086921,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190219.41,
    "out:DH kr savings": 143453.08692077614,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6107854.011847042,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 17.512,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 68.039,
    "out:Total Energy Use Post PV": 63.350253,
    "out:Primary Energy": 57.025195,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 64.434133,
    "out:CO2 Operational/m2": -58.551859,
    "out:Total CO2/m2": 5.882274,
    "out:Total CO2 (tons)": 11.599845,
    "out:Klimatpaverkan": -83.332327,
    "out:Initial Cost/MSEK": 6.157154011847042,
    "out:Running cost/(Apt SEK y)": 9296.529412,
    "out:Running Cost over RSP/MSEK": 2.48196,
    "out:LCP/MSEK": -2.310044,
    "out:ROI% old": 8.925993,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 119,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 17.512,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 148852.187086,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195618.51,
    "out:DH kr savings": 148852.1870858945,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 34533.665996,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 20,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6157154.011847042,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.045404,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 68.039,
    "out:Total Energy Use Post PV": 63.350253,
    "out:Primary Energy": 57.054658,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 65.101476,
    "out:CO2 Operational/m2": -58.551859,
    "out:Total CO2/m2": 6.549617,
    "out:Total CO2 (tons)": 12.915845,
    "out:Klimatpaverkan": -82.016327,
    "out:Initial Cost/MSEK": 6.267230011847041,
    "out:Running cost/(Apt SEK y)": 9296.529412,
    "out:Running Cost over RSP/MSEK": 2.48196,
    "out:LCP/MSEK": -2.42012,
    "out:ROI% old": 8.769219,
    "out:Payback discounted": 14,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 119,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 148633.13682,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195399.46,
    "out:DH kr savings": 148633.13682023133,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 80941.540381,
    "out:% savings (space heating)": 62.485592,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 41.05,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6267230.011847042,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.159104,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 38.307523,
    "out:DHW (DH)": 14.8852,
    "out:Property Electricity": 12.039,
    "out:Electricity (inc PV)": 7.350253,
    "out:Total Energy Use Pre PV": 65.039,
    "out:Total Energy Use Post PV": 60.350253,
    "out:Primary Energy": 54.925195,
    "out:Energy Class": "B",
    "out:CO2 Embodied/m2": 65.101476,
    "out:CO2 Operational/m2": -59.631811,
    "out:Total CO2/m2": 5.469665,
    "out:Total CO2 (tons)": 10.78618,
    "out:Klimatpaverkan": -84.145992,
    "out:Initial Cost/MSEK": 6.316530011847042,
    "out:Running cost/(Apt SEK y)": 8771.029412,
    "out:Running Cost over RSP/MSEK": 2.34221,
    "out:LCP/MSEK": -2.32967,
    "out:ROI% old": 9.01684,
    "out:Payback discounted": 13,
    "out:Atemp": 1972,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 449.44152036891956,
    "out:Facade surface/m2": 994.5403608820859,
    "out:Window surface/m2": 231.35917920733237,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 130,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 517.894124,
    "out:Return %": 8,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 51404.50165165966,
    "out:EAHP (pipework)": 51247.352962574994,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 14.8852,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 312500,
    "out:PV (kr/kWp)": 17461.562555646477,
    "out:PV (kWp installed)": 42.831777,
    "out:PV (m2 panels)": 224.72076,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 40187.786555,
    "out:PV (kWh sold)": 30941.576259,
    "out:PV (% sold (El))": 76.992487,
    "out:PV (kWh self-consumed)": 9246.210295,
    "out:PV (ratio sold/self-consumed)": 3.346406,
    "out:FTX (AHU)": 248288.765625,
    "out:FTX (Ductwork)": 822567.6938370871,
    "out:FTX (Diffusers)": 142796.875,
    "out:FTX (Dampers)": 52187.5,
    "out:FTX (S to F improvement cost)": 312500,
    "out:FTX (Replacement Cost)": 122318.80371968803,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 17.512,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 379452.239298,
    "out:FTX (Facade)": 2718227.98766715,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154032.236985,
    "out:EL kWh savings": 176.981094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 49300,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200798.56,
    "out:DH kr savings": 154032.2369853497,
    "out:El kr savings": 353.9621884623371,
    "out:El kr sold": 46412.36438913721,
    "out:El kr saved": 18492.42059,
    "out:El kr return": 64904.784979137214,
    "out:% solar/total": 63,
    "out:Total_El consumption (without PV)": 14671.680848,
    "out:Total_Space Heating (DH)": 75542.440216,
    "out:% savings (space heating)": 64.987942,
    "out:Total_Water Heating (DH)": 29353.616097,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 471867.7682456162,
    "out:Eupp": 38.31,
    "out:Etvv": 17,
    "out:Ef": 7.35,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6316530.011847042,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": -78.710963,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -79.682752,
    "out:Bought CO2": 0.971789,
    "out:Self-consume CO2": 0
  }
]