import { Company } from "./Company";

export const CompanyId = {
  [Company.CompanyA]: "6733a77a-6fe3-4d8e-84fd-2cfbb083fc3a",
  [Company.CompanyB]: "bd43491b-da20-4e6c-ab1d-3a7a861746b9",
  [Company.CompanyC]: "f12yg12g-h12h-b12v-h1h3-b1h2b112b13b",
  [Company.CompanyD]: "b12bh123-56hb-2cf3-34hb-12h3h1j2312v",
} as const;

export type CompanyId = (typeof CompanyId)[keyof typeof CompanyId];
