import type { Datum, Value } from "../types";

type Calculator = (parameters: {
  currentValue: Value;
  updatedValues: Datum;
  originalValues: Datum;
}) => Value;

export const calculators = new Map<string, [string[], Calculator]>([
  [
    "out:Initial Cost/MSEK",
    [
      [
        "out:Windows(kr/m2)",
        "out:Window surface/m2",
        "out:IMD (#apts)",
        "out:IMD (kr/apt)",
        "out:PV (kr/kWp)",
        "out:PV (kWp installed)",
        "out:Fasadisolering (kr/m2)",
        "out:Facade surface/m2",
        "out:kr/heat exchanger",
        "out:number of heat exchangers",
        "out:cost/m2 injustering",
        "out:Atemp",
        "out:EAHP (Heatpump)",
        "out:EAHP (1000ltanks)",
        "out:EAHP (pipework)",
        "out:EAHP (natural ventilation grills)",
        "out:cost_raised roof",
        "out:EAHP (installation)",
        "out:EAHP (replacement cost)",
        "out:FTX (AHU)",
        "out:FTX (Ductwork)",
        "out:FTX (Diffusers)",
        "out:FTX (Dampers)",
        "out:FTX (Roof)",
        "out:FTX (Facade)",
        "out:cost_raised roof",
        "out:FTX (Replacement Cost)",
        "out:FTX (S to F improvement cost)",
        "out:New AHU Cost/SEK",
        "out:EAHP (S to F improvement cost)",
      ],
      ({ currentValue, updatedValues, originalValues }) => {
        return (
          Number(currentValue) * 1_000_000 -
          Number(originalValues["out:Windows(kr/m2)"]) *
            Number(originalValues["out:Window surface/m2"]) +
          Number(updatedValues["out:Windows(kr/m2)"]) *
            Number(updatedValues["out:Window surface/m2"]) -
          Number(originalValues["out:IMD (kr/apt)"]) *
            Number(originalValues["out:IMD (#apts)"]) +
          Number(updatedValues["out:IMD (kr/apt)"]) *
            Number(updatedValues["out:IMD (#apts)"]) -
          Number(originalValues["out:PV (kr/kWp)"]) *
            Number(originalValues["out:PV (kWp installed)"]) +
          Number(updatedValues["out:PV (kr/kWp)"]) *
            Number(updatedValues["out:PV (kWp installed)"]) -
          Number(originalValues["out:Facade surface/m2"]) *
            Number(originalValues["out:Fasadisolering (kr/m2)"]) +
          Number(updatedValues["out:Facade surface/m2"]) *
            Number(updatedValues["out:Fasadisolering (kr/m2)"]) -
          Number(originalValues["out:kr/heat exchanger"]) *
            Number(originalValues["out:number of heat exchangers"]) +
          Number(updatedValues["out:kr/heat exchanger"]) *
            Number(updatedValues["out:number of heat exchangers"]) -
          Number(originalValues["out:Atemp"]) *
            Number(originalValues["out:cost/m2 injustering"]) +
          Number(updatedValues["out:Atemp"]) *
            Number(updatedValues["out:cost/m2 injustering"]) -
          Number(originalValues["out:New AHU Cost/SEK"]) +
          Number(updatedValues["out:New AHU Cost/SEK"]) -
          (Number(originalValues["out:EAHP (Heatpump)"]) +
            Number(originalValues["out:EAHP (1000ltanks)"]) +
            Number(originalValues["out:EAHP (pipework)"]) +
            Number(originalValues["out:EAHP (natural ventilation grills)"]) +
            Number(originalValues["out:cost_raised roof"]) +
            Number(originalValues["out:EAHP (installation)"]) +
            Number(originalValues["out:EAHP (S to F improvement cost)"]) +
            Number(originalValues["out:EAHP (replacement cost)"])) +
          (Number(updatedValues["out:EAHP (Heatpump)"]) +
            Number(updatedValues["out:EAHP (1000ltanks)"]) +
            Number(updatedValues["out:EAHP (pipework)"]) +
            Number(updatedValues["out:EAHP (natural ventilation grills)"]) +
            Number(updatedValues["out:cost_raised roof"]) +
            Number(updatedValues["out:EAHP (installation)"]) +
            Number(updatedValues["out:EAHP (S to F improvement cost)"]) +
            Number(updatedValues["out:EAHP (replacement cost)"])) -
          (Number(originalValues["out:FTX (AHU)"]) +
            Number(originalValues["out:FTX (Ductwork)"]) +
            Number(originalValues["out:FTX (Diffusers)"]) +
            Number(originalValues["out:FTX (Dampers)"]) +
            Number(originalValues["out:FTX (Roof)"]) +
            Number(originalValues["out:FTX (Facade)"]) +
            Number(originalValues["out:FTX (S to F improvement cost)"]) +
            Number(originalValues["out:FTX (Replacement Cost)"])) +
          (Number(updatedValues["out:FTX (AHU)"]) +
            Number(updatedValues["out:FTX (Ductwork)"]) +
            Number(updatedValues["out:FTX (Diffusers)"]) +
            Number(updatedValues["out:FTX (Dampers)"]) +
            Number(updatedValues["out:FTX (Roof)"]) +
            Number(updatedValues["out:FTX (Facade)"]) +
            Number(updatedValues["out:FTX (S to F improvement cost)"]) +
            Number(updatedValues["out:FTX (Replacement Cost)"]))
        );
      },
    ],
  ],

  [
    "out:Payback Time",
    [
      ["out:ROI %"],
      ({ updatedValues }) => {
        return 100 / Number(updatedValues["out:ROI %"]);
      },
    ],
  ],

  [
    "out:ROI %",
    [
      ["out:Total besparing (kr)", "out:Initial Cost/MSEK"],
      ({ updatedValues }) => {
        const totalInstallationCost = Number(
          updatedValues["out:Total besparing (kr)"],
        );
        const initialCost = Number(updatedValues["out:Initial Cost/MSEK"]);

        return (totalInstallationCost / initialCost) * 100;
      },
    ],
  ],
]);
